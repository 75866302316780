import { reduce } from 'lodash';
import { IStudentActivity } from './../../shared/typings/interfaces/student-activity.interface';
import * as studentActivitiesActions from './../actions/student-activities-actions';

export interface IStudentActivitiesState {
  loading: boolean;
  loaded: boolean;
  studentActivitiesEntities: {
    [id: string]: IStudentActivity;
  };
}

export const initialState: IStudentActivitiesState = {
  loading: false,
  loaded: false,
  studentActivitiesEntities: {},
};

export function reducer(
  state: IStudentActivitiesState = initialState,
  action: studentActivitiesActions.StudentActivitiesAction,
): IStudentActivitiesState {
  let studentActivity;
  let studentActivitiesEntities;
  switch (action.type) {
    case studentActivitiesActions.LOAD_STUDENT_ACTIVITIES:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case studentActivitiesActions.LOAD_STUDENT_ACTIVITIES_SUCCESS:
      const studentActivities = action.payload.data.StudentActivities;
      const loadedStudentActivities: IStudentActivitiesState['studentActivitiesEntities'] = reduce(
        studentActivities,
        (acc, studentActivity: IStudentActivity) => {
          acc[studentActivity._id] = studentActivity;

          return acc;
        },
        {},
      );
      const mergedActivities = { ...state.studentActivitiesEntities, ...loadedStudentActivities };
      return {
        ...state,
        loading: false,
        loaded: true,
        studentActivitiesEntities: mergedActivities,
      };
    case studentActivitiesActions.LOAD_STUDENT_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case studentActivitiesActions.UPDATE_STUDENT_ACTIVITY_SUCCESS:
      const { patch, studentActivityId } = action.payload;
      studentActivitiesEntities = Object.assign({}, state.studentActivitiesEntities);
      studentActivity = studentActivitiesEntities[studentActivityId]
        ? studentActivitiesEntities[studentActivityId]
        : {};
      const updatedStudentActivity = Object.assign(studentActivity, patch);
      studentActivitiesEntities[studentActivityId] = updatedStudentActivity;

      return {
        ...state,
        studentActivitiesEntities,
      };
    case studentActivitiesActions.CREATE_STUDENT_ACTIVITY_SUCCESS:
      studentActivity = action.payload.data.createStudentActivity;
      studentActivitiesEntities = Object.assign({}, state.studentActivitiesEntities);
      studentActivitiesEntities[studentActivity._id] = studentActivity;

      return {
        ...state,
        studentActivitiesEntities,
      };
    default:
      return state;
  }
}
