import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IActivitiesState } from './../reducers/activities-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getActivitiesState = createSelector(
  getPortalState,
  (state: any) => {
    return state.activities;
  },
);

export const getActivitiesEntities = createSelector(
  getActivitiesState,
  (state: IActivitiesState) => {
    return state.activitiesEntities;
  },
);

export const getActivitiesEntitiesList = createSelector(
  getActivitiesState,
  (state: IActivitiesState) => {
    const activitiesEntities = state.activitiesEntities;
    return map(activitiesEntities, (v, k) => v);
  },
);

export const getActivitiesLoadingStatus = createSelector(
  getActivitiesState,
  (state: IActivitiesState) => {
    return state.loading;
  },
);

export const getActivitiesLoadedStatus = createSelector(
  getActivitiesState,
  (state: IActivitiesState) => {
    return state.loaded;
  },
);
