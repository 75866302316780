import { RouterExitService } from './../../../routing/services/router-exit.service';
import { IACOption } from '../../../shared/typings/interfaces/design-library.interface';
import { Router } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { PickerDataService } from './picker.data.service';
import { combineLatest, Observable } from 'rxjs';
import { IPartner, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { map as rxMap } from 'rxjs/operators';
import { ICluster } from '../../typings/interfaces/cluster.interface';
import { IUser } from '../../typings/interfaces/user.interface';
import { UrlPathService } from '../url-path-service/url-path.service';

interface IQueryParams {
  [key: string]: string;
}

interface IPreviousState {
  path: string;
  queryParams: IQueryParams;
}

interface IPreviousURL {
  url: string;
  queryParams: IQueryParams;
}

export interface IPickerBackIcon {
  name: 'arrow-left' | 'close';
  fn: Function;
}

@Injectable()
export class PickerService {
  constructor(
    private router: Router,
    private exitService: RouterExitService,
    private pickerDataService: PickerDataService,
    private urlPathService: UrlPathService,
  ) {}

  filterAcOptions(acOptions: IACOption[], value: IACOption | string): IACOption[] {
    const onSearch = typeof value === 'string';
    if (onSearch) {
      const searchTerm = (value as string).toLowerCase().trim();
      const alternativeSearchTerm = searchTerm.replace('and', '&');

      return acOptions.filter(option => {
        if (!searchTerm) {
          return true;
        }
        const hasTagMatch: boolean =
          option.tags &&
          !!option.tags.filter(({ human, key }) => {
            return (
              human.toLowerCase().includes(searchTerm) ||
              key.toLowerCase().includes(searchTerm) ||
              human.toLowerCase().includes(alternativeSearchTerm) ||
              key.toLowerCase().includes(alternativeSearchTerm)
            );
          }).length;

        const hasKeyMatch: boolean =
          option.key.toLowerCase().includes(searchTerm) || option.key.toLowerCase().includes(alternativeSearchTerm);

        const hasHumanMatch: boolean =
          option.human.toLowerCase().includes(searchTerm) || option.human.toLowerCase().includes(alternativeSearchTerm);

        return hasKeyMatch || hasTagMatch || hasHumanMatch;
      });
    }
  }

  goBackToExploreUI(): void {
    const url = this.urlPathService.computeDistrictUrlPath('/explore');
    this.router.navigate([url]);
  }

  getBackIcon(): IPickerBackIcon {
    let backIcon: IPickerBackIcon = { name: null, fn: null };
    const { url: prevURL, queryParams: prevURLParams } = this.exitService.previousUrl$.value || {};

    const isValidPreviousURL = this._isValidPreviousURL(prevURL);
    if (prevURL?.match(/\/explore/)) {
      backIcon = { name: 'arrow-left', fn: this.goBackToExploreUI.bind(this) };
    } else if (isValidPreviousURL) {
      backIcon.name = 'close';
      const previousURL = { url: prevURL, queryParams: prevURLParams };
      backIcon.fn = this._goBackToPreviousURL(previousURL).bind(this);
    }
    return backIcon;
  }

  private _goBackToPreviousURL(previousURL: IPreviousURL): Function {
    return (): void => {
      const { url, queryParams } = previousURL;
      this.router.navigate([url], { queryParams });
    };
  }

  private _isValidPreviousURL(url: string): boolean {
    const schoolPickerRegex = /\/school-picker/;
    return !!(url && url !== '/shelter-picker' && url !== '/home' && !schoolPickerRegex.test(url));
  }

  // Get a list of school clusters or shelter clusters based on partnerType
  public getClusterOptions(partnerType: TValidPartnerTypes): Observable<IACOption[]> {
    return this.pickerDataService.getClusters(partnerType).pipe(
      rxMap((clusters: ICluster[]) =>
        clusters.map(cluster => ({
          key: cluster._id,
          human: `${cluster.clusterName} (${cluster._id})`,
        })),
      ),
    );
  }

  // Get a list of shelters
  public getPartnerOptions(partnerType: TValidPartnerTypes): Observable<IACOption[]> {
    return this.pickerDataService.getPartners(partnerType).pipe(
      rxMap((partners: IPartner[]) =>
        partners.map(partner => ({
          key: partner._id,
          human: `${partner.partnerName} (${partner._id})`,
        })),
      ),
    );
  }

  // Get a list of filtered school clusters or shelter clusters based on partnerType and user input
  public getFilteredClusterOptions(partnerType, searchVal$: Observable<string>): Observable<IACOption[]> {
    const clusters$ = this.getClusterOptions(partnerType);
    return combineLatest([clusters$, searchVal$]).pipe(
      rxMap(([clusters, searchVal]) => this.filterAcOptions(clusters, searchVal)),
    );
  }

  // Get a list of filtered schools or shelters based on partnerType and user input
  public getFilteredPartnerOptions(partnerType, searchVal$: Observable<string>, user: IUser): Observable<IACOption[]> {
    switch (partnerType) {
      case 'school':
      default:
        const { _role_clusterSchools = [] } = user;
        const schools = _role_clusterSchools.map(({ _id, fullName, nickName }) => ({
          key: _id,
          human: fullName,
          tags: [nickName].map(val => ({ key: val, human: val })),
        }));
        return searchVal$.pipe(rxMap(searchVal => this.filterAcOptions(schools, searchVal)));
      case 'shelter':
        const shelters$ = this.getPartnerOptions('shelter');
        return combineLatest([shelters$, searchVal$]).pipe(
          rxMap(([shelters, searchVal]) => this.filterAcOptions(shelters, searchVal)),
        );
    }
  }

  public getPartnerType(url: string): TValidPartnerTypes {
    // matches /school-picker or /shelter-picker in the passed url
    const matched = url.match(/\/(\w+)-\w+/);
    const partnerType = matched && matched[0].split(/\/|-picker/).join('');
    return partnerType as TValidPartnerTypes;
  }

  public navigateToPortal (partnerId: string, partnerType: TValidPartnerTypes) {
    const url = this.urlPathService.computeDistrictUrlPath(`school/${partnerId}/lists/tiles`);
    switch (partnerType) {
      case 'school':
      default:
        this.router.navigate([url]);
        break;
      case 'shelter':
        this.router.navigate([`/shelter/${partnerId}`]);
        break;
    }
  }

  public navigateToNetDash(clusterId: string, partnerType: TValidPartnerTypes): void {
    switch (partnerType) {
      case 'school':
      default:
        const url = this.urlPathService.computeDistrictUrlPath(`/network/school/${clusterId}`);
        this.router.navigate([url]);
        break;
      case 'shelter':
        // TODO...
        break;
    }
  }

  public navigateToShelterClusterUserSetting(clusterId: string): void {
    const url = this.urlPathService.computeDistrictUrlPath(`/network/shelter/${clusterId}/settings/user-permissions`);
    this.router.navigate([url]);
  }
}
