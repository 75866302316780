import {
  Component,
  ViewChild,
  ElementRef,
  Renderer2,
  ChangeDetectorRef,
  Input,
  OnChanges,
  ChangeDetectionStrategy,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'hybrid-cluster-user-alert',
  templateUrl: './hybrid-cluster-user-alert.component.html',
  styleUrls: ['./hybrid-cluster-user-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HybridClusterUserAlertComponent implements OnChanges {
  @ViewChild('warningContent', { static: false }) warningContentEl: ElementRef;
  @Input() warningContent: string;

  constructor (private renderer: Renderer2, private cd: ChangeDetectorRef) {
    // ..
  }

  ngOnChanges (changes) {
    this.cd.detectChanges();
    if (this.warningContentEl) {
      this.renderer.setProperty(this.warningContentEl.nativeElement, 'innerHTML', this.warningContent);
    }
  }
}
