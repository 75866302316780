import { WindowRef } from './../../../../../../shared/services/windowRef';
import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'monitor-attendance-step',
  templateUrl: './monitor-attendance-step.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MonitorAttendanceStep {
  public stepLabel: string = 'Monitor attendance in support activities';
  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) { }

  goToSupportsSettings () {
    const { schoolId } = this.activatedRoute.snapshot.params;
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/supports`);
    this.windowRef.nativeWindow.open(url, '_blank');
  }
}
