import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ISidebarFlatNode, ISidebarItem } from './nv-sidebar.interface';

/**
 *
 * Displays a list of options. Typically used for routing/navigation or selecting from a set of actions.
 *
 * Omit the `title` when the context of the sidebar makes a title redundant (e.g. Settings).
 *
 */
@Component({
  selector: 'nv-sidebar-list',
  templateUrl: './nv-sidebar-list.component.html',
  styleUrls: ['./nv-sidebar-list.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvSidebarListComponent {
  /**
   *
   *
   * Array of `ISidebarItems`
   * ```js
   * interface ISidebarItem {
   *   human: string;
   *   key: string;
   *   icon?: string;
   *   expandAs?: 'accordion' | 'dropdown';
   *   children?: ISidebarItem[] | IDropdownOption[] ;
   *   disabled?: boolean;
   * }
   * ```
   *
   * `expandAs` defines whether the children appear as a dropdown menu, or in an accordion submenu.
   * When set to `accordion`, any `icon` property will be ignored.
   *
   * Note: Nested accordions are not yet supported.
   *
   */
  @Input() listData: ISidebarItem[];

  /**
   *
   * The `key` of the selected option
   */
  @Input() selectedKey: string; // the selected key

  /**
   *
   * The title of the list
   */
  @Input() title: string;

  /**
   *
   * The subtitle of the list
   */
  @Input() subtitle: string;

  /**
   *
   * Determines whether the subtitle pulses on change. This is particularly useful for batch actions when the subtitle changes based on the selection.
   */
  @Input() shouldAnimateSubtitle: boolean = false;

  /**
   *
   * Emits the `key` of the selected item (or child item)
   */
  @Output() sidebarListItemSelect: EventEmitter<string> = new EventEmitter<string>();

  private _transformer = (node: ISidebarItem, level: number) => {
    return {
      expandable: node.expandAs === 'accordion' && !!node.children && node.children.length > 0,
      human: node.human,
      key: node.key,
      url: node.url,
      level,
      expandAs: node.expandAs,
      children: node.children,
      disabled: !!node.disabled,
      icon: node.icon,
      queryParams: node.queryParams,
      hasBetaFlag: node.hasBetaFlag,
    };
  };

  treeControl = new FlatTreeControl<ISidebarFlatNode>(node => node.level, node => node.expandable);
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ISidebarFlatNode) => node.expandable;

  ngOnInit (): void {
    this.dataSource.data = this.listData;

    // Expands the tree nodes of a selected nested item by default
    for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
      if (this.nodeIsSelected(this.treeControl.dataNodes[i])) {
        this.treeControl.expand(this.treeControl.dataNodes[i]);
      }
    }
  }

  ngOnChanges ({ listData }) {
    if (listData && listData.currentValue !== this.dataSource.data) this.dataSource.data = listData.currentValue;
  }

  handleSelect (key: string) {
    this.sidebarListItemSelect.emit(key);
  }

  nodeIsSelected (node: ISidebarFlatNode): boolean {
    return node.key === this.selectedKey || (node.children && node.children.map(n => n.key).includes(this.selectedKey));
  }

  nodeIsLastNode (node: ISidebarFlatNode): boolean {
    const nodeIndex = this.dataSource.data.map(n => n.key).indexOf(node.key);
    return nodeIndex === this.dataSource.data.length - 1;
  }
}
