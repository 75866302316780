import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { IPageHeaderMeta } from 'Src/ng2/shared/components/header/page-header/page-header-meta.interface';
import { IGroupData, IRowData } from './../../../../models/list-models';
import { Direction, SortAndFilterService, TSortDirections } from './../../../../services/list-services/sort-and-filter.service';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { MasterProgramSettingsDataService } from '../master-program-settings-data.service';

@Component({
  selector: 'master-program-settings-content',
  templateUrl: './master-program-settings-content.component.html',
  styleUrls: ['./master-program-settings-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MasterProgramSettingsContentComponent implements OnInit {
  @Input() groupData$: BehaviorSubject<IGroupData>;
  @Input() option$: BehaviorSubject<IDropdownOption[]>;
  @Input() optionSelected: string;
  @Input() batchActionsMode$: BehaviorSubject<boolean>;
  @Input() pageHeaderMeta: IPageHeaderMeta;
  @Input() schoolId: string;
  @Output() madLibChange = new EventEmitter();
  @Output() onCourseSelected = new EventEmitter<any>();

  public columnIndexMap: { [key: string]: number };
  public columns: any[];
  public listConfig: any;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public batchActionsSelectedIds$: BehaviorSubject<string[]>;
  selectedIds: string[] = [];
  public batchActionsHash: any;
  public filterFormControl: FormControl;

  constructor (
    private masterProgramSettingsDataService: MasterProgramSettingsDataService,
  ) {
    //
  }

  ngOnInit (): void {
    this.sortKey$ = new BehaviorSubject('COURSE_CODE');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(Direction.asc);
    this.filterFormControl = new FormControl();

    this.columns = this.masterProgramSettingsDataService.getColumns();

    this.columnIndexMap = this.columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});

    this.listConfig = {
      emptyTableMessage: 'No courses to display',
      listType: 'MASTER_PROGRAM',
      noDataMessage: 'No courses to display',
      sortableColumns: true,
    };
  }

  public onMadLibChange (e: string): void {
    this.madLibChange.emit(e);
  }

  public updateSort (sortKey: string): void {
    SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
  }

  public formatAndFireBatchActionEvt ($event: { updateAll: boolean; data: IRowData[]; level: 'SECTION' | 'ROW' }): void {
    this.onCourseSelected.emit($event);
  }
}
