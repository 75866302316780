import { NgModule } from '@angular/core';
import { ShelterListToolsComponent } from './shelter-list-tools.component';

@NgModule({
  declarations: [ShelterListToolsComponent],
  imports: [],
  exports: [ShelterListToolsComponent],
  providers: [],
})
export class ShelterListToolsModule {}
