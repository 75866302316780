import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApiService } from '../../../shared/services/api-service/api-service';
import { generateQuery as gen } from './../../../store/effects/projections/support-groupings';

export interface IFilter {
  filterKey: string;
  label: string;
  filterOptions: Array<{ graphQlKey: string; label: string }>;
}

export interface IGrouping {
  graphQlKey: string;
  label: string;
}

export interface IFocus {
  graphQlKey;
  category?: string;
  categoryOrder?: string;
  fociOrder?: string;
  label: string;
  isDefault: boolean;
  filters: IFilter[];
  groupings: IGrouping[];
}

@Injectable()
export class SupportsDataService {
  private fociCache$: Observable<any> = null;

  constructor (public apiService: ApiService) {}

  // called by support list components (top, settings) - used to get madlib
  getSupportFocusData$ (
    schoolId: string,
    focusKey?: string,
    requiresMadlibRefresh?: boolean,
  ): Observable<{ data: { SupportFocus: IFocus[] } }> {
    if (requiresMadlibRefresh) this.clearFociCache();
    if (!this.fociCache$) {
      this.fociCache$ = this.apiService
        .getStudentsGraphQL(this.getSupportFocusQuery(schoolId, focusKey))
        .pipe(shareReplay(1));
    }
    return this.fociCache$;
  }

  // called by support list components (top, settings) - used to generate actual list based on madlib selections
  getSupportGroupingData$ (payload: { schoolId: string; filterKeys: string[]; groupingKey: string; columns: string[] }) {
    // only want active students, so we append this filter behind the scenes
    payload.filterKeys.push('NON_DELETED_SUPPORTS', 'NON_COLLEGE_CAREER_SUPPORTS');
    const query = gen({ payload });
    return this.apiService.getStudentsGraphQL({ query, fetchPolicy: 'no-cache' });
  }

  // called internally - defines the graphQL query to call
  getSupportFocusQuery (schoolId: string, focusKey?: string) {
    const payload = focusKey ? `schoolId:"${schoolId}", focusKey:"${focusKey}"` : `schoolId:"${schoolId}"`;

    const query = `{
      SupportFocus(${payload}) {
        category
        categoryOrder
        graphQlKey
        label
        isDefault
        filters {
          label
          filterOptions {
            graphQlKey
            label
            isDefault
            filterOptions {
              graphQlKey
              label
            }
          }
        }
        groupings {
          graphQlKey
          label
          requiresWildcard
          wildcardKey
          columns {
            columnDataFormat
            graphQlKey
            label
            requiresWildcard
            wildcardKey
            cellConfig
            cellTooltip
            cellTooltipWildcardKey
          }
        }
      }
    }`;
    return { query, fetchPolicy: 'no-cache' };
  }

  clearFociCache (): void {
    this.fociCache$ = null;
  }
}
