import { Component, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { IDropdownOption } from '../../../../../shared/typings/interfaces/design-library.interface';
import {
  StudentPathStatuses,
  STUDENT_PATH_FINAL_DECISION_STATUSES,
  COLLEGE_PATH_STATUSES,
} from '../../../../../shared/constants/student-paths.constant';
import { TPathCategory } from './../../../../typings/types/student-path.types';
import { PATH_CATEGORY_KEYS } from '../../../../constants/student-paths.constant';
import { IStudentPath } from 'Src/ng2/shared/typings/interfaces/studentPaths.interface';

export const DEFAULT_STATUS_OPTION = {
  key: StudentPathStatuses.PLANS_TO_APPLY,
  human: StudentPathStatuses.PLANS_TO_APPLY,
};

@Component({
  selector: 'status-dropdown',
  templateUrl: './status-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class StatusDropdown implements OnInit {
  @Input() readonly pathCategory: TPathCategory;
  @Input() readonly selectedStatusOption: IDropdownOption;
  @Input() readonly studentPaths: IStudentPath[];
  @Output() selectStatus: EventEmitter<IDropdownOption> = new EventEmitter();

  private readonly defaultStatusOptionStrings = [
    StudentPathStatuses.PLANS_TO_APPLY,
    StudentPathStatuses.APPLIED,
    StudentPathStatuses.ACCEPTED,
    StudentPathStatuses.DENIED,
    StudentPathStatuses.COMMITTED,
  ];

  private readonly collegeStatusOptionStrings = Object.values(COLLEGE_PATH_STATUSES);

  private readonly finalDecisionStatuses = Object.values(STUDENT_PATH_FINAL_DECISION_STATUSES);

  public options: IDropdownOption[];
  public showWarning: boolean;
  public warningText = 'This action will set the final decision to the selected pathway.';
  public warningPriority: string = 'warning';
  public infoText = 'Status must be set to "Committed" in order for the student\'s plan to sync to STARS Postsecondary Tracker.';
  public infoPriority: string = 'info';


  constructor () {}

  ngOnInit (): void {
    const hasFinalDecision = (this.studentPaths || []).some((studentPath) => {
      const pathStatus = studentPath.status as StudentPathStatuses;
      return this.finalDecisionStatuses.includes(pathStatus);
    });

    const optionStrings =
      this.pathCategory === PATH_CATEGORY_KEYS.COLLEGE
        ? this.collegeStatusOptionStrings
        : this.defaultStatusOptionStrings;

    const availableOptionStrings = hasFinalDecision
      ? optionStrings.filter((option: StudentPathStatuses) => !this.finalDecisionStatuses.includes(option))
      : optionStrings;

    this.options = this.makeDropdownOptions(availableOptionStrings);
  }

  private makeDropdownOptions (optionStrings: StudentPathStatuses[]): IDropdownOption[] {
    return optionStrings.map((option) => ({ key: option, human: option }));
  }

  onChange (option: IDropdownOption): void {
    this.selectStatus.emit(option);
    this.showWarning = Object.values(STUDENT_PATH_FINAL_DECISION_STATUSES).includes(
      option.key as StudentPathStatuses,
    );
  }
}
