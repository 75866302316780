import { getSchool } from 'Src/ng2/store';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { TValidSdcColumns } from './../../sdc-columns.type';
import { sdcStateConfigConstant } from './../sdc-state-config.constant';
import { tap, take } from 'rxjs/operators';

@Injectable()
export class RegentsPlanningConfigService {
  constructor (private imSchool: ImSchool, private store: Store<any>) {}

  get humanName () {
    return sdcStateConfigConstant.REGENTS_PLANNING.humanName;
  }

  get state () {
    return sdcStateConfigConstant.REGENTS_PLANNING.state;
  }

  get gridColumns () {
    return _.difference(this.columns, this.hiddenColumns);
  }

  get columns (): TValidSdcColumns[] {
    const fullColumns = [
      'studentId',
      'studentName',
      'cohort',
      'seniorFlag',
      'status',
      'regentsPlanningPriorityGrouping',
      'grade',
      'officialClass',

      // google classroom
      'classroomRemoteEngagementLast5',
      'classroomRemoteEngagement',
      'classroomTotalAssignments',
      'classroomTotalEngagedAssignments',
      'classroomTotalEnrolled',
      'classroomStudentEmail',

      'atsCounselor',
      'advisor',
      'iep',
      'ell',
      'homeLanguage',
      'attendanceYtd',
      'gradPlan',
      'currentCourses',
      'currentCourseCodes',
      'totalCreditsEarned',
      'plannedDiplomaType',
      'plannedGraduationDate',
      // regents cats fulfilled
      'countOfRegentsPassedForDiploma',
      'regentsPassedForDiploma',
      // regents cats needed
      'countOfRegentsNeededForOnTrackDiploma',
      'regentsNeededForOnTrackDiploma',
      'regentsNotYetNeededForDiploma',
      'countOfRegentsNeededForOnTrackDiplomaNotSched',
      'regentsNeededForOnTrackDiplomaNotSched',
      'regentsNeededForOnTrackDiplomaSchedNextAdmin',
      'regentsNeededForOnTrackDiplomaSchedFutureAdmin',
      // college readiness
      'collegeReadyOnElaRegentsOrSatCr',
      'collegeReadyOnMathRegentsOrSatMath',
      'regentsNeededForCollegeReadiness',
      'regentsNeededForAdvancedRegentsDiploma',
      // regents plans
      'countOfRegentsSchedForNextAdmin',
      'regentsSchedForNextAdmin',
      'regentsSchedNextAdminAttemptedThreeOrMoreTimes',
      'countOfRegentsExamsPlannedBeyondStudentsGradDate',
      'regentsExamsPlannedBeyondStudentsGradDate',
      // conflicts
      'countOfRegentsExamsWithConclicts',
      // stars
      'countOfScheduledRegentsExamsInStarsForNextAdministration',
      'regentsSchedInStarsNextAdmin',
      'examsSchedInPortalNotPlannedInStars',
      'examsSchedInStarsNotPlannedInPortal',
      'examsToBeDroppedFromStars',
      // nysaa
      'nysaaEligible',
      // recommended not planned
      'recommendedRegentsBasedOnCulminatingCourseRationle',
      'pastDueRegentsExams',
      'pastDueUnplannedRegentsExams',
      'isRegentsRecRatCollegeReadinessMismatchedEla',
      'isRegentsRecRatCollegeReadinessMismatchedAlg',
      // max scores of 5
      'maxScoreEnglishExams',
      'maxScoreMath',
      'maxScoreScience',
      'maxScoreSs',
      'maxScorePlusOne',
      'plusOneExamName',
      // COMP ELA
      'maxScoreCompEla',
      'attCompEla',
      // CC ELA
      'maxScoreCcEla',
      'attCcEla',
      'nvRecCcEla',
      'schedInStarsCcEla',
      'nextScheduledCcEla',
      // INT ALG
      'yearStartedHsMath',
      'maxScoreIntAlg',
      'attIntAlg',
      // CC ALG
      'maxScoreCcAlg',
      'attCcAlg',
      'nvRecCcAlg',
      'schedInStarsCcAlg',
      'nextScheduledCcAlg',
      // OLD GEOM
      'maxScoreOldGeom',
      'attOldGeom',
      // CC GEOM
      'maxScoreCcGeom',
      'attCcGeom',
      'nvRecCcGeom',
      'schedInStarsCcGeom',
      'nextScheduledCcGeom',
      // OLD TRIG
      'maxScoreOldTrig',
      'attOldTrig',
      // CC TRIG
      'maxScoreCcTrig',
      'attCcTrig',
      'nvRecCcTrig',
      'schedInStarsCcTrig',
      'nextScheduledCcTrig',
      // LIVING
      'maxScoreLivingEnv',
      'attLivingEnv',
      'nvRecLivingEnv',
      'schedInStarsLivingEnv',
      'nextScheduledLivingEnv',
      // EARTH
      'maxScoreEarth',
      'attEarth',
      'nvRecEarth',
      'schedInStarsEarth',
      'nextScheduledEarth',
      // PHY SCI
      'maxScorePhysicalScience',
      'maxScoreChem',
      'attChem',
      'nvRecChem',
      'schedInStarsChem',
      'nextScheduledChem',
      // PHYSICS
      'maxScorePhysics',
      'attPhysics',
      'nvRecPhysics',
      'schedInStarsPhysics',
      'nextScheduledPhysics',
      // GLOBAL
      'maxScoreGlobal',
      'attGlobal',
      // GLOBAL II
      'maxScoreGlobalTwo',
      'attGlobalTwo',
      'nvRecGlobalTwo',
      'schedInStarsGlobalTwo',
      'nextScheduledGlobalTwo',
      // US
      'maxScoreUs',
      'attUs',
      // US Framework
      'maxScoreUsFramework',
      'attUsFramework',
      'nvRecUsFramework',
      'schedInStarsUsFramework',
      'nextScheduledUsFramework',
      // LOTE
      'creditsEarnedLote',
      'maxScoreLote',
      'attLote',
      'nvRecLote',
      'schedInStarsLote',
      'nextScheduledLote',
      'nextScheduledLoteExamName',
      // HIDDEN
      // needed for stars export
      'firstName',
      'lastName',

      'examsSchedInPortalAndPlannedInStars',
      // needed for min req for grad toggle in regents planning wizard table
      'countOfRegentsPassedForGrad',
      'regentsPassedForGrad',
      'countOfRegentsNeededForOnTrackGrad',
      'regentsNeededForOnTrackGrad',
      'regentsNotYetNeededForGrad',
      'regentsNeededForOnTrackGradNotSched',
      'regentsNeededForOnTrackGradSchedNextAdmin',
      'regentsNeededForOnTrackGradSchedFutureAdmin',
      // needed for final check step
      'countOfRegentsSchedNextAdminAttemptedThreeOrMoreTimes',
      // added for community schools on 3/22
      'schoolPriorityFlag',
    ];

    return fullColumns as TValidSdcColumns[];
  }

  get hiddenColumns (): TValidSdcColumns[] {
    return [
      // needed for stars export
      'firstName',
      'lastName',

      'examsSchedInPortalAndPlannedInStars',
      // needed for min req for grad toggle in regents planning wizard table
      'countOfRegentsPassedForGrad',
      'regentsPassedForGrad',
      'countOfRegentsNeededForOnTrackGrad',
      'regentsNeededForOnTrackGrad',
      'regentsNotYetNeededForGrad',
      'regentsNeededForOnTrackGradNotSched',
      'regentsNeededForOnTrackGradSchedNextAdmin',
      'regentsNeededForOnTrackGradSchedFutureAdmin',
      // needed for final check step
      'countOfRegentsSchedNextAdminAttemptedThreeOrMoreTimes',
    ];
  }

  get gridViews () {
    return {};
  }

  get filter () {
    const school = this.getSchool();

    const filter = {
      status: { values: ['A'] },
    };

    // Transfer schools should not be filtered by cohort
    const isTransfer = this.imSchool.isTransferSchool(school);
    if (isTransfer) return filter;

    const seniorAndSuperCohorts = this.imSchool.getSeniorAndSuperCohorts(school);

    let mostSeniorCohort;
    if (seniorAndSuperCohorts.length === 0) {
      mostSeniorCohort = _.min(school.uniqueCohorts);
    }

    // Otherwise return either senior + super senior cohorts, or the single most senior cohort
    const cohort = _.size(seniorAndSuperCohorts) ? seniorAndSuperCohorts : [mostSeniorCohort];

    (filter as any).cohort = { values: cohort };

    return filter;
  }

  get sort () {
    return [
      { colId: 'regentsPlanningPriorityGrouping', sort: 'asc' },
      { colId: 'status', sort: 'asc' },
      { colId: 'cohort', sort: 'asc' },
      { colId: 'studentName', sort: 'asc' },
    ];
  }

  get bundleName () {
    return 'REGENTS_PLANNING';
  }

  get availableSdcViews () {
    return sdcStateConfigConstant.REGENTS_PLANNING.availableSdcViews;
  }

  get imStudentMethods (): any {
    return null;
  }

  get projection () {
    return null;
  }

  getSchool () {
    let school;
    this.store.select(getSchool).pipe(
      tap(storeSchool => (school = storeSchool)), take(1)).subscribe();
    return school;
  }
}
