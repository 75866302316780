import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IColumn, IRowData } from 'Src/ng2/shared/models/list-models';
import { IDropdownOption } from '../../../../shared/typings/interfaces/design-library.interface';

export interface IAttendanceLogsMeta {
  eventDate: string;
  supportId: string;
  attendanceRecordIds?: string[];
  studentIds?: {
    studentSupportId: string;
    attendanceRecordId: string;
  };
  expectedStudents?: any[];
}

export const ATTENDANCE_LOGS_KEBAB_OPTIONS = {
  MARK_ALL_PRESENT: {
    human: 'Mark all students present',
    action: 'MARK_PRESENT',
  },
  SESSION_NOT_HELD: {
    human: 'Session was not held',
    action: 'MARK_EXCUSED',
  },
  TAKE_ATTENANCE: {
    human: 'Take attendance',
    action: 'OPEN_TAKE_ATTENDANCE_MODAL',
  },
  REVERT_TO_HELD: {
    human: 'Revert session to held',
    action: 'DELETE_EXCUSED',
  },
};

@Component({
  selector: 'support-attendance-logs-kebab',
  templateUrl: './support-attendance-logs-kebab.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class SupportAttendanceLogsKebabComponent implements OnInit {
  @Input() input: { row: IRowData[]; column: IColumn };
  @Output() output = new EventEmitter();
  options: IDropdownOption[];
  meta: IAttendanceLogsMeta;
  public isDisabled: boolean;

  public ngOnInit () {
    const { row } = this.input;
    if (row && row.length) {
      this.meta = JSON.parse(row[0].meta);
      const { expectedStudents } = this.meta;
      this.isDisabled = !(expectedStudents && expectedStudents.length);
      const wasSupportHeld = row.find(({ columnKey }) => columnKey === 'WAS_SUPPORT_SESSION_HELD').data;
      this.options = this._initDropdownOptions(wasSupportHeld);
    }
  }

  private _initDropdownOptions (wasSupportHeld: Boolean): IDropdownOption[] {
    const { MARK_ALL_PRESENT, SESSION_NOT_HELD, TAKE_ATTENANCE, REVERT_TO_HELD } = ATTENDANCE_LOGS_KEBAB_OPTIONS;
    const sessionWasHeldOptions = [
      { key: MARK_ALL_PRESENT.human, human: MARK_ALL_PRESENT.human },
      { key: SESSION_NOT_HELD.human, human: SESSION_NOT_HELD.human },
      { key: TAKE_ATTENANCE.human, human: TAKE_ATTENANCE.human },
    ];
    const sessionWasNotHeldOptions = [{ key: REVERT_TO_HELD.human, human: REVERT_TO_HELD.human }];
    const options = wasSupportHeld ? sessionWasHeldOptions : sessionWasNotHeldOptions;
    return options;
  }

  public onSelect (e: string): void {
    const { MARK_ALL_PRESENT, SESSION_NOT_HELD, TAKE_ATTENANCE, REVERT_TO_HELD } = ATTENDANCE_LOGS_KEBAB_OPTIONS;
    let action: string;
    switch (e) {
      case MARK_ALL_PRESENT.human: {
        action = MARK_ALL_PRESENT.action;
        break;
      }
      case SESSION_NOT_HELD.human: {
        action = SESSION_NOT_HELD.action;
        break;
      }
      case TAKE_ATTENANCE.human: {
        action = TAKE_ATTENANCE.action;
        break;
      }
      case REVERT_TO_HELD.human: {
        action = REVERT_TO_HELD.action;
        break;
      }
    }

    this.output.emit({
      action,
      meta: this.meta,
    });
  }
}
