import { Store } from '@ngrx/store';
import { map, tap, take } from 'rxjs/operators';
import { ImSchool } from '../../../../shared/services/im-models/im-school';
import { Component, ViewEncapsulation } from '@angular/core';
import { getSchool } from 'Src/ng2/store';
@Component({
  selector: 'regents-planning-wizard-container',
  templateUrl: './regents-planning-wizard-container.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class RegentsPlanningWizardContainer {
  public isTransfer: boolean;

  constructor (
    private imSchool: ImSchool,
    private store: Store<any>,
  ) {}

  ngOnInit () {
    this.store.select(getSchool).pipe(
      map(this.imSchool.isTransferSchool.bind(this)),
      tap((isTransfer: boolean) => (this.isTransfer = isTransfer)),
      take(1),
    ).subscribe();
  }
}
