import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ObjectCache } from '../../../../shared/services/object-cache/object-cache.service';

export interface INgRouterQueryParams {
  relativeTo: ActivatedRoute;
  queryParams: {
    focus: string;
    filter: string;
    grouping: string;
  };
  replaceUrl: boolean;
  queryParamsHandling: string;
}
@Injectable()
export class NgRouterNavigationService {
  constructor (public router: Router, private objectCache: ObjectCache) {}

  updateUrl (route: string[], extras = {}): void {
    this.router.navigate(route, extras);
  }

  goToProfile (payload: {
    caresIds: string[];
    updateRelPath: string;
    queryParams?;
    queryParamsHandling: string;
    route: ActivatedRoute;
  }): void {
    const { caresIds, updateRelPath, queryParamsHandling, queryParams, route } = payload;
    const hashedCaresIds = this.objectCache.cacheObject(caresIds);
    let hashedSort = null;

    if (queryParams) {
      hashedSort = this.objectCache.cacheObject(queryParams.sort);
    }
    const extras = {
      relativeTo: route,
      queryParams: {
        filter: null,
        sort: hashedSort,
        state: null,
        students: hashedCaresIds,
      },
      queryParamsHandling,
    };
    this.updateUrl([updateRelPath], extras);
  }
}
