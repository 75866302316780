import { EventEmitter, Injectable, Output } from '@angular/core';
import { DateHelpers } from './../../../../shared/services/date-helpers/date-helpers.service';
import { SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { Observable, Subject } from 'rxjs';

export interface IShelterSuccessMentoringMessage {
  updated: boolean;
}

@Injectable()
export class SuccessMentoringPanelService {
  @Output() updateCurrentStudentEvent = new EventEmitter<boolean>();
  private subject = new Subject<any>();

  constructor (
    private dateHelpers: DateHelpers,
    private snackBarService: SnackBarService,
  ) { }

  getHistoricalSuccessMentoringDateRange(startDate: string, endDate: string): string {
    const dateFormat = 'll';
    const start = this.dateHelpers.getFormattedMomentFromDate(startDate, dateFormat);
    const end = this.dateHelpers.getFormattedMomentFromDate(endDate, dateFormat);
    return `${start} - ${end}`;
  }

  showToast (updated: boolean, toastMessage: string): void {
    if (updated) {
      this.snackBarService.showToast({ toastText: toastMessage });
    }
  }

  sendMessage (message: IShelterSuccessMentoringMessage) {
    this.subject.next(message);
  }

  getMessage (): Observable<any> {
    return this.subject.asObservable();
  }
}