import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ShelterListToolsModule } from '../../list/list-tools/shelter-list-tools.module';
import { ShelterSideNavModule } from '../../side-nav/shelter-side-nav.module';
import { StandardLayoutComponent } from './standard-layout.component';

@NgModule({
  declarations: [StandardLayoutComponent],
  imports: [CommonModule, RouterModule, ShelterSideNavModule, ShelterListToolsModule],
  exports: [StandardLayoutComponent],
  providers: [],
  schemas: [],
})
export class StandardLayoutModule {}
