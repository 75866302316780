import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NvDropdownModule } from './../../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { Madlib } from './mad-lib.component';

@NgModule({
  imports: [CommonModule, MatMenuModule, NvDropdownModule],
  declarations: [Madlib],
  exports: [Madlib],
  providers: [],
})
export class MadlibModule {}
