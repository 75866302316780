import { AfterContentInit, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { ITooltipContent } from '../../../ng2/shared/nv-tooltip/nv-tooltip.interface';

/**
 *
 * Wrap this component around any input component to automatically
 * add a label that semantically refers to the input element.
 *
 * All labels in a form should be unique.
 * Textboxes/areas span the full width of the form, all other inputs are inline.
 *
 */
// TODO - allow for multiple inputs
@Component({
  selector: 'nv-form-field',
  templateUrl: './nv-form-field.component.html',
  styleUrls: ['./nv-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvFormFieldComponent implements AfterContentInit {
  /**
   *
   * The label to display for the input element
   *
   * @required
   *
   */
  @Input() label: string;

  /**
   *
   * The name of the icon (as `nv-icon`) to be attached to the label, defaults to null
   *
   */
  @Input() labelIcon: string = null;

  /**
   *
   * The data of the tooltip (as `nvTooltip`) to be attached to the label through a label icon, defaults to null
   *
   */
  @Input() labelIconTooltip: string | ITooltipContent = null;

  /**
   *
   * Decide whether the label and icon(optional) should turn pink if the input is invalid, defaults to false
   */
  @Input() showInvalid: boolean = false;

  /**
   *
   * The invalid state of the input, defaults to false
   */
  @Input() isInvalid: boolean = false;

  @ViewChild('inputEl', { static: true }) inputEl: ElementRef;

  private selectors = ['input', 'button', 'textarea', '[contenteditable]'];

  get _label () {
    if (this.label) {
      return this.label
        .toLowerCase()
        .replace(/([ ])/g, '-') // spaces to dashes
        .replace(/([^A-Za-z0-9\-])/g, ''); // remove all non alpha-numeric characters (except dash)
    } else {
      return null;
    }
  }

  ngAfterContentInit () {
    const inputElement = this.inputEl.nativeElement.querySelector(this.selectors.join(','));
    if (inputElement) {
      inputElement.setAttribute('id', this._label);
    }
  }
}
