import { Injectable } from '@angular/core';
import { ListCellFormattingService } from '../../../school/lists/list-display/list-cell-formatting.service';
import { COLUMN_DATA_TYPE } from '../../../shared/constants/list-view/cell-type.constant';
import { DateHelpers } from '../../../shared/services/date-helpers/date-helpers.service';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';

/* istanbul ignore next */
@Injectable()
export class MilestoneListService {
  listCellFormattingService: ListCellFormattingService;

  constructor(private dateHelpers: DateHelpers) {
    this.listCellFormattingService = new ListCellFormattingService();
  }

  // todo: add spec
  formatColumnData(columnType, columnData, cellConfig) {
    const { data } = columnData;

    switch (columnType) {
      case COLUMN_DATA_TYPE.FLAG:
        return this.listCellFormattingService.flagFormatter(data);

      case COLUMN_DATA_TYPE.GENERIC:
        return this.listCellFormattingService.genericFormatter(data);

      case COLUMN_DATA_TYPE.FORMATTED_DATA:
        return this.getFormattedData(data, cellConfig);

      default:
        let formattedData: IListColumnData = {
          data,
          style: '',
        };
        return formattedData;
    }
  }

  // todo: add spec
  getFormattedData(columnData, cellConfig) {
    const { formatType } = cellConfig;

    switch (formatType) {
      case 'status':
        return this.formatStatus(columnData);
      case 'dueBy':
        return this.formatDueBy(columnData);
      default:
        return this.listCellFormattingService.genericFormatter(columnData);
    }
  }

  // todo: add spec
  formatStatus(columnData) {
    const { dueAt } = columnData;
    const today = this.dateHelpers.getMomentObj();
    const dueDate = this.dateHelpers.getMomentObj(dueAt);
    const diff = dueDate.diff(today, 'days');
    let status;
    let style = 'center text-small';
    if (diff <= 0) {
      status = 'Past due';
      style += ' past-due';
    } else if (diff <= 14) {
      status = 'Due soon';
      style += ' due-soon';
    } else status = 'Not yet due';

    if (columnData.status === 'COMPLETE') {
      status = 'Complete';
      style = 'center completed';
    }

    let formattedData: IListColumnData = {
      data: status,
      style,
    };

    return formattedData;
  }

  formatDueBy(columnData) {
    const { status } = columnData;
    const data = status === 'COMPLETE' ? '—' : columnData;
    const style = status === 'COMPLETE' ? 'center text-small completed' : 'center text-small';
    let formattedData: IListColumnData = {
      data,
      style,
    };

    return formattedData;
  }
}
