import { IUserMini } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { LoteSchedulerModalComponent } from './lote-scheduler/lote-scheduler-modal.component';
import { CreditGapsFreeformCourseModal } from './credit-gap-freeform/credit-gap-freeform-course-modal.component';
import { CreditGapsCoursePickerModal } from './credit-gaps-course-picker/credit-gaps-course-picker-modal.component';
import { BackgroundJobSpinnerModal } from './background-job-spinner-modal/background-job-spinner-modal.component';
import { IListSummaryGrouping } from './../components/list-summary/list-summary.component';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ICollegeListUploadPayload } from 'Src/ng2/school/data-uploads/college-list/college-list-upload.component';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { ICunyCollegeReport } from 'Src/ng2/student/common-panels/student-postsec/student-postsec.component';
import { IFlag } from '../typings/interfaces/flags.interface';
import { ActivitiesModalComponent, IActivitiesModalComponentData } from './activities/activities-modal.component';
import {
  ActivityStatusModalComponent,
  IActivityStatusModalComponentData,
} from './activities/activity-status-modal/activity-status-modal.component';
import { AddCareerModalComponent } from './add-career/add-career.component';
import { AlertModalcomponent } from './alert/alert-modal.component';
import {
  AssignSupportModalComponent,
  IAssignSupportModalComponentData,
} from './assign-support/assign-support-modal.component';
import {
  EditContactInfoModalComponent,
  IEditContactInfoModalComponentData,
} from './edit-contact-info-modal/edit-contact-info-modal.component';
import { BatchEditModalComponent } from './batch-edit/batch-edit-modal.component';
import { ConfirmModalComponent, IConfirmModalComponentData } from './confirm/confirm-modal.component';
import {
  CsvUploadConfirmModalComponent,
  IConfirmUploadModalComponentData,
} from './csv-upload/confirm-modal/csv-upload-confirm-modal.component';
import { CsvUploadErrorModalComponent } from './csv-upload/error-modal/csv-upload-error-modal.component';
import { CsvUploadFixErrorsModalComponent } from './csv-upload/fix-errors-modal/csv-upload-fix-errors-modal.component';
import {
  EditGridColumnsModalComponent,
  IEditGridColumnsModalComponentData,
} from './edit-grid-columns/edit-grid-columns-modal.component';
import {
  EndorsementsCredentialsModalComponent,
  IEndorsementsCredentialsModalData,
} from './endorsements-credentials/endorsements-credentials-modal.component';
import { ErrorModalComponent } from './error/error-modal.component';
import { FlagIgnoreModalComponent } from './flag-ignore/flag-ignore-modal.component';
import { FlagLogActionModalComponent } from './flag-log-action/flag-log-action-modal.component';
import { HistoryAllModalComponent, IHistoryModalData } from './history-all/history-all-modal.component';
import { ListSummaryModalComponent } from './list-summary/list-summary-modal.component';
import { EOPMilestoneModalComponent } from './milestone/eop-milestone-modal/eop-milestone-modal.component';
import { FinalDecisionMilestoneModalComponent } from './milestone/final-decision-milestone-modal/final-decision-milestone-modal.component';
import {
  MODALS_CONFIG,
  MODALS_CONFIG_COMMON_MARKUP,
  MODALS_CONFIG_EXTRA_WIDE,
  MODALS_CONFIG_MED,
  MODALS_CONFIG_WIDE,
  MODALS_CONFIG_WIDE_NO_PADDING,
  MODALS_CONFIG_MED_NO_PADDING,
  MODALS_CONFIG_NO_PADDING_NO_OVERFLOW_WIDE,
} from './modals.config';
import { ISyncedSheetModalPayload, SyncedSheetModalComponent } from './other-tools/synced-sheet-modal/synced-sheet-modal.component';
import { ShelterClusterUserModalShellComponent } from './user-management/cluster-user/shelter-cluster-user/shelter-cluster-user-modal-shell/shelter-cluster-user-modal-shell.component';
import {
  IShelterClusterUserModalShellData,
  TShelterClusterUserModalViewMode,
} from './user-management/cluster-user/shelter-cluster-user/shelter-cluster-user-modals.config';
import {
  IRemoveStudentActivitiesModalComponentData,
  RemoveStudentActivityModalComponent,
} from './student-activities/remove-student-activity/remove-student-activity-modal.component';
import {
  IStudentActivitiesModalComponentData,
  StudentActivitiesModalComponent,
} from './student-activities/student-activities-modal.component';
import {
  IStudentActivityStatusModalComponentData,
  StudentActivityStatusModalComponent,
} from './student-activities/student-activity-status/student-activity-status-modal.component';
import {
  IStudentPathsModalComponentData,
  StudentPathsModalComponent,
} from './student-paths/student-paths-modal.component';
import { StudentReportModalComponent, IStudentReportModalData } from './student-report/student-report.component';
import { StudentSupportModalV2Component } from './student-support/student-support-modal-v2.component';
import {
  IStudentSupportsHistoricalData,
  StudentSupportsHistoricalDataModalComponent,
} from './student-supports-historical-data/student-supports-historical-data-modal.component';
import {
  IAttendanceLogsComponentData,
  SupportAttendanceLogsModalComponent,
} from './support-attendance-logs/support-attendance-logs-modal.component';
import { SupportModalComponent } from './support/support-modal.component';
import { ISupportModalComponentData } from './support/support-modal.interface';
import {
  ISupportStatusModalComponentData,
  SupportStatusModalComponent,
} from './support/support-status-modal.component.ts/support-status-modal.component';
import { ClusterUserModalShellComponent } from './user-management/cluster-user/school-cluster-user/cluster-user-modal-shell/cluster-user-modal-shell.component';
import {
  IClusterUserModalShellData,
  TClusterUserModalViewMode,
} from './user-management/cluster-user/school-cluster-user/cluster-user-modals.config';
import { SchoolUserModalShellComponent } from './user-management/school-user/school-user-modal-shell/school-user-modal-shell.component';
import { ISchoolUserModalShellData, TSchoolUserModalViewMode } from './user-management/school-user/school-user-modals.config';
import { AssignPrepModalComponent } from './master-program/assign-prep-modal/assign-prep-modal.component';
import { RemovePrepModalComponent } from './master-program/remove-prep-modal/remove-prep-modal.component';
import { INotesData, NotesModalShellComponent } from './notes/notes-modal-shell.component';
import { IRegentsNextAdminConflictModalComponentData, RegentsNextAdminConflictModalComponent } from './regents-next-admin-conflict/regents-next-admin-conflict.component';
import { IStudentSelSupportsHistoricalData, StudentSelSupportsHistoricalDataModalComponent } from './student-sel-supports-historical-data/student-sel-supports-historical-data-modal.component';
import { IPostsecDataSourcesModalComponentData, PostsecDataSourcesModalComponent } from './postsec-data-sources/postsec-data-sources-modal.component';
import { IShelterSuccessMentorModalComponentData, ShelterSuccessMentorShellComponent } from './shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.component';
import { IPostsecFinAidModalComponentData, PostsecFinancialAidModalComponent } from './postsec-financial-aid/postsec-financial-aid-modal.component';
import { ExperiencesModalComponent, IExperienceModalData } from './experiences/experiences-modal/experiences-modal.component';
import { BatchEditGroupModalComponent, IBatchEditGroupModal } from './batch-edit-group/batch-edit-group-modal.component';
import {
  GridViewNameModalComponent,
  IGridViewNameModalComponentData,
} from './gridview-name/gridview-name-modal.component';
import { IExperience } from '../typings/interfaces/experiences.interface';
import { FuturePlanModalComponent } from './experiences/experiences-modal/future-plan/future-plan-modal.component';

export interface IBaseModalData {
  studentIds?: string[];
  schoolId?: string;
  isProfileMode?: boolean;
}

export { IStudentReportModalData };

@Injectable()
export class ModalsService {
  constructor (public dialog: MatDialog) {}

  // Mat Dialog Configurations
  readonly MODALS_CONFIG = MODALS_CONFIG;
  readonly MODALS_CONFIG_COMMON_MARKUP = MODALS_CONFIG_COMMON_MARKUP;

  private getModalConfig (data?, modalConfig?): MatDialogConfig {
    const config = modalConfig || this.MODALS_CONFIG;
    return data ? { ...config, ...{ data } } : config;
  }

  private getCommonMarkupModalConfig (data?): MatDialogConfig {
    const config = data ? { ...this.MODALS_CONFIG_COMMON_MARKUP, ...{ data } } : this.MODALS_CONFIG_COMMON_MARKUP;
    return config;
  }

  readonly panelClass = MODALS_CONFIG.panelClass;
  readonly autoFocus = MODALS_CONFIG.autoFocus;
  readonly wideClass = MODALS_CONFIG_WIDE.panelClass;
  readonly extraWideClass = MODALS_CONFIG_EXTRA_WIDE.panelClass;
  readonly medClass = MODALS_CONFIG_MED.panelClass;
  readonly noPaddingMedClass = MODALS_CONFIG_MED_NO_PADDING.panelClass
  readonly noPaddingWideClass = MODALS_CONFIG_WIDE_NO_PADDING.panelClass;
  readonly noPaddingNoOverflowWideClass = MODALS_CONFIG_NO_PADDING_NO_OVERFLOW_WIDE.panelClass;

  public openFlagsLogActionModal (flag?: IFlag): MatDialogRef<FlagLogActionModalComponent> {
    const modalConfig = this.getModalConfig(flag);
    const modalRef = this.dialog.open(FlagLogActionModalComponent, modalConfig);

    return modalRef;
  }

  public openFlagsIgnoreModal (flag: IFlag): MatDialogRef<FlagIgnoreModalComponent> {
    const modalConfig = this.getModalConfig(flag);
    const modalRef = this.dialog.open(FlagIgnoreModalComponent, modalConfig);

    return modalRef;
  }

  public openHistoryModal (data: IHistoryModalData): MatDialogRef<HistoryAllModalComponent> {
    const modalRef = this.dialog.open(HistoryAllModalComponent, {
      panelClass: this.extraWideClass,
      data,
    });

    return modalRef;
  }

  public openAlertModal (data): MatDialogRef<AlertModalcomponent> {
    const modalConfig = this.getModalConfig(data);
    const modalRef = this.dialog.open(AlertModalcomponent, modalConfig);

    return modalRef;
  }

  public openBackgroundJobSpinnerModal (data): MatDialogRef<BackgroundJobSpinnerModal> {
    const baseModalConfigs = this.getModalConfig(data);
    const additionalConfig = { disableClose: true, panelClass: 'nv-mat-dialog-sm' };
    const modalConfig = { ...baseModalConfigs, ...additionalConfig };

    const modalRef = this.dialog.open(BackgroundJobSpinnerModal, modalConfig);
    return modalRef;
  }

  public openCreditGapsCoursePickerModal (data): MatDialogRef<CreditGapsCoursePickerModal> {
    const modalConfig = this.getModalConfig(data, { panelClass: this.noPaddingWideClass });
    const modalRef = this.dialog.open(CreditGapsCoursePickerModal, modalConfig);
    return modalRef;
  }

  public openCreditGapsFreeformCourseModal (data): MatDialogRef<CreditGapsFreeformCourseModal> {
    const modalConfig = this.getModalConfig(data, { panelClass: this.noPaddingMedClass });
    const modalRef = this.dialog.open(CreditGapsFreeformCourseModal, modalConfig);
    return modalRef;
  }

  public openAddCareerModal (data): MatDialogRef<AddCareerModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(AddCareerModalComponent, modalConfig);
    return modalRef;
  }

  public openStudentActivitiesModal (
    data: IStudentActivitiesModalComponentData,
  ): MatDialogRef<StudentActivitiesModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(StudentActivitiesModalComponent, modalConfig);
    return modalRef;
  }

  public openRemoveStudentActivitiesModal (
    data: IRemoveStudentActivitiesModalComponentData,
  ): MatDialogRef<RemoveStudentActivityModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(RemoveStudentActivityModalComponent, modalConfig);
    return modalRef;
  }

  public openActivitiesModal (data: IActivitiesModalComponentData): MatDialogRef<ActivitiesModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(ActivitiesModalComponent, modalConfig);
    return modalRef;
  }

  public openEOPMilestoneModal (data): MatDialogRef<EOPMilestoneModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(EOPMilestoneModalComponent, modalConfig);
    return modalRef;
  }

  public openFinalDecisionMilestoneModal (data): MatDialogRef<FinalDecisionMilestoneModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(FinalDecisionMilestoneModalComponent, modalConfig);
    return modalRef;
  }

  public openBatchEditModal (data?: IBaseModalData): MatDialogRef<BatchEditModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: false };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(BatchEditModalComponent, modalConfig);
    return modalRef;
  }

  public openBatchEditStudentGroupsModal (data?: IBatchEditGroupModal): MatDialogRef<BatchEditGroupModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: false, panelClass: this.medClass };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(BatchEditGroupModalComponent, modalConfig);
    return modalRef;
  }

  public openStudentReportModal (data: IStudentReportModalData): MatDialogRef<StudentReportModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: false, panelClass: this.extraWideClass };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(StudentReportModalComponent, modalConfig);
    return modalRef;
  }

  // Ideally, this modal would return a StudentSupport - however, studentSupports currently live in the v2 store,
  // so the result is the selected support so that we can use `sdcActions#createStudentSupportsAsync` when
  // subscribing to the `afterClosed` event of this modal (CM).
  // redundant for now...

  // the payload interface extends IBaseModalData (directly used by some of the other modal methods in this service - JYR

  public openAssignSupportModal (data: IAssignSupportModalComponentData): MatDialogRef<AssignSupportModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: false };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(AssignSupportModalComponent, modalConfig);
    return modalRef;
  }

  public openEditContactInfoModal (data: IEditContactInfoModalComponentData): MatDialogRef<EditContactInfoModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: false };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(EditContactInfoModalComponent, modalConfig);
    return modalRef;
  }

  public openErrorModal (data: {
    title: string,
    message?: string,
    confirmText?: string
  }): MatDialogRef<ErrorModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: true };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(ErrorModalComponent, modalConfig);
    return modalRef;
  }

  public openConfirmModal (data: IConfirmModalComponentData, mode?: string): MatDialogRef<ConfirmModalComponent> {
    let baseModalConfig = this.getCommonMarkupModalConfig(data);
    if (mode === 'med') {
      baseModalConfig = {
        data,
        panelClass: this.medClass,
      };
    }
    const modalRef = this.dialog.open(ConfirmModalComponent, baseModalConfig);
    return modalRef;
  }

  public openListSummaryModal (data: IListSummaryGrouping): MatDialogRef<ListSummaryModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(ListSummaryModalComponent, modalConfig);
    return modalRef;
  }

  public openStudentPathsModal (data: IStudentPathsModalComponentData): MatDialogRef<StudentPathsModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(StudentPathsModalComponent, modalConfig);
    return modalRef;
  }

  public openActivityStatusModal (data: IActivityStatusModalComponentData): MatDialogRef<ActivityStatusModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(ActivityStatusModalComponent, modalConfig);
    return modalRef;
  }

  public openStudentActivityStatusModal (
    data: IStudentActivityStatusModalComponentData,
  ): MatDialogRef<StudentActivityStatusModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(StudentActivityStatusModalComponent, modalConfig);
    return modalRef;
  }

  public openSupportModal (data: ISupportModalComponentData): MatDialogRef<SupportModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(SupportModalComponent, modalConfig);
    return modalRef;
  }

  public openSupportStatusModal (data: ISupportStatusModalComponentData): MatDialogRef<SupportStatusModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(SupportStatusModalComponent, modalConfig);
    return modalRef;
  }

  public openCsvUploadsErrorModal (data, context?: string): MatDialogRef<CsvUploadErrorModalComponent> {
    const configData = context ? { ...data, context } : data;
    const modalConfig = this.getCommonMarkupModalConfig(configData);
    const modalRef = this.dialog.open(CsvUploadErrorModalComponent, modalConfig);
    return modalRef;
  }

  public openCsvUploadsFixErrorsModal (data: ICollegeListUploadPayload): MatDialogRef<CsvUploadFixErrorsModalComponent> {
    const modalRef = this.dialog.open(CsvUploadFixErrorsModalComponent, {
      panelClass: this.wideClass,
      data,
    });
    return modalRef;
  }

  public openCsvConfirmModal (data: IConfirmUploadModalComponentData): MatDialogRef<CsvUploadConfirmModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(CsvUploadConfirmModalComponent, modalConfig);
    return modalRef;
  }

  public createSyncedSheetModal (data: ISyncedSheetModalPayload): MatDialogRef<SyncedSheetModalComponent> {
    const modalRef = this.dialog.open(SyncedSheetModalComponent, { panelClass: 'nv-mat-dialog-wide-tall', data });
    return modalRef;
  }

  public openClusterUserShellModal (
    data: IClusterUserModalShellData,
    mode: TClusterUserModalViewMode,
  ): MatDialogRef<ClusterUserModalShellComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    if (mode === 'PORTFOLIO') {
      baseModalConfig.panelClass = this.noPaddingWideClass;
    }
    const additionalConfig = { disableClose: true };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(ClusterUserModalShellComponent, modalConfig);
    return modalRef;
  }

  openAttendanceLogsModal (data: IAttendanceLogsComponentData): MatDialogRef<SupportAttendanceLogsModalComponent> {
    const modalRef = this.dialog.open(SupportAttendanceLogsModalComponent, {
      panelClass: this.wideClass,
      data,
    });
    return modalRef;
  }

  openStudentSupportModalV2 (data: IStudentSupport): MatDialogRef<StudentSupportModalV2Component> {
    const modalRef = this.dialog.open(StudentSupportModalV2Component, {
      panelClass: this.panelClass,
      data,
    });
    return modalRef;
  }

  openStudentSupportsHistoricalDataModal (
    data: IStudentSupportsHistoricalData,
  ): MatDialogRef<StudentSupportsHistoricalDataModalComponent> {
    const modalRef = this.dialog.open(StudentSupportsHistoricalDataModalComponent, {
      panelClass: this.wideClass,
      data,
    });
    return modalRef;
  }

  openStudentSelSupportsHistoricalDataModal (
    data: IStudentSelSupportsHistoricalData,
  ): MatDialogRef<StudentSelSupportsHistoricalDataModalComponent> {
    const modalRef = this.dialog.open(StudentSelSupportsHistoricalDataModalComponent, {
      panelClass: this.wideClass,
      data,
    });
    return modalRef;
  }

  public openEndorsementsCredentialsModal (
    data: IEndorsementsCredentialsModalData,
  ): MatDialogRef<EndorsementsCredentialsModalComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const modalConfig = { ...baseModalConfig };
    const modalRef = this.dialog.open(EndorsementsCredentialsModalComponent, modalConfig);
    return modalRef;
  }

  public closeExistingModals () {
    const dialogExists = this.dialog.openDialogs.length > 0;
    if (dialogExists) {
      this.dialog.closeAll();
    }
  }

  public openShelterClusterUserShellModal (
    data: IShelterClusterUserModalShellData,
    mode: TShelterClusterUserModalViewMode,
  ): MatDialogRef<ShelterClusterUserModalShellComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    if (mode === 'PORTFOLIO') {
      baseModalConfig.panelClass = this.noPaddingWideClass;
    }
    const additionalConfig = { disableClose: true };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(ShelterClusterUserModalShellComponent, modalConfig);
    return modalRef;
  }

  public openSchoolUserShellModal (
    data: ISchoolUserModalShellData,
    mode: TSchoolUserModalViewMode,
  ): MatDialogRef<SchoolUserModalShellComponent> {
    const baseModalConfig = this.getCommonMarkupModalConfig(data);
    const additionalConfig = { disableClose: true };
    const modalConfig = { ...baseModalConfig, ...additionalConfig };
    const modalRef = this.dialog.open(SchoolUserModalShellComponent, modalConfig);
    return modalRef;
  }

  public openEditGridColumnsModal (data: IEditGridColumnsModalComponentData) {
    const modalRef = this.dialog.open(EditGridColumnsModalComponent, {
      panelClass: this.noPaddingNoOverflowWideClass,
      data,
    });
    return modalRef;
  }

  public openPostsecDataSourcesModal (data: IPostsecDataSourcesModalComponentData): MatDialogRef<PostsecDataSourcesModalComponent> {
    const modalRef = this.dialog.open(PostsecDataSourcesModalComponent, {
      panelClass: this.wideClass,
      data,
    });
    return modalRef;
  }

  public openPostsecFinancialAidModal (data: IPostsecFinAidModalComponentData): MatDialogRef<PostsecFinancialAidModalComponent>  {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(PostsecFinancialAidModalComponent, modalConfig);
    return modalRef;
  }

  public openExperiencesModal (data?: IExperienceModalData): MatDialogRef<ExperiencesModalComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(ExperiencesModalComponent, modalConfig);
    return modalRef;
  }

  public openFuturePlanModal (data: any) {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(FuturePlanModalComponent, modalConfig);
    return modalRef;
  }

  public openAssignPrepInMasterProgramModal (data) {
    const modalRef = this.dialog.open(AssignPrepModalComponent, {
      panelClass: this.panelClass,
      data,
    });
    return modalRef;
  }

  public openRemovePrepInMasterProgramModal (data) {
    const modalRef = this.dialog.open(RemovePrepModalComponent, {
      panelClass: this.panelClass,
      data,
    });
    return modalRef;
  }

  public openNoteModal (data: INotesData): MatDialogRef<NotesModalShellComponent> {
    const modalRef = this.dialog.open(NotesModalShellComponent, {
      autoFocus: false,
      panelClass: this.panelClass,
      data,
    });
    return modalRef;
  }

  public openLoteSchedulerModal (data: string): MatDialogRef<LoteSchedulerModalComponent> {
    const modalRef = this.dialog.open(LoteSchedulerModalComponent, {
      autoFocus: false,
      panelClass: this.medClass,
      data,
    });
    return modalRef;
  }

  public openRegentsNextAdminConflictModal (data: IRegentsNextAdminConflictModalComponentData): MatDialogRef<RegentsNextAdminConflictModalComponent> {
    const modalRef = this.dialog.open(RegentsNextAdminConflictModalComponent, {
      autoFocus: false,
      panelClass: this.medClass,
      data,
    });
    return modalRef;
  }

  public openShelterStudentSuccessMentorModal (data: IShelterSuccessMentorModalComponentData): MatDialogRef<ShelterSuccessMentorShellComponent> {
    const modalConfig = this.getCommonMarkupModalConfig(data);
    const modalRef = this.dialog.open(ShelterSuccessMentorShellComponent, modalConfig);
    return modalRef;
  }

  public openGridViewNameModal (data: IGridViewNameModalComponentData): MatDialogRef<GridViewNameModalComponent> {
    const modalRef = this.dialog.open(GridViewNameModalComponent, {
      panelClass: this.panelClass,
      data,
    });
    return modalRef;
  }
}
