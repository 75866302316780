import { LoadUsersSuccess } from './../../../../store/actions/users-actions';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { select, Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { getSchool } from 'Src/ng2/store/selectors/school-selectors';
import { catchError, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';

@Injectable()
export class SdcSchoolUsersGuard implements CanActivate {
  constructor (
    private store: Store<any>,
    private apiService: ApiService,
  ) {}

  canActivate (): Observable<boolean> {
    // the following code is pulled in from original school.route.ts allSchoolUsers resolver
    return this.store.pipe(
      select(getSchool),
      switchMap(currentSchool => this.apiService.getUsers(currentSchool._id) as Observable<Array<IUser>>),
      tap((users: IUser[]) => {
        this.store.dispatch(new LoadUsersSuccess(users));
      }),
      take(1),
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  canActivateChild (): Observable<boolean> {
    return this.canActivate();
  }
}
