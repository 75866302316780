import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NvMatTooltipModule } from '../../../../shared/components/nv-mat-tooltip/nv-mat-tooltip.module';
import { ListDisplayModule } from './../list-display.module';
import { ListDisplayActivity } from './list-display-activity.component';

@NgModule({
  declarations: [ListDisplayActivity],
  imports: [CommonModule, ListDisplayModule, NvMatTooltipModule, MatMenuModule, MatIconModule],
  exports: [ListDisplayActivity],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ListDisplayActivityModule {
  /** */
}
