import { Injectable } from '@angular/core';
import { IEditGridViewParams, IGridViewColDef, IGridViewFilter, IGridViewSort } from '../../../typings/interfaces/grid-view.interface';

@Injectable()
export class GraphQLGridViewHelperService {
  constructor () {
    //
  }

  getGridViewsQuery (district, gridType, schoolId, userId): string {
    return `{
      GetGridViewsByUser(district: "${district}", gridType: "${gridType}", schoolId: "${schoolId}", userId: "${userId}") { 
        gridViewId
        gridViewName
        gridViewType
        parentCategory
        order
        columnDefs {
          order
          headerName
          portalLogicId
          field
          masterOrder
          width
          pinned
        }
        filters {
          colId
          values
          filterType
        }
        accessPermissions {
          districts
          gridTypes
          userIds
          schoolIds
        }
        sorts {
          colId
          sort
          sortIndex
        }
        createdAt
        active
        createdBy {
          userId
          gafeEmail
        }
      }
    }`;
  }

  updateGridViewQuery (gridViewParams: IEditGridViewParams): string {
    return this.getGridViewMutationQuery(gridViewParams, 'EDIT');
  }

  deleteGridViewQuery (gridViewParams: IEditGridViewParams): string {
    return this.getGridViewMutationQuery(gridViewParams, 'DELETE');
  }

  createGridViewQuery (gridViewParams: IEditGridViewParams): string {
    return this.getGridViewMutationQuery(gridViewParams, 'CREATE');
  }

  public getGridViewMutationQuery (
    gridViewParams: IEditGridViewParams,
    mode: string,
  ): string {
    const {
      _id,
      gridViewId,
      gridViewName,
      gridViewType,
      columnDefs,
      sorts,
      filters,
      accessPermissions,
    } = gridViewParams;

    let mutationName;
    let options = '';

    switch (mode) {
      case 'CREATE':
        mutationName = 'createGridView';
        break;
      case 'EDIT':
        mutationName = 'updateGridView';
        break;
      case 'REMOVE':
      case 'DELETE':
        mutationName = 'deleteGridView';
        break;
    }

    options = `_id: "${_id}", gridViewId: "${gridViewId}", gridViewName: "${gridViewName}", gridViewType: "${gridViewType}"`;
    if (columnDefs) {
      const columnDefString = this._getColumnDefs(columnDefs);
      options += `, columnDefs: [${columnDefString}]`;
    }

    if (sorts) {
      const sortString = this._getSorts(sorts);
      options += `, sorts: ${sortString}`;
    }

    if (filters) {
      const filterString = this._getFilters(filters);
      options += `, filters: ${filterString}`;
    }

    if (accessPermissions) {
      const accessPermissionsString = this._getAccessPermissions(accessPermissions);
      options += `, accessPermissions: ${accessPermissionsString}`;
    }

    return `
    mutation {
      ${mutationName}(
        options: {${options}}
      ){
        _id,
        gridViewId,
        gridViewName,
        gridViewType,
        columnDefs {
          order,
          headerName,
          portalLogicId,
          field,
          masterOrder,
          width,
          pinned,
        },
        filters {
          colId,
          values,
          filterType
        }
        sorts {
          colId,
          sort,
          sortIndex
        }
        accessPermissions {
          districts,
          gridTypes,
          userIds,
          schoolIds,
        },
        createdBy {
          userId,
          firstName,
          lastName,
          gafeEmail,
        }
      }
    }`;
  }

  _getColumnDefs (colDefs: IGridViewColDef[]): string {
    let columnDefs = '';
    for (const colDef of colDefs) {
      let columnDefString = '{';
      const { order, headerName, portalLogicId, field, masterOrder, width, pinned } = colDef;
      if (field) {
        columnDefString += `field: "${field}"`;
      }
      if (order) {
        columnDefString += `, order: ${order}`;
      }
      if (headerName) {
        columnDefString += `, headerName: "${headerName}"`;
      }
      if (portalLogicId) {
        columnDefString += `, portalLogicId: "${portalLogicId}"`;
      }
      if (masterOrder) {
        columnDefString += `, masterOrder: ${masterOrder}`;
      }
      if (width) {
        columnDefString += `, width: ${width}`;
      }
      if (pinned) {
        columnDefString += `, pinned: "${pinned}"`;
      }
      columnDefString += '},';
      columnDefs += columnDefString;
    }
    return columnDefs.substring(0, columnDefs.length - 1);
  }

  _getSorts (sorts: IGridViewSort[]): string {
    if (sorts?.length > 0) {
      let sortListString = '[';
      for (const sortObj of sorts) {
        let sortString = '{';
        const { colId, sort, sortIndex } = sortObj;
        if (colId) {
          sortString += `colId: "${colId}"`;
        }
        if (sort) {
          sortString += `, sort: "${sort}"`;
        }
        if (sortIndex) {
          sortString += `, sortIndex: ${sortIndex}`;
        }

        sortString += '},';
        sortListString += sortString;
      }
      return sortListString.length > 0 ? sortListString.substring(0, sortListString.length - 1) + ']' : null;
    } else {
      return '[]';
    }
  }

  _getFilters (filters: IGridViewFilter[]): string {
    let filterListString = '[';

    for (const filterObj of filters) {
      let filterString = '{';
      const { colId, filterType, values } = filterObj;
      if (colId) {
        filterString += `colId: "${colId}"`;
      }
      if (values) {
        const valueString = values.map(val => `"${val}"`).join(', ');
        filterString += `, values: [${valueString}]`;
      }
      if (filterType) {
        filterString += `, filterType: "${filterType}"`;
      }

      filterString += '},';
      filterListString += filterString;
    }
    return filterListString + ']';
  }

  _getAccessPermissions (accessPermissions): string {
    const { schoolIds } = accessPermissions;
    // only custom/non-template/non-admin grid views should be saved
    // these grid views are of type=custom and expected to be available only for the current school.
    // hence we will not add district/gridtype info here
    const schoolIdsString = schoolIds.map(schoolId => `"${schoolId}"`).join(', ');
    const accessString = `{ schoolIds: [${schoolIdsString}] }`;
    // TODO: shared custom views will populate userIds shared with  //, userIds: "${userIds}",
    return accessString;
  }
}
