import { IExperienceModalData } from './../../modals/experiences/experiences-modal/experiences-modal.component';
import { IPathData } from './../../typings/interfaces/studentPaths.interface';
import { Router } from '@angular/router';
import { ObjectCache } from './../../services/object-cache/object-cache.service';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, find, includes, map, forEach } from 'lodash';
import { Subject, SubscriptionLike as ISubscription, throwError } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap, filter as rxjsFilter } from 'rxjs/operators';
import { TValidPdfReportKeys, TValidPdfReportNames } from 'Src/ng2/shared/constants/pdf-reports.constant';
import { BatchEditService } from 'Src/ng2/shared/services/batch-edit-service/batch-edit-service';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { IStudentPath } from '../../../shared/typings/interfaces/studentPaths.interface';
import {
  BulkUpdateStudentSupports,
  getBatchActionsSelectedStudentIds,
  getSchool,
  getStudentPathsEntitiesList,
  IBulkCreateStudentSupports,
  IStudentSupportUpdatePayload,
} from '../../../store';
import { ToggleBatchActions, UpdateSelectedStudentIds } from '../../../store/actions/batch-actions-actions';
import { StudentSupportStatuses } from '../../constants/student-support-statuses.constant';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IAssignSupportModalComponentData } from '../../modals/assign-support/assign-support-modal.component';
import { AssignSupportModalService } from '../../modals/assign-support/assign-support-modal.service';
import { IBaseModalData, ModalsService } from '../../modals/modals.service';
import { IRemoveStudentActivitiesModalComponentData } from '../../modals/student-activities/remove-student-activity/remove-student-activity-modal.component';
import { IStudentActivitiesModalComponentData } from '../../modals/student-activities/student-activities-modal.component';
import { IUser } from '../../typings/interfaces/user.interface';
import { TValidStudentPathStatuses } from '../../typings/types/student-path.types';
import {
  BulkCreateStudentPath,
  BulkUpdateStudentPath,
  IStudentPathBulkCreatePayload,
  IStudentPathBulkUpdatePayload,
  LoadAllStudentPaths,
} from './../../../store/actions/student-paths-actions';
import { getAllStudentPathsLoadedStatus } from './../../../store/selectors/student-paths-selector';
import { IStudentPathsModalComponentData } from './../../modals/student-paths/student-paths-modal.component';
import { IBatchEditStudentParams } from './../../services/batch-edit-service/batch-edit-service';
import { ImSchool } from './../../services/im-models/im-school';
import { TValidStudentSupportBackendStatuses } from '../../typings/interfaces/student-support.interface';
import { PATH_CATEGORY_KEYS } from '../../constants/student-paths.constant';
import { SCHOOL_BATCH_ACTIONS, SCHOOL_BATCH_ACTIONS_LABEL, SHELTER_BATCH_ACTIONS, SHELTER_BATCH_ACTIONS_DATA_GRID, SHELTER_BATCH_ACTION_LABEL } from './batch-actions-menu.constants';
import { IShelterSuccessMentorModalComponentData, TValidShelterSuccessMentorModes } from '../../modals/shelter/shelter-success-mentor/shelter-success-mentor-shell/shelter-success-mentor-shell.component';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { NgRouterNavigationService } from 'Src/ng2/school/lists/services/list-navigation/ng-router-list-navigation.service';
import { INotesData } from '../../modals/notes/notes-modal-shell.component';
import { IValidNoteModes, VALID_NOTE_MODES } from '../../typings/interfaces/note.interface';
import { UrlPathService } from '../../services/url-path-service/url-path.service';
import { ApiService } from '../../services/api-service/api-service';
import { BatchActionsEffectsUtilities } from 'Src/ng2/store/utilities/batch-actions-effects-utilities';
import { SnackBarService } from '../../services/snackbar/snackbar.service';
import { BACKGROUND_JOB_STATUS_TYPES, BackgroundJob } from '../../services/background-job/background-job.service';

type TValidBatchActionMenuType = 'side-nav' | 'dropdown';

type IBatchActionMenuType = {
  [key in TValidBatchActionMenuType]: { // eslint-disable-line
    isVisible: boolean;
    sortOrder?: number;
  }
};

export type TBatchActionsOrigin = 'LIST' | 'DATA-GRID' | 'SHELTER-DATA-GRID' | 'PROFILE-PANEL-LIST' | 'GRADUATION-PLAN-TAB';

export enum BatchActionOriginOptions {
  /* eslint-disable */
  LIST = 'LIST',
  DATA_GRID = 'DATA-GRID',
  SHELTER_DATA_GRID = 'SHELTER-DATA-GRID',
  PROFILE_PANEL_LIST = 'PROFILE-PANEL-LIST',
};
interface IBatchActionLink {
  human: string;
  districts: Array<string>;
  tooltip: {
    default: string;
    unique: string;
  };
  tooltipStyle: string;
  key: string;
  userCanAccess: boolean;
  iconStyle?: string;
  type?: string;
  options?: any;
  hsOnly: boolean;
  menuTypes: IBatchActionMenuType;
}
/* istanbul ignore next */
@Component({
  selector: 'batch-actions-menu',
  templateUrl: './batch-actions-menu.html',
  styleUrls: ['./batch-actions-menu.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [BatchEditService],
})
@unsubscribeComponent
export class BatchActionsMenuComponent implements OnInit {
  @Input() userCanEdit: boolean;
  @Input() school: ISchool;
  @Input() currentUser: IUser;
  @Input() mode: TValidBatchActionMenuType = 'side-nav';
  @Input() origin: TBatchActionsOrigin;
  @Input() batchActionsToShow: string[];
  @Input() contextPartnerType: TValidPartnerTypes = PartnerTypes.SCHOOL;
  @Input() shelterId: string;

  public studentIds: string[];
  public countStudents: number = null;
  public schoolId: string;
  public studentPaths: IStudentPath[];
  public batchEditMode: boolean;

  public openBatchEditModalSubscription: ISubscription;
  public selectedStudentIdsSubscription: ISubscription;
  public studentPathsLoadedSubscription: ISubscription;
  public studentPathsSubscription: ISubscription;
  public schoolSubscription: ISubscription;
  public openReportsModalSubscription: ISubscription;
  public openAssignSupportsModalSubscription: ISubscription;
  public openAddCollegeModalSubscription: ISubscription;
  public openUpdateCollegeModalSubscription: ISubscription;
  public updateStudentSupportModalSubscription: ISubscription;
  public studentActivitiesModalSubscription: ISubscription;
  public activitiesModalSubscription: ISubscription;

  public selectedReport: TValidPdfReportKeys;
  public selectedReportName: TValidPdfReportNames;

  public tooltipDefault: string = 'Select one or more students from the list';
  public toolTipDefaultStyle: string = 'batch-action-tooltip';
  public iconStyle: string;
  public batchActions: IBatchActionLink[];
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public tooltipShowDelay: number = 500;
  public tooltipMenuItemShowDelay: number = 350;
  public dropdownLabel: string = 'Edit';
  public dropdownIcon: string = 'edit';

  constructor (
    public modalsService: ModalsService,
    private store: Store<any>,
    private assignSupportModalService: AssignSupportModalService,
    private batchEditService: BatchEditService,
    private imSchool: ImSchool,
    private objectCache: ObjectCache,
    private router: Router,
    private urlPathService: UrlPathService,
    private ngRouterNavigationService: NgRouterNavigationService,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    private backgroundJob: BackgroundJob,
  ) { }

  ngOnInit () {
    const slice$ = this.store.select(getBatchActionsSelectedStudentIds);
    this.selectedStudentIdsSubscription = slice$.pipe(takeUntil(this.destroy$)).subscribe(studentIds => {
      this.studentIds = studentIds;
      this.countStudents = this.studentIds.length;
    });

    this.schoolSubscription = this.store
      .select(getSchool)
      .pipe(take(1))
      .subscribe(({ _id }) => (this.schoolId = _id));

    this.batchActions = this.getBatchActions();
    if (this.origin === BatchActionOriginOptions.SHELTER_DATA_GRID) {
      this.dropdownLabel = 'Success Mentoring';
      this.dropdownIcon = 'person';
    }
  }

  getBatchActions (): IBatchActionLink[] {
    let filteredBatchActions;
    switch (this.contextPartnerType) {
      case PartnerTypes.SHELTER:
        filteredBatchActions = this._getShelterFilteredBatchActions();
        break;
      case PartnerTypes.SCHOOL:
      default:
        filteredBatchActions = this._getSchoolFilteredBatchActions();
        break;
    }
    return filteredBatchActions;
  }

  private _getSchoolFilteredBatchActions (): IBatchActionLink[] {
    const schoolActionsWithEditPermissions = SCHOOL_BATCH_ACTIONS.map(action => {
      return {
        ...action,
        userCanAccess: action.userCanAccess || this.userCanEdit, // Add edit permissions
      };
    });

    const filteredSchoolBatchActions = this._filterSchoolBatchAction(schoolActionsWithEditPermissions);
    const sortedSchoolBatchActions = filteredSchoolBatchActions.sort((a, b) => a.menuTypes[this.mode].sortOrder - b.menuTypes[this.mode].sortOrder);

    return sortedSchoolBatchActions;
  }

  private _getShelterFilteredBatchActions (): IBatchActionLink[] {
    const shelterBatchAction = this.origin === BatchActionOriginOptions.SHELTER_DATA_GRID ? SHELTER_BATCH_ACTIONS_DATA_GRID : SHELTER_BATCH_ACTIONS;
    const shelterActionsWithEditPermissions = shelterBatchAction.map(action => {
      return {
        ...action,
        userCanAccess: action.userCanAccess || this.userCanEdit, // Add edit permissions
      };
    });
    return shelterActionsWithEditPermissions.filter(action => action.userCanAccess);
  }

  private _filterSchoolBatchAction (batchActions: IBatchActionLink[]): IBatchActionLink[] {
    const isHs = this.imSchool.isHighSchool(this.school);

    const filteredBatchActions = batchActions.filter((batchAction: IBatchActionLink) => {
      const validForDistrict = batchAction.districts.includes(this.school.district);
      const visible = batchAction.menuTypes[this.mode].isVisible;
      const allowedToShow = Array.isArray(this.batchActionsToShow)
        ? this.batchActionsToShow.includes(batchAction.human)
        : true;

      let decision = true;
      if ((!validForDistrict)) {
        decision = false;
      } else if (!visible) {
        decision = false;
      } else if (!allowedToShow) {
        decision = false;
      } else if (!isHs) {
        // for batch action menus that are visible and allowed, check the school type as the final step
        decision = !batchAction.hsOnly;
      }
      return decision;
    });

    return filteredBatchActions.filter(action => action.userCanAccess);
  }

  openAction (actionKey: string): void {
    switch (actionKey) {
      case SCHOOL_BATCH_ACTIONS_LABEL.ASSIGN_SUPPORT:
        this.sendToAssignSupportModal();
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.COMPLETE_SUPPORT:
        this.sendToUpdateStudentSupportModal(StudentSupportStatuses.backend.COMPLETED as TValidStudentSupportBackendStatuses);
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.DELETE_SUPPORT_RECORD:
        this.sendToUpdateStudentSupportModal(StudentSupportStatuses.backend.DELETED as TValidStudentSupportBackendStatuses);
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.ADD_COLLEGE:
        this.sendToAddCollegeModal();
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.REMOVE_COLLEGE:
        this.sendToRemoveCollegeModal();
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.UPDATE_STATUS:
        this.sendToUpdateCollegeModal();
        break;
      case 'FIELDS':
        this.sendToBatchEditModal();
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.ADD_NOTES_SCHOOL:
        this.sendToAddNoteModal(PartnerTypes.SCHOOL, VALID_NOTE_MODES.CREATE_BULK_SCHOOL);
        break;
      case 'REPORTS':
        this.sendToStudentReportModal();
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.ADD_EXPERIENCE:
        this.sendToExperienceModal();
        break;
      case SCHOOL_BATCH_ACTIONS_LABEL.VIEW_PROFILES:
        this.sendToStudentProfiles();
        break;
      case SHELTER_BATCH_ACTION_LABEL.VIEW_SHELTER_STUDENT_PROFILES:
        this.sendToShelterStudentProfiles();
        break;
      case SHELTER_BATCH_ACTION_LABEL.ASSIGN_SUCCESS_MENTOR:
        this.sendBatchActionToShelterSuccessMentorModal('ASSIGN');
        break;
      case SHELTER_BATCH_ACTION_LABEL.MARK_COMPLETE_SUCCESS_MENTOR:
        this.sendBatchActionToShelterSuccessMentorModal('MARK_COMPLETE');
        break;
      case SHELTER_BATCH_ACTION_LABEL.REMOVE_SUCCESS_MENTOR:
        this.sendBatchActionToShelterSuccessMentorModal('DELETE');
        break;
      case SHELTER_BATCH_ACTION_LABEL.ADD_NOTES:
        this.sendToAddNoteModal(PartnerTypes.SHELTER, VALID_NOTE_MODES.CREATE_BULK_SHELTER);
        break;
      default:
        return null;
    }
  }

  cancel (): void {
    this.batchEditMode = false;
    this.store.dispatch(new ToggleBatchActions(this.batchEditMode));
    this.store.dispatch(new UpdateSelectedStudentIds([]));
  }

  sendToStudentProfiles (): void {
    const _id = map(this.studentIds, id => `${id}${this.schoolId}`);
    const filter = this.objectCache.cacheObject({ _id });
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/student`);
    this.router.navigate([url], { queryParams: { filter } });
  }

  sendToShelterStudentProfiles (): void {
    const caresIds = this.studentIds;
    const payload = {
      caresIds,
      updateRelPath: `shelter/${this.shelterId}/profile`,
      queryParamsHandling: '',
      route: null,
    };
    this.ngRouterNavigationService.goToProfile(payload);
  }

  /**
   *  TODO: Current design indicates batch actions will trigger 11 modals
   *  Not ideal to have 11 separate modal methods in this component, how can we dry this up?
   */
  sendToAssignSupportModal (): void {
    const data: IAssignSupportModalComponentData = {
      schoolId: this.schoolId,
      studentIds: this.studentIds,
      isProfileMode: false,
    };
    this.openAssignSupportsModalSubscription = this.modalsService
      .openAssignSupportModal(data)
      .afterClosed()
      .subscribe(partialPayload => {
        if (partialPayload) {
          // TODO: THIS IS NOT IDEAL - WE SHOULD NOT BE RECREATING STUDENT _IDS ON THE FRONTEND
          const _ids = map(this.studentIds, id => `${id}${this.schoolId}`);
          if (data) {
            const payload: IBulkCreateStudentSupports = {
              ...partialPayload,
              studentIds: _ids,
              origin: this.origin,
            };
            this.assignSupportModalService.bulkAssignStudentSupport(payload);
          }
        }
      });
  }

  sendToUpdateStudentSupportModal (status: TValidStudentSupportBackendStatuses): void {
    const data: IAssignSupportModalComponentData = {
      schoolId: this.schoolId,
      studentIds: this.studentIds,
      isProfileMode: false,
      isEditMode: true,
      status,
    };

    this.updateStudentSupportModalSubscription = this.modalsService
      .openAssignSupportModal(data)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          const payload: IStudentSupportUpdatePayload = {
            studentSupportIds: res.studentSupportIds,
            status,
            origin: this.origin,
          };
          this.store.dispatch(new BulkUpdateStudentSupports(payload));
        }
      });
  }

  sendToBatchEditModal (): void {
    const baseModalData: IBaseModalData = {
      studentIds: this.studentIds,
      isProfileMode: false,
    };
    this.openBatchEditModalSubscription = this.modalsService
      .openBatchEditModal(baseModalData)
      .afterClosed()
      .pipe(
        tap((res: IBatchEditStudentParams['patch']) => {
          const studentIds = map(this.studentIds, id => `${id}${this.schoolId}`);
          const params: IBatchEditStudentParams = {
            studentIds,
            schoolId: this.schoolId,
            patch: res,
            destroy$: this.destroy$,
            origin: this.origin,
          };
          if (res) this.batchEditService.batchEditStudents(params);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

  sendToStudentReportModal (): void {
    // TODO: THIS IS NOT IDEAL - WE SHOULD NOT BE RECREATING STUDENT _IDS ON THE FRONTEND
    const students = map(
      this.studentIds,
      (studentId) => ({
        _id: `${studentId}${this.schoolId}`,
        studentId,
      }),
    );
    const data = { students, isProfileMode: false };
    this.openReportsModalSubscription = this.modalsService
      .openStudentReportModal(data)
      .afterClosed()
      .subscribe(data => {
        if (data) {
          this.batchEditMode = true;
          this.store.dispatch(new ToggleBatchActions(this.batchEditMode));
        }
      });
  }

  sendToAddCollegeModal (): void {
    const data: IStudentPathsModalComponentData = {
      schoolId: this.schoolId,
      studentIds: this.studentIds,
      isProfileMode: false,
      mode: 'ADD',
    };
    this.openAddCollegeModalSubscription = this.modalsService
      .openStudentPathsModal(data)
      .afterClosed()
      .subscribe((pathData: IPathData) => {
        if (pathData && pathData.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
          const _ids = map(this.studentIds, id => `${id}${this.schoolId}`);
          const payload: IStudentPathBulkCreatePayload = {
            schoolId: this.schoolId,
            studentIds: _ids,
            college: pathData.path.college,
            origin: this.origin,
          };
          // dispatch action to bulk create student paths
          this.store.dispatch(new BulkCreateStudentPath(payload));
        }
      });
  }

  sendToRemoveCollegeModal (): void {
    // make sure studentPaths are loaded on the store
    this.store
      .select(getAllStudentPathsLoadedStatus)
      .pipe(take(1))
      .subscribe(allLoaded => {
        if (!allLoaded) this.store.dispatch(new LoadAllStudentPaths({ schoolId: this.schoolId }));
      });

    const data: IStudentPathsModalComponentData = {
      schoolId: this.schoolId,
      studentIds: this.studentIds,
      isProfileMode: false,
      mode: 'REMOVE',
    };
    const _ids = map(this.studentIds, id => `${id}${this.schoolId}`);

    this.openUpdateCollegeModalSubscription = this.modalsService
      .openStudentPathsModal(data)
      .afterClosed()
      .subscribe((pathData: IPathData) => {
        if (pathData) {
          this.studentPathsSubscription = this.store
            .select(getStudentPathsEntitiesList)
            .pipe(take(1))
            .subscribe(studentPaths => {
              // only keep studentPaths for currently selected students (JE)
              this.studentPaths = filter(studentPaths, (studentPath: IStudentPath) => {
                return includes(_ids, studentPath.studentId);
              });
            });

          // find studentPathIds - if no studentPath exists for a student/college path, no patch will be sent for that student (JE)
          const matchedStudentPathIds: string[] = map(_ids, _id => {
            const matchedPath = find(this.studentPaths, (studentPath: IStudentPath) => {
              if (
                studentPath.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE &&
                pathData.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE
              ) {
                const { path: { college } } = pathData;
                return studentPath.path.college === college && studentPath.studentId === _id;
              }
            });
            return matchedPath ? matchedPath._id : null;
          });

          const studentPathIds = filter(matchedStudentPathIds, id => !!id);
          if (studentPathIds.length) {
            const payload: IStudentPathBulkUpdatePayload = {
              schoolId: this.schoolId,
              studentPathIds,
              status: 'DELETED' as TValidStudentPathStatuses,
              origin: this.origin,
            };
            // dispatch action to bulk remove student paths
            this.store.dispatch(new BulkUpdateStudentPath(payload));
          }
        }
      });
  }

  sendToUpdateCollegeModal (): void {
    // make sure studentPaths are loaded on the store
    this.store
      .select(getAllStudentPathsLoadedStatus)
      .pipe(take(1))
      .subscribe(allLoaded => {
        if (!allLoaded) this.store.dispatch(new LoadAllStudentPaths({ schoolId: this.schoolId }));
      });
    const data: IStudentPathsModalComponentData = {
      schoolId: this.schoolId,
      studentIds: this.studentIds,
      isProfileMode: false,
      mode: 'UPDATE',
    };
    const _ids = map(this.studentIds, id => `${id}${this.schoolId}`);

    this.openUpdateCollegeModalSubscription = this.modalsService
      .openStudentPathsModal(data)
      .afterClosed()
      .subscribe((pathData: IPathData) => {
        if (pathData) {
          this.studentPathsSubscription = this.store
            .select(getStudentPathsEntitiesList)
            .pipe(take(1))
            .subscribe(studentPaths => {
              // only keep studentPaths for currently selected students (JE)
              this.studentPaths = filter(studentPaths, (studentPath: IStudentPath) => {
                return includes(_ids, studentPath.studentId);
              });
            });
          if (pathData.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
            const { path: { college }, status } = pathData;
            this._handleUpdateCollege({ college, status }, _ids);
          }
        }
      });
  }

  _handleUpdateCollege (data: { college: string; status: TValidStudentPathStatuses }, _ids: string[]): void {
    const { college, status } = data;
    // find studentPaths that already exist for selected students
    const studentPathIds = [];
    const studentIdsWithoutPath = [];
    forEach(_ids, id => {
      const matchedPath = find(this.studentPaths, (studentPath: IStudentPath) => {
        if (studentPath.path.pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
          return studentPath.path.college === college && studentPath.studentId === id;
        }
      });
      // if matchedPath.status === selected status, don't patch that student
      if (matchedPath && matchedPath.status !== status) studentPathIds.push(matchedPath._id);
      else if (!matchedPath) studentIdsWithoutPath.push(id);
    });

    if (status === 'DELETED' && studentPathIds.length) {
      const payload: IStudentPathBulkUpdatePayload = {
        schoolId: this.schoolId,
        studentPathIds,
        status,
        origin: this.origin,
      };
      // dispatch action to bulk remove student paths
      this.store.dispatch(new BulkUpdateStudentPath(payload));
    } else {
      // payload includes studentIds for students who do not already have matching studentPath
      const payload: IStudentPathBulkUpdatePayload = {
        schoolId: this.schoolId,
        studentPathIds,
        status,
        studentIds: null,
        college,
        origin: this.origin,
      };
      if (studentIdsWithoutPath.length) payload.studentIds = studentIdsWithoutPath;

      // only dispatch update if some students do not already have this path and status (JE)
      if (studentIdsWithoutPath.length || studentPathIds.length) { this.store.dispatch(new BulkUpdateStudentPath(payload)); }
    }
  }

  sendBatchActionToShelterSuccessMentorModal (mode: TValidShelterSuccessMentorModes): void {
    const data: IShelterSuccessMentorModalComponentData = {
      caresIds: this.studentIds, // for shelter students we use caresIds
      shelterId: this.shelterId,
      origin: this.origin,
      mode,
    };

    this.modalsService.openShelterStudentSuccessMentorModal(data);
  }

  sendToAddNoteModal (partnerType: TValidPartnerTypes, mode: IValidNoteModes): void {
    const _ids = map(this.studentIds, id => `${id}${this.schoolId}`);
    const data: INotesData = {
      partnerType: this.contextPartnerType,
      shelterId: this.shelterId,
      currentUser: this.currentUser,
      school: this.school,
      mode,
    };

    // for shelter students studentIds will be caresIds
    const idsForStudentType = (partnerType === PartnerTypes.SCHOOL) ? { studentIds: _ids } : { caresIds: this.studentIds };
    Object.assign(data, idsForStudentType);

    this.modalsService.openNoteModal(data);
  }

  sendToExperienceModal (): void {
    const _ids = this.studentIds.map(studentId => `${studentId}${this.schoolId}`);
    const data: IExperienceModalData = {
      batchAction: {
        schoolId: this.schoolId,
        studentIds: _ids,
      },
      isProfileMode: false,
    }

    this.modalsService.openExperiencesModal(data)
      .afterClosed()
      .pipe(
        switchMap(event => {
          if (event) {
            const payload = {
              ...data.batchAction,
              ...event,
            };
            return this.apiService.bulkCreateExperiences$(payload);
          }
        }),
        switchMap(response => {
          const subject = BatchActionsEffectsUtilities.getJobSubjectFromGraphql(this.backgroundJob, { response, queryName: 'bulkCreateExperience' });
          this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Creating experiences' });
          return subject;
        }),
        // wait for brj to resolve before showing success toast        
        rxjsFilter(({ type }) => type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE),
        tap(() => BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true }),
        catchError((err: any) => {
          BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: false });
          return throwError(err);
        })),
      ).subscribe();
  }
}
