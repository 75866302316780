/* eslint-disable quotes */
import { Component} from '@angular/core';
import { INoRowsOverlayAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-no-rows-overlay',
  templateUrl: './no-rows.component.html',
  styleUrls: ['./no-rows.component.scss'],
})

export class NoRowsComponent implements INoRowsOverlayAngularComp {
  public params: any;
  public showButton: boolean = true;
  public messageDivClass: string;
  public primaryEmptyStateHeader: string;
  public secondaryEmptyStateMessage: string;
  public secondaryMessageClass: string;
  public additionalTextMessage: string;
  public backgroundImage: string;

  agInit (params: any): void {
    this.params = params;
    let toggleState;
    let parentToggleOptions;
    let hasAppliedFilters;
    if (this.params.parentName === 'history-all-modal') {
      parentToggleOptions = this.params.parentComponent.options;
      toggleState = parentToggleOptions[this.params.parentComponent.listIndex];
      hasAppliedFilters = this.params.parentComponent.hasAppliedFilters;
      if (!hasAppliedFilters) this.showButton = false;
    }
    this._getNoRowsMessage({ parentName: this.params.parentName, toggleState, hasAppliedFilters });
    this._getEmptyMessageClass(this.params.parentName);
    this.backgroundImage = this.params.backgroundImage || 'no-rows-default';
  }

  private _getNoRowsMessage ({
    parentName,
    toggleState,
    hasAppliedFilters,
  }: {
    parentName: string;
    toggleState?: string;
    hasAppliedFilters?: boolean;
  }): void {
    if (parentName === 'history-all-modal') {
      const isAllLogsView = toggleState === 'All logs';
      this._setHistoryLogsMessage({ hasAppliedFilters, isAllLogsView });
    } else if (parentName === 'grid-groups') {
      const { grandparentComponent } = this.params;
      this.showButton = false;
      this.primaryEmptyStateHeader = grandparentComponent.primaryEmptyStateHeader;
      this.secondaryEmptyStateMessage = grandparentComponent.secondaryEmptyStateMessage;
      this.additionalTextMessage = grandparentComponent.additionalTextMessage;
    } else {
      this.primaryEmptyStateHeader = 'No results found';
      this.secondaryEmptyStateMessage =
        'There are no results found matching this filter combination. Remove the last filter added or clear all filters to start your search again.';
    }
  }

  private _setHistoryLogsMessage ({ hasAppliedFilters, isAllLogsView } : { hasAppliedFilters: boolean, isAllLogsView: boolean }) : void {
    let logName: string;
    if (isAllLogsView) logName = 'history logs';
    else logName = 'notes';

    // primary message
    if (hasAppliedFilters) this.primaryEmptyStateHeader = `No ${logName} available with selected filters`;
    else this.primaryEmptyStateHeader = `No ${logName} available`;

    // secondary message
    if (hasAppliedFilters) {
      this.secondaryEmptyStateMessage = isAllLogsView
        ? 'There are no history logs available for this student that match these filters. To see logs, remove some filters.'
        : 'There are no notes logged for this student that match these filters. To see notes, remove some filters.';
    } else {
      this.secondaryEmptyStateMessage = isAllLogsView
        ? 'There are no history logs available for this student. To see logs, create an action for this student, such as adding this student to a support. To see notes, add a note for this student.'
        : "There are no notes logged for this student yet. When a note is added, it will appear here. To keep track of important details for this student, add a note by clicking 'Add Note'.";
    }
  }

  private _getEmptyMessageClass (parentName): void {
    this.messageDivClass = 'text-message-main';
    this.secondaryMessageClass = 'short-secondary-message';
    if (parentName === 'history-all-modal') {
      this.messageDivClass = 'text-message-wide';
      this.secondaryMessageClass = 'long-secondary-message';
    }
  }

  public onClearAllFilters () {
    if (this.params?.parentName === "network-foci") {
      this.params?.parentComponent?.onClearFilters(this.params?.api?.gridOptionsWrapper?.gridOptions);
    } else {
      this.params?.parentComponent?.onClearFilters();
    }
  }
}
