import { Injectable } from '@angular/core';
import { IStudentActivity } from '../../typings/interfaces/student-activity.interface';
import { DateHelpers } from '../date-helpers/date-helpers.service';

@Injectable()
export class ImStudentActivity {
  constructor(public dateHelpers: DateHelpers) {
    // empty block
  }

  getStudentActivityTitle(studentActivity: IStudentActivity): string {
    const {
      activity: {
        sector,
        type,
        partnerOrg: { name },
      },
      startDate,
      endDate,
    } = studentActivity;
    const start = this.dateHelpers.getFormattedMoment(startDate, 'MMM D');
    const startDates = start.split(' ');
    const end = this.dateHelpers.getFormattedMoment(endDate, 'MMM D');
    const endDates = end.split(' ');
    let computedDate;
    if (start === end) computedDate = `${startDates[0]} ${startDates[1]}`;
    else if (startDates[0] === endDates[0]) computedDate = `${startDates[0]} ${startDates[1]} - ${endDates[1]}`;
    else computedDate = `${startDates[0]} ${startDates[1]} - ${endDates[0]} ${endDates[1]}`;
    if (sector) return `${type} at ${name}, ${sector}, ${computedDate}`;
    else return `${type} at ${name}, ${computedDate}`;
  }
}
