import { Component, HostBinding, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';

/**
 *
 * Use the Note component to highlight a piece of text in a UI.
 *
 * Pass any HTML into the note as you would a `<span>` element
 */
@Component({
  selector: 'nv-note',
  templateUrl: './nv-note.component.html',
  styleUrls: ['./nv-note.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvNoteComponent {
  /**
   *
   * A small note is inline—typically for one sentence.
   *
   * A large note spans the full width (`block`)—typically for more than on sentence.
   *
   */
  @Input() size: 'small' | 'large' = 'small';

  /**
   *
   * Info notes are blue.
   * Warning notes are yellow.
   * Danger notes are red.
   *
   */
  @Input() priority: 'info' | 'warning' | 'danger' = 'info';

  @HostBinding('class') classString = '';

  get iconName () {
    switch (this.priority) {
      case 'warning':
        return 'exclamation-yellow';
      case 'danger':
        return 'exclamation-red';
      default:
        return 'info';
    }
  }

  ngOnChanges (changes: SimpleChanges): void {
    if (changes.size || changes.priority) {
      this.classString = `${this.size} ${this.priority}`;
    }
  }
}
