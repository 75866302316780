import { IStudentGroupingsPayload } from '../../actions/student-groupings-actions/student-groupings-actions';

// @ts-ignore
export const generateQuery = ({ payload }: { payload: IStudentGroupingsPayload }) => {
  const { schoolId, filterKeys, groupingKey, columns } = payload;

  const columnKeys: string[] = columns.map(({ graphQlKey }) => graphQlKey);
  // TODO: handle wildcard in groupingKey
  const filterKeysArray: string[] = filterKeys.reduce((accum: string[], key: string) => {
    if (key.includes('="')) {
      // Escape the wildcard value passed from filter dropdown, in addition to the one from rollup table
      const filterVal = key.split('=');
      const filterString = filterVal[1].replace(/\"/g, '\\"');
      accum.push(`"${filterVal[0]}=${filterString}"`);
    } else {
      accum.push(`"${key}"`);
    }
    return accum;
  }, []);

  return `{
    StudentGroupings(schoolId: "${schoolId}", filterKeys: [${filterKeysArray}], groupingKey: "${groupingKey}", columns: ${JSON.stringify(
    columnKeys,
  )}){
        key
        human
        tooltip
        rowData {
          data
          meta
          columnKey
        }
    }
  }
  `;
};

export const STUDENT_GROUPINGS_JOINS = [];
