export const SYNC_ENABLED_DOMAINS = [
  'newvisions.org',
  'datatools.newvisions.org',
  'dgafe.newvisions.org',
  'schools.nyc.gov',
  'mynycschool.org',
  'strongschools.nyc',
  'communityschools.nyc',
  'claremontihs.org',
  'crotonaihs.org',
  'ihs-us.org',
  '24q236.gsuite.nyc',
  '22k152.gsuite.nyc',
  '09X229.org',
  '10x80.com',
  '11x178.com',
  '11x370.com',
  '12x217.org',
  '19k306.org',
  '19k328.org',
  '22k315.org',
  '152evergreen.com',
  '41gunhillroad.org',
  '127knights.org',
  '301msdunbar.org',
  '498vannestacademy.org',
  'academyhc.org',
  'academyforcollegeprep.me',
  'actvf.org',
  'aelnyc.org',
  'aesmithhs.org',
  'afsenyc.org',
  'afyw.org',
  'amsbronx.org',
  'aoiths.org',
  'appabx.org',
  'urbanassembly.org',
  'archimedesacademy.org',
  'astorcollegiate.org',
  'augustmartin.org',
  'aupnyc.org',
  'automotivehs.org',
  'bammnyc.org',
  'basehighschool.org',
  'baysidehighschool.org',
  'bba578.org',
  'bcamhs.org',
  'bccprep.com',
  'beaconschool.org',
  'belmontprephs.org',
  'benfranklin055.org',
  'betahighschool.org',
  'bhsva418.com',
  'bhswca.com',
  'bkagf.org',
  'bkmusicntheatre.com',
  'bklawtech.com',
  'bla2hs.org',
  'blueprintms.org',
  'boysandgirlshs.org',
  'bronxarena.org',
  'bronxaerospacehs.org',
  'bronxcommunityhighschool.org',
  'bronxdalehs.org',
  'bronxdelta.org',
  'bronxenvision.org',
  'bronxearlycollegeacademy.org',
  'bronxhaven.org',
  'bronxhsbusiness.org',
  'bronxlatin.com',
  'bronxlawhs.org',
  'bronxlgj.org',
  'bronxmath.org',
  'bronxparkms.info',
  'bronxriverhs.org',
  'bronxtheatrehighschool.org',
  'bronxwritingacademy.net',
  'brooklyncollegiate.net',
  'brooklyndemocracy.org',
  'brooklyngeneration.org',
  'brooklynlabschool.org',
  'brooklynlandmark.org',
  'brooklynprephs.org',
  'brownsvilleacademy.com',
  'bronxwoodprep.com',
  'bsmartnow.com',
  'bssjbrooklyn.com',
  'bsswa.org',
  'bushwickleaders.org',
  'bxcollegiate.org',
  'bxcsm.org',
  'bxletters.org',
  'bxlgj.org',
  'bxslf.org',
  'casa189.org',
  'charter.newvisions.org',
  'chcprep.net',
  'cimsnyc.org',
  'cityas.org',
  'clanyc.org',
  'clintonhs.net',
  'cls335brooklyn.org',
  'cpe-hs.org',
  'cs111q.org',
  'cs112bronxwood.org',
  'dreamyard.com',
  'digitaltechhs.org',
  'earwshs.net',
  'eastbronxacademy.org',
  'eastflatbushcrs.org',
  'eastfordham.org',
  'ebchighschool.org',
  'efms352.org',
  'emolior.net',
  'energytechhs.org',
  'enymse.org',
  'envirostudies.org',
  'epaschool.org',
  'ephsinstrctn.org',
  'eschs.org',
  'espnyc.com',
  'ewsis.org',
  'fannielou.org',
  'fda2.net',
  'fda7.org',
  'fdaiii.com',
  'fdavms.org',
  'flhfhs.org',
  'flushinghighschool.org',
  'flushinginternational.org',
  'fmhsnyc.org',
  'fordhamleadership.org',
  'foresthillshs.org',
  'forysthsatellite.net',
  'fthamiltonhs.org',
  'gjs284.org',
  'gchs.nyc',
  'goddardhs308.org',
  'herohigh.net',
  'highbridgegreen.com',
  'hillcresths.org',
  'hillsidearts.org',
  'hopschool.com',
  'hsartstech.org',
  'hseigators.org',
  'hsforteaching.org',
  'hsfi.us',
  'hshcs.nyc',
  'hsleaps.org',
  'hsmcnyc.org',
  'hsps.us',
  'hsvd.org',
  'hs-gc.org',
  'hsslnyc.org',
  'hstat.org',
  'htlc154.org',
  'hudsonhs.com',
  'iam-hs.org',
  'ichsbronx.org',
  'innovationdp.org',
  'in-techacademy.org',
  'internationalsnetwork.org',
  'internationalmiddle593.org',
  'inwoodearlycollege.org',
  '​ihpch.org',
  'is68.org',
  'is123x.org',
  'is584x.org',
  'is254.com',
  'is291.org',
  'is313.org',
  'is339.org',
  'is349.org',
  'islaschool.com',
  'jamaicagateway.net',
  'jctsmail.com',
  'jdhyatt.org',
  'jhs117.org',
  'johnadamsnyc.org',
  'juanmorelcampos.com',
  'k156.org',
  'k497.org',
  'kappaiii.school',
  'kappainternational.org',
  'khalilgibranhs.org',
  'kurthahnschool.org',
  'landmarkhs.org',
  'languageandinnovation.org',
  'lehmanhs.com',
  'lichs.org',
  'lindentree567.com',
  'longwoodprep.org',
  'lucero311.org',
  'lyonscommunityschool.org',
  'm528.org',
  'macsx297.org',
  'manhattanbridgeshs.org',
  'masths.org',
  'matherhsnyc.org',
  'mchsbronx.org',
  'mckinneyssa.com',
  'mcndhs.com',
  'marblehillschool.org',
  'metrodphs.com',
  'metropolitanhs.org',
  'mha65.org',
  'mhbx.org',
  'mhcsbronx.org',
  'mhs.nyc',
  'mhsbk.org',
  'mhvpschool.com',
  'middleschool219.org',
  'millenniumhs.org',
  'mottjhs.org',
  'motthall3.com',
  'motthallv.org',
  'ms126magnet.org',
  'ms224bx.org',
  'ms331.com',
  'ms363aple.org',
  'ms50communityschool.org',
  'ms45.org',
  'ms53.org',
  'ms302.net',
  'ms337.org',
  'ms582.org',
  'ms72q.org',
  'ms8q.org',
  'ms223.org',
  'mshs223.org',
  'msta318.org',
  'mvbhigh.com',
  'mybahc.org',
  'mysbchs.org',
  'mychah.org',
  'mycves.org',
  'myhsca.org',
  'mynjp226.org',
  'ndssonline.org',
  'newcomershs.org',
  'newdesignhigh.com',
  'newdorphs.org',
  'newheightsms.org',
  'newyorkharborschool.org',
  'newpathwaysacademy.org',
  'nmbamiddleschool.org',
  'northbronxpower.org',
  'nqchs.org',
  'nycacademy.org',
  'nycboss.org',
  'nychscl.org',
  'nycoutwardbound.org',
  'olympusacademy.org',
  'orchardcollegiateacademy.org',
  'originshighschool.org',
  'panamericanihs.org',
  'paihsmonroe.org',
  'parkeasths.org',
  'pathwaysprep.org',
  'paulo75.org',
  'pgms566.org',
  'portrichmondhs.org',
  'ps062.org',
  'ps123.org',
  'ps132m.org',
  'ps15m.org',
  'ps160.org',
  'ps165k.org',
  'ps506k.org',
  'ps005m.org',
  'ps142m.org',
  'ps194m.com',
  'ps197q.org',
  'ps204.org',
  'ps241k.org',
  'ps241stem.com',
  'ps298.org',
  'ps32belmont.com',
  'ps29bengals.net',
  'ps36mds.org',
  'ps503online.org',
  'ps19q.org',
  'ps52q.org',
  'ps19r.com',
  'ps78r.org',
  'ps24school.org',
  'ps138school.com',
  'ps18x.com',
  'ps46x.org',
  'ps94x.org',
  'ps100x.org',
  'ps163x.com',
  'ps54.org ',
  'ps83.org',
  'ps87.org',
  'ps85bronx.org',
  'ps291bronx.org',
  'ps96act.com',
  'psms15.org',
  'psms20.org',
  'psms95x.org',
  'psms161.org',
  'psms308.org',
  'psms42q.org',
  'q2l.org',
  'qhss.org',
  'qirtnyc.org',
  'qshso.com',
  'queensacademy.net',
  'queenscollegiate.org',
  'queensgateway.org',
  'queensmetro.com',
  'queenstechhs.org',
  'renaissancemtt.org',
  'rfwagner.org',
  'richmondhillhs.org',
  'rockawaycollegiatehs.com',
  'rockawayparkhs.net',
  'rsam377.org',
  'sbaam296.org',
  'scholarsnyc.com',
  '​schoolforexcellence.org',
  'sccsbrooklyn.org',
  'shronline.org​',
  'sljhs.org',
  'sof.edu',
  'sojournertruthschool.org',
  'soundviewacademy.org',
  'stocktonbulldogs.org',
  'sunsetparkhighschool.org',
  'taehs.org',
  'tapconyc.org',
  'taps391.org',
  'tcs-nyc.org',
  'tcanyc.com',
  'teachingmatters.org',
  'thebenningtonschool.com',
  'theclaremontschool.org',
  'theclintonschool.net',
  'thedorseyschool.com',
  'theglcnyc.org',
  'theheritageschoolnyc.com',
  'tmanyc.org',
  'transittechHS.org',
  'tywls-astoria.org',
  'tywls.org',
  'tywlsbronx.org',
  'tywlsbrooklyn.org',
  'tywlsqueens.org',
  'uaagl.org',
  'uafutureleaders.com',
  'uagateway.org',
  'uaglobalcommerce.org',
  'uagreencareers.org',
  'uainstitute.org',
  'uamaker.nyc',
  'uamhighschool.org',
  'uaperformingarts.org',
  'uasbyw.net',
  'uasch.org',
  'uascriminaljustice.org',
  'uasdc.org',
  'uasem.org',
  'uaunisonschool.org',
  'uim371.org',
  'unionsquareacademy.org',
  'unmslearns.net',
  'urbanscholarscs.org',
  'validusprep.org',
  'victorycollegiate.org',
  'voyagesprep.org',
  'wadleigharts.org',
  'wadleighsecondary.org',
  'watchnyc.org',
  'westbronxacademy.org',
  'westchestersquare.org',
  'westinghousehs.org',
  'wheelsnyc.org',
  'whsat.org',
  'wingsnation.org',
  'williamsburgprep.org',
  'wjps.org',
  'worldviewhs.org',
  'wwms246.school',
  'ycdhs.org',
];
