import { IUserMini } from './user.interface';

export interface IAssessmentItem {
  section: string;
  validValues: string[];
}

export interface IAssessmentMini {
  assessmentId: string;
  assessmentTitle: string;
  assessmentNickName: string;
  items: IAssessmentItem[];
}

export interface ISchoolAssessment {
  _id: string;
  schoolId: string;
  adminNumber: number;
  startDate?: string;
  endDate?: string;
  gradeLevels: string[];
  assessment: IAssessmentMini;
  status: 'OPEN' | 'LOCKED' | 'DELETED';
  schoolYear: string;
  lastEditedBy: IUserMini;
  lastEditedOn: string;
}

export const SCHOOL_ASSESSMENTS_KEY_MAP = {
  'F & P': 'FOUNTAS_AND_PINNELL',
};

export type TValidSchoolAssessmentKey = 'FOUNTAS_AND_PINNELL';
