import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BatchActionOriginOptions } from 'Src/ng2/shared/components/batch-actions-menu/batch-actions-menu.component';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { IColumn, IRowData } from 'Src/ng2/shared/models/list-models';
import { SuccessMentoringPanelService } from '../success-mentoring-panel.service';
import { IDropdownOption } from './../../../../../shared/typings/interfaces/design-library.interface';

export type TSuccessMentoringMode = 'EDIT' | 'MARK_COMPLETE' | 'DELETE';
interface IKebabOptions {
  human: string;
  action: TSuccessMentoringMode;
}

export const SUCCESS_MENTORING_MENU_OPTIONS: { [key: string]: IKebabOptions } = {
  EDIT: {
    human: 'Edit mentor record',
    action: 'EDIT',
  },
  COMPLETE: {
    human: 'Mark complete',
    action: 'MARK_COMPLETE',
  },
  DELETE: {
    human: 'Delete mentor record',
    action: 'DELETE',
  },
};
const { COMPLETE, EDIT, DELETE } = SUCCESS_MENTORING_MENU_OPTIONS;

@Component({
  selector: 'success-mentoring-options-kebab',
  templateUrl: './success-mentoring-options-kebab.component.html',
  styleUrls: ['./success-mentoring-options-kebab.component.scss'],
})

export class SuccessMentoringOptionsKebabComponent implements OnInit {
  constructor (
    private modalsService: ModalsService,
    public successMentoringDataService: SuccessMentoringPanelService,
  ) {}

  @Input() input: { row: IRowData[]; column: IColumn; dynamicComponentInputData?: any };
  @Output() output = new EventEmitter();

  private shelterId: string;
  private studentId: string;
  private studentName: string;
  private successMentorName: string

  public options: IDropdownOption[];
  public overlapTrigger: boolean = true;

  public ngOnInit (): void {
    const { row, dynamicComponentInputData: { shelterId, studentId, studentName, successMentorName } } = this.input;

    this.shelterId = shelterId;
    this.studentId = studentId[0];
    this.studentName = studentName;
    this.successMentorName = successMentorName;

    if (row && row.length) {
      this.options = this._initDropdownOptions();
    }
  }

  private _initDropdownOptions (): IDropdownOption[] {
    const options = [
      { key: EDIT.action, human: EDIT.human },
      { key: COMPLETE.action, human: COMPLETE.human },
      { key: DELETE.action, human: DELETE.human },
    ];
    return options;
  }

  public sendToShelterSuccessMentorModal(action: TSuccessMentoringMode): void {
    const data = {
      caresIds: [this.studentId],
      shelterId: this.shelterId,
      origin: BatchActionOriginOptions.PROFILE_PANEL_LIST,
      mode: action,
      studentName: this.studentName, 
      successMentorName: this.successMentorName,
    };
    this.modalsService.openShelterStudentSuccessMentorModal(data)
    .afterClosed()
      .subscribe(res => {
        if (res) {
          this.successMentoringDataService.updateCurrentStudentEvent.emit(true);
          this.successMentoringDataService.showToast(res, 'This student was updated.');
          this.successMentoringDataService.sendMessage({ updated: true }); // send message to fetch doc logs
        }
      });
  }

  public onSelect (e: string): void {
    let action: TSuccessMentoringMode;
    switch (e) {
      case EDIT.action: {
        action = EDIT.action;
        break;
      }
      case COMPLETE.action: {
        action = COMPLETE.action;
        break;
      }
      case DELETE.action: {
        action = DELETE.action;
        break;
      }
    }
    this.sendToShelterSuccessMentorModal(action);
  }
}
