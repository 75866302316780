import { IDropdownOption } from '../typings/interfaces/design-library.interface';
import { snakeCase } from 'lodash';

export type TValidPdfReportNames =
  | 'Student Snapshot'
  | 'Regents Plan & Prep Report'
  | 'EMS Assessment Report'
  | 'HS Assessment Report'
  | 'Attendance Report'
  | 'Academic Report'
  | 'DESSA (SEL) Report'
  | 'Mock Regents Report';

type TTranslationLngs = 'ar' | 'bn' | 'en' | 'es' | 'fr' | 'ht' | 'ko' | 'ru' | 'ur' | 'zh';

export type TValidPdfReportKeys =
  | 'snapshot'
  | 'assessment'
  | 'emsAssessment'
  | 'hsAssessment'
  | 'regentsSched'
  | 'attendance'
  | 'academic'
  | 'dessa'
  | `dessa_${TTranslationLngs}`
  | 'mockRegents';

interface IPdfReportTypes {
  snapshot: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  emsAssessment: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  hsAssessment: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  regentsSched: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  attendance: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  academic: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  dessa: IDropdownOption & { 
    human: TValidPdfReportNames; 
    key: TValidPdfReportKeys; 
    options: Array<IDropdownOption>;
    districts: Array<string>;
  };
  mockRegents: IDropdownOption & {
    human: TValidPdfReportNames;
    key: TValidPdfReportKeys;
    options: Array<IDropdownOption>;
    districts: Array<string>;
  }
}

const nonDessaDefaults = {
  exportFileNamePrefix: 'students',
};

const dessaDefaults = {
  exportFileNameBody: 'dessa_letter',
};

const mockRegentsDefaults = {
  exportFileNamePrefix: 'mock',
  exportFileNameBody: 'regents',
};

function calculateBody({ key }) {
  return snakeCase(key);
}

function calculateSuffix({ human }) {
  return snakeCase(human);
}

//Want to include some calculations in the form of getters but to have this be entirely constant at export time.
//So, going to export the destructure of this type.
const pdfReportTypesUnprocessed: IPdfReportTypes = {
  snapshot: {
    ...nonDessaDefaults,
    human: 'Student Snapshot',
    key: 'snapshot',
    options: [],
    get exportFileNameBody() {
      return calculateBody(this);
    },
    districts: ['NYC', 'Schenectady', 'Uniondale'],
  },
  regentsSched: {
    ...nonDessaDefaults,
    human: 'Regents Plan & Prep Report',
    key: 'regentsSched',
    exportFileNameBody: 'regents_prep',
    options: [],
    districts: ['NYC', 'Schenectady', 'Uniondale'],
  },
  emsAssessment: {
    ...nonDessaDefaults,
    human: 'EMS Assessment Report',
    key: 'hsAssessment',
    exportFileNameBody: 'assessment',
    options: [],
    districts: ['NYC', 'Schenectady', 'Uniondale'],
  },
  hsAssessment: {
    ...nonDessaDefaults,
    human: 'HS Assessment Report',
    key: 'emsAssessment',
    exportFileNameBody: 'assessment',
    options: [],
    districts: ['NYC', 'Schenectady', 'Uniondale'],
  },
  attendance: {
    ...nonDessaDefaults,
    human: 'Attendance Report',
    key: 'attendance',
    options: [],
    get exportFileNameBody() {
      return calculateBody(this);
    },
    districts: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
  },
  academic: {
    ...nonDessaDefaults,
    human: 'Academic Report',
    key: 'academic',
    options: [],
    get exportFileNameBody() {
      return calculateBody(this);
    },
    districts: ['NYC']
  },
  dessa: {
    human: 'DESSA (SEL) Report',
    key: 'dessa',
    options: [
      {
        ...dessaDefaults,
        key: 'dessa_en',
        human: 'English',
        pillPlaceholder: 'DESSA (SEL) Report: English',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ar',
        human: 'Arabic',
        pillPlaceholder: 'DESSA (SEL) Report: Arabic',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_bn',
        human: 'Bengali',
        pillPlaceholder: 'DESSA (SEL) Report: Bengali',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_zh',
        human: 'Chinese',
        pillPlaceholder: 'DESSA (SEL) Report: Chinese',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_fr',
        human: 'French',
        pillPlaceholder: 'DESSA (SEL) Report: French',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ht',
        human: 'Haitian Creole',
        pillPlaceholder: 'DESSA (SEL) Report: Haitian Creole',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ko',
        human: 'Korean',
        pillPlaceholder: 'DESSA (SEL) Report: Korean',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ru',
        human: 'Russian',
        pillPlaceholder: 'DESSA (SEL) Report: Russian',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_es',
        human: 'Spanish',
        pillPlaceholder: 'DESSA (SEL) Report: Spanish',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
      {
        ...dessaDefaults,
        key: 'dessa_ur',
        human: 'Urdu',
        pillPlaceholder: 'DESSA (SEL) Report: Urdu',
        get exportFileNameSuffix() {
          return calculateSuffix(this);
        },
      },
    ],
    districts: ['NYC'],
  },
  mockRegents: {
    human: 'Mock Regents Report',
    key: 'mockRegents',
    options: [
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_alg1Aug2022',
        human: 'Algebra 1',
        pillPlaceholder: 'Algebra 1 Mock Regent',
        exportFileNameSuffix: 'alg1Aug2022',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_globalAug2022',
        human: 'Global History',
        pillPlaceholder: 'Global History Mock Regent',
        exportFileNameSuffix: 'globalAug2022',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_usPractice',
        human: 'US History',
        pillPlaceholder: 'US History Mock Regent',
        exportFileNameSuffix: 'usPractice',
      },
      {
        ...mockRegentsDefaults,
        key: 'mockRegents_livingEnvAug2022',
        human: 'Living Environment',
        pillPlaceholder: 'Living Environment Mock Regent',
        exportFileNameSuffix: 'livingEnvAug2022',
      },
    ],
    districts: ['NYC']
  },
};

//Take a static clone, effectively, and return that.
export const pdfReportTypes = { ...pdfReportTypesUnprocessed };
