import { Component, Input, ViewEncapsulation } from '@angular/core';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

export interface IInfoRow {
  name: string;
  data: string;
  tooltipData?: any;
}

export interface IInfoPanel {
  title: string;
  rows: IInfoRow[];
}

@Component({
  selector: 'info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class InfoPanelComponent {
  @Input() infoPanel: IInfoPanel;
}
