// TODO UPDATE AT REGENTS ADMIN FLIP: remove admin date values that are in the past, add next new one
// i.e if the next regents admin is AUG 2018, remove June 2018; add June 2022
export const ValidRegentsPlans = {
  JAN_2024: 'Jan 2024',
  JUN_2024: 'June 2024',
  AUG_2024: 'Aug 2024',
  JAN_2025: 'Jan 2025',
  JUN_2025: 'June 2025',
  AUG_2025: 'Aug 2025',
  JAN_2026: 'Jan 2026',
  JUN_2026: 'June 2026',
  AUG_2026: 'Aug 2026',
  JAN_2027: 'Jan 2027',
  JUN_2027: 'June 2027',
  AUG_2027: 'AUG 2027',
};

export const RegentsPlans = Object.assign({}, ValidRegentsPlans, {
  DROP: 'DROP',
});
export class RegentsAdminHelper {
  static SIT_REGENTS_STR = 'Sit';
  static WAIVE_REGENTS_STR = 'Waive';

  public static SITTABLE_REGENTS = {
    'June 2021': {
      ccEla: true,
      ccAlg: true,
      livingEnv: true,
      earth: true,
    },
  };

  static isSittable (examKey: string, adminDate: string): boolean {
    return RegentsAdminHelper.SITTABLE_REGENTS[adminDate] && RegentsAdminHelper.SITTABLE_REGENTS[adminDate][examKey];
  }

  static getAdminDate (examKey: string, adminDate: string, isWaived: boolean): string {
    const isSittable = RegentsAdminHelper.isSittable(examKey, adminDate);
    let nextScheduled: string = adminDate;
    if (isSittable) {
      const str = isWaived ? RegentsAdminHelper.WAIVE_REGENTS_STR : RegentsAdminHelper.SIT_REGENTS_STR;
      nextScheduled = `${adminDate} (${str})`;
    }
    return nextScheduled;
  }

  static getAdminDateOpts (examKey: string, adminDates: string[] = []): string[] {
    return adminDates.reduce((acc, adminDate) => {
      const isSittable = RegentsAdminHelper.isSittable(examKey, adminDate);
      const plans = isSittable 
        ? [`${adminDate} (${RegentsAdminHelper.SIT_REGENTS_STR})`, `${adminDate} (${RegentsAdminHelper.WAIVE_REGENTS_STR})`]
        : [adminDate];
      acc.push(...plans);
      return acc;
    },[]);
  }

  static getAdminDatePatch (adminDate: string): { adminDate: string, isWaived: boolean } {
    if (!adminDate || adminDate === 'DROP') return { adminDate, isWaived: true };

    const [month, year, sitOrWaiveStr] = adminDate.split(' ');
    const isSitting = sitOrWaiveStr === `(${RegentsAdminHelper.SIT_REGENTS_STR})`;
    return { adminDate: `${month} ${year}`, isWaived: !isSitting };
  }
}