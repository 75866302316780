import { RollbarMock } from './../../../../../test/mocks/upgraded-services/rollbar.mock';
import { environment } from 'Src/environments/environment';
import { includes } from 'lodash';
import * as Rollbar from 'rollbar';

import {
  Injectable,
  Inject,
  InjectionToken,
  ErrorHandler,
} from '@angular/core';

const { PUBLIC_CONFIG } = environment as any;

const rollbarConfig = {
  accessToken: (PUBLIC_CONFIG as any).ROLLBAR_POST_CLIENT_ITEM,
  autoInstrument: {
    network: true,
    log: true,
    dom: true,
    navigation: true,
    connectivity: false,
  },
  // setting `captureUncaught: true` will allow all uncaught errors to be sent to Rollbar,
  // including those generated by vendor code. May temporarily pass through more errors to Rollbar
  // but will being handling in checkIgnore as err sources are identified.
  captureUncaught: false,
  verbose: true,
  // if true, logs errs from failed promises that don't have a .then() handler
  captureUnhandledRejections: false,
  // add error messages we want to ignore - such as those generated by 3rd party packages
  ignoredMessages: [],
  /**
   * Custom matcher.  At present time, only checks for a couple auth0 vendor errors, to prevent these from being
   * reported to Rollbar.
   *
   * @param isUncaught
   * @param args
   * @param payload
   * @returns {boolean}
   */
  checkIgnore: (isUncaught, args, payload) => {
    const { request, body } = payload;
    const isLoginUrl = includes(request.url, '/login');
    const auth0Errors = ['server error', 'User closed the popup window'];

    // for now, only handling specific auth0 vendor errs.
    if (isLoginUrl) {
      // and only handling if there's a stack trace
      if (body.trace && body.trace.exception.message) {
        const isIgnorable = auth0Errors.indexOf(body.trace.exception.message) > -1;
        if (isIgnorable) return true;
      }
    }
  },
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
    environment: (PUBLIC_CONFIG as any).ROLLBAR_ENV,
    server: {
      root: 'webpack:///.',
    },
    person: {
      id: undefined,
      username: undefined,
      email: undefined,
    },
    nv_authorizationStatus: undefined,
    // school
    nv_role: undefined,
    nv_delegatedRole: undefined,
    // shelter
    nv_roleShelter: undefined,
    nv_delegatedRoleShelter: undefined,
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  rollbarConfig;

  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
  ) { }

  handleError (err:any) : void {
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory () {
  // if the config doesn't have these a ROLLBAR_POST_CLIENT_ITEM, then we can't set up a rollbar connection.
  // this applies for non staging/prod environments that are currently not supported by Rollbar.
  // without this check, there will be error logs in the console trying to make a request to rollbar. (Jack)
  if (!(PUBLIC_CONFIG as any).ROLLBAR_POST_CLIENT_ITEM) return new RollbarMock();
  else return new Rollbar(rollbarConfig);
}
