import { DynamicComponentModule } from './../directives/dynamic-component/dynamic-component.module';
import { DocMouseMoveService } from './../services/document-mousemove-service/document-mousemove.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvTooltipComponent } from './nv-tooltip.component';
import { NvTooltipDirective } from './nv-tooltip.directive';
import { NvTooltipDataService } from './nv-tooltip.service';
import { NvGroupedTablesTooltipComponent } from './templates/grouped-tables/nv-grouped-tables-tooltip.component';
@NgModule({
  declarations: [NvTooltipComponent, NvTooltipDirective, NvGroupedTablesTooltipComponent],
  providers: [NvTooltipDataService, DocMouseMoveService],
  imports: [OverlayModule, CommonModule, DynamicComponentModule],
  exports: [NvTooltipDirective, NvTooltipComponent],
})
export class NvTooltipModule {}
