import { NvDropdownModule } from './../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedServicesModule } from '../../services/shared.services.module';
import { ListSummaryComponent } from './list-summary.component';

@NgModule({
  declarations: [ListSummaryComponent],
  imports: [CommonModule, SharedServicesModule, NvDropdownModule],
  exports: [ListSummaryComponent],
})
export class ListSummaryModule {}
