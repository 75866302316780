import { Injectable } from '@angular/core';
import { WebStorageUtility } from './../../../utilities/web-storage/web-storage.utility';
import { WindowRef } from './../../windowRef';

@Injectable()
export class LocalStorageService extends WebStorageUtility<string> {
  // INJECTOR ISSUE WITH ROLLBAR
  constructor (private windowRef: WindowRef) {
    super(windowRef.nativeWindow.localStorage, null);
  }
}
