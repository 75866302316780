import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { ActivatedRoute } from '@angular/router';
import { reduce } from 'lodash';
import { GraduationPlan } from '../../../../../../shared/constants/graduation-plan.constant';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'complete-grad-planning-step',
  templateUrl: './complete-grad-planning-step.component.html',
  styleUrls: ['./complete-grad-planning-step.component.scss', '../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CompleteGradPlanningStep {
  @Input() filteredStudents = [];

  public stepLabel: string = 'Complete graduation planning';

  public schoolId: string;
  public studentsWithoutGradPlanOrDate;
  public options;

  constructor (
    private route: ActivatedRoute,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit (): void {
    this.schoolId = this.route.snapshot.params.schoolId;
    this.studentsWithoutGradPlanOrDate = this.getStudentsWithNoGradPlanOrDate(this.filteredStudents);
    this.options = { columnKeys: ['cohort', 'gradPlan', 'plannedDiplomaType', 'plannedGraduationDate'] };
  }

  ngOnChanges ({ filteredStudents }) {
    if (filteredStudents && !filteredStudents.isFirstChange()) {
      this.ngOnInit();
    }
  }

  goToGradPlanningWizard (schoolId) {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/other-tools/grad-planning/wizard`);
    this.windowRef.nativeWindow.open(url, '_blank');
  }

  getStudentsWithNoGradPlanOrDate (students): string[] {
    return reduce(
      students,
      (studentIdCollection: string[], student: any) => {
        const { gradPlan, studentId } = student;
        if (
          !gradPlan ||
          gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName ||
          gradPlan === GraduationPlan.PLAN_IN_PAST.humanName
        ) { studentIdCollection.push(studentId); }
        return studentIdCollection;
      },
      [],
    );
  }
}
