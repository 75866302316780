import * as batchActions from '../actions/batch-actions-actions';
import { ISelectedStudentIdData } from './../actions/batch-actions-actions';

export interface IBatchActionsState {
  enabled: boolean;
  selectedStudentIds: ISelectedStudentIdData[];
}

export const initialState = {
  enabled: false,
  selectedStudentIds: [],
};

export function reducer(
  state: IBatchActionsState = initialState,
  action: batchActions.BatchActionsAction,
): IBatchActionsState {
  switch (action.type) {
    case batchActions.TOGGLE_BATCH_ACTIONS: {
      return {
        ...state,
        enabled: action.payload,
      };
    }

    case batchActions.UPDATE_SELECTED_STUDENT_IDS: {
      return {
        ...state,
        selectedStudentIds: action.payload,
      };
    }

    default:
      return state;
  }
}
