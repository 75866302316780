import { NvTimePickerModule } from './../nv-time-picker/nv-time-picker.module';
import { NvTimeRangePickerComponent } from './nv-time-range-picker.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';

import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';

@NgModule({
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NvIconModule,
    NvIconButtonModule,
    NvTimePickerModule,
  ],
  declarations: [NvTimeRangePickerComponent],
  exports: [NvTimeRangePickerComponent],
})
export class NvTimeRangePickerModule {}
