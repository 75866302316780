import { distinctUntilChanged, debounceTime, takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { find } from 'lodash';
import * as moment from 'moment';
import { Subject } from 'rxjs';

interface IMenuTrigger {
  menuTrigger: MatAutocompleteTrigger;
  instance: string;
}

@Component({
  selector: 'nv-time-range-picker',
  templateUrl: './nv-time-range-picker.component.html',
  styleUrls: ['./nv-time-range-picker.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvTimeRangePickerComponent {
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
   *
   * Use a FormGroup to keep track of the selected time instead of a control
   * @required
   */
  @Input() timeRangeForm: FormGroup;

  /**
   * The placeholder text when the picker is empty
   */
  @Input() placeholderText: string = 'Time';

  /**
   * The name of the left icon (as `nv-icon`)
   */
  @Input() icon: string = null;

  /**
   * Indicates whether form should have a clear button
   */
  @Input() hasClearBtn: boolean = false;

  /**
   * An optional start time for the options menu
   */
  @Input() rangeStartTime?: string = '6:00 AM';

  /**
   * An optional end time for the options menu
   */
  @Input() rangeEndTime?: string = '9:00 PM';

  /**
   * An optional interval. Defaults to 30
   */
  @Input() interval? = 30;

  resetRequested: boolean;
  menuTriggers: IMenuTrigger[] = [];
  areBothValid: boolean;

  readonly pattern = 'hh:mm a';

  constructor() {
    /** */
  }

  ngOnInit(): void {
    this.timeRangeForm.controls.startTime.setErrors(null);
    this.timeRangeForm.controls.endTime.setErrors(null);

    this.timeRangeForm.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(changes => {
        this.getStyle();
      });
  }

  getStyle() {
    const { startTime, endTime } = this.timeRangeForm.controls;
    const endIsInvalid = endTime.invalid || endTime.untouched;
    const startIsInvalid = startTime.invalid || startTime.untouched;
    const isLoadState = this.timeRangeForm.invalid && this.timeRangeForm.pristine;

    if (
      isLoadState ||
      this.timeRangeForm.controls.pristine ||
      this.timeRangeForm.untouched ||
      this.timeRangeForm.valid
    ) {
      return 'blue';
    } else if (this.timeRangeForm.touched) {
      if (startIsInvalid || endIsInvalid) {
        return 'pink';
      } else if (startIsInvalid && endIsInvalid) {
        return 'pink';
      }
    }
  }

  updateRightPicker(leftValue: string): void {
    this.rangeStartTime = leftValue;
    // since both components expect a rangeStartTime input, the previous action would then
    // set the startTime for the left menu, but we want to retain the original  state of the left menu.
    this.resetRequested = true;

    const isValidTimeOrder = this.isValidTimeOrder(leftValue, this.timeRangeForm.value.endTime);
    if (!isValidTimeOrder) {
      this.timeRangeForm.controls.endTime.setValue(leftValue);
    }
  }

  updateLeftPicker(rightValue: string): void {
    const isValidTimeOrder = this.isValidTimeOrder(this.rangeStartTime, rightValue);
    if (!isValidTimeOrder) {
      this.timeRangeForm.controls.startTime.setValue(rightValue);
    }
  }

  closePanel(menuTrigger: MatAutocompleteTrigger, instance: string): void {
    const triggerObj: IMenuTrigger = { menuTrigger, instance };
    const matchedTrigger = find(this.menuTriggers, triggerObj => triggerObj.instance === instance);
    if (!matchedTrigger) this.menuTriggers.push(triggerObj);
    const otherTrigger = find(this.menuTriggers, triggerObj => triggerObj.instance !== instance);
    if (otherTrigger) otherTrigger.menuTrigger.closePanel();
  }

  private isValidTimeOrder(startTime: string, endTime: string): boolean {
    if (!startTime || !endTime) return true;
    const momentLeft = moment(startTime, this.pattern);
    const momentRight = moment(endTime, this.pattern);
    return momentLeft.isBefore(momentRight);
  }
}
