import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { SharedServicesModule } from '../../services/shared.services.module';
import { NvMatTooltipModule } from '../nv-mat-tooltip/nv-mat-tooltip.module';
import { BatchActionsMenuComponent } from './batch-actions-menu.component';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';
import { NvIconModule } from 'Src/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvDropdownMenuModule } from 'Src/nvps-libraries/design/nv-dropdown-menu/nv-dropdown-menu.module';

@NgModule({
  declarations: [BatchActionsMenuComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    SharedServicesModule,
    NvMatTooltipModule,
    NvButtonModule,
    NvIconModule,
    NvDropdownMenuModule,
  ],
  exports: [BatchActionsMenuComponent],
  providers: [],
  schemas: [],
})
export class BatchActionsMenuModule {}
