import { NvToastComponent } from './../../../../nvps-libraries/design/nv-toast/nv-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Inject, Injectable, NgZone } from '@angular/core';
import { extend, isError } from 'lodash';
import { tap } from 'rxjs/operators';
import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { LocalStorageService } from '../web-storage/local-storage/local-storage.service';
import * as Rollbar from 'rollbar';
import { RollbarService } from '../rollbar/rollbar.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorToaster {
  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
    private snackBar: MatSnackBar,
    private windowRef: WindowRef,
    private zone: NgZone,
    private localStorage: LocalStorageService,
    ) {}

  public handleError (err: Error | HttpErrorResponse) : void {
    const errMessage = this._messageFromError(err);
    if(this.isChuckLoadError(errMessage) && !this.snackBar._openedSnackBarRef) {
      this.handleChunkLoadError();
    }
  }

  private handleChunkLoadError () : void {
    const hasChunkLoadErrorOccured = this.localStorage.getItem('chunkLoadError');
    if(hasChunkLoadErrorOccured) this.rollbar.error('Stuck ChunkLoadError');
    this.zone.run(() => this.showChunkLoadErrorToast());
  }

  private showChunkLoadErrorToast () : void {
    const window = this.windowRef.nativeWindow;
    const snackBarRef = this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText: 'There is a new version of the app.',
        hasClearButton: true,
        actionText: 'Reload',
        maxWidth: 'none',
      },
    });
    snackBarRef.onAction()
    .pipe(
      tap(() => {
        this.localStorage.setItem('chunkLoadError', true);
        window.location.reload();
      }))
    .subscribe();
  }

  private isChuckLoadError(errMessage: string) : boolean {
    // matches the word 'ChunkLoadError' case-insensitive
    return /ChunkLoadError/gi.test(errMessage);
  }

  private _processJsError (err: Error) : string {
    return err.name + '\n' + err.message;
  }

  private _process$HttpError (err: HttpErrorResponse) : string {
    return err.status + ' ' + err.statusText + '\n';
  }

  private _messageFromError (err: Error | HttpErrorResponse) : string {
    if (isError(err)) {
      return this._processJsError(err);
    } else if (err.status && err.statusText) {
      return this._process$HttpError(err);
    } else if (err.status === -1) {
      // Network issues
      return this._process$HttpError(extend(err, { statusText: 'Request has timedout' }));
    }
  }
}
