import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export type TValidStudentUpdate =
  | 'StudentUpdate'
  | 'StudentSupportUpdate'
  | 'StudentPathUpdate'
  | 'StudentActivityCreate'
  | 'StudentActivityUpdate'
  | 'StudentMilestoneUpdate'
  | 'BulkStudentUpdate';

export interface IStudentUpdateMessage {
  update: TValidStudentUpdate;
  studentId: String | String[];
}

// NOTE: Keeping the same toast message for all updates. Can be use for more custom messages
export const STUDENT_UPDATE_HUMAN_MAP = {
  'StudentUpdate' : 'This student was updated.',
  'StudentSupportUpdate': 'This student was updated.',
  'StudentPathUpdate': 'This student was updated.',
  'StudentActivityCreate': 'This student was updated.',
  'StudentActivityUpdate': 'This student was updated.',
  'StudentMilestoneUpdate': 'This student was updated.',
  'BulkStudentUpdate' : 'This student was updated.',
}

@Injectable({
  providedIn: 'root',
})
export class StudentUpdateNotificationService {
  private subject = new Subject<any>();

  sendStudentUpdateNotificationMessage (message: IStudentUpdateMessage) {
    this.subject.next(message);
  }

  getStudentUpdateNotificationMessage (): Observable<any> {
    return this.subject.asObservable();
  }
}
