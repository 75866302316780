import { NvTextboxModule } from './../nv-textbox/nv-textbox.module';
import { NvTextboxVirtualScrollComponent } from './nv-textbox-virtual-scroll.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NgxMaskModule } from 'ngx-mask';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  imports: [
    CommonModule,
    NvIconModule,
    NvIconButtonModule,
    MatAutocompleteModule,
    ScrollingModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(),
    NvTextboxModule,
  ],
  declarations: [NvTextboxVirtualScrollComponent],
  providers: [ScrollingModule, CdkVirtualScrollViewport],
  exports: [NvTextboxVirtualScrollComponent],
})
export class NvTextboxVirtualScrollModule {}
