import { CommonModule } from '@angular/common';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { UtilitiesService } from './../../services/utilities/utilities.service';
import { CreditGapsCoursePickerModal } from './credit-gaps-course-picker-modal.component';
import { NgModule } from '@angular/core';
import { FindACourseTableModule } from '../../components/find-a-course-table-component/find-a-course-table.module';

@NgModule({
  imports: [MatToolbarModule, NvButtonModule, FindACourseTableModule, CommonModule],
  exports: [],
  declarations: [CreditGapsCoursePickerModal],
  providers: [
    UtilitiesService,
    ApiService,
  ],
})
export class CreditGapsCoursePickerModalModule { }
