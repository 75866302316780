import { GraduationMonth } from '../../../ng2/shared/constants/graduation-month.constant';

export type diplomaTypeCategory =
  | 'Planned Local'
  | 'Planned Regents'
  | 'Planned Advanced Regents'
  | 'Planned Non-Grad'
  | 'No Plan'
  | 'Negative Discharges'
  | 'Graduates';

export type outcomeCategory = '4 Year Jan' | '4 Year March' | '4 Year June' | '4 Year Aug' | '5 Year' | '6+ Year' | null;

export type diploma = 'Advanced Regents' | 'Regents' | 'Local' | null;

export interface IGradPlanValue {
  readonly humanName: string;
  readonly month: string | null;
  readonly isPlanned4YearGradPlan: boolean;
  readonly diplomaTypeCategory: diplomaTypeCategory;
  readonly diploma: string;
  readonly outcomeCategory: outcomeCategory;
}

export const GraduationPlan = {
  NEGATIVE_DISCHARGE: {
    humanName: 'Negative Discharge',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: null,
    diplomaTypeCategory: 'Negative Discharges',
    outcomeCategory: null,
  } as IGradPlanValue,
  GRADUATE: {
    humanName: 'Graduate',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: null,
    diplomaTypeCategory: 'Graduates',
    outcomeCategory: null,
  } as IGradPlanValue,
  NON_GRADUATE: {
    humanName: 'Non-Graduate',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: null,
    diplomaTypeCategory: 'Planned Non-Grad',
    outcomeCategory: null,
  } as IGradPlanValue,
  PLAN_INCOMPLETE: {
    humanName: 'Plan Incomplete',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: null,
    diplomaTypeCategory: 'No Plan',
    outcomeCategory: null,
  } as IGradPlanValue,
  PLAN_IN_PAST: {
    humanName: 'Plan in Past',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: null,
    diplomaTypeCategory: 'No Plan',
    outcomeCategory: null,
  } as IGradPlanValue,
  LOCAL_4_YEAR_JAN: {
    humanName: 'Local 4 Year Jan',
    month: GraduationMonth.JAN,
    isPlanned4YearGradPlan: true,
    diploma: 'Local',
    diplomaTypeCategory: 'Planned Local',
    outcomeCategory: '4 Year Jan',
  } as IGradPlanValue,
  LOCAL_4_YEAR_MAR: {
    humanName: 'Local 4 Year March',
    month: GraduationMonth.MAR,
    isPlanned4YearGradPlan: true,
    diploma: 'Local',
    diplomaTypeCategory: 'Planned Local',
    outcomeCategory: '4 Year March',
  } as IGradPlanValue,
  LOCAL_4_YEAR_JUNE: {
    humanName: 'Local 4 Year June',
    month: GraduationMonth.JUNE,
    isPlanned4YearGradPlan: true,
    diploma: 'Local',
    diplomaTypeCategory: 'Planned Local',
    outcomeCategory: '4 Year June',
  } as IGradPlanValue,
  LOCAL_4_YEAR_AUG: {
    humanName: 'Local 4 Year Aug',
    month: GraduationMonth.AUG,
    isPlanned4YearGradPlan: true,
    diploma: 'Local',
    diplomaTypeCategory: 'Planned Local',
    outcomeCategory: '4 Year Aug',
  } as IGradPlanValue,
  LOCAL_5_YEAR: {
    humanName: 'Local 5 Year',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: 'Local',
    diplomaTypeCategory: 'Planned Local',
    outcomeCategory: '5 Year',
  } as IGradPlanValue,
  LOCAL_6_PLUS_YEAR: {
    humanName: 'Local 6+ Year',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: 'Local',
    diplomaTypeCategory: 'Planned Local',
    outcomeCategory: '6+ Year',
  } as IGradPlanValue,
  REGENTS_4_YEAR_JAN: {
    humanName: 'Regents 4 Year Jan',
    month: GraduationMonth.JAN,
    isPlanned4YearGradPlan: true,
    diploma: 'Regents',
    diplomaTypeCategory: 'Planned Regents',
    outcomeCategory: '4 Year Jan',
  } as IGradPlanValue,
  REGENTS_4_YEAR_MAR: {
    humanName: 'Regents 4 Year March',
    month: GraduationMonth.MAR,
    isPlanned4YearGradPlan: true,
    diploma: 'Regents',
    diplomaTypeCategory: 'Planned Regents',
    outcomeCategory: '4 Year March',
  } as IGradPlanValue,
  REGENTS_4_YEAR_JUNE: {
    humanName: 'Regents 4 Year June',
    month: GraduationMonth.JUNE,
    isPlanned4YearGradPlan: true,
    diploma: 'Regents',
    diplomaTypeCategory: 'Planned Regents',
    outcomeCategory: '4 Year June',
  } as IGradPlanValue,
  REGENTS_4_YEAR_AUG: {
    humanName: 'Regents 4 Year Aug',
    month: GraduationMonth.AUG,
    isPlanned4YearGradPlan: true,
    diploma: 'Regents',
    diplomaTypeCategory: 'Planned Regents',
    outcomeCategory: '4 Year Aug',
  } as IGradPlanValue,
  REGENTS_5_YEAR: {
    humanName: 'Regents 5 Year',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: 'Regents',
    diplomaTypeCategory: 'Planned Regents',
    outcomeCategory: '5 Year',
  } as IGradPlanValue,
  REGENTS_6_PLUS_YEAR: {
    humanName: 'Regents 6+ Year',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: 'Regents',
    diplomaTypeCategory: 'Planned Regents',
    outcomeCategory: '6+ Year',
  } as IGradPlanValue,
  ADVANCED_REGENTS_4_YEAR_JAN: {
    humanName: 'Advanced Regents 4 Year Jan',
    month: GraduationMonth.JAN,
    isPlanned4YearGradPlan: true,
    diploma: 'Advanced Regents',
    diplomaTypeCategory: 'Planned Advanced Regents',
    outcomeCategory: '4 Year Jan',
  } as IGradPlanValue,
  ADVANCED_REGENTS_4_YEAR_MAR: {
    humanName: 'Advanced Regents 4 Year March',
    month: GraduationMonth.MAR,
    isPlanned4YearGradPlan: true,
    diploma: 'Advanced Regents',
    diplomaTypeCategory: 'Planned Advanced Regents',
    outcomeCategory: '4 Year March',
  } as IGradPlanValue,
  ADVANCED_REGENTS_4_YEAR_JUNE: {
    humanName: 'Advanced Regents 4 Year June',
    month: GraduationMonth.JUNE,
    isPlanned4YearGradPlan: true,
    diploma: 'Advanced Regents',
    diplomaTypeCategory: 'Planned Advanced Regents',
    outcomeCategory: '4 Year June',
  } as IGradPlanValue,
  ADVANCED_REGENTS_4_YEAR_AUG: {
    humanName: 'Advanced Regents 4 Year Aug',
    month: GraduationMonth.AUG,
    isPlanned4YearGradPlan: true,
    diploma: 'Advanced Regents',
    diplomaTypeCategory: 'Planned Advanced Regents',
    outcomeCategory: '4 Year Aug',
  } as IGradPlanValue,
  ADVANCED_REGENTS_5_YEAR: {
    humanName: 'Advanced Regents 5 Year',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: 'Advanced Regents',
    diplomaTypeCategory: 'Planned Advanced Regents',
    outcomeCategory: '5 Year',
  } as IGradPlanValue,
  ADVANCED_REGENTS_6_PLUS_YEAR: {
    humanName: 'Advanced Regents 6+ Year',
    month: null,
    isPlanned4YearGradPlan: false,
    diploma: 'Advanced Regents',
    diplomaTypeCategory: 'Planned Advanced Regents',
    outcomeCategory: '6+ Year',
  } as IGradPlanValue,
};

export const GRAD_PLAN_THRESHOLDS_MIN_VALUES = {
  NYC: {
    CREDITS: {
      ANNUALIZED: {
        maxEachTerm: 11,
        maxSummerTerm: 0,
      },
      SEMESTER: {
        maxEachTerm: 5.5,
        maxSummerTerm: 0,
      },
      TRIMESTER: {
        maxEachTerm: 3.67,
        maxSummerTerm: 0,
      },
      QUARTERLY: {
        maxEachTerm: 2.75,
        maxSummerTerm: 0,
      },
    },
    REGENTS: {
      firstYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      secondYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      thirdYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      fourthYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
    },
  },
  Schenectady: {
    CREDITS: {
      ANNUALIZED: {
        maxEachTerm: 5.5,
        maxSummerTerm: 0,
      },
      SEMESTER: {
        maxEachTerm: 2.75,
        maxSummerTerm: 0,
      },
      TRIMESTER: {
        maxEachTerm: 1.8,
        maxSummerTerm: 0,
      },
      QUARTERLY: {
        maxEachTerm: 1.4,
        maxSummerTerm: 0,
      },
    },
    REGENTS: {
      firstYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      secondYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      thirdYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      fourthYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
    },
  },
  Uniondale: {
    CREDITS: {
      ANNUALIZED: {
        maxEachTerm: 5.5,
        maxSummerTerm: 0,
      },
      SEMESTER: {
        maxEachTerm: 2.75,
        maxSummerTerm: 0,
      },
      TRIMESTER: {
        maxEachTerm: 1.8,
        maxSummerTerm: 0,
      },
      QUARTERLY: {
        maxEachTerm: 1.4,
        maxSummerTerm: 0,
      },
    },
    REGENTS: {
      firstYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      secondYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      thirdYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
      fourthYear: {
        regentsNeededAt65Of9: 0,
        regentsNeededAt65Of5ForSNE: 0,
      },
    },
  },
};
