import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IRowData } from '../../models/list-models';

@Directive({
  selector: '[dynamicComponent]',
})
export class DynamicComponentDirective implements OnInit {
  @Input() dynamicComponentInput: { row: IRowData[]; column: IRowData; dynamicComponentInputData?: any };
  @Input() dynamicComponentTrigger: null | boolean = null;
  @Output() dynamicComponentOutput = new EventEmitter();
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private vc: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
  ) {}

  ngOnInit(): void {
    const { column } = this.dynamicComponentInput;
    if (column.dynamic) {
      // dynamic.disabled? decide whether a UI should be disabled
      // dynamic.trigger? switch between a UI and a plain text
      const factory = this.resolver.resolveComponentFactory(column.dynamic);
      const componentRef: ComponentRef<any> = this.vc.createComponent(factory);
      const instance = componentRef.instance;
      instance.input = this.dynamicComponentInput;
      // some dynamic components may not have an output event emitter
      instance.output?.pipe(takeUntil(this.destroy$)).subscribe(val => {
        this.dynamicComponentOutput.emit(val);
      });
      if (this.dynamicComponentTrigger !== null) {
        instance.trigger = this.dynamicComponentTrigger;
      }
      const parentElement = this.el.nativeElement;
      if (parentElement.children && parentElement.children.length > 0) {
        this.renderer.removeChild(parentElement, parentElement.children[0]);
      }
      if (componentRef.location && componentRef.location.nativeElement) {
        this.renderer.appendChild(parentElement, componentRef.location.nativeElement);
      }
      componentRef.onDestroy(() => {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
      });
    }
  }
}
