import { FormItemDatePickerComponent } from './experience-form/form-item/form-item-date-picker/form-item-date-picker.component';
import { ExperienceModalService } from './experience-modal-service/experience-modal-service';
import { ButtonGroupComponent } from './../button-group/button-group.component';
import { FormItemComponent } from './experience-form/form-item/form-item.component';
import { NgModule } from '@angular/core';
import { ExperiencesModalComponent } from './experiences-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { BaseModalModule } from '../../base-modal.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';
import { CommonModule } from '@angular/common';
import { ExperienceFormComponent } from './experience-form/experience-form.component';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvTextareaModule } from 'Src/nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvDateRangePickerModule } from 'Src/nvps-libraries/design/nv-date-range-picker/nv-date-range-picker.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NvNumboxModule } from 'Src/nvps-libraries/design/nv-numbox/nv-numbox.module';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PickerService } from 'Src/ng2/shared/services/picker/picker.service';
import { PickerDataService } from 'Src/ng2/shared/services/picker/picker.data.service';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatCheckboxModule,
    NvFormFieldModule,
    NvTextboxModule,
    NvTextareaModule,
    NvDateRangePickerModule,
    BaseModalModule,
    NvDropdownModule,
    NvButtonModule,
    NvNumboxModule,
    NvNoteModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ExperienceFormComponent],
  declarations: [
    ExperiencesModalComponent,
    ExperienceFormComponent,
    FormItemComponent,
    ButtonGroupComponent,
    FormItemDatePickerComponent,
  ],
  providers: [ExperienceModalService, PickerService, PickerDataService, ImSchool],
})
export class ExperiencesModalModule { }
