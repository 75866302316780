// take a look at https://github.com/lancedikson/bowser#browser-flags
// for potential browser type values (CM).
export type TBrowserTypes = 'chrome' | 'safari' | 'firefox' | 'msedge' | 'msie' | 'other';

export enum BrowserUpgradeStatus {
  FullButNotOptimized = 'FullButNotOptimized',
  MustUpdateOrSwitch = 'MustUpdateOrSwitch',
  MustSwitch = 'MustSwitch',
}

export interface IBrowserVersionsSupportedConfig {
  [key: string]: BrowserUpgradeStatus;
}

type TBrowserVersionsSupported = { [key in TBrowserTypes]: IBrowserVersionsSupportedConfig };

// browser versions should include one of the following:
// <, <=, >, >=, or 'All' (CM)
export const BROWSER_VERSIONS_SUPPORTED: TBrowserVersionsSupported = {
  chrome: {
    '<61': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  safari: {
    '>=11': BrowserUpgradeStatus.FullButNotOptimized,
    '<11': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  firefox: {
    '>=61': BrowserUpgradeStatus.FullButNotOptimized,
    '<61': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  msedge: {
    '>=18': BrowserUpgradeStatus.FullButNotOptimized,
    '<18': BrowserUpgradeStatus.MustUpdateOrSwitch,
  },
  msie: {
    All: BrowserUpgradeStatus.MustSwitch,
  },
  other: {
    All: BrowserUpgradeStatus.MustSwitch,
  },
};

export const BROWSER_HUMAN_NAME = {
  chrome: 'Chrome',
  safari: 'Safari',
  firefor: 'Firefox',
  msedge: 'Edge',
  msie: 'IE',
  other: 'your browser', // for other browsers
};
