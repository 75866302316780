import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { LoadSchool } from './../../../store/actions/school-actions';
import { getSchoolLoadedStatus, getSchool } from './../../../store/selectors/school-selectors';
import { tap, filter, switchMap, take, catchError, mapTo, withLatestFrom } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class CurrentSchoolGuard implements CanActivate {
  private SCHOOL_ID_REGEX = /^[0-9a-zA-Z]+$/;

  constructor (
    private store: Store<any>,
    private router: Router
  ) {}

  getFromStoreOrAPI (schoolId: string): Observable<ISchool> {
    return this.store.pipe(
      select(getSchoolLoadedStatus),
      withLatestFrom(this.store.select(getSchool)),
      tap(([loaded, school]) => {
        /**
         * Example of why this check:
         * If a cluster user was to select a school and navigate to a feature
         * and then selecta different school and navigate to another feature
         * then was to navigate back thru the browser history via the back button
         * the portal url and store will fall out of sync.
         * The portal will see the store as loaded (for the most recently loaded school)
         * so once you hit a url for the other school the two diverge, leaving the user in a broken state
         */
        const storeSchoolId = school._id;
        if (!loaded || (storeSchoolId !== schoolId)) this.store.dispatch(new LoadSchool(schoolId));
      }),
      filter(([_, school]) => school._id === schoolId),
      switchMap(() => this.store.pipe(select(getSchool))),
      take(1),
    );
  }

  canActivate (routeSnapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const {
      params: { schoolId },
    } = routeSnapshot;

    // validate schoolId
    const isValidSchoolId = this.SCHOOL_ID_REGEX.test(schoolId);

    if (isValidSchoolId) {
      return this.getFromStoreOrAPI(schoolId).pipe(
        mapTo(true),
        catchError(() => of(false)),
      );
    } 

    return of(this.router.createUrlTree([`/not-found`]));
  }

  canActivateChild (routeSnapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    return this.canActivate(routeSnapshot);
  }
}
