import { PathCatKeyToPathCatConfig, PATH_CATEGORY_KEYS } from '../../../../shared/constants/student-paths.constant';

export const reduceToStudentPathName = (pathSubdoc) => {
  const { fields: fieldConfig } = PathCatKeyToPathCatConfig[pathSubdoc.pathCategory];

  const uniqAppendCharMap = {
    college: ' - ',
  };

  const fieldKeys = Object.keys(fieldConfig);
  const fieldValsForName = Object.entries(pathSubdoc)
    .reduce(
      (acc, [fieldKey, fieldValue]) => {
        const { includeInListName } = fieldConfig[fieldKey] || {};

        if (includeInListName) {
          const appendChar = uniqAppendCharMap[fieldKey] || ', ';
          const fieldIndex = fieldKeys.indexOf(fieldKey);
          acc[fieldIndex] = { fieldValue, appendChar };
        }
        return acc;
      }, [],
    );

  const nameString = fieldValsForName
    .filter(
      (val) => !!val,
    )
    .reduce(
      (acc, { fieldValue, appendChar }) => {
        acc += `${fieldValue}${appendChar}`;
        return acc;
      }, '',
    )
    .slice(0, -2);

  return nameString;
};

export const reduceToStudentPathTooltip = (pathSubdoc): string[][] => {
  const { fields: fieldConfig } = PathCatKeyToPathCatConfig[pathSubdoc.pathCategory];

  const fieldKeys = Object.keys(fieldConfig);
  const fieldRowForTooltip = Object.entries(pathSubdoc)
    .reduce(
      (acc, [fieldKey, fieldValue]) => {
        const { human, includeInListTooltip } = fieldConfig[fieldKey] || {};
        if (includeInListTooltip) {
          const fieldIndex = fieldKeys.indexOf(fieldKey);
          acc[fieldIndex] = [human, fieldValue];
        }
        return acc;
      }, [],
    );

  return fieldRowForTooltip.filter(
    (row) => !!row,
  );
};

export const getPathCategoryHuman = (pathCategoryKey: PATH_CATEGORY_KEYS) =>
  PathCatKeyToPathCatConfig[pathCategoryKey].human;
