import { WizardHelpers } from './../../../services/wizard-helpers.service';
import { TransferStudentReviewStep } from './student-review-step/student-review-step.component';
import { SpinnerService } from '../../../../../shared/components/spinner/spinner-modal.service';
import { RegentsPrepConfigService } from '../../../../../../app/sdc/sdc-state/sdc-configs/regents-prep-config.service';
import { FormControl } from '@angular/forms';
import { ImSchool } from '../../../../../shared/services/im-models/im-school';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { PrintRegentsStep } from '../shared-steps/print-regents-step/print-regents-step.component';
import { tap, map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { TrackAttendanceStep } from '../shared-steps/track-attendance-step/track-attendance-step.component';
import { SchedulingCheckStep } from '../shared-steps/scheduling-check/scheduling-check.component';
import { MonitorAttendanceStep } from '../shared-steps/monitor-attendance-step/monitor-attendance-step.component';
import { FinalPrepCheckStep } from '../shared-steps/final-check-prep-step/final-prep-check-step.component';
import { CompleteGradPlanningStep } from '../shared-steps/complete-grad-planning-step/complete-grad-planning-step.component';
import { AssignSupportsStep } from '../shared-steps/assign-supports-step/assign-supports-step.component';
import { Component, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { PrepReviewStep } from '../shared-steps/prep-review-step/prep-review-step.component';
import { Observable, combineLatest } from 'rxjs';
import { InstructionStep } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.component';
@Component({
  selector: 'regents-prep-transfer-wizard',
  templateUrl: './regents-prep-transfer-wizard.component.html',
  styleUrls: ['./regents-prep-transfer-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegentsPrepTransferWizard {
  public school$: Observable<ISchool>
  public flattenedStudents$: Observable<any>;
  public schoolSupports$: Observable<any>;
  public studentSupports$: Observable<any>;
  public classOfDropdownOptions: Array<{key: string, human: string}>
  public currentUser$: Observable<any>;
  public dropdownSelection;
  public spinner;
  public isLinear: boolean = false;
  private schoolId: string;
  public selectedStepIndex: number;
  public instructionDescription: string = `The purpose of this wizard is to support you in ensuring that all students planned to sit for an exam in the
  upcoming administration have the support they need to be successful.`

  constructor (
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private imSchool: ImSchool,
    private spinnerService: SpinnerService,
    private wizardHelpers: WizardHelpers,
    private regentsPrepConfigService: RegentsPrepConfigService,
  ) {}

  @ViewChild(AssignSupportsStep) assignSupportStep: AssignSupportsStep;
  @ViewChild(CompleteGradPlanningStep) completeGradPlanningStep: CompleteGradPlanningStep;
  @ViewChild(FinalPrepCheckStep) finalPrepCheckStep: FinalPrepCheckStep;
  @ViewChild(InstructionStep) instructionStep: InstructionStep;
  @ViewChild(MonitorAttendanceStep) monitorAttendanceStep: MonitorAttendanceStep;
  @ViewChild(TrackAttendanceStep) trackAttendanceStep: TrackAttendanceStep;
  @ViewChild(SchedulingCheckStep) schedulingCheckStep: SchedulingCheckStep;
  @ViewChild(PrintRegentsStep) printRegentsStep: PrintRegentsStep;
  @ViewChild(TransferStudentReviewStep) studentReviewStep: TransferStudentReviewStep;
  @ViewChild(PrepReviewStep) prepReviewStep: PrepReviewStep;
  @ViewChild(MatStepper) stepper: MatStepper;

  ngOnInit () {
    this.selectedStepIndex = this.activatedRoute.snapshot.queryParams.activeWizardStep || 0;
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.classOfDropdownOptions = this.wizardHelpers.generateTransferDropdown(this.imSchool.getNextFourGradDatesForTransfer());
    this.dropdownSelection = new FormControl(this.activatedRoute.snapshot.data?.filter?.dropdownSelection || 'All');
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.school$ = this.wizardHelpers.getSchool$();
    this.schoolSupports$ = this.wizardHelpers.getSchoolSupports$(this.schoolId);
    this.studentSupports$ = this.wizardHelpers.getStudentSupports$(this.schoolId);
    this.currentUser$ = this.wizardHelpers.getUser$();

    this.flattenedStudents$ = combineLatest([
      this.wizardHelpers.getSdcStudents(this.regentsPrepConfigService),
      this.school$,
      this.dropdownSelection.valueChanges.pipe(startWith(this.dropdownSelection.value)),
    ]).pipe(
      tap(([_, __, dropdownSelection]) => this.wizardHelpers.updateUrl(dropdownSelection, this.selectedStepIndex)),
      map(([students, dropdownSelection]) =>
        this.wizardHelpers.getFilteredStudents({
          students,
          filters: this.wizardHelpers.getFilters(this.wizardHelpers.getGradPlanFilter(dropdownSelection, this.classOfDropdownOptions), this.regentsPrepConfigService.filter),
        })),
      tap(() => this.spinner.close()),
    );
  }

  ngAfterViewInit () {
    this.stepper._getIndicatorType = () => 'number';
  }

  ngAfterContentChecked () {
    this.cd.detectChanges();
  }

  public onStepChange ({ selectedIndex }) {
    this.selectedStepIndex = selectedIndex;
    this.wizardHelpers.updateUrl(this.dropdownSelection.value, this.selectedStepIndex);
  }

  public handleDropdownChange ($event) {
    this.dropdownSelection.patchValue($event);
  }
}
