import { CategoryTagsComponent } from './category-tags-renderer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NvPillModule } from '../../../../../nvps-libraries/design/nv-pill/nv-pill.module';

@NgModule({
  imports: [CommonModule, NvPillModule],
  declarations: [CategoryTagsComponent],
  exports: [CategoryTagsComponent],
})
export class CategoryTagsRendererModule { }
