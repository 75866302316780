import { Component, Inject, Input, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { find } from 'lodash';
import { COLUMN_DATA_TYPE } from '../../../../shared/constants/list-view/cell-type.constant';
import { IActivitiesModalComponentData } from '../../../../shared/modals/activities/activities-modal.component';
import { IActivityStatusModalComponentData } from '../../../../shared/modals/activities/activity-status-modal/activity-status-modal.component';
import { ModalsService } from '../../../../shared/modals/modals.service';
import { ObjectCache } from '../../../../shared/services/object-cache/object-cache.service';
import { IActivity } from '../../../../shared/typings/interfaces/activity.interface';
import { ListDisplay } from '../list-display.component';
import { ListDisplayService } from '../list-display.service';

export const ACTIVITIES_LIST_COLUMNS = [
  {
    human: 'Activities list',
    key: 'activityName',
    cellType: COLUMN_DATA_TYPE.SECTION_HEADER,
    cellConfig: {},
    dataType: null,
    orderBy: null,
  },
  {
    human: 'Status',
    key: 'status',
    cellType: COLUMN_DATA_TYPE.GENERIC,
    cellConfig: {},
    dataType: null,
    orderBy: null,
  },
  {
    human: 'Created by',
    key: 'createdBy',
    cellType: COLUMN_DATA_TYPE.GENERIC,
    cellConfig: {},
    dataType: null,
    orderBy: null,
  },
  {
    human: 'Projected hours',
    key: 'projectedHours',
    cellType: COLUMN_DATA_TYPE.GENERIC,
    cellConfig: {},
    dataType: null,
    orderBy: null,
  },
  {
    human: 'Linked courses',
    key: 'linkedCourses',
    cellType: COLUMN_DATA_TYPE.GENERIC,
    cellConfig: {},
    dataType: null,
    orderBy: null,
  },
];
/* istanbul ignore next */
@Component({
  selector: 'list-display-activity',
  templateUrl: './list-display-activity.component.html',
  styleUrls: ['./list-display-activity.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListDisplayActivity extends ListDisplay {
  @Input() activities: IActivity[];

  constructor (
    protected listViewService: ListDisplayService,
    protected objectCache: ObjectCache,
    private modalsService: ModalsService,
    private ngrxStore: Store<any>,
  ) {
    super(listViewService, objectCache, ngrxStore);
  }

  /* istanbul ignore next */
  rowClick () {
    //
  }

  editActivity ({ dependencies: { activityId } }) {
    // find selected activity
    const activity = find(this.activities, activity => {
      return activity._id === activityId;
    });

    const modalOptions: IActivitiesModalComponentData = {
      mode: 'UPDATE',
      schoolId: activity.schoolId,
      isProfileMode: true,
      activity,
    };
    this.modalsService.openActivitiesModal(modalOptions);
  }

  deleteActivity ({ dependencies: { activityId } }) {
    // find selected activity
    const activity = find(this.activities, activity => {
      return activity._id === activityId;
    });

    const modalOptions: IActivityStatusModalComponentData = {
      activity,
      mode: 'DELETE',
    };
    this.modalsService.openActivityStatusModal(modalOptions);
  }
}
