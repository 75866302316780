import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';

export const FREQUENCY_OPTIONS: IDropdownOption[] = [
  { key: 'DAILY', human: 'Daily' },
  { key: 'WEEKLY', human: 'Once a week' },
  { key: 'BIWEEKLY', human: 'Once every two weeks' },
  { key: 'TRIWEEKLY', human: 'Once every three weeks' },
  { key: 'MONTHLY', human: 'Once a month' },
];

export const STATUS_OPTIONS: IDropdownOption[] = [
  { key: 'IN_PROGRESS', human: 'In Progress' },
  { key: 'COMPLETE', human: 'Complete' },
];

export const SHELTER_SUCCESS_MENTOR_CONFIGS = {
  ASSIGN: {
    mode: 'ASSIGN',
    title: 'Assign success mentor',
    primaryBtnName: 'Assign',
  },
  MARK_COMPLETE: {
    mode: 'MARK_COMPLETE',
    title: 'Mark success mentoring complete',
    primaryBtnName: 'Mark complete',
  },
  DELETE: {
    mode: 'DELETE',
    title: 'Delete success mentor record',
    primaryBtnName: 'Delete',
  },
  EDIT: {
    mode: 'EDIT',
    title: 'Edit mentor record',
    primaryBtnName: 'Edit record',
  },
};
