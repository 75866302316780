import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { sortBy } from 'lodash';
import { IColumnStuLvl } from './../../../../shared/models/list-models';
import { IFocus, IGrouping } from './../../academic-list-v2/academic-list-data/academic-data.service';
import { WildcardService } from 'Src/ng2/shared/services/list-services/wildcard.service';
import { getCurrScreenerTermByDistrict } from 'Src/ng2/shared/constants/current-school-year.constant';

@Injectable()
export class MadlibService {
  constructor (
    private wildcardService: WildcardService,
  ) {}

  getModelForFocusChange (
    schoolId: string,
    madlibModel: FormGroup,
    evt: { dimension: string; data: any },
    getFociData$: Function,
  ): Observable<FormGroup> {
    return getFociData$(schoolId, evt.data.graphQlKey).pipe(
      switchMap(([completeFocusData]) => {
        const formattedFocus = this.formatFocus(completeFocusData);
        return of(this.updateModelOnNonFocusChange({ dimension: evt.dimension, data: formattedFocus }, madlibModel));
      }),
    );
  }

  getListModelForFocusChange (
    schoolId: string,
    madlibModel: FormGroup,
    evt: { dimension: string; data: any },
    getFociData$: Function,
  ): Observable<FormGroup> {
    return getFociData$(schoolId, evt.data.graphQlKey).pipe(
      switchMap(([completeFocusData]) => {
        return of(this.updateModelOnNonFocusChange({ dimension: evt.dimension, data: completeFocusData }, madlibModel));
      }),
    );
  }

  public getModelForNonFocusChange (madlibModel: FormGroup, evt: { dimension: string; data: any }): Observable<FormGroup> {
    return of(this.updateModelOnNonFocusChange(evt, madlibModel));
  }

  updateModelOnFocusChange (focus, madlibModel): any {
    madlibModel.patchValue({ focus });
    const initialFilterSelection = this.getInitialFilterSelection(focus.filters);
    madlibModel.patchValue({ filter: initialFilterSelection });
    const initialGroupingSelection = this.getInitialGroupingSelection(focus.groupings);
    madlibModel.patchValue({ grouping: initialGroupingSelection });
    return madlibModel;
  }

  // non-focus change
  updateModelOnNonFocusChange (evt: { dimension: string; data: any }, madlibModel) {
    const { dimension, data } = evt;
    if (dimension === 'focus') this.updateModelOnFocusChange(data, madlibModel);
    else madlibModel.patchValue({ [dimension]: data });
    return madlibModel;
  }

  getDefaultFocus (fociPartials, params) {
    const selectedFocus = params?.focus;
    const selectedFocusExists = fociPartials.some(({ graphQlKey }) => selectedFocus === graphQlKey);
    // if a url contains a focus that is no longer showing, use the default (Jack)
    if (!selectedFocusExists) {
      return fociPartials.find(({ isDefault }) => isDefault).graphQlKey;
    }
    return params.focus;
  }

  getListDefaultFocus(fociPartials, params) {
    const selectedFocus = params?.focus;
    const flattenedFociPartials = fociPartials.flatMap(fociOptions => fociOptions.options);
    const selectedFocusExists = flattenedFociPartials.some(({ graphQlKey }) => selectedFocus === graphQlKey);
    // if a url contains a focus that is no longer showing, use the default (Jack)
    if (!selectedFocusExists) {
      const foundVal = flattenedFociPartials.find(({ isDefault, screenerType, district, defaultDuringTerms }) => { 
        if(isDefault) return isDefault;
        const currTerm = getCurrScreenerTermByDistrict(screenerType, district);
        return !!defaultDuringTerms && defaultDuringTerms.includes(currTerm);
      });
      return foundVal ? foundVal.graphQlKey : flattenedFociPartials[0].graphQlKey;
    }
    return params.focus;
  }

  getInitialFocusSelection (foci: any, focusKey?: string): any {
    let initialFocus: any;
    // manually flatten the nested options to find default (from url or config default)
    const flattenedFoci = foci.reduce((acc, focus) => {
      acc = focus.options ? [...acc, ...focus.options] : [...acc, focus];
      return acc;
    }, []);

    if (focusKey) { [initialFocus] = flattenedFoci.filter((focus: { graphQlKey: string }) => focus.graphQlKey === focusKey); } else [initialFocus] = flattenedFoci.filter((focus: { isDefault: boolean }) => focus.isDefault);
    // fallback to the first foci option as a last resort (this would happen if `isDefault` is not set for one of the foci options)
    return initialFocus || flattenedFoci[0];
  }

  private findFilterMatch (filters: any[], filterKey: string) {
    let foundOption = null;
    if (filterKey) {
      for (const filter of filters) {
        const nestedOpts = filter.options || filter.filterOptions;
        if (foundOption) {
          break;
        } else if (filter.graphQlKey === filterKey) {
          foundOption = filter;
        } else if (nestedOpts) {
          foundOption = this.findFilterMatch(nestedOpts, filterKey);
        }
      }
    }
    return foundOption;
  }

  getInitialFilterSelection (filters: any[], filterKey?: string): any {
    if (filterKey) {
      // set initialFilter to what is in the url
      const initialFilter = this.findFilterMatch(filters, filterKey);
      if (!initialFilter) {
        // when we come from certain urls, we are passing a hash for a filter
        // TODO remove when state param is not passing a hash for a filter
        return this.getDefaultFilter(filters);
      }

      return initialFilter;
    } else {
      return this.getDefaultFilter(filters);
    }
  }

  getDefaultFilter (filters: any[]): any {
    // set initialFilter to the default based on the initial focus
    const initialFilter = filters.reduce((initialFilter, { options }) => {
      const [match] = options.filter(option => option.isDefault);
      if (match) initialFilter = match;
      return initialFilter;
    }, null);

    const nonNestedDefault = filters.find(filter => filter?.isDefault);
    // we could end up with nothing due to misalignments
    // ie: user manually updates the url or the no focus option default is set in the configs
    // this is the final fallback to the first value in the filterOptions
    return initialFilter || filters[0]?.options[0] || nonNestedDefault || {};
  }

  getFormattedFoci (foci: IFocus[]): Array<{ foci: any[]; categoryLabel: string }> {
    const unsortedFociByCat = this.organizeFociByCategory(foci);
    const sortedFociByCat = this.sortFociByCategory(unsortedFociByCat);
    return this.formatFociForNvDropdown(sortedFociByCat);
  }

  formatFociForNvDropdown (fociByCat): Array<{ foci: any[]; categoryLabel: string }> {
    return fociByCat.reduce((accum, catData) => {
      const { foci, categoryLabel } = catData;
      if (foci.length > 1) {
        accum.push({
          key: categoryLabel,
          human: categoryLabel,
          options: this.formatNestedFociOptions(foci),
        });
      } else {
        const [nonNestedFocus] = foci;
        const formattedFocusOption = this.formatNonNestedFocusOption(nonNestedFocus);
        accum.push(formattedFocusOption);
      }
      return accum;
    }, []);
  }

  sortFociByCategory (unsortedFociByCat) {
    return unsortedFociByCat.reduce((result, unsortedCat) => {
      const { foci } = unsortedCat;
      const sortedFoci = sortBy(foci, ({ fociOrder }) => +fociOrder);
      result.push({ ...unsortedCat, ...{ foci: sortedFoci } });
      return result;
    }, []);
  }

  organizeFociByCategory (foci) {
    const catMap = foci.reduce((accum, focus) => {
      const { categoryOrder, category } = focus;
      if (!accum[categoryOrder]) accum[categoryOrder] = { foci: [focus], categoryLabel: category };
      else accum[categoryOrder].foci.push(focus);
      return accum;
    }, {});
    const organizedFoci = Object.keys(catMap).sort().reduce((acc, categoryOrder) => {
      const groupedFoci = catMap[categoryOrder];
      acc.push(groupedFoci);
      return acc;
    }, []);
    return organizedFoci;
  }

  formatNestedFociOptions (foci) {
    return foci.map(({ graphQlKey, label, pillPlaceholder, filters, groupings }) => {
      const option = {
        key: graphQlKey,
        graphQlKey,
        human: label,
        // maintain backwards compatiability (JJ)
        filters: filters ? this.formatFilters(filters) : [],
        groupings: groupings ? this.formatGroupings(groupings) : [],
      };
      const formatted = pillPlaceholder ? { pillPlaceholder, ...option } : option;
      return formatted;
    });
  }

  formatNonNestedFocusOption (focus) {
    const { graphQlKey: key, label: human, filters, groupings } = focus;
    return {
      ...focus,
      key,
      human,
      // maintain backwards compatiability (JJ)
      filters: filters ? this.formatFilters(filters) : [],
      groupings: groupings ? this.formatGroupings(groupings) : [],
    };
  }

  // These format functions are only required to massage the data into the shape necessary for the nv-dropdown
  // TODO: rework the backend configuration to be in this shape and remove all of this overhead(JJ)
  formatFocus (focus) {
    const { graphQlKey: key } = focus;
    const filters = this.formatFilters(focus.filters);
    const groupings = this.formatGroupings(focus.groupings);
    return { ...focus, key, filters, groupings };
  }

  private formatGroupingOptions (groupingOptions: IGrouping[] = []) {
    return groupingOptions.map(option => {
      const { graphQlKey: key, label: human, groupingOptions } = option;
      const formattedOptions = groupingOptions ? this.formatGroupingOptions(groupingOptions) : [];
      return { key, human, options: formattedOptions, ...option };
    });
  }

  formatGroupings (groupings) {
    return groupings.map((grouping: IGrouping) => {
      const { graphQlKey: key, label: human, groupingOptions } = grouping;
      const options = this.formatGroupingOptions(groupingOptions);
      return { key, human, ...grouping, options };
    });
  }

  setMadlibModel (formattedFocus, params) {
    const { filter: urlFilter, grouping: urlGrouping } = params;
    const filter = this.getInitialFilterSelection(formattedFocus.filters, urlFilter);
    const grouping = this.getInitialGroupingSelection(formattedFocus.groupings, urlGrouping);
    return new FormGroup({
      // need to format this
      focus: new FormControl(formattedFocus),
      filter: new FormControl(filter),
      grouping: new FormControl(grouping),
    });
  }

  private formatFilterOptions (filterOptions = []) {
    return filterOptions.map(option => {
      const { graphQlKey: key, label: human, filterOptions } = option;
      const formattedOptions = filterOptions ? this.formatFilterOptions(filterOptions) : [];
      return { key, human, options: formattedOptions, ...option };
    });
  }

  private formatFilters (filters) {
    return filters.map(({ label: humanName, graphQlKey, isDefault, filterOptions }) => {
      const options = this.formatFilterOptions(filterOptions);
      return { key: graphQlKey, human: humanName, label: humanName, graphQlKey, isDefault, options };
    });
  }

  getInitialGroupingSelection (groupings, groupingKey?: string): any {
    let initialGrouping: any;

    if (groupingKey) {
      const [graphQlKey, wildcardKey] = groupingKey.split('=');
      [initialGrouping] = groupings.filter(
        (grouping: { graphQlKey: string, wildcardKey: string }) => {
          return grouping.graphQlKey === graphQlKey && (!wildcardKey || grouping.wildcardKey === wildcardKey);
        },
      );
    } else {
      [initialGrouping] = groupings.filter((focus: { isDefault: boolean }) => focus.isDefault);
    }
    return initialGrouping || groupings[0];
  }

  getColumnDataBasedOnMadlibModel (
    madlibModel: FormGroup,
  ): { columns: any[]; columnIndexMap: { [key: string]: number } } {
    const { focus, grouping } = madlibModel.value;

    const columns = grouping.columns.map(col => {
      if (col.requiresWildcard) return { ...col, ...{ label: this.getWildcardColumnLabel(focus.label, col) } };
      else return col;
    });

    const columnIndexMap = columns.reduce((mapping, col: IColumnStuLvl, i: number) => {
      const key = col.colKey || col.graphQlKey;
      mapping[key] = i;
      return mapping;
    }, {});
    return { columns, columnIndexMap };
  }

  getWildcardColumnLabel (focusLabel: string, column) {
    const regex = /\[(.*?)\]/;
    const match = regex.exec(column.label);
    if (match) {
      const [textToReplace] = match;
      return column.label.replace(textToReplace, focusLabel);
    }
    return column.label;
  }

  getGroupingDataPayload (schoolId: string, { focus, filter, grouping }) {
    return {
      schoolId,
      filterKeys: filter && filter.graphQlKey ? [filter.graphQlKey] : [],
      groupingKey: this.wildcardService.getListsGroupingCalcKey({ focusKey: focus.graphQlKey, grouping }),
      columns: this.wildcardService.getListsColsWithCalcKeysComposed({ columns: grouping.columns, focusKey: focus.graphQlKey }),
    };
  }
}
