import { Auth, IDistrict } from 'Src/ng2/shared/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { catchError, switchMap, take, tap } from 'rxjs/operators';
import { PortalConfig } from 'Src/ng2/shared/services/portal-config';
import { IRouteConfigsOpts } from '../../route.config';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';
import { districtsConfig } from 'Src/ng2/shared/constants/districts-config.constant';

@Injectable()
export class DistrictPickerGuard implements CanActivate {
  constructor (
    private router: Router,
    private auth: Auth,
    private portalConfig: PortalConfig,
    private modalsService: ModalsService,
    private sessionStorageService: SessionStorageService,
  ) {}

  canActivate (): Observable<UrlTree|boolean> {
    const authenticated = this.auth.isAuthenticated();
    if (authenticated) {
      // Get user accessible districts
      return from(this.auth.getUserDistricts()).pipe(
        switchMap((districts) => {
          if (this.portalConfig.publicConfig.IS_NYC_DISTRICT) return this.handleNycDistrictPortalAccess(districts);
          else if (districts.length === 1) return this.handleSingleDistrictAccess(districts);
          else if (districts.length > 1) return of(true);
          else return this.handleNoDistrictAccess();
        }),
        catchError(this.handleError.bind(this)),
      );
    }
    // redirect to login if not authenticated
    return of(this.router.createUrlTree(['/login']));
  }

  private isValidDistrictForUser (currentDistrict: string, districts: IDistrict[]): boolean {
    if (!currentDistrict || !districts?.find(d => d._id === currentDistrict)) {
      return false;
    }
    return true;
  }

  private handleNycDistrictPortalAccess (districts: IDistrict[]): Observable<UrlTree|boolean> {
    const currentDistrict = districtsConfig.NYC_DISTRICT; // manually set to nyc if running nyc portal
    this.sessionStorageService.setItem('currentDistrict', currentDistrict);
    if (this.isValidDistrictForUser(currentDistrict, districts)) return of(this.router.createUrlTree(['/home']));
    const message = `Your user does have access to the ${currentDistrict} district. Please contact your administrator.`;
    this.showRedirectModal(message);
    return of(false);
  }

  private handleSingleDistrictAccess (districts: IDistrict[]): Observable<UrlTree|boolean> {
    // manually set to the first district if user only has access to 1 district
    this.sessionStorageService.setItem('currentDistrict', districts[0]._id);
    return of(this.router.createUrlTree(['/home']));
  }

  private handleNoDistrictAccess (): Observable<UrlTree|boolean> {
    const message = 'There isn\'t any district you can access. Please contact your administrator';
    this.showRedirectModal(message);
    return of(false);
  }

  private handleError (): Observable<UrlTree|boolean> {
    const message = 'There was an error retrieving your user districts. Please try again.';
    this.showRedirectModal(message);
    return of(false);
  }

  private showRedirectModal (message: string): void {
    const title = 'Unauthorized Access';
    this.modalsService.openErrorModal({ title, message })
      .afterClosed()
      .pipe(
        take(1),
        tap(() => this.auth.logout()))
      .subscribe();
  }
}
