import { IModalsConfig } from './../modals.config.interface';

export const EDIT_CONTACT_INFO_MODAL_CONFIG: IModalsConfig = {
  title: 'Edit Primary Contact Information',
  buttons: {
    confirm: {
      text: 'Save',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
