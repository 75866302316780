import { Component, ElementRef, HostBinding, HostListener, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Unsubscribable } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { IPickerOption } from '../nv-multi-picker.interface';
import { NvMultipickerMenuRef } from './nv-multipicker.ref';

/* istanbul ignore next */
// TODO - enable arrow key navigation
@Component({
  selector: 'nv-multi-picker-menu-content',
  templateUrl: './nv-multi-picker-menu-content.component.html',
  styleUrls: ['./nv-multi-picker-menu-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvMultiPickerMenuContentComponent {
  private _PADDING_LEFT = 8;
  private _PADDING_RIGHT = 2;
  private _SCROLLBAR_WIDTH = 12;
  private _TOTAL_PADDING = this._PADDING_LEFT + this._PADDING_RIGHT + this._SCROLLBAR_WIDTH;

  public options: IPickerOption[];
  public hasAllButton: boolean = true;
  public hasClearButton: boolean = true;
  public hasSearchBox: boolean = true;
  public selectedOptions: object = {};

  @ViewChild('pillsContainer', { static: true }) pillsContainer: ElementRef;

  @HostBinding('style.max-width') maxWidth: string;
  @HostBinding('class.is-open') menuIsOpen: boolean;
  @HostListener('keyup', ['$event']) handleKeyup (e) {
    if (e.key === 'Escape') {
      this.menuRef.closeMenu();
    }
  }

  searchboxForm: FormControl;
  shownOptions: IPickerOption[];

  private _debounce_time: number = 50; // millis
  private _destroy$: Subject<boolean> = new Subject<boolean>();
  private _optionsSubscription: Unsubscribable;
  private _clickawaySubscription: Unsubscribable;

  constructor (private menuRef: NvMultipickerMenuRef) {
    this.options = menuRef.props.options;
    this.hasAllButton = menuRef.props.hasAllButton;
    this.hasClearButton = menuRef.props.hasClearButton;
    this.hasSearchBox = menuRef.props.hasSearchBox;
    // initialize options
    this.selectedOptions = menuRef.props.initialSelection;
  }

  ngOnInit (): void {
    this.searchboxForm = new FormControl('');
    this.shownOptions = this.options;

    // subscribe to changes
    this._optionsSubscription = this.menuRef.props.selectedOption$.subscribe(selection => {
      this.selectedOptions = selection;
    });

    this._clickawaySubscription = this.menuRef.initClickaway();
  }

  ngOnChanges (changes: SimpleChanges): void {
    const { options } = changes;
    if (options && options.currentValue) this.shownOptions = options.currentValue;
  }

  ngAfterViewInit (): void {
    this.searchboxForm.valueChanges
      .pipe(
        debounceTime(this._debounce_time),
        distinctUntilChanged(),
        takeUntil(this._destroy$),
        tap(value => this.onSearchValueChange(value)),
      )
      .subscribe(null);
    this.setMaxWidth();
  }

  ngOnDestroy (): void {
    this._optionsSubscription.unsubscribe();
    this._clickawaySubscription.unsubscribe();
  }

  private setMaxWidth () {
    // Search through all the pills and find the biggest one
    // set the max-width of the panel to fit that pill
    let maxPillWidth = 0;
    setTimeout(() => {
      for (const pill of this.pillsContainer.nativeElement.children) {
        const w = pill.offsetWidth + this._TOTAL_PADDING;
        maxPillWidth = w > maxPillWidth ? w : maxPillWidth;
      }
      this.maxWidth = `${maxPillWidth}px`;
      this.menuIsOpen = true; // internal prop used to animate the menu
    }, 0);
  }

  onSearchValueChange (value: string): void {
    value = value.toLowerCase();
    this.shownOptions = this.options.filter(opt => {
      return opt.key.includes(value) || opt.human.toLowerCase().includes(value);
    });
  }

  clearSearchbox () {
    this.searchboxForm.setValue('');
  }

  clickPill (key: string) {
    // key can be 'all', 'clear', or a selection key
    this.menuRef.emitUpdate(key); // emit the new value up to the parent
  }

  /* tslint:disable:no-unused-variable */
  // It's used in the template
  isKeySelected (key: string): boolean {
    return !!this.selectedOptions[key];
  }
}
