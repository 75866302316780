import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IStudentActivitiesState } from './../reducers/student-activities-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getStudentActivitiesState = createSelector(
  getPortalState,
  (state: any) => {
    return state.studentActivities;
  },
);

export const getStudentActivitiesEntities = createSelector(
  getStudentActivitiesState,
  (state: IStudentActivitiesState) => {
    return state.studentActivitiesEntities;
  },
);

export const getStudentActivitiesEntitiesList = createSelector(
  getStudentActivitiesState,
  (state: IStudentActivitiesState) => {
    const studentActivitiesEntities = state.studentActivitiesEntities;
    return map(studentActivitiesEntities, (v, k) => v);
  },
);

export const getStudentActivitiesLoadingStatus = createSelector(
  getStudentActivitiesState,
  (state: IStudentActivitiesState) => {
    return state.loading;
  },
);

export const getStudentActivitiesLoadedStatus = createSelector(
  getStudentActivitiesState,
  (state: IStudentActivitiesState) => {
    return state.loaded;
  },
);
