import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, compact, concat, find, includes, isEqual, map, reduce, sortBy, uniqBy, filter, some } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { IPickerOption } from '../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { ACTIVITY_SECTORS, ACTIVITY_TYPES } from '../../constants/activities.constant';
import { ImSchool } from '../../services/im-models/im-school';
import {
  IActivity,
  ICreateActivityParams,
  ILinkedCourse,
  IUpdateActivityParams,
} from '../../typings/interfaces/activity.interface';
import { IACOption, IDropdownOption } from '../../typings/interfaces/design-library.interface';
import { ICourse, ISchool, ITermInfo, ITermInfoMini } from '../../typings/interfaces/school.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { DateHelpers } from './../../../shared/services/date-helpers/date-helpers.service';
import { getSchool, getSchoolLoadedStatus } from './../../../store';
import { CreateActivity, UpdateActivity } from './../../../store/actions/activities-actions';
import { CreatePartnerOrg, LoadPartnerOrgs } from './../../../store/actions/partner-orgs-actions';
import { LoadSchool } from './../../../store/actions/school-actions';
import { getPartnerOrgsLoadedStatus, getPartnerOrgsEntitiesList } from './../../../store/selectors/partner-orgs-selectors';

import { ACTIVITIES_MODAL_CONFIG } from './activities-modal.config';

export interface IActivitiesModalComponentData extends IBaseModalData {
  schoolId: string;
  mode: string;
  activity?: IActivity;
}

@Component({
  selector: 'activities-modal',
  templateUrl: './activities-modal.component.html',
  styleUrls: ['./activities-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivitiesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public activityForm: FormGroup;
  public dateRangeSubscription: Subscription;
  public termPickerSubscription: Subscription;
  public formSubscription: Subscription;
  public destroy$: Subject<boolean> = new Subject<boolean>();

  // Modal Configurations
  public isEditMode: boolean;
  public schoolId: string;
  public school: ISchool;
  public count: number;
  readonly buttons = ACTIVITIES_MODAL_CONFIG.buttons;
  public itemCount: number;
  public itemType: string;
  public title: string;
  public confirmationButtonLabel: string;

  public termEndDate: string;
  public searchIcon = 'search';
  public emptyStateIcon = 'add';
  public currentTerm;
  public activitySchoolYear: string;

  // activity type properties
  public filteredTypeOptions: IACOption[];
  public allActivityTypeOptions: IACOption[];
  public typePlaceholder: string = 'Select an activity type...';
  public typeEmptyState: string = 'No activity types found';

  // partner org properties
  public filteredPartnerOrgOptions: IACOption[];
  public partnerOrgPlaceholder: string = 'Select an organization...';
  public partnerOrgOptions: IACOption[];
  public partnerOrgEmptyState: string = 'No organizations found';
  public partnerOrgSelection: string;
  public duplicatePartnerOrg: boolean = true; // true by default to avoid allowing duplicates organizations when editing an activity

  // sector properties
  public sectorOptions: IDropdownOption[];
  public sectorSelection: string;
  public sectorPlaceholder = 'Select a sector';

  // term picker properties
  public orderedTermDates: ITermInfo[];
  public termPickerStartOptions: IDropdownOption[] = [];
  public termPickerEndOptions: IDropdownOption[] = [];
  // public selectedTerms: { startTerm: IDropdownOption; endTerm: IDropdownOption };
  public selectedTerms;
  public termPickerOptionsMap: Map<string, any> = new Map();
  public termYearArray: string[] = [];

  // date properties
  public initialStartDate: string;
  public initialEndDate: string;

  // linked courses properties
  public courseCodes: IPickerOption[];
  public coursesPlaceholder = 'Select a course';
  public linkedCoursesTooltip =
    'To enable the hours in this activity to count towards CDOS, the activity must be linked to a course coded for CDOS or CTE.';

  public selectedCourseCodes: string[] = [];
  public originalCourses: string[] = [];
  public codesMetadataMap: { [key: string]: ILinkedCourse };
  public linkedCoursesInfoText: string = null;
  public isDisabled: boolean;

  // projected hours properties
  public hoursSelection: number;
  public hoursPlaceholderIsEnabled: boolean = true;
  public hoursPlaceholder: string = 'Enter projected hours if applicable';
  public hoursIcon = 'time';

  // used to disable 'save' button if no change has been made to activity (JE)
  public originalForm;

  constructor (
    dialogRef: MatDialogRef<ActivitiesModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IActivitiesModalComponentData,
    private store: Store<any>,
    private imSchool: ImSchool,
    private dateHelpers: DateHelpers,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, isProfileMode, schoolId, activity } = cloneDeep(this.data);
    this.isEditMode = mode === 'UPDATE';
    this.isProfileMode = isProfileMode;
    this.schoolId = schoolId;
    this.initialStartDate = this.dateHelpers.getFormattedNow('YYYY-MM-DD');
    this.allActivityTypeOptions = map(ACTIVITY_TYPES, type => {
      return {
        key: type,
        human: type,
      };
    });
    this.filteredTypeOptions = this.allActivityTypeOptions;

    const sectors = map(ACTIVITY_SECTORS, option => ({ key: option, human: option }));
    const selectASector = { key: 'Select a sector', human: 'Select a sector' };
    this.sectorOptions = [selectASector, ...sectors];

    // get PartnerOrgData
    this.store
      .select(getPartnerOrgsLoadedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          const payload = { schoolId: this.data.schoolId };
          this.store.dispatch(new LoadPartnerOrgs(payload));
        } else {
          this.store
            .select(getPartnerOrgsEntitiesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe(partnerOrgs => {
              const sortedOrgs = sortBy(partnerOrgs, org => org.name.toLowerCase());
              this.partnerOrgOptions = map(sortedOrgs, org => {
                return {
                  key: org._id,
                  human: org.name,
                };
              });
              const filter = this.activityForm ? this.activityForm.controls.partnerOrg.value : '';
              this.filteredPartnerOrgOptions = this.filterPartnerOrg(filter);
            });
        }
      });

    // get School data
    this.store
      .select(getSchoolLoadedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          const payload = { schoolId: this.data.schoolId };
          this.store.dispatch(new LoadSchool(payload));
        } else {
          this.store
            .select(getSchool)
            .pipe(takeUntil(this.destroy$))
            .subscribe(school => {
              this.school = cloneDeep(school);
              this.termEndDate = this.imSchool.getEndDateForCurrentTerm(this.school);
              this.initialEndDate = this.termEndDate;

              // term menu options
              this.orderedTermDates = this.imSchool.getOrderedTermInfoArray(this.school);
              this.orderedTermDates.forEach((term, index) => {
                // parse termInfo school year to be SY##-## format
                // const parsedSchoolYear = 'SY' + term.schoolYear.slice(4);
                // term.schoolYear = parsedSchoolYear;
                const human = `${term.termId === '7' ? 'Summer' : `Term ${term.termId}`} ${term.schoolYear}`;
                const option = { key: term.yearTerm, human };
                const termWithDropdownOption = { ...term, option, index };
                this.termPickerStartOptions.push(option);
                this.termPickerOptionsMap.set(term.yearTerm, termWithDropdownOption);
              });

              const currentTermYear = this.imSchool.getEffectiveCurrentTermYear(school);
              this.currentTerm = this.termPickerOptionsMap.get(`${currentTermYear}`);
              this.termPickerEndOptions = this.termPickerStartOptions.slice(0, this.currentTerm.index + 1);

              // course codes
              this.codesMetadataMap = this._createCourseCodeOptionsMap(school);
            });
        }
      }); // covered in another branch (JE)

    // update modal UI
    if (mode === 'CREATE') {
      this.title = 'Create new activity';
      this.confirmationButtonLabel = 'Create';
    } else if (mode === 'UPDATE') {
      this.title = 'Edit activity';
      this.confirmationButtonLabel = 'Save';
    }

    // activity passed in to edit
    if (activity && this.isEditMode) {
      const {
        type,
        partnerOrg,
        sector,
        terms,
        startDate,
        endDate,
        hours,
        linkedCourses,
        hoursCountedInCourse,
      } = activity;
      const { startTerm, endTerm, termRange } = terms;
      this.sectorSelection = sector;
      this.initialStartDate = startDate;
      this.initialEndDate = endDate;
      const codes = map(linkedCourses, course => `${course.name} (${course.code})`);
      this.selectedCourseCodes = codes;
      this.originalCourses = [...this.selectedCourseCodes].sort();
      this.termYearArray = termRange;
      this._filterCoursesByTerm(this.termYearArray);
      this._updateLinkedCourseDisableState();
      this.selectedTerms = {
        startTerm: this.termPickerOptionsMap.get(startTerm.yearTerm),
        endTerm: this.termPickerOptionsMap.get(endTerm.yearTerm),
      };
      this.termPickerEndOptions = this.termPickerStartOptions.slice(
        0,
        this.termPickerOptionsMap.get(this.selectedTerms.startTerm.option.key).index + 1,
      );
      const dateRange = new FormGroup({
        startDate: new FormControl(startDate, []),
        endDate: new FormControl(endDate, []),
      });
      const termPicker = new FormGroup({
        startTerm: new FormControl(startTerm.yearTerm, []),
        endTerm: new FormControl(endTerm.yearTerm, []),
      });
      // build form
      this.activityForm = this.formBuilder.group({
        type: [type, [Validators.required, ActivitiesModalComponent.typeValidator]],
        partnerOrg: [partnerOrg.name, Validators.required],
        sector: [sector],
        dateRange: [dateRange, Validators.required],
        termPicker: [termPicker, Validators.required],
        hours: [hours],
        hoursCountedInCourse: [hoursCountedInCourse],
      });
      this.originalForm = cloneDeep(this.activityForm.value);
      this.dateRangeSubscription = dateRange.valueChanges.subscribe(val => {
        if (val.startDate && val.startDate !== this.originalForm.dateRange.controls.startDate.value) {
          this.activityForm.markAsDirty();
          this.activityForm.controls.dateRange.value.controls.startDate.markAsDirty();
        }
        if (val.endDate && val.endDate !== this.originalForm.dateRange.controls.endDate.value) {
          this.activityForm.markAsDirty();
          this.activityForm.controls.dateRange.value.controls.endDate.markAsDirty();
        }
      });
      this.termPickerSubscription = termPicker.valueChanges.subscribe(val => {
        if (val.startTerm && val.startTerm !== this.originalForm.termPicker.controls.startTerm.value) {
          this.activityForm.markAsDirty();
          this.activityForm.controls.termPicker.value.controls.startTerm.markAsDirty();
        }
        if (val.endTerm && val.endTerm !== this.originalForm.termPicker.controls.endTerm.value) {
          this.activityForm.markAsDirty();
          this.activityForm.controls.termPicker.value.controls.endTerm.markAsDirty();
        }
      });
      this.partnerOrgSelection = partnerOrg.name;
    } else {
      this.selectedCourseCodes = [];
      this.termYearArray = [this.currentTerm.yearTerm];
      this._filterCoursesByTerm(this.termYearArray);
      this._updateLinkedCourseDisableState();
      this.selectedTerms = {
        startTerm: { ...this.currentTerm },
        endTerm: { ...this.currentTerm },
      };
      const termPicker = new FormGroup({
        startTerm: new FormControl(this.selectedTerms.startTerm.option.key, []),
        endTerm: new FormControl(this.selectedTerms.endTerm.option.key, []),
      });
      const dateRange = new FormGroup({
        startDate: new FormControl(this.currentTerm.termStartDate, []),
        endDate: new FormControl(this.currentTerm.termEndDate, []),
      });
      // build form
      this.activityForm = this.formBuilder.group({
        type: [null, [Validators.required, ActivitiesModalComponent.typeValidator]],
        partnerOrg: [null, Validators.required],
        sector: [null],
        termPicker: [termPicker, Validators.required],
        dateRange: [dateRange, Validators.required],
        hours: [null],
        hoursCountedInCourse: [null],
      });
      this.originalForm = cloneDeep(this.activityForm.value);
    }
    /* istanbul ignore next */
    this.formSubscription = this.activityForm.valueChanges.subscribe(changes => {
      const { type, partnerOrg, hours } = changes;
      if (!!type || type === '') {
        const typeFilter = type.human ? type.human : type;
        this.filteredTypeOptions = this.filterType(typeFilter);
      }
      if (!!partnerOrg || partnerOrg === '') {
        // while the user types, partnerOrg is a string, when it's clicked, becomes an object
        // disables the ability of adding a new partnerOrg if it already exits
        if(typeof partnerOrg === 'string') this.duplicatePartnerOrg = this.isDuplicatePartnerOrg(this.partnerOrgOptions, partnerOrg);
        const partnerOrgFilter = partnerOrg.human ? partnerOrg.human : partnerOrg;
        this.filteredPartnerOrgOptions = this.filterPartnerOrg(partnerOrgFilter);
        // allows user to directly type in name of partner org to make selection
        const exactMatch = find(this.filteredPartnerOrgOptions, org => {
          return org.human.toLowerCase() === partnerOrgFilter.toLowerCase();
        });
        this.partnerOrgSelection = exactMatch ? partnerOrg : null;
      }
      if (hours || hours === 0) this.hoursPlaceholderIsEnabled = false;
      else this.hoursPlaceholderIsEnabled = true;
      // check form to see if any values have been changed (JE)
      // used to disable the 'save' button when editing a form
      let changed = false;
      for (const prop in changes) {
        if (changes[prop] === this.originalForm[prop]) {
          this.activityForm.get(prop).markAsPristine();
        } else {
          this.activityForm.get(prop).markAsDirty();
          changed = true;
        }
      }
      if (changed) this.activityForm.markAsDirty();
    });

    this.hoursPlaceholderIsEnabled = true;
  }

  public static typeValidator (control: AbstractControl) {
    return ACTIVITY_TYPES.includes(control.value)
      ? null
      : {
        validateType: {
          valid: false,
        },
      };
  }

  // TODO: refactor nv-multi-picker to accept a formControl directly to avoid using this (JE)
  get disableConfirm (): Boolean {
    if (!this.activityForm.valid) return true;
    const sortedSelected = this.selectedCourseCodes.sort();
    const coursesChanged = !isEqual(sortedSelected, this.originalCourses);
    const { type, partnerOrg, sector, termPicker, dateRange, hours, hoursCountedInCourse } = this.activityForm.controls;
    const typeChanged = type.value !== this.originalForm.type;
    const partnerOrgChanged = partnerOrg.value !== this.originalForm.partnerOrg;
    const sectorChanged = sector.value !== this.originalForm.sector;
    const hoursChanged = hours.value !== this.originalForm.hours;
    const startDateChanged = dateRange.value.value.startDate !== this.originalForm.dateRange.value.startDate;
    const endDateChanged = dateRange.value.value.endDate !== this.originalForm.dateRange.value.endDate;
    const startTermChanged = termPicker.value.value.startTerm !== this.originalForm.termPicker.value.startTerm;
    const endTermChanged = termPicker.value.value.endTerm !== this.originalForm.termPicker.value.endTerm;
    const hoursCountedInCourseChanged = hoursCountedInCourse.value !== this.originalForm.hoursCountedInCourse;
    const formChanged =
      typeChanged ||
      partnerOrgChanged ||
      sectorChanged ||
      hoursChanged ||
      startDateChanged ||
      endDateChanged ||
      coursesChanged ||
      startTermChanged ||
      endTermChanged ||
      hoursCountedInCourseChanged;
    if (this.isEditMode) {
      return (!formChanged && !coursesChanged) || !this.partnerOrgSelection;
    } else {
      return !this.activityForm.valid || !this.partnerOrgSelection;
    }
  }

  ngOnDestroy () {
    if (this.isEditMode) {
      this.dateRangeSubscription.unsubscribe();
      this.termPickerSubscription.unsubscribe();
    }
    this.formSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public filterType (value: string): IACOption[] {
    if (!value) {
      return this.allActivityTypeOptions;
    }
    const filterValue = value.toLowerCase();
    const filteredTypes = this.allActivityTypeOptions.filter(option =>
      option.human.toLowerCase().includes(filterValue),
    );
    return filteredTypes;
  }

  public filterPartnerOrg (value?: string): IACOption[] {
    if (!value || value === '') {
      return this.partnerOrgOptions;
    } else {
      const filterValue = value.toLowerCase().trim();
      const filteredPartnerOrgs = this.partnerOrgOptions.filter(partnerOrg => {
        const { human } = partnerOrg;
        return human.toLowerCase().includes(filterValue);
      });
      return filteredPartnerOrgs;
    }
  }

  public close (): void {
    super.close();
  }

  submit (form: FormGroup) {
    if (this.disableConfirm) return;
    if (this.isEditMode) {
      this.updateActivity(form);
    } else {
      this.createActivity(form);
    }
  }

  public updateActivity (form: FormGroup) {
    if (!this.disableConfirm && form.valid) {
      const {
        controls: { type, sector, hours, partnerOrg, dateRange, termPicker, hoursCountedInCourse },
      } = form;
      const { startDate, endDate } = dateRange.value.controls;
      const { startTerm, endTerm } = termPicker.value.controls;
      const patch: IUpdateActivityParams = {};
      if (type.dirty) patch.type = type.value;
      if (sector.dirty) patch.sector = sector.value;
      if (startDate.dirty) patch.startDate = startDate.value;
      if (endDate.dirty) patch.endDate = endDate.value;
      if (hours.dirty) patch.hours = hours.value ? hours.value : 0;
      if (!isEqual(this.selectedCourseCodes, this.originalCourses)) {
        patch.linkedCourses = map(this.selectedCourseCodes, code => this.codesMetadataMap[code]);
      }
      if (partnerOrg.dirty) {
        const org = find(this.partnerOrgOptions, (option: IACOption) => {
          return option.human === partnerOrg.value;
        });
        patch.partnerOrg = {
          _id: org.key,
          name: org.human,
        };
      }
      if (startTerm.dirty || endTerm.dirty) {
        const startTermMini = this._getTermMini(startTerm.value);
        const endTermMini = this._getTermMini(endTerm.value);
        patch.terms = {
          startTerm: startTermMini,
          endTerm: endTermMini,
          termRange: this.termYearArray,
        };
      }
      if (this.selectedCourseCodes.length === 0) patch.hoursCountedInCourse = false;
      // clears value if courses are de-selected (JE)
      else if (hoursCountedInCourse.dirty) patch.hoursCountedInCourse = hoursCountedInCourse.value;
      // only dispatch action if patch contains a change
      if (Object.keys(patch).length > 0) {
        this.store.dispatch(new UpdateActivity({ activityId: this.data.activity._id, patch }));
      }
      super.close();
    }
  }

  public createActivity (form: FormGroup): void {
    if (form.valid) {
      const { type, partnerOrg, sector, termPicker, dateRange, hours, hoursCountedInCourse } = form.value;
      const parsedHours = hours === 0 ? null : hours;
      // get id for selected partnerOrg
      const matchedOrg = find(this.partnerOrgOptions, org => {
        return org.human === partnerOrg;
      });
      const selectedOrg = {
        _id: matchedOrg.key,
        name: matchedOrg.human,
      };

      const startTermMini = this._getTermMini(termPicker.value.startTerm);
      const endTermMini = this._getTermMini(termPicker.value.endTerm);
      const terms = {
        startTerm: startTermMini,
        endTerm: endTermMini,
        termRange: this.termYearArray,
      };

      const linkedCourses = map(this.selectedCourseCodes, code => this.codesMetadataMap[code]);

      const payload: ICreateActivityParams = {
        schoolId: this.schoolId,
        partnerOrg: selectedOrg,
        startDate: dateRange.value.startDate,
        endDate: dateRange.value.endDate,
        hours: parsedHours,
        terms,
        sector,
        type,
        linkedCourses,
        hoursCountedInCourse,
      };

      // dispatch action to create new activity (JE)
      this.store.dispatch(new CreateActivity({ activityParams: payload }));

      // pass payload on close so that StudentActivitiesModal can pre-populate new activity (JE)
      super.close(payload);
    }
  }

  setType (selection: string): void {
    // handles for double-click resulting in 'bubble' object being passed from nv-textbox (JE)
    if (typeof selection === 'string' || !selection) {
      this.activityForm.patchValue({ type: selection });
      this.filteredTypeOptions = this.filterType(selection);
    }
  }

  clearType (): void {
    this.setType(null);
  }

  setPartnerOrg (selection: string): void {
    // handles for double-click resulting in 'bubble' object being passed from nv-textbox (JE)
    if (typeof selection === 'string' || !selection) {
      this.partnerOrgSelection = selection;
      this.activityForm.patchValue({ partnerOrg: selection });
      this.filteredPartnerOrgOptions = this.filterPartnerOrg(selection);
    }
  }

  clearPartnerOrg (): void {
    this.setPartnerOrg(null);
  }

  clearHours (): void {
    this.activityForm.patchValue({ hours: null });
  }

  public createPartnerOrg (): void {
    const { partnerOrg } = this.activityForm.value;
    if (partnerOrg) {
      const orgName = partnerOrg.trim(); // remove accidental whitespace to avoid duplicates
      const payload = {
        schoolId: this.schoolId,
        partnerOrgParams: {
          name: orgName,
        },
      };
      this.partnerOrgSelection = partnerOrg;
      this.store.dispatch(new CreatePartnerOrg(payload));
      this.duplicatePartnerOrg = true;
    }
  }
  
  public isDuplicatePartnerOrg (partnerOrgOptions: IACOption[], orgName: string): boolean {
    return some(partnerOrgOptions, (option)  => {
      return option.human.trim().toLowerCase() === orgName.trim().toLowerCase();
    });
  }

  public updateSector (option: string) {
    const parsedOption = option === 'Select a sector' ? null : option;
    this.activityForm.patchValue({ sector: parsedOption });
    this.sectorSelection = parsedOption;
  }

  public isSectorActive (option: string) {
    return option === this.sectorSelection;
  }

  public onTermPickerSelect (optionKey: IDropdownOption['key'], picker: 'start' | 'end') {
    const selectedTerm = this.termPickerOptionsMap.get(optionKey);
    const { termPicker, dateRange } = this.activityForm.controls;

    if (picker === 'start') {
      // update dropdown
      this.selectedTerms = {
        startTerm: selectedTerm,
        endTerm: selectedTerm,
      };

      // update term picker form
      termPicker.value.setValue({
        startTerm: this.selectedTerms.startTerm.option.key,
        endTerm: this.selectedTerms.endTerm.option.key,
      });

      // update end term dropdown options
      this.termPickerEndOptions = this.termPickerStartOptions.slice(0, selectedTerm.index + 1);

      // update date range form
      dateRange.value.patchValue({
        startDate: selectedTerm.termStartDate,
        endDate: selectedTerm.termEndDate,
      });
    } else {
      // only update end term and date pickers
      this.selectedTerms.endTerm = selectedTerm;
      termPicker.value.patchValue({ endTerm: this.selectedTerms.endTerm.option.key });
      dateRange.value.patchValue({ endDate: selectedTerm.termEndDate });
    }

    // update term range
    const startIndex = this.termPickerOptionsMap.get(termPicker.value.value.startTerm).index;
    const endIndex = this.termPickerOptionsMap.get(termPicker.value.value.endTerm).index;
    const termRange = this.orderedTermDates.slice(endIndex, startIndex + 1);
    this.termYearArray = map(termRange, term => term.yearTerm);

    // update linked courses
    this._filterCoursesByTerm(this.termYearArray);
    this.selectedCourseCodes = filter(this.selectedCourseCodes, code => {
      const courseTermYear = `${this.codesMetadataMap[code].termYear}`;
      return this.termYearArray.includes(courseTermYear);
    });
    // reset checkbox if no courses are selected
    if (this.selectedCourseCodes.length === 0) this.activityForm.controls.hoursCountedInCourse.setValue(false);
    // update the linkedCoursesInfoText depending on terms
    if (this.currentTerm.yearTerm < termRange[0].yearTerm) {
      this.linkedCoursesInfoText =
        'Courses for upcoming terms will appear in the linked courses menu once they have been programmed.';
    } else {
      this.linkedCoursesInfoText = null;
    }
    this._updateLinkedCourseDisableState();
  }

  _createCourseCodeOptionsMap (school: ISchool): { [key: string]: ILinkedCourse } {
    const { masterSchedule, masterSchedulePriorYr } = school;
    const currentAndPrevYrSchedules = compact(concat(masterSchedule, masterSchedulePriorYr));
    const uniqueCodes = sortBy(uniqBy(currentAndPrevYrSchedules, 'courseCode'), 'courseCode');
    return reduce(
      uniqueCodes,
      (res, course: ICourse) => {
        const { courseCode, courseName, schoolYear, creditValue, termYear } = course;
        const courseNameCode = `${courseName} (${courseCode})`;
        if (!res[courseNameCode]) {
          res[courseNameCode] = {
            code: courseCode,
            name: courseName,
            schoolYear,
            creditValue,
            termYear,
          };
        }
        return res;
      },
      {},
    );
  }

  _updateLinkedCourseDisableState () {
    this.isDisabled = this.courseCodes.length === 0;
  }

  _filterCoursesByTerm (terms: string[]) {
    const filteredCourses = filter(this.codesMetadataMap, course => {
      const effectiveCourseTermYear = this.imSchool.getEffectiveCourseTermYear(this.school, course);
      return includes(terms, `${effectiveCourseTermYear}`);
    });
    const codes = map(filteredCourses, course => {
      const { code, name } = course;
      const courseNameCode = `${name} (${code})`;
      return {
        key: courseNameCode,
        human: courseNameCode,
      };
    });
    this.courseCodes = sortBy(codes, code => code.human);
  }

  _getTermMini (termYear: string): ITermInfoMini {
    const term = this.termPickerOptionsMap.get(termYear);
    const { yearTerm, schoolYear, termStartDate, termEndDate } = term;
    return { yearTerm, schoolYear, termStartDate, termEndDate };
  }

  setHoursCountedInCourse (checked): void {
    this.activityForm.controls.hoursCountedInCourse.setValue(checked);
  }
}
