import { Injectable } from '@angular/core';
import { groupBy } from 'lodash';
import { IGroupData, IRowData } from 'Src/ng2/shared/models/list-models';
import { IStudentSelSupport } from 'Src/ng2/shared/typings/interfaces/student-sel-support.interface';
import { DateHelpers } from './../../../../shared/services/date-helpers/date-helpers.service';

export interface IGroupedHistoricSupportData {
  [groupBy: string]: IStudentSelSupport[];
}

@Injectable()
export class StudentSelSupportsPanelListDataService {
  constructor(private dateHelpers: DateHelpers) {}

  formatHistoricalGroupData(supportsByYear: IGroupedHistoricSupportData): IGroupData[] {
    const groupData = Object.entries(supportsByYear).reduce((acc: any, supportsByYear) => {
      const shortenedSchoolYear = supportsByYear[0].slice(4);
      const rowData: IRowData[][] = this.getRowData(supportsByYear[1]);
      acc.push(
        {
          human: `SY${shortenedSchoolYear}`,
          rowData,
        }
      );
      return acc;
    }, []);
    return groupData;
  }

  formatCurrentSchoolYearData(supports: IStudentSelSupport[]): IGroupData[] {
    const rowData: IRowData[][] = this.getRowData(supports);
    const groupData = [{
      human: 'SEL Supports',
      rowData,
    }];
    return groupData;
  }

  getRowData(supports: IStudentSelSupport[]): IRowData[][] {
    const rowData = supports.reduce((acc, { support }) => {
      const { interventionType, tier, status, frequency, startDate, endDate } = support;
      const formattedDateRange = this.getSupportDateRange(startDate, endDate);
      const formattedFrequency = this.capitalizeOnlyFirstLetter(frequency);
      const formattedStatus = this.capitalizeOnlyFirstLetter(status);

      acc.push([
        {
          columnKey: 'SEL_SUPPORTS',
          data: interventionType,
        },
        {
          columnKey: 'TIER',
          data: tier,
        },
        {
          columnKey: 'STUDENT_SUPPORT_STATUS',
          data: formattedStatus,
        },
        {
          columnKey: 'FREQUENCY',
          data: formattedFrequency,
        },
        {
          columnKey: 'STUDENT_START_AND_END_DATE',
          data: formattedDateRange,
        },
      ]);
      return acc;
    }, []);
    return rowData;
  }

  groupSupportData(supports: IStudentSelSupport[]): IGroupedHistoricSupportData { 
    return groupBy(supports, 'support.schoolYear');
  }

  filterForCurrentSchoolYearSupports(supports: IStudentSelSupport[], currSY: string): IStudentSelSupport[] {
    const currentSupports = supports.filter(({ support }) => {
      const { schoolYear } = support;
      return schoolYear === currSY;
    });
    return currentSupports;
  }

  getSupportDateRange(startDate: string, endDate: string): string {
    const dateFormat = 'll';
    if (!startDate && !endDate) return null;
    const start = startDate ? this.dateHelpers.getFormattedMomentFromDate(startDate, dateFormat) : 'N/A';
    const end = endDate ? this.dateHelpers.getFormattedMomentFromDate(endDate, dateFormat) : 'N/A';
    // If dates are equal, only return start date; otherwise return both
    const dateRange = (start === end) ? `${start}` : `${start} - ${end}`;
    return dateRange;
  }

  capitalizeOnlyFirstLetter(text: string): string {
    if (!text) return null;
    const lower = text.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1);
  }
}
