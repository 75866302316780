import { Component, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { NvTextboxComponent } from '../nv-textbox/nv-textbox.component';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

/**
 *
 * The Virtual Scroll Textbox allows for faster performance for large datasets in an autocomplete dropdown
 * and extends the functionality of `nv-textbox`. Additionally, it allows for highlighting the active selection
 * for an autocomplete.
 *
 */
@Component({
  selector: 'nv-textbox-virtual-scroll',
  templateUrl: './nv-textbox-virtual-scroll.component.html',
  styleUrls: ['../nv-textbox/nv-textbox.scss', './nv-textbox-virtual-scroll.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvTextboxVirtualScrollComponent extends NvTextboxComponent {
  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  // custom height, if none provided, defaults to 256px (declared in scss)
  @Input() customViewportHeight: string = '';

  // custom styling to override, defaults to empty string (no custom styling)
  @Input() customStylingWrapperId: string = '';

  // TODO - Consider move the following two into the parent nv-textbox
  // decides whether the current selected option has a visual active state, defaults to false;
  @Input() autocompleteHasActiveState: boolean = false;

  // current selected option;
  @Input() selected: any = null;

  public scrollToIndex (index: number) {
    this.viewport.scrollToIndex(index);
  }
}
