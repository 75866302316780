import { intersection, union } from 'lodash';
import { PartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import {
  IUser,
  IUserMiniWithRole,
} from '../../../typings/interfaces/user.interface';
import { IM_USER_CONSTANTS } from './../im-user';
import { ImUserCore } from './im-user-core';
export class ImUserSchool extends ImUserCore {
  isSchoolUser (user: IUser): boolean {
    const role = this.getRoleType(user, PartnerTypes.SCHOOL);
    return IM_USER_CONSTANTS.SINGLE_SCHOOL_ROLES.includes(role as any);
  }

  isMultiSchoolUser (user: IUser | IUserMiniWithRole): boolean {
    const role = this.getRoleType(user, PartnerTypes.SCHOOL);
    return IM_USER_CONSTANTS.MULTI_SCHOOL_ROLES.includes(role as any);
  }

  public isSchoolLevelUser (user: IUser): boolean {
    return this.isSchoolUser(user) || this.isMultiSchoolUser(user);
  }

  isNonCaseloadSchoolClusterOrDelegatedAdmin (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return IM_USER_CONSTANTS.NON_CASELOAD_EDITING_ROLES.includes(role as any);
  }

  // Decide if user has school 'edit_all' roles
  isSchoolWideEditor (user: IUser, opts?: { partnerType?, partnerId? }): boolean {
    const role = this.getRoleTypeOnAllLevels(user, opts);
    return role === 'edit_all';
  }

  // Decide if user can access UFT DOE Advising page
  isUftDoeAdvisingUser (user: IUser): boolean {
    const { opsrSchools } = user;
    const isUftDoeAdvisingUser = !!(opsrSchools && opsrSchools.length > 0);
    return isUftDoeAdvisingUser;
  }

  getPrimarySchool (user: IUser): string | null {
    return (user.nvRole && user.nvRole.schoolId) || null;
  }

  // Decide if cluster user can acccess network settings (i.e. school cluster user permissions)
  canViewSchoolNetworkSettings (user: IUser | IUserMiniWithRole): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    const {
      nvRole: { type },
      canManageUsers,
    } = user;
    const roleMet = type === 'cluster_admin' || type === 'cluster';
    return roleMet && canManageUsers;
  }

  // Decide if a user has at least one school to view or edit
  static checkSchoolPortfolioAccess (user: IUser): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    const imUser = new this();
    const role = imUser.getRoleType(user);
    if (role === 'cluster_admin') {
      return true;
    }
    if (role === 'network_and_school') {
      return this.isClusterWideOrHasOnePlus(user.portfolio);
    }
    if (role === 'multi_school') {
      return !!this.getMultiSchoolIdsWithAccess(user).length;
    }
    return false;
  }

  // Get an array of school ids multi school user has access to
  static getMultiSchoolIdsWithAccess (user: IUser): string[] {
    // TODO: Fix the following and the dependencies to be camel case
    /* eslint-disable */ 
    const _schoolIds = user.nvRole.schoolIds || [];
    const { delegated_school_admin, edit_all, view_all, edit_caseload, view_caseload } = user.portfolio?.permissions;
    const schoolIdsInPortfolio = union(delegated_school_admin, edit_all, view_all, edit_caseload, view_caseload);
    const schoolIdsWithAccess = intersection(_schoolIds, schoolIdsInPortfolio);
    return schoolIdsWithAccess;
  }

  // Decide if cluster user can access student lvl from mid lvl in school net dash view
  canSeeSchoolNetworkStuLvl (user: IUser): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    const role = this.getRoleType(user);
    return role === 'cluster_admin' || role === 'network_and_school';
  }

  // Decide if cluster user can manage users
  static checkSchoolClusterUserManageStatus (user: IUser): boolean {
    if (this.isSuperAdmin(user)) {
      return true;
    }
    return user.canManageUsers;
  }
}
