export const POSTSECONDARY_PROJECTION = {
  _id: true,
  studentId: true,
  'studentDetails.name': true,
  'studentDetails.grade': true,
  'studentDetails.classOf': true,
  'studentDetails.officialClass': true,
  'gradPlanningDetails.plannedGraduationDate': true,
  'gradPlanningDetails.plannedDiplomaType': true,
  'spedDetails.isSped': true,
  'ellDetails.isEll': true,
  isMS: true,
  isHS: true,
  isES: true,
  pointPeople: true,
  otherStaff: true,
};

export const generateQuery = (schoolId, where = '{}') => {
  return `{
    CalculatedFields(schoolId: "${schoolId}", where: "${where}"){
    _id
    studentId
    name
    pictureId
    professionalEmail
    collegeFair
    finalizedPersonalStatement
    finalizedLetterOfRec
    createdPostsecondaryList
    oppProgramEllConfirm
    cunyAppSubmission
    fafsaAppSubmission
    tapAppSubmission
    counselingFinalDecision
    finalDecision
    counselingTransition
    workingPapers
    annualCareerInterestSurvey
    annualPostsecondaryPlan
    appliedForSummerJob
    completedSummerJob
    coverLetter
    linkedInProfile
    employabilitySkillsProfile
    finalizedResume
    financialAid{
      calculatedStatus
      tooltip
    }
    applied {
      totalPaths {
        dueAt
        status
        name
      }
      matchedPaths {
        dueAt
        status
        name
      }
      calculatedStatus
    }
    responses {
      totalPaths {
        dueAt
        status
        name
      }
      matchedPaths {
        dueAt
        status
        name
      }
      calculatedStatus
    }
    acceptances {
      totalPaths {
        dueAt
        status
        name
      }
      matchedPaths {
        dueAt
        status
        name
      }
      calculatedStatus
    }
    decision
  }
  }
  `;
};

export const POSTSECONDARY_JOINS = [];
