import { switchMap } from 'rxjs/operators';
import { getSchool } from 'Src/ng2/store';
import { Observable, of } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { DeprecatedRouteService } from 'Src/ng2/shared/services/route-services/deprecated-route.service';
import { DEPRECATED_GRAPHS } from 'Src/ng2/shared/constants/dashboard-graphs.constant';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Injectable()
export class DashboardDeprecationGuard implements CanActivate {
  constructor (
    private store: Store<any>,
    private router: Router,
    private deprecatedRouteService: DeprecatedRouteService,
    private urlPathService: UrlPathService,
  ) {}

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree| boolean> {
    const { queryParams } = snapshot;
    const { focus } = queryParams;
    const { schoolId } = snapshot.params;
    const isFocusDeprecated = DEPRECATED_GRAPHS[focus];

    if (isFocusDeprecated) {
      const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/tiles`);
      return of(this.router.createUrlTree([url]));
    }

    const isParamDeprecated = this.deprecatedRouteService.isParamDeprecated({
      stateOrUrl: '/lists/mid-level',
      routeParams: queryParams,
      param: 'focus',
    });

    if (isParamDeprecated) {
      return this.store.pipe(
        select(getSchool),
        switchMap(({ _id: schoolId, schoolType }) => {
          const paramsForRedirect = this.deprecatedRouteService.getNewParamsForRedirect({
            stateOrUrl: '/lists/mid-level',
            routeParams: queryParams,
            param: 'focus',
            schoolType,
          });
          const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/mid-level`);
          return of(this.router.createUrlTree([url], { queryParams: paramsForRedirect }));
        }),
      );
    } else {
      return of(true);
    }
  }
}
