import { PortalConfig } from './../../../../shared/services/portal-config';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ISidebarItem } from '../../../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { HelpDeskService } from '../../../../shared/services/help-desk/help-desk.service';
import { WindowRef } from '../../../../shared/services/windowRef';
import { HUMAN_TO_ROUTE, OtherToolsSideNavConfig } from '../other-tools.constant';
import { OtherToolsDataService } from './../other-tools-data/other-tools-data.service';
import { ROUTE_TO_HUMAN } from './../other-tools.constant';
import { ITool } from 'Src/ng2/shared/typings/interfaces/tool.interface';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { Store } from '@ngrx/store';
import { getSchool } from 'Src/ng2/store';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';

interface INavItem {
  key: string;
  humanName: string;
  stateName?: string;
}
/* istanbul ignore next */
@unsubscribeComponent
@Component({
  selector: 'other-tools-left-nav',
  templateUrl: './other-tools-left-nav.component.html',
  styleUrls: ['./other-tools-left-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OtherToolsLeftNavComponent implements OnInit {
  @Output() updateView: EventEmitter<any> = new EventEmitter<any>();

  private schoolId: string;
  protected dataSubscription: Subscription;
  public internalViewsSheetsMenu: ISidebarItem[] = [];
  public internalViewsToolsMenu: ISidebarItem[] = [];
  public sidebarTitleGS = 'Google Sheets';
  public sidebarTitleTools = 'Legacy Tools';
  public selectedSidebarItem: string;
  private isTransferSchool: boolean;

  public userMenuOptions = [
    {
      key: 'getHelp',
      humanName: 'Get Help',
      stateName: null,
    },
    {
      key: 'privacyAndTerms',
      humanName: 'Privacy & Terms',
      stateName: 'privacy-and-terms',
    },
  ];

  constructor (
    private helpDeskService: HelpDeskService,
    private otherToolsDataService: OtherToolsDataService,
    private route: ActivatedRoute,
    private router: Router,
    private windowRef: WindowRef,
    private portalConfig: PortalConfig,
    private imSchool: ImSchool,
    private store: Store<any>,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit () {
    this.schoolId = this.route.snapshot.params.schoolId;
    this.selectedSidebarItem = ROUTE_TO_HUMAN[this.router.url.split('/').pop()];
    this.store
      .select(getSchool)
      .pipe(
        tap(school => {
          this.isTransferSchool = this.imSchool.isTransferSchool(school);
        }),
        take(1),
      )
      .subscribe();

    this.dataSubscription = this.otherToolsDataService.getTools$(this.schoolId)
      .pipe(
        tap(async (tools: ITool[]) => {
          const { sheetsMenu, toolsMenu } = await this.otherToolsDataService.getSideNav({
            OtherToolsSideNavConfig,
            tools,
            activatedRoute: this.route.snapshot,
          });
          this.internalViewsSheetsMenu = sheetsMenu;

          if (this.isTransferSchool) {
            this.internalViewsToolsMenu = toolsMenu.filter((menuItem) =>
              Object.values(menuItem).every(value => value !== 'Graduation Metrics'));
          } else {
            this.internalViewsToolsMenu = toolsMenu;
          }
        }),
      )
      .subscribe();
  }

  public goToMyStudents (): void {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/lists/tiles`);
    this.router.navigate([url]);
  }

  public updateViewTools (option: string): void {
    const route = HUMAN_TO_ROUTE[option];
    this.selectedSidebarItem = option;
    this.router.navigate([`./${route}`], { relativeTo: this.route });
  }

  public goToState (option: INavItem): void {
    const window = this.windowRef.nativeWindow;
    switch (option.key) {
      case 'getHelp': {
        this.helpDeskService.showHelp();
        break;
      }
      case 'privacyAndTerms': {
        window.open(this.portalConfig.publicConfig.PRIVACY_POLICY, '_blank');
        break;
      }
    }
  }
}
