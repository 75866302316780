import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { reduce } from 'lodash';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

export const schedulingCheckStepcolumnKeys = {
  studentsNotPlannedForNeededExamsOptions: ['cohort', 'regentsNeededForOnTrackDiploma', 'regentsNeededForOnTrackDiplomaNotSched'],
  starsMismatchOptions: [
    'cohort',
    'regentsSchedForNextAdmin',
    'examsSchedInPortalNotPlannedInStars',
    'examsSchedInStarsNotPlannedInPortal',
  ],
};

@Component({
  selector: 'scheduling-check-step',
  templateUrl: './scheduling-check.component.html',
  styleUrls: ['../shared-step-styles.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class SchedulingCheckStep implements OnInit {
  @Input() students;

  public stepLabel = 'Complete Regents planning';
  public nextRegentsAdminDate: string;
  public studentIdsNotPlannedForNeededExams: string[];
  public studentIdsNotScheduledInStars: string[];
  public studentIdsNotPlannedInPortal: string[];
  public studentsNotPlannedForNeededExamsOptions: { columnKeys: string[] };
  public starsMismatchOptions: { columnKeys: string[] }

  constructor (
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit () {
    this.studentIdsNotPlannedForNeededExams = this.getIdsForStudentsNotPlannedForNeededExams(this.students);
    this.studentIdsNotScheduledInStars = this.getIdsForStudentsNotScheduledInStars(this.students);
    this.studentIdsNotPlannedInPortal = this.getIdsForStudentsNotPlannedInPortal(this.students);
    this.studentsNotPlannedForNeededExamsOptions = {
      columnKeys: schedulingCheckStepcolumnKeys.studentsNotPlannedForNeededExamsOptions,
    };
    this.starsMismatchOptions = {
      columnKeys: schedulingCheckStepcolumnKeys.starsMismatchOptions,
    };
  }

  ngOnChanges ({ students }) {
    if (!students.isFirstChange()) {
      this.studentIdsNotPlannedForNeededExams = this.getIdsForStudentsNotPlannedForNeededExams(this.students);
      this.studentIdsNotScheduledInStars = this.getIdsForStudentsNotScheduledInStars(this.students);
      this.studentIdsNotPlannedInPortal = this.getIdsForStudentsNotPlannedInPortal(this.students);
    }
  }

  goToRegentsPlanningWizard (): void {
    // pass this in
    const { schoolId } = this.activatedRoute.snapshot.params;
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/regents-planning/wizard`);
    this.windowRef.nativeWindow.open(url, '_blank');
  }

  private getIdsForStudentsNotPlannedForNeededExams (students: any) {
    return reduce(
      students,
      (result, student: any) => {
        const { studentId, countOfRegentsNeededForOnTrackDiplomaNotSched } = student;
        const studentsHasUnaddressedRegentsNeeds = countOfRegentsNeededForOnTrackDiplomaNotSched > 0;
        if (studentsHasUnaddressedRegentsNeeds) result.push(studentId);
        return result;
      },
      [],
    );
  };

  private getIdsForStudentsNotScheduledInStars (students: any) {
    return reduce(
      students,
      (result, student: any) => {
        const { studentId, examsSchedInPortalNotPlannedInStars } = student;
        if (examsSchedInPortalNotPlannedInStars.length > 0) result.push(studentId);
        return result;
      },
      [],
    );
  }

  private getIdsForStudentsNotPlannedInPortal (students: any) {
    return reduce(
      students,
      (result, student: any) => {
        const { studentId, examsSchedInStarsNotPlannedInPortal } = student;
        if (examsSchedInStarsNotPlannedInPortal.length > 0) result.push(studentId);
        return result;
      },
      [],
    );
  }
}
