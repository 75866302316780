import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NvMatTooltipModule } from '../../../shared/components/nv-mat-tooltip/nv-mat-tooltip.module';
import { MilestoneListService } from '../../../student/common-panels/student-postsec/student-postsec.service';
import { MyStudentService } from '../my-students-list/my-student.service';
import { PostsecondaryListService } from '../postsecondary-list/postsecondary-list.service';
import { ListCellFormattingService } from './list-cell-formatting.service';
import { CareerPathService } from './list-display-path/list-display-career-path/career-path.service';
import { CollegePathService } from './list-display-path/list-display-college-path/college-path.service';
import { ListDisplayService } from './list-display.service';
import { AttendanceService } from '../attendance-list/attendance.service';
import { AcademicService } from '../academic-list/academic.service';
import { GraphDetailService } from '../../graph-detail/graph-detail.service';
import { GraphListService } from '../../graph-list/graph-list.service';
import { TranscriptAndExamHistoryService } from 'Src/ng2/student/common-panels/student-transcript-and-regents-history-panel/transcript-and-exam-history.service';

@NgModule({
  imports: [CommonModule, FormsModule, NvMatTooltipModule],
  providers: [
    ListDisplayService,
    MyStudentService,
    MilestoneListService,
    CollegePathService,
    CareerPathService,
    PostsecondaryListService,
    ListCellFormattingService,
    AttendanceService,
    AcademicService,
    GraphDetailService,
    GraphListService,
    TranscriptAndExamHistoryService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ListDisplayModule {
  /** */
}
