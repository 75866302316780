import { LocalStorageService } from './../../shared/services/web-storage/local-storage/local-storage.service';
import { filter, pairwise } from 'rxjs/operators';
import { BehaviorSubject, Unsubscribable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Router, RouterEvent, RoutesRecognized } from '@angular/router';

const PREVIOUS_URL = 'prevUrl';

@Injectable()
export class RouterExitService {
  public previousUrl$ = new BehaviorSubject<any>(null);
  public previousUrlStack$ = new BehaviorSubject<any>([]);
  private routerSub: Unsubscribable;

  constructor (router: Router, private localStorage: LocalStorageService) {
    const isLogin = location.pathname === '/login'; // url from router is always '/' since this is instantiated when the app is bootstrapped
    const previousUrl: string = localStorage.getItem(PREVIOUS_URL);
    if (!isLogin && previousUrl) {
      this.addPrevUrlFromLocalStorage(previousUrl);
    }
    this.routerSub = router.events
      .pipe(
        filter(this.validator),
        pairwise(),
      )
      .subscribe(([previous]: [RoutesRecognized, RoutesRecognized]) => {
        const previousUrl = previous.urlAfterRedirects ? previous.urlAfterRedirects.split('?')[0] : previous.url.split('?')[0];
        const { queryParams: previousQueryParams } = previous.state.root;
        const urlToPush = { url: previousUrl, queryParams: previousQueryParams };
        const currentUrl = router.url;
        const [currentUrlWithoutQueryParams] = currentUrl ? currentUrl.split('?') : ['/'];
        if(RouterExitService.checkIfUrlChangeValid(previousUrl, currentUrlWithoutQueryParams)) {
          this.previousUrl$.next(urlToPush);
          this.previousUrlStack$.next(this.previousUrlStack$.value.concat(urlToPush));
        }
      });
  }

   /**
   * Checks that a URL change is substantial. Many pages make use of redirects that
   * add in default query params. This is fine. But, it causes 
   * lastUrl and prevUrl to be basically the same.
   * This is a problem if a page depends upon having a previous URL to return to
   * This is especially important for pages like /profile and /student
   * For now, to limit unintended consequences, going to only apply this to such known pages
   */
    static checkIfUrlChangeValid(previousUrlWithoutQuery: string, currentUrlWithoutQuery: string) {
      return !['/student','/profile','supports/new']
        .some(endingToCheckAgainst => currentUrlWithoutQuery.endsWith(endingToCheckAgainst)) 
        || previousUrlWithoutQuery !== currentUrlWithoutQuery;
    }

  private addPrevUrlFromLocalStorage (previousUrl: string): void {
    const [url, queryParamsStr] = previousUrl.split('?');
    const queryParams = (Object as any).fromEntries(new URLSearchParams(decodeURIComponent(queryParamsStr)));
    const prevUrlWithParams = { url, queryParams };
    this.previousUrl$.next(prevUrlWithParams);
    this.previousUrlStack$.next([prevUrlWithParams]);
    this.localStorage.removeItem(PREVIOUS_URL);
  }

  private validator (event: RouterEvent): boolean {
    let isValid: boolean = false;
    const isRouteRecognized = event instanceof RoutesRecognized;
    if (isRouteRecognized) {
      const [currentUrl] = event.url.split('?');
      const isCallback = currentUrl.includes('/callback');
      isValid = !isCallback;
    }
    return isValid;
  }

  ngOnDestroy (): void {
    this.routerSub.unsubscribe();
  }
}
