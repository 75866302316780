import { Toggles, TToggles } from 'Src/ng2/shared/constants/toggles.constant';
import { TValidSchoolTypeCats } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { PORTAL_TYPES, TPortalLocation } from 'Src/ng2/shared/typings/interfaces/portal.interface';
import { District, TDistricts } from 'Src/ng2/shared/typings/interfaces/district.interface';

/**
 * The INavItem contains more information than what the nv-sidebar-list component needs
 * During runtime, these configs will be formatted to ISidebarItem objects
 *
 * To enable a dropdown for a side nav item, the children field must exist.
 *
 * Toggles must use the Toggle key present in toggles.constant.ts. At the moment,
 * the behavior is: if the toggle in the toggle array is on, then the item will not display.
 * The code will loop through all toggles in the array and display accordingly.
 *
 * @prop {boolean} isActive -
 * The isActive key can be used to hide a nav item on the UI. If set to anything but null
 * If the nav item is allowed to be shown on the Portal, then this side-nav component determines
 * if the user should be allowed to see the item. (isRestricted function)
 *
 * @property {string} url - link to navigate to.
 * Top level items with children do not have urls and external links.
 * Make sure the url given here can be found in USER_ROLE_PERMISSIONS_FOR_GUARDS
 * as it is being checked to ensure user authorization.
 * the string here will be parsed relative to /school/
 * @exmaple url: 'data-grid' will navigate to '/school/data-grid'
 *
 * @property {boolean} isExternalUrl - if true, then the url and behavior of the url
 * needs to be handled by the component
 */
export interface INavItem {
  key?: string;
  humanName: string;
  order: number;
  isActive: boolean;
  schoolTypes: TValidSchoolTypeCats[];
  districts: TDistricts[];
  toggleKeys?: TToggles[] | null;
  portalLocation?: TPortalLocation[];
  url?: string;
  queryParams?: any;
  isExternalUrl?: boolean;
  children?: INavItem[];
  hasBetaFlag?: boolean;
}

export const ContentAreaNavItems: INavItem[] = [
  {
    key: 'ACADEMIC',
    humanName: 'Academic',
    order: 0,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
    isExternalUrl: false,
    children: [
      {
        key: 'ACADEMIC_DASHBOARD',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
        toggleKeys: [Toggles.TOGGLE_CONTENT_AREA_DASHBOARD],
        url: 'academic',
        isExternalUrl: false,
      },
      {
        key: 'ACADEMIC_LIST',
        humanName: 'Student List',
        order: 1,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC],
        url: 'lists/academic-list',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'ATTENDANCE',
    humanName: 'Attendance',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    isExternalUrl: false,
    children: [
      {
        key: 'ATTENDANCE_DASHBOARD',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
        toggleKeys: [Toggles.TOGGLE_ATTENDANCE_CONTENT_AREA_DASHBOARD],
        url: 'attendance',
        isExternalUrl: false,
      },
      {
        key: 'ATTENDANCE_LIST',
        humanName: 'Student List',
        order: 1,
        isActive: true,
        schoolTypes: ['all'],
        districts: [District.NYC, District.LANSING],
        url: 'lists/attendance-list',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'CREDITS',
    humanName: 'Credits',
    order: 2,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
    toggleKeys: [Toggles.TOGGLE_CREDIT_GAPS],
    url: 'credits',
    isExternalUrl: false,
  },
  {
    key: 'GRADUATION',
    humanName: 'Graduation',
    order: 4,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [District.NYC, District.SCHENECTADY, District.UNIONDALE],
    toggleKeys: [Toggles.TOGGLE_GRADUATION],
    url: 'graduation',
    isExternalUrl: false,
    hasBetaFlag: true,
  },
  {
    key: 'REGENTS',
    humanName: 'Regents',
    order: 6,
    isActive: true,
    schoolTypes: ['hs'],
    districts: [District.NYC],
    isExternalUrl: false,
    children: [
      {
        key: 'REGENTS_DASHBOARD',
        humanName: 'Dashboard',
        order: 0,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        toggleKeys: [Toggles.TOGGLE_SDC_REGENTS],
        url: 'regents',
        isExternalUrl: false,
      },
      {
        key: 'MOCK_REGENTS_LIST',
        humanName: 'Mock Regents',
        order: 1,
        isActive: true,
        isExternalUrl: false,
        url: 'lists/mock-regents',
        districts: [District.NYC],
        schoolTypes: ['hs', 'ms', 'transfer'],
        portalLocation: [PORTAL_TYPES.SCHOOL],
      },
    ],
  },
  {
    key: 'POSTSECONDARY',
    humanName: 'Postsecondary',
    isActive: true,
    order: 5,
    schoolTypes: ['hs'],
    districts: [District.NYC],
    isExternalUrl: false,
    children: [
      {
        key: 'POSTSECONDARY_OVERVIEW',
        humanName: 'Overview',
        order: 0,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        url: 'postsecondary/overview',
        isExternalUrl: false,
      },
      {
        key: 'COLLEGE_NOW',
        humanName: 'CUNY College Now',
        order: 2,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        url: 'postsecondary/college-now',
        isExternalUrl: false,
      },
      {
        key: 'SUPPLEMENTAL_ADVISING',
        humanName: 'Supplemental Advising',
        order: 3,
        isActive: true,
        schoolTypes: ['hs'],
        districts: [District.NYC],
        url: 'doe-postsec-advising',
        isExternalUrl: false,
      },
    ],
  },
  {
    key: 'SCREENERS_LIST',
    humanName: 'Screeners',
    order: 7,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING],
    url: 'lists/screeners-list',
    isExternalUrl: false,
  },
  {
    key: 'STUDENTS',
    humanName: 'Students',
    order: 8,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING],
    toggleKeys: [Toggles.TOGGLE_CONTENT_AREA_DASHBOARD],
    url: 'students',
    isExternalUrl: false,
  },
  {
    key: 'SUMMER_SCHOOL_LIST',
    humanName: 'Summer School',
    order: 9,
    isActive: true,
    districts: [District.NYC],
    schoolTypes: ['all'],
    url: 'lists/summer-school',
    isExternalUrl: false,
  },
];

export const MoreToolsNavItems: INavItem[] = [
  {
    key: 'HOME_DASHBOARD',
    humanName: 'Home',
    order: 0,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    url: 'lists/tiles',
    isExternalUrl: false,
  },
  {
    key: 'DEFAULT_SDC_GRID',
    humanName: 'Data Grid',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    url: 'data-grid',
    isExternalUrl: false,
  },
  {
    key: 'STUDENT_PROFILE',
    humanName: 'Student Profile',
    order: 2,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    url: 'student',
    queryParams: {
      focus: null,
      filter: null,
      grouping: null,
      sort: [{ colId: 'studentName', sort: 'asc' }],
      search: null,
    },
    isExternalUrl: false,
  },
  {
    key: 'SUPPORT_ATTENDANCE_LIST',
    humanName: 'Supports',
    order: 3,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    url: 'lists/supports',
    isExternalUrl: false,
  },
];

export const smallNavItems: INavItem[] = [
  {
    key: 'OTHER_TOOLS',
    humanName: 'Other Tools',
    order: 0,
    isActive: true,
    isExternalUrl: false,
    url: 'other-tools',
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    schoolTypes: ['all'],
    portalLocation: [PORTAL_TYPES.SCHOOL],
  },
  {
    key: 'SETTINGS',
    humanName: 'Settings',
    order: 1,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocation: [PORTAL_TYPES.ALL],
    url: '[DYNAMIC_SETTINGS_ROUTE]',
    isExternalUrl: false,
  },
  {
    key: 'DATA_SOURCES',
    humanName: 'Data Uploads',
    order: 2,
    isActive: true,
    schoolTypes: ['hs'],
    portalLocation: [PORTAL_TYPES.ALL],
    districts: [District.NYC],
    url: 'data-uploads/college-list',
    isExternalUrl: false,
  },
  {
    key: 'FP_DATA_ENTRY',
    humanName: 'F&P Data Entry',
    order: 3,
    isActive: true,
    schoolTypes: ['ems'],
    districts: [District.NYC],
    toggleKeys: [Toggles.TOGGLE_NYCT_MVP],
    portalLocation: [PORTAL_TYPES.SCHOOL],
    url: 'data-entry/assessments',
    isExternalUrl: false,
  },
  {
    key: 'GET_HELP',
    humanName: 'Get Help',
    order: 4,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocation: [PORTAL_TYPES.ALL],
    isExternalUrl: true,
  },
  {
    key: 'PRIVACY_AND_TERMS',
    humanName: 'Privacy & Terms',
    order: 5,
    isActive: true,
    schoolTypes: ['all'],
    districts: [District.NYC, District.LANSING, District.SCHENECTADY, District.UNIONDALE],
    portalLocation: [PORTAL_TYPES.ALL],
    isExternalUrl: true,
  },
];

export function getSmallSideNavConfig (settingsRoute: string): typeof smallNavItems {
  return JSON.parse(JSON.stringify(smallNavItems).replace('[DYNAMIC_SETTINGS_ROUTE]', settingsRoute));
}

export function shapeSchoolNavItems (configs: INavItem[], schoolId: string, computeDistrictUrlPath: Function): ISidebarItem[] {
  const shapedItems = configs.map(item => {
    const { humanName, key, url, children, hasBetaFlag } = item;
    const formattedChildren = children ? shapeSchoolNavItems(children, schoolId, computeDistrictUrlPath) : null;
    const expandAs = children ? ('accordion' as const) : null;
    const calculatedUrl = url ? computeDistrictUrlPath(`/school/${schoolId}/${url}`) : null;

    return {
      human: humanName,
      key: key || humanName,
      expandAs,
      children: formattedChildren,
      url: calculatedUrl,
      hasBetaFlag: hasBetaFlag || false,
    };
  });
  return shapedItems;
}
