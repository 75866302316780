import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { Component, OnInit, ViewChild, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { WindowRef } from './../../../services/windowRef';
import { ChangeSavedInMetrics } from './../../../../store/actions/metrics-actions';
import { cloneDeep, isEqual, omit } from 'lodash';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'graduation-settings',
  templateUrl: './graduation-settings.component.html',
  styleUrls: ['./graduation-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class GraduationSettingsComponent implements OnInit, OnDestroy {
  @ViewChild('gradPlanningTabs') gradPlanningTabs: MatTabGroup;

  public currentSchool: ISchool;
  public animationDuration: string = '0ms';
  public selectedGradPlanIndex: number = 0;
  public gradPlanRoute: string;
  public gradPlanningTabNames = {
    firstYear: '1ST YEAR STUDENTS',
    secondYear: '2ND YEAR STUDENTS',
    thirdYear: '3RD YEAR STUDENTS',
    fourthYear: '4TH YEAR STUDENTS',
  };

  private currentCohortMetricsKey: 'firstYearCohort' | 'secondYearCohort' | 'thirdYearCohort' | 'fourthYearCohort';

  public pageHeaderMeta = {
    title: 'Graduation Metrics',
    subTitle: null,
    icon: {},
  };

  public currentSchoolCopy: ISchool;

  constructor (
    private activatedRoute: ActivatedRoute,
    private windowRef: WindowRef,
    private store: Store<any>,
    private router: Router,
    private urlPathService: UrlPathService,
  ) {}

  ngOnInit (): void {
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;
    this.gradPlanRoute = this.activatedRoute.snapshot.data.gradPlanRoute;
    this.currentSchoolCopy = cloneDeep(this.currentSchool);
    this.setGradPlanTab();
  }

  ngOnDestroy ():void {
    document.title = 'Portal by New Visions';
  }

  ngAfterViewInit (): void {
    if (this.gradPlanningTabs) this.gradPlanningTabs._handleClick = this.interceptTabChange.bind(this);
  }

  public goToGradPlanRoute (tabName): void {
    switch (tabName) {
      case this.gradPlanningTabNames.fourthYear: {
        const url = this.urlPathService.computeDistrictUrlPath(`school/${this.currentSchool._id}/other-tools/graduation/fourth-year-students`);
        this.router.navigate([url]);
        break;
      }
      case this.gradPlanningTabNames.thirdYear: {
        const url = this.urlPathService.computeDistrictUrlPath(`school/${this.currentSchool._id}/other-tools/graduation/third-year-students`);
        this.router.navigate([url]);
        break;
      }
      case this.gradPlanningTabNames.secondYear: {
        const url = this.urlPathService.computeDistrictUrlPath(`school/${this.currentSchool._id}/other-tools/graduation/second-year-students`);
        this.router.navigate([url]);
        break;
      }
      case this.gradPlanningTabNames.firstYear: {
        const url = this.urlPathService.computeDistrictUrlPath(`school/${this.currentSchool._id}/other-tools/graduation/first-year-students`);
        this.router.navigate([url]);
        break;
      }
      default: {
        const url = this.urlPathService.computeDistrictUrlPath(`school/${this.currentSchool._id}/other-tools/graduation/fourth-year-students`);
        this.router.navigate([url]);
        break;
      }
    }
  }

  private setGradPlanTab (): void {
    switch (this.gradPlanRoute) {
      case 'first': {
        this.selectedGradPlanIndex = 3;
        this.currentCohortMetricsKey = 'firstYearCohort';
        break;
      }
      case 'second': {
        this.selectedGradPlanIndex = 2;
        this.currentCohortMetricsKey = 'secondYearCohort';
        break;
      }
      case 'third': {
        this.selectedGradPlanIndex = 1;
        this.currentCohortMetricsKey = 'thirdYearCohort';
        break;
      }
      case 'fourth': {
        this.selectedGradPlanIndex = 0;
        this.currentCohortMetricsKey = 'fourthYearCohort';
        break;
      }
      default: {
        this.selectedGradPlanIndex = 0;
        this.currentCohortMetricsKey = 'fourthYearCohort';
        break;
      }
    }
  }

  public handleSavedChanges ($event: boolean) {
    if ($event) this.currentSchoolCopy = cloneDeep(this.currentSchool);
  }

  /* _key and _order are set on this.currentSchool in the cohort.component, which can carry over to this.currentSchoolCopy
  if a user saves changes and the currentSchool is cloned again. To check for credits and regents changes
  onTrackMetrics.gradPlanningThresholds, create new objects that omit these keys and allow for equal comparison.
  */
  private hasChanges (): boolean {
    const currentSchoolMetrics = this.currentSchool.onTrackMetrics.gradPlanningThresholds[this.currentCohortMetricsKey];
    const currentSchoolCopyMetrics = this.currentSchoolCopy.onTrackMetrics.gradPlanningThresholds[this.currentCohortMetricsKey];
    const asOfJanSchoolMetrics = omit(currentSchoolMetrics.asOfJan, '_key', '_order');
    const asOfSeptSchoolMetrics = omit(currentSchoolMetrics.asOfSept, '_key', '_order');
    const asOfJanSchoolCopyMetrics = omit(currentSchoolCopyMetrics.asOfJan, '_key', '_order');
    const asOfSeptSchoolCopyMetrics = omit(currentSchoolCopyMetrics.asOfSept, '_key', '_order');
    const hasChangesJan = !isEqual(asOfJanSchoolMetrics, asOfJanSchoolCopyMetrics);
    const hasChangesSept = !isEqual(asOfSeptSchoolMetrics, asOfSeptSchoolCopyMetrics);
    return (hasChangesJan || hasChangesSept);
  }

  private interceptTabChange (tab: MatTab): void {
    const message = 'You have unsaved changes, Are you sure you want to leave this page?';
    const tabTitle = tab.textLabel;
    const tabTitleLower = tabTitle.toLowerCase();
    let result;
    if (tabTitleLower.includes('year students')) {
      if (this.hasChanges()) {
        result = this.windowRef.nativeWindow.confirm(message);
        if (result) {
          this.store.dispatch(new ChangeSavedInMetrics());
          this.goToGradPlanRoute(tabTitle);
        }
      } else {
        this.goToGradPlanRoute(tabTitle);
      }
    }
  }
}
