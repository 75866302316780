import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLExperiencesHelperService {
  private stringBuilderFn = (acc, [key, value]): string => {
    if (key === 'dates') {
      acc += `startDate: "${value.startDate}", endDate: "${value.endDate}", \n`;
    } else if (Array.isArray(value)) {
      acc += `${key}: [${value.map(val => `"${val}"`)}]`;
    } else if (typeof value !== 'string') {
      acc += `${key}: ${value}, \n`;
    } else {
      acc += `${key}: "${value}", \n`;
    }
    return acc;
  };

  constructor () {}

  getModalConfigQuery (modalFocus, formConfigKey?): string {
    const query = formConfigKey
      ? `{
            ModalConfig(modalFocus: "${modalFocus}", formConfigKey: "${formConfigKey}"){
              key
              human
              options {
                key
                human
                options {
                  key
                  human
                }
              meta {
                controlType
                placeholder
                icon
                labelIconTooltip
                required
                validationMessage
              }
            }
          }
        }`
      : `{
        ModalConfig(modalFocus: "${modalFocus}") {
          key
          human
        }
      }`;

    return query;
  }

  getStudentExperienceQuery (schoolId: string, studentId: string): string {
    const query = ` {
      StudentExperiences(schoolId: "${schoolId}", studentId: "${studentId}") {
        experiences {
          _id
          createdAt
          createdBy {
            userId
            firstName
            lastName
            gafeEmail
            doeEmail
            delegatedRole
          }
          schoolId
          studentId
          schoolYear
          milestone
          type
          startDate
          endDate
          partnerOrg
          hours
          details
          countHoursForWbl
        }
        ExperienceListConstants {
          key
          human
          subheader
          tooltipHeader
          tooltipBody
        }
      }
    }`;

    return query;
  }

  getCreateExperienceQuery (params: any): string {
    const optionsString = Object.entries(params).reduce(this.stringBuilderFn, '');

    const query = `mutation{
        createExperience(options: {${optionsString}}) {
          _id
          milestone
        }
      }`;

    return query;
  }

  getBulkCreateExperienceQuery (params: any): string {
    const optionsString = Object.entries(params).reduce(this.stringBuilderFn, '');

    const query = `mutation{
        bulkCreateExperience(options: {${optionsString}}) {
          backgroundJobId
        }
      }`;

    return query;
  }

  getEditExperienceMutation (params: any): string {
    const optionsString = Object.entries(params).reduce(this.stringBuilderFn, '');

    const mutation = `mutation{
        updateExperience(patch: {${optionsString}}) {
          _id
          milestone
        }
      }`;

    return mutation;
  }

  getDeleteExperienceMutation (experienceId: string): string {
    const mutation = `mutation{
      deleteExperience(experienceId: "${experienceId}") {
          _id
        }
      }`;

    return mutation;
  }
}
