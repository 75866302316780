export enum PORTAL_TYPES {
  // eslint-disable-next-line no-unused-vars
  ALL = 'all',
  // eslint-disable-next-line no-unused-vars
  NETWORK = 'NETWORK',
  // eslint-disable-next-line no-unused-vars
  SCHOOL = 'SCHOOL',
  // eslint-disable-next-line no-unused-vars
  SHELTER = 'SHELTER',
}

export type TPortalLocation = typeof PORTAL_TYPES[keyof typeof PORTAL_TYPES];
