import { identity } from 'lodash';
import { LoadToggles } from './../../../store/actions/toggle-actions';
import { tap, filter, switchMap, take, catchError, mapTo } from 'rxjs/operators';
import { getToggleLoadedStatus, getToggleEntities } from './../../../store/selectors/toggles-selectors';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class TogglesGuard implements CanActivate {
  constructor (private store: Store<any>) {}

  getFromStoreOrAPI (currentSchoolId: string): Observable<any> {
    return this.store.pipe(
      select(getToggleLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadToggles({ currentSchoolId }));
      }),
      filter(identity),
      switchMap(() => this.store.pipe(select(getToggleEntities))),
      take(1),
    );
  }

  canActivate (route: ActivatedRouteSnapshot): Observable<boolean> {
    const { schoolId } = route.params;
    return this.getFromStoreOrAPI(schoolId).pipe(
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  canActivateChild (route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.canActivate(route);
  }
}
