import { Injectable } from '@angular/core';
import { reverse } from 'lodash';

@Injectable()
export class DashboardCircleService {
  getColors(data: any): Array<{ regular: string; bright: string }> {
    const colors = [
      { regular: 'rgb(114, 194, 214)', bright: 'rgb(0, 176, 202)' },
      { regular: 'rgb(116, 131, 160)', bright: 'rgb(25, 71, 125)' },
      { regular: 'rgb(146, 121, 173)', bright: 'rgb(102, 45, 145)' },
      { regular: 'rgb(114, 180, 131)', bright: 'rgb(0, 155, 72)' },
      { regular: 'rgb(127, 225, 202)', bright: 'rgb(10, 196, 172)' },
      { regular: 'rgb(255, 219, 227)', bright: 'rgb(255, 92, 143)' },
      { regular: 'rgb(255, 167, 167)', bright: 'rgb(255, 137, 137)' },
    ];
    const colorCount = colors.length;
    const colorArray = [];
    let m = 0;
    [...data].forEach(i => {
      if (m === colorCount) m = 0;
      colorArray.push(colors[m]);
      m++;
    });
    return colorArray;
  }

  sortColors(colorArray: Array<{ regular: string; bright: string }>): Array<{ regular: string; bright: string }> {
    return reverse(colorArray);
  }

  // makes colors darker
  shadeRGBColor(color: string, percent: number): string {
    let f = color.split(',');
    let t = percent < 0 ? 0 : 255;
    let p = percent < 0 ? percent * -1 : percent;
    let R = parseInt(f[0].slice(4));
    let G = parseInt(f[1]);
    let B = parseInt(f[2]);
    return (
      'rgb(' +
      (Math.round((t - R) * p) + R) +
      ',' +
      (Math.round((t - G) * p) + G) +
      ',' +
      (Math.round((t - B) * p) + B) +
      ')'
    );
  }
}
