export const ACTIVITY_TYPES = [
  'Annual individual progress review',
  'Apprenticeship',
  'Boot camp',
  'Bridge program',
  'Bridge training',
  'Career fair',
  'Career interest survey',
  'Career mentorship',
  'Career-focused research project',
  'College fair',
  'College trip',
  'Community service',
  'Enrichment / out of school activity',
  'Entrepreneurship',
  'Family / caregiver engagement event',
  'Guest speaker',
  'Informational interview',
  'Internship',
  'Job',
  'Job application support',
  'Job shadow',
  'Match review',
  'Mock interview',
  'Networking activity',
  'Postsecondary advising',
  'Postsecondary application session',
  'Postsecondary research',
  'Presentation',
  'Program recruitment / information session',
  'School based enterprise',
  'Sector training',
  'Service learning',
  'SYEP',
  'Tour',
  'Trip',
  'Volunteer',
  'Workplace challenge',
  'Other',
];

export const ACTIVITY_SECTORS = [
  'All',
  'Construction',
  'Food service',
  'Health',
  'Hospitality',
  'Retail',
  'Social work',
  'Tech',
  'Transportation',
  'Other',
];
