export enum District {
  // eslint-disable-next-line no-unused-vars
  NYC = 'NYC',
  // eslint-disable-next-line no-unused-vars
  LANSING = 'Lansing',
  // eslint-disable-next-line no-unused-vars
  SCHENECTADY = 'Schenectady',
  // eslint-disable-next-line no-unused-vars
  UNIONDALE = 'Uniondale',
}
export type TDistricts = District.NYC
  | District.LANSING
  | District.SCHENECTADY
  | District.UNIONDALE
  | 'all';
