import { SupportCategoriesPillsModule } from './../../../../shared/components/supports-list/support-categories-pills/support-categories-pills.module';
import { SupportCategoriesPills } from './../../../../shared/components/supports-list/support-categories-pills/support-categories-pills.component';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { FixedTableModule } from 'Src/ng2/shared/components/list/fixed-table/fixed-table.module';
import { FixedToInfiniteViewModule } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.module';
import { BatchActionsService } from '../../services/batch-actions/batch-actions.service';
import { NvDropdownItemModule } from './../../../../../nvps-libraries/design/nv-dropdown-item/nv-dropdown-item.module';
import { NvDropdownModule } from './../../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvIconButtonModule } from './../../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { PageHeaderModule } from './../../../../shared/components/header/page-header/page-header.module';
import { ListContainerModule } from './../../../../shared/components/list/list-container/list-container.module';
import { MadlibModule } from './../../../../shared/components/list/mad-lib/mad-lib.module';
import { BaseModalModule } from './../../../../shared/modals/base-modal.module';
import { SupportModalComponent } from './../../../../shared/modals/support/support-modal.component';
import { SharedServicesModule } from './../../../../shared/services/shared.services.module';
import { ContentToolsModule } from './../../../../shell/content-tools/content-tools.module';
import { ListHeaderService } from './../../services/list-headers/list-headers.service';
import { ListNavigationService } from './../../services/list-navigation/list-navigation.service';
import { MadlibService } from './../../services/madlib/madlib.service';
import { SupportsDataService } from './../supports-data.service';
import { SupportOptionsMenuKebabComponent } from './support-options-menu/support-options-menu-kebab.component';
import { SupportSettingsListContainer } from './support-settings-list.component';
import { NvIconModule } from 'Src/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { WildcardService } from 'Src/ng2/shared/services/list-services/wildcard.service';

@NgModule({
  declarations: [SupportSettingsListContainer, SupportOptionsMenuKebabComponent],
  imports: [
    MatMenuModule,
    CommonModule,
    ListContainerModule,
    FixedToInfiniteViewModule,
    FixedTableModule,
    ContentToolsModule,
    PageHeaderModule,
    SharedServicesModule,
    MadlibModule,
    NvIconButtonModule,
    NvDropdownModule,
    NvDropdownItemModule,
    BaseModalModule,
    ReactiveFormsModule,
    SupportCategoriesPillsModule,
    NvIconModule,
    NvTooltipModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [SupportSettingsListContainer],
  providers: [
    SupportsDataService,
    BatchActionsService,
    MadlibService,
    WildcardService,
    ListNavigationService,
    ListHeaderService,
  ],
})
export class SupportSettingsListModule {}
