import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SpinnerService } from './../../../../shared/components/spinner/spinner-modal.service';
import { ofType } from '@ngrx/effects';
import { CREATE_SUPPORT_SUCCESS, UPDATE_SUPPORT_SUCCESS } from './../../../../store/actions/supports-actions';
import {
  SortAndFilterService,
  TSortDirections,
  Direction,
} from './../../../../shared/services/list-services/sort-and-filter.service';
import { Store, ActionsSubject } from '@ngrx/store';
import { ModalsService } from './../../../../shared/modals/modals.service';
import { IListConfig } from './../../../../shared/models/list-models';
import {
  BackgroundJobNotificationService,
  TValidBackgroundJob,
} from './../../../../shared/services/background-job-notification-service/background-job-notification-service';
import { IListData } from './../../../../shell/content-tools/content-tools.component';
import { ListNavigationService } from './../../services/list-navigation/list-navigation.service';
import { MadlibService } from './../../services/madlib/madlib.service';
import { SupportsDataService, IFocus } from './../supports-data.service';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Unsubscribable, throwError, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { FixedToInfiniteViewComponent } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { ListHeaderService } from '../../services/list-headers/list-headers.service';
import { TValidSchoolTypes } from 'Src/ng2/shared/typings/interfaces/school.interface';

@Component({
  selector: 'support-settings-list',
  templateUrl: './support-settings-list.component.html',
  styleUrls: ['./support-settings-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// @ts-ignore
export class SupportSettingsListContainer extends FixedToInfiniteViewComponent implements OnInit, OnDestroy {
  // SuperProps (all must be declared, only some defined)
  public onFixed: Function;
  public onInfinite: Function;
  public onRowClick: Function;
  public onBatchAction: Function;
  public updateSort: Function;
  public onUiRowDataUpdate: Function;
  public onDynamicComponentClick: Function = () => null;
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$ = new BehaviorSubject(null);
  public filterFormControl: FormControl;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public dynamicComponentInputData: any = {};
  public noDataMessage;
  public columns;
  public listConfig: IListConfig = {
    listType: 'SUPPORT_SETTINGS',
    noDataMessage: 'No supports to display',
    sortableColumns: false,
    emptyTableMessage: 'Empty table message placeholder',
  };

  // LOCAL
  public isRefactoredList: boolean = true;
  public fociData$: Observable<any>;
  public madlibModel: FormGroup;
  public dataSource$: Observable<any>;
  public foci: IFocus[];
  public fociByCategory: Array<{ categoryLabel: string; foci: IFocus[] }>;
  public activeList: 'INFINITE' | 'FIXED';
  public batchActionsHash;
  public schoolTermInfo;
  public supports: any[];
  public supportNames: string[];
  public users: any[];
  public pageActionsStyle: string = 'support-settings';
  // The background jobs this view needs to refetch on
  public backgroundJobDependencies: TValidBackgroundJob[] = ['BulkStudentUpdate', 'SupportMiniSchemaUpdate'];

  // The store effects this view needs to refetch on
  public storeEffectDependencies: any[] = [CREATE_SUPPORT_SUCCESS, UPDATE_SUPPORT_SUCCESS];

  // MetaData
  public listData: IListData;
  public pageHeaderMeta = {
    title: 'Supports',
    subTitle: null,
    action: {
      label: 'Create new support',
      trigger: null,
    },
    textboxPlaceholder: 'Search by support',
    infoIcon: {
      tooltipData: 'This section allows you to create or modify supports your school can assign to students. Supports are designed to improve student attendance, academic performance, social-emotional health, and physical health.',
    },
  };

  // SubSinks
  public notificationServiceSub: Unsubscribable;
  public batchActionsModeSubscription: Unsubscribable;
  public getFociDataSub: Unsubscribable;
  public schoolSub: Unsubscribable;
  public actionsSubscription: Unsubscribable;

  @Input() schoolId: string;
  @Input() schoolType: TValidSchoolTypes;
  @Input() batchActionsMode$: Observable<any>;
  @Output() output = new EventEmitter();
  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: true }) listContent: ElementRef;

  // DI
  constructor (
    public resolver: ComponentFactoryResolver,
    public madlibService: MadlibService,
    public objectCache: ObjectCache,
    public notificationService: BackgroundJobNotificationService,
    public supportsDataService: SupportsDataService,
    public navigationService: ListNavigationService,
    private listHeaderService: ListHeaderService,
    private modalsService: ModalsService,
    public store: Store<any>,
    private actionsSubject: ActionsSubject,
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private batchActionsService: BatchActionsService,
  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnInit (): void {
    this.supportsDataService.clearFociCache();
    this.filterFormControl = new FormControl(this.activatedRoute.snapshot.queryParams.search || null);
    this.sortKey$ = new BehaviorSubject(this.activatedRoute.snapshot.queryParams.sortKey || 'SUPPORT_NAME');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(this.activatedRoute.snapshot.queryParams.sortDirection || Direction.asc);
    // Super Props
    this.updateSort = (sortKey: string) => {
      SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
      this.navigationService.updateUrl(this.sortKey$, this.sortDirection$, this.filterFormControl, this.madlibModel);
    };

    // Overwrite super methods --responding to user events
    this.onRowClick = ($event) => this.navigationService.goToSupportRoster(this.schoolId, $event);

    this.notificationServiceSub = this.getNotificationServiceSub();
    this.actionsSubscription = this.getActionsSub();

    const modalData = { schoolId: this.schoolId };
    this.dynamicComponentInputData = { ...modalData };

    this.pageHeaderMeta.action.trigger = () => {
      this.modalsService.openSupportModal({
        mode: 'CREATE',
        ...modalData,
      });
    };

    // Called by super; when in each of the list modes
    this.onFixed = this.updateCssForListType('FIXED');
    this.onInfinite = this.updateCssForListType('INFINITE');
    // INIT
    const shouldLoadContainer = true;
    this.initializeSupportSettingsList(this.schoolId, this.activatedRoute.snapshot.queryParams, shouldLoadContainer);
  }

  ngOnDestroy () {
    if (this.actionsSubscription) this.actionsSubscription.unsubscribe();
    if (this.schoolSub) this.schoolSub.unsubscribe();
    if (this.notificationServiceSub) this.notificationServiceSub.unsubscribe();
    if (this.getFociDataSub) this.getFociDataSub.unsubscribe();
  }

  getActionsSub () {
    return this.actionsSubject.pipe(ofType(CREATE_SUPPORT_SUCCESS, UPDATE_SUPPORT_SUCCESS)).subscribe(
      (data: any) => {
        const {
          type,
          payload: { requiresMadlibRefresh },
        } = data;
        if (this.storeEffectDependencies.includes(type)) {
          const shouldLoadContainer = false;
          this.initializeSupportSettingsList(
            this.schoolId,
            this.activatedRoute.snapshot.queryParams,
            shouldLoadContainer,
            requiresMadlibRefresh,
          );
        }
      },
      err => throwError(err),
    );
  }

  getNotificationServiceSub () {
    return this.notificationService
      .getMessage()
      .pipe(
        filter(({ backgroundJob }) => this.backgroundJobDependencies.includes(backgroundJob)),
        tap(() => {
          const shouldLoadContainer = false;
          this.initializeSupportSettingsList(this.schoolId, this.activatedRoute.snapshot.queryParams, shouldLoadContainer);
        }),
      )
      .subscribe();
  }

  // getFocusData---createMadlibModel---getGroupingData---createListContainer--|
  initializeSupportSettingsList (
    schoolId: string,
    params,
    loadContainer: boolean,
    requiresMadlibRefresh?: boolean,
  ) {
    this.getFociData$(schoolId)
      .pipe(
        // Format and set the partials to fit the nv-dropdown
        tap(fociPartials => (this.fociByCategory = this.madlibService.getFormattedFoci(fociPartials))),
        // Once we know the default focus, fetch complete data to populate corresponding filter and grouping options
        switchMap(fociPartials =>
          this.getFociData$(
            schoolId,
            this.madlibService.getDefaultFocus(fociPartials, params),
            requiresMadlibRefresh,
          ),
        ),
        switchMap(([completeFocusData]) =>
          of(this.madlibService.setMadlibModel(this.madlibService.formatFocus(completeFocusData), params)),
        ),
        // Set madlib model with focus containing all data
        tap(madlibModel => (this.madlibModel = madlibModel)),
        switchMap(madlibModel => this.getGrouping$(madlibModel)),
        tap(() =>
          this.navigationService.updateUrl(
            this.sortKey$,
            this.sortDirection$,
            this.filterFormControl,
            this.madlibModel,
          ),
        ),
        tap(groupingData => {
          this.updateGroupingDependentProps(groupingData);
          if (loadContainer) this.createListContainer(this.dynamicComponentInputData);
        }),
      )
      .subscribe();
  }

  onMadlibChange (evt: { dimension: string; data: any }, schoolId: string, madlibModel: FormGroup): void {
    const spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    const stream$ = evt.dimension === 'focus'
      ? this.madlibService.getModelForFocusChange(schoolId, madlibModel, evt, this.getFociData$.bind(this))
      : this.madlibService.getModelForNonFocusChange(madlibModel, evt);
    stream$.pipe(
      tap(() =>
        this.navigationService.updateUrl(
          this.sortKey$,
          this.sortDirection$,
          this.filterFormControl,
          this.madlibModel,
        ),
      ),
      switchMap(madlibModel => this.getGrouping$(madlibModel)),
      tap(groupingData => this.updateGroupingDependentProps(groupingData)),
    )
      .subscribe({ complete: () => spinner.close() });
  }

  updateCssForListType (listMode: 'INFINITE' | 'FIXED'): Function {
    return (instance?: ElementRef): void => {
      this.activeList = listMode;
      const addClassForListMode = listMode === 'INFINITE' ? 'infinite' : 'fixed';
      this.listContent.nativeElement.classList.add(addClassForListMode);
      const removeClassForListMode = listMode === 'INFINITE' ? 'fixed' : 'infinite';
      this.listContent.nativeElement.classList.remove(removeClassForListMode);
      this.pageHeaderMeta =
        listMode === 'INFINITE'
          ? { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getInfiniteHeaderIcon(instance) } }
          : { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getFixedHeaderIcon() } };
    };
  }

  getFociData$ (schoolId: string, focusKey?: string, requiresMadlibRefresh?: boolean): Observable<any> {
    return this.supportsDataService.getSupportFocusData$(schoolId, 'SUPPORT_DETAILS', requiresMadlibRefresh).pipe(
      switchMap(({ data: { SupportFocus } }) => of(SupportFocus)),
      take(1),
    );
  }

  getGrouping$ (madlibModel) {
    return this.supportsDataService.getSupportGroupingData$(
      this.madlibService.getGroupingDataPayload(this.schoolId, madlibModel.value) as any,
    );
  }

  updateGroupingDependentProps ({ data: { SupportGroupings } }): void {
    const { columns, columnIndexMap } = this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel);
    this.dynamicColumns.next(columns);
    this.dynamicColumnIndexMap.next(columnIndexMap);
    this.groupingData$.next(SupportGroupings);
  }
}
