import { RollbarService } from './../../../shared/services/rollbar/rollbar.service';
import * as Rollbar from 'rollbar';
import { LocalStorageService } from './../../../shared/services/web-storage/local-storage/local-storage.service';
import { UrlTree, ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { getCurrentUser, getCurrentUserErrorStatus } from 'Src/ng2/store/selectors/current-user-selectors';
import { getCurrentUserLoadingStatus, getCurrentUserLoadedStatus } from './../../../store/selectors/current-user-selectors';
import { IRouteConfigsOpts } from './../../route.config';
import { LoadCurrentUser, LoadCurrentUserSuccess } from './../../../store/actions/current-user-actions';
import { Inject, Injectable } from '@angular/core';

import { Store, select } from '@ngrx/store';
import { Observer, of, Observable, throwError, combineLatest, from } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { Auth, IDistrict } from 'Src/ng2/shared/auth/auth.service';
import { API_AUTH_FAILURE_MESSAGE, API_AUTH_INVALID_FORMAT, API_AUTH_NOT_FOUND } from 'Src/ng2/shared/constants/auth/auth.constant';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { RegularExpressionsUtility } from 'Src/ng2/shared/utilities/regular-expressions/regular-expressions.utility';
import { PortalConfig } from 'Src/ng2/shared/services/portal-config';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';
import { SessionStorageService } from 'Src/ng2/shared/services/web-storage/session-storage/session-storage.service';
import { districtsConfig } from 'Src/ng2/shared/constants/districts-config.constant';

@Injectable()
export class RouteGuard implements CanActivate, CanActivateChild {
  constructor (
    @Inject(RollbarService) private rollbar: Rollbar,
    private auth: Auth,
    private imUser: ImUser,
    private store: Store<any>,
    private router: Router,
    private localStorageService: LocalStorageService,
    private sessionStorageService: SessionStorageService,
    private portalConfig: PortalConfig,
    private urlPathService: UrlPathService,
  ) {}

  canActivate (snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean |UrlTree> {
    // cast the snapshot.routeConfigs because the route config interface contains additional props to accomodate our hybrid state
    const routeConfig = snapshot.routeConfig as IRouteConfigsOpts;
    const { authenticationRequired } = routeConfig;
    return authenticationRequired ? this.checkAuthAndRole$(routeConfig, state.url) : this.checkRedirect$(routeConfig);
  }

  canActivateChild (snapshot: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree> {
    return this.canActivate(snapshot, state);
  }

  private checkAuthAndRole$ (routeConfig: IRouteConfigsOpts, url: string): Observable<boolean|UrlTree> {
    const authenticated = this.auth.isAuthenticated();
    return authenticated ? this.canAccessRoute$(routeConfig, url) : this.redirectToLogin$({ lastKnownUrl: url });
  }

  private canAccessRoute$ (routeConfig: IRouteConfigsOpts, url: string): Observable<boolean|UrlTree> {
    if (!this.isValidDistrictForUser()) return this.reditectToDistrictPicker$();
    return this.getCurrentUser$().pipe(
      catchError(err => {
        this.handleApiError({ err, lastKnownUrl: url });
        return of(null);
      }),
      filter(user => user),
      switchMap(user => {
        const parsedPartnerId = this.parsePartnerIdFromUrl(url);
        return this.isRoleAuthorized$(user, routeConfig, parsedPartnerId);
      }),
      switchMap(isRouteAccessible =>
        isRouteAccessible ? this.checkRedirect$(routeConfig) : this.redirectToHome$({ noAccessUrl: url }),
      ),
    );
  }

  private parsePartnerIdFromUrl (url: string): string | null {
    const id = null;
    const schoolIdsRegex = RegularExpressionsUtility.schoolIdRouteRegexCtr();
    const matchedSchoolRoute = url.match(schoolIdsRegex);
    const matchedSchoolId = matchedSchoolRoute && matchedSchoolRoute[0].split('/')[2];
    const shelterIdRegex = RegularExpressionsUtility.shelterIdRouteRegexCtr();
    const matchedShelterRoute = url.match(shelterIdRegex);
    const matchedShelterId = matchedShelterRoute && matchedShelterRoute[0].split('/')[2];
    return matchedSchoolId || matchedShelterId || id;
  }

  private isRoleAuthorized$ (user: IUser, routeConfig: IRouteConfigsOpts, partnerId: string): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      const { rolePermissions: rolesThatCanAccessRoute, partnerType } = routeConfig;
      const authorized = this.imUser.isRoleAuthorized({
        user,
        rolesThatCanAccessRoute,
        partnerId,
        partnerType,
      });
      authorized ? observer.next(true) : observer.next(false);
      observer.complete();
    });
  }

  private getCurrentUser$ (): Observable<IUser> {
    return this.store.select(getCurrentUserLoadedStatus).pipe(
      // manage subscription since we are streaming from the store
      take(1),
      switchMap(isLoaded => {
        return isLoaded ? this.store.select(getCurrentUser).pipe(take(1)) : this.loadCurrentUser$();
      }),
      catchError((err) => throwError(err)),
    );
  }

  private loadCurrentUser$ (): Observable<IUser> {
    // Convert the promise returned by this.auth.getCurrentUserId() to an observable and use combineLatest to avoid using asycn await within operators
    return combineLatest(([this.store.select(getCurrentUserLoadedStatus), from(this.auth.getCurrentUserId())])).pipe(
      tap(([loaded, _id]) => {
        if (!loaded) {
          this.store.dispatch(new LoadCurrentUser({ _id }));
        }
      }),
      // Use mergeMap as the value of loaded is needed to determine if an error occurred
      mergeMap(([loaded]) => {
        return this.store.select(getCurrentUserLoadingStatus).pipe(
          filter(loading => !loading), // Only go to next operator if user is not loading
          switchMap(() => {
            if (loaded) return this.store.pipe(select(getCurrentUser));
            return this.store.select(getCurrentUserErrorStatus).pipe(
              switchMap((error) => throwError(error)),
            );
          }),
        );
      }),
      take(1),
    );
  }

  private checkRedirect$ (routeConfig: IRouteConfigsOpts): Observable<boolean> {
    const { redirectTo } = routeConfig;
    return new Observable((observer: Observer<any>) => {
      if (redirectTo) {
        observer.next(this.router.createUrlTree([redirectTo]));
        observer.complete();
      }
      observer.next(true);
      observer.complete();
    });
  }

  private redirectToLogin$ (logoutOpts?: { errorMessage?: string; lastKnownUrl?: string }): Observable<boolean> {
    return new Observable((observer: Observer<any>) => {
      this.setLastKnownUrl(logoutOpts.lastKnownUrl); // only valid routes will be set since 404 redirect will catch non-existent routes
      const loginUrlTree = this.router.createUrlTree(['/login']);
      observer.next(loginUrlTree);
      observer.complete();
    });
  }

  private redirectToHome$ ({ noAccessUrl }): Observable<boolean|UrlTree> {
    return of(this.router.createUrlTree(['/home'], { queryParams: { noAccessUrl } }));
  }

  private reditectToDistrictPicker$ (): Observable<UrlTree> {
    this.sessionStorageService.removeItem('currentDistrict');
    this.sessionStorageService.removeItem('districts');
    return of(this.router.createUrlTree(['/district-picker']));
  }

  private isValidDistrictForUser (): boolean {
    const currentDistrict: string = this.sessionStorageService.getItem('currentDistrict');
    const accessibleDistricts: IDistrict[] = this.sessionStorageService.getItem('districts');

    if (!currentDistrict || !accessibleDistricts?.find(d => d._id === currentDistrict) ||
      (this.portalConfig.publicConfig.IS_NYC_DISTRICT && currentDistrict !== districtsConfig.NYC_DISTRICT)) {
      return false;
    }
    return true;
  }

  private setLastKnownUrl (url: string) {
    this.localStorageService.setItem('lastKnownUrl', url);
  }

  // Mimic api err handler from main.route.ts for top level `main` state
  // Search `AuthenticationRequiredError` to spot the original logic - jchu
  private handleApiError (context: { err: any | Error; lastKnownUrl: string }): void {
    const { err, lastKnownUrl } = context;
    const httpResErr = err as any;
    const hasHttpAuthErr =
      httpResErr.xhrStatus === 'error' || (httpResErr.error && httpResErr.error.message === API_AUTH_FAILURE_MESSAGE);
    const hasInvalidTokenFormat =
      httpResErr.error && httpResErr.error.message === API_AUTH_INVALID_FORMAT;
    const hasNotFoundError =
      httpResErr.error && httpResErr.statusText === API_AUTH_NOT_FOUND;
    const regErr = err as Error;
    const hasRegAuthErr = regErr.message === API_AUTH_FAILURE_MESSAGE;
    let loginErrToDisplay;
    if (hasHttpAuthErr || hasRegAuthErr || hasInvalidTokenFormat) {
      loginErrToDisplay = 'Unauthorized or bad token. Please try again';
    } else if (hasNotFoundError) {
      loginErrToDisplay = 'User not found';
    } else {
      loginErrToDisplay = ((httpResErr.data && httpResErr.data.message) || regErr.message).slice(0, 50);
    }
    this.rollbar.debug('RouteGuard#handleApiError', err);
    this.auth.logout({ errorMessage: loginErrToDisplay, lastKnownUrl });
  }
}
