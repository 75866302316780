import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { SupportCategoriesPills } from './support-categories-pills.component';

@NgModule({
  imports: [CommonModule, NvDesignLibraryModule, NvTooltipModule],
  declarations: [SupportCategoriesPills],
  exports: [SupportCategoriesPills],
  providers: [],
})
export class SupportCategoriesPillsModule {}
