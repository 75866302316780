import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { NvButtonModule } from '../../../../../nvps-libraries/design/nv-button/nv-button.module';
import { NvDropdownModule } from '../../../../../nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { BaseModalModule } from '../../base-modal.module';
import { FinalDecisionMilestoneModalComponent } from './final-decision-milestone-modal.component';

@NgModule({
  declarations: [FinalDecisionMilestoneModalComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule, BaseModalModule, NvDropdownModule, NvButtonModule],
  exports: [FinalDecisionMilestoneModalComponent],
})
export class FinalDecisionMilestoneModalModule {}
