import { each, reduce } from 'lodash';
import { SubjectAreas } from '../../../ng2/shared/constants/subject-areas.constant';
import { TCreditSubjAreas } from '../../../ng2/shared/typings/interfaces/school.interface';
import { Departments } from './departments.constant';
import { CREDIT_REQS } from './credit-reqs.constant';

// (DS) See this ss for more details about the relationship between Credit Requirements, Departments, and Subjects:
// https://docs.google.com/spreadsheets/d/1rD_VKVzx8fJ2u0i510l9VI0ZlUtSKz_LoDnxiBHaN3g/edit#gid=2042731958

export interface ICreditRequirement {
  human: {
    NYC: string;
    Lansing: string;
    Schenectady: string;
    Uniondale: string;
  },
  humanShort: string;
  humanShortByDistrict?: {
    NYC: string;
    Lansing: string;
    Schenectady: string;
    Uniondale: string;
  }
  gradReq: any;
  humanOnTrackCreditsMetricsTable?: string;
  regentsExams: string[];
  camelCase: string;
  orders: {
    creditPanel: number;
    creditGapPanel: number;
    creditGapModal: number;
    creditGapsWizard: number;
    transcriptAndRegentsHistory?: number;
  };
  hasCreditsEarnedField: boolean;
  join?: any[];
  alignedCreditRequirements?: any[];
  includedCreditRequirements?: any[];
  subjectAreaKeys?: TCreditSubjAreas[];
  department?: any; // (DS) only credit requirements that are included in credit gaps modal should have this
}

export const CreditRequirements = {
  ART: {
    human: {
      NYC: 'Art',
      Lansing: 'VPA',
      Schenectady: 'Art',
      Uniondale: 'Art',
    },
    humanShortByDistrict: {
      NYC: 'Art',
      Lansing: 'VPA',
      Schenectady: 'Art',
      Uniondale: 'Art',
    },
    humanShort: 'Art',
    gradReq: CREDIT_REQS.art.gradReq,
    regentsExams: [],
    camelCase: 'art',
    orders: {
      creditPanel: 14,
      creditGapPanel: 13,
      creditGapModal: 14,
      creditGapsWizard: 12,
      transcriptAndRegentsHistory: 13,
    },
    hasCreditsEarnedField: true,
    department: Departments.ART,
    subjectAreaKeys: [SubjectAreas.ART.camelCase],
  } as ICreditRequirement,

  CTE: {
    human: {
      NYC: 'CTE',
      Lansing: 'CTE',
      Schenectady: 'CTE',
      Uniondale: 'CTE',
    },
    humanShort: 'CTE',
    gradReq: CREDIT_REQS.cte.gradReq,
    regentsExams: [],
    camelCase: 'cte',
    orders: {
      creditPanel: 15,
      creditGapPanel: null,
      creditGapModal: 13,
      creditGapsWizard: 11,
      transcriptAndRegentsHistory: 14,
    },
    hasCreditsEarnedField: true,
    department: Departments.CTE,
    subjectAreaKeys: [SubjectAreas.CTE.camelCase],
  } as ICreditRequirement,

  ELA_CORE: {
    human: {
      NYC: 'ELA',
      Lansing: 'ELA',
      Schenectady: 'ELA',
      Uniondale: 'ELA',
    },
    humanShort: 'ELA',
    gradReq: CREDIT_REQS.elaCore.gradReq,
    regentsExams: ['compEla', 'ccEla'], // RegentsCategory.ELA
    camelCase: 'elaCore',
    orders: {
      creditPanel: 2,
      creditGapPanel: 2,
      creditGapModal: 2,
      creditGapsWizard: 2,
      transcriptAndRegentsHistory: 1,

    },
    hasCreditsEarnedField: true,
    department: Departments.ELA,
    subjectAreaKeys: [SubjectAreas.ELA_CORE.camelCase, SubjectAreas.ELA_CORE_ESL.camelCase],
  } as ICreditRequirement,

  ELA_OTHER: {
    human: {
      NYC: 'ELA Other',
      Lansing: 'ELA Other',
      Schenectady: 'ELA Other',
      Uniondale: 'ELA Other',
    },
    humanShort: 'ELA Other',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
    },
    regentsExams: [],
    camelCase: 'elaOther',
    orders: {
      creditPanel: null,
      creditGapPanel: null,
      creditGapModal: null,
      creditGapsWizard: null,
    },
    hasCreditsEarnedField: true,
  } as ICreditRequirement,

  HEALTH: {
    human: {
      NYC: 'Health',
      Lansing: 'Health',
      Schenectady: 'Health',
      Uniondale: 'Health',
    },
    humanShort: 'Health',
    gradReq: CREDIT_REQS.health.gradReq,
    regentsExams: [],
    camelCase: 'health',
    orders: {
      creditPanel: 13,
      creditGapPanel: 12,
      creditGapModal: 16,
      creditGapsWizard: 14,
      transcriptAndRegentsHistory: 12,
    },
    hasCreditsEarnedField: true,
    department: Departments.HPE,
    subjectAreaKeys: [SubjectAreas.HEALTH.camelCase],
  } as ICreditRequirement,

  HPE: {
    human: {
      NYC: 'Health / PE',
      Lansing: 'Health / PE',
      Schenectady: 'Health / PE',
      Uniondale: 'Health / PE',
    },
    humanShort: 'Health / PE',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
    },
    regentsExams: [],
    camelCase: 'hpe',
    orders: {
      creditPanel: null,
      creditGapPanel: null,
      creditGapModal: null,
      creditGapsWizard: null,
    },
    hasCreditsEarnedField: false,
  } as ICreditRequirement,

  LOTE: {
    human: {
      NYC: 'LOTE',
      Lansing: 'World Language',
      Schenectady: 'LOTE',
      Uniondale: 'LOTE',
    },
    humanShortByDistrict: {
      NYC: 'LOTE',
      Lansing: 'World Lang',
      Schenectady: 'LOTE',
      Uniondale: 'LOTE',
    },
    humanShort: 'LOTE',
    gradReq: CREDIT_REQS.lote.gradReq,
    regentsExams: ['lote'], // RegentsCategory.LOTE
    camelCase: 'lote',
    orders: {
      creditPanel: 12,
      creditGapPanel: 10,
      creditGapModal: 12,
      creditGapsWizard: 10,
      transcriptAndRegentsHistory: 10,
    },
    hasCreditsEarnedField: true,
    department: Departments.LOTE,
    subjectAreaKeys: [SubjectAreas.LOTE.camelCase],
  } as ICreditRequirement,

  MATH: {
    human: {
      NYC: 'Math',
      Lansing: 'Math',
      Schenectady: 'Math',
      Uniondale: 'Math',
    },
    humanShort: 'Math',
    gradReq: CREDIT_REQS.math.gradReq,
    regentsExams: ['intAlg', 'ccAlg', 'oldGeom', 'ccGeom', 'oldTrig', 'ccTrig'], // RegentsCategory.Math
    camelCase: 'math',
    orders: {
      creditPanel: 3,
      creditGapPanel: 3,
      creditGapModal: 3,
      creditGapsWizard: 3,
      transcriptAndRegentsHistory: 2,

    },
    hasCreditsEarnedField: true,
    join: ['mathAlg', 'mathGeom', 'mathTrig'],
    department: Departments.MATH,
    subjectAreaKeys: [
      SubjectAreas.MATH_ALG.camelCase,
      SubjectAreas.MATH_GEOM.camelCase,
      SubjectAreas.MATH_TRIG.camelCase,
      SubjectAreas.MATH_STATS.camelCase,
      SubjectAreas.MATH_PRE_CALC.camelCase,
      SubjectAreas.MATH_CALC.camelCase,
      SubjectAreas.MATH_OTHER.camelCase,
      // math alg1 and alg2 are for lansing only
      SubjectAreas.MATH_ALG1.camelCase,
      SubjectAreas.MATH_ALG2.camelCase,
    ],
  } as ICreditRequirement,

  MISC: {
    human: {
      NYC: 'Misc',
      Lansing: 'Misc',
      Schenectady: 'Misc',
      Uniondale: 'Misc',
    },
    humanShort: 'Misc',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
    },
    regentsExams: [],
    camelCase: 'misc',
    orders: {
      creditPanel: null,
      creditGapPanel: null,
      creditGapModal: null,
      creditGapsWizard: null,
      transcriptAndRegentsHistory: 15,
    },
    hasCreditsEarnedField: false,
  } as ICreditRequirement,

  PE: {
    human: {
      NYC: 'PE',
      Lansing: 'PE',
      Schenectady: 'PE',
      Uniondale: 'PE',
    },
    humanShort: 'PE',
    gradReq: CREDIT_REQS.pe.gradReq,
    regentsExams: [],
    camelCase: 'pe',
    orders: {
      creditPanel: 12,
      creditGapPanel: 11,
      creditGapModal: 15,
      creditGapsWizard: 13,
      transcriptAndRegentsHistory: 11,
    },
    hasCreditsEarnedField: true,
    department: Departments.HPE,
    subjectAreaKeys: [SubjectAreas.PE.camelCase],
  } as ICreditRequirement,

  SCI_CORE: {
    human: {
      NYC: 'Sci Core',
      Lansing: 'Sci Core',
      Schenectady: 'Sci Core',
      Uniondale: 'Sci Core',
    },
    humanShort: 'Sci Core',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
    },
    regentsExams: [],
    camelCase: 'sciCore',
    orders: {
      creditPanel: null,
      creditGapPanel: null,
      creditGapModal: null,
      creditGapsWizard: null,
    },
    hasCreditsEarnedField: false,
  } as ICreditRequirement,

  SCI_PHYSICAL: {
    human: {
      NYC: 'Physical Science',
      Lansing: 'Chemistry/Physics',
      Schenectady: 'Physical Science',
      Uniondale: 'Physical Science',
    },
    humanShort: 'Phys Sci',
    gradReq: CREDIT_REQS.sciPhysical.gradReq,
    regentsExams: ['chem', 'physics', 'earth'], // RegentsCategory.PhysicalScience
    camelCase: 'sciPhysical',
    orders: {
      creditPanel: 11,
      creditGapPanel: 9,
      creditGapModal: 10,
      creditGapsWizard: 8,
      transcriptAndRegentsHistory: 9,
    },
    hasCreditsEarnedField: true,
    join: ['sciEarth', 'sciPhysics', 'sciChem'],
    department: Departments.SCI,
    subjectAreaKeys: [
      SubjectAreas.SCI_CHEM.camelCase,
      SubjectAreas.SCI_EARTH.camelCase,
      SubjectAreas.SCI_PHYSICS.camelCase,
      SubjectAreas.SCI_PHYSICAL.camelCase,
      SubjectAreas.SCI_FORENSICS.camelCase, // forensics counted as sci_physical through SY18-19. This is used for student-credit-gaps-panel-course-history.
    ],
  } as ICreditRequirement,

  SCI_LIFE: {
    human: {
      NYC: 'Life Science',
      Lansing: 'Biology',
      Schenectady: 'Life Science',
      Uniondale: 'Life Science',
    },
    humanShort: 'Life Sci',
    gradReq: CREDIT_REQS.sciLife.gradReq,
    regentsExams: ['livingEnv'], // RegentsCategory.LifeScience
    camelCase: 'sciLife',
    orders: {
      creditPanel: 10,
      creditGapPanel: 8,
      creditGapModal: 11,
      creditGapsWizard: 9,
      transcriptAndRegentsHistory: 8,
    },
    hasCreditsEarnedField: true,
    department: Departments.SCI,
    subjectAreaKeys: [
      SubjectAreas.SCI_BIO.camelCase,
      SubjectAreas.SCI_LIVING.camelCase,
      SubjectAreas.SCI_LIFE.camelCase,
    ],
  } as ICreditRequirement,

  SCI_OTHER: {
    human: {
      NYC: 'Sci Other',
      Lansing: 'Sci Other',
      Schenectady: 'Sci Other',
      Uniondale: 'Sci Other',
    },
    humanShort: 'Sci Other',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
    },
    regentsExams: [],
    camelCase: 'sciOther',
    orders: {
      creditPanel: null,
      creditGapPanel: null,
      creditGapModal: null,
      creditGapsWizard: null,
      transcriptAndRegentsHistory: 7,
    },
    hasCreditsEarnedField: false,
    department: Departments.SCI,
    subjectAreaKeys: [SubjectAreas.SCI_FORENSICS.camelCase],
  } as ICreditRequirement,

  SCI_TOTAL: {
    human: {
      NYC: 'Total Science',
      Lansing: 'Science',
      Schenectady: 'Total Science',
      Uniondale: 'Total Science',
    },
    humanShort: 'Total Sci',
    gradReq: CREDIT_REQS.sciTotal.gradReq,
    regentsExams: ['chem', 'physics', 'earth', 'livingEnv'], // RegentsCategory.Science
    camelCase: 'sciTotal',
    orders: {
      creditPanel: 9,
      creditGapPanel: 7,
      creditGapModal: 9,
      creditGapsWizard: 7,
      transcriptAndRegentsHistory: 7,
    },
    hasCreditsEarnedField: true,
    join: ['sciEarth', 'sciPhysics', 'sciChem', 'sciLiving'],
    department: Departments.SCI,
    subjectAreaKeys: [
      SubjectAreas.SCI_LIVING.camelCase,
      SubjectAreas.SCI_BIO.camelCase,
      SubjectAreas.SCI_LIFE.camelCase,
      SubjectAreas.SCI_CHEM.camelCase,
      SubjectAreas.SCI_EARTH.camelCase,
      SubjectAreas.SCI_PHYSICS.camelCase,
      SubjectAreas.SCI_PHYSICAL.camelCase,
      SubjectAreas.SCI_OTHER.camelCase,
      SubjectAreas.SCI_FORENSICS.camelCase,
    ],
  } as ICreditRequirement,

  SS_ECON: {
    human: {
      NYC: 'Economics',
      Lansing: 'Economics',
      Schenectady: 'Economics',
      Uniondale: 'Economics',
    },
    humanShort: 'Econ',
    gradReq: CREDIT_REQS.ssEcon.gradReq,
    regentsExams: [],
    camelCase: 'ssEcon',
    orders: {
      creditPanel: 7,
      creditGapPanel: null,
      creditGapModal: 7,
      creditGapsWizard: null,
      transcriptAndRegentsHistory: 6,
    },
    hasCreditsEarnedField: true,
    department: Departments.SS,
    subjectAreaKeys: [SubjectAreas.SS_GOVT_ECON.camelCase, SubjectAreas.SS_ECON.camelCase],
  } as ICreditRequirement,

  SS_ELECTIVE: {
    human: {
      NYC: 'SS Elective',
      Lansing: 'SS Elective',
      Schenectady: 'SS Elective',
      Uniondale: 'SS Elective',
    },
    humanShort: 'SS Elective',
    gradReq: {
      NYC: 0,
      Lansing: 0,
      Schenectady: 0,
      Uniondale: 0,
    },
    regentsExams: [],
    camelCase: 'ssElective',
    orders: {
      creditPanel: null,
      creditGapPanel: null,
      creditGapModal: null,
      creditGapsWizard: null,
    },
    hasCreditsEarnedField: true,
  } as ICreditRequirement,

  SS_GLOBAL: {
    human: {
      NYC: 'Global',
      Lansing: 'World History',
      Schenectady: 'Global',
      Uniondale: 'Global',
    },
    humanShort: 'Global',
    gradReq: CREDIT_REQS.ssGlobal.gradReq,
    regentsExams: ['global', 'globalTwo'], // RegentsCategory.Global
    camelCase: 'ssGlobal',
    orders: {
      creditPanel: 4,
      creditGapPanel: 4,
      creditGapModal: 4,
      creditGapsWizard: 4,
      transcriptAndRegentsHistory: 3,
    },
    hasCreditsEarnedField: true,
    department: Departments.SS,
    subjectAreaKeys: [SubjectAreas.SS_GLOBAL.camelCase],
  } as ICreditRequirement,

  SS_GOVT: {
    human: {
      NYC: 'Government',
      Lansing: 'Government',
      Schenectady: 'Government',
      Uniondale: 'Government',
    },
    humanShort: 'Govt',
    gradReq: CREDIT_REQS.ssGovt.gradReq,
    regentsExams: [],
    camelCase: 'ssGovt',
    orders: {
      creditPanel: 6,
      creditGapPanel: null,
      creditGapModal: 6,
      creditGapsWizard: null,
      transcriptAndRegentsHistory: 5,
    },
    department: Departments.SS,
    hasCreditsEarnedField: true,
  } as ICreditRequirement,

  SS_GOVT_ECON: {
    human: {
      NYC: 'Govt / Econ',
      Lansing: 'Govt / Econ',
      Schenectady: 'Govt / Econ',
      Uniondale: 'Govt / Econ',
    },
    humanShort: 'Gov / Econ',
    gradReq: CREDIT_REQS.ssGovtEcon.gradReq,
    regentsExams: [],
    camelCase: 'ssGovtEcon',
    orders: {
      creditPanel: 8,
      creditGapPanel: 6,
      creditGapModal: 8,
      creditGapsWizard: 6,
    },
    hasCreditsEarnedField: false,
    department: Departments.SS,
    subjectAreaKeys: [
      SubjectAreas.SS_GOVT.camelCase,
      SubjectAreas.SS_GOVT_ECON.camelCase,
      SubjectAreas.SS_ECON.camelCase,
    ],
  } as ICreditRequirement,

  SS_US: {
    human: {
      NYC: 'US',
      Lansing: 'US History',
      Schenectady: 'US',
      Uniondale: 'US',
    },
    humanShort: 'US',
    gradReq: CREDIT_REQS.ssUs.gradReq,
    regentsExams: ['us', 'usFramework'], // RegentsCategory.Us
    camelCase: 'ssUs',
    orders: {
      creditPanel: 5,
      creditGapPanel: 5,
      creditGapModal: 5,
      creditGapsWizard: 5,
      transcriptAndRegentsHistory: 4,
    },
    hasCreditsEarnedField: true,
    department: Departments.SS,
    subjectAreaKeys: [SubjectAreas.SS_US.camelCase],
  } as ICreditRequirement,

  // subjectAreaKeys for TOTAL are added dynamically by getUniqueSubjectAreaKeys()
  TOTAL: {
    human: {
      NYC: 'Total Area',
      Lansing: 'Total Area',
      Schenectady: 'Total Area',
      Uniondale: 'Total Area',
    },
    humanShort: '44 Req',
    humanShortByDistrict: {
      NYC: '44 Req',
      Lansing: '22 Req',
      Schenectady: '22 Req',
      Uniondale: '22 Req',
    },
    gradReq: CREDIT_REQS.total.gradReq,
    humanOnTrackCreditsMetricsTable: 'Total',
    regentsExams: [],
    camelCase: 'total',
    orders: {
      creditPanel: 1,
      creditGapPanel: 1,
      creditGapModal: 1,
      creditGapsWizard: 1,
    },
    hasCreditsEarnedField: true,
    join: [
      'elaCore',
      'health',
      'mathAlg',
      'mathGeom',
      'mathTrig',
      'sciChem',
      'sciEarth',
      'sciLiving',
      'sciPhysics',
      'ssEcon',
      'ssGlobal',
      'ssGovtEcon',
      'ssUs',
    ],
  } as ICreditRequirement,
};

// ADD ALL unique subjectAreaKeys in CreditRequirements to CreditRequirements.TOTAL.subjectAreaKeys
CreditRequirements.TOTAL.subjectAreaKeys = getUniqueSubjectAreaKeys();

function getUniqueSubjectAreaKeys() {
  const acc = {
    uniqueSubjectAreaKeysHash: {},
    uniqueSubjectAreaKeys: [],
  };
  const { uniqueSubjectAreaKeys } = reduce(
    CreditRequirements,
    (acc, creditRequirement: ICreditRequirement) => {
      const { subjectAreaKeys } = creditRequirement;

      if (subjectAreaKeys) {
        each(subjectAreaKeys, key => {
          if (!acc.uniqueSubjectAreaKeysHash[key]) {
            acc.uniqueSubjectAreaKeysHash[key] = true;
            acc.uniqueSubjectAreaKeys.push(key);
          }
        });
      }

      return acc;
    },
    acc,
  );

  return uniqueSubjectAreaKeys;
}

// ALIGNED CREDIT REQUIREMENTS
// Example #1 - ART impacts credit gaps for ART and TOTAL
// Example #2 - SCI_LIFE impacts credit gaps for SCI_LIFE, SCI_TOTAL, and TOTAL
CreditRequirements.ART.alignedCreditRequirements = [CreditRequirements.ART, CreditRequirements.TOTAL];
CreditRequirements.CTE.alignedCreditRequirements = [CreditRequirements.CTE, CreditRequirements.TOTAL];
CreditRequirements.ELA_CORE.alignedCreditRequirements = [CreditRequirements.ELA_CORE, CreditRequirements.TOTAL];
CreditRequirements.ELA_OTHER.alignedCreditRequirements = [CreditRequirements.ELA_OTHER, CreditRequirements.TOTAL];
CreditRequirements.HEALTH.alignedCreditRequirements = [CreditRequirements.HEALTH, CreditRequirements.TOTAL];
CreditRequirements.HPE.alignedCreditRequirements = [CreditRequirements.HPE, CreditRequirements.TOTAL];
CreditRequirements.LOTE.alignedCreditRequirements = [CreditRequirements.LOTE, CreditRequirements.TOTAL];
CreditRequirements.MATH.alignedCreditRequirements = [CreditRequirements.MATH, CreditRequirements.TOTAL];
CreditRequirements.MISC.alignedCreditRequirements = [CreditRequirements.MISC, CreditRequirements.TOTAL];
CreditRequirements.PE.alignedCreditRequirements = [CreditRequirements.PE, CreditRequirements.TOTAL];
CreditRequirements.SCI_CORE.alignedCreditRequirements = [CreditRequirements.SCI_CORE, CreditRequirements.TOTAL];
CreditRequirements.SCI_PHYSICAL.alignedCreditRequirements = [
  CreditRequirements.SCI_PHYSICAL,
  CreditRequirements.SCI_TOTAL,
  CreditRequirements.TOTAL,
];
CreditRequirements.SCI_LIFE.alignedCreditRequirements = [
  CreditRequirements.SCI_LIFE,
  CreditRequirements.SCI_TOTAL,
  CreditRequirements.TOTAL,
];
CreditRequirements.SCI_OTHER.alignedCreditRequirements = [CreditRequirements.SCI_TOTAL, CreditRequirements.TOTAL];
CreditRequirements.SCI_TOTAL.alignedCreditRequirements = [CreditRequirements.SCI_TOTAL, CreditRequirements.TOTAL];
CreditRequirements.SS_ECON.alignedCreditRequirements = [CreditRequirements.SS_GOVT_ECON, CreditRequirements.TOTAL];
CreditRequirements.SS_ELECTIVE.alignedCreditRequirements = [CreditRequirements.SS_ELECTIVE, CreditRequirements.TOTAL];
CreditRequirements.SS_GLOBAL.alignedCreditRequirements = [CreditRequirements.SS_GLOBAL, CreditRequirements.TOTAL];
CreditRequirements.SS_GOVT.alignedCreditRequirements = [CreditRequirements.SS_GOVT_ECON, CreditRequirements.TOTAL];
CreditRequirements.SS_GOVT_ECON.alignedCreditRequirements = [CreditRequirements.SS_GOVT_ECON, CreditRequirements.TOTAL];
CreditRequirements.SS_US.alignedCreditRequirements = [CreditRequirements.SS_US, CreditRequirements.TOTAL];
CreditRequirements.TOTAL.alignedCreditRequirements = [CreditRequirements.TOTAL];

// INCLUDED GRAD REQUIREMENTS
// Example #1 - SCI_TOTAL includes SCI_LIFE and SCI_PHYSICAL
// Example #2 - SS_GOVT_ECON includes SS_ECON and SS_GOVT
CreditRequirements.ART.includedCreditRequirements = [CreditRequirements.ART.camelCase];
CreditRequirements.CTE.includedCreditRequirements = [CreditRequirements.CTE.camelCase];
CreditRequirements.ELA_CORE.includedCreditRequirements = [CreditRequirements.ELA_CORE.camelCase];
CreditRequirements.ELA_OTHER.includedCreditRequirements = [CreditRequirements.ELA_OTHER.camelCase];
CreditRequirements.HEALTH.includedCreditRequirements = [CreditRequirements.HEALTH.camelCase];
CreditRequirements.HPE.includedCreditRequirements = [CreditRequirements.HPE.camelCase];
CreditRequirements.LOTE.includedCreditRequirements = [CreditRequirements.LOTE.camelCase];
CreditRequirements.MATH.includedCreditRequirements = [CreditRequirements.MATH.camelCase];
CreditRequirements.MISC.includedCreditRequirements = [CreditRequirements.MISC.camelCase];
CreditRequirements.PE.includedCreditRequirements = [CreditRequirements.PE.camelCase];
CreditRequirements.SCI_CORE.includedCreditRequirements = [CreditRequirements.SCI_CORE.camelCase];
CreditRequirements.SCI_PHYSICAL.includedCreditRequirements = [CreditRequirements.SCI_PHYSICAL.camelCase];
CreditRequirements.SCI_LIFE.includedCreditRequirements = [CreditRequirements.SCI_LIFE.camelCase];
CreditRequirements.SCI_OTHER.includedCreditRequirements = [CreditRequirements.SCI_OTHER.camelCase];
CreditRequirements.SCI_TOTAL.includedCreditRequirements = [
  CreditRequirements.SCI_TOTAL.camelCase,
  CreditRequirements.SCI_LIFE.camelCase,
  CreditRequirements.SCI_PHYSICAL.camelCase,
  CreditRequirements.SCI_OTHER.camelCase,
];
CreditRequirements.SS_ECON.includedCreditRequirements = [CreditRequirements.SS_ECON.camelCase];
CreditRequirements.SS_ELECTIVE.includedCreditRequirements = [CreditRequirements.SS_ELECTIVE.camelCase];
CreditRequirements.SS_GLOBAL.includedCreditRequirements = [CreditRequirements.SS_GLOBAL.camelCase];
CreditRequirements.SS_GOVT.includedCreditRequirements = [CreditRequirements.SS_GOVT.camelCase];
CreditRequirements.SS_GOVT_ECON.includedCreditRequirements = [
  CreditRequirements.SS_ECON.camelCase,
  CreditRequirements.SS_GOVT.camelCase,
  CreditRequirements.SS_GOVT_ECON.camelCase,
];
CreditRequirements.SS_US.includedCreditRequirements = [CreditRequirements.SS_US.camelCase];
CreditRequirements.TOTAL.includedCreditRequirements = [CreditRequirements.TOTAL.camelCase];
