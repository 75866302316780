import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BatchEditService } from '../../services/batch-edit-service/batch-edit-service';
import { BaseModalModule } from '../base-modal.module';
import { NvDesignLibraryModule } from './../../../../nvps-libraries/design/nv-design-library.module';
import { EndorsementsCredentialsModalComponent } from './endorsements-credentials-modal.component';
import { NvIconModule } from 'Src/nvps-libraries/design/nv-icon/nv-icon.module';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';

@NgModule({
  declarations: [EndorsementsCredentialsModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatSelectModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    BaseModalModule,
    MatMenuModule,
    NvDesignLibraryModule,
    NvIconModule,
  ],
  exports: [EndorsementsCredentialsModalComponent],
  providers: [BatchEditService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class EndorsementsCredentialsModalModule {}
