import { UtilitiesService } from './../../services/utilities/utilities.service';
import { tap } from 'rxjs/operators';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { filter, orderBy, remove } from 'lodash';
import { NotificationMessageParser } from './../../services/notification-message-parser/notification-message-parser.service';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { INotification } from '../../typings/interfaces/notification.interface';

interface IDisplayNotification extends INotification {
  canBeDismissed: boolean;
}

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class Notifications {
  @Input() notifications;
  @Input() currentUser;

  public displayedNotifications = [];
  public toggleText;
  public notificationMessages = {};

  constructor (
    private notificationMessageParser: NotificationMessageParser,
    private apiService: ApiService,
    private utilitiesService: UtilitiesService,
  ) {
    //
  }

  ngOnInit (): void {
    if (this.notifications) this.loadNotifications(this.notifications);
  }

  private loadNotifications (notifications): void {
    this.notifications = filter(notifications, (notification: IDisplayNotification) => {
      this.notificationMessages[notification._id] = this.notificationMessageParser.parseMessage(notification.message);

      notification.canBeDismissed = this.isDismissable(notification);

      const isAlert = notification.type === 'ALERT';
      const isDismissed = notification.dismissals[this.currentUser._id];

      if (isAlert && !isDismissed) {
        return notification;
      }
    });

    this.displayedNotifications = this.sortNotifications().slice(0, 2);
    this.toggleText = `${this.notifications.length - 2} more notifications` || null;
  }

  public isDismissable (item): boolean {
    const isDismissed = item.dismissals[this.currentUser._id];
    const canBeDismissed = item.isDismissable && !isDismissed;
    return canBeDismissed;
  }

  private sortNotifications () {
    return orderBy(this.notifications, ['priority', 'endsOn']);
  }

  public dismissNotification (notification): void {
    // current users id exists as a key, regardless of value
    if (notification.dismissals[this.currentUser._id] === true) {
      // should not enter this condition at all, button should be disable
      console.warn(`${this.currentUser._id} has already notification has already been acknowledged by user`);
    } else if (notification.isDismissable) {
      const path = 'dismissals';
      const newValue = { [this.currentUser._id]: true };
      const patch = this.utilitiesService.setFieldByPath({}, path, newValue);

      this.apiService
        .patchNotification({ _id: notification._id, patch })
        .pipe(
          tap((res: any) => {
            // need to remove both from original set as well as what is currently displayed
            remove(this.displayedNotifications, (n: any) => n._id === res._id);
            remove(this.notifications, (n: any) => n._id === res._id);
            if (this.displayedNotifications.length < 2) this.displayedNotifications = this.notifications;
          }),
        )
        .subscribe();
    }
  }

  public toggleDisplayedNotifications (): void {
    // if there were 5 originally but what is currently displayed is not 5
    if (this.displayedNotifications.length !== this.notifications.length) {
      this.displayedNotifications = this.sortNotifications();
      this.toggleText = 'Show Less';
    } else {
      this.displayedNotifications = this.sortNotifications().slice(0, 2);
      this.toggleText = `${this.notifications.length - 2} more notifications`;
    }
  }
}
