/* tslint:disable */
import { Action } from '@ngrx/store';
import { IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';
export const LOAD_SDC_STUDENTS = '[Portal] Load SDC Students';
export const UPDATE_SDC_STUDENTS = '[Portal] Update SDC Students';
export const UPDATE_SDC_STUDENT = '[Portal] Update SDC Student';
export const UPDATE_SDC_STUDENTS_NO_REQUEST = '[Portal] Update SDC Students No request';
export const UPDATE_SDC_STUDENT_NO_REQUEST = '[Portal] Update SDC Student No request';
export const LOAD_SDC_STUDENTS_SUCCESS = '[Portal] Load SDC Students Success';
export const UPDATE_SDC_STUDENTS_SUCCESS = '[Portal] Update SDC Students Success';
export const UPDATE_SDC_STUDENT_SUCCESS = '[Portal] Update SDC Student Success';
export const SET_SDC_GRID_STATE = '[Portal] Set SDC Grid State';

export class LoadSdcStudents implements Action {
  readonly type = LOAD_SDC_STUDENTS;
  constructor (public payload: {
    schoolId: string,
    columnKeys: Array<string>,
    joins: Array<string>,
    loadedProjection: Array<string>
    loadedJoins: Array<string>
    bundleName: string,
  }) {}
}

export class LoadSdcStudentsSuccess implements Action {
  readonly type = LOAD_SDC_STUDENTS_SUCCESS;
  constructor (public payload: {
    students: {[studentID:string]: any },
    flattenedStudents: {[studentID:string]: any },
    joins: Array<string>,
    projection: {[columnKey:string]: any },
    columnKeys: Array<string>
  }) {}
}

export class UpdateSdcStudents implements Action {
  readonly type = UPDATE_SDC_STUDENTS;
  constructor (public payload: {
    patches: Array<any>,
  }) {}
}

export class UpdateSdcStudent implements Action {
  readonly type = UPDATE_SDC_STUDENT;
  constructor (public payload: {
    id: string,
    patch: any,
  }) {}
}

export class UpdateSdcStudentsNoRequest implements Action {
  readonly type = UPDATE_SDC_STUDENTS_NO_REQUEST;
  constructor (public payload: {
    patches: Array<{[columnKey: string]: any}>,
  }) {}
}

export class UpdateSdcStudentNoRequest implements Action {
  readonly type = UPDATE_SDC_STUDENT_NO_REQUEST;
  constructor (public payload: IStudent) {}
}

export class UpdateSdcStudentsSuccess implements Action {
  readonly type = UPDATE_SDC_STUDENTS_SUCCESS;
  constructor (public payload) {}
}

export class UpdateSdcStudentSuccess implements Action {
  readonly type = UPDATE_SDC_STUDENT_SUCCESS;
  constructor (public payload) {}
}

export class SetSdcGridState implements Action {
  readonly type = SET_SDC_GRID_STATE;
  constructor (public payload: { sdcKey: string, state: any }) {}
}

export type SdcAction = LoadSdcStudents
| LoadSdcStudentsSuccess
| UpdateSdcStudents
| UpdateSdcStudent
| UpdateSdcStudentsSuccess
| UpdateSdcStudentSuccess
| UpdateSdcStudentsNoRequest
| UpdateSdcStudentNoRequest
| SetSdcGridState;
