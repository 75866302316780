import { getCurrentUser } from 'Src/ng2/store/selectors/current-user-selectors';
import { Auth } from 'Src/ng2/shared/auth/auth.service';
import { LoadCurrentUser } from './../../../store/actions/current-user-actions';
import { getCurrentUserLoadedStatus } from './../../../store/selectors/current-user-selectors';
import { tap, filter, switchMap, take, catchError, mapTo } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable()
export class CurrentUserGuard implements CanActivate {
  constructor (private store: Store<any>, private auth: Auth) {}

  getFromStoreOrAPI (): Observable<any> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      tap(async loaded => {
        if (!loaded) {
          const _id = await this.auth.getCurrentUserId();
          this.store.dispatch(new LoadCurrentUser({ _id }));
        }
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
      take(1),
    );
  }

  canActivate (): Observable<boolean> {
    return this.getFromStoreOrAPI().pipe(
      mapTo(true),
      catchError(() => of(false)),
    );
  }

  canActivateChild (): Observable<boolean> {
    return this.canActivate();
  }
}
