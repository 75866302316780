import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';
import { PortalConfig } from './../../shared/services/portal-config';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take, tap, filter as rxjsFilter, map as rxjsMap, takeUntil } from 'rxjs/operators';
import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { HelpDeskService } from '../../shared/services/help-desk/help-desk.service';
import { ImUser } from '../../shared/services/im-models/im-user';
import { IUser } from '../../shared/typings/interfaces/user.interface';
import { getCurrentUser } from './../../store/selectors/current-user-selectors';
import { Subject } from 'rxjs';
import { Toggles } from 'Src/ng2/shared/constants/toggles.constant';
import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';

export interface INavItem {
  key: string;
  humanName: string;
  stateName?: string;
}

@Component({
  selector: 'network-left-side-nav',
  templateUrl: './network-left-side-nav.component.html',
  styleUrls: ['./network-left-side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NetworkLeftSideNavComponent implements OnInit {
  public userMenuOptions: INavItem[];
  public currentUser: IUser;
  public label: string;
  public selectedView: string;


  public mySchoolsTitle: string;
  public networkSidebarOptions: ISidebarItem[];
  public selectedSidebarKey: string;

  public destroy$: Subject<boolean> = new Subject<boolean>();
  public activeUrl: string;
  private isAttendanceTrendsToggleOn: boolean;

  constructor (
    private helpDeskService: HelpDeskService,
    private imUser: ImUser,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private windowRef: WindowRef,
    private portalConfig: PortalConfig,
    private toggleService: ToggleService,
  ) { }

  ngOnInit () {
    this.store
      .pipe(
        select(getCurrentUser),
        tap(currentUser => {
          this.currentUser = currentUser;
          this.label = this.getClusterName();
        }),
        take(1),
      )
      .subscribe();

    this.selectedView = 'Network';
    this.isAttendanceTrendsToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_ATTENDANCE_TRENDS);

    this.networkSidebarOptions = [
      {
        human: 'Dashboard',
        key: 'NETWORK_DASHABORD',
        url: '../dashboard',
      },
      ...(
          this.isAttendanceTrendsToggleOn
            ? [
                {
                  human: 'Attendance Trends',
                  key: 'ATTENDANCE_TRENDS',
                  url: '../attendance',
                },
              ]
            : []
        ),
      {
        human: 'Grid',
        key: 'NETWORK_GRID',
        url: '../grid',
      },
    ];
    this.mySchoolsTitle = 'My Schools';
    this.activeUrl = this.getActiveUrl(this.router.routerState.snapshot.url);
    this.updateSelectedItem(this.networkSidebarOptions, { activeUrl: this.activeUrl });

    this.userMenuOptions = [
      {
        key: 'getHelp',
        humanName: 'Get Help',
        stateName: null,
      },
      {
        key: 'privacyAndTerms',
        humanName: 'Privacy & Terms',
        stateName: 'privacy-and-terms',
      },
    ];
    if (this.imUser.canViewNetworkSettings(this.currentUser)) {
      this.userMenuOptions.unshift({
        key: 'networkSettings',
        humanName: 'Settings',
        stateName: null,
      });
    }
  }

  public updateSelectedItem (menuConfigs: ISidebarItem[], { selectedItemKey, activeUrl }: { selectedItemKey?: string, activeUrl?: string }): void {
    if (selectedItemKey) { // find item by key
      const navItem = menuConfigs.find(navItem => {
        if (navItem.key === selectedItemKey) return true;
        if (navItem.children) return this.updateSelectedItem(navItem.children, { selectedItemKey });
        return false;
      });
      if (!navItem?.children) this.selectedSidebarKey = selectedItemKey;
    } else { // find item by url
      menuConfigs.find(navItem => {
        // may need to rethink how urls are stored if they become more complex
        const relativeUrl = navItem.url.split('../').join('');
        if (activeUrl === relativeUrl) {
          this.selectedSidebarKey = navItem.key;
          return true;
        }
        if (navItem?.children) return this.updateSelectedItem(navItem.children, { activeUrl });
        return false;
      });
    }
  }

  public goToState (option: INavItem): void {
    const window = this.windowRef.nativeWindow;
    switch (option.key) {
      case 'networkSettings':
        this.router.navigate(['../settings'], { relativeTo: this.route });
        break;
      case 'getHelp':
        this.helpDeskService.showHelp();
        break;
      case 'privacyAndTerms':
        window.open(this.portalConfig.publicConfig.PRIVACY_POLICY, '_blank');
        break;
      default:
        return null;
    }
  }

  private getClusterName (): string {
    return this.currentUser._role_cluster
      ? this.currentUser._role_cluster.clusterName
      : this.route.snapshot.data.cluster.clusterName;
  }

  private getActiveUrl (url: string): string {
    if (!url) return;
    const [urlWithoutParams] = url.split('?');
    const remainingPath = urlWithoutParams.split('/').slice(4);
    const activeUrl = remainingPath.join('/');
    return activeUrl;
  }

  public navigateToHomepage (): void {
    this.router.navigate(['../dashboard'], { relativeTo: this.route });
  }
}
