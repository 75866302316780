import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from '../../../typings/interfaces/design-library.interface';
import { IRowData } from '../../../models/list-models';

@Component({
  selector: 'portfolio-permission-dropdown',
  templateUrl: './portfolio-permission-dropdown.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class PortfolioPermissionDropdownComponent implements OnInit {
  selected: string; // the key of selected option
  plainText: string; // the human of selected option
  options: IDropdownOption[] = [
    { key: 'View only', human: 'View only' },
    { key: 'Can edit', human: 'Can edit' },
    { key: 'No access', human: 'No access' },
  ];

  public mode: 'DROPDOWN_MODE' | 'TEXT_MODE';
  @Input() trigger: null | boolean = null;
  @Input() input: { row: IRowData[]; column: IRowData };
  @Output() output = new EventEmitter();

  ngOnInit () {
    const { column: permissionCol } = this.input;
    const human = permissionCol.data;
    this.selected = human;
    this.plainText = human;
    this.mode = this.trigger ? 'DROPDOWN_MODE' : 'TEXT_MODE';
  }

  public onSelect (e): void {
    const { row } = this.input;
    this.output.emit({ selectedKey: e, row });
  }
}
