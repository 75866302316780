export enum SettingsRoute {
  Metrics = 'Metrics',
  MasterProgram = 'Master Program',
  Supports = 'Supports',
  Activities = 'Activities',
  UserManagement = 'User Management',
  Assessments = 'Assessments',
  CreditMetrics = 'Credit Metrics',
  RegentsMetrics = 'Regents Metrics',
  GraduationMetrics = 'Graduation Metrics',
  DistanceToGraduation = 'Distance to Graduation'
}

export enum MetricsRoute {
  Credits = 'Credits',
  Regents = 'Regents',
  GradPlanning = 'Grad Planning',
}

export const SettingsRoutesRelativeUrls = {
  'Credit Metrics': 'credits',
  'Regents Metrics': 'regents',
  Supports: 'supports',
  Activities: 'activities',
  'Master Program': 'master-program',
  Assessments: 'assessments',
  'User Management': 'user-management',
  Metrics: 'metrics/credits',
};
