import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, filter, find, map, reduce, sortBy } from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IBaseModalData } from '../../../shared/modals/modals.service';
import { getStudent } from '../../../store';
import { TBatchActionsOrigin } from '../../components/batch-actions-menu/batch-actions-menu.component';
import { ImUser } from '../../services/im-models/im-user';
import { IActivity, ILinkedCourse } from '../../typings/interfaces/activity.interface';
import { IACOption, IDropdownOption } from '../../typings/interfaces/design-library.interface';
import {
  IBulkUpdateStudentActivitiesParams,
  ICreateStudentActivityParams,
  IStudentActivity,
} from '../../typings/interfaces/student-activity.interface';
import { IUser } from '../../typings/interfaces/user.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IConfirmModalComponentData } from '../confirm/confirm-modal.component';
import { LoadActivities } from './../../../store/actions/activities-actions';
import {
  BulkUpdateStudentActivities,
  CreateStudentActivity,
  LoadStudentActivities,
  UpdateStudentActivity,
} from './../../../store/actions/student-activities-actions';
import { getActivitiesEntitiesList, getActivitiesLoadedStatus } from './../../../store/selectors/activities-selectors';
import {
  getStudentActivitiesEntitiesList,
  getStudentActivitiesLoadedStatus,
} from './../../../store/selectors/student-activities-selectors';
import { ImActivity } from './../../services/im-models/im-activity.service';
import { TValidStudentActivityStatus } from './../../typings/interfaces/student-activity.interface';
import { STUDENT_ACTIVITIES_MODAL_CONFIG } from './student-activities-modal.config';
import { StudentActivityModalHelpersService } from './student-activity-modal-helpers.service';

export interface IStudentActivitiesModalComponentData extends IBaseModalData {
  schoolId: string;
  currentSchoolYear: string;
  studentIds: string[];
  mode: string;
  isProfileMode: boolean;
  activity?: any;
  studentActivity?: IStudentActivity;
  currentUser: IUser;
  origin?: TBatchActionsOrigin;
}

@Component({
  selector: 'student-activities-modal',
  templateUrl: './student-activities-modal.component.html',
  styleUrls: ['./student-activities-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentActivitiesModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public formSubscription: Subscription;
  public startDateSubscription: Subscription;
  public endDateSubscription: Subscription;
  public form: FormGroup;
  public searchIcon = 'search';
  public hoursIcon = 'time';
  public emptyStateIcon = 'add';

  // school year props
  public schoolYear: string;
  public schoolYearOptions: IDropdownOption[];
  public selectedSchoolYear: string;

  // activity props
  public filteredActivityOptions: IACOption[];
  public activitySearch: string;
  public selectedActivity: IActivity = null;
  public selectedStudentActivity: IStudentActivity = null;

  // raw data
  public activities: IActivity[];
  public activitiesForSchoolYear: IActivity[];
  public studentActivities: IStudentActivity[];

  // placeholders
  public hoursPlaceholder: string = 'Enter completed hours';
  public emptyActivityText: string = 'No activities found';
  public activityPlaceholder: string = 'Find an activity...';
  public statusPlaceholder: string = 'Select a status';
  public startPlaceholder: string = 'Start';
  public endPlaceholder: string = 'End';

  // Modal Configurations
  readonly buttons = STUDENT_ACTIVITIES_MODAL_CONFIG.buttons;
  public itemCount: number;
  public itemType: string;
  public title: string;
  public mode: string;
  public confirmationButtonLabel: string;
  public isEditMode: boolean;
  public isBatchEditMode: boolean;
  public showActivitySearch: boolean;
  public canCreateActivities: boolean;
  public schoolId: string;
  public studentIds: string[];
  public count: number;
  public isUpdateMode: boolean;
  public titleTooltipInfo: string;

  // used to disable 'update' button if no change has been made to studentActivity (JE)
  public originalForm;

  // Confirmation Dropdown configurations
  public completionOptions: IDropdownOption[] = [
    {
      key: 'COMPLETED',
      human: 'Complete',
    },
    {
      key: 'INCOMPLETE',
      human: 'Incomplete',
    },
    {
      key: 'CLEAR',
      human: 'Clear completion status',
    },
  ];

  // Maps the key of the options to the  IDropdownOption object (AB)
  private readonly completeOptionsMap = new Map([
    [this.completionOptions[0].key, this.completionOptions[0].key],
    [this.completionOptions[1].key, this.completionOptions[1].key],
    [this.completionOptions[2].key, this.completionOptions[2].key],
  ]);

  public selectedOption: string = ''; // defaults dropdown to placeholder option
  public completeInfo: string;

  constructor (
    dialogRef: MatDialogRef<StudentActivitiesModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IStudentActivitiesModalComponentData,
    private store: Store<any>,
    private imUser: ImUser,
    private imActivity: ImActivity,
    private studentActivitiesModalHelpers: StudentActivityModalHelpersService,
  ) {
    super(dialogRef);
  }

  get disableSubmit (): boolean {
    if (!this.form.valid || !this.selectedActivity) return true;
    if (this.isEditMode) {
      return !this.form.dirty;
    }
    return false;
  }

  /* istanbul ignore next */
  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, studentIds, isProfileMode, schoolId, currentSchoolYear, activity, currentUser } = cloneDeep(
      this.data,
    );
    const selectedActivitySY = activity ? activity.terms.startTerm.schoolYear : null;
    this.schoolYear = selectedActivitySY || currentSchoolYear;
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.mode = mode;
    this.isEditMode = mode === 'UPDATE';
    this.isBatchEditMode = mode === 'BATCH_ASSIGN_UPDATE';
    this.canCreateActivities = this.imUser.isEditingUser(currentUser);
    this.showActivitySearch = !this.isEditMode;
    this.isProfileMode = isProfileMode;
    this.schoolId = schoolId;
    this.studentIds = studentIds;

    // subscribe to student activities
    this.store
      .select(getStudentActivitiesLoadedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          const payload = { schoolId: this.data.schoolId };
          this.store.dispatch(new LoadStudentActivities(payload));
        } else {
          this.store
            .select(getStudentActivitiesEntitiesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe(studentActivities => {
              this.studentActivities = studentActivities;
            });
        }
      });

    // subscribe to activities
    this.store
      .select(getActivitiesLoadedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          const payload = { schoolId: this.data.schoolId };
          this.store.dispatch(new LoadActivities(payload));
        } else {
          this.store
            .select(getActivitiesEntitiesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe(activities => {
              const activeActivities = filter(activities, (activity: IActivity) => {
                return activity.status !== 'DELETED';
              });
              this.schoolYearOptions = this._buildSchoolYearOptions(activeActivities);
              this.selectedSchoolYear = this.schoolYearOptions[1].key;
              let activityOptions;
              // in batch edit mode, all activities are available (JE)
              const len = this.studentIds.length;
              const showAllActivities = len > 1 || (len === 1 && (this.isEditMode || this.isBatchEditMode));
              if (showAllActivities) {
                activityOptions = activeActivities;
              } else {
                // remove acitivites student is already assigned to (JE)
                activityOptions = filter(activeActivities, (activity: IActivity) => {
                  const { _id } = activity;
                  const matchedStudentActivity = find(this.studentActivities, (studentActivity: IStudentActivity) => {
                    const {
                      activity: { activityId },
                      status,
                      studentId,
                    } = studentActivity;
                    // TODO: handle multiple student ids for bulk updates (JE)
                    const studentMatch = studentId === this.studentIds[0];
                    const idMatch = activityId === _id;
                    const isActive = status !== 'DELETED';
                    return idMatch && studentMatch && isActive;
                  });
                  return !matchedStudentActivity;
                });
              }

              // sort activities by start date
              const sortedActivities = sortBy(activityOptions, (activity: IActivity) => {
                return activity.startDate;
              });
              this.activities = sortedActivities;
              this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities(
                this.activitySearch,
                sortedActivities,
                this.selectedSchoolYear,
              );
              this.setActivity(this.data.activity);
              this.setStudentActivity(this.data.studentActivity, activities);
            });
        }
      });

    // update modal UI
    if (mode === 'CREATE') {
      this.title = 'Assign activity';
      this.confirmationButtonLabel = 'Assign';
    } else if (mode === 'UPDATE') {
      this.title = 'Update activity';
      this.isUpdateMode = true;
      this.confirmationButtonLabel = 'Update';
    } else if (mode === 'BATCH_ASSIGN_UPDATE') {
      this.title = 'Assign or update activity';
      this.titleTooltipInfo =
        'The activity will be assigned if a student does not currently have it in their list. Values entered in the optional fields, will apply to all selected students.';
      this.isUpdateMode = true;
      this.confirmationButtonLabel = 'Save';
    } else if (mode === 'REMOVE') {
      this.title = 'Remove activity';
      this.confirmationButtonLabel = 'Remove';
    }
    this.setActivity(activity);
    // build form
    if (!this.form) {
      const dateRange = new FormGroup({
        startDate: new FormControl(null, []),
        endDate: new FormControl(null, []),
      });
      this.form = this.formBuilder.group({
        dateRange: [dateRange, Validators.required],
        activity: [{ value: null, disabled: this.isEditMode }, Validators.required],
        completedHours: [null],
        status: [null],
      });
      this.setFormSubscriptions();
    }

    this.completeInfo = `Please provide the completed hours below if they have not already been entered for ${
      isProfileMode ? 'this' : 'each'
    } student.`;
  }

  ngOnDestroy () {
    this.formSubscription.unsubscribe();
    this.startDateSubscription.unsubscribe();
    this.endDateSubscription.unsubscribe();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public _buildSchoolYearOptions (activities: IActivity[]): IDropdownOption[] {
    const yearEndInt = parseInt(this.schoolYear.substring(this.schoolYear.length - 2));
    const currentYear = `SY${yearEndInt - 1}-${yearEndInt}`;
    const nextYear = `SY${yearEndInt}-${yearEndInt + 1}`;
    const optionsMap = reduce(
      activities,
      (res, activity) => {
        const { schoolYear } = activity.terms.startTerm;
        const parsedInt = parseInt(schoolYear.substring(schoolYear.length - 2));
        const year = `SY${parsedInt - 1}-${parsedInt}`;
        if (year !== currentYear && year !== nextYear && !res[year]) res[year] = 1;
        return res;
      },
      {},
    );
    const years = Object.keys(optionsMap)
      .sort()
      .reverse();
    return map([nextYear, currentYear, ...years], year => {
      return {
        key: year,
        human: year,
      };
    });
  }

  public setActivity (act: IActivity): void {
    if (act) {
      let dateRange;
      if (this.mode === 'BATCH_ASSIGN_UPDATE') {
        dateRange = new FormGroup({
          startDate: new FormControl(null, []),
          endDate: new FormControl(null, []),
        });
      } else {
        dateRange = new FormGroup({
          startDate: new FormControl(act.startDate, []),
          endDate: new FormControl(act.endDate, []),
        });
      }
      const name = this.imActivity.getActivityTitle(act);
      this.form = this.formBuilder.group({
        dateRange: [dateRange, Validators.required],
        activity: [name, Validators.required],
        completedHours: [null],
        status: [null],
      });
      this.setFormSubscriptions();
      this.selectedActivity = act;
      this.showActivitySearch = this.mode === 'CREATE' || this.mode === 'BATCH_ASSIGN_UPDATE';
      this.form.markAsDirty();
      const { status } = this.form.controls;
      this._configureCompletionOptions(status.value);
      const { hoursCountedInCourse } = act;
      let completeInfo;
      if (hoursCountedInCourse) {
        completeInfo =
          'The activity hours will not count as additional CDOS hours because the activity is included as part of the linked course(s) seat time.';
      } else {
        completeInfo = `Please provide the completed hours below if they have not already been entered for ${
          this.isProfileMode ? 'this' : 'each'
        } student.`;
      }
      this.completeInfo = completeInfo;
    }
  }

  public setStudentActivity (studentActivity: IStudentActivity, activities: IActivity[]): void {
    if (studentActivity) {
      const {
        activity: { activityId },
        startDate,
        endDate,
        status,
        completedHours,
      } = studentActivity;
      const matchedActivity = find(activities, (act: IActivity) => {
        return act._id === activityId;
      });
      const name = matchedActivity ? this.imActivity.getActivityTitle(matchedActivity) : null;
      const dateRange = new FormGroup({
        startDate: new FormControl(startDate, []),
        endDate: new FormControl(endDate, []),
      });
      this.form = this.formBuilder.group({
        activity: [{ value: name, disabled: this.isEditMode }, Validators.required],
        dateRange: [dateRange, Validators.required],
        status: [status],
        completedHours: [completedHours],
      });
      this.setFormSubscriptions();
      this.selectedActivity = matchedActivity;
      this.selectedStudentActivity = studentActivity;
      this.selectedOption = this.completeOptionsMap.get(status) || this.selectedOption;
      this._configureCompletionOptions(status);
    }
  }

  /* istanbul ignore next */
  public setFormSubscriptions (): void {
    this.originalForm = cloneDeep(this.form.value);
    this.formSubscription = this.form.valueChanges.subscribe(changes => {
      const { activity } = changes;
      // bubble handles for double-click or drag on input
      if (!!activity && !activity.bubbles) {
        // human activity comes from click selection in the nv-textbox (JE)
        const searchTerm = activity.human ? activity.human : activity;
        this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities(
          searchTerm,
          this.activities,
          this.selectedSchoolYear,
        );
      }
      for (const prop in changes) {
        if (changes[prop] === this.originalForm[prop]) this.form.get(prop).markAsPristine();
        else this.form.get(prop).markAsDirty();
      }
    });
    const { startDate, endDate } = this.form.controls.dateRange.value.controls;

    this.startDateSubscription = startDate.valueChanges.subscribe(val => {
      if (val && val !== this.originalForm.dateRange.controls.startDate.value) {
        this.form.markAsDirty();
        startDate.markAsDirty();
      } else {
        startDate.markAsPristine();
      }
    });
    this.endDateSubscription = endDate.valueChanges.subscribe(val => {
      if (val && val !== this.originalForm.dateRange.controls.endDate.value) {
        this.form.markAsDirty();
        endDate.markAsDirty();
      } else {
        endDate.markAsPristine();
      }
    });
  }

  public setActivityFromName (name): void {
    if (!name.bubbles) {
      this.form.controls.activity.setValue(name);
      this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities(
        name,
        this.activities,
        this.selectedSchoolYear,
      );
      const matchedActivity = find(this.activities, activity => {
        return this.imActivity.getActivityTitle(activity) === name;
      });
      if (matchedActivity) this.setActivity(matchedActivity);
    }
  }

  public clearSelection (inputType: string): void {
    if (inputType === 'activity') {
      this.form.controls.activity.setValue(null);
      this.form.controls.dateRange.value.controls.startDate.setValue(null);
      this.form.controls.dateRange.value.controls.endDate.setValue(null);
      this.form.controls.status.setValue(null);
      this.selectedActivity = null;
      this.filteredActivityOptions = this.studentActivitiesModalHelpers.activitiesToACOptions(this.activities);
      this.selectedOption = '';
    }
    if (inputType === 'completedHours') {
      this.form.controls.completedHours.setValue(null);
    }
  }

  public close (): void {
    super.close();
  }

  /* istanbul ignore next */
  public submit (form: FormGroup): void {
    if (this.disableSubmit) return;
    if (!this.selectedActivity._id) {
      this.selectedActivity = this._findMatchingActivity(this.activities);
    }

    // mode: create
    if (this.mode === 'CREATE') {
      const {
        dateRange: {
          value: {
            controls: { startDate, endDate },
          },
        },
        status,
        completedHours,
      } = form.controls;

      if (this.studentIds.length === 1) {
        // single student create
        const payload: ICreateStudentActivityParams = {
          studentId: this.studentIds[0],
          schoolId: this.schoolId,
          activityId: this.selectedActivity._id,
          startDate: startDate.value,
          endDate: endDate.value,
          status: status.value,
          completedHours: completedHours.value,
        };
        // dispatch action to create new studentActivity (JE)
        this.store.dispatch(new CreateStudentActivity({ studentActivityParams: payload }));
      }
    } else {
      // mode: edit
      const {
        dateRange: {
          value: {
            controls: { startDate, endDate },
          },
        },
        status,
        completedHours,
      } = form.controls;
      // single student update
      if (this.mode === 'UPDATE') {
        const patch: { startDate?: string; endDate?: string; status?: string; completedHours?: number } = {};
        if (startDate.dirty) patch.startDate = startDate.value;
        if (endDate.dirty) patch.endDate = endDate.value;
        if (status.dirty) patch.status = status.value;
        if (completedHours.dirty) patch.completedHours = completedHours.value;
        const payload = {
          studentActivityId: this.selectedStudentActivity._id,
          patch,
        };
        this.store.dispatch(new UpdateStudentActivity(payload));
      } else {
        // batch assign or update
        // dates are valid if both have a value, or if neither have a vulue (JE)
        const datesValid = (startDate.value && endDate.value) || (!startDate.value && !endDate.value);
        if (datesValid) {
          // multiple student activity update (JE)
          const patch: {
            startDate?: string;
            endDate?: string;
            completedHours?: number;
            status?: TValidStudentActivityStatus;
          } = {};
          if (startDate.value) patch.startDate = startDate.value;
          if (endDate.value) patch.endDate = endDate.value;
          if (status.value) patch.status = status.value;
          if (completedHours.value || completedHours.value === 0) patch.completedHours = completedHours.value;

          const payload: IBulkUpdateStudentActivitiesParams = {
            studentIds: this.studentIds,
            schoolId: this.schoolId,
            activityId: this.selectedActivity._id,
            patch,
            origin: this.data.origin,
          };
          this.store.dispatch(new BulkUpdateStudentActivities(payload));
        }
      }
    }
    super.close();
  }

  public clearActivity (): void {
    this.clearSelection('activity');
  }

  public clearHours (): void {
    this.clearSelection('completedHours');
  }

  public createActivity (): void {
    // payload tells parent container to open a new Activities modal (JE)
    const payload = {
      returnTo: 'StudentActivitiesModal',
    };
    super.close(payload);
  }

  public updateDropdown (optionKey: IDropdownOption['key']): void {
    this.selectedOption = this.completeOptionsMap.get(optionKey);
    switch (this.selectedOption) {
      case 'COMPLETED':
        this.form.controls.status.setValue('COMPLETED');
        break;
      case 'INCOMPLETE':
        this.form.controls.status.setValue('INCOMPLETE');
        break;
      case 'CLEAR':
        this.form.controls.status.setValue('CLEARED');
        break;
      default:
        this.form.controls.status.setValue(null);
        break;
    }
  }

  public updateSchoolYearDropdown (optionKey: IDropdownOption['key']): void {
    if (optionKey !== this.selectedSchoolYear) {
      this.clearActivity();
      this.selectedSchoolYear = optionKey;
      this.activitiesForSchoolYear = filter(this.activities, activity => {
        // todo: remove once format is standard;
        const { schoolYear } = activity.terms.startTerm;
        const yearEndInt = parseInt(schoolYear.substring(this.schoolYear.length - 2));
        const formatted = `SY${yearEndInt - 1}-${yearEndInt}`;
        const yearMatch = formatted === this.selectedSchoolYear;
        return activity.status !== 'DELETED' && yearMatch;
      });
      this.filteredActivityOptions = this.studentActivitiesModalHelpers.activitiesToACOptions(
        this.activitiesForSchoolYear,
      );
    }
  }

  public createStudentActivity (form: FormGroup): void {
    const {
      dateRange: {
        value: {
          controls: { startDate, endDate },
        },
      },
    } = form.controls;
    const { completedHours } = form.value;
    const hours = completedHours || 0;
    const { linkedCourses } = this.selectedActivity;
    // construct payload
    let studentActivityPayload: ICreateStudentActivityParams;
    if (this.studentIds.length === 1) {
      // single student mode
      studentActivityPayload = {
        studentId: this.studentIds[0],
        schoolId: this.schoolId,
        activityId: this.selectedActivity._id,
        startDate: startDate.value,
        endDate: endDate.value,
        completedHours: hours,
      };
      this._handleSingleStudentCreate(linkedCourses, studentActivityPayload);
    }
  }

  private _handleSingleStudentCreate (
    linkedCourses: ILinkedCourse[],
    studentActivityPayload: ICreateStudentActivityParams,
  ): void {
    // check if activity has linked course and student not enrolled in linked course (JE)
    if (linkedCourses && linkedCourses.length) {
      this._handleLinkedCourseActivity(linkedCourses, studentActivityPayload);
    } else {
      // activity has no linked courses
      this.store.dispatch(new CreateStudentActivity({ studentActivityParams: studentActivityPayload }));
      super.close();
    }
  }

  private _handleLinkedCourseActivity (
    linkedCourses: ILinkedCourse[],
    studentActivityPayload: ICreateStudentActivityParams,
  ): void {
    // get student from store
    this.store
      .select(getStudent)
      .pipe(takeUntil(this.destroy$))
      .subscribe(studentObj => {
        const _id = Object.keys(studentObj)[0];
        const {
          currProgram: { courses },
          studentDetails: {
            name: { first, last },
          },
        } = studentObj[_id];
        const studentCourseCodes = map(courses, course => course.code);
        const overlap = linkedCourses.filter(course => studentCourseCodes.includes(course.code));
        if (!overlap.length) {
          // student has no overlapping courses with linkedcourses
          // open warning modal
          const confirmModalData: IConfirmModalComponentData = {
            title: 'Enrollment in linked courses',
            message: `${first} ${last} is not enrolled in any courses that are linked to this activity. The student's hours earned in this activity will not apply towards CDOS, unless a CDOS or CTE course linked to this activity is completed within the same term.`,
            subMessage: 'Are you sure you want to assign the activity?',
            confirmText: 'Assign',
          };
          const payload = {
            confirmModalData,
            studentActivityPayload,
          };
          super.close(payload);
        } else {
          // student has linked course
          this.store.dispatch(new CreateStudentActivity({ studentActivityParams: studentActivityPayload }));
          super.close();
        }
      });
  }

  private _findMatchingActivity (activities: IActivity[]): IActivity {
    return find(activities, (activity: IActivity) => {
      const {
        startDate,
        endDate,
        hours,
        partnerOrg: { name, _id },
        type,
        sector,
      } = this.selectedActivity;
      const startMatch = activity.startDate === startDate;
      const endMatch = activity.endDate === endDate;
      const hoursMatch = activity.hours === hours;
      const nameMatch = activity.partnerOrg.name === name;
      const orgIdMatch = activity.partnerOrg._id === _id;
      const typeMatch = activity.type === type;
      const sectorMatch = activity.sector === sector;
      return startMatch && endMatch && hoursMatch && nameMatch && orgIdMatch && typeMatch && sectorMatch;
    });
  }

  private _configureCompletionOptions (status: string) {
    if (this.isProfileMode) {
      if (this.mode === 'CREATE' || status === null) this.completionOptions = this.completionOptions.slice(0, 2);
    }
  }
}
