import { ModalsService } from './../../../modals/modals.service';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, startWith, tap, withLatestFrom } from 'rxjs/operators';
import { getBatchActionsSelectedStudentIds } from 'Src/ng2/store';
import { IColumnStuLvl, IGroupData, IListConfig, IRowData, TList } from '../../../models/list-models';
import { BatchEditService } from '../../../services/batch-edit-service/batch-edit-service';
import { CellDisplayService } from '../../../services/list-services/cell-display.service';
import { HeaderService, IDisplayedHeader } from '../../../services/list-services/header.service';
import { SortAndFilterService, TSortDirections } from '../../../services/list-services/sort-and-filter.service';
import { MatCheckboxChange, MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { IHistoryModalData, THistoryLogsComponent } from './../../../modals/history-all/history-all-modal.component';

export interface IBatchActionTableHeaderIsChecked {
  headerName: string;
  isChecked: boolean;
  isIndeterminate: boolean;
  visibleRowData: IRowData[][];
  numRowsSelectedOfTotal: number;
}

export interface IRowDataWithBatchActions {
  [index: number]: IRowData[][];
  isChecked: boolean;
}

/* istanbul ignore next */
@Component({
  selector: 'fixed-table',
  templateUrl: './fixed-table.component.html',
  styleUrls: ['./fixed-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } }],
})
export class FixedTableComponent implements OnInit {
  // REQUIRED bindings if used along with FixedToInfiniteViewComponent
  @Input() schoolId: string;
  @Input() shelterId: string;
  @Input() madlibModel: any;
  @Input() groupIndx: number;
  @Input() groupData: IGroupData;
  @Input() columns: IColumnStuLvl[];
  @Input() maximumVisibleRowsPerGroup: number;
  @Input() filterFormControl: FormControl;
  @Input() batchActionsMode$: Observable<boolean>;
  @Input() sortKey$: BehaviorSubject<string>;
  @Input() sortDirection$: BehaviorSubject<TSortDirections>;
  @Input() hideHeader: boolean;
  @Input() currentFilterFormValue: string;
  @Input() columnIndexMap: { [key: string]: number };
  @Input() listConfig: IListConfig;
  @Output() sortColUpdate = new EventEmitter<string>();
  @Output() focusedGroup = new EventEmitter<{
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }>();

  @Output() clickedRowData = new EventEmitter<IRowData[]>();
  @Output() onDynamicComponentClick = new EventEmitter<any>();
  @Output() batchActionData = new EventEmitter();
  @Output() tableHeaderIsChecked = new EventEmitter<IBatchActionTableHeaderIsChecked>();
  @Output() uiRowData = new EventEmitter<IGroupData>();

  // OPTIONAL bindins
  @Input() batchActionsSelectedIds$: Observable<string[]> = null;
  @Input() dynamicComponentInputData: any;
  @Input() dynamicComponentTrigger: null | boolean;
  @Input() showRowsSelectedOfTotal: boolean = false;
  @Input() showFirstColumnHeader: boolean = false;
  @Output() dynamicComponentClicked = new EventEmitter<any>();

  // methods
  sortRowData: Function = SortAndFilterService.sortTableRowData;
  filterRows: Function = SortAndFilterService.filterRows;
  getDisplayedHeaders: Function = HeaderService.getDisplayedHeaders;
  getHeaderOverflow: Function = HeaderService.getHeaderOverflow;

  // all other props
  displayedHeaders: IDisplayedHeader[];
  tableRows$: BehaviorSubject<IRowData[]>;
  visibleDataSource$ = new BehaviorSubject<IRowData[][] | IRowDataWithBatchActions[]>([]);
  dataSource$ = new BehaviorSubject([]);
  hasSearchResult$ = new BehaviorSubject<boolean>(true);
  headerOverflow: boolean = false;
  sectionHeaderIsChecked: boolean;
  sectionHeaderIsInd: boolean;
  hideSectionHeaderCheckbox: boolean;
  displayGroupFooter: boolean = true;
  dataPipeline$: Observable<any>;
  noDataMessage: string;
  emptyTableMessage: string;
  sortableColumns: boolean = true;
  showAll: boolean = false;
  isBatchActionActivated: boolean;
  numRowsSelectedOfTotal: number;
  batchActions$: Observable<any>;
  useCircleCheckbox: boolean;
  rowDataToFormat;
  isOverrideActionClicked: boolean = false;

  constructor (private store: Store<any>, 
    private batchEditService: BatchEditService,
    private modalsService: ModalsService) {}

  ngOnInit (): void {
    const { showAll, rowData } = this.groupData;
    this.emptyTableMessage = this.listConfig.emptyTableMessage;
    this.noDataMessage = this.listConfig.noDataMessage;
    this.sortableColumns = this.listConfig.sortableColumns;
    this.hideSectionHeaderCheckbox = this.listConfig.hideSectionHeaderCheckbox;
    this.displayGroupFooter = this.listConfig.displayGroupFooter ?? this.displayGroupFooter;
    this.showAll = !!showAll;
    this.tableRows$ = new BehaviorSubject<any[]>(rowData);
    this.displayedHeaders = this.getDisplayedHeaders(this.columns, this.groupData, this.madlibModel?.value, this.showFirstColumnHeader);
    this.noDataMessage = this.listConfig.noDataMessage;
    this.useCircleCheckbox = this.listConfig.useCircleCheckbox;

    this.batchActions$ = combineLatest([
      this.batchActionsMode$,
      this.batchActionsSelectedIds$ || this.store.select(getBatchActionsSelectedStudentIds),
    ]).pipe(
      tap(([batchActionsMode, batchEditIds]) => {
        if (this.dataSource$.value.length) {
          this.setDataSourceWithBatchActions(batchActionsMode, batchEditIds, this.dataSource$.value);
        }
      }),
    );

    this.dataPipeline$ = combineLatest([
      this.tableRows$,
      this.filterFormControl.valueChanges.pipe(
        debounceTime(150),
        startWith(this.currentFilterFormValue),
      ),
      this.sortKey$,
      this.sortDirection$,
    ]).pipe(
      withLatestFrom(this.batchActions$),
      tap(([latestData, batchActions]) => {
        const [rowData, filterTerm, sortKey, sortDirection] = latestData;
        const [batchActionsMode, batchEditIds] = batchActions;
        const sortKeyIndex = this.columnIndexMap[sortKey];
        const { listType } = this.listConfig;
        const filteredRowData = this.filterRows({ filterTerm, listType, rowData });
        const sortedRowData = this.sortableColumns && this.sortRowData(filteredRowData, sortKeyIndex, sortDirection, sortKey);
        this.rowDataToFormat = sortedRowData || filteredRowData;

        const formattedRowData = CellDisplayService.getFormattedRowData({
          listType,
          rowDataToFormat: this.rowDataToFormat,
          columns: this.columns,
        });

        this.uiRowData.emit({ ...this.groupData, ...{ rowData: formattedRowData } });

        this.setDataSourceWithBatchActions(batchActionsMode, batchEditIds, formattedRowData);

        const noSearchMatches = filterTerm !== '' && this.visibleDataSource$.value.length === 0;
        const showSearchResults = !noSearchMatches;
        this.hasSearchResult$.next(showSearchResults);
      }),
    );
  }

  ngOnChanges (changes: SimpleChanges): void {
    // Allows fixed table component to re-render data if there has been any changes to rowData.
    // This case is for instances where fixed table is not being reinstantiated by a parent component on data updates (AB)
    if (changes.groupData && !changes.groupData.firstChange && this.tableRows$) {
      this.displayedHeaders = this.getDisplayedHeaders(this.columns, changes.groupData.currentValue, this.madlibModel?.value, this.showFirstColumnHeader);
      this.tableRows$.next(changes.groupData.currentValue.rowData);
    }
  }

  private setDataSourceWithBatchActions (batchActionsMode, batchEditIds, formattedRowData): void {
    // for keeping track of batchActionsMode$ without manual subscription across the component lifecycle
    this.isBatchActionActivated = batchActionsMode;
    if (!batchActionsMode) this.sectionHeaderIsChecked = false;
    const rowDataWithOptionalBatchActions = batchActionsMode
      ? this.getExistingBatchActionSelections(formattedRowData, batchEditIds)
      : formattedRowData;
    const visibleRows = this.showAll
      ? rowDataWithOptionalBatchActions
      : rowDataWithOptionalBatchActions.slice(0, this.maximumVisibleRowsPerGroup);
    this.dataSource$.next(rowDataWithOptionalBatchActions);
    this.visibleDataSource$.next(visibleRows);
  }

  emitUpdatedSortCol (col: string): void {
    if (this.sortableColumns) {
      this.sortColUpdate.emit(col);
    }
  }

  emitFocusedGroup (): void {
    this.focusedGroup.emit({
      groupData: this.groupData,
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      groupIndx: this.groupIndx,
    });
  }

  emitRowClick (rowData: IRowData[], dataColumn?): void {
    if (dataColumn && dataColumn.dynamic) return;

    if (this.isOverrideActionClicked) return;
    if (!this.isBatchActionActivated) {
      this.clickedRowData.emit(rowData);
    } else {
      this.emitBatchActionRowId(rowData);
    }
  }

  emitUiRowData (uiRowData: IGroupData): void {
    this.uiRowData.emit(uiRowData);
  }

  emitDynamicComponent (data: any): void {
    this.onDynamicComponentClick.emit(data);
  }

  getExistingBatchActionSelections (rowData: IRowData[][], batchEditIds: string[]): IRowDataWithBatchActions[] {
    const rowIdHash = this.batchEditService.getSelectedRowsHash(batchEditIds);
    const listType: TList = this.listConfig.listType;
    const { mappedData, count } = this.batchEditService.getRowDataWithBatchActions(rowIdHash, rowData, listType);
    this.sectionHeaderIsChecked = !!(rowData.length > 0 && rowData.length === count);
    this.sectionHeaderIsInd = !!(count && rowData.length > count);
    const headerName = this.displayedHeaders[0].human;
    this.numRowsSelectedOfTotal = mappedData.filter(({ isChecked }) => isChecked).length;
    this.tableHeaderIsChecked.emit({
      headerName,
      isChecked: this.sectionHeaderIsChecked,
      isIndeterminate: this.sectionHeaderIsInd,
      visibleRowData: rowData,
      numRowsSelectedOfTotal: this.numRowsSelectedOfTotal,
    });
    return mappedData;
  }

  emitBatchActionRowId (data: IRowData[], $event?: MatCheckboxChange): void {
    const batchData = {
      data,
      updateAll: false,
      level: 'ROW',
      $event,
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      section: this.groupIndx,
    };
    this.batchActionData.emit(batchData);
  }

  emitBatchActionSectionIds (): void {
    const data = this.dataSource$.value;
    const batchData = {
      data,
      updateAll: !this.sectionHeaderIsChecked,
      level: 'SECTION',
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      section: this.groupIndx,
    };
    this.batchActionData.emit(batchData);
  }

  checkHeaderOverflow (className: string): void {
    this.headerOverflow = this.getHeaderOverflow(className);
  }

  resetHeaderOverflow (): void {
    this.headerOverflow = false;
  }

  // List has staticRows set to true: grid edit column modal
  // List has staticRows undefined: attendance list, academic list, cluster user portfolio modal, etc
  trackByRowFn (index, rowData: IRowData[]): string {
    const staticRows = this.listConfig && this.listConfig.staticRows;
    const [stubColumn] = rowData;
    if (staticRows) {
      return stubColumn.data;
    } else {
      return this.getIdentifierForDynamicRows(rowData);
    }
  }

  trackByColumnFn (index, column: IRowData): string {
    return column.columnKey;
  }

  getIdentifierForDynamicRows (rowData) {
    const compoundIdentifier = `${JSON.stringify(rowData)} ${this.dynamicComponentTrigger}`;
    return compoundIdentifier;
  }

  private parseItem (inputData): string
  {
    let parsedData = '';
    if (inputData) {
      parsedData = inputData.replace(/['\']/g,'');
      parsedData = JSON.parse(parsedData);
    } 
    return parsedData;
  }

  public openModal (rowData: IRowData[]): void {
    this.isOverrideActionClicked = true;
    const studentData = rowData[0];
    const shelterId = this.shelterId;
    const schoolId = this.schoolId;
    let listType: THistoryLogsComponent;
    if (this.listConfig.listType) listType = this.listConfig.listType as THistoryLogsComponent;
    else listType = 'SHELTER_ATT_LIST';
    const modalData: IHistoryModalData = {
      studentData,
      shelterId,
      schoolId,
      listType,
    };

    this.modalsService
      .openHistoryModal(modalData)
      .afterClosed()
      .pipe(
        tap(() => {
            this.isOverrideActionClicked = false;
        })).subscribe();
  }
}
