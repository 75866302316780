import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvSegmentedControlComponent } from './nv-segmented-control.component';

@NgModule({
  imports: [CommonModule],
  declarations: [NvSegmentedControlComponent],
  exports: [NvSegmentedControlComponent],
})
export class NvSegmentedControlModule {}
