import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvTooltipModule } from '../../../ng2/shared/nv-tooltip/nv-tooltip.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvFormFieldComponent } from './nv-form-field.component';

@NgModule({
  imports: [CommonModule, NvTooltipModule, NvIconModule],
  declarations: [NvFormFieldComponent],
  exports: [NvFormFieldComponent],
})
export class NvFormFieldModule {}
