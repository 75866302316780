import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { each } from 'lodash';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map as rxjsMap, startWith, takeUntil, tap, debounceTime } from 'rxjs/operators';
import { TValidTimeInterval } from './nv-time-picker.interface';

@Component({
  selector: 'nv-time-picker',
  templateUrl: './nv-time-picker.component.html',
  styleUrls: ['./nv-time-picker.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvTimePickerComponent {
  @ViewChild('inputTime', { static: true }) inputTimeElement: ElementRef;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger, static: true }) inputTime: MatAutocompleteTrigger;

  readonly base = ['12', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

  /**
   *
   * Use a FormControl to keep track of the selected time.
   * Use Validators to control the input values, or mark a value as `required`
   * @required
   */
  @Input() control: FormControl;

  /**
   * The placeholder text when the picker is empty
   */
  @Input() placeholderText: string = 'Time';

  /**
   * The name of the left icon (as `nv-icon`)
   */
  @Input() icon: string = null;

  /**
   * Indicates whether form should have a clear button
   */
  @Input() hasClearBtn: boolean = false;

  /**
   * An optional start time for the options menu
   */
  @Input() rangeStartTime?: string = '8:00 AM';

  /**
   * An optional end time for the options menu
   */
  @Input() rangeEndTime?: string = '5:00 PM';

  /**
   * An optional interval. Defaults to 30
   */
  @Input() interval: TValidTimeInterval = 30;

  /**
   * Pass this option to trigger a menu reset.
   */
  @Input() resetRequested?: boolean;

  /**
   * Output the time selection
   */
  @Output() selectTime: EventEmitter<string> = new EventEmitter<string>();

  /**
   * Emit the selectedOption
   */
  @Output() selectedOption: EventEmitter<any> = new EventEmitter();

  /**
   * Emit the panel trigger so parent can handle closing any sibling time pickers
   */
  @Output() openPanel: EventEmitter<any> = new EventEmitter();

  am: string[];
  pm: string[];
  initialRange: string[];
  userDefinedRange: string[];
  filteredOptions: Observable<string[]>;
  showClearBtn: boolean;
  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor () {
    /** */
  }

  ngOnInit () {
    /**
     * passing true allows an error to be thrown if the ENGINEER provided inputs
     * are invalid (eg a menu start time that is AFTER a menu end time).
     * That's something we would want the engineer to see
     * when they use this component and are defining the range of options that a
     * end user could pick from.
     *
     * in other usages, passing nothing means that the method has been called in
     * response to USER input, and is handled accordingly.
     */
    const isRunOnInit = true;
    this._checkInputValidity(isRunOnInit);
    this.icon = 'time';
    this.am = this._setTimeIntervals('AM');
    this.pm = this._setTimeIntervals('PM');
    if (this.am && this.pm) {
      this.initialRange = [...this.am, ...this.pm];
    }
    const start = this.rangeStartTime.toUpperCase();
    const end = this.rangeEndTime.toUpperCase();
    this._setOptions(start, end);
    this._setValidators(this.control);
    this._getFiltered();
  }

  ngOnChanges (changesObj) {
    if (changesObj.rangeStartTime && changesObj.rangeStartTime.currentValue) {
      this.rangeStartTime = changesObj.rangeStartTime.currentValue;
    }
    /**
     * with two pickers, changing first triggers the reset request condition
     *
     */
    if (this.resetRequested) {
      /**
       * triggered when
       * Is time-range-picker implementation with optional resetRequest input
       * that is defined by the first picker and handled by the second.
       */
    } else if (changesObj.rangeStartTime && !changesObj.rangeStartTime.firstChange) {
      /**
       * triggered when
       * 1. Is single picker instance
       * 2. Is time range picker implementation where the second picker responds to the first
       */
      const isValid = this._checkInputValidity();
      if (isValid) {
        this.control.updateValueAndValidity();
      } else {
        // if input is invalid, means user picked a startTime in picker 1
        // that was AFTER the endtime.
        this.control.setValue('');
        this.control.setErrors(null);
      }
      this._setOptions(this.rangeStartTime, this.rangeEndTime);
      this._getFiltered();
    }
  }

  getValidity () {
    return this.control.invalid && typeof this.control.value === 'string';
  }

  _checkInputValidity (isFirstRun?) {
    const pattern = 'hh:mm a';
    const momentStart = moment(this.rangeStartTime, pattern);
    const momentEnd = moment(this.rangeEndTime, pattern);
    if (momentStart.isBefore(momentEnd)) {
      return true;
    } else {
      // TODO: When the user enters a startTime that is Later than the developer defined range, what is the
      // desired behavior?

      // if this method was run onInit, it would check the inputs defined by the eng
      // and should throw an error if they attempted to provide invalid times.
      // in all other cases, this check runs as a result of user defined value, in which case the
      // error isn't thrown, and is instead handled
      if (isFirstRun) {
        throw new Error(`
            You have entered a rangeStartTime: ${this.rangeStartTime} that is BEFORE your rangeEndTime: ${
          this.rangeEndTime
        } . Dropdown menu will fail to render. Check the component's inputs.
          `);
      } else {
        // honor user request. Set range to override latest possible endTime.
        this._setOptions(this.rangeStartTime, '11:30 PM');
        this._getFiltered();
      }
    }
  }

  _setTimeIntervals (period: 'AM' | 'PM') {
    let instances = 0;

    // uses interval to set a limit on how many strings will be created for an hour
    switch (this.interval) {
      case 10:
        instances = 6;
        break;
      case 15:
        instances = 4;
        break;
      case 20:
        instances = 3;
        break;
      case 30:
        instances = 2;
        break;
      default:
        instances = 2;
        return null;
    }

    const times = this.base.reduce((acc, num) => {
      const intervalsForHour = [];
      for (let i = 0; i < instances; i++) {
        const int = this.interval * i;
        let time = `${num}:${int.toString()}`;
        const topOfHour = time.endsWith(':0');
        if (topOfHour) time = `${time}0`;
        time = `${time} ${period}`;
        intervalsForHour.push(time);
      }
      return acc.concat(intervalsForHour);
    }, []);
    return times;
  }

  _validateTimeFormat (c: FormControl) {
    const hoursMatcher = /^(1[0-2]|0?[1-9])\s*(●?[AaPp][Mm]?)?\s?$/;
    const hoursMinutesMatcher = /^(1[0-2]|0?[1-9]):?[0-5][0-9]\s*(●?[AaPp][Mm]?)?\s?$/;
    const hoursMatcherA = /^(1[0-2]|0?[1-9])\s*(●?[AaPp])?\s?$/;
    return hoursMatcher.test(c.value) || hoursMatcherA.test(c.value) || hoursMinutesMatcher.test(c.value)
      ? null
      : {
        validateTime: {
          valid: false,
        },
      };
  }

  _setValidators (control: FormControl): void {
    control.setValidators([Validators.required, this._validateTimeFormat]);
    control.updateValueAndValidity();
  }

  _setOptions (start: string, end: string): void {
    /**
     * NOTE: the initial range, due to restrictions on the interval a
     * user can request, will always look like one of the following:
     *
     * 12:00, 12:10, 12:20, 12:30, 12:40, 12:50
     * 12:00, 12:15, 12:30, 12:45
     * 12:00, 12:20, 12:40
     * 12:00, 12:30
     *
     * in this world, a start time of "12:07" or ANY time that isnt found in the range
     * generated based on the interval would cause menu generation to fail.
     *
     * If we run into this scenario, the logic falls back to a default times. (JYR)
     */

    each(this.initialRange, (time: string, index: number) => {
      // set requested start time
      if (time === start) {
        this.userDefinedRange = this.initialRange.slice(index);
      }
      // set requested end time
      if (time === end) {
        const numToChop = this.initialRange.length - (index + 1);
        if (numToChop > this.userDefinedRange.length) {
          this.userDefinedRange.length = this.userDefinedRange.length - numToChop;
        }
      }
    });

    if (start === end || !this.userDefinedRange) {
      console.warn(`
      1. You supplied a start time (${this.rangeStartTime}) that does not match a time found in the menu 
      that was generated based on the interval you supplied (${this.interval}), 
      
      2. Your start and end times match, which is not permitted. 
      
      Falling back to default start and end times`);
      this._setOptions('8:00 AM', '5:00 PM');
    }
    // if none, allows values to appear from form
    // if (!this.control.value) this.control.setValue(null);
  }

  _filter (value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.userDefinedRange.filter(option => option.toLowerCase().includes(filterValue));
  }

  _onValueChange (textValue: string): void {
    if (!!textValue && textValue.length > 0) {
      this.showClearBtn = true;
    } else {
      this.showClearBtn = false;
    }
  }

  _getFiltered (): void {
    this.filteredOptions = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(100),
      distinctUntilChanged(),
      tap(value => this._onValueChange(value)),
      rxjsMap(value => this._filter(value || '')),
      takeUntil(this.destroy$),
    );
  }

  emitOption (option: any): void {
    this.selectedOption.emit(option.value);
    this.inputTime.closePanel();
    if (this.control.valid) this.control.markAsPristine();
  }

  clearSearch (): void {
    this.inputTimeElement.nativeElement.value = null;
    this.inputTimeElement.nativeElement.focus();
    this.control.setValue(null);
    this.control.updateValueAndValidity();
    this.control.markAsPristine();
    if (this.resetRequested) {
      this._setOptions('9:00 AM', '5:00 PM');
    }
    this._getFiltered();
  }

  emitPanelState (): void {
    this.openPanel.emit({ menuTrigger: this.inputTime });
  }

  validateAndEmit (): void {
    const { valid, value } = this.control;
    // if valid, we need to ensure that the value emitted from menu 1 is formatted correctly
    // so it can be used to determine options for menu 2
    if (valid) {
      // If user didn't specify a period, assume they meant AM and append.
      const patternToAppendAm = /^\d{1,2}[:]?(\d{1,2})?$/.test(value);
      // if receiving value with just 'a' or 'p'? need to add an m
      const patternToAppendM = /^\d{1,2}\s*?[:]?(\d{1,2})?[AaPp]$/.test(value);

      let formattedValue = value;
      if (patternToAppendAm) formattedValue = `${value} AM`;
      if (patternToAppendM) formattedValue = `${value}M`;

      formattedValue = formattedValue.toUpperCase();
      if (!formattedValue.includes(' ')) formattedValue = formattedValue.replace(/.{2}$/, ' $&');

      const hasColon = /:/.test(formattedValue);
      if (!hasColon) {
        if (formattedValue.length === 6) {
          const hours = formattedValue.slice(0, 1);
          const mins = formattedValue.slice(1, 3);
          const period = formattedValue.slice(4);
          formattedValue = `${hours}:${mins} ${period}`;
        } else if (formattedValue.length === 7) {
          const hours = formattedValue.slice(0, 2);
          const mins = formattedValue.slice(2, 4);
          const period = formattedValue.slice(5);
          formattedValue = `${hours}:${mins} ${period}`;
        } else if (formattedValue.length === 4) {
          const hours = formattedValue.slice(0, 1);
          const period = formattedValue.slice(2);
          formattedValue = `${hours}:00 ${period}`;
        } else if (formattedValue.length === 5) {
          const hours = formattedValue.slice(0, 2);
          const period = formattedValue.slice(3);
          formattedValue = `${hours}:00 ${period}`;
        } else {
          console.warn(`User has entered a start time of: ${formattedValue}, which has a pattern that is unhandled.`);
        }
      }
      if (this.control.value !== formattedValue) {
        this.selectedOption.emit(formattedValue);
      }
      this.control.setValue(`${formattedValue}`);
    }
  }
}
