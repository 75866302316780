import { Injectable } from '@angular/core';
import { UtilitiesService } from '../utilities/utilities.service';
import * as objectHash from 'object-hash';
@Injectable()
export class ImCachedObject {
  readonly OBJECT_HASH_OPTS = {
    algorithm: 'sha1',
    respectType: false,
  };

  constructor(
    private utilitiesService: UtilitiesService,
  ) { }

  createFromObject(obj) {
    obj = this.utilitiesService.toPOJO(obj);
    const hash = this.createHash(obj);
    return {
      _id: hash,
      json: JSON.stringify(obj),
    };
  }

  createHash(obj) {
    // we wrap this function to make it easier to test (CM).
    return objectHash(obj, this.OBJECT_HASH_OPTS);
  }
}
