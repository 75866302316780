import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

import { IPageHeaderMeta } from 'Src/ng2/shared/components/header/page-header/page-header-meta.interface';
import { IGroupData, IRowData } from './../../../../models/list-models';
import { Direction, SortAndFilterService, TSortDirections } from './../../../../services/list-services/sort-and-filter.service';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';

@Component({
  selector: 'user-management-content',
  templateUrl: './user-management-content.component.html',
  styleUrls: ['./user-management-content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserManagementContentComponent implements OnInit {
  @Input() groupData$: BehaviorSubject<IGroupData>;
  @Input() option$: BehaviorSubject<IDropdownOption[]>;
  @Input() optionSelected: string;
  @Input() batchActionsMode$: BehaviorSubject<boolean>;
  @Output() madLibChange = new EventEmitter();
  @Output() clickedMoreButton = new EventEmitter();
  @Output() onUserSelected = new EventEmitter<any>();

  public columnIndexMap: { [key: string]: number };
  public columns: any[];
  public listConfig: any;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public batchActionsSelectedIds$: BehaviorSubject<string[]>;
  selectedIds: string[] = [];
  public batchActionsHash: any;

  public pageHeaderMeta: IPageHeaderMeta = {
    title: 'User Management',
    subTitle: null,
    infoIcon: {
      tooltipData: 'This section allows you to create and modify user accounts at your school.',
    },
    textboxPlaceholder: 'Search by name or email',
  };

  public userFilter: FormControl = new FormControl();

  constructor () {
    //
  }

  ngOnInit (): void {
    this.sortKey$ = new BehaviorSubject('NAME');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(Direction.asc);
    this.columns = [
      {
        columnName: 'Name',
        columnOrder: 0,
        graphQlKey: 'NAME',
        cellTooltip: 'SCHOOL_USER_INFO',
      },
      {
        columnName: 'access level',
        columnDataFormat: '',
        columnOrder: 1,
        graphQlKey: 'SCHOOL_ACCESS_LEVEL',
        headerTooltip: {
          tableContent: {
            typeOfTable: 'stackedTable', // optional filed to apply stacked table css
            rowData: [
              ['Delegated Admin', 'Can edit all students, create Supports and manage users'],
              ['School Wide Editor', 'Can edit all students and create Supports'],
              ['School Wide Viewer', 'Can view all students and add Notes'],
              ['Caseload Editor', 'Can edit caseload students'],
              ['Caseload Viewer', 'Can view caseload students and add Notes'],
              ['No Access', 'Can’t log in'],
            ],
          },
        },
      },
      {
        columnName: 'job role',
        columnOrder: 2,
        graphQlKey: 'JOB_ROLE',
        headerTooltip: 'User job role as defined by admin or DOE source file',
      },
      {
        columnName: 'added on',
        columnOrder: 3,
        graphQlKey: 'ADDED_ON',
        cellTooltip: 'ADDED_BY',
      },
      {
        columnName: 'student caseload',
        columnOrder: 3,
        graphQlKey: 'STUDENT_CASELOAD',
        headerTooltip: 'Number of students assigned to caseload out of total active students. Relevant only to users with caseload access.',
      },
      {
        columnName: null,
        columnOrder: 4,
        graphQlKey: 'SCHOOL_MORE',
        columnDataFormat: 'SCHOOL_USER_LIST_MORE',
      },
    ];
    this.columnIndexMap = this.columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});

    this.listConfig = {
      listType: 'USER_MANAGEMENT',
      noDataMessage: 'No users to display',
      sortableColumns: true,
    };
  }

  public onMadLibChange (e: string): void {
    this.madLibChange.emit(e);
  }

  public onMoreButtonClick (data): void {
    this.clickedMoreButton.emit(data);
  }

  public updateSort (sortKey: string): void {
    SortAndFilterService.updateSortCol(sortKey, this.sortKey$, this.sortDirection$);
  }

  public formatAndFireBatchActionEvt ($event: { updateAll: boolean; data: IRowData[]; level: 'SECTION' | 'ROW' }): void {
    this.onUserSelected.emit($event);
  }
}
