import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IHistoryModalData } from '../../../modals/history-all/history-all-modal.component';
import { HistoryAllHelperService, IHistoryRecord } from 'Src/ng2/shared/modals/history-all/history-all-helpers.service';
import { HistoryAllHelperServiceShelter } from 'Src/ng2/shared/modals/history-all/history-all-helper-shelter.service';
import { IDocLog, TDocLogCategory } from 'Src/ng2/shared/typings/interfaces/docLogs.interface';

@Component({
  selector: 'category-tags-renderer',
  templateUrl: './category-tags-renderer.component.html',
  styleUrls: ['./category-tags-renderer.component.scss'],
})

export class CategoryTagsComponent implements ICellRendererAngularComp {
  protected params: ICellRendererParams;
  protected data: IHistoryModalData;
  public docLog: IDocLog;
  public historyLogDisplay: any;
  public categoryTagsArray: string[] = [];

  constructor (
        private historyAllHelperService: HistoryAllHelperService,
        private historyAllHelpersShelter: HistoryAllHelperServiceShelter,
  ) {}

  public agInit (params: ICellRendererParams): void {
    this.params = params;
    this.docLog = params.node.data;
    this.setLogDisplay(this.docLog);
    if (this.historyLogDisplay) this.setCategoryTags(this.docLog?.tags);
  }

  public refresh (params: ICellRendererParams): boolean {
    return false;
  }

  public setLogDisplay (docLog: IDocLog): void {
    if (!docLog) return;
    let historyRecord: IHistoryRecord;
    if (docLog.shelterId) {
      historyRecord = this.historyAllHelpersShelter.constructHistoryRecord(docLog);
    } else {
      historyRecord = this.historyAllHelperService.constructHistoryRecord(docLog);
    }
    this.historyLogDisplay = historyRecord?.modal ? historyRecord.modal : historyRecord?.expanded;
  }

  public setCategoryTags (tagArray: TDocLogCategory[] = []): void {
    if (!tagArray.length) return;
    const filteredTags: string[] = tagArray.filter((category) => category !== 'Notes')
      .sort();
    if (filteredTags.length) {
      const namedTags = filteredTags.slice(0, 3);
      if (filteredTags.length > 3) {
        const numUnnamed = filteredTags.length - 3;
        namedTags.push(`+${numUnnamed}`);
      }
      this.categoryTagsArray = namedTags;
    }
  }
}
