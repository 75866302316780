import { isEmpty } from 'lodash';
import { IUserMini } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseModalComponent } from '../base-modal.component';
import { TRUNCATED_FIN_AID_STATUSES } from '../../../student/common-panels/student-postsec/student-postsec.config';
import { Store } from '@ngrx/store';
import { IDropdownOption } from '../../typings/interfaces/design-library.interface';

export interface IPostsecFinAidModalComponentData { 
  eopFinancialEligibility: string;
  truncatedFafsaStatus: TRUNCATED_FIN_AID_STATUSES; 
  truncatedTapStatus: TRUNCATED_FIN_AID_STATUSES; 
  realTruncatedFafsaStatus: TRUNCATED_FIN_AID_STATUSES; 
  realTruncatedTapStatus: TRUNCATED_FIN_AID_STATUSES;
  currentUser: IUserMini;
}

const DASH = '—';

@Component({
  templateUrl: './postsec-financial-aid-modal.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./postsec-financial-aid-modal.component.scss']
})

export class PostsecFinancialAidModalComponent extends BaseModalComponent implements OnInit {
  // Modal Configurations
  public readonly title: string = 'Edit financial aid information';
  public readonly fafsaLabelTooltip = 'Overwrite the FAFSA status from the Higher Education Services Corps (HESC) report by marking the status as complete or waived.'
  public readonly tapLabelTooltip = 'Overwrite the TAP status from the Higher Education Services Corps (HESC) report by marking the status as complete or waived.'
  public readonly confirmationButtonLabel: string = 'Save';
  public readonly isProfileMode: boolean = true;

  // HESC
  public truncatedFafsaStatus: TRUNCATED_FIN_AID_STATUSES;
  public truncatedTapStatus: TRUNCATED_FIN_AID_STATUSES;
  public realTruncatedFafsaStatus: TRUNCATED_FIN_AID_STATUSES;
  public realTruncatedTapStatus: TRUNCATED_FIN_AID_STATUSES;
  public currentUser: IUserMini;

  // EOP
  public eopFinancialEligibilityOptions: IDropdownOption[];
  public selectedEop: string;

  constructor (
    dialogRef: MatDialogRef<PostsecFinancialAidModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPostsecFinAidModalComponentData,
    private store: Store<any>,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    const { eopFinancialEligibility, truncatedFafsaStatus, truncatedTapStatus, realTruncatedFafsaStatus, realTruncatedTapStatus, currentUser } = this.data;
    
    this.truncatedFafsaStatus = truncatedFafsaStatus || TRUNCATED_FIN_AID_STATUSES.STUB;
    this.realTruncatedFafsaStatus = realTruncatedFafsaStatus || TRUNCATED_FIN_AID_STATUSES.STUB;
    this.truncatedTapStatus = truncatedTapStatus || TRUNCATED_FIN_AID_STATUSES.STUB;
    this.realTruncatedTapStatus = realTruncatedTapStatus || TRUNCATED_FIN_AID_STATUSES.STUB;
    this.currentUser = currentUser;
    this.eopFinancialEligibilityOptions = [
      { key: DASH, human: DASH },
      { key: 'Eligible', human: 'Eligible' },
      { key: 'Not eligible', human: 'Not eligible' },
    ];
    this.selectedEop = eopFinancialEligibility || DASH;
  }

  public onEopOptionChange (option): void {
    this.selectedEop = option;
  }

  private formatHescPatch (fafsaPatchData, tapPatchData) {
    const patch = {} as any;

    if (fafsaPatchData) {
      patch.fafsaStatus = fafsaPatchData.status;
      patch.fafsaDateLastUpdatedAt = fafsaPatchData.dateLastUpdatedAt;
      patch.fafsaLastUpdatedBy = fafsaPatchData.lastUpdatedBy;
    }

    if (tapPatchData) {
      patch.tapStatus = tapPatchData.status;
      patch.tapDateLastUpdatedAt = tapPatchData.dateLastUpdatedAt;
      patch.tapLastUpdatedBy = tapPatchData.lastUpdatedBy;
    }

    return patch;
  }

  private formatEopPatch () {
    const { eopFinancialEligibility, currentUser } = this.data;

    const patch = {} as any;
    
    const hasEditedEopEligibility = (eopFinancialEligibility || DASH) !== this.selectedEop;
    if (hasEditedEopEligibility) {
      const checkedStatus = this.selectedEop === DASH ? null : this.selectedEop;
      patch.status = checkedStatus;
      patch.lastUpdatedBy = currentUser
    }

    return patch;
  }

  public save (getFafsaPatchData, getTapPatchData) {
    const fafsaPatchData = getFafsaPatchData();
    const tapPatchData = getTapPatchData();

    const hescPatch = this.formatHescPatch(fafsaPatchData, tapPatchData);
    const eopPatch = this.formatEopPatch();

    const patch = {
      ...(!isEmpty(hescPatch) && { hescEditable: hescPatch }),
      ...(!isEmpty(eopPatch) && { eopFinancialEligibility: eopPatch })
    }

    this.dialogRef.close(patch);
  }

  public cancel () {
    this.dialogRef.close();
  }
}