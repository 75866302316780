import { GraphQLExperiencesHelperService } from './shared/services/graphql-helpers/experiences/experiences-queries.service';
import { StudentSet } from './shared/services/student-set/student-set.service';
import { CourseDiffsAndGapPlansContainerModule } from './school/sdc/course-diffs-and-gap-plans/course-diffs-and-gap-plans-container.module';
import { ToggleService } from 'Src/ng2/shared/services/toggle/toggle.service';
import { GraphQLStudentMapGrowthHelperService } from './shared/services/graphql-helpers/student-map-growth/student-map-growth-queries.service';
import { GraphQLClusterHelperService } from './shared/services/graphql-helpers/clusters/cluster-queries.service';
import { GraphQLPartnerHelperService } from './shared/services/graphql-helpers/partners/partner-queries.service';
import { GraphQLSchoolUserHelperService } from './shared/services/graphql-helpers/user-management/school-user/school-user-queries.service';
import { GraphQlShelterClusterUserHelperService } from './shared/services/graphql-helpers/user-management/cluster-user/shelter-cluster-user-queries.service';
import { GraphQLSchoolClusterUserHelperService } from './shared/services/graphql-helpers/user-management/cluster-user/school-cluster-user-queries.service';
import { GraphQLStudentAssessmentsHelperService } from './shared/services/graphql-helpers/student-assessments/student-assessments-queries.service';
import { GraphQLSchoolAssessmentsHelperService } from './shared/services/graphql-helpers/school-assessments/school-assessments-queries.service';
import { GraphQLStudentActivitiesHelperService } from './shared/services/graphql-helpers/student-activities/student-activities-queries.service';
import { GraphQLActivitiesHelperService } from './shared/services/graphql-helpers/activities/activities-queries.service';
import { GraphQLDataLoadsHelperService } from './shared/services/graphql-helpers/data-loads/data-loads-queries.service';
import { ApiHelpers } from './shared/services/api-helpers';
import { PortalConfig } from './shared/services/portal-config';
import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { LocalStorageService } from 'Src/ng2/shared/services/web-storage/local-storage/local-storage.service';
import { DateHelpers } from 'Src/ng2/shared/services/date-helpers/date-helpers.service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { BooleanYesNoFilter } from './shared/services/boolean-yes-no-filter/boolean-yes-no-filter.service';
import { AgGridFilterService } from './shared/services/ag-grid-filter/ag-grid-filter.service';
import { rollbarFactory, RollbarService } from './shared/services/rollbar/rollbar.service';
import { SorterColumn } from './shared/services/sorter-column/sorter-column.service';
import { DefaultSdcConfigService } from './../app/sdc/sdc-state/sdc-configs/default-sdc-config.service';
import { RegentsPlanningConfigService } from './../app/sdc/sdc-state/sdc-configs/regents-planning-config.service';
import { RegentsPrepConfigService } from './../app/sdc/sdc-state/sdc-configs/regents-prep-config.service';
import { CreditGapsConfigService } from './../app/sdc/sdc-state/sdc-configs/credit-gaps-config.service';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { Angulartics2Module } from 'angulartics2';
import { GradPlanningConfigService } from 'Src/app/sdc/sdc-state/sdc-configs/grad-planning-config.service';
import { NvToastModule } from './../nvps-libraries/design/nv-toast/nv-toast.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/app-routing.module';
import { GridConfigService } from './school/grid/services/grid-config.service';
import { GridService } from './school/grid/services/grid.service';
import { Auth } from './shared/auth/auth.service';
import { NotificationsModule } from './shared/components/notifications/notifications.module';
import { SpinnerModalModule } from './shared/components/spinner/spinner-modal.module';
import { AssignSupportModalService } from './shared/modals/assign-support/assign-support-modal.service';
import { ErrorModalModule } from './shared/modals/error/error-modal.module';
import { ModalsService } from './shared/modals/modals.service';
import { HttpInterceptorModule } from './shared/services/auth/http-interceptor.module';
import { BackgroundJob } from './shared/services/background-job/background-job.service';
import { CustomErrorHandlerService } from './shared/services/custom-error-handler/custom-error-handler.service';
import { EventBus } from './shared/services/event-bus/event-bus';
import { NvPusherSocketIdInterceptor } from './shared/services/pusher/nv-pusher-interceptor.module';
import { SnackBarService } from './shared/services/snackbar/snackbar.service';
import { UserInactivity } from './shared/services/user-inactivity/user-inactivity.service';
import { effects, initialState, reducers } from './store';
import { LOAD_SCHOOL_SUCCESS } from './store/actions/school-actions';
import { LOAD_SHELTER_SUCCESS } from './store/actions/shelter-actions/shelter-actions';
import { CLEAR_STATE } from './store/actions/clear-state-actions';
import { GraphQlStudentPathsHelperService } from './shared/services/graphql-helpers/student-paths/student-paths-queries.service';
import { GraphQLStudentDocLogsHelperService } from './shared/services/graphql-helpers/student-docLogs/student-docLogs-queries.service';
import { GraphQLCsvDownloadsService } from './shared/services/graphql-helpers/csv-downloads/csv-downloads.queries.service';
import { GraphQLGridViewHelperService } from './shared/services/graphql-helpers/gridviews/gridviews-queries.service';
import { GraphQLNvConfigsHelperService } from './shared/services/graphql-helpers/nv-configs/nv-configs.queries.service';
import { MAT_TABS_CONFIG } from '@angular/material/tabs';

const initializeEventBus = EventBus => () => EventBus.initializeNgrxActionPipeline();
const handleAuthentication = Auth => () => Auth.handleAuthentication();
const initializeGoogleAnalyticsAccount = (PortalConfig: PortalConfig, WindowRef: WindowRef) => () => WindowRef.nativeWindow.ga('create', PortalConfig.publicConfig.GOOG_ANALTICS_ID, 'auto');

// MetaReducer that resets store ( EXCEPT TOGGLES ) to initialState when a new school is selected
/* istanbul ignore next */
function resetStoreState (reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === LOAD_SCHOOL_SUCCESS) {
      console.warn('New School Selected: Resetting NgRx Store');

      // currentUser resolves before school, so this keeps the currentUser
      // from being overwritten on LOAD_SCHOOL_SUCCESS (JE)
      const currentUser = state.PORTAL_STORE.currentUser;
      initialState.PORTAL_STORE.currentUser = currentUser;

      state = initialState;
    }
    if (action.type === CLEAR_STATE) {
      const newState = { ...initialState };
      return reducer({ state: newState }, action);
    }

    return reducer(state, action);
  };
}

function resetShelterStoreState (reducer: ActionReducer<any>): ActionReducer<any> {
  return (state, action) => {
    if (action.type === LOAD_SHELTER_SUCCESS) {
      console.warn('New Shelter Selected: Resetting NgRx Store');
      state.PORTAL_STORE.shelterGridData = initialState.PORTAL_STORE.shelterGridData;
    }
    return reducer(state, action);
  };
}

// tslint:disable-next-line
const metaReducers: MetaReducer<any>[] = [resetStoreState, resetShelterStoreState];

// tslint:disable-next-line:max-classes-per-file
@NgModule({
  imports: [
    // THIRD PARTY
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    HttpInterceptorModule.forRoot(),
    NgIdleModule.forRoot(),
    NvPusherSocketIdInterceptor.forRoot(),
    StoreModule.forRoot(
      { PORTAL_STORE: reducers },
      { initialState,
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ),
    // StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot(effects),
    // PORTAL MODULES
    NotificationsModule,
    SpinnerModalModule,
    AppRoutingModule,
    Angulartics2Module.forRoot(), // angulartics2 module should be registered after AppRoutingModule
    NvToastModule, // needed for store effect dependencies
    ErrorModalModule, // needed for pusher failures
    CourseDiffsAndGapPlansContainerModule,
  ],
  declarations: [AppComponent],
  providers: [
    { provide: APP_INITIALIZER, useFactory: initializeEventBus, deps: [EventBus], multi: true },
    { provide: APP_INITIALIZER, useFactory: handleAuthentication, deps: [Auth], multi: true },
    { provide: APP_INITIALIZER, useFactory: initializeGoogleAnalyticsAccount, deps: [PortalConfig, WindowRef], multi: true },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandlerService,
    },
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: MAT_TABS_CONFIG, useValue: { animationDuration: '0ms' } },
    UserInactivity,
    // After Route migration these dependencies need to be cleaned up
    GridConfigService,
    GridService,
    ModalsService,
    SnackBarService,
    BackgroundJob,
    MatSnackBar,
    AssignSupportModalService,
    // TEMPORARY: These should be removed when AngularJS wizards are removed for redesign or migrated to Angular
    GradPlanningConfigService,
    CreditGapsConfigService,
    RegentsPrepConfigService,
    RegentsPlanningConfigService,
    DefaultSdcConfigService,
    SorterColumn,
    AgGridFilterService,
    BooleanYesNoFilter,
    Auth,
    UtilitiesService,
    DateHelpers,
    LocalStorageService,
    WindowRef,
    PortalConfig,
    ApiHelpers,
    GraphQLDataLoadsHelperService,
    GraphQLActivitiesHelperService,
    GraphQLStudentActivitiesHelperService,
    GraphQLSchoolAssessmentsHelperService,
    GraphQLStudentAssessmentsHelperService,
    GraphQLSchoolClusterUserHelperService,
    GraphQlShelterClusterUserHelperService,
    GraphQLSchoolUserHelperService,
    GraphQLPartnerHelperService,
    GraphQLClusterHelperService,
    GraphQLStudentMapGrowthHelperService,
    ToggleService,
    StudentSet,
    GraphQlStudentPathsHelperService,
    GraphQLStudentDocLogsHelperService,
    GraphQLCsvDownloadsService,
    GraphQLNvConfigsHelperService,
    GraphQLGridViewHelperService,
    GraphQLExperiencesHelperService,
  ],
  bootstrap: [AppComponent],
})
/* istanbul ignore next */
export class AppModule { }
