import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { filter } from 'lodash';

import { Toggles } from '../../../../shared/constants/toggles.constant';
import { ToggleService } from '../../../../shared/services/toggle/toggle.service';
import { ImUser } from '../../../services/im-models/im-user';
import { ImSchool } from '../../../services/im-models/im-school';
import { ISchool } from '../../../typings/interfaces/school.interface';
import { IUser } from '../../../typings/interfaces/user.interface';
import { SettingsRoutesRelativeUrls } from './../../../constants/settings-routes.constant';
import { SettingsPageData } from './../settings-data-service/settings-data.service';
import { ISidebarItem } from './../../../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

const SETTINGS_ROUTES = {
  NYC: {
    ems: ['Supports', 'Assessments', 'User Management'],
    hs: ['Credit Metrics', 'Regents Metrics', 'Supports', 'Master Program', 'User Management'],
    hybrid: ['Credit Metrics', 'Regents Metrics', 'Graduation Metrics', 'Supports', 'Master Program', 'Assessments', 'User Management'],
    transferHS: ['Master Program', 'Supports', 'User Management'],
  },
  Schenectady: {
    ems: ['Supports', 'Assessments', 'User Management'],
    hs: ['Credit Metrics', 'Regents Metrics', 'Graduation Metrics', 'Supports', 'Activities', 'Master Program', 'User Management'],
    hybrid: ['Credit Metrics', 'Regents Metrics', 'Graduation Metrics', 'Supports', 'Activities', 'Master Program', 'Assessments', 'User Management'],
    transferHS: ['Distance to Graduation', 'Master Program', 'Supports', 'Activities', 'User Management'],
  },
  Uniondale: {
    ems: ['Supports', 'Assessments', 'User Management'],
    hs: ['Credit Metrics', 'Regents Metrics', 'Graduation Metrics', 'Supports', 'Activities', 'Master Program', 'User Management'],
    hybrid: ['Credit Metrics', 'Regents Metrics', 'Graduation Metrics', 'Supports', 'Activities', 'Master Program', 'Assessments', 'User Management'],
    transferHS: ['Distance to Graduation', 'Master Program', 'Supports', 'Activities', 'User Management'],
  },
  Lansing: {
    ems: ['Supports', 'User Management'],
    hs: ['Supports', 'User Management'],
    hybrid: ['Supports', 'User Management'],
    transferHS: ['Supports', 'User Management'],
  }
}

@Component({
  selector: 'settings-left-nav',
  templateUrl: './settings-left-nav.component.html',
  styleUrls: ['./settings-left-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsLeftNavComponent implements OnInit {
  currentUser: IUser;
  currentSchool: ISchool;
  private isHs: boolean;
  public isTransferSchool: boolean = false;
  private isHybridSchool: boolean;
  private isPbatSchool: boolean;
  private isSchoolWideEditor: boolean;
  private isCaseloadUser: boolean;
  private restrictedRoutes: string[] = ['Credit Metrics', 'Regents Metrics', 'Graduation Metrics', 'Master Program', 'User Management'];
  public routeOptions: ISidebarItem[];
  public showAssessments: boolean;
  private schoolId: string;
  public selectedSidebarItem: string;

  constructor (
    private imSchool: ImSchool,
    private imUser: ImUser,
    private toggleService: ToggleService,
    private activatedRoute: ActivatedRoute,
    private settingsDataService: SettingsPageData,
    private urlPathService: UrlPathService,
    private router: Router) {
    // empty block
  }

  ngOnInit () {
    this.settingsDataService.getCurrentUser$()
      .pipe(
        tap(currentUser => (this.currentUser = currentUser)),
        take(1),
      ).subscribe();

    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;
    this.showAssessments = this.toggleService.getToggleState(Toggles.TOGGLE_NYCT_MVP);
    this.isHs = this.imSchool.isHighSchool(this.currentSchool);
    this.isTransferSchool = this.imSchool.isTransferSchool(this.currentSchool);
    this.isHybridSchool = this.imSchool.isHybridSchool(this.currentSchool);
    this.isPbatSchool = this.imSchool.isPbatSchool(this.currentSchool);
    this.isSchoolWideEditor = this.imUser.isSchoolWideEditor(this.currentUser);
    this.isCaseloadUser = this.imUser.isCaseloadUser(this.currentUser);
    this.routeOptions = this.getRouteOptions(this.currentSchool);
    this.selectedSidebarItem = this.routeOptions[0].key;
  }

  /**
   * Will determine which routes to show based on the school type and the current user's role.
   */
  public getRouteOptions (school:ISchool): ISidebarItem[] {
    const { district } = school;
    const allRouteOptions = Object.keys(SettingsRoutesRelativeUrls);
    let validRoutes;

    // get route options based on school type (JE)
    if (this.isHybridSchool) validRoutes = SETTINGS_ROUTES[district].hybrid;
    else if (this.isTransferSchool) validRoutes = SETTINGS_ROUTES[district].transferHS; // order is important to check first if is is a transfer school
    else if (this.isHs) validRoutes = SETTINGS_ROUTES[district].hs;
    else validRoutes = SETTINGS_ROUTES[district].ems;

    // remove assessments route if toggle is not on (JE)
    if (!this.showAssessments) validRoutes = filter(validRoutes, route => route !== 'Assessments');

    // remove regents metrics route if PBAT school (AK)
    if (this.isPbatSchool) validRoutes = filter(validRoutes, route => route !== 'Regents Metrics');

    const routeOptions = allRouteOptions.reduce((routes, route) => {
      if (validRoutes.includes(route)) {
        // make sure option is a valid route for the school type
        if (this.isSchoolWideEditor || this.isCaseloadUser) {
          // make sure the option is valid for the user type
          if (!this.restrictedRoutes.includes(route)) routes.push(route);
        } else {
          routes.push(route);
        }
      }
      return routes;
    }, []);
    // Shape each route in the format of ISidebarItem
    const formattedRoutesOptions = routeOptions.map(routeOption => {
      return {
        key: routeOption,
        human: routeOption,
      };
    });
    return formattedRoutesOptions;
  }

  changeView (route: string): void {
    const url = this.urlPathService.computeDistrictUrlPath(`school/${this.currentSchool._id}/settings/${SettingsRoutesRelativeUrls[route]}`);
    const redirectRoute = [url];
    this.selectedSidebarItem = route;
    this.router.navigate(redirectRoute);
  }

  public goToMyStudents (): void {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/lists/tiles`);
    this.router.navigate([url]);
  }
}
