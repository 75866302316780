import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep, difference, intersection } from 'lodash';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { BaseModalComponent } from '../../../base-modal.component';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { TBatchActionsOrigin, BatchActionOriginOptions } from 'Src/ng2/shared/components/batch-actions-menu/batch-actions-menu.component';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { FREQUENCY_OPTIONS, STATUS_OPTIONS, SHELTER_SUCCESS_MENTOR_CONFIGS } from './shelter-success-mentor-shell.config';
import { BackgroundJobNotificationService } from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { FormValidatorsService } from 'Src/ng2/shared/services/form-validators/form-validators.service';
import { DateHelpers } from 'Src/ng2/shared/services/date-helpers/date-helpers.service';

export type TValidShelterSuccessMentorModes = 'ASSIGN' | 'MARK_COMPLETE' | 'DELETE' | 'EDIT';
export type TValidShelterSuccessMentorFrequency = 'DAILY' | 'WEEKLY' | 'BIWEEKLY' | 'TRIWEEKLY' | 'MONTHLY';
export type TValidShelterSuccessMentorStatus = 'IN_PROGRESS' | 'COMPLETE' | 'DELETED';
export interface IShelterSuccessMentorModalComponentData {
  caresIds?: string[];
  shelterId: string;
  origin?: TBatchActionsOrigin;
  mode: string;
  status?: TValidShelterSuccessMentorStatus;
  endDate?: string;
}

export interface IAssignSuccessMentorPayload {
  caresIds: string[];
  shelterId: string;
  mentor: string;
  frequency?: TValidShelterSuccessMentorFrequency;
  startDate: string;
}

export interface IUpdateSuccessMentorPayload {
  caresIds: string[];
  shelterId: string;
  status?: TValidShelterSuccessMentorStatus;
  endDate?: string;
  startDate?: string;
}

export interface ISuccessMentoringModalConfigs {
  title?: string;
  primaryBtnText?: string;
  profile?: boolean;
  buttonDisabled?: boolean;
  modalText?: any;
}

const MODAL_TEXT_OPTIONS =
{
  COMPLETE: 'be marked Complete',
  DELETE: 'deleted',
  NO_ACTION: 'Students you selected do not have a Success Mentor',
  SUCCESS_MENTORING_COMPLETE: 'mark Success Mentoring complete',
  DELETE_SUCCESS_MENTOR_RECORD: 'delete the current Success Mentor records',
  MARK_SUCCESS_MENTORING: 'mark Success Mentoring by',
  DELETE_AND_REMOVE_SUCCESS_MENTOR: 'delete this Success Mentor record and remove ',
  AS_COMPLETE: 'as complete',
  AS_SUCCESS_MENTOR: 'as a Success Mentor',
};

// @unsubscribeComponent
@Component({
  templateUrl: './shelter-success-mentor-shell.component.html',
  styleUrls: ['./shelter-success-mentor-shell.component.scss'],
})
export class ShelterSuccessMentorShellComponent extends BaseModalComponent implements OnInit {
  // COMMON MODAL PROPERTIES
  public studentsWithMentors: any[];
  public mode: TValidShelterSuccessMentorModes;
  public origin: TBatchActionsOrigin;
  public _id: string;
  public itemCount: number;
  public itemType: string = 'student';
  public needsToConfirm: boolean = false;
  public startLimit: string;

  // INITIAL CONFIG
  public modalConfig: ISuccessMentoringModalConfigs = {
    buttonDisabled: true,
    modalText: { confirmText: '' },
    profile: false,
  };

  // ASSIGN MODAL
  public isAssignButtonDisabled: boolean = false;
  public shelterSuccessMentorForm: FormGroup;
  public readonly frequencyOptions: Array<IDropdownOption> = FREQUENCY_OPTIONS;
  public readonly statusOptions: Array<IDropdownOption> = STATUS_OPTIONS;
  public currentFrequency: string;
  public currentStatus: string;
  readonly DATE_FORMAT: string = 'YYYY-MM-DD';
  public shelterSuccessMentorFormSubscription: Subscription;

  // SUCCESS MENTORING PANEL
  public studentName: string;
  public successMentorName: string;

  // MARK COMPLETE MODAL
  public firstMarkCompleteModal: boolean = true;
  public endDateFormControl: FormControl;
  public endDateFormControlSubscription: Subscription;

  // EDIT MODAL
  public editModalStartDate: string;
  public editModalStartDatePlaceHolder: string;
  public startDateFormControl: FormControl;
  public startDateFormControlSubscription: Subscription;

  // ORIGIN OF MODAL DATA
  public individualStudentUpdate: boolean;
  public batchActionsUpdate: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    dialogRef: MatDialogRef<ShelterSuccessMentorShellComponent>,
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    private formValidatorService: FormValidatorsService,
    private dateHelpers: DateHelpers,
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    this.mode = this.data.mode;
    this.itemCount = this.data.caresIds ? this.data.caresIds.length : 0;
    this.studentName = this.data.studentName;
    this.successMentorName = this.data.successMentorName;
    this.individualStudentUpdate = this.data.origin === BatchActionOriginOptions.PROFILE_PANEL_LIST;
    this.batchActionsUpdate = (this.data.origin === BatchActionOriginOptions.SHELTER_DATA_GRID || this.data.origin === BatchActionOriginOptions.LIST);

    // For date validation
    this.apiService
      .checkInProgressShelterSuccessMentor({ caresIds: this.data.caresIds, shelterId: this.data.shelterId })
      .pipe(
        tap((res: { caresIds: string, _id: string, startDate: string, endDate: string }[]) => {
          if (res) {
            const earliestMarkCompleteDate = res.map(successMentorRecord => moment(successMentorRecord.startDate));
            this.startLimit = moment.max(earliestMarkCompleteDate).toString();

            if (res.length === 1) {
              this.editModalStartDatePlaceHolder = moment(res[0].startDate).format('MMM D').toString();
              this.editModalStartDate = moment(res[0].startDate).format(this.DATE_FORMAT).toString();
            }
          }
        }),
      )
      .subscribe();

    switch (true) {
      case this.individualStudentUpdate:
        switch (this.mode) {
          case SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode:
            this._createSuccessMentorForm();
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.primaryBtnName,
              profile: true,
            };
            break;
          case SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode:
            this._createMarkSuccessMentorComplete();
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.primaryBtnName,
              profile: true,
              buttonDisabled: true,
              modalText: {
                profileText: MODAL_TEXT_OPTIONS.MARK_SUCCESS_MENTORING,
                followUpProfileText: MODAL_TEXT_OPTIONS.AS_COMPLETE,
              },
            };
            break;
          case SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode:
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.primaryBtnName,
              profile: true,
              buttonDisabled: false,
              modalText: {
                profileText: MODAL_TEXT_OPTIONS.DELETE_AND_REMOVE_SUCCESS_MENTOR,
                followUpProfileText: MODAL_TEXT_OPTIONS.AS_SUCCESS_MENTOR,
              },
            };
            break;
          case SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode:
            this._editSuccessMentorForm(this.editModalStartDate);
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.primaryBtnName,
              profile: true,
            };
            break;
        }
        break;
      case this.batchActionsUpdate:
        switch (this.mode) {
          case SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode:
            this._createSuccessMentorForm();
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.primaryBtnName,
            };
            break;
          case SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode:
            this._createMarkSuccessMentorComplete();
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.primaryBtnName,
              modalText: { batchActionsText: this._createUpdateBodyText() },
            };
            break;
          case SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode:
            this.modalConfig = {
              ...this.modalConfig,
              title: SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.title,
              primaryBtnText: SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.primaryBtnName,
              modalText: { batchActionsText: this._createUpdateBodyText() },
              buttonDisabled: false,
            };
            break;
        }
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    if (this.shelterSuccessMentorFormSubscription) this.shelterSuccessMentorFormSubscription.unsubscribe();
    if (this.endDateFormControlSubscription) this.endDateFormControlSubscription.unsubscribe();
    if (this.startDateFormControlSubscription) this.startDateFormControlSubscription.unsubscribe();
  }

  public onFrequencySelection(frequency: string): void {
    this.currentFrequency = frequency;
    this.shelterSuccessMentorForm.patchValue({ frequency });
  }

  public onCancel(): void {
    super.close();
  }

  public onAssign(): void {
    const payload = this._prepPayloadForApi();
    this._sendToApi({ payload });
  }

  public onConfirmFirstMarkCompleteModal () {
    this.needsToConfirm = true;
  }

  public onConfirmAssign(): void {
    const payload = this._prepPayloadForApi();
    if (this._isAllStudentsValidForUpdate(payload.caresIds, this.studentsWithMentors)) {
      // all students have in-progress mentors, close modal
      super.close();
    } else {
      const filteredDuplicatedIds = difference(payload.caresIds, this.studentsWithMentors);
      payload.caresIds = filteredDuplicatedIds;
      this._applySuccessMentor(payload);
    }
  }

  public onEdit(): void {
    const payload = this._prepPayloadForApi();
    this._sendToApi({ payload });
  }

  public onConfirmEdit(): void {
    const payload = this._prepPayloadForApi();
    if (this.studentsWithMentors.length === 0) {
      super.close();
    } else if (this._isAllStudentsValidForUpdate(payload.caresIds, this.studentsWithMentors)) {
      this._applySuccessMentor(payload);
    } else {
      const filteredValidIds = intersection(payload.caresIds, this.studentsWithMentors);
      payload.caresIds = filteredValidIds;
      this._applySuccessMentor(payload);
    }
  }

  public onClear(): void {
    this.shelterSuccessMentorForm.controls['mentorName'].patchValue('');
  }

  private _createSuccessMentorForm(): void {
    const todaysDate = this.dateHelpers.getFormattedNow(this.DATE_FORMAT);
    const validNameValidator = this.formValidatorService.peopleNameValidatorCtr();
    this.shelterSuccessMentorForm = this.formBuilder.group({
      mentorName: ['', Validators.compose([Validators.required, validNameValidator])],
      frequency: ['', Validators.compose([Validators.required])],
      startDate: todaysDate,
    });

    // Whatch for changes in the form
    this.shelterSuccessMentorFormSubscription = this.shelterSuccessMentorForm.valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
    ).subscribe(
      formValues => this._setAssignButtonDisabledStatus(formValues)
    );
  }

  private _editSuccessMentorForm(startData: string): void {
    this.startDateFormControl = new FormControl(startData);

    // Watch for changes in the date picker
    this.startDateFormControlSubscription = this.startDateFormControl.valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      // eslint-disable-next-line
    ).subscribe(() => this.modalConfig.buttonDisabled = false);
  }

  private _createMarkSuccessMentorComplete(): void {
    this.endDateFormControl = new FormControl();

    // Watch for changes in the date picker
    this.endDateFormControlSubscription = this.endDateFormControl.valueChanges.pipe(
      distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
      // eslint-disable-next-line
    ).subscribe(() => this.modalConfig.buttonDisabled = false);
  }

  private _prepPayloadForApi(): IAssignSuccessMentorPayload | IUpdateSuccessMentorPayload {
    let payload: IAssignSuccessMentorPayload | IUpdateSuccessMentorPayload;
    switch (this.mode) {
      case SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode:
        payload = this._createAssignSuccessMentorPayload();
        break;
      case SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode:
      case SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode:
      case SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode:
        payload = this._updateSuccessMentorPayload();
        break;
      default:
        break;
    }

    return payload;
  }

  private _createAssignSuccessMentorPayload(): IAssignSuccessMentorPayload {
    const { caresIds, shelterId } = cloneDeep(this.data);
    const { mentorName, frequency, startDate } = this.shelterSuccessMentorForm.value;
    const payload: IAssignSuccessMentorPayload = {
      caresIds,
      shelterId,
      mentor: mentorName,
      frequency,
      startDate,
    };

    return payload;
  }

  private _updateSuccessMentorPayload(): IUpdateSuccessMentorPayload {
    const { caresIds, shelterId } = cloneDeep(this.data);
    let payload: IUpdateSuccessMentorPayload;

    if (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode) {
      const endDate = this.endDateFormControl?.value;
      payload = {
        caresIds,
        shelterId,
        endDate,
        status: 'COMPLETE',
      };
    }

    if (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode) {
      payload = {
        caresIds,
        shelterId,
        status: 'DELETED',
      };
    }

    if (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode) {
      const startDate = this.startDateFormControl?.value;
      payload = {
        caresIds,
        shelterId,
        startDate,
        status: 'IN_PROGRESS',
      };
    }

    return payload;
  }

  private _sendToApi({ payload }): void {
    if (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.ASSIGN.mode) {
      this.apiService
        .checkInProgressShelterSuccessMentor(payload)
        .pipe(
          tap((res: string[]) => {
            if (res.length === 0) {
              // students don't have existing Success Mentors, assign mentor without warning
              this._applySuccessMentor(payload);
            } else {
              // students who have existing success,show warning and onConfirm will assign mentors to students without IN_PROGRESS mentor
              this.studentsWithMentors = res.map((s: any) => s.caresId);
              this.modalConfig.modalText.confirmText = this._createConfirmationTextForAssign(payload.caresIds, this.studentsWithMentors);
              this.needsToConfirm = true;
            }
          }),
        )
        .subscribe();
    }

    if (this.individualStudentUpdate) {
      if ((this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode) ||
        (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode) ||
        (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode)) {
        this.apiService
          .checkInProgressShelterSuccessMentor(payload)
          .pipe(
            tap((res: { caresIds: string, _id: string, startDate: string }[]) => {
              this.studentsWithMentors = res.map((s: any) => s.caresId);
              this._applySuccessMentor({ _id: res[0]._id, startDate: res[0].startDate, ...payload });
            }),
          )
          .subscribe();
      }
    }

    if (this.batchActionsUpdate) {
      if ((this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode) ||
        (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.DELETE.mode) ||
        (this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.EDIT.mode)) {
        this.apiService
          .checkInProgressShelterSuccessMentor(payload)
          .pipe(
            tap((res: { caresIds: string, _id: string, startDate: string }[]) => {
              if (res.length === 0) {
                // students DON'T have existing IN_PROGRESS success mentors
                // create warning message and onConfirmEdit will close the modal without any updates
                this.studentsWithMentors = [];
                this.modalConfig.modalText.confirmText = MODAL_TEXT_OPTIONS.NO_ACTION;
                this.needsToConfirm = true;
              } else {
                this.studentsWithMentors = res.map((s: any) => s.caresId);
                const isValid = this._isAllStudentsValidForUpdate(payload.caresIds, this.studentsWithMentors);

                // ALL students have IN_PROGRESS Success Mentors
                if (isValid) {
                  this._applySuccessMentor(payload);
                }
                else {
                  // Some students have existing Success Mentors, create warning message and onConfirmEdit will
                  // update the status to COMPLETE or DELETE only to students with IN_PROGRESS mentors
                  this.studentsWithMentors = res.map((s: any) => s.caresId);
                  this.modalConfig.modalText.confirmText = this._createConfirmationTextForUpdates();
                  this.needsToConfirm = true;
                }
              }
            }),
          )
          .subscribe();
      }
    }
  }

  private _applySuccessMentor(payload): void {
    this.apiService.mutateShelterSuccessMentor(payload, this.mode, this.data.origin).pipe(
      tap(res => {
        const backgroundjobType = Object.keys(res.data)[0];
        let backgroundJob;
        if (backgroundjobType === 'bulkCreateShelterSuccessMentor') backgroundJob = 'BulkShelterSuccessMentorCreate';
        if (backgroundjobType === 'bulkUpdateShelterSuccessMentor') backgroundJob = 'BulkShelterSuccessMentorUpdate';
        this.backgroundJobNotificationService.sendMessage({ backgroundJob });
        super.close(res);
      })
    ).subscribe();
  }

  private _createConfirmationTextForAssign(caresIds: string[], existingStudentsWithMentors: string[]) {
    const numOfStudentsSelected = caresIds.length;
    const isPlural = numOfStudentsSelected > 1;
    const isEqual = numOfStudentsSelected === existingStudentsWithMentors.length;
    const noApplyText = `be applied to the selected student${isPlural ? 's' : ''}`;
    const applySomeText = `apply to students that currently have a Success Mentor`;
    return `
      ${existingStudentsWithMentors.length} out of ${numOfStudentsSelected} student${isPlural ? 's' : ''} you selected already have a Success
      Mentor. This change will not ${isEqual ? noApplyText : applySomeText}.
    `;
  }

  private _createConfirmationTextForUpdates() {
    const completeOrDeleteText = this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode ?
      MODAL_TEXT_OPTIONS.COMPLETE
      : MODAL_TEXT_OPTIONS.DELETE;
    return `Only students that currently have a Success Mentor will be ${completeOrDeleteText}`;
  }

  private _createUpdateBodyText(): string {
    const typeOfUpdateText = this.mode === SHELTER_SUCCESS_MENTOR_CONFIGS.MARK_COMPLETE.mode
      ? MODAL_TEXT_OPTIONS.SUCCESS_MENTORING_COMPLETE
      : MODAL_TEXT_OPTIONS.DELETE_SUCCESS_MENTOR_RECORD;
    return `Are you sure you would like to ${typeOfUpdateText} for the selected students?`;
  }

  private _isAllStudentsValidForUpdate(caresIdsToUpdate: string[], existingStudentsWithMentor: string[]): boolean {
    return caresIdsToUpdate.length === existingStudentsWithMentor.length;
  }

  private _setAssignButtonDisabledStatus(formValues): void {
    const { mentorName, frequency, startDate } = formValues;
    const validationMentorError = this.shelterSuccessMentorForm.get('mentorName').errors;
    const allFieldsRequiredValid = mentorName && frequency && startDate;
    if (allFieldsRequiredValid && !validationMentorError?.invalid) {
      this.isAssignButtonDisabled = false;
    } else {
      this.isAssignButtonDisabled = true;
    }
  }
}