import { Auth } from './../../../shared/auth/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router, ActivatedRouteSnapshot } from '@angular/router';
import { PortalConfig } from 'Src/ng2/shared/services/portal-config';
import { Observable, of } from 'rxjs';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor (
    private router: Router,
    private auth: Auth,
    private portalConfig: PortalConfig,
    private apiService: ApiService,
  ) {}

  canActivate (route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.isMaintenanceMode(route).pipe(
      switchMap((modeOn) => {
        const isAuthenticated = this.auth.isAuthenticated();
        if (modeOn) return of(true);
        else if (isAuthenticated) return of(this.router.createUrlTree(['home']));
        else if (!this.portalConfig.publicConfig.IS_NYC_DISTRICT) {
          this.auth.loginWithRedirect();
          return of(false);
        } else return of(true);
      }),
    );
  }

  private isMaintenanceMode (route: ActivatedRouteSnapshot) {
    // DISPLAY_ENV_PATH is only set in deployed environments, therefore maintenance mode is never enabled locally
    if (this.portalConfig.publicConfig.DISPLAY_ENV_PATH) {
      return this.apiService.getApiStatus().pipe(
        map(() => false),
        catchError((err) => {
          route.data = { ...route.data, isMaintenanceMode: true, isMaintenanceModeError: err };
          return of(true);
        }),
      );
    }
    return of(false);
  }
}
