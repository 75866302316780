import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, filter, find, sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/batch-actions-menu/batch-actions-menu.component';
import { IActivity } from '../../../../../ng2/shared/typings/interfaces/activity.interface';
import { IACOption } from '../../../../../nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { ImActivity } from '../../../../shared/services/im-models/im-activity.service';
import {
  IBulkUpdateStudentActivitiesParams,
  IStudentActivity,
} from '../../../../shared/typings/interfaces/student-activity.interface';
import { IUser } from '../../../../shared/typings/interfaces/user.interface';
import {
  BulkUpdateStudentActivities,
  getActivitiesEntitiesList,
  getActivitiesLoadedStatus,
  getStudentActivitiesEntitiesList,
  getStudentActivitiesLoadedStatus,
  LoadActivities,
  LoadStudentActivities,
} from '../../../../store';
import { BaseModalComponent } from '../../base-modal.component';
import { IBaseModalData } from '../../modals.service';
import { REMOVE_STUDENT_ACTIVITY_MODAL_CONFIG } from '../student-activities-modal.config';
import { StudentActivityModalHelpersService } from '../student-activity-modal-helpers.service';

export interface IRemoveStudentActivitiesModalComponentData extends IBaseModalData {
  schoolId: string;
  studentIds: string[];
  currentUser: IUser;
  origin?: TBatchActionsOrigin;
}

@Component({
  selector: 'remove-student-activity-modal',
  templateUrl: './remove-student-activity-modal.component.html',
  styleUrls: ['./remove-student-activity-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RemoveStudentActivityModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup;
  public searchIcon = 'search';

  // activity props
  public filteredActivityOptions: IACOption[];
  public activitySearch: string;
  public selectedActivity: IActivity = null;

  // raw data
  public activities: IActivity[];
  public studentActivities: IStudentActivity[];

  // placeholders
  public emptyActivityText = 'No activities found';
  public activityPlaceholder = 'Find an activity...';

  // Modal Configurations
  readonly buttons = REMOVE_STUDENT_ACTIVITY_MODAL_CONFIG.buttons;
  public itemCount: number;
  public itemType: string;
  public title: string;
  public confirmationButtonLabel: string;
  public schoolId: string;
  public studentIds: string[];
  public currentUser: IUser;

  public activityName: string;
  public studentActivity: IStudentActivity;

  constructor (
    dialogRef: MatDialogRef<RemoveStudentActivityModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IRemoveStudentActivitiesModalComponentData,
    private store: Store<any>,
    private imActivity: ImActivity,
    private studentActivitiesModalHelpers: StudentActivityModalHelpersService,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { schoolId, studentIds, currentUser } = cloneDeep(this.data);
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.schoolId = schoolId;
    this.studentIds = studentIds;
    this.title = 'Remove activity';
    this.currentUser = currentUser;

    // build form
    this.form = this.buildForm();
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(changes => {
      const { activity } = changes;
      // bubble handles for double-click or drag on input
      if (!!activity && !activity.bubbles) {
        // human activity comes from click selection in the nv-textbox (JE)
        const searchTerm = activity.human ? activity.human : activity;
        this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities(
          searchTerm,
          this.activities,
        );
      }
    });

    // subscribe to student activities
    this.store
      .select(getStudentActivitiesLoadedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          const payload = { schoolId: this.data.schoolId };
          this.store.dispatch(new LoadStudentActivities(payload));
        } else {
          this.store
            .select(getStudentActivitiesEntitiesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe(studentActivities => {
              this.studentActivities = filter(
                studentActivities,
                studentActivity => studentActivity.status !== 'DELETED',
              );
            });
        }
      });

    // subscribe to activities
    this.store
      .select(getActivitiesLoadedStatus)
      .pipe(takeUntil(this.destroy$))
      .subscribe(loaded => {
        if (!loaded) {
          const payload = { schoolId: this.data.schoolId };
          this.store.dispatch(new LoadActivities(payload));
        } else {
          this.store
            .select(getActivitiesEntitiesList)
            .pipe(takeUntil(this.destroy$))
            .subscribe(activities => {
              const activeActivities = filter(activities, (activity: IActivity) => {
                return activity.status !== 'DELETED';
              });
              // sort activities by start date
              const sortedActivities = sortBy(activeActivities, (activity: IActivity) => {
                return activity.startDate;
              });
              this.activities = sortedActivities;
              this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities(
                this.activitySearch,
                sortedActivities,
              );
            });
        }
      });
  }

  ngOnDestroy () {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public buildForm (): FormGroup {
    return this.formBuilder.group({
      activity: [null, Validators.required],
    });
  }

  public generateActivityName (studentActivity: IStudentActivity): string {
    return this.imActivity.getActivityTitle(studentActivity.activity);
  }

  public close (): void {
    super.close();
  }

  public submit (form: FormGroup): void {
    if (form.valid) {
      // filter out students who don't have this activity
      const relevantStudentIds = filter(this.studentIds, id => {
        const studentActivity = find(this.studentActivities, studentActivity => {
          const {
            studentId,
            activity: { activityId },
          } = studentActivity;
          return studentId === id && activityId === this.selectedActivity._id;
        });
        return !!studentActivity;
      });
      const payload: IBulkUpdateStudentActivitiesParams = {
        studentIds: relevantStudentIds,
        schoolId: this.schoolId,
        activityId: this.selectedActivity._id,
        patch: {
          status: 'DELETED',
        },
        origin: this.data.origin,
      };
      // dispatch action to update existing studentActivity (JE)
      if (relevantStudentIds.length) this.store.dispatch(new BulkUpdateStudentActivities(payload));
    }
    super.close();
  }

  public setActivityFromName (name): void {
    if (!name.bubbles) {
      this.form.controls.activity.setValue(name);
      this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities(name, this.activities);
      const matchedActivity = find(this.activities, activity => {
        return this.imActivity.getActivityTitle(activity) === name;
      });
      if (matchedActivity) this.setActivity(matchedActivity);
    }
  }

  public setActivity (act: IActivity): void {
    if (act) {
      const name = this.imActivity.getActivityTitle(act);
      this.form = this.formBuilder.group({
        activity: [name, Validators.required],
      });
      this.selectedActivity = act;
      this.form.markAsDirty();
    } else {
      this.form.controls.activity.setValue(null);
      this.activitySearch = '';
      this.filteredActivityOptions = this.studentActivitiesModalHelpers._filterActivities('', this.activities);
    }
  }
}
