import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';

import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

@Component({
  selector: 'supports-settings',
  templateUrl: './supports-settings.component.html',
  styleUrls: ['./supports-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class SupportsSettingsComponent implements OnInit, OnDestroy {
  public batchActionsMode$: Observable<any>;
  public currentSchool: any;

  constructor (
    private activatedRoute: ActivatedRoute,
  ) {
    document.title = 'Settings - Supports | Portal';
  }

  ngOnInit (): void {
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;
    this.batchActionsMode$ = of(false);
  }

  ngOnDestroy ():void {
    document.title = 'Portal by New Visions';
  }
}
