import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { ImActivity } from 'Src/ng2/shared/services/im-models/im-activity.service';
import { IStudentActivity } from 'Src/ng2/shared/typings/interfaces/student-activity.interface';
import { UpdateStudentActivity } from 'Src/ng2/store';
import { BaseModalComponent } from '../../base-modal.component';
import { IBaseModalData } from '../../modals.service';
import { STUDENT_ACTIVITY_STATUS_MODAL_CONFIG } from '../student-activities-modal.config';

enum MODAL_MODES {
  MARK_COMPLETE = 'MARK_COMPLETE',
  UNASSIGN = 'UNASSIGN',
}

export interface IStudentActivityStatusModalComponentData extends IBaseModalData {
  mode: string;
  isProfileMode: boolean;
  studentActivity?: IStudentActivity;
  studentFullName?: string;
}

@Component({
  selector: 'student-activity-status-modal',
  templateUrl: './student-activity-status-modal.component.html',
  styleUrls: ['./student-activity-status-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentActivityStatusModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public form: FormGroup;
  public searchIcon = 'search';
  public hoursIcon = 'time';

  // Modal Configurations
  readonly buttons = STUDENT_ACTIVITY_STATUS_MODAL_CONFIG.buttons;
  public itemCount: number;
  public itemType: string;
  public title: string;
  public confirmationButtonLabel: string;
  public isEditMode: boolean;
  public isUnassignMode: boolean;
  public schoolId: string;
  public studentIds: string[];
  public mode: string;

  public activityName: string;
  public activityHours: number;
  public studentActivity: IStudentActivity;
  public studentFullName: string;

  constructor (
    dialogRef: MatDialogRef<StudentActivityStatusModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IStudentActivityStatusModalComponentData,
    private store: Store<any>,
    private imActivity: ImActivity,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, studentIds, isProfileMode, studentActivity, studentFullName } = cloneDeep(this.data);
    this.itemCount = 1;
    this.itemType = 'student';
    this.mode = mode;
    this.isEditMode = mode === MODAL_MODES.MARK_COMPLETE;
    this.isUnassignMode = mode === MODAL_MODES.UNASSIGN;
    this.isProfileMode = isProfileMode;
    this.schoolId = studentActivity.schoolId;
    this.studentIds = studentIds;
    this.studentActivity = studentActivity;
    this.studentFullName = studentFullName;

    this.activityName = this.generateActivityName(studentActivity);
    this.activityHours = studentActivity.activity.hours;

    // update modal UI
    if (mode === MODAL_MODES.MARK_COMPLETE) {
      this.title = 'Mark complete';
      this.confirmationButtonLabel = 'Complete';
    } else if (mode === MODAL_MODES.UNASSIGN) {
      this.title = 'Remove activity';
      this.confirmationButtonLabel = 'Remove';
    }
    // build form
    this.form = this.buildForm();
  }

  public buildForm (): FormGroup {
    return this.formBuilder.group({
      completedHours: [this.studentActivity.completedHours],
    });
  }

  public generateActivityName (studentActivity: IStudentActivity): string {
    return this.imActivity.getActivityTitle(studentActivity.activity);
  }

  public close (): void {
    super.close();
  }

  public submit (form: FormGroup): void {
    let status;
    /* istanbul ignore next */
    if (this.mode === MODAL_MODES.MARK_COMPLETE) status = 'COMPLETED';
    const patch: { status: string; completedHours?: number } = {
      status,
    };
    const {
      completedHours: { value },
    } = form.controls;
    /* istanbul ignore next */
    if (value !== this.studentActivity.completedHours) patch.completedHours = value;
    const payload = {
      studentActivityId: this.data.studentActivity._id,
      patch,
    };

    // dispatch action to update existing studentActivity (JE)
    this.store.dispatch(new UpdateStudentActivity(payload));
    super.close(payload);
  }

  public clearHours (): void {
    this.form.controls.completedHours.setValue(null);
  }

  private unassignStudentActivity (): void {
    const patch = {
      status: 'DELETED',
    };
    const payload = {
      studentActivityId: this.studentActivity._id,
      patch,
    };

    // dispatch action to update (remove) existing studentActivity
    this.store.dispatch(new UpdateStudentActivity(payload));
    super.close(payload);
  }

  // delegate confirm action by mode
  public onConfirm (form): void {
    if (this.mode === MODAL_MODES.MARK_COMPLETE) {
      this.submit(form);
    } else if (this.mode === MODAL_MODES.UNASSIGN) {
      this.unassignStudentActivity();
    }
  }
}
