import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BaseModalModule } from '../base-modal.module';
import { StudentReportModalComponent } from './student-report.component';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  declarations: [StudentReportModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    FormsModule,
    MatDialogModule,
    BaseModalModule,
    NvFormFieldModule,
    NvDropdownModule,
    NvNoteModule,
    NvButtonModule,
  ],
  exports: [StudentReportModalComponent],
  providers: [],
})
export class StudentReportModalModule {}
