import { Inject, Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as Rollbar from 'rollbar';
import { TValidSdcColumns } from 'Src/app/sdc/sdc-columns.type';
import { SorterColumnDataType } from '../../../../ng2/shared/constants/sorter-column-data-type.constant';
import { SorterColumnNumberType } from '../../../../ng2/shared/constants/sorter-column-number-type.constant';
import { ISorterColumn } from '../../../../ng2/shared/services/sorter-column/sorter-column.service';
import { IFetchDataFromEndpointRes, IProjection, StudentFetchService } from '../../../../ng2/shared/services/student-fetch/student-fetch.service';
import { ISchool } from '../../../../ng2/shared/typings/interfaces/school.interface';
import { IStudent } from '../../../../ng2/shared/typings/interfaces/student.interface';
import { IUserMini } from '../../../../ng2/shared/typings/interfaces/user.interface';
import { CellRenderer } from './../../../../app/shared/services/sorter/ag-grid/cell-renderer.service';
import { SorterColumn } from './../../../../ng2/shared/services/sorter-column/sorter-column.service';
import { AgGridFilterService } from './../ag-grid-filter/ag-grid-filter.service';
import { BooleanYesNoFilter } from './../boolean-yes-no-filter/boolean-yes-no-filter.service';
import { RollbarService } from './../rollbar/rollbar.service';
import { UtilitiesService } from './../utilities/utilities.service';

export interface IFetchStudentParams {
  schoolId: string;
  columnKeys?: TValidSdcColumns[];
  projection?: string[];
  joins?: any;
  loadedProjection?: { [key: string]: any };
  loadedJoins?: string[];
  bundleName?: any;
  forceDataRefetch?: boolean;
  includeStudentTypeFilter?: boolean;
  isEms?: boolean;
  whereFilter?: object | null;
}

@Injectable()
export class StudentSet {
  constructor (
    private SorterColumn: SorterColumn,
    private UtilitiesService: UtilitiesService,
    private studentFetchService: StudentFetchService,
    private AgGridFilterService: AgGridFilterService,
    @Inject(RollbarService) private Rollbar: Rollbar,
  ) {}

  /**
   * Fetch students from the API
   *
   * @param {boolean} rawPojo true if you want POJOs returned, not Student objects
   * @param {object} loadedProjection the projection that is already loaded (will be excluded from the fetch)
   * @param {array} loadedJoins the joins that are already loaded (will be excluded from fetch)
   * @returns {Promise} resolved with an object like `{ students, projection }` where students is an array of
   *   partial students just fetched, and projection is the projection that was fetched
   *   @note This promise is rejected with err.status of -1 if the fetch request
   *   is cancelled due to another `fetch`
   *   or `fetchColumns` call.
   */
  fetchStudents<T> ({
    schoolId,
    columnKeys,
    joins,
    loadedProjection,
    loadedJoins,
    bundleName,
    forceDataRefetch = false,
    includeStudentTypeFilter = true,
    isEms = false,
    whereFilter = null,
  }: IFetchStudentParams): Promise<{ noNewData: boolean } | { students: T[], projection: IProjection, joins: string[]} > {
    // Calculate the difference between the loadedProjection (if it exists) and the
    // sum of requested column/ path projections
    let diffProjection;
    const columnProjection = this.getProjectionForColumnKeys(columnKeys);

    if (_.size(loadedProjection)) {
      diffProjection = this.UtilitiesService.diffProjection(loadedProjection, columnProjection);
    } else {
      diffProjection = columnProjection;
    }

    // Calculate the difference between the loadedJoins (if it exists) and the
    // sum of requested column/ parameter joins
    let diffJoins;
    const columnJoins = this._getJoinsForColumnKeys(columnKeys);
    const fullJoins = _.union(columnJoins, joins);

    if (loadedJoins && loadedJoins.length > 0) {
      diffJoins = _.union(fullJoins, loadedJoins);
    } else {
      diffJoins = fullJoins;
    }

    diffJoins = this.validJoinsForJoinsProjection(diffJoins, diffProjection);

    // Always include at least the _id in the projection
    diffProjection._id = true;

    const skipProjectionReload = _.isEqual({ _id: true }, diffProjection);
    const skipJoinReload = diffJoins.length === 0;

    if (skipProjectionReload && skipJoinReload && !forceDataRefetch) {
      // Don't need any new data, so just return
      return Promise.resolve({ noNewData: true });
    } else {
      // fetch student data
      return this.studentFetchService
        .fetchStudentData<T>({
          schoolId,
          bundleName,
          projection: diffProjection,
          fullProjection: columnProjection, // columnProjection projection is needed to log bundle mismatched (CM)
          joins: diffJoins,
          includeStudentTypeFilter,
          isEms,
          whereFilter,
        })
        .then((data: IFetchDataFromEndpointRes<T>['data']) => {
          return {
            students: data,
            projection: diffProjection,
            joins: diffJoins,
          };
        });
    }
  }

  /**
   * Returns the set of flattened students
   *
   * @returns {Array} An array of flattened students like this:
   *    [
   *       {
   *          _id: "1234513W320
   *          studentId: '12345',
   *          studentName: 'John Smith'
   *       }
   *       // .. other students ..
   *    ]
   *
   *    The keys of these students are sorter column keys, and the values are the projected MongoDB values.
   *
   */
  flattenStudents (students: IStudent[], school: ISchool, columnKeys: TValidSdcColumns[]) {
    const flattenedStudents = _.map(students, student => this._flattenStudent(student, school, columnKeys));
    return flattenedStudents;
  }

  filterStudents (students, flattenedStudents, filter) {
    // Array < Student >, Array <FlattenedStudent>, {}
    // returns an object w/ `flattenedStudents` & `students` keys

    const filteredStudents = {
      flattenedStudents: [],
      students: [],
    };

    const filterFunctions = this.AgGridFilterService.makeFilterFunctionsFromFilterSet(filter);

    if (!filter) {
      filteredStudents.flattenedStudents = flattenedStudents;
      filteredStudents.students = students;
    } else {
      // filter flattenedStudents
      _.each(flattenedStudents, (flattenedStudent, idx) => {
        const doesPassFilters = _.every(filterFunctions, (func: Function) => func(flattenedStudent));
        if (doesPassFilters) {
          // push flattened
          filteredStudents.flattenedStudents.push(flattenedStudent);
          // push unflattened
          const unflattenedStudent = students[idx];
          filteredStudents.students.push(unflattenedStudent);
        }
      });
    }
    return filteredStudents;
  }

  sortFlattenedStudents (students, sort) {
    if (!_.isUndefined(sort)) {
      const colIds = [];
      const sorts = [];

      _.each(sort, sortCol => {
        colIds.push(sortCol.colId);
        sorts.push(sortCol.sort);
      });
      students = _.orderBy(students, colIds, sorts);
    }
    return students;
  }

  getColumnDefs (columnKeys: string[], userMinis?: IUserMini[]) {
    const virtualColumnDef = {
      gridRowSelector: {
        colId: 'gridRowSelector',
        headerName: null,
        checkboxSelection: true,
        field: null,
        virtualField: 'gridRowSelector',
        pinned: 'left',
        width: 50,
        cellClass: 'center',
        suppressMenu: true,
      },
    };

    const colUnion = _.union(columnKeys, ['studentId', 'studentName']);

    const ret = _.map(columnKeys || colUnion, (col: string) => {
      // virtualCols are already projected to the format needed by AgGrid, so just return it.
      // The only virtualCol currently is the first column with the checkboxes (AKA gridRowSelector)
      const virtualCol = virtualColumnDef[col];
      if (virtualCol) {
        return virtualCol;
      }

      const sorterCol: ISorterColumn = this.SorterColumn.getByColumnKey(col);

      // The rest of this function converts a SorterCol into a Column Def for AgGrid
      // See properties here: https://www.ag-grid.com/javascript-grid-column-properties/#gsc.tab=0
      let filter;
      let cellEditor;
      let cellEditorParams;
      let cellRenderer;
      let cellRendererParams;
      let filterParams;
      let keyCreator;
      let valueGetter;

      // Custom valueGetters
      const booleanValueGetter = "data[colDef.field] === null ? null : data[colDef.field] ? 'Yes' : 'No'";
      const numericNaNValueGetter = 'Number.isNaN(data[colDef.field]) ? null : data[colDef.field]';

      // dimensions
      const width = sorterCol.width || 90;

      // filters, cellEditors and cellRenderers
      switch (sorterCol.dataType) {
        case SorterColumnDataType.STRING:
          if (_.includes(sorterCol.path, 'notes.')) {
            cellEditor = 'largeText';
          } else {
            cellEditor = 'agPopupTextCellEditor';
          }
          filter = 'agSetColumnFilter';
          filterParams = {
            // include a formatter to handle case sensitivity, accents etc.
            textFormatter: this._caseInSensitiveFilter,
          };
          break;

        case SorterColumnDataType.BOOLEAN_YES_NO: {
          cellEditor = 'agRichSelectCellEditor';
          let isBooleanEligibleVariant;
          if (sorterCol.dataTypeOptions && sorterCol.dataTypeOptions.isBooleanEligibleVariant) {
            isBooleanEligibleVariant = sorterCol.dataTypeOptions.isBooleanEligibleVariant;
          }
          cellEditorParams = {
            values: [true, false, '-'],
            cellRenderer: undefined,
            valueGetter: undefined,
          };
          if (isBooleanEligibleVariant) {
            cellEditorParams.cellRenderer = CellRenderer.renderBooleanAsEligible;
            cellRenderer = CellRenderer.renderBooleanAsEligible;

            cellEditorParams.valueGetter = booleanValueGetter;
            valueGetter = booleanValueGetter;
          } else {
            cellEditorParams.cellRenderer = CellRenderer.renderBooleanAsYesNo;
            cellRenderer = CellRenderer.renderBooleanAsYesNo;

            cellEditorParams.valueGetter = booleanValueGetter;
            valueGetter = booleanValueGetter;
          }
          filter = BooleanYesNoFilter;
          break;
        }

        case SorterColumnDataType.ARRAY:
          cellRenderer = CellRenderer.renderArrayAsCSV;
          filter = 'agSetColumnFilter';
          filterParams = {
            // include a formatter to handle case sensitivity, accents etc.
            textFormatter: this._caseInSensitiveFilter,
          };
          break;

        case SorterColumnDataType.ENUM:
        case SorterColumnDataType.REGENTS_ADMIN: {
          cellEditor = 'agRichSelectCellEditor';
          const values = _.cloneDeep(sorterCol.dataTypeOptions.values);
          if (sorterCol.dataTypeOptions.canBeNull) {
            values.push('-');
          }
          cellEditorParams = { values };
          cellRenderer = CellRenderer.renderEnumWithNull;
          filter = 'agSetColumnFilter';
          filterParams = {
            textFormatter: this._caseInSensitiveFilter,
          };
          break;
        }

        case SorterColumnDataType.NUMERIC:
          cellEditor = 'agPopupTextCellEditor';
          if (sorterCol.dataTypeOptions.numberType === SorterColumnNumberType.PERCENT) {
            cellRenderer = CellRenderer.renderAsPercent;
          } else if (sorterCol.dataTypeOptions.numberType === SorterColumnNumberType.WITHOUT_DECIMAL) {
            cellRenderer = CellRenderer.renderWithoutDecimal;
          } else if (sorterCol.dataTypeOptions.numberType === SorterColumnNumberType.WITH_ONE_DECIMAL) {
            cellRenderer = CellRenderer.renderWithOneDecimal;
          } else if (sorterCol.dataTypeOptions.numberType === SorterColumnNumberType.WITH_NAN) {
            cellRenderer = CellRenderer.renderWithNaN;
            valueGetter = numericNaNValueGetter;
          }
          filter = 'agNumberColumnFilter';
          break;

        case SorterColumnDataType.USER_MINI: {
          if (!userMinis) {
            // tslint:disable-next-line:max-line-length
            const err = `Cannot load column "${
              sorterCol.humanName
            }" without providing student-set.service with userMinis arg`;
            throw new Error(err);
          }
          // tslint:disable-next-line:array-type
          const dataValidationVals: Array<IUserMini> = _.sortBy([...userMinis], 'lastName');

          // Allows "(none)" to be selected
          dataValidationVals.unshift({
            userId: null,
            gafeEmail: null,
            doeEmail: null,
            firstName: null,
            lastName: null,
          });

          cellEditor = 'agRichSelectCellEditor';
          cellRenderer = CellRenderer.renderUserMini;

          cellEditorParams = {
            cellRenderer,
            values: dataValidationVals,
          };

          keyCreator = params => {
            return params.value && params.value.lastName ? params.value.lastName : '(None)';
          };

          filter = 'agSetColumnFilter';

          filterParams = {
            // values: dataValidationVals.map((userMini) => this.imUser.getFullNameWithEmailFromMini(userMini)),
            textFormatter: this._caseInSensitiveFilter,
          };

          break;
        }

        default:
          cellEditor = 'agPopupTextCellEditor';
      }

      const _editable = () => {
        return sorterCol.editable === true;
      };

      /**
       * Wraps each custom cellClassRule and uses the object passed in by AgGrid to each classRule to only pass
       * in the property in the AgGrid object that we care about - "value" - to
       * each classRule. Hence, we are  * * simplifying the AgGrid API by only requiring
       * that the calculation/value is passed as a parameter to each * custom class
       * function from the sorter constant.
       *
       * @param {Object} of cellClassRules:
       *    {
       *      'class-name-1': function(calculation) {},
       *      'class-name-2': function(calculation) {}
       *    }
       *
       * @returns {Object} of cellClassRules:
       *    {
       *      'class-name-1': function(calculation) {},
       *      'class-name-2': function(calculation) {}
       *    }
       */
      const _wrapCellClassRules = cellClassRules => {
        const wrappedCellClassRules = {};

        _.each(cellClassRules, (value: Function, key) => {
          return (wrappedCellClassRules[key] = params => {
            return value(params.value);
          });
        });

        return wrappedCellClassRules;
      };

      // This functionality handles nulls and empty strings in the set filter. Nulls are rendered as "-" and empty
      // strings are rendered as "(Empty)". The default set filter behavior is to combine nulls and empty strings
      // into one value for filtering that is "null", but this causes all sorts of issues with the rest of the
      // app.
      let _keyCreator;
      if (
        sorterCol.dataType === SorterColumnDataType.ENUM ||
        sorterCol.dataType === SorterColumnDataType.REGENTS_ADMIN
      ) {
        _keyCreator = params => {
          if (_.isNull(params.value)) {
            return '-';
          } else if (params.value === '') {
            return '(Empty)';
          } else {
            return params.value;
          }
        };
      }

      if (sorterCol.dataType === SorterColumnDataType.ARRAY) {
        _keyCreator = params => {
          if (params.value && params.value.length) {
            return params.value;
          }
          return '(Blanks)';
        };
      }

      const _defaultComparator = (val1, val2) => {
        if (_.isNil(val1) && _.isNil(val2)) {
          return 0;
        }
        if (_.isNil(val1)) {
          return -1;
        }

        if (_.isNil(val2)) {
          return 1;
        }

        if (typeof val1 === 'string' && typeof val2 === 'string') {
          return val1.toLowerCase().localeCompare(val2.toLowerCase());
        }

        return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
      };

      // comparator for our custom data type object USER_MINI
      const _userMiniComparator = (val1, val2) => {
        val1 = val1 && val1.lastName;
        val2 = val2 && val2.lastName;

        if (_.isNil(val1) && _.isNil(val2)) {
          return 0;
        }
        if (_.isNil(val1)) {
          return -1;
        }

        if (_.isNil(val2)) {
          return 1;
        }

        return val1.toLowerCase().localeCompare(val2.toLowerCase());
      };

      const _arrayComparator = (arr1, arr2) => {
        if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
          return;
        }

        if (arr1.length === 0 && arr2.length === 0) {
          return 0;
        }

        if (arr1.length === 0) {
          return -1;
        }

        if (arr2.length === 0) {
          return 1;
        }

        const val1 = arr1.reduce((accum, value) => accum + value).toLowerCase();
        const val2 = arr2.reduce((accum, value) => accum + value).toLowerCase();

        return val1.localeCompare(val2);
      };

      // this comparator is used in instances where a numerical data is represented as string
      const _stringNumericComparator = (val1, val2) => {
        if (_.isNil(val1) && _.isNil(val2)) {
          return 0;
        }

        if (_.isNil(val1)) {
          return -1;
        }

        if (_.isNil(val2)) {
          return 1;
        }

        if (typeof val1 === 'string' && typeof val2 === 'string') {
          return val1.localeCompare(val2, undefined, { numeric: true });
        }

        // shouldn't come here ideally, but wouldn't hurt to have a failsafe.
        return val1 > val2 ? 1 : val1 < val2 ? -1 : 0;
      };

      let _comparator = _defaultComparator;
      if (sorterCol.dataType === SorterColumnDataType.NUMERIC) {
        _comparator = _stringNumericComparator;
      } else if (sorterCol.dataType === SorterColumnDataType.ARRAY) {
        _comparator = _arrayComparator;
      } else if (sorterCol.dataType === SorterColumnDataType.USER_MINI) {
        _comparator = _userMiniComparator;
      }

      // TODO: Make an interface for this (JC)
      return {
        headerName: sorterCol.humanName,
        headerClass: _editable() ? 'isEditable' : null,
        headerTooltip: sorterCol.headerTooltip || sorterCol.humanName,
        menuTabs: ['generalMenuTab', 'filterMenuTab'],
        field: col,
        filter,
        filterParams,
        pinned: sorterCol.pinned,
        lockPinned: sorterCol.lockPinned,
        minWidth: 90,
        width,
        sortable: true,
        // volatile: true,
        editable: false,
        cellEditor,
        cellEditorParams,
        cellRenderer,
        cellRendererParams,
        tooltipField: sorterCol.tooltipCol,
        cellClassRules: _.extend(
          {
            isEditable () {
              return sorterCol.editable === true;
            },
            center () {
              return sorterCol.dataType === SorterColumnDataType.NUMERIC;
            },
          },
          _wrapCellClassRules(sorterCol.formatClass),
        ),
        keyCreator: keyCreator || _keyCreator,
        comparator: _comparator,
        valueGetter: valueGetter,
      };
    });

    return ret;
  }

  // PRIVATE METHODS:
  _flattenStudent (student, school, columnKeys) {
    const flattenedStudent = {
      _id: null,
    };

    // set columnKeys on student:
    _.each(columnKeys, col => {
      this._setFlattenedStudentCol(flattenedStudent, col, student, student, school);
    });

    flattenedStudent._id = student._id;

    return flattenedStudent;
  }

  getProjectionForColumnKeys (columnKeys) {
    const projection = {};
    _.each(columnKeys, col => {
      const sorterCol = this.SorterColumn.getByColumnKey(col);

      if (sorterCol) {
        const paths = sorterCol.paths || [sorterCol.path];
        _.each(paths, path => (projection[path] = true));
      }
    });

    return this.UtilitiesService.reduceProjection(projection);
  }

  _getProjectionForPaths (paths) {
    const projection = {};
    _.each(paths, path => {
      projection[path] = true;
    });

    return this.UtilitiesService.reduceProjection(projection);
  }

  _setFlattenedStudentCol (flattenedStudent, col, student, studentPOJO, school) {
    let val;
    const sorterColumn = this.SorterColumn.getByColumnKey(col);
    if (sorterColumn.calculation) {
      // It seems like if an error is thrown in one of the sorterColumn.calculation functions,
      // the stack trace is lost. Catch and log to rollbar manually
      try {
        val = sorterColumn.calculation(student, school);
      } catch (err) {
        val = 'Bad calculation';
        const debug = {
          message: err.message,
          columnName: col,
          studentId: student._id,
          schoolId: school._id,
        };
        this.Rollbar.error('Bad calculation for SorterColumn', debug);
      }
    } else {
      const path = this.SorterColumn.getByColumnKey(col).path;
      val = this.UtilitiesService.getFieldByPath(studentPOJO, path);
    }
    if (_.isNil(val)) {
      flattenedStudent[col] = '-';
    } else {
      flattenedStudent[col] = val;
    }
  }

  _getJoinsForColumnKeys (columnKeys) {
    const allJoins = [];
    _.forEach(columnKeys, key => {
      const sorterCol = this.SorterColumn.getByColumnKey(key);

      if (!sorterCol) {
        this.Rollbar.warning(`Could not get joins for SorterColumn[${key}], skipping`);
        return;
      }

      if (sorterCol.joins) {
        // joins will be an array - - doesn't exist yet'
        _.forEach(sorterCol.joins, j => {
          allJoins.push(j);
        });
      }
    });
    return _.uniq(allJoins);
  }

  // when comparing to the text entered in the filter box, the text in the filter box is
  // always converted to lower case.
  // https://www.ag-grid.com/javascript-grid-filter-text/index.php#textFormatter&gsc.tab=0
  _caseInSensitiveFilter (filterStr) {
    if (filterStr == null) return null;
    return filterStr.toLowerCase();
  }

  validJoinsForJoinsProjection (joins, projection): string[] | null {
    // e.g. { join_student_mapGrowth: true, join_studentSupports: true } --> ['student_mapGrowth', 'studentSupports']
    const joinsForJoinsProjection = Object.keys(projection).reduce((acc, path) => {
      if (path.match('join_')) {
        acc.push(path.slice(5));
      }
      return acc;
    }, []);
    const validJoins = _.union(joins, joinsForJoinsProjection);
    return validJoins;
  }
}
