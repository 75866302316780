import { BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BACKGROUND_JOB_STATUS_TYPES } from './../../shared/services/background-job/background-job.service';
import { identity } from 'lodash';
import { BatchActionsEffectsUtilities } from './../utilities/batch-actions-effects-utilities';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Injector, forwardRef } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, take, tap, withLatestFrom, map, timeout } from 'rxjs/operators';
import { BackgroundJobNotificationService } from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import * as milestoneActions from '../actions/milestone-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import { ISnackBarData, SnackBarService } from './../../shared/services/snackbar/snackbar.service';
import { MILESTONE_PROJECTION } from './projections';

@Injectable()
export class MilestoneEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    private store: Store<any>,
    private backgroundJob: BackgroundJob,
    private injector: Injector,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
    @Inject(forwardRef(() => ModalsService)) public modalsService: ModalsService,
  ) {}

  snackBarData: ISnackBarData = { message: '', duration: 2000 };

  loadAllMilestones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(milestoneActions.LOAD_ALL_MILESTONES),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId } = action.payload;
        const projection = MILESTONE_PROJECTION;
        const payload = { schoolId, projection };
        return this.apiService.getMilestones(payload).pipe(
          switchMap(response => {
            const milestones = response.data || response;
            return [new milestoneActions.LoadAllMilestonesSuccess(milestones)];
          }),
          catchError(error => of(new milestoneActions.LoadAllMilestonesFail(error))),
        );
      }),
    );
  });

  loadStudentMilestones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(milestoneActions.LOAD_STUDENT_MILESTONES),
      switchMap((action: { type: string; payload: any }) => {
        const schoolId = action.payload.schoolId;
        const studentId = action.payload.studentId;
        const payload = { schoolId, studentId };
        return this.apiService.getMilestones(payload).pipe(
          switchMap(response => {
            const milestones = response.data || response;
            return [new milestoneActions.LoadStudentMilestonesSuccess({ milestones, studentId })];
          }),
          catchError(error => of(new milestoneActions.LoadStudentMilestonesFail(error))),
        );
      }),
    );
  });

  updateMilestone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(milestoneActions.UPDATE_MILESTONE),
      switchMap((action: { type: string; payload: any }) => {
        const { docId, patch, schoolId, studentId } = action.payload;
        
        return this.apiService.patchMilestone(docId, patch).pipe(
          switchMap(milestone => {
            return [new milestoneActions.UpdateMilestoneSuccess(milestone)];
          }),
          catchError(error => {
            return of(new milestoneActions.UpdateMilestoneFail(error));
          }),
        );
      }),
    );
  });

  updateMilestones$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(milestoneActions.BULK_UPDATE_MILESTONES),
      withLatestFrom(this.store),
      switchMap(([action]) => {
        const payload = action.payload;
        const { milestoneIds, status, where } = payload;
        const httpResponseObsv$: Observable<HttpResponse<any>> = this.apiService.bulkPatchMilestones(
          milestoneIds,
          status,
          where,
        );
        return httpResponseObsv$.pipe(
          take(1),
          map(response => BatchActionsEffectsUtilities.getJobSubject(this.backgroundJob, { response })),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Updating Students' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkMilestoneUpdate' });
              this.snackBarService.showBatchActionToast({ success: true });
            }
          }),
          catchError(() => {
            this.snackBarService.showBatchActionToast({ success: false });
            return of({});
          }),
        );
      }),
    );
  }, { dispatch: false });
}
