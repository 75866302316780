import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'nv-dashboard-tile',
  templateUrl: './nv-dashboard-tile.component.html',
  styleUrls: ['./nv-dashboard-tile.component.scss'],
})
export class NvDashboardTileComponent {
  /**
   * Tile data to be emitted on click
   */
  @Input() tile;

  /**
   * Description text of tile
   */
  @Input() description: string = '';

  /**
   * Label text
   */
  @Input() label: string = '';

  /**
   * Value of tile
   */
  @Input() value: number | string;

  /**
   * Description of what the value represents
   */
  @Input() valueDescription: string = '';

  /**
   * Optional icon to show the change direction
   */
  @Input() changeIcon?: 'increase' | 'increase-blue' |'increase-red' | 'dot' | 'decrease' | 'decrease-blue' | 'decrease-red';

  /**
   * Optional subtext to describe focused trend
   */
  @Input() changeText? : string;

  /**
   * Tile click emitter
   */
  @Output() tileClickEmitter = new EventEmitter<any>();

  public emitTileClick (tile): void {
    this.tileClickEmitter.emit(tile);
  }
}
