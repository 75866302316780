import { difference } from 'lodash';
import { Injectable } from '@angular/core';
import { TValidSdcColumns } from './../../sdc-columns.type';
import { DEFAULT_SORT } from './../default-sort.constant';
import { sdcStateConfigConstant } from './../sdc-state-config.constant';
@Injectable()
export class RegentsPrepConfigService {
  get humanName () {
    return sdcStateConfigConstant.REGENTS_PREP.humanName;
  }

  get state () {
    return sdcStateConfigConstant.REGENTS_PREP.state;
  }

  get gridColumns () {
    return difference(this.columns, this.hiddenColumns);
  }

  get columns (): TValidSdcColumns[] {
    return [
      'studentId',
      'studentName',
      'cohort',
      'seniorFlag',
      'status',
      'regentsPrepPriorityGrouping',
      'grade',
      'officialClass',

      // google classroom
      'classroomRemoteEngagementLast5',
      'classroomRemoteEngagement',
      'classroomTotalAssignments',
      'classroomTotalEngagedAssignments',
      'classroomTotalEnrolled',
      'classroomStudentEmail',

      'atsCounselor',
      'advisor',
      'studentEmail',
      'parentEmail',
      'iep',
      'ell',
      'homeLanguage',
      'attendanceYtd',
      'gradPlan',
      'currentCourses',
      'currentCourseCodes',
      'totalCreditsEarned',

      // regents planning - these are hidden
      'countOfRegentsNeededForOnTrackDiploma',
      'regentsNeededForOnTrackDiploma',
      'countOfRegentsNeededForOnTrackDiplomaNotSched',
      'regentsNeededForOnTrackDiplomaNotSched',
      'regentsNeededForCollegeReadiness',
      'examsSchedInPortalNotPlannedInStars',
      'examsSchedInStarsNotPlannedInPortal',

      // regents prep
      'countOfRegentsSchedForNextAdmin',
      'regentsSchedForNextAdmin',
      'countOfCurrentlyActiveSupports',
      'currentlyActiveSupports',
      'countOfRegentsSchedNextAdminWithNoPrep',
      'regentsSchedNextAdminWithNoPrep',
      'countOfRegentsSchedNextAdminWithCourseOnlyFailingBorderline',
      'regentsSchedNextAdminWithCourseOnlyFailingBorderline',

      // CCELA
      'ccElaPlannedNextAdmin',
      'maxScoreEnglishExams',
      'ccElaPrepStatus',
      'ccElaAlignedCourses',
      'ccElaAlignedSupports',
      // CCALG
      'ccAlgPlannedNextAdmin',
      'maxScoreMath',
      'ccAlgPrepStatus',
      'ccAlgAlignedCourses',
      'ccAlgAlignedSupports',
      // CCGEOM
      'ccGeomPlannedNextAdmin',
      'ccGeomPrepStatus',
      'ccGeomAlignedCourses',
      'ccGeomAlignedSupports',
      // CCTRIG
      'ccTrigPlannedNextAdmin',
      'ccTrigPrepStatus',
      'ccTrigAlignedCourses',
      'ccTrigAlignedSupports',
      // LIVINGENV
      'livingEnvPlannedNextAdmin',
      'livingEnvPrepStatus',
      'livingEnvAlignedCourses',
      'livingEnvAlignedSupports',
      // EARTH
      'earthPlannedNextAdmin',
      'earthPrepStatus',
      'earthAlignedCourses',
      'earthAlignedSupports',
      // CHEM
      'chemPlannedNextAdmin',
      'chemPrepStatus',
      'chemAlignedCourses',
      'chemAlignedSupports',
      // PHYSICS
      'physicsPlannedNextAdmin',
      'physicsPrepStatus',
      'physicsAlignedCourses',
      'physicsAlignedSupports',
      // GLOBAL II
      'globalTwoPlannedNextAdmin',
      'globalTwoPrepStatus',
      'globalTwoAlignedCourses',
      'globalTwoAlignedSupports',
      // US Framework
      'usFrameworkPlannedNextAdmin',
      'usFrameworkPrepStatus',
      'usFrameworkAlignedCourses',
      'usFrameworkAlignedSupports',
      // LOTE
      'lotePlannedNextAdmin',
      'nextScheduledLoteExamName',
      'lotePrepStatus',
      'loteAlignedCourses',
      'loteAlignedSupports',
      // added for community schools on 3/22
      'schoolPriorityFlag',
    ];
  }

  get hiddenColumns (): TValidSdcColumns[] {
    return [
      'countOfRegentsNeededForOnTrackDiploma',
      'regentsNeededForOnTrackDiploma',
      'countOfRegentsNeededForOnTrackDiplomaNotSched',
      'regentsNeededForOnTrackDiplomaNotSched',
      'regentsNeededForCollegeReadiness',
      'examsSchedInPortalNotPlannedInStars',
      'examsSchedInStarsNotPlannedInPortal',
    ];
  }

  get gridViews () {
    return {};
  }

  get filter () {
    return {
      status: { values: ['A'] },
    };
  }

  get sort () {
    return DEFAULT_SORT;
  }

  get bundleName () {
    return 'REGENTS_PREP';
  }

  get availableSdcViews () {
    return sdcStateConfigConstant.REGENTS_PREP.availableSdcViews;
  }

  get imStudentMethods (): any {
    return null;
  }

  get joins (): string[] {
    return ['studentSupports'];
  }
}
