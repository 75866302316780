import { GraduationMonth } from './graduation-month.constant';

// TValidGradDates is mapped to the "GRAD_DATES" const on the backend
export type TValidGradDates =
  | 'Jan 2021'
  | 'March 2021'
  | 'June 2021'
  | 'Aug 2021'
  | 'Jan 2022'
  | 'March 2022'
  | 'June 2022'
  | 'Aug 2022'
  | 'Jan 2023'
  | 'March 2023'
  | 'June 2023'
  | 'Aug 2023'
  | 'Jan 2024'
  | 'March 2024'
  | 'June 2024'
  | 'Aug 2024'
  | 'Jan 2025'
  | 'March 2025'
  | 'June 2025'
  | 'Aug 2025'
  | 'Jan 2026'
  | 'March 2026'
  | 'June 2026'
  | 'Aug 2026'
  | 'Jan 2027'
  | 'March 2027'
  | 'June 2027'
  | 'Aug 2027'
  | '—';

export interface IGraduationDate {
  humanName: TValidGradDates;
  month: string;
  monthOrder: number;
  year: number;
}

// TODO UPDATE AT ATS FLIP: add new grad dates, remove old grad dates
export const GraduationDate = {
  JAN_2021: {
    humanName: 'Jan 2021',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2021,
  } as IGraduationDate,
  MARCH_2021: {
    humanName: 'March 2021',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2021,
  } as IGraduationDate,
  JUNE_2021: {
    humanName: 'June 2021',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2021,
  } as IGraduationDate,
  AUG_2021: {
    humanName: 'Aug 2021',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2021,
  } as IGraduationDate,
  JAN_2022: {
    humanName: 'Jan 2022',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2022,
  } as IGraduationDate,
  MARCH_2022: {
    humanName: 'March 2022',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2022,
  } as IGraduationDate,
  JUNE_2022: {
    humanName: 'June 2022',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2022,
  } as IGraduationDate,
  AUG_2022: {
    humanName: 'Aug 2022',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2022,
  } as IGraduationDate,
  JAN_2023: {
    humanName: 'Jan 2023',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2023,
  } as IGraduationDate,
  MARCH_2023: {
    humanName: 'March 2023',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2023,
  } as IGraduationDate,
  JUNE_2023: {
    humanName: 'June 2023',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2023,
  } as IGraduationDate,
  AUG_2023: {
    humanName: 'Aug 2023',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2023,
  } as IGraduationDate,
  JAN_2024: {
    humanName: 'Jan 2024',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2024,
  } as IGraduationDate,
  MARCH_2024: {
    humanName: 'March 2024',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2024,
  } as IGraduationDate,
  JUNE_2024: {
    humanName: 'June 2024',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2024,
  } as IGraduationDate,
  AUG_2024: {
    humanName: 'Aug 2024',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2024,
  } as IGraduationDate,
  JAN_2025: {
    humanName: 'Jan 2025',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2025,
  } as IGraduationDate,
  MARCH_2025: {
    humanName: 'March 2025',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2025,
  } as IGraduationDate,
  JUNE_2025: {
    humanName: 'June 2025',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2025,
  } as IGraduationDate,
  AUG_2025: {
    humanName: 'Aug 2025',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2025,
  } as IGraduationDate,
  JAN_2026: {
    humanName: 'Jan 2026',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2026,
  } as IGraduationDate,
  MARCH_2026: {
    humanName: 'March 2026',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2026,
  } as IGraduationDate,
  JUNE_2026: {
    humanName: 'June 2026',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2026,
  } as IGraduationDate,
  AUG_2026: {
    humanName: 'Aug 2026',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2026,
  } as IGraduationDate,
  JAN_2027: {
    humanName: 'Jan 2027',
    month: GraduationMonth.JAN,
    monthOrder: 1,
    year: 2027,
  } as IGraduationDate,
  MARCH_2027: {
    humanName: 'March 2027',
    month: GraduationMonth.MAR,
    monthOrder: 2,
    year: 2027,
  } as IGraduationDate,
  JUNE_2027: {
    humanName: 'June 2027',
    month: GraduationMonth.JUNE,
    monthOrder: 3,
    year: 2027,
  } as IGraduationDate,
  AUG_2027: {
    humanName: 'Aug 2027',
    month: GraduationMonth.AUG,
    monthOrder: 4,
    year: 2027,
  } as IGraduationDate,
};

// TODO UPDATE AT ATS FLIP: change to to GraduationDate.AUG_[current school year end]
// TODO UPDATE AT TERM 1 FLIP: change to to GraduationDate.JAN_[current school year end]
// TODO UPDATE AT TERM 2 FLIP: change to GraduationDate.MARCH_[current school year end]
// TODO UPDATE AT END OF MARCH: change to GraduationDate.JUNE_[current school year end]
export const NextGraduationDate = GraduationDate.JAN_2024 as IGraduationDate;
