import { NvDatePickerModule } from './../../../../../../nvps-libraries/design/nv-date-picker/nv-date-picker.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';

import { ShelterSuccessMentorShellComponent } from './shelter-success-mentor-shell.component';
import { BaseModalModule } from './../../../base-modal.module';
import { NvFormFieldModule } from '../../../../../../nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvButtonModule } from '../../../../../../nvps-libraries/design/nv-button/nv-button.module';
import { NvIconModule } from '../../../../../../nvps-libraries/design/nv-icon/nv-icon.module';
import { NvMatTooltipModule } from '../../../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { NvTimePickerModule } from '../../../../../../nvps-libraries/design/nv-time-picker/nv-time-picker.module';
import { NvTextareaModule } from '../../../../../../nvps-libraries/design/nv-textarea/nv-textarea.module';
import { NvMultipickerModule } from '../../../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.module';
import { NvTextboxModule } from '../../../../../../nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvDesignLibraryModule } from '../../../../../../nvps-libraries/design/nv-design-library.module';

@NgModule({
  declarations: [ShelterSuccessMentorShellComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    BaseModalModule,
    NvFormFieldModule,
    NvIconModule,
    NvMatTooltipModule,
    NvButtonModule,
    NvDatePickerModule,
    NvTimePickerModule,
    NvTextareaModule,
    NvMultipickerModule,
    NvTextboxModule,
    NvDesignLibraryModule,
  ],
  exports: [ShelterSuccessMentorShellComponent],
  providers: [],
})
export class ShelterSuccessMentorShellModalModule {}
