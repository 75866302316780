import { Component, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { COLLEGE_PATH_STATUSES } from '../../../../../shared/constants/student-paths.constant';
import { ListDisplay } from '../../list-display.component';
import { IStudent } from './../../../../../shared/typings/interfaces/student.interface';
import { IStudentPathUpdatePayload, UpdateStudentPath } from './../../../../../store/actions/student-paths-actions';

/*
WARNING: This is a base class for ListDisplayCareerPath component. Bear this in mind
         when modifying the logic here (dvn)
*/

export const SPEC_OPP_HUMAN_MAP = {
  'Applied w. spec. opp.': COLLEGE_PATH_STATUSES.APPLIED_WITH_SPECIAL_OPP,
  'Accepted w. spec. opp.': COLLEGE_PATH_STATUSES.ACCEPTED_WITH_SPECIAL_OPP,
  'Committed w. spec. opp.': COLLEGE_PATH_STATUSES.COMMITTED_WITH_SPECIAL_OPP,
};

export const SPEC_OPP_KEY_MAP = {
  'Applied w. special opportunity': 'Applied w. spec. opp.',
  'Accepted w. special opportunity': 'Accepted w. spec. opp.',
  'Committed w. special opportunity': 'Committed w. spec. opp.',
};

@Component({
  selector: 'list-display-college-path',
  templateUrl: './list-display-college-path.component.html',
  styleUrls: ['./../list-display-path.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ListDisplayCollegePath extends ListDisplay {
  otherPathData; // refers to careerPath data. don't change the name since it's used in career component (dvn)
  @Input()
  set careerPathsData$ (careerData: string) {
    this.otherPathData = JSON.parse(careerData);
  }

  @Input() isViewOnlyUser: boolean;
  @Input() student: IStudent;

  finalDecisionPathId: string = null; // holds the studentPathId of path that has final decision
  iconName = 'star';
  iconColor = 'yellow';

  finalDecisionStatusesForAllPaths = [
    COLLEGE_PATH_STATUSES.COMMITTED,
    COLLEGE_PATH_STATUSES.COMMITTED_WITH_SPECIAL_OPP,
  ];

  finalDecisionStatuses = [COLLEGE_PATH_STATUSES.COMMITTED, COLLEGE_PATH_STATUSES.COMMITTED_WITH_SPECIAL_OPP];

  normalStatuses = [
    COLLEGE_PATH_STATUSES.PLANS_TO_APPLY,
    COLLEGE_PATH_STATUSES.APPLIED,
    COLLEGE_PATH_STATUSES.APPLIED_WITH_SPECIAL_OPP,
    COLLEGE_PATH_STATUSES.ACCEPTED,
    COLLEGE_PATH_STATUSES.ACCEPTED_WITH_SPECIAL_OPP,
    COLLEGE_PATH_STATUSES.WAITLISTED,
    COLLEGE_PATH_STATUSES.DENIED,
  ];

  /* istanbul ignore next */
  checkForFinalDecision (listData, isFormatted: boolean): void {
    if (listData.sections.length === 0) return;

    const dataSection = isFormatted ? listData.sections[0].formattedRows : listData.sections[0].data;

    dataSection.forEach((row: any) => {
      const plan = isFormatted ? row[1].data : row.status.data;
      if (this.finalDecisionStatusesForAllPaths.indexOf(plan) > -1) {
        const studentPathId = isFormatted ? row[1].dependencies.studentPathId : row.status.dependencies.studentPathId;
        this.finalDecisionPathId = studentPathId;
      }
    });
  }

  ngOnChanges (changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.checkForFinalDecision(this.parsedData, true);
    this.checkForFinalDecision(this.otherPathData, false);
  }

  rowClick (dataRow): void {
    //
  }
}
