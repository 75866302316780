import { IAttendanceRecord } from 'Src/ng2/shared/typings/interfaces/attendance-record.interface';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { getBatchActionsSelectedStudentIds } from 'Src/ng2/store';

export const SUPPORT_ATTENDANCE_FORM_DROPDOWN_OPTIONS: IDropdownOption[] = [
  {
    key: 'DELETED',
    human: '–',
  },
  {
    key: 'PRESENT',
    human: 'Present',
  },
  {
    key: 'LATE',
    human: 'Late',
  },
  {
    key: 'ABSENT',
    human: 'Absent',
  },
  {
    key: 'EXCUSED',
    human: 'Excused',
  },
];

@Component({
  selector: 'support-attendance-form-dropdown',
  templateUrl: './support-attendance-form-dropdown.component.html',
  styleUrls: ['./support-attendance-form-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class SupportAttendanceFormDropdown implements OnInit {
  @Input() input: any;
  @Output() output: any = new EventEmitter();
  public status: IAttendanceRecord['status'];
  meta: any;

  constructor (private store: Store<any>) {}

  public batchActionsSelectedIds$: Observable<string[]> = null;
  public batchEditOptions = SUPPORT_ATTENDANCE_FORM_DROPDOWN_OPTIONS;
  public isInverted = true;

  ngOnInit (): void {
    this.batchActionsSelectedIds$ = this.store.select(getBatchActionsSelectedStudentIds);
    const { row, column } = this.input;
    if (row && row.length) {
      this.meta = JSON.parse(row[0].meta);
      this.status = column.data;
      this.meta.status = this.status;
    }
  }

  onSelectAttendanceStatus ($event: IAttendanceRecord['status']) {
    if ($event !== this.status) {
      this.output.emit({
        action: $event,
        meta: this.meta,
      });
    }
  }
}
