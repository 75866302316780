import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NvDesignLibraryModule } from '../../../../nvps-libraries/design/nv-design-library.module';
import { ModalsModule } from '../../modals/modals.module';
import { ExitViewComponent } from './exit-view.component';

@NgModule({
  declarations: [ExitViewComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, ModalsModule, NvDesignLibraryModule],
  exports: [ExitViewComponent],
})
export class ExitViewModule {}
