import { IStudentSupport } from '../../../shared/typings/interfaces/student-support.interface';
import { IPointPerson } from '../../../shared/typings/interfaces/student.interface';

export const ATTENDANCE_PROJECTION = {
  _id: true,
  studentId: true,
  'studentDetails.name': true,
  'studentDetails.grade': true,
  'studentDetails.classOf': true,
  'studentDetails.officialClass': true,
  'gradPlanningDetails.plannedGraduationDate': true,
  'gradPlanningDetails.plannedDiplomaType': true,
  'spedDetails.isSped': true,
  'ellDetails.isEll': true,
  isMS: true,
  isHS: true,
  isES: true,
  pointPeople: true,
  otherStaff: true,
  isNewStudent: true,
  'studentDetails.admitDate': true,
  numAttSupports: true,
  attSupports: true,
  'att.riskGroup': true,
  'att.ytd': true,
  'att.ytdLastSy': true,
  'att.overallLastSy': true,
  'att.today': true,
  'att.last5.trend': true,
  'att.last5.ytdStart': true,
  'att.last5.abs': true,
  'att.last5.pctPres': true,
  'att.last20.trend': true,
  'att.last20.ytdStart': true,
  'att.last20.abs': true,
  'att.last20.pctPres': true,
  'att.currTerm.trend': true,
  'att.currTerm.ytdStart': true,
  'att.currTerm.abs': true,
  'att.currTerm.pctPres': true,
  'att.currSy.trend': true,
  'att.currSy.ytdStart': true,
  'att.currSy.abs': true,
  'att.currSy.pctChange': true,
  'att.currSy.pctPres': true,
  'att.daysBeforeSlipping': true,
};

export interface IAttendanceListStudent {
  _id: string;
  studentId: string;
  studentDetails: {
    classOf: string;
    grade: string;
    name: {
      firstLast: string;
    };
  };
  gradPlanningDetails: {
    plannedDiplomaType: string;
    plannedGraduationDate: string;
  };
  spedDetails: {
    isSped: boolean;
  };
  ellDetails: {
    isEll: boolean;
  };
  isMS: boolean;
  isHS: boolean;
  isES: boolean;
  pointPeople: IPointPerson[];
  otherStaff: IPointPerson[];
  isNewStudent: boolean;
  numAttSupports: number;
  attSupports: IStudentSupport[];
  att: any;
  flags?: any;
  activeStudentSupports?: any;
}

export const ATTENDANCE_JOINS = [];
