import { getCurrentUser, getCurrentUserLoadedStatus } from 'Src/ng2/store/selectors/current-user-selectors';
import { LoadCurrentUser } from './../../../store/actions/current-user-actions';
import { ImUser } from './../../../shared/services/im-models/im-user';
import { tap, filter, switchMap, take } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { Auth } from 'Src/ng2/shared/auth/auth.service';

@Injectable()
export class UftDoeAdvisingGuard implements CanActivate {
  constructor(
    private imUser: ImUser,
    private auth: Auth,
    private router: Router,
    private store: Store<any>,
  ) {}

canActivate (): Observable<boolean | UrlTree> {
  return this.getCurrentUserFromStoreOrApi().pipe(
    switchMap(user => {
      const isUftDoeAdvisingUser = this.imUser.isUftDoeAdvisingUser(user);
      if (isUftDoeAdvisingUser) return of(true) as Observable<boolean>;
      else return of(this.router.createUrlTree(['home'])) as Observable<UrlTree>;
    }),
  );
}

getCurrentUserFromStoreOrApi (): Observable<IUser> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      tap(async loaded => {
        if (!loaded) {
          const _id = await this.auth.getCurrentUserId();
          this.store.dispatch(new LoadCurrentUser({ _id }));
        }
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
      take(1),
    );
  }
}