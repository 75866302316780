import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvNoteComponent } from './nv-note.component';

@NgModule({
  imports: [CommonModule, NvIconModule],
  declarations: [NvNoteComponent],
  exports: [NvNoteComponent],
})
export class NvNoteModule {}
