import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IModalConfig } from '../experience-form.component';
import { map, startWith, tap } from 'rxjs/operators';
import { PickerService } from 'Src/ng2/shared/services/picker/picker.service';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { Unsubscribable } from 'rxjs';

@Component({
  selector: 'form-item',
  templateUrl: 'form-item.component.html',
})

@unsubscribeComponent
export class FormItemComponent implements OnInit {
  @Input() config: IModalConfig;
  @Input() form: FormControl | FormGroup;

  @Output() changeHandler = new EventEmitter();

  public icon: string;
  public labelIcon: string;
  public options;

  private searchValSub: Unsubscribable;

  constructor (private pickerService: PickerService) {};

  ngOnInit (): void {
    this.icon = this.config.meta.icon || null;
    this.labelIcon = this.config.meta.labelIconTooltip ? 'info' : null;

    if (this.config.options) {
      const searchVal$ = this.form.valueChanges.pipe(startWith(''));
      this.searchValSub = searchVal$.pipe(
        map(searchVal => this.pickerService.filterAcOptions(this.config.options, searchVal)),
        tap(options => {
          this.options = options;
        }),
      ).subscribe();
    }
  }

  onSelectOption ($event) {
    this.changeHandler.emit($event);
  }

  clearTextBoxValue () {
    if (this.config.options) {
      this.options = this.config.options;
    }
    this.form.reset('');
  }
}
