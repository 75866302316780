import { createFeatureSelector, createSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getShelterGridDataSlice = createSelector(
  getPortalState,
  (state: any) => state.shelterGridData,
);

export const getShelterGridDataLoaded = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.loaded,
);

export const getShelterGridDataLoading = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.loading,
);

export const getShelterGridDataShelterId = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.shelterId,
);

export const getShelterGridDataColumnDefs = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.columnDefs,
);

export const getShelterGridDataRowData = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.rowData,
);

export const getShelterGridDataFilterHash = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.filterHash,
);

export const getShelterGridDataSortHash = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.sortHash,
);

export const getShelterGridDataStateHash = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.stateHash,
);

export const getShelterGridDataScrollLeft = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.scrollLeft,
);

export const getShelterGridDataScrollTop = createSelector(
  getShelterGridDataSlice,
  (state: any) => state.scrollTop,
);
