import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { cloneDeep, filter, find, forEach, includes, isEqual, map, reduce, sortBy } from 'lodash';
import { Observable } from 'rxjs/Observable';
import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ModalsService } from '../../../../shared/modals/modals.service';
import {
  ISchoolAssessment,
  SCHOOL_ASSESSMENTS_KEY_MAP,
} from '../../../../shared/typings/interfaces/school-assessment.interface';
import { ISchool } from '../../../../shared/typings/interfaces/school.interface';
import {
  BulkCreateSchoolAssessments,
  BulkUpdateSchoolAssessments,
  IBulkUpdateSchoolAssessmentsPayload,
  ISchoolAssessmentCreateOption,
  ISchoolAssessmentUpdateOption,
} from '../../../../store';
import { HelpDeskService } from '../../../services/help-desk/help-desk.service';
import { SettingsPageData } from '../settings-data-service/settings-data.service';
import { CurrentSchoolYear } from './../../../constants/current-school-year.constant';

export interface ITempSchoolAssessment {
  adminNumber: number;
  startDate?: string;
  endDate?: string;
  _id?: string;
}

export interface IGroupedSchoolAssessments {
  name: string;
  admins: ISchoolAssessment[] | ITempSchoolAssessment[];
  isTemp: boolean;
}

@Component({
  selector: 'assessments-settings',
  templateUrl: './assessments-settings.component.html',
  styleUrls: ['./assessments-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class AssessmentsSettingsComponent implements OnInit, OnDestroy {
  public schoolAssessments$: Observable<ISchoolAssessment[]>;
  public currentSchool: ISchool;
  public schoolAssessments: ISchoolAssessment[];
  // TODO: make this dynamic once more assessments are added (JE)
  public schoolAssessmentOptions: string[] = ['F & P'];
  public unusedSchoolAssessmentOptions: string[];
  public groupedAssessments: IGroupedSchoolAssessments[];
  public assessmentOptions: string[];
  public assessmentsLocked: boolean;
  public unsavedChanges: boolean;
  public disableAddAssessment: boolean;
  public showWarningBanner: boolean;
  public assessmentsFinalized: boolean;
  public enableFinalize: boolean;
  public adminNumberOptions = [3, 4, 5, 6, 7, 8, 9, 10];
  public displayGradeTable = false;
  public emsGrades: string[];
  public parsedEmsGrades: string[];
  public finalizeTooltip = 'Prevent changes to assessment settings and enable assessment data entry';
  public pageHeaderMeta = {
    title: 'Assessments',
    subTitle: null,
    icon: {},
    infoIcon: {
      tooltipData:
        'This section allows you to add and modify what formative assessments your school administers for each grade.',
    },
  };

  // forms properties
  public assessmentsForm: FormGroup;
  public initialFormState: FormGroup;
  public enableSaveDraft: boolean;
  public assessmentMinMaxMap = {};

  constructor (
    private activatedRoute: ActivatedRoute,
    private dataService: SettingsPageData,
    private formBuilder: FormBuilder,
    private helpDeskService: HelpDeskService,
    private modalsService: ModalsService,
    private store: Store<any>,
  ) {
    document.title = 'Settings - Assessments | Portal';
  }

  ngOnInit () {
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;
    this.schoolAssessments$ = this.dataService.getSchoolAssessments$({
      schoolId: this.currentSchool._id,
      schoolYear: CurrentSchoolYear.WITH_SY_PREFIX,
    });
    const { gradesServed } = this.currentSchool;
    this.emsGrades = filter(gradesServed, grade => {
      // remove high school grades from table for hybrid schools (JE)
      return !includes(['09', '10', '11', '12'], grade);
    });

    // removes leading 0s from grade level rows
    this.parsedEmsGrades = map(this.emsGrades, grade => {
      return grade.replace(/^0+/, '');
    });

    // subscribe to schoolAssessments slice of store (JE)
    this.schoolAssessments$.subscribe((schoolAssessments: ISchoolAssessment[]) => {
      this.schoolAssessments = schoolAssessments;
      this.assessmentsFinalized = !!find(schoolAssessments, assessment => assessment.status === 'LOCKED');
      this.groupedAssessments = this.groupAssessments(this.schoolAssessments);
      this._buildAssessmentsForm(this.groupedAssessments);
      this.filterAssessmentOptions();
      this.assessmentsForm.valueChanges.subscribe(() => {
        this._checkFormChanges();
      });
    });
  }

  ngOnDestroy (): void {
    document.title = 'Portal by New Visions';
  }

  filterAssessmentOptions (): void {
    this.unusedSchoolAssessmentOptions = filter(this.schoolAssessmentOptions, option => {
      // check if assessment has already been saved in the database (JE)
      const createdAsssessment = find(this.groupedAssessments, assessment => {
        return assessment.name === option;
      });
      return !createdAsssessment;
    });
    const assignedAssessments = map(this.groupedAssessments, assmnt => assmnt.name);
    this.assessmentOptions = [...assignedAssessments, '—'];
    this.disableAddAssessment = this.unusedSchoolAssessmentOptions.length === 0;
    this.showWarningBanner = this.groupedAssessments.length > 0;
  }

  selectAssessmentOption (option: string): void {
    const admins = [
      // default # of admins for a new assessment is 3 (JE)
      {
        adminNumber: 1,
        startDate: null,
        endDate: null,
      },
      {
        adminNumber: 2,
        startDate: null,
        endDate: null,
      },
      {
        adminNumber: 3,
        startDate: null,
        endDate: null,
      },
    ];
    const newSchoolAssessment: IGroupedSchoolAssessments = {
      name: option,
      admins,
      isTemp: true,
    };
    const asmntFormGroup: FormArray = this.formBuilder.array([]);
    for (let i = 0; i < 3; i++) {
      const adminFormGroup = this.formBuilder.group({
        startDate: new FormControl(null),
        endDate: new FormControl(null),
      });
      asmntFormGroup.push(adminFormGroup);
    }
    this.assessmentsForm.registerControl(option, asmntFormGroup);
    this.assessmentsForm.controls[option].markAsDirty();
    this.showWarningBanner = true;
    this.displayGradeTable = true;
    this.groupedAssessments = [...this.groupedAssessments, newSchoolAssessment];
    this.filterAssessmentOptions();
  }

  updateAdmins (assessment: IGroupedSchoolAssessments, num: number): void {
    const { admins, name } = assessment;
    const len = admins.length;
    let newAdmins;
    const form = this.assessmentsForm.controls[name] as FormArray;
    if (len > num) {
      // removing admins
      newAdmins = admins.slice(0, num);
      const removedAdmins = admins.slice(num);
      // admins without an _id have not been saved yet and can just be removed from the form
      const filteredRemovedAdmins = filter(removedAdmins, admin => admin._id);
      // update form
      const numToRemove = len - num;
      for (let i = 0; i < numToRemove; i++) {
        form.removeAt(form.value.length - 1);
      }

      // dispatch patch to delete removed admins
      const payload = map(filteredRemovedAdmins, (admin: ITempSchoolAssessment) => {
        const update = {
          schoolAssessmentId: admin._id,
          status: 'DELETED',
        };
        return update;
      });
      if (payload.length) this.dispatchBulkUpdateAssessment({ payload });
    } else {
      // adding admins
      newAdmins = [...admins];
      // create new empty admins
      for (let i = len + 1; i <= num; i++) {
        const newAdmin = {
          adminNumber: i,
          startDate: null,
          endDate: null,
        };
        newAdmins = [...newAdmins, newAdmin];
        // update form
        const newControl = this.formBuilder.group({
          startDate: null,
          endDate: null,
        });
        form.push(newControl);
      }
    }

    // update rendering data
    const newAssessment = assessment;
    newAssessment.admins = newAdmins;
    // remove old assessment
    const filteredAssessments = filter(this.groupedAssessments, assmnt => assmnt.name !== assessment.name);
    // add updated assessment
    this.groupedAssessments = [...filteredAssessments, newAssessment];
    this._checkFormChanges();
  }

  removeAssessment (assessment: IGroupedSchoolAssessments): void {
    const data = {
      title: 'Remove assessment?',
      message: "Are you sure you want to remove this assessment from your school's administration plan?",
      confirmText: 'Remove',
    };
    this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .subscribe((confirmed: false | true) => {
        if (confirmed) {
          const { name, isTemp } = assessment;
          if (isTemp) {
            // assessment hasn't been saved, just remove it from temp data (JE)
            this.groupedAssessments = filter(this.groupedAssessments, assmnt => assmnt.name !== name);
            this.filterAssessmentOptions();
            this.assessmentsForm.removeControl(name);
          } else {
            // if assessment has already been saved, send patch to remove it (JE)
            // find all existing admins for this assessment at the school
            const adminsForAssmnt = filter(
              this.schoolAssessments,
              asmnt => asmnt.assessment.assessmentNickName === name,
            );
            const payload = map(adminsForAssmnt, admin => {
              return { schoolAssessmentId: admin._id, status: 'DELETED' };
            });
            const updates: IBulkUpdateSchoolAssessmentsPayload = { payload };
            // dispatch action to set status of these admins to 'DELETED'
            this.dispatchBulkUpdateAssessment(updates);
          }
        }
        // remove any assessment assignments from grade levels for removed assessment
        const gradeAssignments = this.assessmentsForm.controls['Grade Selections'].value;
        forEach(this.emsGrades, grade => {
          if (gradeAssignments[grade] === assessment.name) gradeAssignments[grade] = null;
        });
        this.assessmentsForm.controls['Grade Selections'].setValue(gradeAssignments);

        // update save button state
        this._checkFormChanges();

        // hide table if no assessments are assigned to school
        this.displayGradeTable = !!this.groupedAssessments.length;
      });
  }

  groupAssessments (schoolAssessments: ISchoolAssessment[]): IGroupedSchoolAssessments[] {
    const result = [];
    forEach(this.schoolAssessmentOptions, option => {
      const filteredAdmins = filter(schoolAssessments, schoolAssessment => {
        return schoolAssessment.assessment.assessmentNickName === option;
      });
      const sortedAdmins = sortBy(filteredAdmins, admin => {
        return admin.adminNumber;
      });
      const assessment = {
        name: option,
        admins: sortedAdmins,
        isTemp: false,
      };
      if (filteredAdmins.length) result.push(assessment);
    });
    return result;
  }

  /*
   * To accomodate for multiple assessments, this form has the following structure
   * Parent Form = a FormGroup with controls representing each assessment (such as F & P)
   *   Assessment Control = a FormArray with controls representing individual admins of that assessment
   *     Admin Control = a FormGroup with controls representing values for the start and end dates of the admin
   *       Start/End Control = a FormControl used by the Date Pickers from our design library (JE)
   */
  _buildAssessmentsForm (groupedAssessments: IGroupedSchoolAssessments[]): void {
    this.assessmentsForm = this.formBuilder.group({}); // parent form
    // add already-created assessments to the form (JE)
    forEach(groupedAssessments, assessment => {
      const { admins } = assessment;
      const asmntFormGroup: FormArray = this.formBuilder.array([]); // sub-group, one per assessment type (ie 'F & P')
      forEach(admins, admin => {
        const adminFormGroup = this.formBuilder.group({
          startDate: new FormControl(admin.startDate),
          endDate: new FormControl(admin.endDate),
        });
        asmntFormGroup.push(adminFormGroup);
        adminFormGroup.valueChanges.subscribe(changes => {
          const { startDate, endDate } = changes;
          // handle for whether the date as been changed back to it's original value
          if (admin.startDate !== startDate || admin.endDate !== endDate) {
            this.assessmentsForm.controls[assessment.name].markAsDirty();
            adminFormGroup.markAsDirty();
          }
          this._checkFormChanges();
        });
      });
      this.assessmentsForm.registerControl(assessment.name, asmntFormGroup);
    });

    // maps which assessments are assigned to which grades
    // in the shape { '06': 'F & P', '07': null }
    const gradeAssessmentMap = reduce(
      this.emsGrades,
      (result, grade) => {
        const assignedAssessment = find(this.schoolAssessments, asmnt => {
          return asmnt.gradeLevels.indexOf(grade) >= 0;
        });
        result[grade] = assignedAssessment ? assignedAssessment.assessment.assessmentNickName : null;
        return result;
      },
      {},
    );
    // register grade selections with parent form
    this.assessmentsForm.registerControl('Grade Selections', new FormControl(gradeAssessmentMap));
    this.initialFormState = cloneDeep(this.assessmentsForm);
    // table is only visible if assessments have been created for this school
    this.displayGradeTable = !!groupedAssessments.length;
  }

  updateGradeAssessment (grade, assessmentNickName): void {
    const gradeAssignments = this.assessmentsForm.controls['Grade Selections'].value;
    if (assessmentNickName === '—') gradeAssignments[grade] = null;
    else gradeAssignments[grade] = assessmentNickName;
    this.assessmentsForm.controls['Grade Selections'].setValue(gradeAssignments);
    this._checkFormChanges();
  }

  _checkFormChanges (): void {
    let formHasChanged = false;
    const controlKeys = Object.keys(this.assessmentsForm.controls);
    forEach(controlKeys, control => {
      const initialValue = this.initialFormState.controls[control]
        ? this.initialFormState.controls[control].value
        : null;
      const currentValue = this.assessmentsForm.controls[control].value;
      const isDirty = this.assessmentsForm.controls[control].dirty;
      if (!isEqual(initialValue, currentValue) || isDirty) formHasChanged = true;
    });
    this.enableSaveDraft = formHasChanged;
    this.enableFinalize = this.isFormValid();
  }

  saveDraft (finalize?: boolean): void {
    const { gradeSelections, assessmentKeys, assessmentGradesMap } = this._parseAssessmentsForm(this.assessmentsForm);

    // identify which assessments need to be created, and which need to be updated
    // Note: while these nested loops are not ideal, a school can only have a maximum of 10 admins per assessment
    forEach(assessmentKeys, key => {
      const assessmentFormGroup = this.assessmentsForm.controls[key] as FormGroup;
      // parse grades assigned to this assessment
      const gradeKeys = Object.keys(gradeSelections);
      const gradeLevels = reduce(
        gradeKeys,
        (grades, grade) => {
          if (gradeSelections[grade] === key) grades.push(grade);
          return grades;
        },
        [],
      );
      const assessmentsToUpdate: ISchoolAssessmentUpdateOption[] = [];
      const assessmentsToCreate: ISchoolAssessmentCreateOption[] = [];

      // tslint ignore required due to TS bug with FormArrays,
      // see https://stackoverflow.com/questions/49395203/iterate-angular-2-formarray (JE)
      /* tslint:disable:no-string-literal */
      const adminControls = assessmentFormGroup.controls;
      forEach(adminControls, (adminControl: FormGroup, index) => {
        const adminNumber = parseInt(index) + 1;
        // check if admin already exists
        const match = find(this.schoolAssessments, (schoolAssessment: ISchoolAssessment) => {
          const indexMatch = schoolAssessment.adminNumber === adminNumber;
          const nameMatch = schoolAssessment.assessment.assessmentNickName === key;
          return nameMatch && indexMatch;
        });
        // if admin already exists, patch it
        if (match) {
          const options = { match, assessmentGradesMap, key, adminControl, finalize };
          const payload = this._constructUpdatePayload(options);
          // only add admin to payload if metadata has changed
          const hasChanges = payload.startDate || payload.endDate || payload.gradeLevels || payload.status;
          if (hasChanges) assessmentsToUpdate.push(payload);
        } else {
          // handles for added, unsaved assessments being finalized
          const status = finalize ? 'LOCKED' : 'OPEN';
          // payload has the shape [{adminNumber: Int!, startDate: String, endDate: String, gradeLevels: [String], status: String}]
          const assessment: ISchoolAssessmentCreateOption = {
            adminNumber,
            startDate: adminControl.controls.startDate.value,
            endDate: adminControl.controls.endDate.value,
            gradeLevels,
            status,
          };
          assessmentsToCreate.push(assessment);
        }
      });
      const bulkCreatePayload = {
        schoolId: this.currentSchool._id,
        payload: assessmentsToCreate,
        assessmentKey: SCHOOL_ASSESSMENTS_KEY_MAP[key],
      };
      const bulkUpdatePayload = {
        payload: assessmentsToUpdate,
      };
      if (assessmentsToCreate.length) this.dispatchBulkCreateAssessment(bulkCreatePayload);
      if (assessmentsToUpdate.length) this.dispatchBulkUpdateAssessment(bulkUpdatePayload);
    });
    this.enableSaveDraft = false;
  }

  dispatchBulkUpdateAssessment (updates: { payload: ISchoolAssessmentUpdateOption[] }): void {
    this.store.dispatch(new BulkUpdateSchoolAssessments(updates));
  }

  dispatchBulkCreateAssessment (payload: {
    schoolId: string;
    payload: ISchoolAssessmentCreateOption[];
    assessmentKey: string;
  }): void {
    this.store.dispatch(new BulkCreateSchoolAssessments(payload));
  }

  finalizeAssessments (): void {
    const data = {
      title: 'Finalize Assessments?',
      message:
        "Once assessments have been finalized, you will not be able to make changes to your school's administration plan",
      confirmText: 'Finalize',
    };
    this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .subscribe((confirmed: false | true) => {
        if (confirmed) {
          this.saveDraft(true);
          this.assessmentsFinalized = true;
        }
      });
  }

  isFormValid (): boolean {
    let valid = true;
    let assessmentAssignedToGrade = false;
    const { assessmentKeys, assessmentGradesMap } = this._parseAssessmentsForm(this.assessmentsForm);
    // check if admins have date range overlaps
    forEach(assessmentKeys, key => {
      // check if assessment has been assigned to any grade
      if (assessmentGradesMap[key].length > 0) assessmentAssignedToGrade = true;
      // formArray represents a single assessment such as F & P, with many admins
      const formArray = this.assessmentsForm.controls[key] as FormArray;
      const admins = map(formArray.controls, (adminControl: FormGroup) => {
        const {
          controls: { startDate, endDate },
        } = adminControl;
        // check for unassigned start/end dates
        if (!startDate.value || !endDate.value) valid = false;
        return { startDate: new Date(startDate.value), endDate: new Date(endDate.value) };
      });
      for (let i = 0; i < admins.length - 1; i++) {
        const current = admins[i];
        const next = admins[i + 1];
        if (current.endDate > next.startDate) {
          valid = false;
        }
      }
    });

    // check that some grade has been assisnged to some assessment
    if (!assessmentAssignedToGrade) valid = false;
    return valid;
  }

  _parseAssessmentsForm (form: FormGroup) {
    const gradeSelections = form.controls['Grade Selections'].value;
    const assessmentKeys = Object.keys(form.controls).filter(control => control !== 'Grade Selections');
    const assessmentsMap = reduce(
      assessmentKeys,
      (sum, key) => {
        sum[key] = [];
        return sum;
      },
      {},
    );
    // creates an object with assessment names as keys and arrays of grades as values (JE)
    const assessmentGradesMap = reduce(
      Object.keys(gradeSelections),
      (sum, grade) => {
        const assmntForGrade = gradeSelections[grade];
        if (assmntForGrade) sum[assmntForGrade] = [...sum[assmntForGrade], grade];
        return sum;
      },
      assessmentsMap,
    );

    return { gradeSelections, assessmentKeys, assessmentGradesMap };
  }

  _constructUpdatePayload (options: {
    match: ISchoolAssessment;
    assessmentGradesMap;
    key: string;
    adminControl: FormGroup;
    finalize: boolean;
  }): ISchoolAssessmentUpdateOption {
    const { match, assessmentGradesMap, key, adminControl, finalize } = options;
    const payload: ISchoolAssessmentUpdateOption = {
      schoolAssessmentId: match._id,
    };
    const { gradeLevels } = match;
    const gradeLevelsChanged = !isEqual(gradeLevels, assessmentGradesMap[key]);
    if (gradeLevelsChanged) payload.gradeLevels = assessmentGradesMap[key];

    const { startDate, endDate } = adminControl.controls;
    const formStart = startDate.value;
    const formEnd = endDate.value;
    if (match.startDate !== formStart) payload.startDate = formStart;
    if (match.endDate !== formEnd) payload.endDate = formEnd;
    if (finalize) payload.status = 'LOCKED';
    return payload;
  }

  public getHelp (): void {
    this.helpDeskService.showHelp();
  }
}
