import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS , MatDateFormats } from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/* istanbul ignore next */
@Component({
  selector: 'nv-date-picker-header',
  styleUrls: ['./nv-date-picker-header.scss'],
  templateUrl: './nv-date-picker-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NvDatePickerHeaderComponent<D> implements OnDestroy {
  public destroy$: Subject<any> = new Subject<any>();
  public lastMonthActive: boolean;
  public nextMonthActive: boolean;
  public minDate;
  public maxDate;

  constructor (
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit () {
    this.calendar.stateChanges.pipe(takeUntil(this.destroy$)).subscribe(() => this.cdr.markForCheck());
    const { minDate, maxDate } = this.calendar;
    this.minDate = minDate ? new Date(minDate.toString()) : null;
    this.maxDate = maxDate ? new Date(maxDate.toString()) : null;
    this.updateArrows();
  }

  ngOnDestroy () {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  get monthLabel () {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel);
  }

  previousClicked () {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, -1);
    this.updateArrows();
  }

  nextClicked () {
    this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, 1);
    this.updateArrows();
  }

  updateArrows () {
    const { activeDate } = this.calendar;
    const today = new Date(activeDate.toString());
    const currYear = today.getFullYear();
    const currMonth = today.getMonth();
    if (this.minDate) {
      const minYear = this.minDate.getFullYear();
      const minMonth = this.minDate.getMonth();
      if (minYear < currYear) this.lastMonthActive = true;
      else if (minYear === currYear) this.lastMonthActive = minMonth < currMonth;
    } else this.lastMonthActive = true;

    if (this.maxDate) {
      const maxYear = this.maxDate.getFullYear();
      const maxMonth = this.maxDate.getMonth();
      if (maxYear > currYear) this.nextMonthActive = true;
      else if (maxYear === currYear) this.nextMonthActive = maxMonth > currMonth;
    } else this.nextMonthActive = true;
  }
}
