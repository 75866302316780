import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { LogOutComponent } from './log-out.component';

@NgModule({
  declarations: [LogOutComponent],
  imports: [MatListModule],
  exports: [LogOutComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LogOutModule {}
