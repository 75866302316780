import { getSchool } from 'Src/ng2/store';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { TValidSdcColumns } from './../../sdc-columns.type';
import { SdcStateServiceBaseModel } from './../sdc-state-basemodel';
import { sdcStateConfigConstant } from './../sdc-state-config.constant';
import { tap, take } from 'rxjs/operators';

@Injectable()
export class CreditGapsConfigService extends SdcStateServiceBaseModel {
  constructor (
    private ImSchool: ImSchool,
    private store: Store<any>,
  ) {
    super();
  }

  get humanName () {
    return sdcStateConfigConstant.CREDIT_GAPS.humanName;
  }

  get state () {
    return sdcStateConfigConstant.CREDIT_GAPS.state;
  }

  get gridColumns () {
    return _.difference(this.columns, this.hiddenColumns);
  }

  get columns (): TValidSdcColumns[] {
    const fullColumns = [
      'studentId',
      'studentName',
      'cohort',
      'seniorFlag',
      'isSuperSenior',
      'status',
      'admitDate',
      'dischargeCode',
      'dischargeDate',
      'creditGapPriorityGrouping',
      'grade',
      'officialClass',

      // google classroom
      'classroomRemoteEngagementLast5',
      'classroomRemoteEngagement',
      'classroomTotalAssignments',
      'classroomTotalEngagedAssignments',
      'classroomTotalEnrolled',
      'classroomStudentEmail',

      'atsCounselor',
      'advisor',
      'iep',
      'ell',
      'homeLanguage',
      'attendanceYtd',
      'plannedGraduationDate',
      'gradPlan',
      'currentCourses',
      'currentCourseCodes',
      'totalCreditsEarned',
      'subjectsWithCreditGaps',
      'creditMaxGaps',
      'creditMaxGapsNoPlan',
      'coursesPotentiallyEligibleForCreditRecovery',
      'coursesPotentialCreditIssues',
      'countOfCreditGapPendingChanges',
      'pendingCourseAdditions',
      'pendingCourseAdditionsWithoutSection',
      'pendingCourseDrops',
      'gapPlansSummary',
      'hasPotentialCreditIssuesOnCurrProgram',
      'hasPotentialCreditIssuesOnTranscript',
      'hasGapPlansPastPlannedGradDate',
      'countOfRegentsPassedForDiploma',
      'countOfRegentsPassedForGrad',
      'countOfRegentsPassedAt65Of5',
      'countOfRegentsPassedAt55Of5SpedOnly',
      'countOfRegentsPassedAt65Of9',
      'creditGapsStartTotal',
      'creditGapsTotal',
      'creditGapsStartElaCore',
      'creditGapsElaCore',
      'creditGapsStartMath',
      'creditGapsMath',
      'creditGapsStartSsGlobal',
      'creditGapsSsGlobal',
      'creditGapsStartSsUs',
      'creditGapsSsUs',
      'creditGapsStartSsGovtEcon',
      'creditGapsSsGovtEcon',
      'creditGapsStartSciTotal',
      'creditGapsSciTotal',
      'creditGapsStartSciPhysical',
      'creditGapsSciPhysical',
      'creditGapsStartSciLife',
      'creditGapsSciLife',
      'creditGapsStartLote',
      'creditGapsLote',
      'creditGapsStartCte',
      'creditGapsCte',
      'creditGapsStartArt',
      'creditGapsArt',
      'creditGapsStartPe',
      'creditGapsPe',
      'creditGapsStartHealth',
      'creditGapsHealth',

      // added for community schools on 3/22
      'schoolPriorityFlag',
    ];

    // based on toggle return either or
    return fullColumns as TValidSdcColumns[];
  }

  get hiddenColumns (): TValidSdcColumns[] {
    return ['isSuperSenior'];
  }

  get gridViews () {
    return {};
  }

  get filter () {
    const school = this.getSchool();

    const filter = {
      status: { values: ['A'] },
    };

    // Transfer schools should not be filtered by cohort
    const isTransfer = this.ImSchool.isTransferSchool(school);
    if (isTransfer) return filter;

    const seniorAndSuperCohorts = this.ImSchool.getSeniorAndSuperCohorts(school);

    let mostSeniorCohort;
    if (seniorAndSuperCohorts.length === 0) {
      mostSeniorCohort = _.min(school.uniqueCohorts);
    }

    // Otherwise return either senior + super senior cohorts, or the single most senior cohort
    const cohort = _.size(seniorAndSuperCohorts) ? seniorAndSuperCohorts : [mostSeniorCohort];

    (filter as any).cohort = { values: cohort };
    return filter;
  }

  get sort () {
    return [
      {
        colId: 'creditMaxGapsNoPlan',
        sort: 'desc',
      },
    ];
  }

  get bundleName () {
    return 'CREDIT_GAPS';
  }

  get availableSdcViews () {
    return sdcStateConfigConstant.CREDIT_GAPS.availableSdcViews;
  }

  getSchool () {
    let school;
    this.store.select(getSchool).pipe(
      tap(storeSchool => (school = storeSchool)), take(1)).subscribe();
    return school;
  }
}
