import { distinctUntilChanged } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IHistoryLogsFilterOptions, TDocLogCategory } from 'Src/ng2/shared/typings/interfaces/docLogs.interface';
import { IOptionClickedEvent } from 'Src/nvps-libraries/design/nv-multi-picker/nv-multi-picker.component';
import { IPickerOption } from 'Src/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';

@unsubscribeComponent
@Component({
  selector: 'filter-logs-options',
  templateUrl: './filter-logs-options.component.html',
  styleUrls: ['./filter-logs-options.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class FilterLogsOptionsComponent implements OnInit, OnChanges {
  @Input() filterOptions: { categories: TDocLogCategory[]; users: { key: string; humanName: string }[] };
  @Input() userSelectedFilters: IHistoryLogsFilterOptions;
  @Output() selectedFilters = new EventEmitter<IHistoryLogsFilterOptions>();

  filterText: string = 'Filter';
  iconTextColor: string = 'blue';

  formattedCategories: IPickerOption[];
  formattedUsers: IPickerOption[];

  selectedCategories: TDocLogCategory[];
  selectedUsers: string[];
  disabledClearButton: boolean = true;
  disabledSaveButton: boolean = true;

  // Needed for date-range-picker
  public dateRangeForm: FormGroup;
  public startPlaceholder: string;
  public endPlaceHolder: string;

  constructor (
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit (): void {
    // Set form
    this._setDatePickerFilterForm({
      startDate: this.userSelectedFilters.startDate,
      endDate: this.userSelectedFilters.endDate,
    });
  }

  ngOnChanges (changes: SimpleChanges) {
    if (changes.filterOptions || changes.userSelectedFilters) {
      this._getAndSetUserSelectedFilter();
    }
  }

  private _getAndSetUserSelectedFilter(): void {
    const { categories, users, startDate, endDate } = this.userSelectedFilters;
    const isValidDateFilter = this._isValidDateFilter();

    if(categories?.length > 0 || users?.length > 0 || isValidDateFilter ) {
      this.disabledClearButton = false;
      this.disabledSaveButton = false;
    }

    if(categories?.length === 0 && users?.length === 0 && !isValidDateFilter) {
      this.disabledClearButton = true;
      this.disabledSaveButton = true;
    }

    this._setDatePickerFilterForm({
      startDate: startDate,
      endDate: endDate,
    });

    this.selectedCategories = categories;
    this.selectedUsers = users;
    this._setText(this.userSelectedFilters);
  }

  private _setClearBtDisableStatusAndValidators (formValues: {startDate: string, endDate: string}):void {
    const { startDate, endDate } = formValues;
    const startDateControl = this.dateRangeForm.get('startDate');
    const endDateControl = this.dateRangeForm.get('endDate');
    const isValidDateFilter = this._isValidDateFilter();
    const hasSelectedCategories = this.selectedCategories?.length > 0;
    const hasSelectedUsers = this.selectedUsers?.length > 0;

    if (startDate && !endDate) {
      this.disabledClearButton = true;
      this.disabledSaveButton = true;
      endDateControl.addValidators([Validators.required]);
      endDateControl.updateValueAndValidity();
    } 
    
    if (endDate && !startDate) {
      this.disabledClearButton = true;
      this.disabledSaveButton = true;
      startDateControl.addValidators([Validators.required]);
      startDateControl.updateValueAndValidity();
    } 

    if( isValidDateFilter ) {  
      this.disabledClearButton = false;
      this.disabledSaveButton = false;
    }

    if((hasSelectedCategories || hasSelectedUsers) && isValidDateFilter ) {  
      this.disabledClearButton = false;
      this.disabledSaveButton = false;
    }
  }

  public onFilter (): void {
    const { startDate, endDate } = this.dateRangeForm.value;
    const userFilters = {
      categories: this.selectedCategories,
      users: this.selectedUsers,
      startDate: startDate,
      endDate: endDate,
    };
    this.selectedFilters.emit(userFilters);
    this._setText(userFilters);
  }

  public resetSelectedFilters (): void {
    const defaultUserFilters = {
      categories: [],
      users: [],
      startDate: null,
      endDate: null,
    };
    this.selectedFilters.emit(defaultUserFilters);
    this.selectedCategories = [];
    this.selectedUsers = [];
    this._setDatePickerFilterForm({
      startDate: defaultUserFilters.startDate,
      endDate: defaultUserFilters.endDate,
    });
    this._setText(defaultUserFilters);
  }

  private _setDatePickerFilterForm ({ startDate, endDate }): void {
    const expectedDateRegex = /^\d{4}-\d{2}-\d{2}$/;
    this.startPlaceholder = 'Start';
    this.endPlaceHolder = 'End';

    this.dateRangeForm = this.formBuilder.group({
      startDate: [ startDate || null,
        {
          validators: [Validators.pattern(expectedDateRegex)],
        },
      ],
      endDate: [ endDate || null,
        {
          validators: [Validators.pattern(expectedDateRegex)],
        },
      ],
    });

    const startDateControl = this.dateRangeForm.get('startDate');
    const endDateControl = this.dateRangeForm.get('endDate');

    if(startDate) {
      endDateControl.addValidators(Validators.required);
      this.disabledClearButton = false;

    } 
    
    if(endDate) {
      startDateControl.addValidators(Validators.required);
      this.disabledClearButton = false;
    }

    // Whatch for changes in the date picker
    this.dateRangeForm.valueChanges.pipe(
      distinctUntilChanged((a,b ) => JSON.stringify(a) === JSON.stringify(b))
      ).subscribe(
        formValues => this._setClearBtDisableStatusAndValidators(formValues),
      );
  }

  public onCategoriesOptionClicked (e: IOptionClickedEvent): void {
    const value = e.value;
    const isUserFilterSelected = this.selectedUsers.length > 0;

    this._setClearAndSaveDisableState (value, isUserFilterSelected);
  }

  public onUsersOptionClicked (e: IOptionClickedEvent): void {
    const value = e.value;
    const isCategoriesFilterSelected = this.selectedCategories.length > 0;

    this._setClearAndSaveDisableState (value, isCategoriesFilterSelected);
  }

  private _setClearAndSaveDisableState (value: string, isOtherFilerSelected: boolean): void {
    if (value === 'clear') {
      if(isOtherFilerSelected && this._isValidDateFilter() || isOtherFilerSelected && this._isDateFiltersNull()) {
        this.disabledClearButton = false;
        this.disabledSaveButton = false;
      }
    } else {
      if(this._isValidDateFilter() || this._isDateFiltersNull()){
        this.disabledClearButton = false;
        this.disabledSaveButton = false;
      }
    }
  }

  private _setText (userFilters: IHistoryLogsFilterOptions): void {
    const count = this._getCountOfUserSelectedFilters(userFilters);
    let hasUserFilters = false;

    if (count > 0) {
      hasUserFilters = true;
    }

    this.iconTextColor = hasUserFilters ? 'purple' : 'blue';
    this.filterText = this._getFilterText(count);
  }

  private _getCountOfUserSelectedFilters(userFilters: IHistoryLogsFilterOptions): number {
    let count = 0;
    if (userFilters?.categories?.length > 0) count++;
    if (userFilters?.users?.length > 0) count++;
    if (userFilters?.startDate && userFilters?.endDate) count++;

    return count;
  }

  private _getFilterText (count: number): string {
    let filterText = '';
    if (count === 0) filterText = 'Filter';
    if (count === 1) filterText = '1 Filter';
    if (count > 1) filterText = `${count} Filters`;

    return filterText;
  }

  private _isValidDateFilter(): boolean {
    const { startDateValue, endDateValue } = this._getCurrentDateFormValues();

    if ((startDateValue && endDateValue)) return true;
    return false;
  }

  private _isDateFiltersNull(): boolean {
    const { startDateValue, endDateValue } = this._getCurrentDateFormValues();

    if ((!startDateValue && !endDateValue)) return true;
    return false;
  }

  private _getCurrentDateFormValues(): { startDateValue: string, endDateValue: string } {
    const startDateControl = this.dateRangeForm?.get('startDate');
    const endDateControl = this.dateRangeForm?.get('endDate');
    const startDateValue = startDateControl?.value;
    const endDateValue = endDateControl?.value;
    return { startDateValue, endDateValue };
  }

  onCancel (): void {
    this.selectedFilters.emit(this.userSelectedFilters);
    this._getAndSetUserSelectedFilter();
  }
}
