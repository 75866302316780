import { PortalSwitcherModule } from './../../shared/components/portal-switcher/portal-switcher.module';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NvSidebarItemModule } from 'Src/nvps-libraries/design/nv-sidebar-item/nv-sidebar-item.module';
import { NvSidebarListModule } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar-list.module';
import { ShelterSideNavComponent } from './shelter-side-nav.component';
import { NvLogoModule } from 'Src/nvps-libraries/design/nv-logo/nv-logo.module';
import { RouterModule } from '@angular/router';
import { LogOutModule } from 'Src/ng2/shell/log-out/log-out.module';

@NgModule({
  declarations: [ShelterSideNavComponent],
  imports: [CommonModule, RouterModule, LogOutModule, NvSidebarListModule, NvSidebarItemModule, PortalSwitcherModule, NvLogoModule],
  exports: [ShelterSideNavComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ShelterSideNavModule {}
