export const MILESTONE_PROJECTION = {
  _id: true,
  studentId: true,
  key: true,
  dueAt: true,
  status: true,
  isCollegeTrack: true,
  isCareerTrack: true,
};

export const MILESTONE_JOINS = [];
