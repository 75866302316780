import { Component, ViewEncapsulation } from '@angular/core';
import { Auth } from './../../shared/auth/auth.service';
@Component({
  selector: 'nvps-log-out',
  templateUrl: './log-out.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class LogOutComponent {
  constructor (private auth: Auth) {}

  logOut (): void {
    this.auth.logout();
  }
}
