/*
  WARNING: Don't edit theses statuses willy-nilly.
*/
export enum StudentPathStatuses {
  PLANS_TO_APPLY = 'Plans to apply',
  APPLIED = 'Applied',
  APPLIED_WITH_SPECIAL_OPP = 'Applied w. special opportunity',
  WAITLISTED = 'Waitlisted',
  DENIED = 'Denied',
  ACCEPTED = 'Accepted',
  ACCEPTED_WITH_SPECIAL_OPP = 'Accepted w. special opportunity',
  COMMITTED = 'Committed',
  COMMITTED_WITH_SPECIAL_OPP = 'Committed w. special opportunity',
  DEFAULT = 'Plans to apply',
}

export const StudentPathCareerTypes = {
  JOB_TRAINING: 'Job training',
  CERTIFICATION_PROGRAM: 'Certification program',
  APPRENTICESHIP: 'Apprenticeship',
  MILITARY: 'Military',
  PUBLIC_SERVICE: 'Public service',
  OTHER: 'Other',
};

export const STUDENT_PATH_DEFAULT_STATUS = StudentPathStatuses.DEFAULT;

export const COLLEGE_PATH_STATUSES = {
  PLANS_TO_APPLY: StudentPathStatuses.PLANS_TO_APPLY,
  APPLIED: StudentPathStatuses.APPLIED,
  APPLIED_WITH_SPECIAL_OPP: StudentPathStatuses.APPLIED_WITH_SPECIAL_OPP,
  ACCEPTED: StudentPathStatuses.ACCEPTED,
  ACCEPTED_WITH_SPECIAL_OPP: StudentPathStatuses.ACCEPTED_WITH_SPECIAL_OPP,
  WAITLISTED: StudentPathStatuses.WAITLISTED,
  DENIED: StudentPathStatuses.DENIED,
  COMMITTED: StudentPathStatuses.COMMITTED,
  COMMITTED_WITH_SPECIAL_OPP: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
};

export const CAREER_PATH_STATUSES = {
  PLANS_TO_APPLY: StudentPathStatuses.PLANS_TO_APPLY,
  APPLIED: StudentPathStatuses.APPLIED,
  ACCEPTED: StudentPathStatuses.ACCEPTED,
  DENIED: StudentPathStatuses.DENIED,
  COMMITTED: StudentPathStatuses.COMMITTED,
};

export const STUDENT_PATH_APPLIED_STATUSES = {
  APPLIED: StudentPathStatuses.APPLIED,
  APPLIED_WITH_SPECIAL_OPP: StudentPathStatuses.APPLIED_WITH_SPECIAL_OPP,
  WAITLISTED: StudentPathStatuses.WAITLISTED,
  DENIED: StudentPathStatuses.DENIED,
  ACCEPTED: StudentPathStatuses.ACCEPTED,
  ACCEPTED_WITH_SPECIAL_OPP: StudentPathStatuses.ACCEPTED_WITH_SPECIAL_OPP,
  COMMITTED: StudentPathStatuses.COMMITTED,
  COMMITTED_WITH_SPECIAL_OPP: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
};

export const STUDENT_PATH_RESPONSE_STATUSES = {
  WAITLISTED: StudentPathStatuses.WAITLISTED,
  DENIED: StudentPathStatuses.DENIED,
  ACCEPTED: StudentPathStatuses.ACCEPTED,
  ACCEPTED_WITH_SPECIAL_OPP: StudentPathStatuses.ACCEPTED_WITH_SPECIAL_OPP,
  COMMITTED: StudentPathStatuses.COMMITTED,
  COMMITTED_WITH_SPECIAL_OPP: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
};

export const STUDENT_PATH_ACCEPTANCE_STATUSES = {
  ACCEPTED: StudentPathStatuses.ACCEPTED,
  ACCEPTED_WITH_SPECIAL_OPP: StudentPathStatuses.ACCEPTED_WITH_SPECIAL_OPP,
  COMMITTED: StudentPathStatuses.COMMITTED,
  COMMITTED_WITH_SPECIAL_OPP: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
};

export const STUDENT_PATH_FINAL_DECISION_STATUSES = {
  COMMITTED: StudentPathStatuses.COMMITTED,
  COMMITTED_WITH_SPECIAL_OPP: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
};

export const STUDENT_PATH_GROUP = {
  NEED_TO_APPLY: 'Need to apply',
  NEED_FINAL_DECISION: 'Need final decision',
  COMMITTED: 'Committed',
};

export const SPEC_OPP_HUMAN_MAP = {
  'Applied w. spec. opp.': COLLEGE_PATH_STATUSES.APPLIED_WITH_SPECIAL_OPP,
  'Accepted w. spec. opp.': COLLEGE_PATH_STATUSES.ACCEPTED_WITH_SPECIAL_OPP,
  'Committed w. spec. opp.': COLLEGE_PATH_STATUSES.COMMITTED_WITH_SPECIAL_OPP,
};

export const SPEC_OPP_KEY_MAP = {
  'Applied w. special opportunity': 'Applied w. spec. opp.',
  'Accepted w. special opportunity': 'Accepted w. spec. opp.',
  'Committed w. special opportunity': 'Committed w. spec. opp.',
};

export enum PATH_CATEGORY_KEYS {
  CAREER_TRAINING_PROGRAM = 'careerTrainingProgram',
  CERTIFICATE_PROGRAM = 'certificateProgram',
  COLLEGE = 'college',
  EMPLOYMENT = 'employment',
  MILITARY = 'military',
  OTHER = 'other',
  PUBLIC_SERVICE = 'publicService'
}

export enum PATH_CATEGORY_HUMAN {
  CAREER_TRAINING_PROGRAM = 'Career training program',
  CERTIFICATE_PROGRAM = 'Certificate program',
  COLLEGE = 'College',
  EMPLOYMENT = 'Employment',
  MILITARY = 'Military',
  OTHER = 'Other',
  PUBLIC_SERVICE = 'Public service',
};

export enum FIELD_KEYS {
  AREA_OF_STUDY = 'areaOfStudy',
  ORGANIZATION = 'organization',
  SPECIALTY_AREA = 'specialtyArea',
  TRANSITION_SUPPORT_ORG = 'transitionSupportOrg',
  PROGRAM = 'program',
  COLLEGE = 'college',
  CITY = 'city',
  STATE = 'state',
  EMPLOYER_INDUSTRY = 'employerIndustry',
  EMPLOYER = 'employer',
  JOB_TYPE = 'jobType',
  JOB_STATUS = 'jobStatus',
  MILITARY_BRANCH = 'militaryBranch',
  OTHER = 'other',
  TYPE_OF_PLACEMENT = 'typeOfPlacement'
}

export enum FIELD_HUMAN {
  AREA_OF_STUDY = 'Area of study',
  ORGANIZATION = 'Organization',
  ORGANIZATION_OR_COLLEGE = 'Organization or college',
  SPECIALTY_AREA = 'Specialty area',
  TRANSITION_SUPPORT_ORG = 'Transition support organization',
  PROGRAM = 'Program',
  COLLEGE = 'College',
  CITY = 'City',
  STATE = 'State',
  EMPLOYER_INDUSTRY = 'Employer industry',
  EMPLOYER = 'Employer',
  JOB_TYPE = 'Job type',
  JOB_STATUS = 'Job status',
  MILITARY_BRANCH = 'Military branch',
  OTHER = 'Other',
  TYPE_OF_PLACEMENT = 'Type of placement'
}

interface IFieldConfig {
  human: string,
  isRequired: boolean,
  isSearchable?: boolean,
  includeInListName: boolean,
  includeInListTooltip: boolean,
}
export interface IFields {
  [field: string]: IFieldConfig;
}

export interface IPathCategoryConfig {
  [pathCategory: string]: {
    human: string;
    fields: IFields;
  }
}

export const PathCatKeyToPathCatConfig: IPathCategoryConfig = {
  [PATH_CATEGORY_KEYS.CAREER_TRAINING_PROGRAM]: {
    human: PATH_CATEGORY_HUMAN.CAREER_TRAINING_PROGRAM,
    fields: {
      [FIELD_KEYS.ORGANIZATION]: {
        human: FIELD_HUMAN.ORGANIZATION,
        isRequired: false,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.AREA_OF_STUDY]: {
        human: FIELD_HUMAN.AREA_OF_STUDY,
        isRequired: true,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.SPECIALTY_AREA]: {
        human: FIELD_HUMAN.SPECIALTY_AREA,
        isRequired: false,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.TRANSITION_SUPPORT_ORG]: {
        human: FIELD_HUMAN.TRANSITION_SUPPORT_ORG,
        isRequired: false,
        isSearchable: true,
        includeInListName: false,
        includeInListTooltip: true,
      },
    },
  },
  [PATH_CATEGORY_KEYS.CERTIFICATE_PROGRAM]: {
    human: PATH_CATEGORY_HUMAN.CERTIFICATE_PROGRAM,
    fields: {
      [FIELD_KEYS.ORGANIZATION]: {
        human: FIELD_HUMAN.ORGANIZATION_OR_COLLEGE,
        isRequired: false,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.PROGRAM]: {
        human: FIELD_HUMAN.PROGRAM,
        isRequired: false,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.AREA_OF_STUDY]: {
        human: FIELD_HUMAN.AREA_OF_STUDY,
        isRequired: true,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.TRANSITION_SUPPORT_ORG]: {
        human: FIELD_HUMAN.TRANSITION_SUPPORT_ORG,
        isRequired: false,
        isSearchable: true,
        includeInListName: false,
        includeInListTooltip: true,
      },
    },
  },
  [PATH_CATEGORY_KEYS.COLLEGE]: {
    human: PATH_CATEGORY_HUMAN.COLLEGE,
    fields: {
      [FIELD_KEYS.COLLEGE]: {
        human: FIELD_HUMAN.COLLEGE,
        isRequired: true,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.CITY]: {
        human: FIELD_HUMAN.CITY,
        isRequired: false,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: false,
      },
      [FIELD_KEYS.STATE]: {
        human: FIELD_HUMAN.STATE,
        isRequired: false,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: false,
      },
      [FIELD_KEYS.TRANSITION_SUPPORT_ORG]: {
        human: FIELD_HUMAN.TRANSITION_SUPPORT_ORG,
        isRequired: false,
        isSearchable: true,
        includeInListName: false,
        includeInListTooltip: true,
      },
    },
  },
  [PATH_CATEGORY_KEYS.EMPLOYMENT]: {
    human: PATH_CATEGORY_HUMAN.EMPLOYMENT,
    fields: {
      [FIELD_KEYS.EMPLOYER]: {
        human: FIELD_HUMAN.EMPLOYER,
        isRequired: false,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.EMPLOYER_INDUSTRY]: {
        human: FIELD_HUMAN.EMPLOYER_INDUSTRY,
        isRequired: true,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.JOB_TYPE]: {
        human: FIELD_HUMAN.JOB_TYPE,
        isRequired: false,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.JOB_STATUS]: {
        human: FIELD_HUMAN.JOB_STATUS,
        isRequired: false,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.TRANSITION_SUPPORT_ORG]: {
        human: FIELD_HUMAN.TRANSITION_SUPPORT_ORG,
        isRequired: false,
        isSearchable: true,
        includeInListName: false,
        includeInListTooltip: true,
      },
    },
  },
  [PATH_CATEGORY_KEYS.MILITARY]: {
    human: PATH_CATEGORY_HUMAN.MILITARY,
    fields: {
      [FIELD_KEYS.MILITARY_BRANCH]: {
        human: FIELD_HUMAN.MILITARY_BRANCH,
        isRequired: true,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.JOB_TYPE]: {
        human: FIELD_HUMAN.JOB_TYPE,
        isRequired: false,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
    },
  },
  [PATH_CATEGORY_KEYS.PUBLIC_SERVICE]: {
    human: PATH_CATEGORY_HUMAN.PUBLIC_SERVICE,
    fields: {
      [FIELD_KEYS.PROGRAM]: {
        human: FIELD_HUMAN.PROGRAM,
        isRequired: true,
        isSearchable: true,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.TYPE_OF_PLACEMENT]: {
        human: FIELD_HUMAN.TYPE_OF_PLACEMENT,
        isRequired: false,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.TRANSITION_SUPPORT_ORG]: {
        human: FIELD_HUMAN.TRANSITION_SUPPORT_ORG,
        isRequired: false,
        isSearchable: true,
        includeInListName: false,
        includeInListTooltip: true,
      },
    },
  },
  [PATH_CATEGORY_KEYS.OTHER]: {
    human: PATH_CATEGORY_HUMAN.OTHER,
    fields: {
      [FIELD_KEYS.OTHER]: {
        human: FIELD_HUMAN.OTHER,
        isRequired: true,
        isSearchable: false,
        includeInListName: true,
        includeInListTooltip: true,
      },
      [FIELD_KEYS.TRANSITION_SUPPORT_ORG]: {
        human: FIELD_HUMAN.TRANSITION_SUPPORT_ORG,
        isRequired: false,
        isSearchable: true,
        includeInListName: false,
        includeInListTooltip: true,
      },
    },
  },
};

export enum EmployerIndustry {
  BUSINESS = 'Business',
  HEALTH_AND_MEDICINE = 'Health & Medicine',
  SKILLED_TRADES_MANUFACTURING_TRANSPORTATION_AND_GREEN_CAREERS = 'Skilled Trades',
  BIOLOGY_AGRICULTURE_AND_ENVIRONMENT = 'Biology, Agriculture & Environment',
  PHYSICAL_SCIENCES_AND_ENGINEERING = 'Physical Sciences & Engineering',
  MATH_TECHNOLOGY_AND_COMPUTERS = 'Math, Technology & Computers',
  LAW_AND_PROTECTIVE_SERVICES = 'Law & Protective Services',
  COMMUNICATIONS_AND_INFORMATION = 'Communications & Information',
  EDUCATION = 'Education',
  CREATIVE_ARTS_AND_DESIGN = 'Creative Arts & Design',
  SERVICE_INDUSTRY = 'Service Industry',
  LIBERAL_ARTS_AND_SOCIAL_SCIENCES = 'Liberal Arts & Social Sciences',
  SOCIAL_SERVICES = 'Social Services',
  OTHER = 'Other'
}

export enum JobStatus {
  PART_TIME = 'Part-Time',
  FULL_TIME = 'Full-Time',
  VOLUNTEER = 'Volunteer',
  INTERN = 'Intern'
}

export enum MilitaryBranch {
  ARMY = 'Army',
  MARINE_CORPS = 'Marine Corps',
  NAVY = 'Navy',
  AIR_FORCE = 'Air Force',
  SPACE_FORCE = 'Space Force',
  COAST_GUARD = 'Coast Guard',
  NATIONAL_GUARD = 'National Guard'
}

export enum TypeOfPlacement {
  PAID = 'Paid',
  UNPAID = 'Unpaid'
}
