import { createFeatureSelector, createSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IPartnerOrgsState } from './../reducers/partner-orgs-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getPartnerOrgsState = createSelector(
  getPortalState,
  (state: any) => {
    return state.partnerOrgs;
  },
);

export const getPartnerOrgsEntities = createSelector(
  getPartnerOrgsState,
  (state: IPartnerOrgsState) => {
    return state.partnerOrgsEntities;
  },
);

export const getPartnerOrgsEntitiesList = createSelector(
  getPartnerOrgsState,
  (state: IPartnerOrgsState) => {
    const partnerOrgsEntities = state.partnerOrgsEntities;
    return map(partnerOrgsEntities, (v, k) => v);
  },
);

export const getPartnerOrgsLoadingStatus = createSelector(
  getPartnerOrgsState,
  (state: IPartnerOrgsState) => {
    return state.loading;
  },
);

export const getPartnerOrgsLoadedStatus = createSelector(
  getPartnerOrgsState,
  (state: IPartnerOrgsState) => {
    return state.loaded;
  },
);
