import { ModalsService } from './../../../modals/modals.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, Unsubscribable, of } from 'rxjs';
import { debounceTime, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { getBatchActionsSelectedStudentIds } from '../../../../store';
import { collapseOutAni } from '../../../animations/collapse-out.animation';
import { expandInAni } from '../../../animations/expande-in.animation';
import { IColumnStuLvl, IGroupData, IRowData, TList } from '../../../models/list-models';
import { BatchEditService } from '../../../services/batch-edit-service/batch-edit-service';
import { CellDisplayService } from '../../../services/list-services/cell-display.service';
import { HeaderService, IDisplayedHeader } from '../../../services/list-services/header.service';
import { SortAndFilterService, TSortDirections } from '../../../services/list-services/sort-and-filter.service';
import { IUser } from '../../../typings/interfaces/user.interface';
import { IRowDataWithBatchActions } from '../fixed-table/fixed-table.component';
import { IHistoryModalData, THistoryLogsComponent } from 'Src/ng2/shared/modals/history-all/history-all-modal.component';

/* istanbul ignore next */
@Component({
  selector: 'infinite-table',
  templateUrl: './infinite-table.component.html',
  styleUrls: ['./infinite-table.component.scss'],
  animations: [expandInAni(), collapseOutAni()],
  encapsulation: ViewEncapsulation.None,
  providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } }],
})
export class InfiniteTableComponent implements OnInit, AfterViewInit {
  // REQUIRED bindings if used along with FixedToInfiniteViewComponent
  @Input() schoolId: string;
  @Input() shelterId: string;
  @Input() madlibModel: any;
  @Input() groupData$: Observable<IGroupData[]>;
  @Input() sortKey$: BehaviorSubject<string>;
  @Input() sortDirection$: BehaviorSubject<TSortDirections>;
  @Input() columns: IColumnStuLvl[];
  @Input() filterFormControl: FormControl;
  @Input() batchActionsMode$: Observable<boolean>;
  @Input() columnIndexMap: { [key: string]: number };
  @Input() listConfig: any;
  @Input() dynamicComponentInputData: any;
  @Output() collapseOutDone = new EventEmitter<{
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
  }>();

  @Output() clickedRowData = new EventEmitter<IRowData[]>();
  @Output() batchActionData = new EventEmitter<any>();
  @Output() uiRowData = new EventEmitter<[IGroupData]>();

  // OPTIONAL bindins
  @Input() batchActionsSelectedIds$: Observable<string[]> = null;
  @Input() currentUser: IUser;
  @Output() onDynamicComponentClick = new EventEmitter<any>(); // this
  // @Output() dynamicComponentClicked = new EventEmitter<any>(); // or this
  @Output() sortEmitter = new EventEmitter<string>();

  // available after view init
  @ViewChild(CdkVirtualScrollViewport, { static: false }) scrollViewport: CdkVirtualScrollViewport;

  // methods
  checkShadow: Function = HeaderService.checkHeaderShadow;
  getHeaderOverflow: Function = HeaderService.getHeaderOverflow;
  sortRowData: Function = SortAndFilterService.sortTableRowData;
  filterRows: Function = SortAndFilterService.filterRows;
  getDisplayedHeaders: Function = HeaderService.getDisplayedHeaders;

  // all other props
  aniState: string = 'expanded';
  showShadow$: Observable<boolean>;
  headerOverflow: boolean = false;
  currentFilterFormValue: string;
  noDataMessage: string;
  allowEmptyTable: boolean;
  sortableColumns: boolean = true;
  displayedHeaders: IDisplayedHeader[];
  dataSource$ = new BehaviorSubject<IRowDataWithBatchActions[] | IRowData[][]>([]);
  dataPipeline$: Observable<any>;
  sectionHeaderIsChecked: boolean = false;
  sectionHeaderIsInd: boolean;
  groupData: IGroupData;
  dataPipelineSub: Unsubscribable;
  isBatchActionActivated: boolean;
  batchActions$: Observable<any>;
  isOverrideActionClicked: boolean = false;

  constructor (private store: Store<any>, 
    private batchEditService: BatchEditService,
    private modalsService: ModalsService) {}

  ngOnInit (): void {
    this.currentFilterFormValue = this.filterFormControl.value || '';
    const { listType, noDataMessage, allowEmptyTable, sortableColumns } = this.listConfig;
    this.noDataMessage = noDataMessage;
    this.allowEmptyTable = allowEmptyTable;
    this.sortableColumns = sortableColumns;

    this.batchActions$ = combineLatest([
      this.batchActionsMode$,
      this.batchActionsSelectedIds$ || this.store.select(getBatchActionsSelectedStudentIds),
    ]).pipe(
      tap(([batchActionsMode, batchEditIds]) => {
        if (this.dataSource$.value.length) {
          this.setDataSourceWithBatchActions(batchActionsMode, batchEditIds, this.dataSource$.value);
        }
      }),
    );

    // update table on changes to rowdata, sort, or filter(JJ)
    this.dataPipeline$ = combineLatest(
      this.groupData$,
      this.filterFormControl.valueChanges.pipe(startWith(this.currentFilterFormValue)),
      this.sortKey$,
      this.sortDirection$,
    ).pipe(
      withLatestFrom(this.batchActions$),
      tap(([latestData, batchActions]) => {
        const [groupData, filterTerm, sortKey, sortDirection] = latestData;
        const [batchActionsMode, batchEditIds] = batchActions;
        const { rowData } = groupData;
        this.groupData = groupData;
        this.allowEmptyTable = groupData.allowEmptyTable;
        this.currentFilterFormValue = filterTerm;
        this.displayedHeaders = this.getDisplayedHeaders(this.columns, groupData, this.madlibModel?.value);
        const filteredRowData = this.filterRows({ filterTerm, listType, rowData });
        const sortKeyIndex = this.columnIndexMap[sortKey];
        const sortedRowData = this.sortableColumns && [...this.sortRowData(filteredRowData, sortKeyIndex, sortDirection, sortKey)];
        const rowDataToFormat = sortedRowData || filteredRowData;

        const formattedRowData = CellDisplayService.getFormattedRowData({
          listType,
          rowDataToFormat,
          columns: this.columns,
        });

        this.uiRowData.emit([{ ...this.groupData, ...{ rowData: formattedRowData } }]);

        this.setDataSourceWithBatchActions(batchActionsMode, batchEditIds, formattedRowData);
      }),
    );
    this.dataPipelineSub = this.dataPipeline$.subscribe();
  }

  private setDataSourceWithBatchActions (batchActionsMode, batchEditIds, formattedRowData) {
    // for keeping track of batchActionsMode$ without manual subscription across the component lifecycle
    this.isBatchActionActivated = batchActionsMode;
    const rowDataWithOptionalBatchActions = batchActionsMode
      ? this.getExistingBatchActionSelections(formattedRowData, batchEditIds)
      : formattedRowData;
    this.dataSource$.next(rowDataWithOptionalBatchActions);
  }

  public updateSort (sortKey: string): void {
    this.sortEmitter.emit(sortKey);
  }

  ngAfterViewInit (): void {
    if (this.scrollViewport) {
      this.showShadow$ = this.scrollViewport.elementScrolled().pipe(
        debounceTime(10),
        switchMap(e => of(this.checkShadow((e.target as HTMLElement).scrollTop))),
      );
    }
  }

  emitUiRowData (uiRowData: [IGroupData]): void {
    this.uiRowData.emit(uiRowData);
  }

  emitCollapseOutDone (): void {
    if (this.aniState === 'collapsed') {
      this.collapseOutDone.emit({
        groupData: this.groupData,
        sortKey: this.sortKey$.value,
        sortDirection: this.sortDirection$.value,
      });
    }
  }

  emitRowClick (rowData: IRowData[], dataColumn?): void {
    if (dataColumn && dataColumn.dynamic) return;

    if (this.isOverrideActionClicked) return;

    if (!this.isBatchActionActivated) {
      this.clickedRowData.emit(rowData);
    } else {
      this.emitBatchActionRowId(rowData);
    }
  }

  emitDynamicComponent (data: any): void {
    this.onDynamicComponentClick.emit(data);
  }

  onClear (): void {
    this.filterFormControl.setValue('');
  }

  // pull all this out
  getExistingBatchActionSelections (rowData: IRowData[][], batchEditIds: string[]): IRowDataWithBatchActions[] {
    const rowIdHash = this.batchEditService.getSelectedRowsHash(batchEditIds);
    const listType = this.listConfig.listType;
    const { mappedData, count } = this.batchEditService.getRowDataWithBatchActions(rowIdHash, rowData, listType);
    this.sectionHeaderIsChecked = rowData.length === count;
    this.sectionHeaderIsInd = !!(count && rowData.length > count);
    return mappedData;
  }

  emitBatchActionRowId (data: IRowData[]): void {
    const batchData = {
      data,
      updateAll: false,
      level: 'ROW',
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      section: 0,
    };
    this.batchActionData.emit(batchData);
  }

  emitBatchActionSectionIds (): void {
    const data = this.dataSource$.value;
    const batchData = {
      data,
      updateAll: !this.sectionHeaderIsChecked,
      level: 'SECTION',
      sortKey: this.sortKey$.value,
      sortDirection: this.sortDirection$.value,
      section: 0,
    };
    this.batchActionData.emit(batchData);
  }

  checkHeaderOverflow (className: string): void {
    this.headerOverflow = this.getHeaderOverflow(className);
  }

  resetHeaderOverflow (): void {
    this.headerOverflow = false;
  }

  ngOnDestroy (): void {
    this.dataPipelineSub.unsubscribe();
  }

  public parseItem (inputData): string
  {
    let parsedData = '';
    if (inputData) {
      parsedData = inputData.replace(/['\']/g,'');
      parsedData = JSON.parse(parsedData);
    } 
    return parsedData;
  }

  public openModal (rowData: IRowData[]): void {
    this.isOverrideActionClicked = true;
    const studentData = rowData[0];
    const shelterId = this.shelterId;
    const schoolId = this.schoolId;
    let listType: THistoryLogsComponent;
    if (this.listConfig.listType) listType = this.listConfig.listType as THistoryLogsComponent;
    else listType = 'SHELTER_ATT_LIST';
    const modalData: IHistoryModalData = {
      studentData,
      shelterId,
      schoolId,
      listType,
    };

    this.modalsService
      .openHistoryModal(modalData)
      .afterClosed()
      .pipe(
        tap(() => {
            this.isOverrideActionClicked = false;
        })).subscribe();
  }
}
