import { WizardFilterResolver } from './resolvers/sdc/wizard-filter/wizard-filter.resolver';
import { MaintenanceModeResolver } from './resolvers/maintenance-mode/maintenance-mode.resolver';
import { PbatSchoolGuard } from './guards/pbat-school-guard/pbat-school-guard';
import { OtherToolsLayoutModule } from './../school/layouts/other-tools-layout/other-tools-layout.module';
import { StoreDataService } from './../shared/services/store-data-service/store-data.service';
import { OtherToolsFirstDefaultRouteGuard } from './guards/other-tools-first-default-route-guard/other-tools-first-default-route-guard';
import { PusherResolver } from './resolvers/pusher/pusher-resolver';
import { SchoolTypeGuard } from './guards/school-type-guard/school-type-guard';
import { DistrictGuard } from './guards/district-guard/district-guard';
import { SyncSheetGuard } from './guards/sync-sheet-guard/sync-sheet-guard';
import { LastKnownUrlGuard } from './guards/last-known-url-guard/last-known-url-guard';
import { SyncCombinatorGuard } from './guards/sync-combinator-guard/sync-combinator-guard';
import { SchoolSsvFilterResolver } from './resolvers/school-ssv/school-ssv-filter/school-ssv-filter.resolver';
import { FullScreenLayoutModule } from './../shelter/layouts/full-screen-layout/full-screen-layout.module';
import { SchoolStandardLayoutModule } from './../school/layouts/school-standard-layout/school-standard-layout.module';
import { NetworkStandardLayoutModule } from './../network/layouts/network-standard-layout/network-standard-layout.module';
import { StandardLayoutModule } from './../shelter/layouts/standard-layout/standard-layout.module';
import { SupportLeadGuard } from './guards/support-lead-guard/support-lead-guard';
import { SummerSchoolGuard } from './guards/summer-school-guard/summer-school-guard';
import { DashboardDeprecationGuard } from './guards/dashboard-deprecation-guard/dashboard-deprecation-guard';
import { ListDeprecationGuard } from './guards/list-deprecation-guard/list-deprecation-guard';
import { DashboardScreenerFociRotationGuard } from './guards/dashboard-screener-foci-rotation-guard/dashboard-screener-foci-rotation-guard';
import { LoginGuard } from './guards/login-guard/login-guard';
import { HomeGuard } from './guards/home-guard/home-guard';
import { CurrentUserGuard } from './guards/current-user-guard/current-user-guard';
import { TogglesGuard } from 'Src/ng2/routing/guards/toggles-guard/toggles-guards';
import { FeatureToggleGuard } from './guards/feature-toggle-guard/feature-toggle-guard';
import { CurrentSchoolGuard } from './guards/current-school-guard/current-school-guard';
import { CurrentDistrictGuard } from './guards/current-district-guard/current-district-guard';
import { DistrictPickerGuard } from './guards/district-picker-guard/district-picker-guard';
import { NoDistrictRouteGuard } from './guards/no-district-route-guard/no-district-route-guard';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { RouterExitService } from './services/router-exit.service';
import { CaresIdsResolver } from './resolvers/cares-ids/cares-ids.resolver';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';
import { ShelterIdResolver } from './resolvers/shelter-id/shelter-id.resolver';
import { routeHelpers } from './route-helpers';
import { DashboardToggleGuard } from './guards/dashboard-toggle-guard/dashboard-toggle-guard';
import { sdcFullScreenResolver } from './resolvers/sdc/sdc-full-screen/sdc-full-screen';
import { SdcActivityGuardForProfile } from './guards/sdc/sdc-activity-guard/sdc-activity-guard-for-profile';

import { CurrentSdcSchoolGuard } from './guards/sdc/current-sdc-school-guard/current-sdc-school-guard';
import { InitialGridStateResolver } from './resolvers/sdc/initial-grid-state/initial-grid-state.resolver';
import { PusherGuard } from './guards/pusher-guard/pusher-guard';
import { SettingsRoutesResolver } from './resolvers/settings-routes/settings-routes.resolver';
import { SdcSchoolUsersGuard } from './guards/sdc/sdc-school-users-guard/sdc-school-users-guard';
import { SchoolSsvSortResolver } from './resolvers/school-ssv/school-ssv-sort/school-ssv-sort.resolver';
import { SchoolResourceResolver } from './resolvers/school-resource/school-resource.resolver';
import { SchoolSingleColumnLayoutModule } from '../school/layouts/school-single-column-layout/school-single-column-layout.module';
import { GridStateResolver } from './resolvers/grid-state/grid-state.resolver';
import { SettingsLayoutModule } from '../shared/components/settings/settings-layout/settings-layout.module';
import { UftDoeAdvisingGuard } from './guards/uft-doe-advising-guard/uft-doe-advising-guard';
import { ClusterIdResolver } from './resolvers/cluster-id/cluster-id.resolver';
import { PendingChangesGuard } from './guards/pending-changes-guard/pending-changes-guard';

const routes: Routes = routeHelpers.get();

// DEBUGGING ROUTES? Set { enableTracing: true } below and check console
const options: ExtraOptions = {
  useHash: false,
  enableTracing: false,
  paramsInheritanceStrategy: 'always',
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, options),
    // LAYOUTS (containing router-outlets)
    StandardLayoutModule,
    NetworkStandardLayoutModule,
    SchoolStandardLayoutModule,
    FullScreenLayoutModule,
    OtherToolsLayoutModule,
    SchoolSingleColumnLayoutModule,
    SettingsLayoutModule,
  ],
  exports: [RouterModule],
  providers: [
    // GUARDS
    RouteGuard,
    CurrentSchoolGuard,
    CurrentDistrictGuard,
    DistrictPickerGuard,
    NoDistrictRouteGuard,
    FeatureToggleGuard,
    TogglesGuard,
    CurrentUserGuard,
    PusherGuard,
    HomeGuard,
    LoginGuard,
    DashboardToggleGuard,
    DashboardDeprecationGuard,
    ListDeprecationGuard,
    DashboardScreenerFociRotationGuard,
    SummerSchoolGuard,
    SupportLeadGuard,
    SyncCombinatorGuard,
    SchoolTypeGuard,
    DistrictGuard,
    LastKnownUrlGuard,
    PbatSchoolGuard,
    OtherToolsFirstDefaultRouteGuard,
    UftDoeAdvisingGuard,
    PendingChangesGuard,
    SyncSheetGuard,
    // GUARDS FOR SDC
    CurrentSdcSchoolGuard,
    SdcSchoolUsersGuard,
    // RESOLVERS
    MaintenanceModeResolver,
    ShelterIdResolver,
    CaresIdsResolver,
    SchoolSsvFilterResolver,
    SchoolSsvSortResolver,
    PusherResolver,
    SettingsRoutesResolver,
    SchoolResourceResolver,
    ClusterIdResolver,
    GridStateResolver,
    // RESOLVERS FOR SDC
    WizardFilterResolver,
    SdcActivityGuardForProfile,
    InitialGridStateResolver,
    sdcFullScreenResolver,
    // MISC SERVICES
    RouterExitService,
    StoreDataService,
  ],
})
export class AppRoutingModule {
  // inject the service into the app routing module to activate service
  constructor (public exitService: RouterExitService) {}
}
