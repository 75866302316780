import { Injectable } from '@angular/core';
import { filter, find, map } from 'lodash';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';
import { IMilestone } from '../../../shared/typings/interfaces/milestones.interface';
import { ListCellFormattingService } from '../list-display/list-cell-formatting.service';
import { COLUMN_CALCULATION_TYPE, COLUMN_DATA_TYPE } from './../../../shared/constants/list-view/cell-type.constant';

/* istanbul ignore next */
@Injectable()
export class PostsecondaryListService {
  listCellFormattingService: ListCellFormattingService;

  constructor() {
    this.listCellFormattingService = new ListCellFormattingService();
  }

  formatColumnData(columnType, columnData, cellConfig) {
    const { data } = columnData;

    switch (columnType) {
      case COLUMN_CALCULATION_TYPE.FINANCIAL_AID:
        return this.financialAidTooltipFormatterForPostsecondaryList(columnData);
      case COLUMN_CALCULATION_TYPE.MILESTONE:
        return this.milestoneCalculatedColumnFormatter(columnData);
      case COLUMN_CALCULATION_TYPE.STUDENT_PATH:
        return this.studentPathColumnFormatter(columnData, cellConfig);
      case COLUMN_DATA_TYPE.STUDENT_ACTIVITY_STATUS:
        return this.studentActivityColumnFormatter(columnData);
      case COLUMN_DATA_TYPE.FLAG:
        return this.listCellFormattingService.flagFormatter(data);
      case COLUMN_DATA_TYPE.RAW:
        return this.listCellFormattingService.milestoneFormatter(columnData);

      default:
        let formattedData: IListColumnData = {
          data,
          style: '',
        };
        return formattedData;
    }
  }

  milestoneCalculatedColumnFormatter(columnData): IListColumnData {
    if (!columnData.data) {
      return columnData;
    }
    const { data } = columnData;
    const { milestones, calculatedStatus } = data;
    let formattedData: IListColumnData = {
      data: calculatedStatus || '-',
      style: 'center',
      tooltipMsg: '',
      tooltipClass: 'path-status-tooltip',
    };

    if (milestones) {
      const tooltips = map(milestones, (ms: any) => {
        return `
          <div>
            <span>${ms.formattedHuman}</span>
            <span>${ms.formattedStatus}</span>
          </div>
        `;
      });
      formattedData.tooltipMsg = tooltips.join('<br/>');
    }

    return formattedData;
  }

  cunyCollegeReadinessColumnFormatter(columnData, config): IListColumnData {
    const { student } = columnData.data;
    const { isELACR, isMathCR, SAT } = student.postsecondary;
    const {
      byCategory5: { ela, math },
    } = student.regentsDetails;
    let cunyVal;
    if (isELACR && isMathCR) cunyVal = '2/2';
    else if ((isELACR && !isMathCR) || (!isELACR && isMathCR)) cunyVal = '1/2';
    else cunyVal = '0/2';

    const cunyElaReady = isELACR ? 'College ready' : 'Not ready';
    const cunyElaTooltip = `<div class="tooltip-score">ELA: ${cunyElaReady}</div>`;

    const maxELARegents = ela.maxScore.string;
    const cunyMaxELATooltip =
      `<div class="tooltip-text">Max ELA Regents: ` +
      `<span class="tooltip-score">${maxELARegents ? maxELARegents : '—'}</span></div>`;
    const cunySATReadWriteTooltip =
      `<div class="tooltip-text">Max SAT reading & writing: ` +
      `<span class="tooltip-score">${SAT.readingMax ? SAT.readingMax : '—'}</span></div><br>`;

    const cunyMathReady = isMathCR ? 'College ready' : 'Not ready';
    const cunyMathTooltip = `<div class="tooltip-score">Math: ${cunyMathReady}</div>`;

    const maxMathRegents = math.maxScore.string;
    const cunyMaxMathTooltip =
      `<div class="tooltip-text">Max math Regents: ` +
      `<span class="tooltip-score">${maxMathRegents ? maxMathRegents : '—'}</span></div>`;
    const cunySATMathTooltip =
      `<div class="tooltip-text">Max SAT reading & writing: ` +
      `<span class="tooltip-score">${SAT.mathMax ? SAT.mathMax : '—'}</span></div>`;

    const tooltip =
      cunyElaTooltip +
      cunyMaxELATooltip +
      cunySATReadWriteTooltip +
      cunyMathTooltip +
      cunyMaxMathTooltip +
      cunySATMathTooltip;

    let cellStyle = 'center';

    if (cunyVal === '0/2') cellStyle += ' gradient-high';
    else if (cunyVal === '1/2') cellStyle += ' gradient-medium';

    let formattedData: IListColumnData = {
      data: cunyVal,
      style: cellStyle,
      tooltipMsg: tooltip,
      tooltipClass: 'tooltip-wide',
    };

    if (columnData === null || columnData === 0 || columnData === '0') {
      formattedData.data = '—';
      formattedData.style = 'disabled center';
    }
    return formattedData;
  }

  formatFinancialAidTooltipMsg(fafsaStatus, tapStatus): string {
    const fafsaLabel = `<div>FAFSA application submission</div>`;
    const fafsaText = `<div class="tooltip-text">${fafsaStatus}</div><br>`;

    const tapLabel = `<div>TAP application submission</div>`;
    const tapText = `<div class="tooltip-text">${tapStatus}</div>`;

    return fafsaLabel + fafsaText + tapLabel + tapText;
  }

  financialAidTooltipFormatterForPostsecondaryList(columnData): IListColumnData {
    const {
      data: { tooltip },
    } = columnData;
    const { fafsaStatus, tapStatus } = this.calculateFafsaAndTapStatus(tooltip);
    const data = fafsaStatus === 'Complete' && tapStatus === 'Complete' ? 'Complete' : 'Incomplete';
    const tooltipMsg = this.formatFinancialAidTooltipMsg(fafsaStatus, tapStatus);

    const formattedData = {
      data,
      style: 'center',
      tooltipMsg,
      tooltipClass: 'financial-aid-tooltip',
    };
    return formattedData;
  }

  calculateFafsaAndTapStatus(tooltip) {
    let fafsaStatus;
    let tapStatus;
    if (tooltip === 'FAFSA and TAP completed') {
      fafsaStatus = 'Complete';
      tapStatus = 'Complete';
    } else {
      fafsaStatus = tooltip.includes('Only FAFSA') ? 'Complete' : 'Not complete';
      tapStatus = tooltip.includes('Only TAP') ? 'Complete' : 'Not complete';
    }
    return {
      fafsaStatus,
      tapStatus,
    };
  }

  studentActivityColumnFormatter(columnData: { data: string | number }): IListColumnData {
    const { data } = columnData;
    let formattedData: IListColumnData = {
      data,
      style: columnData.data === 'Needs confirmation' ? 'center warning' : 'center',
      tooltipMsg: '',
      tooltipClass: '',
    };
    return formattedData;
  }

  studentPathColumnFormatter(columnData, config): IListColumnData {
    if (!columnData.data) {
      return columnData;
    }
    const { data } = columnData;
    const { matchedPaths, totalPaths, decision } = data;
    const filteredMatched = filter(matchedPaths, (studentPath: { status: string }) => studentPath.status !== 'DELETED');
    const filteredTotal = filter(totalPaths, (studentPath: { status: string }) => studentPath.status !== 'DELETED');
    let { calculatedStatus } = data;
    const defaultStyle = 'center';

    let formattedData: IListColumnData = {
      data: '-',
      displayValue: '-',
      style: defaultStyle,
      tooltipMsg: '',
      tooltipClass: 'path-status-tooltip',
    };

    if (totalPaths) {
      const mp = filteredMatched.length;
      const tp = filteredTotal.length;

      formattedData.displayValue = !mp && !tp ? '-' : `${mp} / ${tp}`;
      if (formattedData.displayValue === '-') calculatedStatus = 100;

      if (config.gradientRule) {
        const gradientType = config.gradientRule.gradientType;
        switch (gradientType) {
          case 'APPLIED':
            formattedData.style = `${defaultStyle} ${this.getGradientStyle(calculatedStatus)}`;
            break;
          case 'RESPONSES':
            formattedData.style = `${defaultStyle} ${this.getGradientStyle(calculatedStatus)}`;
            break;
          case 'ACCEPTANCES':
            formattedData.style = `${defaultStyle} ${this.getGradientStyle(mp, true)}`;
            formattedData.displayValue = mp ? mp : '-';
            formattedData.data = mp;
            break;
          default:
            break;
        }
      }

      // For the Applied col, totalPaths equals all original student paths, for the Responses and
      // Acceptance cols, totalPaths reps the total paths meeting a certain condition
      const tooltips = map(filteredTotal, (studentPath: any) => {
        if (!studentPath.status) studentPath.status = 'No response yet';
        const name = studentPath.path ? studentPath.path.name : studentPath.name;
        const status = studentPath.status;
        return `
            <div>
              <span>${name}</span>
              <span>${status}</span>
            </div>
          `;
      });

      formattedData.tooltipMsg = tooltips.join('<br/>');
    } else if (decision) {
      formattedData.displayValue = decision;
      formattedData.data = decision;
      if (decision.length > 1) formattedData.tooltipMsg = decision;
    }

    return formattedData;
  }

  getCalculatedStatus(columnData: IMilestone, date): string {
    const { dueAt, status } = columnData;
    const now = date.getTime();
    const dueAtRaw = new Date(dueAt);
    const due = dueAtRaw.getTime();
    const dueMinus14 = dueAtRaw.setDate(dueAtRaw.getDate() - 14);
    const isComplete = status === 'COMPLETE';
    const isPastDue = status === 'NOT_COMPLETE' && now >= due;
    const isDue = status === 'NOT_COMPLETE' && (now >= dueMinus14 && now < due);
    const isNotYetDue = status === 'NOT_COMPLETE' && now < dueMinus14;

    if (isComplete) return 'Complete';
    if (isDue) return 'Due soon';
    if (isPastDue) return 'Past due';
    if (isNotYetDue) return 'Not due yet';
  }

  getGradientStyle(num: number, isWhole?): string {
    if (isWhole) return num === 0 ? '' : 'gradient-low';
    const a = num === 100;
    const b = num >= 75 && num <= 99.9;
    const c = num >= 50 && num <= 74.9;
    const d = num >= 25 && num <= 49.9;
    const e = num >= 0 && num <= 24.9;
    if (a) return '';
    if (b) return 'gradient-low';
    if (c) return 'gradient-medium';
    if (d) return 'gradient-high';
    if (e) return 'gradient-higher';
  }
}
