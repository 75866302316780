import { Injectable } from '@angular/core';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';
import { MilestoneListService } from '../../../student/common-panels/student-postsec/student-postsec.service';
import { AttendanceService } from '../attendance-list/attendance.service';
import { MyStudentService } from '../my-students-list/my-student.service';
import { PostsecondaryListService } from '../postsecondary-list/postsecondary-list.service';
import { LIST_TYPE } from './../../../shared/constants/list-view/list-view.constant';
import { GraphDetailService } from './../../graph-detail/graph-detail.service';
import { GraphListService } from './../../graph-list/graph-list.service';
import { AcademicService } from './../academic-list/academic.service';
import { CareerPathService } from './list-display-path/list-display-career-path/career-path.service';
import { CollegePathService } from './list-display-path/list-display-college-path/college-path.service';

@Injectable()
export class ListDisplayService {
  constructor(
    private myStudentService: MyStudentService,
    private attendanceService: AttendanceService,
    private academicService: AcademicService,
    private graphDetailService: GraphDetailService,
    private graphListService: GraphListService,
    private milestoneListService: MilestoneListService,
    private collegePathService: CollegePathService,
    private careerPathService: CareerPathService,
    private postsecondaryListService: PostsecondaryListService,
  ) {}

  /* istanbul ignore next */
  processColumnData({ listType, columnType, columnData, cellConfig }) {
    switch (listType) {
      case LIST_TYPE.MY_STUDENTS:
        return this.myStudentService.formatColumnData(columnType, columnData);

      case LIST_TYPE.ATTENDANCE:
        return this.attendanceService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.ACADEMIC:
        return this.academicService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.GRAPH_DETAIL:
        return this.graphDetailService.formatColumnData(columnType, columnData);

      case LIST_TYPE.GRAPH_LIST:
        return this.graphListService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.MILESTONE:
        return this.milestoneListService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.POSTSECONDARY:
        return this.postsecondaryListService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.POSTSECONDARY_ALT:
        return this.postsecondaryListService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.STUDENT_ACTIVITIES:
        return this.postsecondaryListService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.COLLEGE_PATH:
        return this.collegePathService.formatColumnData(columnType, columnData, cellConfig);

      case LIST_TYPE.CAREER_PATH:
        return this.careerPathService.formatColumnData(columnType, columnData, cellConfig);

      default:
        const defaultData: IListColumnData = { data: columnData.data, style: '' };
        if (columnData.tooltipMsg) defaultData.tooltipMsg = columnData.tooltipMsg;
        if (columnData.tooltipClass) defaultData.tooltipClass = columnData.tooltipClass;
        return defaultData;
    }
  }

  getStyleForList(listType: string): string {
    switch (listType) {
      case LIST_TYPE.MY_STUDENTS:
        return 'list-my-students';

      case LIST_TYPE.ATTENDANCE:
        return 'list-attendance';

      case LIST_TYPE.ACADEMIC:
        return 'list-academic';

      case LIST_TYPE.GRAPH_DETAIL:
        return 'graph-detail';

      case LIST_TYPE.GRAPH_LIST:
        return 'graph-list';

      case LIST_TYPE.GRAPH_LIST_POSTSEC:
        return 'graph-list-post-sec';

      case LIST_TYPE.MILESTONE:
        return 'list-milestone';

      case LIST_TYPE.COLLEGE_PATH:
        return 'list-college-path';

      case LIST_TYPE.CAREER_PATH:
        return 'list-career-path';

      case LIST_TYPE.POSTSECONDARY:
        return 'list-postsecondary';

      case LIST_TYPE.POSTSECONDARY_ALT:
        return 'list-postsecondary-alt';

      case LIST_TYPE.STUDENT_ACTIVITIES:
        return 'list-student-activities';

      default:
        return 'list-default';
    }
  }

  // if more list type needs to have grouping navigate feature (JChu)
  // add additional case to match
  listShouldHaveGroupingNav(listType: string): boolean {
    switch (listType) {
      case LIST_TYPE.POSTSECONDARY:
      case LIST_TYPE.POSTSECONDARY_ALT:
      case LIST_TYPE.MY_STUDENTS:
      case LIST_TYPE.ATTENDANCE:
      case LIST_TYPE.ACADEMIC:
        return true;
      default:
        return false;
    }
  }
}
