import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WizardHelpers } from 'Src/ng2/school/sdc/services/wizard-helpers.service';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'on-track-credit-step',
  templateUrl: './on-track-credit-step.component.html',
  encapsulation: ViewEncapsulation.None,
})

export class OnTrackCreditStep {
    public stepLabel: string = "Review your school's On-Track Credit Metrics"

  constructor (
    private activatedRoute: ActivatedRoute,
    private wizardHelpers: WizardHelpers,
    private urlPathService: UrlPathService,
  ) { }

  navigateToCreditSettings () {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.activatedRoute.snapshot.params.schoolId}/settings/credits`);
    this.wizardHelpers.goToStateInNewTab(url);
  }
}
