import { createSelector } from '@ngrx/store';
import { createFeatureSelector } from '@ngrx/store';
import { map } from 'lodash';
import { IMilestonesState } from './../reducers/milestone-reducer';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getMilestonesState = createSelector(
  getPortalState,
  (state: any) => state.milestones,
);

export const getMilestoneEntities = createSelector(
  getMilestonesState,
  (state: IMilestonesState) => state.milestoneEntities,
);

export const getMilestoneEntitiesList = createSelector(
  getMilestonesState,
  (state: IMilestonesState) => {
    const milestoneEntities = state.milestoneEntities;
    return map(milestoneEntities, (v, k) => v);
  },
);

export const getMilestoneLoadedEntities = createSelector(
  getMilestonesState,
  (state: IMilestonesState) => {
    return state.loadedEntities;
  },
);

export const getMilestoneLoadedStatus = createSelector(
  getMilestonesState,
  (state: IMilestonesState) => {
    return state.loaded;
  },
);

export const getAllMilestoneLoadedStatus = createSelector(
  getMilestonesState,
  (state: IMilestonesState) => {
    return state.allLoaded;
  },
);
