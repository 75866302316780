export interface ICohort {
  humanName: string;
  value: number;
  studentYear: number;
  keyForGradPlanningThresholds: string;
}

// TODO UPDATE AT ATS FLIP: add new cohorts, up all .studentYear props by 1
// Don't use .studentYear to find a student's current year in high school instead use ImStudent.getStudentYear()
// .studentYear in this constant should be only used to determine cohorts for Grad Planning
export const Cohort = {
  2009: {
    humanName: '2009',
    value: 2009,
    studentYear: 19,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2010: {
    humanName: '2010',
    value: 2010,
    studentYear: 18,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2011: {
    humanName: '2011',
    value: 2011,
    studentYear: 17,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2012: {
    humanName: '2012',
    value: 2012,
    studentYear: 16,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2013: {
    humanName: '2013',
    value: 2013,
    studentYear: 15,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2014: {
    humanName: '2014',
    value: 2014,
    studentYear: 14,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2015: {
    humanName: '2015',
    value: 2015,
    studentYear: 13,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2016: {
    humanName: '2016',
    value: 2016,
    studentYear: 12,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2017: {
    humanName: '2017',
    value: 2017,
    studentYear: 11,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2018: {
    humanName: '2018',
    value: 2018,
    studentYear: 10,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2019: {
    humanName: '2019',
    value: 2019,
    studentYear: 9,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2020: {
    humanName: '2020',
    value: 2020,
    studentYear: 8,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2021: {
    humanName: '2021',
    value: 2021,
    studentYear: 7,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2022: {
    humanName: '2022',
    value: 2022,
    studentYear: 6,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2023: {
    humanName: '2023',
    value: 2023,
    studentYear: 5,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2024: {
    humanName: '2024',
    value: 2024,
    studentYear: 4,
    keyForGradPlanningThresholds: 'fourthYearCohort',
  } as ICohort,
  2025: {
    humanName: '2025',
    value: 2025,
    studentYear: 3,
    keyForGradPlanningThresholds: 'thirdYearCohort',
  } as ICohort,
  2026: {
    humanName: '2026',
    value: 2026,
    studentYear: 2,
    keyForGradPlanningThresholds: 'secondYearCohort',
  } as ICohort,
  2027: {
    humanName: '2027',
    value: 2027,
    studentYear: 1,
    keyForGradPlanningThresholds: 'firstYearCohort',
  } as ICohort,
};

// TODO UPDATE AT TERM 1 FLIP
// The current constants defied here reflect cohorts that are five+ years out from graduating (super-seniors +).
// Add an additional key/value pair reflecting an additional year (and remove the earliest one)
export const Cohort_D75 = {
  2029: {
    humanName: '2029',
    value: 2029,
    studentYear: 0,
  },
  2030: {
    humanName: '2030',
    value: 2030,
    studentYear: 0,
  },
  2031: {
    humanName: '2031',
    value: 2031,
    studentYear: 0,
  },
  2032: {
    humanName: '2032',
    value: 2032,
    studentYear: 0,
  },
};
