/* tslint:disable */

import { Action } from '@ngrx/store';

/**
 * When generating studentReport PDFs...
 * the order of the created reports needs to align
 * with the list at the time of report creation.
 * To do this, we need to send more than just the studentIds
 * we need to send the list position of the student as well.
 * When we select the selectedStudentIds from the store
 * they will be sorted via the selector
 * and passed to potential observers(JJ)
 */
export interface ISelectedStudentIdData {
  studentId: string;
  sectionIndex: number;
  rowIndex: number;
}

export const TOGGLE_BATCH_ACTIONS = '[Portal] Toggle Batch Actions';
export const UPDATE_SELECTED_STUDENT_IDS = '[Portal] Update Selected Student Ids';

export class ToggleBatchActions implements Action {
  readonly type = TOGGLE_BATCH_ACTIONS;
  constructor(public payload: boolean) {}
}

export class UpdateSelectedStudentIds implements Action {
  readonly type = UPDATE_SELECTED_STUDENT_IDS;
  constructor(public payload: ISelectedStudentIdData[]) {}
}

// action types
export type BatchActionsAction = ToggleBatchActions | UpdateSelectedStudentIds;
