// import { AttendanceGridService } from './../grid/attendance/services/attendance-grid.service';
import { Component, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { NgRouterNavigationService } from 'Src/ng2/school/lists/services/list-navigation/ng-router-list-navigation.service';
import { PortalConfig } from 'Src/ng2/shared/services/portal-config';
import { ISidebarItem } from '../../../nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { INavItem } from '../../network/network-left-side-nav/network-left-side-nav.component';
import { Auth } from '../../shared/auth/auth.service';
import { HelpDeskService } from '../../shared/services/help-desk/help-desk.service';
import { WindowRef } from '../../shared/services/windowRef';
import { IPartner, TValidPartnerTypes } from '../../shared/typings/interfaces/partner.interface';
import { IUser } from '../../shared/typings/interfaces/user.interface';
import { getCurrentUser } from '../../store/selectors/current-user-selectors';

export const DEFAULT_SHELTER_PROFILE_SORT = [{ colId: 'SHELTER_STUDENT_NAME', sort: 'asc' }];
@Component({
  selector: 'shelter-side-nav',
  templateUrl: './shelter-side-nav.component.html',
  styleUrls: ['./shelter-side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ShelterSideNavComponent {
  currentUser: IUser;
  partnerType: TValidPartnerTypes = 'shelter';
  partner: IPartner;
  sidebarOptions: ISidebarItem[] = [
    {
      human: 'Attendance',
      key: 'Attendance',
      url: '/shelter/{id}/lists/attendance-list',
    },
    {
      human: 'Data Grid',
      key: 'Data grid',
      url: '/shelter/{id}/grid',
    },
    {
      human: 'Student Profile',
      key: 'Student Profile',
      // the `url` is dynamic, so it will be taken care of in `goToProfile()`
    },
  ];

  selectedKey: string = 'Attendance';
  shelter: string;
  userMenuOptions = [
    {
      key: 'getHelp',
      humanName: 'Get Help',
      stateName: null,
    },
    {
      key: 'privacyAndTerms',
      humanName: 'Privacy & Terms',
      stateName: 'privacy-and-terms',
    },
  ];

  public shelterId: string;

  constructor (
    private auth: Auth,
    private helpDeskService: HelpDeskService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<any>,
    private windowRef: WindowRef,
    private portalConfig: PortalConfig,
    private navigationService: NgRouterNavigationService,
  ) {}

  ngOnInit (): void {
    this.partner = this.route.snapshot.data.shelter;
    this.shelterId = this.route.snapshot.params.shelterId;

    //Replace the placeholder id now that we have access to it.
    this.sidebarOptions.forEach(option => {
      option.url = option.url?.replace('{id}', this.shelterId);
    });

    this.store
      .select(getCurrentUser)
      .pipe(take(1))
      .subscribe(user => {
        this.currentUser = user;
      });
  }

  goToList (): void {
    // current route url is 'shelter/:shelterId/lists'
    this.router.navigate(['attendance-list'], { relativeTo: this.route });
  }

  goToGrid (): void {
    // current route url is 'shelter/:shelterId/grid'
    this.router.navigate(['../grid'], { relativeTo: this.route });
  }

  goToProfile (): void {
    const shelterId = this.route.snapshot.params.shelterId;
    const payload = {
      caresIds: [],
      updateRelPath: `shelter/${shelterId}/profile`,
      queryParams: {
        sort: DEFAULT_SHELTER_PROFILE_SORT,
      },
      queryParamsHandling: '',
      route: null,
    };

    this.navigationService.goToProfile(payload);
  }

  goExternal (selection): void {
    switch (selection) {
      case 'Data grid': {
        this.goToGrid();
        break;
      }
      case 'Attendance': {
        this.goToList();
        break;
      }
      case 'Student Profile': {
        this.goToProfile();
        break;
      }
      default:
        break;
    }
  }

  public goToState (option: INavItem): void {
    const window = this.windowRef.nativeWindow;
    switch (option.key) {
      case 'getHelp': {
        this.helpDeskService.showHelp();
        break;
      }
      case 'privacyAndTerms':
        window.open(this.portalConfig.publicConfig.PRIVACY_POLICY, '_blank');
        break;
      default:
        return null;
    }
  }

  public navigateToHomepage (): void {
    this.router.navigate([`/shelter/${this.shelterId}/lists/attendance-list`]);
  }
}
