import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { cloneDeep, reduce, isEqual } from 'lodash';
import { Subject } from 'rxjs';
import { ICollegePath } from '../../../shared/typings/interfaces/paths.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { ENDORSEMENTS_CREDENTIALS_MODAL_CONFIG } from './endorsements-credentials-modal.config';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISingleStudent } from 'Src/ng2/student/student-data-service/single-student-data.interface';

export interface IEndorsementsCredentialsModalData extends IBaseModalData {
  mode: 'ENDORSEMENTS' | 'CREDENTIALS';
  singleStudent: ISingleStudent
}

type TValidMode = 'ENDORSEMENTS' | 'CREDENTIALS';

const ENDORSEMENTS_OPTIONS = ['Arts', 'CDOS', 'CTE', 'Honors', 'Mastery in Math', 'Mastery in Science'];
const CREDENTIALS_OPTIONS = ['CDOS', 'SACC', 'TASC'];

/* istanbul ignore next */
@Component({
  selector: 'endorsements-credentials-modal',
  templateUrl: './endorsements-credentials-modal.component.html',
  styleUrls: ['./endorsements-credentials-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EndorsementsCredentialsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public collegePaths: ICollegePath[];
  public placeholder: string;
  public placeholderIsEnabled: boolean = true;
  public optionIsSelected: boolean = false;
  public count: number;
  // Modal Configurations
  readonly buttons = ENDORSEMENTS_CREDENTIALS_MODAL_CONFIG.buttons;

  public destroy$: Subject<boolean> = new Subject<boolean>();

  public itemCount: number;
  public itemType: string;

  public title: string;

  public singleStudent: ISingleStudent;
  public isEndorsementsMode: boolean;
  public sectionTitle: string;
  public checkboxOptions: string[];

  constructor (
    dialogRef: MatDialogRef<EndorsementsCredentialsModalComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: IEndorsementsCredentialsModalData,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, singleStudent } = (this.data = cloneDeep(this.data));
    this.singleStudent = singleStudent;
    this.isEndorsementsMode = mode === 'ENDORSEMENTS';
    this.checkboxOptions = mode === 'ENDORSEMENTS' ? ENDORSEMENTS_OPTIONS : CREDENTIALS_OPTIONS;
    this.sectionTitle = `PLANNED ${mode}`;
    this.itemType = 'student';
    this.isProfileMode = true;

    const { title, valueMap } = this._formatData(mode, singleStudent);
    this.title = title;
    // build form
    this.form = this.buildForm(mode, valueMap);
  }

  // returns title and value map based on mode and student data (JE)
  public _formatData (mode: TValidMode, singleStudent: ISingleStudent): { title: string; valueMap: { [key: string]: boolean } } {
    const { PLANNED_ENDORSEMENTS, PLANNED_CREDENTIALS } = singleStudent;
    let title;
    let valueMap;
    if (mode === 'ENDORSEMENTS') {
      title = 'Edit planned endorsements';
      valueMap = reduce(
        PLANNED_ENDORSEMENTS as string[],
        (endorsementsMap, endorsement) => {
          endorsementsMap[endorsement] = true;
          return endorsementsMap;
        },
        { Arts: false, CDOS: false, CTE: false, Honors: false, 'Mastery in Math': false, 'Mastery in Science': false },
      );
      // set up endorsements
    } else if (mode === 'CREDENTIALS') {
      title = 'Edit planned credentials';
      valueMap = reduce(
        PLANNED_CREDENTIALS as string[],
        (credentialsMap, credential) => {
          credentialsMap[credential] = true;
          return credentialsMap;
        },
        { CDOS: false, SACC: false, TASC: false },
      );
    }
    return { title, valueMap };
  }

  public close (): void {
    super.close();
  }

  public submit (form: FormGroup): void {
    const updates = reduce(
      this.checkboxOptions,
      (result, option) => {
        if (form.value[option]) result.push(option);
        return result;
      },
      [],
    );
    const { PLANNED_DIPLOMA_TYPE, PLANNED_CREDENTIALS } = this.singleStudent;
    const original = this.isEndorsementsMode ? PLANNED_DIPLOMA_TYPE : PLANNED_CREDENTIALS;
    const valuesChanged = !isEqual(original, updates);
    if (valuesChanged) super.close(updates);
    else super.close();
  }

  public buildForm (mode: TValidMode, valueMap: { [key: string]: boolean }): FormGroup {
    let form;
    if (mode === 'ENDORSEMENTS') {
      form = this.formBuilder.group({
        Arts: [valueMap.Arts, Validators.required],
        CDOS: [valueMap.CDOS, Validators.required],
        CTE: [valueMap.CTE, Validators.required],
        Honors: [valueMap.Honors, Validators.required],
        'Mastery in Math': [valueMap['Mastery in Math'], Validators.required],
        'Mastery in Science': [valueMap['Mastery in Science'], Validators.required],
      });
    } else {
      form = this.formBuilder.group({
        CDOS: [valueMap.CDOS, Validators.required],
        SACC: [valueMap.SACC, Validators.required],
        TASC: [valueMap.TASC, Validators.required],
      });
    }

    return form;
  }

  public onCheckboxClick (option: string) {
    if (option) {
      // method auto-calls on init with an undefined value
      const prev = this.form.value[option];
      this.form.controls[option].setValue(!prev);
    }
  }
}
