import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLStudentDocLogsHelperService {
  getStudentDocLogsFilterOptionsQuery ({ studentId, schoolId }): string {
    return `{
      DocLogsFilterOptions(
        studentId: "${studentId}",
        schoolId: "${schoolId}"
      ) {
          categories
          users {
            key
            human
          }
        }
      }`;
  }

  getShelterDocLogsFilterOptionsQuery ({ caresId, shelterId }): string {
    return `{
      DocLogsFilterOptions(
        caresId: "${caresId}",
        shelterId: "${shelterId}"
      ) {
          categories
          users {
            key
            human
          }
        }
    }`;
  }
};
