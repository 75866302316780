import { Component, ComponentFactoryResolver, ComponentRef, EventEmitter, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, Subscription, Unsubscribable } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { skip, take, tap } from 'rxjs/operators';
import { IBatchData } from 'Src/ng2/school/student-level-dashboard/student-level-dash.component';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { InfiniteTableComponent } from '../infinite-table/infinite-table.component';
import { ListContainerComponent } from '../list-container/list-container.component';
import { IColumnStuLvl, IGroupData, IListConfig, IRowData } from './../../../models/list-models';
import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { Store } from '@ngrx/store';
import { UpdateSelectedStudentIds } from 'Src/ng2/store';

/* istanbul ignore next */
@Component({ template: '' })
@unsubscribeComponent
// @ts-ignore
export abstract class FixedToInfiniteViewComponent {
  // required to overwrite
  abstract schoolId: string;
  abstract entry: any;
  abstract onRowClick: Function;
  abstract onBatchAction: Function;
  abstract onDynamicComponentClick: Function;
  abstract onInfinite: Function;
  abstract onFixed: Function;
  abstract onUiRowDataUpdate: Function;
  abstract groupingData$: BehaviorSubject<IGroupData[]>;
  abstract columns: IColumnStuLvl[];
  abstract batchActionsMode$: Observable<boolean>;
  abstract columnIndexMap: { [key: string]: number };
  abstract listConfig: IListConfig;
  abstract dynamicColumns;
  abstract dynamicColumnIndexMap;
  abstract noDataMessage;
  abstract dynamicComponentInputData?: any;

  public batchActionsHash: any;
  stubReplacement: any;

  // optional, inherited class can overwrite
  shelterId?: string = null;
  madlibModel?: any;

  // for url
  abstract filterFormControl: FormControl;
  abstract sortKey$: BehaviorSubject<string>;
  abstract sortDirection$: BehaviorSubject<TSortDirections>;

  abstract updateSort: Function;

  // component refs
  listContainerComponent: ComponentRef<ListContainerComponent>;
  infiniteTableComponent: ComponentRef<InfiniteTableComponent>;

  infiniteGroupIndex: number;

  // subscriptions
  clickedRowFixedSub: Subscription;
  clickedRowInfiniteSub: Subscription;
  batchActionSub: Subscription;
  groupingDataSub: Unsubscribable;
  clickedDynamicComponentSub?: Subscription;
  uiRowDataSub: Unsubscribable;

  // @ts-ignore
  constructor (
    public resolver: ComponentFactoryResolver,
    // @ts-ignore
    private batchActionsService: BatchActionsService,
    private store: Store<any>,
  ) {}

  createListContainer (dynamicComponentInputData = null): ComponentRef<ListContainerComponent> {
    const listContainerFactory = this.resolver.resolveComponentFactory(ListContainerComponent);
    this.listContainerComponent = this.entry.createComponent(listContainerFactory);
    const instance: any = this.listContainerComponent.instance;
    instance.groupingData$ = this.groupingData$;
    instance.schoolId = this.schoolId;
    instance.shelterId = this.shelterId;
    instance.madlibModel = this.madlibModel;
    instance.noDataMessage = this.noDataMessage;
    instance.dynamicComponentInputData = dynamicComponentInputData;
    instance.sortKey$ = this.sortKey$;
    instance.sortDirection$ = this.sortDirection$;

    let indexSub: Unsubscribable | null;
    let columnSub: Unsubscribable | null;

    if (!this.dynamicColumns) instance.columns = this.columns;
    else columnSub = this.dynamicColumns.subscribe(cols => (instance.columns = cols));

    if (!this.dynamicColumnIndexMap) instance.columnIndexMap = this.columnIndexMap;
    else indexSub = this.dynamicColumnIndexMap.pipe(tap(colIndx => (instance.columnIndexMap = colIndx))).subscribe();

    instance.filterFormControl = this.filterFormControl;
    instance.batchActionsMode$ = this.batchActionsMode$;
    instance.listConfig = this.listConfig;

    instance.sortEmitter.pipe(tap(evt => this.updateSort(evt))).subscribe();

    instance.focusedGroup
      .pipe(
        tap(($event: { groupData: IGroupData; sortKey: string; sortDirection: TSortDirections; groupIndx: number }) => {
          this.infiniteGroupIndex = $event.groupIndx;
          if (columnSub) columnSub.unsubscribe();
          if (indexSub) indexSub.unsubscribe();
          this.destroyComponent(this.listContainerComponent);
          this.createInfiniteStudentTableComponent($event);
        }),
        take(1),
      )
      .subscribe();

      this.clickedRowFixedSub = instance.clickedRow
        .pipe(tap(($event: IRowData[]) => this.onRowClick($event)))
        .subscribe();
    if (this.listConfig.listType !== 'SUPPORT_SETTINGS') {
      this.uiRowDataSub = instance.uiRowDataUpdate
        .pipe(
          tap($event => this.onUiRowDataUpdate($event)),
          skip(1),
        )
        .subscribe();
      this.batchActionSub = instance.batchActionData
        .pipe(tap(($event: IBatchData) => this.onBatchAction($event)))
        .subscribe();
      this.clickedDynamicComponentSub = instance.clickedDynamicComponent
        .pipe(tap(($event: IBatchData) => this.onDynamicComponentClick($event)))
        .subscribe();
    }

    // hook for subclass to access activeInstance
    this.onFixed(instance);

    return this.listContainerComponent;
  }

  createInfiniteStudentTableComponent ($event): ComponentRef<InfiniteTableComponent> {
    const groupData$ = new BehaviorSubject($event.groupData);
    const infiniteStudentTableContainerFactory = this.resolver.resolveComponentFactory(InfiniteTableComponent);
    this.infiniteTableComponent = this.entry.createComponent(infiniteStudentTableContainerFactory);
    const instance: any = this.infiniteTableComponent.instance;
    instance.schoolId = this.schoolId;
    instance.shelterId = this.shelterId;
    instance.madlibModel = this.madlibModel;
    instance.groupData = $event.groupData;
    instance.dynamicComponentInputData = this.dynamicComponentInputData;
    this.groupingDataSub = this.groupingData$
      .pipe(
        skip(1),
        tap((groupData: IGroupData[]) => {
          // if currentIndex no longer exists after modifying groups, default to 0.
          const currentGroup = groupData[this.infiniteGroupIndex] || groupData[0];
          groupData$.next(currentGroup);
        }),
      )
      .subscribe();

    instance.groupData$ = groupData$;
    instance.sortKey$ = this.sortKey$;
    instance.sortDirection$ = this.sortDirection$;
    instance.filterFormControl = this.filterFormControl;
    instance.batchActionsMode$ = this.batchActionsMode$;
    this.listConfig.allowEmptyTable = $event.groupData.allowEmptyTable ? $event.groupData.allowEmptyTable : false;
    instance.listConfig = this.listConfig;
    if (!this.dynamicColumns) instance.columns = this.columns;
    else {
      this.dynamicColumns
        .pipe(
          tap(cols => (instance.columns = cols)),
          take(1),
        )
        .subscribe();
    }

    if (!this.dynamicColumnIndexMap) instance.columnIndexMap = this.columnIndexMap;
    else {
      this.dynamicColumnIndexMap
        .pipe(
          tap(colIndx => (instance.columnIndexMap = colIndx)),
          take(1),
        )
        .subscribe();
    }

    // close list
    instance.collapseOutDone
      .pipe(
        tap(() => {
          this.groupingDataSub.unsubscribe();
          this.destroyComponent(this.infiniteTableComponent);
          this.createListContainer(this.dynamicComponentInputData);
        }),
        take(1),
      )
      .subscribe();

    this.clickedDynamicComponentSub = instance.onDynamicComponentClick;
    instance.sortEmitter.pipe(tap(evt => this.updateSort(evt))).subscribe();

    // TODO: explore making subscriptions conditional on something upstream
    this.clickedRowInfiniteSub = instance.clickedRowData
      .pipe(tap(($event: IRowData[]) => this.onRowClick($event)))
      .subscribe();

    this.batchActionSub = instance.batchActionData
      .pipe(tap(($event: IBatchData) => this.onBatchAction($event)))
      .subscribe();

    this.clickedDynamicComponentSub = instance.onDynamicComponentClick
      .pipe(tap(($event: IBatchData) => this.onDynamicComponentClick($event)))
      .subscribe();

    this.uiRowDataSub = instance.uiRowData
      .pipe(
        tap(($event: IBatchData) => {
          return this.onDynamicComponentClick($event);
        }),
      )
      .subscribe();

    // hook for subclass to access activeInstance
    this.onInfinite(instance);

    return this.infiniteTableComponent;
  }

  formatAndFireBatchActionEvt ($event: IBatchData): void {
    const { sortKey, sortDirection, section } = $event;
    const sort = sortKey && sortDirection ? { sortKey, sortDirection, section } : null;
    $event.stubReplacement = this.stubReplacement;
    const batchData = this.batchActionsService.formatBatchActionEvt($event);
    this.batchActionsHash.updateStudentIds(batchData);
    const studentIds = this.batchActionsHash.getStudentIds(sort);
    this.store.dispatch(new UpdateSelectedStudentIds(studentIds));
  }

  destroyComponent (component: ComponentRef<ListContainerComponent | InfiniteTableComponent>): void {
    component.destroy();
  }
}
