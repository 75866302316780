import { Injectable } from '@angular/core';

import { each, keys, map, set, startsWith } from 'lodash';

interface IProjection {
  [key: string]: boolean;
}

@Injectable()
export class ApiHelpers {
  constructor() {
    //
  }

  unzipData(data = { paths: [], values: [] }, projection) {
    const paths = data.paths;
    const values = data.values;
    const pathsToGet = projection && keys(projection);
    const arrayToObjectFunction = this.buildArrayToObjectFunction(paths, pathsToGet);
    const unzipedData = map(values, arrayToObjectFunction);
    return unzipedData;
  }

  /* istanbul ignore next */
  private buildArrayToObjectFunction(paths: string[], pathsToGet?: string[]) {
    const ret = {};
    const pathAndIndex: { paths: string[]; idxs: number[] } = { paths: [], idxs: [] };
    let func: string;

    if (pathsToGet) {
      each(pathsToGet, pToReturn => {
        each(paths, (p, idx) => {
          if (startsWith(p, pToReturn)) {
            pathAndIndex.paths.push(p);
            pathAndIndex.idxs.push(idx);
          }
        });
      });
    } else pathAndIndex.paths = paths;

    each(pathAndIndex.paths, p => set(ret, p, undefined));

    func = `var ret = ${JSON.stringify(ret)};`;

    if (pathAndIndex.idxs.length) {
      each(pathAndIndex.paths, (p: any, idx: number) => {
        func = func + `ret.${p}=values[${pathAndIndex.idxs[idx]}];`;
      });
    } else {
      each(paths, (p, idx) => {
        func = func + `ret.${p}=values[${idx}];`;
      });
    }

    func = func + `return ret;`;

    const arrayToObjectFunction: any = new Function('values', func);

    return arrayToObjectFunction;
  }
}
