import { Inject } from '@angular/core';
import { RollbarService } from './../../services/rollbar/rollbar.service';
import * as Rollbar from 'rollbar';
// This class is a wrapper of HTML Web Storage.
// We are using HTML Web Storage instead of an external library since it is supported
// by all browsers the app supports (CM).

export abstract class WebStorageUtility<T extends string> implements Storage {
  [key: string]: any;

  readonly storage: Storage;

  constructor (storage: Storage, @Inject(RollbarService) private rollbar: Rollbar) {
    this.storage = storage;
    this.rollbar = rollbar;
  }

  public key (n: number) {
    return this.storage.key(n);
  }

  // check to see if key exists in storage
  public checkForItem (key: string): boolean {
    return this.storage.hasOwnProperty(key);
  }

  // Save data to storage
  public setItem (key: T, value): void {
    let _value: string;

    try {
      _value = value && JSON.stringify(value);
    } catch (err) {
      this.rollbar.debug(`Store#setItem error for key "${key}". Value causing error is "${value}".`);

      _value = null;
    }

    this.storage.setItem(key, _value);
  }

  // Get saved data from storage
  public getItem (key: T) {
    let _value: any;

    try {
      _value = this.storage.getItem(key);
      _value = _value && JSON.parse(_value);
    } catch (err) {
      this.rollbar.debug(
        `Store#getItem error for key "${key}". Value causing error is "${this.storage.getItem(key)}".`,
      );

      _value = null;
      // clean up storage and set value to null (CM)
      this.setItem(key, _value);
    }

    return _value;
  }

  // remove saved data from storage
  public removeItem (key: T): void {
    this.storage.removeItem(key);
  }

  public clear (): void {
    this.storage.clear();
  }

  public get length (): number {
    return this.storage.length;
  }
}
