import { AnyAaaaRecord } from 'dns';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { PortalConfig } from '../portal-config';
import { StudentFetchMssgsService } from './student-fetch-mssgs.service';
import { StudentFetchUnzipService } from './student-fetch-unzip.service';
/*
  Notes to develop
  1. `npm run gen-student-bundles`
  2. `localhost` is blacklisted for bundles on `staging`, so use `dev` for local development when working on bundles.
  3. load fixtures on dev
*/

export interface IProjection {
  [path: string]: true | 1;
}

export interface IData {
  editablePaths?: string[];
  paths: string[];
  staticPaths?: string[];
  values: any[][];
}

interface IBundleData {
  redirectTo?: string;
}

interface IItems {
  begin: number;
  end: number;
  limit: number;
  total: number;
}

interface IPages {
  current: number;
  hasNext: boolean;
  hasPrev: boolean;
  next: number;
  prev: number;
  total: number;
}

interface IStudentDataRes {
  data: IData;
  items: IItems;
  pages: IPages;
}

export interface IFetchDataFromBundleRes<T> {
  data: T[];
  paths: string[];
}

export interface IFetchDataFromEndpointRes<T> {
  data: T[];
  paths: string[];
  bundleProjection: IProjection;
}

interface IJoinedDataRes<T> {
  joinedData: T[];
  unmatchedStudentsExist: boolean;
  bundleDataMismatches: string[];
  endpointDataMismatches: string[];
}

/* istanbul ignore next */
@Injectable()
export class StudentFetchService {
  readonly STUDENT_LIST_ENDPOINT_URL: string;
  readonly STATIC_BUNDLES: boolean;
  readonly LOG_STUDENT_BUNDLE_MISMATCHES: boolean;
  readonly LOG_PROJECTION_BUNDLE_MISMATCHES: boolean;
  readonly LOG_SDC_BUNDLE_MISMATCHES: boolean;

  private publicConfig: PortalConfig['publicConfig'];

  constructor (
    private $http: HttpClient,
    private utilitiesService: UtilitiesService,
    private studentFetchMssgsService: StudentFetchMssgsService,
    private studentFetchUnzipService: StudentFetchUnzipService,
    private portalConfig: PortalConfig,
  ) {
    this.publicConfig = this.portalConfig.publicConfig;

    const {
      NV_API_ORIGIN,
      LOG_PROJECTION_BUNDLE_MISMATCHES,
      LOG_SDC_BUNDLE_MISMATCHES,
      LOG_STUDENT_BUNDLE_MISMATCHES,
      STATIC_BUNDLES,
    } = this.publicConfig;
    this.STUDENT_LIST_ENDPOINT_URL = NV_API_ORIGIN + '/v1/students/search';
    this.STATIC_BUNDLES = STATIC_BUNDLES;
    this.LOG_SDC_BUNDLE_MISMATCHES = LOG_SDC_BUNDLE_MISMATCHES;
    this.LOG_STUDENT_BUNDLE_MISMATCHES = LOG_STUDENT_BUNDLE_MISMATCHES;
    this.LOG_PROJECTION_BUNDLE_MISMATCHES = LOG_PROJECTION_BUNDLE_MISMATCHES;
  }

  // Fetches student data.
  // Depending on config, it fetches data from the student list endpoint or both the endpoint and bundles.
  // Returns array of students with each obj containing the specified projection, regardless of bundle configuration.
  // Using generic <T> instead of IStudent, since the shape of a student returned is dependent on the projection. (CM)
  async fetchStudentData<T> (opts: {
    bundleName?: any;
    fullProjection: IProjection;
    joins: any;
    projection: IProjection;
    schoolId: string;
    includeStudentTypeFilter: boolean;
    isEms: boolean;
    whereFilter: object;
  }): Promise<T[]> {
    const { bundleName, fullProjection, joins, projection, schoolId, includeStudentTypeFilter, isEms, whereFilter } = opts;
    const fetchDataFromEndpointOpts = { schoolId, bundleName, projection, fullProjection, joins, includeStudentTypeFilter, isEms, whereFilter };
    const endpointData = this.fetchDataFromEndpoint<T>(fetchDataFromEndpointOpts);
    let endpointDataResolved: IFetchDataFromEndpointRes<T>;

    if (bundleName && !isEms) {
      if (this.STATIC_BUNDLES) {
        const fetchBundleOpts = { bundleName, schoolId };
        const bundleData = this.fetchBundle<T>(fetchBundleOpts);
        try {
          endpointDataResolved = await endpointData;
        } catch (err) {
          // We throw an error to get a stack trace (CM)
          throw this.utilitiesService.handleAwaitErr(err);
        }

        let bundleDataResolved: IFetchDataFromBundleRes<T>;
        try {
          bundleDataResolved = await bundleData;
        } catch (err) {
          // We throw an error to get a stack trace (CM)
          throw this.utilitiesService.handleAwaitErr(err);
        }

        const { joinedData, unmatchedStudentsExist, bundleDataMismatches, endpointDataMismatches } = this.joinData<T>(
          bundleDataResolved.data,
          endpointDataResolved.data,
        );

        if (this.LOG_STUDENT_BUNDLE_MISMATCHES && unmatchedStudentsExist) {
          const warningForBundleMismatchOpts = { schoolId, bundleName, bundleDataMismatches, endpointDataMismatches };
          // Student list endpoint and bundle results are mismatched.
          this.studentFetchMssgsService.logWarningForBundleMismatch(warningForBundleMismatchOpts);
        }

        if (this.LOG_PROJECTION_BUNDLE_MISMATCHES) {
          const { bundleProjection } = endpointDataResolved;
          const { paths: pathsInBundle } = bundleDataResolved;
          const logMismatchedBundleProjectionOpts = { bundleName, bundleProjection, pathsInBundle };
          this.studentFetchMssgsService.logMismatchedBundleProjection(logMismatchedBundleProjectionOpts);
        }

        // If bundles are ON, return joined data from bundle and student list endpoint. (CM)

        return joinedData;
      } else {
        // Bundles are OFF in publicConfig
        this.studentFetchMssgsService.logBundlesOffInPublicConfig();
      }
    }

    // If bundles are OFF, return all data from student list endpoint. (CM)
    try {
      endpointDataResolved = await endpointData;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    return endpointDataResolved.data;
  }

  private joinData<T> (bundleData: T[], endpointData: T[]): IJoinedDataRes<T> {
    const {
      mergedObjs: joinedData,
      unmatchedIdsForArr1: bundleDataMismatches,
      unmatchedIdsForArr2: endpointDataMismatches,
    } = this.utilitiesService.mergeArrayObjsById<T>(bundleData, endpointData);
    const unmatchedStudentsExist = !!(bundleDataMismatches.length || endpointDataMismatches.length);

    return { joinedData, unmatchedStudentsExist, bundleDataMismatches, endpointDataMismatches };
  }

  private async fetchDataFromEndpoint<T> (opts: {
    bundleName?: AnyAaaaRecord;
    fullProjection: IProjection;
    joins: any;
    projection: IProjection;
    schoolId: string;
    includeStudentTypeFilter: boolean;
    isEms: boolean;
    whereFilter: object;
  }): Promise<IFetchDataFromEndpointRes<T>> {
    const { bundleName, fullProjection, joins, projection, schoolId, includeStudentTypeFilter, isEms, whereFilter } = opts;
    let diffProjection: IProjection;
    let diffJoins: string[];
    let bundleProjection: IProjection;

    if (bundleName && this.STATIC_BUNDLES && !isEms) {
      // Get projection for bundle and diff projection to get the fields not in the bundle
      // from the student list endpoint. (CM)
      try {
        bundleProjection = await this.fetchBundleProjection(bundleName);
      } catch (err) {
        // We throw an error to get a stack trace (CM)
        throw this.utilitiesService.handleAwaitErr(err);
      }

      // Remove fields included in static bundle from request to student list endpoint. (CM)
      diffProjection = this.utilitiesService.diffProjection(bundleProjection, projection);
      // remove the joins included in static bundle already so that no aditional joins will be sent to student list endpint to maximize the usage of the bundles (jchu)
      diffJoins = this.utilitiesService.diffJoinsForWhenBundleIsOn(joins, diffProjection, bundleProjection);

      if (this.LOG_SDC_BUNDLE_MISMATCHES) {
        const logMismatchedBundlePathsOpts = {
          bundleName,
          bundleProjection,
          fullProjection,
        };

        this.studentFetchMssgsService.logMismatchedBundlePaths(logMismatchedBundlePathsOpts);
      }

      // Always include at least "_id" in the projection
      diffProjection._id = true;
    } else {
      // Prevents this message from being displayed when not fetching a bundle

      if (bundleName) {
        console.warn(
          'Static bundles are turned off in publicConfig. ' +
            'Fetching editable and static data from student list search endpoint.',
        );
      }

      // get the entire projection from the student list endpoint
      diffProjection = projection;
      diffJoins = joins;
    }

    const url = this.STUDENT_LIST_ENDPOINT_URL;
    // remove the joins included in static bundle already so that no aditional joins will be sent to student list endpint to maximize the usage of the bundles
    const data: {
      schoolId: string;
      projection: IProjection;
      joins: string[];
      includePathTypes: boolean;
      where?: object;
    } = {
      schoolId,
      projection: diffProjection,
      joins: diffJoins,
      // `includePathTypes` makes response include `staticPaths` and `editablePaths`.
      // `staticPaths` includes the fields in the projection that are static.
      // `editablePaths` includes the fields in the projection that are editable (CM).
      includePathTypes: !!this.LOG_SDC_BUNDLE_MISMATCHES,
    };

    if (includeStudentTypeFilter) {
      let studentTypeFilter;

      if (isEms) {
        studentTypeFilter = {
          $or: [{ isES: true }, { isMS: true }],
        };
      } else {
        studentTypeFilter = { isHS: { $eq: true } };
      }
      data.where = { ...studentTypeFilter };
    }

    if (whereFilter) {
      data.where = { ...data.where, ...whereFilter };
    }

    const config = { params: { schoolId } };
    let req: Promise<IStudentDataRes>;
    let res: IStudentDataRes;
    try {
      req = this.$http.post<IStudentDataRes>(url, data, config).toPromise();
      res = await req;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    const zippedData = res && res.data ? res.data : { paths: [], values: [], staticPaths: [], editablePaths: [] };
    const unzippedData = this.studentFetchUnzipService.unzipData<T>(zippedData);
    const { paths, staticPaths } = zippedData;

    if (this.LOG_SDC_BUNDLE_MISMATCHES) {
      const logMismatchedStaticPathsOpts = { bundleName, staticPaths };
      this.studentFetchMssgsService.logMismatchedStaticPaths(logMismatchedStaticPathsOpts);
    }

    return { data: unzippedData, paths, bundleProjection };
  }

  // Returns the projection for the bundle stored in bundlePaths.json on the backend (CM).
  private async fetchBundleProjection (bundleName: AnyAaaaRecord): Promise<IProjection> {
    const bundleProjectionUrl = `${this.publicConfig.NV_API_ORIGIN}/v1/students/bundleProjections?name=${bundleName}`;
    let req: Promise<IProjection>;
    let res: IProjection;
    try {
      req = this.$http.get<IProjection>(bundleProjectionUrl).toPromise();
      res = await req;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    return res;
  }

  // requests static bundle (CM)
  private async fetchBundle<T> (opts: {
    bundleName: AnyAaaaRecord;
    schoolId: string;
  }): Promise<IFetchDataFromBundleRes<T>> {
    const { bundleName, schoolId } = opts;
    const bundleUrl = this.publicConfig.NV_API_ORIGIN + `/v1/students/bundles/${bundleName}?schoolId=${schoolId}`;
    // 1. If `ENV.STATIC_BUNDLES = true` on backend - res will be aws link to fetch bundle.
    // 2. If `ENV.STATIC_BUNDLES = false` on backend - res will be actual student data fetched
    // from the DB. (CM)
    let req: Promise<IBundleData & IStudentDataRes>;
    let res: IBundleData & IStudentDataRes;
    try {
      req = this.$http.get<IBundleData & IStudentDataRes>(bundleUrl).toPromise();
      res = await req;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    const { redirectTo: url } = res;
    let data: IData;

    if (url) {
      // If server responds with a `redirectTo` url, then we do a "manual redirect" to fetch from that url.
      // The reason for this manual redirect is if we let the browser to it via a 302, then the redirected
      // request will still include the Authorization header (exposing the client JWT to AWS). Here, we are
      // able to manually suppress the Authorization header for the AWS request. (CM)
      try {
        data = await this.fetchBundleFromAws(url);
      } catch (err) {
        // We throw an error to get a stack trace (CM)
        throw this.utilitiesService.handleAwaitErr(err);
      }
    } else {
      // Bundles turned off on backend, so server responded with the student data directly. (CM)
      console.warn('Static bundles are turned off in the backend.');
      data = res && res.data;
    }

    const unzipedData = this.studentFetchUnzipService.unzipData<T>(data);
    const { paths } = data;

    return { data: unzipedData, paths };
  }

  private async fetchBundleFromAws (url: string): Promise<IData> {
    let req: Promise<IStudentDataRes>;
    let res: IStudentDataRes;
    try {
      req = this.$http.get<IStudentDataRes>(url).toPromise();
      res = await req;
    } catch (err) {
      // We throw an error to get a stack trace (CM)
      throw this.utilitiesService.handleAwaitErr(err);
    }

    const data = res && res.data;
    return data;
  }
}
