import { Injectable } from '@angular/core';

@Injectable()
export class ListHeaderService {
  getInfiniteHeaderIcon(instance: any): { name: string; tooltip: string; handler: Function } {
    const handler = () => (instance.aniState = 'collapsed');
    return {
      name: 'close',
      tooltip: 'Close',
      handler,
    };
  }

  getFixedHeaderIcon(): { name: string; tooltip: string; handler: Function } {
    return {
      name: null,
      tooltip: null,
      handler: () => {
        // noop
      },
    };
  }
}
