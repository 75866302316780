import { BACKGROUND_JOB_TIMEOUT_THRESHOLDS, BACKGROUND_JOB_STATUS_TYPES } from './../../shared/services/background-job/background-job.service';
import { identity } from 'lodash';
import { forwardRef, Inject, Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap, map, timeout } from 'rxjs/operators';
import { BackgroundJobNotificationService } from 'Src/ng2/shared/services/background-job-notification-service/background-job-notification-service';
import { SnackBarService } from './../../shared/services/snackbar/snackbar.service';
import { ModalsService } from '../../shared/modals/modals.service';
import { BackgroundJob } from '../../shared/services/background-job/background-job.service';
import * as studentActivitiesActions from '../actions/student-activities-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import {
  IBulkUpdateStudentActivitiesParams,
  ICreateStudentActivityParams,
} from './../../shared/typings/interfaces/student-activity.interface';
import { IApi } from 'Src/ng2/shared/services/api-service/api-service.interface';
import { BatchActionsEffectsUtilities } from '../utilities/batch-actions-effects-utilities';

@Injectable()
export class StudentActivitiesEffects {
  constructor (
    private actions$: Actions,
    private apiService: ApiService,
    private injector: Injector,
    private backgroundJob: BackgroundJob,
    @Inject(forwardRef(() => ModalsService)) private modalsService: ModalsService,
    private snackBarService: SnackBarService,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
  ) {}

  loadStudentActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentActivitiesActions.LOAD_STUDENT_ACTIVITIES),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, where } = action.payload;
        return this.apiService.getStudentActivities({ schoolId, where }).pipe(
          switchMap((studentActivities: any) => [
            new studentActivitiesActions.LoadStudentActivitiesSuccess(studentActivities),
          ]),
          catchError(error => of(new studentActivitiesActions.LoadStudentActivitiesFail(error))),
        );
      }),
    );
  });

  updateStudentActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentActivitiesActions.UPDATE_STUDENT_ACTIVITY),
      switchMap((action: { type: string; payload: any }) => {
        const { studentActivityId, patch } = action.payload;
        
        return this.apiService.patchStudentActivity({ studentActivityId, patch }).pipe(
          switchMap((studentActivity: any) => {
            const payload = {
              studentActivityId,
              patch,
            };
            return [new studentActivitiesActions.UpdateStudentActivitySuccess(payload)];
          }),
          catchError(error => {
            return of(new studentActivitiesActions.UpdateStudentActivityFail(error));
          }),
        );
      }),
    );
  });

  createStudentActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentActivitiesActions.CREATE_STUDENT_ACTIVITY),
      switchMap((action: { type: string; payload: { studentActivityParams: ICreateStudentActivityParams } }) => {
        const { studentActivityParams } = action.payload;

        return this.apiService.createStudentActivity(studentActivityParams).pipe(
          switchMap((studentActivity: any) => {
            return [new studentActivitiesActions.CreateStudentActivitySuccess(studentActivity)];
          }),
          catchError(error => {
            return of(new studentActivitiesActions.CreateStudentActivityFail(error));
          }),
        );
      }),
    );
  });

  bulkUpdateStudentActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(studentActivitiesActions.BULK_UPDATE_STUDENT_ACTIVITIES),
      switchMap((action: any) => {
        const payload: IBulkUpdateStudentActivitiesParams = action.payload;
        const { origin } = payload;
        return this.apiService.bulkUpdateStudentActivities(payload).pipe(
          map((response: IApi['bulkUpdateStudentActivitiesRes']) =>
            BatchActionsEffectsUtilities.getJobSubjectFromGraphql(this.backgroundJob, {
              response,
              queryName: 'bulkUpdateStudentActivity',
            }),
          ),
          tap(subject => {
            this.modalsService.openBackgroundJobSpinnerModal({ backgroundJobSubject: subject, title: 'Updating activities' });
          }),
          switchMap(identity),
          timeout(BACKGROUND_JOB_TIMEOUT_THRESHOLDS.BATCH_ACTIONS),
          tap(({ type }) => {
            if (type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE) {
              BatchActionsEffectsUtilities.sendSnack(this.snackBarService, { success: true, origin });
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentActivityUpdate' });
            }
          }),
          catchError(error => {
            // open modal with error
            const payload = {
              title: 'Unable to Complete Batch Action',
              message: 'The batch action could not be applied to all of the selected students. Please try again.',
              confirmText: 'Ok',
            };
            this.modalsService.openErrorModal(payload);
            return of(new studentActivitiesActions.BulkUpdateStudentActivitiesFail(error));
          }),
        );
      }),
    );
  }, { dispatch: false });
}
