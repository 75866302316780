import { Injectable } from '@angular/core';
import { every, map, reduce, sortBy } from 'lodash';
import { IACOption } from '../../../../nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { DateHelpers } from '../../services/date-helpers/date-helpers.service';
import { ImActivity } from '../../services/im-models/im-activity.service';
import { IActivity } from '../../typings/interfaces/activity.interface';

@Injectable()
export class StudentActivityModalHelpersService {
  constructor (public imActivity: ImActivity, public dateHelpers: DateHelpers) {}

  public activitiesToACOptions (activities: IActivity[]): IACOption[] {
    return map(activities, activity => {
      const sortedCourses = sortBy(activity.linkedCourses, course => course);
      const tags = reduce(
        sortedCourses,
        (acc, course) => {
          if (course) {
            const { code, name } = course;
            const tag = { key: code, human: `${name} (${code})` };
            acc.push(tag);
          }
          return acc;
        },
        [],
      );
      const val = this.imActivity.getActivityTitle(activity);
      const opt: IACOption = {
        key: val,
        human: val,
        tags,
      };
      return opt;
    });
  }

  public _filterActivities (searchText: string = '', activities: IActivity[] = [], schoolYear?: string): IACOption[] {
    const filterValues = searchText
      .toLowerCase()
      .split(/[ ,]+/)
      .filter(val => val !== 'at' && val !== '-');

    const filteredActivities = activities
      .filter(option => {
        const {
          type,
          partnerOrg: { name },
          sector,
          startDate,
          endDate,
          linkedCourses,
          terms: {
            startTerm: { schoolYear: activitySchoolYear },
          },
        } = option;
        if (schoolYear) {
          const parsedYear = 'SY20' + schoolYear.slice(2);
          const schoolYearMatch = activitySchoolYear === schoolYear || activitySchoolYear === parsedYear;
          if (!schoolYearMatch) return false;
        }
        const startString = this.dateHelpers.getFormattedMoment(startDate, 'MMMM, DD');
        const endString = this.dateHelpers.getFormattedMoment(endDate, 'MMMM, DD');
        const fullActivity = `${type} ${name} ${sector} ${startString} ${endString}`;
        const subtitle = reduce(
          linkedCourses,
          (acc, course) => {
            if (course) acc.push(`${course.name} (${course.code})`);
            return acc;
          },
          [],
        ).join();
        // const subtitle = map(linkedCourses, course => `${course.name} (${course.code})`).join();
        return every(filterValues, val => {
          const activityNameMatch = fullActivity.toLowerCase().includes(val);
          const linkedCourseMatch = subtitle.toLowerCase().includes(val);
          return activityNameMatch || linkedCourseMatch;
        });
      })
      .slice(0, 100);

    // coerce into IACOption shape for nv-textbox component (JE);
    const shapedFilteredActivities = this.activitiesToACOptions(filteredActivities);
    return shapedFilteredActivities;
  }
}
