import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import * as icons from '../assets/icons';
import { toKebabFromPascal } from '../utilities/helpers';

@Injectable()
export class NvIconService {
  constructor(private _registryService: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this.registerAllIcons();
  }
  registerAllIcons() {
    Object.entries(icons).forEach(icon => {
      this.registerIcon(icon[0], icon[1]);
    });
  }
  registerIcon(name: string, svg: string) {
    const svgElement = this._domSanitizer.bypassSecurityTrustHtml(svg);
    const iconName = toKebabFromPascal(name);
    this._registryService.addSvgIconLiteral(iconName, svgElement);
  }
}
