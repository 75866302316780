import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as activitiesActions from '../actions/activities-actions';
import { ApiService } from './../../shared/services/api-service/api-service';
import { IActivity, ICreateActivityParams } from './../../shared/typings/interfaces/activity.interface';

@Injectable()
export class ActivitiesEffects {
  constructor(
    private actions$: Actions,
    private apiService: ApiService,
    private store: Store<any>,
    private injector: Injector,
  ) {}

  loadActivities$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(activitiesActions.LOAD_ACTIVITIES),
      switchMap((action: { type: string; payload: any }) => {
        const { schoolId, where } = action.payload;
        return this.apiService.getActivities({ schoolId, where }).pipe(
          switchMap((activities: any) => [new activitiesActions.LoadActivitiesSuccess(activities)]),
          catchError(error => of(new activitiesActions.LoadActivitiesFail(error))),
        );
      }),
    );
  });

  updateActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(activitiesActions.UPDATE_ACTIVITY),
      switchMap((action: { type: string; payload: any }) => {
        const { activityId, patch } = action.payload;
  
        return this.apiService.patchActivity({ activityId, patch }).pipe(
          switchMap((response: { data: { updateActivity: IActivity } }) => {
            const { updateActivity } = response.data;
            const result = { activityId: updateActivity._id, patch: updateActivity };
            return [new activitiesActions.UpdateActivitySuccess(result)];
          }),
          catchError(error => {
            return of(new activitiesActions.UpdateActivityFail(error));
          }),
        );
      }),
    );
  });
  
  createActivity$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(activitiesActions.CREATE_ACTIVITY),
      switchMap((action: { type: string; payload: { activityParams: ICreateActivityParams } }) => {
        const { activityParams } = action.payload;

        return this.apiService.createActivity(activityParams).pipe(
          switchMap((activity: any) => {
            return [new activitiesActions.CreateActivitySuccess(activity)];
          }),
          catchError(error => {
            return of(new activitiesActions.CreateActivityFail(error));
          }),
        );
      }),
    );
  });
}
