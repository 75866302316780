import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { uniq, isEqual, differenceWith } from 'lodash';
import { Store } from '@ngrx/store';

import { UpdateSchool } from './../../../../store/actions/school-actions';
import { unsubscribeComponent } from 'Src/ng2/shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IPickerOption } from 'Src/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';

const mapSubjectToLongName = {
  ela: 'ELA',
  alg: 'Algebra',
  geom: 'Geometry',
  trig: 'Trigonometry',
  livingEnv: 'Living Environment',
  earth: 'Earth Science',
  chem: 'Chemistry',
  physics: 'Physics',
  global: 'Global',
  us: 'US',
  lote: 'LOTE',
};

interface IprepCourse {
  courseId: string;
  coursePrepsFor: string;
}

@Component({
  selector: 'remove-prep-modal',
  templateUrl: './remove-prep-modal.component.html',
  styleUrls: ['./remove-prep-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class RemovePrepModalComponent implements OnInit {
  public iconName = 'close';
  public title = 'Remove prep';
  public subtitle: string;
  public numOfCoursesSelected: number;
  public selectedSubjects: string[] = [];
  public subjectOptions: IPickerOption[];

  constructor (
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<RemovePrepModalComponent>,
    private store: Store<any>,
  ) {
    // ..
  }

  ngOnInit (): void {
    this.numOfCoursesSelected = this.data.coursesId.length;
    this.subtitle = (this.numOfCoursesSelected > 1) ? `${this.numOfCoursesSelected} courses selected` : `${this.numOfCoursesSelected} course selected`;
    this.subjectOptions = this.getSubjectOptions();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onConfirm (): void {
    this._prepPayloadForApi();
  }

  private getSubjectOptions (): IPickerOption[] {
    // Compare all courses in the list with couresId from batch actions and filter only courses that will be updated
    const coursesToUpdate:IprepCourse[] = this._getCoursesToUpdate();

    // Remove duplicate subjects
    const uniqueSubjects = uniq(coursesToUpdate.map(course => course.coursePrepsFor));

    // Construct options array
    const subjectOptions = uniqueSubjects.map(subject => (
      {
        key: subject,
        human: mapSubjectToLongName[subject],
      }
    ));

    return subjectOptions;
  }

  private _getCoursesToUpdate () {
    const coursesToUpdate:IprepCourse[] = this.data.allCurrentUserPrepCourses.filter((course) => {
      return this.data.coursesId.some(id => {
        return id === course.courseId;
      });
    });
    return coursesToUpdate;
  }

  private _prepPayloadForApi (): void {
    const currentUserEnteredPrepCourses = this.data.allCurrentUserPrepCourses;
    const selectedPrepSubjects = this.selectedSubjects;
    const coursesToUpdate = this._getCoursesToUpdate();

    // Filter courses that include selected(prep) subjects to remove
    const mapOfSubjects = selectedPrepSubjects.reduce((acc, elem) => {
      acc[elem] = elem;
      return acc;
    }, {});

    const coursesWithPrepsToRemove = coursesToUpdate.filter(course => {
      return course.coursePrepsFor === mapOfSubjects[course.coursePrepsFor];
    });

    const coursesToKeep = this._getArraysDifference(currentUserEnteredPrepCourses, coursesWithPrepsToRemove);

    // Construct payload needed to updated school
    const payload = {
      schoolId: this.data.schoolId,
      patch: {
        userEnteredPrepCourses: coursesToKeep,
      },
    };
    this._sendToApi(payload);
  }

  private _sendToApi (payload): void {
    this.store.dispatch(new UpdateSchool(payload));
    this.dialogRef.close(true);
  }

  // Helper method to filter courses that are in both arrays.
  // If the course exists in both arrays, it means that we want to remove
  private _getArraysDifference = (arrayToInspect, valuesToExclude) => {
    return differenceWith(arrayToInspect, valuesToExclude, isEqual);
  };
}
