import { Injectable } from '@angular/core';
import { differenceWith, find, includes, isEqual, reduce, unionWith } from 'lodash';
import { IPointPerson } from '../../typings/interfaces/student.interface';
import { IUserMini } from '../../typings/interfaces/user.interface';
import { DateHelpers } from './../../services/date-helpers/date-helpers.service';
import { studentEditableMap } from './../../constants/paths/student-editable-map.constant';
import { UtilitiesService } from '../../services/utilities/utilities.service';
import { CreditRequirements } from '../../constants/credit-requirements.constant';
import { orderedHescOptionsHuman } from 'Src/ng2/student/common-panels/student-postsec/student-postsec.config';
import { IHistoryLogsFilterOptions, IDocLog } from '../../typings/interfaces/docLogs.interface';
import moment from 'moment';
import { TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { IPickerOption } from 'Src/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';

export interface IHistoryRecord {
  id: string;
  condensed: string;
  expanded: string;
  age: string;
  panelOpen: boolean;
  createdAt: string;
  collection: string;
  modal?: string; // cases where the history logs modal needs to display custom text
}

export interface IHistoryRecordText {
  condensed: string;
  expanded: string;
  modal?: string; // If the history logs modal needs to display custom data
}
const flagPPTypes = ['ATTENDANCE', 'ACADEMIC', 'POSTSECONDARY', 'REGENTS', 'CREDITS'];

@Injectable()
export class HistoryAllHelperService {
  constructor (
    private dateHelpers: DateHelpers,
    private UtilitiesService: UtilitiesService,
  ) {}

  userFilters: IHistoryLogsFilterOptions = {
    categories: [],
    users: [],
    startDate: null,
    endDate: null,
  };

  getUserSelectedFilters (): IHistoryLogsFilterOptions {
    return this.userFilters;
  }

  saveUserFilters (userFilters: IHistoryLogsFilterOptions) {
    this.userFilters = userFilters;
  }

  // creates history card data based on docLog path (JE)
  constructHistoryRecord (docLog: IDocLog, district: string = 'NYC'): IHistoryRecord {
    const { createdAt, historyType, collection } = docLog;
    const time = this.dateHelpers.getMomentSinceVariableUnit(createdAt);
    const age = `${time.count}${time.unit}`;
    const panelOpen = false;
    let historyRecord = null;
    // create condensed and expanded text based on recordType (JE)
    switch (historyType) {
      case 'STUDENT_EDIT':
        historyRecord = this.createEditHistoryRecord(docLog);
        break;
      case 'STUDENT_FINANCIAL_AID_EDIT':
        historyRecord = this.createFinancialAidEditHistoryRecord(docLog);
        break;
      case 'STUDENT_SUPPORT_ASSIGN':
        historyRecord = this.createAssignHistoryRecord(docLog);
        break;
      case 'STUDENT_SUPPORT_UNASSIGN':
        historyRecord = this.createUnassignHistoryRecord(docLog);
        break;
      case 'STUDENT_SUPPORT_COMPLETE':
        historyRecord = this.createCompleteHistoryRecord(docLog);
        break;
      case 'STUDENT_SUPPORT_UPDATE':
        historyRecord = this.createUpdateHistoryRecord(docLog);
        break;
      case 'NOTE_ADDED':
        historyRecord = this.createNoteAddedHistoryRecord(docLog);
        break;
      case 'NOTE_UPDATED':
        historyRecord = this.createNoteUpdatedHistoryRecord(docLog, null); // Other Notes updates don't require bodyText
        break;
      case 'STUDENT_POINT_PERSON':
        historyRecord = this.createPointPeopleEditRecord(docLog);
        break;
      case 'MILESTONE_COMPLETE':
        historyRecord = this.createMilestoneRecord(docLog, 'complete');
        break;
      case 'MILESTONE_INCOMPLETE':
        historyRecord = this.createMilestoneRecord(docLog, 'incomplete');
        break;
      case 'STUDENT_PATH_ADDED':
        historyRecord = this.createStudentPathAddedRecord(docLog);
        break;
      case 'STUDENT_PATH_REMOVED':
        historyRecord = this.createStudentPathRemovedRecord(docLog);
        break;
      case 'STUDENT_PATH_UPDATED':
        historyRecord = this.createStudentPathStatusRecord(docLog);
        break;
      case 'STUDENT_ACTIVITY_ADDED':
        historyRecord = this.createStudentActivityAssignedRecord(docLog);
        break;
      case 'STUDENT_ACTIVITY_REMOVED':
        historyRecord = this.createStudentActivityRemovedRecord(docLog);
        break;
      case 'STUDENT_ACTIVITY_UPDATED':
        historyRecord = this.createStudentActivityUpdatedRecord(docLog);
        break;
      case 'STUDENT_ASSESSMENT_SCORE_UPDATE':
        historyRecord = this.createStudentAssessmentScoreRecord(docLog);
        break;
      case 'STUDENT_COURSE_DIFF_ADDED':
        historyRecord = this.createStudentCourseDiffsRecord(docLog);
        break;
      case 'STUDENT_COURSE_DIFF_UPDATED':
      case 'STUDENT_COURSE_DIFF_REMOVED':
        historyRecord = this.updateStudentCourseDiffsRecord(docLog);
        break;
      case 'STUDENT_GAP_PLAN_ADDED':
        historyRecord = this.createStudentGapPlanRecord(docLog);
        break;
      case 'STUDENT_GAP_PLAN_UPDATED':
      case 'STUDENT_GAP_PLAN_REMOVED':
        historyRecord = this.updatedStudentGapPlanRecord(docLog, district);
        break;
      case 'EXPERIENCE_ADDED':
        historyRecord = this.getCreateExperienceRecord(docLog);
        break;
      case 'EXPERIENCE_UPDATED':
        historyRecord = this.getUpdateExperienceRecord(docLog);
        break;
      case 'EXPERIENCE_REMOVED':
        historyRecord = this.getRemoveExperienceRecord(docLog);
      default:
        break;
    }

    let expanded;
    let condensed;
    let modal;
    if (historyRecord) {
      condensed = historyRecord.condensed;
      expanded = historyRecord.expanded;
      modal = historyRecord.modal;
      return {
        id: docLog._id,
        condensed,
        expanded,
        age,
        panelOpen,
        createdAt,
        collection,
        modal,
      };
    } else {
      return null;
    }
  }

  parseCreatedBy (user: IUserMini): string {
    const { firstName, lastName } = user;
    return `${firstName} ${lastName.substring(0, 1)}.`;
  }

  truncate (value: string): string {
    if (!value?.length) return '';
    return value.length > 20 ? value.substring(0, 39) + '...' : value;
  }

  createEditHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      diffs,
      metaData: { fieldHuman },
    } = docLog;
    let editableField = fieldHuman;
    let editableFieldCropped = this.truncate(editableField);
    const user = this.parseCreatedBy(createdBy);

    //if more than one field is changed in the same update, capture all the changes
    //if the editable field (A) is implied (the value is set internally based on another editable field (B), 
    //then the change is captured with B (not A) 
    const [condensed , expanded ] = diffs.reduce(([prevcondensed, prevexpanded], diff, index) => {
      let ishumanReadableFound = true;
      let subcondensed, subexpanded;
     if (diffs?.length > 1) 
     {
        const humanReadable = studentEditableMap[diff.path];
        if (humanReadable) {
          editableField = humanReadable;
          editableFieldCropped = this.truncate(editableField);
        }
        else {
          ishumanReadableFound = false;
        }
      }
      //Handle if humanReadable name is not found elegantly. 
      //This can occur intentionally if the edit is implied internally (Ex: isWaived for nextScheduledRegents.[subject])
      //It can also indicate a bug where studentEditableMap is not updated for fields.
      if (ishumanReadableFound) {
        let oldVal = diff.oldVal || '\u2014';
        // catch case where newVal is an array (JE)
        if (oldVal.length === 0) oldVal = '\u2014';
        let newVal = diff.newVal || '\u2014';
        if (newVal.length === 0) newVal = '\u2014';

        subcondensed =
          `<div>Changed <span class="history-value">${editableFieldCropped}</span> ` +
          `to <span class="history-value">${this.truncate(newVal)}</span></div>`;
          
        //Do not repeat user if multiple fields were changed in the same update
        let userText = `<div><span class="history-value">${user}</span> changed </div>`;
        subexpanded = ((index == 0) ? `<div><span class="history-value">${user}</span> changed ` : "") +
          `<div><span class="history-value">${editableField}</span> ` +
          `from <span class="history-value">${oldVal}</span> ` +
          `to <span class="history-value">${newVal}</span></div></div>`;
      }
     else 
     {
        subcondensed = '';
        subexpanded = '';
      }
        return [ (prevcondensed ? prevcondensed : '' ) + subcondensed,  (prevexpanded ? prevexpanded : '' ) + subexpanded];
    },"");
    return { condensed, expanded };
  }

  createFinancialAidEditHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      diffs,
      metaData: { studentSubDoc },
    } = docLog;

    const diffsWithMappedHumanValues = diffs.map(
      (diff) => {
        const updatedDiff = { ...diff };
        if (diff.path === 'hescEditable.tapStatus' || diff.path === 'hescEditable.fafsaStatus') {
          const subDocPath = diff.path.split('.')[1];
          const realHescVal = studentSubDoc ? studentSubDoc[subDocPath] : null;

          const oldVal = diff.oldVal || realHescVal;
          const newVal = diff.newVal || realHescVal;

          const oldValHuman = orderedHescOptionsHuman[oldVal] || '—';
          const newValHuman = orderedHescOptionsHuman[newVal] || '—';

          updatedDiff.oldVal = oldValHuman;
          updatedDiff.newVal = newValHuman;
        };
        return updatedDiff;
      }
    );

    return this.createEditHistoryRecord(
      {
        ...docLog,
        diffs: diffsWithMappedHumanValues
      }
    );
  }

  createAssignHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const { newDoc, createdBy } = docLog;
    const supportName = newDoc.support.name;
    const supportCropped = this.truncate(supportName);
    const user = this.parseCreatedBy(createdBy);

    const condensed = `Assigned to <span class="history-value">${supportCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> assigned student to ` +
      `<span class="history-value">${supportName}</span>`;
    return { condensed, expanded };
  }

  createUnassignHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { supportName },
    } = docLog;
    const supportCropped = this.truncate(supportName);
    const user = this.parseCreatedBy(createdBy);
    const condensed = `Unassigned from <span class="history-value">${supportCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> unassigned student from ` +
      `<span class="history-value">${supportName}</span>`;
    return { condensed, expanded };
  }

  createUpdateHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { supportName },
    } = docLog;
    const supportCropped = this.truncate(supportName);
    const user = this.parseCreatedBy(createdBy);
    const condensed = `Updated student's start/end date(s) in <span class="history-value">${supportCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> updated student's start/end date(s) ` +
      `in <span class="history-value">${supportName}</span>`;
    return { condensed, expanded };
  }

  createCompleteHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { supportName },
    } = docLog;
    const supportCropped = this.truncate(supportName);
    const user = this.parseCreatedBy(createdBy);
    const condensed = `Marked complete in <span class="history-value">${supportCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> marked student ` +
      `complete in <span class="history-value">${supportName}</span>`;
    return { condensed, expanded };
  }

  createNoteAddedHistoryRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      newDoc: { body }
    } = docLog;
    const noteCropped = this.truncate(body);
    const condensed = `Note: <span class="history-value">${noteCropped}</span>`;
    const expanded = `<span class="history-value-note">${body}</span>`;
    const modal = `Note: <span class="history-value">${body}</span>`;
    return { condensed, expanded, modal };
  }

  createNoteUpdatedHistoryRecord (docLog: IDocLog, bodyText: string): IHistoryRecordText {
    const { diffs } = docLog;
    const note = diffs[0].newVal;
    const noteValue = Array.isArray(note) ? note.join(', ') : note;
    const noteCropped = this.truncate(noteValue);
    const isDeletedNote = noteValue === 'DELETED';
    const deletedNoteText = 'Note deleted';

    const condensed = isDeletedNote ? deletedNoteText : `Note updated: <span class="history-value">${noteCropped}</span>`;
    const expanded = !isDeletedNote ? `<span class="history-value-note">${bodyText}</span>` : `<span class="history-value-note">${deletedNoteText}</span>`;
    const modal = isDeletedNote ? deletedNoteText : `Note updated: <span class="history-value">${noteCropped}</span>`;
    return { condensed, expanded, modal };
  }

  createPointPeopleEditRecord (docLog: IDocLog): IHistoryRecordText {
    const { diffs, createdBy } = docLog;
    const user = this.parseCreatedBy(createdBy);
    const oldPP: IPointPerson[] = diffs[0].oldVal;
    const newPP: IPointPerson[] = diffs[0].newVal;
    const ppAdded = oldPP.length < newPP.length;
    const ppRemoved = newPP.length < oldPP.length;
    const ppEdited = oldPP.length === newPP.length;

    if (ppAdded) {
      // should be a single point person object (JE)
      const ppDiff: IPointPerson = differenceWith(newPP, oldPP, isEqual)[0];
      const isSupportLeadType = ppDiff.type === 'SUPPORT_LEAD';
      if (isSupportLeadType) return null;
      // convert `GUIDANCE_COUNSELOR` to `Guidance counselor` and `ACADEMIC` to `Academic`
      let parsedType = ppDiff.type
        .toLowerCase()
        .split('_')
        .join(' ');
      parsedType = parsedType.charAt(0).toUpperCase() + parsedType.slice(1);
      const diffString = includes(flagPPTypes, ppDiff.type) ? `${parsedType} point person` : parsedType;
      const newPPName = `${ppDiff.user.firstName} ${ppDiff.user.lastName}`;
      const condensed = `Changed <span class="history-value">${diffString}</span>`;
      const expanded =
        `<span class="history-value">${user}</span> changed` +
        ` <span class="history-value">${diffString}</span>` +
        ` from <span class="history-value">—</span> to <span class="history-value">${newPPName}</span>`;
      return { condensed, expanded };
    }

    if (ppRemoved) {
      const ppDiff: IPointPerson = differenceWith(oldPP, newPP, isEqual)[0];
      const isSupportLeadType = ppDiff.type === 'SUPPORT_LEAD';
      if (isSupportLeadType) return null;
      let parsedType = ppDiff.type
        .toLowerCase()
        .split('_')
        .join(' ');
      parsedType = parsedType.charAt(0).toUpperCase() + parsedType.slice(1);
      const diffString = includes(flagPPTypes, ppDiff.type) ? `${parsedType} point person` : parsedType;
      const removedPPName = `${ppDiff.user.firstName} ${ppDiff.user.lastName}`;
      const condensed = `Changed <span class="history-value">${diffString}</span>`;
      const expanded =
        `<span class="history-value">${user}</span> changed` +
        ` <span class="history-value">${diffString}</span>` +
        ` from <span class="history-value">${removedPPName}</span>` +
        ' to <span class="history-value">—</span>';
      return { condensed, expanded };
    }

    if (ppEdited) {
      const oldPerson: IPointPerson = differenceWith(oldPP, newPP, isEqual)[0];
      if (!oldPerson) return null; // if oldPerson is undefined, meaning that there is no diff between oldPP and newPP, we skip creating history record in the UI
      const oldPersonName = `${oldPerson.user.firstName} ${oldPerson.user.lastName}`;
      const newPerson: IPointPerson = differenceWith(newPP, oldPP, isEqual)[0];
      const newPersonName = `${newPerson.user.firstName} ${newPerson.user.lastName}`;

      let parsedType = oldPerson.type
        .toLowerCase()
        .split('_')
        .join(' ');
      parsedType = parsedType.charAt(0).toUpperCase() + parsedType.slice(1);
      const diffString = includes(flagPPTypes, oldPerson.type) ? `${parsedType} point person` : parsedType;
      const condensed = `Changed <span class="history-value">${diffString}</span>`;
      const expanded =
        `<span class="history-value">${user}</span> changed` +
        ` <span class="history-value">${diffString}</span>` +
        ` from <span class="history-value">${oldPersonName}</span>` +
        ` to <span class="history-value">${newPersonName}</span>`;
      return { condensed, expanded };
    }
  }

  createMilestoneRecord (docLog: IDocLog, status: string): IHistoryRecordText {
    const {
      createdBy,
      metaData: { milestoneHuman },
    } = docLog;

    const milestoneHumanCropped = this.truncate(milestoneHuman);
    const user = this.parseCreatedBy(createdBy);

    const condensed = `Marked <span class="history-value">${milestoneHumanCropped}</span> ${status}`;
    const expanded =
      `<span class="history-value">${user}</span> marked ` +
      `<span class="history-value">${milestoneHuman}</span> milestone <span class="history-value">${status}</span>`;
    return { condensed, expanded };
  }

  createStudentPathAddedRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { college, pathCategory },
    } = docLog;
    const pathway = college || pathCategory;
    const studentPathCropped = this.truncate(pathway);
    const user = this.parseCreatedBy(createdBy);

    const condensed = `Added <span class="history-value">${studentPathCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> added ` +
      `<span class="history-value">${pathway}</span> to postsecondary list`;
    return { condensed, expanded };
  }

  createStudentPathRemovedRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { college, pathCategory },
    } = docLog;
    const pathway = college || pathCategory;
    const studentPathCropped = this.truncate(pathway);
    const user = this.parseCreatedBy(createdBy);

    const condensed = `Removed <span class="history-value">${studentPathCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> removed ` +
      `<span class="history-value">${pathway}</span> from postsecondary list`;
    return { condensed, expanded };
  }

  createStudentPathStatusRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      diffs,
      metaData: { college, pathCategory },
    } = docLog;
    const pathway = college || pathCategory;
    const { oldVal, newVal } = diffs[0];
    const studentPathCropped = this.truncate(pathway);
    const statusCropped = this.truncate(newVal);
    const user = this.parseCreatedBy(createdBy);

    const condensed =
      `Changed <span class="history-value">${studentPathCropped}</span> status ` +
      `to <span class="history-value">${statusCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> changed ` +
      `<span class="history-value">${pathway}</span> status from <span class="history-value">` +
      `${oldVal}</span> to <span class="history-value">${newVal}</span>`;
    return { condensed, expanded };
  }

  createStudentActivityAssignedRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { activityName },
      newDoc: { status, completedHours },
    } = docLog;

    const activityNameCropped = this.truncate(activityName);
    const user = this.parseCreatedBy(createdBy);
    let parsedStatus;
    if (status) {
      switch (status) {
        case 'COMPLETED':
          parsedStatus = 'complete';
          break;
        case 'INCOMPLETE':
          parsedStatus = 'incomplete';
          break;
        default:
          break;
      }
    }
    const condensed = `Assigned student to <span class="history-value">${activityNameCropped}</span>`;
    let expanded =
      `<span class="history-value">${user}</span> assigned student ` +
      `to <span class="history-value">${activityName}</span>`;

    // account for assignment with hours (JE)
    if (completedHours || completedHours === 0) { expanded += ` and changed completed hours to <span class="history-value">${completedHours}</span>`; }
    if (parsedStatus) expanded += ` and marked <span class="history-value">${parsedStatus}</span>`;

    return { condensed, expanded };
  }

  createStudentActivityUpdatedRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { activityName },
      diffs,
    } = docLog;
    const activityNameCropped = this.truncate(activityName);
    const user = this.parseCreatedBy(createdBy);
    const hoursDiff = find(diffs, diff => diff.path === 'completedHours');
    const statusDiff = find(diffs, diff => diff.path === 'status');
    const dateUpdateDiff = find(diffs, diff => diff.path === 'endDate' || diff.path === 'startDate');

    let parsedStatus;
    if (statusDiff) {
      if (statusDiff.newVal === 'COMPLETED') parsedStatus = 'complete';
      if (statusDiff.newVal === 'INCOMPLETE') parsedStatus = 'incomplete';
    }
    let condensed;
    let expanded;

    if (dateUpdateDiff) {
      condensed = `Updated student's start/end date(s) in <span class="history-value">${activityNameCropped}</span>`;
      expanded = `<span class="history-value">${user}</span> updated student's schedule ` +
        `in <span class="history-value">${activityName}</span>`;
    } else if (hoursDiff) {
      const { oldVal, newVal } = hoursDiff;
      const parsedOld = oldVal || oldVal === 0 ? oldVal : '–';
      const parsedNew = newVal || newVal === 0 ? newVal : '–';
      condensed = `Changed student <span class="history-value">completed hours</span> in <span class="history-value">${activityNameCropped}</span> to <span class="history-value">${parsedNew}</span>`;
      expanded = `<span class="history-value">${user}</span> changed student <span class="history-value">completed hours</span> in <span class="history-value">${activityName}</span> from <span class="history-value">${parsedOld}</span> to <span class="history-value">${parsedNew}</span>`;
      // accounts for text shape when hours and status have changed
      if (parsedStatus) {
        expanded += ` and marked <span class="history-value">${parsedStatus}</span>`;
      } else if (statusDiff && statusDiff.oldVal && !statusDiff.newVal) {
        expanded += ' and cleared completion status';
      }
    } else if (parsedStatus) {
      // accounts for only status change
      condensed = `Marked student <span class="history-value">${parsedStatus}</span> in <span class="history-value">${activityNameCropped}</span>`;
      expanded = `<span class="history-value">${user}</span> marked student <span class="history-value">${parsedStatus}</span> in <span class="history-value">${activityName}</span>`;
    } else if (statusDiff && !statusDiff.newVal) {
      // accounts for only status cleared
      condensed = `Cleared <span class="history-value">completion status</span> in <span class="history-value">${activityNameCropped}</span>`;
      expanded = `<span class="history-value">${user}</span> cleared completion status in <span class="history-value">${activityName}</span>`;
    }
    return { condensed, expanded };
  }

  createStudentActivityRemovedRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { activityName },
    } = docLog;

    const activityNameCropped = this.truncate(activityName);
    const user = this.parseCreatedBy(createdBy);

    const condensed = `Removed student from <span class="history-value">${activityNameCropped}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> removed student ` +
      `from <span class="history-value">${activityName}</span>`;
    return { condensed, expanded };
  }

  // ignored until language is finalized (JE)
  /* istanbl ignore next */
  createStudentAssessmentScoreRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      diffs,
      metaData: { assessmentNickName, adminNumber },
    } = docLog;
    const user = this.parseCreatedBy(createdBy);
    const { newVal } = find(diffs, diff => diff.path === 'items');
    const parsedVal = reduce(
      newVal,
      (result, score, key) => {
        const section = score.section;
        const value = score.value ? score.value : '-';
        result += `<span class="history-value">${section}</span> to <span class="history-value">${value} and</span> `;
        return result;
      },
      '',
    );
    const trimmedValue = parsedVal.substring(0, parsedVal.length - 11);
    const condensed =
      `<span class="history-value">${assessmentNickName} Admin ${adminNumber}</span> ` + `${trimmedValue}</span>`;
    const expanded =
      `<span class="history-value">${user}</span> changed <span class="history-value">${assessmentNickName} Admin ${adminNumber}</span> ` +
      `${trimmedValue}</span>`;
    return { condensed, expanded };
  }

  createStudentCourseDiffsRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      newDoc: {courseId, action, status, termYear },
    } = docLog;
    const user = this.parseCreatedBy(createdBy);
    const term = termYear ? this.UtilitiesService.getHumanReadableTerm(termYear) : '-';
    const isDeletedAction = action === 'DROP';
    const isPendingAction = status === 'PENDING';
    // Format of courseId is: "13W320-212-EES83-31". We need the course without schoolId(13W320) and termYear(212)
    const condensed = `${isDeletedAction ? 'Dropped' : 'Added'} <span class="history-value">${courseId.substring(11)}</span>
      ${isDeletedAction ? 'from' : 'to'} <span class="history-value">${term}</span>${isPendingAction && isDeletedAction ?
        ' (Pending Drop)' : ' (Pending Add)'}`;

    const expanded = `<span class="history-value">${user}</span> ${isDeletedAction ? 'dropped' : 'added'} <span class="history-value">${courseId.substring(11)}</span> ` +
      `${isDeletedAction ? 'from' : 'to'} <span class="history-value">${term}</span> ${isPendingAction && isDeletedAction ? ' (Pending Drop)' : ' (Pending Add)'}`;

    return { condensed, expanded };
  }

  updateStudentCourseDiffsRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      diffs,
      metaData: { courseId, termYear},
    } = docLog;

    const user = this.parseCreatedBy(createdBy);
    const status = diffs[0].path;
    const newVal = diffs[0].newVal;
    const term = termYear ? this.UtilitiesService.getHumanReadableTerm(termYear) : '-';
    const isDeletedAction = newVal === 'DROP';

    const condensed = `${isDeletedAction ? 'Dropped' : 'Updated'} <span class="history-value">${courseId.substring(11)}</span> ${isDeletedAction ?
      'from <span class="history-value">' + term + '</span>' : status}`;

    const expanded =`<span class="history-value">${user}</span>  ${isDeletedAction ? 'dropped' : 'updated ' + status + ' for '} <span class="history-value">${courseId.substring(11)}</span> 
      ${isDeletedAction ? 'from <span class="history-value">' + term + '</span>' : ''}`;

    return { condensed, expanded };
  }

  createStudentGapPlanRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      newDoc: { plan, status, termYear},
    } = docLog;

    const user = this.parseCreatedBy(createdBy);
    const termYearHuman = termYear ? this.UtilitiesService.getHumanReadableTerm(termYear) : '-';
    const isActiveStatus = status === 'ACTIVE';

    const condensed = `Added <span class="history-value">${plan}</span> to <span class="history-value">${termYearHuman}</span> ${isActiveStatus ? '' : '(Pending Add)'}`;

    const expanded = `<span class="history-value">${user}</span> added <span class="history-value">${plan}</span> 
      to <span class="history-value">${termYearHuman}</span> ${isActiveStatus ? '' : '(Pending Add)'}`;

    return { condensed, expanded };
  }

  updatedStudentGapPlanRecord (docLog: IDocLog, district: string): IHistoryRecordText {
    const {
      createdBy,
      diffs,
      metaData: { gapPlan, termYear},
    } = docLog;

    const course = gapPlan;
    const user = this.parseCreatedBy(createdBy);
    const term = termYear ? this.UtilitiesService.getHumanReadableTerm(termYear) : '-';
    const { path, oldVal, newVal } = this._getOldAndNewVals(diffs[0], district);
    const isDeletedStatus = newVal === 'DELETED';

    const condensed = `${isDeletedStatus ?
      'Dropped <span class="history-value">' + course + '</span>' :
      'Updated ' + path + ' for <span class="history-value">' + course + '</span>'} ${isDeletedStatus ? 'from <span class="history-value">' + term + '</span>' :
        ' from <span class="history-value"> ' + oldVal + '</span> to <span class="history-value">' + newVal + '</span>'}`;

    const expanded = `<span class="history-value">${user}</span> ${isDeletedStatus ? 'dropped ': 'updated ' + path + ' for '} 
      <span class="history-value">${course}</span> ${isDeletedStatus ? 'from <span class="history-value">' + term + '</span>' :
        ' from <span class="history-value"> ' + oldVal + '</span> to <span class="history-value">' + newVal + '</span>'}`;

    return { condensed, expanded };
  }

  getCreateExperienceRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { milestoneHuman },
      newDoc: { type, schoolYear },
    } = docLog;

    const user = this.parseCreatedBy(createdBy);

    const condensed = `Added <span class="history-value">${milestoneHuman} experience`;

    const expanded = `<span class="history-value">${user}</span> added <span class="history-value">${milestoneHuman}, ${type.toLowerCase()}</span> 
      experience <span class="history-value"> for the ${schoolYear} school year`;

    return { condensed, expanded };
  }

  getUpdateExperienceRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      diffs,
      metaData: { milestoneHuman, pathHumanMap },
    } = docLog;

    const user = this.parseCreatedBy(createdBy);

    const condensed = `Updated <span class="history-value">${milestoneHuman} </span>experience`;
    const expanded = diffs.reduce(
      (acc, { path, oldVal, newVal }) => {
        const pathHuman = pathHumanMap[path];
        acc += `<div><span class="history-value">${pathHuman}</span> from <span class="history-value">${oldVal || '—'}</span> to <span class="history-value">${newVal}</span></div>`;
        return acc;
      }, `<span class="history-value">${user}</span> updated  <span class="history-value">${milestoneHuman}</span> experience `,
    );

    return { condensed, expanded };
  }

  getRemoveExperienceRecord (docLog: IDocLog): IHistoryRecordText {
    const {
      createdBy,
      metaData: { milestoneHuman },
    } = docLog;

    const user = this.parseCreatedBy(createdBy);

    const condensed = `Removed <span class="history-value">${milestoneHuman} </span>experience`;
    const expanded = `<span class="history-value">${user}</span> removed  <span class="history-value">${milestoneHuman}</span>
    experience`;

    return { condensed, expanded };
  }

  _getOldAndNewVals (diff, district: string) {
    const { path, oldVal, newVal } = diff;
    switch (path) {
      case 'gradReq': {
        const humanOldVal = find(CreditRequirements, { camelCase: oldVal }).human[district];
        const humanNewVal = find(CreditRequirements, { camelCase: newVal }).human[district];
        return {
          path: 'Grad Requirement',
          oldVal: humanOldVal,
          newVal: humanNewVal,
        };
      }
      case 'status':
        return {
          path: 'Status',
          oldVal: oldVal,
          newVal: newVal,
        };
      case 'termYear': {
        const humanOldVal = oldVal ? this.UtilitiesService.getHumanReadableTerm(oldVal): '-';
        const humanNewVal = newVal ? this.UtilitiesService.getHumanReadableTerm(newVal): '-';
        return {
          path: 'Term',
          oldVal: humanOldVal,
          newVal: humanNewVal,
        };
      }
      case 'creditValue':
        return {
          path: 'Credit Value',
          oldVal: oldVal,
          newVal: newVal,
        };
      case 'plan':
        return {
          path: 'Plan',
          oldVal: oldVal,
          newVal: newVal,
        };
      default:
        return {
          path,
          oldVal,
          newVal,
        };
    }
  }

  // When the comparator returns true, the items are considered duplicates and only the first occurrence will be included in the new array.
  _filterDuplicateNoteLogs (sortedLogs) {
    return unionWith(sortedLogs, (logA: any, logB: any) => {
      return (logA.docId === logB.docId) && (logA.collection === 'notes');
    });
  };

  _filterDeletedNotes (noteLogs) {
    return noteLogs.filter(note => {
      const isDeletedNote = (note.historyType === 'NOTE_UPDATED' || note.historyType === 'SHELTER_NOTE_UPDATED' || note.historyType === 'SHELTER_NOTE_DELETED') && (note.diffs[0].path === 'status') && (note.diffs[0].newVal === 'DELETED');
      return !isDeletedNote;
    });
  };

  _checkIsAllNoteLogsDeleted (noteLogs) {
    return noteLogs.every(note => (note.historyType === 'NOTE_UPDATED' || note.historyType === 'SHELTER_NOTE_UPDATED' || note.historyType === 'SHELTER_NOTE_DELETED') && (note.diffs[0].path === 'status') && (note.diffs[0].newVal === 'DELETED'));
  }

  getDefaultUserSelectedFilters (): IHistoryLogsFilterOptions {
    return {
      categories: [],
      users: [],
      startDate: null,
      endDate: null,
    };
  }

  getFilterQueryOptions ({ userSelectedFilters, partnerType, isNotesView, studentId, caresId }: {userSelectedFilters: IHistoryLogsFilterOptions, partnerType: TValidPartnerTypes, isNotesView: boolean, studentId?: string, caresId?: string}): string {
    let whereOptions = {} as any;
    if (partnerType === 'shelter') {
      whereOptions = {
        caresId,
        contextPartnerType: partnerType,
        historyType: { $ne: null },
      };
    } else {
      whereOptions = {
        studentId,
        historyType: { $ne: null },
      };
    }

    if (isNotesView) whereOptions.collection = 'notes';

    // apply filters
    const { categories, users, startDate, endDate } = userSelectedFilters;
    if (categories && categories?.length > 0) whereOptions.tags = { $in: categories };
    if (users && users.length > 0) whereOptions['createdBy.userId'] = { $in: users };
    if (startDate) {
      const formattedStartDate = moment(startDate);
      const formattedEndDate = userSelectedFilters.endDate ? moment(endDate).add(1, 'days') : moment();
      whereOptions.createdAt = { $gte: formattedStartDate, $lte: formattedEndDate };
    };

    return JSON.stringify(whereOptions);
  }

  formatFiltersOptions (filterOptions: {categories: string[], users: {key: string, human: string}[]}): any {
    const categories = filterOptions?.categories;
    const users = filterOptions?.users;
    const formattedCategories = this.convertToCategoriesPickerArr(categories);
    return {
      categories: formattedCategories,
      users,
    };
  }

  convertToCategoriesPickerArr (arr: string[] = []): IPickerOption[] {
    return arr.map(ele => ({ key: ele, human: ele }));
  }

  checkForAppliedFilters (userFilters): boolean {
    if (userFilters?.startDate ||
      userFilters?.endDate ||
      userFilters?.categories.length ||
      userFilters?.users.length) return true;
    return false;
  }
}
