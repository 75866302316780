import { CaresIdsResolver } from '../../resolvers/cares-ids/cares-ids.resolver';
import { ShelterIdResolver } from '../../resolvers/shelter-id/shelter-id.resolver';
import { StandardLayoutComponent } from '../../../shelter/layouts/standard-layout/standard-layout.component';
import { FullScreenLayoutComponent } from '../../../shelter/layouts/full-screen-layout/full-screen-layout.component';
import { SyncCombinatorGuard } from '../../guards/sync-combinator-guard/sync-combinator-guard';
import { RouteGuard } from '../../guards/route-guard/route-guard';
import { CurrentUserGuard } from '../../guards/current-user-guard/current-user-guard';
import { TogglesGuard } from '../../guards/toggles-guard/toggles-guards';
import { SchoolSingleColumnLayoutComponent } from 'Src/ng2/school/layouts/school-single-column-layout/school-single-column-layout.component';
import { SchoolSsvSortResolver } from '../../resolvers/school-ssv/school-ssv-sort/school-ssv-sort.resolver';
import { NoDistrictRouteGuard } from '../../guards/no-district-route-guard/no-district-route-guard';

export const shelterRoute = {
  path: 'shelter',
  url: '/shelter',
  canActivate: [SyncCombinatorGuard],
  data: {
    guards: [NoDistrictRouteGuard, RouteGuard, CurrentUserGuard, TogglesGuard],
  },
  children: [
    {
      path: '',
      pathMatch: 'full',
      redirectTo: '/not-found',
    },
    {
      path: ':shelterId',
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'lists/attendance-list', // first default path
        },
        {
          path: 'lists',
          component: StandardLayoutComponent, // pass through
          loadChildren: () =>
            import('../../../shelter/list/shelter-list-routing.module').then(m => m.ShelterListRoutingModule),
          resolve: {
            shelter: ShelterIdResolver,
          },
        },
        {
          path: 'grid',
          component: FullScreenLayoutComponent, // pass through
          loadChildren: () =>
            import('../../../shelter/grid/attendance/shelter-att-grid.module').then(m => m.ShelterAttGridModule),
        },
        {
          path: 'profile',
          component: SchoolSingleColumnLayoutComponent, // pass through
          loadChildren: () =>
            import('../../../shelter/profile/shelter-profile.module').then(m => m.ShelterProfileModule),
          resolve: {
            sort: SchoolSsvSortResolver,
            caresIds: CaresIdsResolver,
          },
        },
      ],
    },
  ],
};
