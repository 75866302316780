import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'nv-card',
  templateUrl: './nv-card.component.html',
  styleUrls: ['./nv-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvCardComponent {
  /**
   * Value of the header
   */
  @Input() header: string;

  /**
   * Value of subtitle
   */
  @Input() subtitle: string;

  /**
   *
   * The name of the icon to display
   */
  @Input() icon: string;

  /**
   *
   * Text for button if it is null there will be no button
   */
  @Input() button: string;

  /**
   *
   * The row data in the card
   */
  @Input() rowData: string[][];

  @Output() clickButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickCell: EventEmitter<any> = new EventEmitter<any>();

  public onButtonClick (event: Event) {
    this.clickButton.emit(event); // Emit the `press` event
  }

  public onCellClick (event: Event) {
    this.clickCell.emit(event); // Emit the metadata event
  }
}
