import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { UserRolePermissionsForModel } from '../../../../ng2/shared/constants/user-role-permissions-for-model.constant';
import { UtilitiesService } from '../utilities/utilities.service';

type TValidModelNames =
  | 'ACTIVITY'
  | 'ATTENDANCE_RECORD'
  | 'COURSE_DIFF'
  | 'GAP_PLAN'
  | 'IM_SCHOOL'
  | 'IM_STUDENT'
  | 'SUPPORT'
  | 'NOTE'
  | 'NOTIFICATION'
  | 'USER';

type TValidActions = 'canCreate' | 'canDelete' | 'canEdit' | 'canView';

@Injectable()
export class UserRolePermissionsForModelService {
  constructor (private UtilitiesService: UtilitiesService, private imUser: ImUser) {}

  canCreatePartial (PERMISSIONING_MODEL_NAME: TValidModelNames, relationToUserPath?: string) {
    const action = 'canCreate';

    return this.roleAllowsForActionPartial(PERMISSIONING_MODEL_NAME, action, relationToUserPath);
  }

  canDeletePartial (PERMISSIONING_MODEL_NAME: TValidModelNames, relationToUserPath?: string) {
    const action = 'canDelete';

    return this.roleAllowsForActionPartial(PERMISSIONING_MODEL_NAME, action, relationToUserPath);
  }

  // returns function that takes viewingUser
  canEditPartial (PERMISSIONING_MODEL_NAME: TValidModelNames, relationToUserPath?: string) {
    const action = 'canEdit';

    return this.roleAllowsForActionPartial(PERMISSIONING_MODEL_NAME, action, relationToUserPath);
  }

  canViewPartial (PERMISSIONING_MODEL_NAME: TValidModelNames, relationToUserPath?: string) {
    const action = 'canView';

    return this.roleAllowsForActionPartial(PERMISSIONING_MODEL_NAME, action, relationToUserPath);
  }

  private getUserRolePermissionsForModel (PERMISSIONING_MODEL_NAME: TValidModelNames, action: TValidActions) {
    return UserRolePermissionsForModel[PERMISSIONING_MODEL_NAME][action];
  }

  // returns function that takes viewingUser
  roleAllowsForActionPartial (
    PERMISSIONING_MODEL_NAME: TValidModelNames,
    action: TValidActions,
    relationToUserPath?: string,
  ) {
    const userCanPerformActionMemo = {};
    const userRolesWithPermission = this.getUserRolePermissionsForModel(PERMISSIONING_MODEL_NAME, action);

    // to check permissioning for a specific obj/instance, pass the obj/instance as first argument
    // to check for MODEL wide permissioning (not specific to an obj/instance), set obj/instance to `undefined`/`null`.
    const roleAllowsForAction = (obj, viewingUser): boolean => {
      const viewingUserId = viewingUser._id;
      const objId = obj && obj._id;

      if (objId && !userCanPerformActionMemo[viewingUserId]) {
        userCanPerformActionMemo[viewingUserId] = {};
      }

      if (objId && userCanPerformActionMemo[viewingUserId][objId]) {
        return userCanPerformActionMemo[viewingUserId][objId];
      } else {
        let canPerformAction = false;
        if (_.includes(userRolesWithPermission, 'all')) {
          canPerformAction = true;
        } else if (this.imUser.isSuperAdmin(viewingUser, 'school') && this.imUser.isActive(viewingUser, 'school')) {
          canPerformAction = true;
        } else if (this.imUser.isSuperAdmin(viewingUser, 'shelter') && this.imUser.isActive(viewingUser, 'shelter')) {
          canPerformAction = true;
        } else if (_.includes(userRolesWithPermission, this.imUser.getRoleTypeOnAllLevels(viewingUser))) {
          canPerformAction = true;
        } else if (obj && relationToUserPath && _.includes(userRolesWithPermission, 'self')) {
          const objUserId = this.UtilitiesService.getFieldByPath(obj, relationToUserPath);
          const userId = viewingUser._id;

          if (objUserId === userId) {
            canPerformAction = true;
          }
        }

        return objId ? (userCanPerformActionMemo[viewingUserId][objId] = canPerformAction) : canPerformAction;
      }
    };

    return roleAllowsForAction;
  }
}
