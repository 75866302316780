import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';

@Component({
  selector: 'distance-to-graduation-settings',
  templateUrl: './distance-to-graduation-settings.component.html',
  styleUrls: ['./distance-to-graduation-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class DistanceToGraduationSettingsComponent implements OnInit, OnDestroy {
  public currentSchool: any;
  public pageHeaderMeta = {
    title: 'Distance to Graduation',
    subTitle: null,
    icon: {},
  };

  constructor (
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit (): void {
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;
  }

  ngOnDestroy ():void {
    document.title = 'Portal by New Visions';
  }
}
