import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconComponent } from './nv-icon.component';
import { NvIconService } from './nv-icon.service';

@NgModule({
  declarations: [NvIconComponent],
  imports: [CommonModule],
  providers: [NvIconService],
  exports: [NvIconComponent],
})
export class NvIconModule {
  constructor(private _iconService: NvIconService) {
    this._iconService.registerAllIcons();
  }
}
