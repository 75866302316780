export const CHANNELS = {
  FLAG: {
    ADDED: 'flag-added',
    PATCHED: 'flag-patched',
    DIRTY: 'flag-dirty',
  },
  DOC_LOG: {
    ADDED: 'doc-log-added',
    PATCHED: 'doc-log-patched',
    DIRTY: 'doc-log-dirty',
  },
  MILESTONE: {
    ADDED: 'milestone-added',
    PATCHED: 'milestone-patched',
    DIRTY: 'milestone-dirty',
  },
  STUDENT_SUPPORT: {
    ADDED: 'student-support-added',
    PATCHED: 'student-support-patched',
    DIRTY: 'student-support-dirty',
  },
  STUDENT_PATHS: {
    ADDED: 'student-paths-added',
    PATCHED: 'student-paths-patched',
    DIRTY: 'student-paths-dirty',
  },
  SYNC_TOOL: {
    ADDED: 'sync-tool-added',
    DELETED: 'sync-tool-deleted',
    UPDATED: 'sync-tool-updated',
    FAILED_CREATION: 'sync-tool-fail-to-create',
    FAILED_DELETION: 'sync-tool-fail-to-delete',
    FAILED_UPDATE: 'sync-tool-fail-to-update'
  },
  CALCULATED_FIELDS: {
    PATCHED: 'calculated-fields-patched',
  },
  ACTIVITY: {
    ADDED: 'activity-added',
    PATCHED: 'activity-patched',
    DIRTY: 'activity-dirty',
  },
  STUDENT_ACTIVITY: {
    ADDED: 'student-activity-added',
    PATCHED: 'student-activity-patched',
    DIRTY: 'student-activity-dirty',
  },
  PARTNER_ORG: {
    ADDED: 'partner-org-added',
    PATCHED: 'partner-org-patched',
    DIRTY: 'partner-org-dirty',
  },
  SCHOOL_ASSESSMENT: {
    ADDED: 'school-assessment-added',
    PATCHED: 'school-assessment-patched',
    DIRTY: 'school-assessment-dirty',
  },
  STUDENT_ASSESSMENT: {
    ADDED: 'student-assessment-added',
    PATCHED: 'student-assessment-patched',
    DIRTY: 'student-assessment-dirty',
  },
  DATA_LOAD: {
    ADDED: 'data-load-added',
    PATCHED: 'data-load-patched',
    DIRTY: 'data-load-dirty',
  },
};
