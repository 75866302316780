import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { RegularExpressionsUtility } from '../../utilities/regular-expressions/regular-expressions.utility';

export interface IGridViewMiniInfo {
  gridViewName?: string | null;
  gridViewId?: string | null;
}

export interface IGridViewNameModalComponentData extends IBaseModalData, IGridViewMiniInfo {
  gridViewName: string | null;
  gridViewId: string | null;
  existingGridViews: any [];
}

@Component({
  selector: 'gridview-name-modal',
  templateUrl: './gridview-name-modal.component.html',
  styleUrls: ['./gridview-name-modal.component.scss'],
})
export class GridViewNameModalComponent extends BaseModalComponent implements OnInit {
  public gridViewForm: FormGroup;
  public placeholder: string = 'Enter view name';
  public iconName: string = 'close';

  public gridViewName: string;
  public characterLimit: number = 60;
  private existingGridViews: any[];
  public warningMessage = '';
  public isExistingGridViewName: boolean = false;
  public isNameInValid: boolean = false;
  public isExceededCharLimit: boolean = false;

  // Modal Configurations
  readonly title = 'Save as new view';
  readonly subtitle = 'Enter a unique name that does not match any of the existing template view names or custom view names.';

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IGridViewNameModalComponentData,
    dialogRef: MatDialogRef<GridViewNameModalComponent>,
    private formBuilder: FormBuilder,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const {
      gridViewName,
      existingGridViews,
    } = cloneDeep(this.data);

    this.existingGridViews = existingGridViews;
    this.gridViewForm = this.formBuilder.group({
      gridViewName: [gridViewName || ''],
    });
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onSave (): void {
    const newGridViewName = this.gridViewForm.controls.gridViewName.value;

    if (!this.isUniqueGridViewName(newGridViewName)) { this.dialogRef.close({ newGridViewName }); } else {
      this.warningMessage = 'This view name matches the name of an existing view. Please enter a new view name.';
      this.gridViewForm.get('gridViewName').setErrors({ incorrect: true });
      this.isExistingGridViewName = true;
    }
  }

  public isUniqueGridViewName (newGridViewName: string): boolean {
    const existingName = this.existingGridViews.find((gvName) => gvName.human === newGridViewName);
    return (existingName?.key?.length > 0);
  }

  public onClearInput (): void {
    this.gridViewForm.get('gridViewName')?.setValue('');
    this.gridViewForm.get('gridViewName')?.markAsDirty();
  }

  public validateGridName () {
    const gvNameText = this.gridViewForm.get('gridViewName')?.value;
    this.isNameInValid = gvNameText ? this.gridViewNameValidator(gvNameText) : false;
    this.isExistingGridViewName = this.isUniqueGridViewName(gvNameText);
    this.isExceededCharLimit = gvNameText.length < 1 || gvNameText.length > this.characterLimit;

    this.isExceededCharLimit || this.isExistingGridViewName || this.isNameInValid
      ? this.gridViewForm.get('gridViewName').setErrors({ incorrect: true })
      : this.gridViewForm.get('gridViewName').setErrors(null);
    this.warningMessage = this.isExistingGridViewName
      ? 'This view name matches the name of an existing view. Please enter a new view name.'
      : this.isNameInValid
        ? 'Name cannot contain special characters.'
        : gvNameText.length > this.characterLimit
          ? 'View name must not exceed ' + this.characterLimit + ' characters.'
          : '';
    return gvNameText && (this.isExceededCharLimit || this.isExistingGridViewName || this.isNameInValid);
  }

  public gridViewNameValidator (gvNameText: string) {
    let isInValid = false;
    if (gvNameText) {
      const gridViewNameRegEx = RegularExpressionsUtility.gridViewNameRegexCtr();
      const valid = gvNameText.match(gridViewNameRegEx);
      if (!valid) {
        isInValid = true;
      }
    }
    return isInValid;
  }
}
