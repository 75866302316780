import { LicenseManager } from '@ag-grid-enterprise/core';
import { AppModule } from './ng2/app.module';
import { enableProdMode, ViewEncapsulation } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from './environments/environment';

import 'zone.js';


(window as any).agGrid = require('@ag-grid-community/core');
(window as any).moment = require('moment-recur');
window._ = require('lodash');

if (environment?.production) {
  enableProdMode();
}

// tslint:disable-next-line:max-line-length
LicenseManager.setLicenseKey(
  'CompanyName=New Visions For Public Schools, Inc.,LicensedApplication=New Visions Data Tools Portal,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=2,LicensedProductionInstancesCount=1,AssetReference=AG-030520,SupportServicesEnd=13_August_2023_[v2]_MTY5MTg4MTIwMDAwMA==29b412d98ec8c8aedf0b5e0f57374700',
);

// bootstrap NG2 App (CM).
platformBrowserDynamic().bootstrapModule(AppModule);
