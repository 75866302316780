import { IEmitFormPayload } from './path-category-forms/base-student-path-modal-form.component';
import { PathCatKeyToPathCatConfig, PATH_CATEGORY_KEYS } from 'Src/ng2/shared/constants/student-paths.constant';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { IACOption } from '../../../../nvps-libraries/design/nv-textbox/nv-textbox.interface';
import { IDropdownOption } from '../../typings/interfaces/design-library.interface';
import { IStudentPath } from '../../typings/interfaces/studentPaths.interface';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';

export interface IStudentPathsModalComponentData extends IBaseModalData {
  schoolId: string;
  studentIds: string[];
  mode: string;
  studentPaths?: IStudentPath[];
}

/* istanbul ignore next */
@Component({
  selector: 'student-paths-modal',
  templateUrl: './student-paths-modal.component.html',
  styleUrls: ['./student-paths-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentPathsModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  private readonly PATH_CATEGORY_CONFIG = PathCatKeyToPathCatConfig;
  public readonly PATH_CATEGORY_KEYS = PATH_CATEGORY_KEYS;

  public selectedPathCategory: PATH_CATEGORY_KEYS;
  public pathCategoryOptions: IDropdownOption[];
  public destroy$: Subject<boolean> = new Subject<boolean>();

  public schoolId: string;
  public studentPaths: IStudentPath[];
  public itemCount: number;
  public itemType: string;
  public isEditMode: boolean;
  public isProfileMode: boolean;

  public action: string;

  constructor (
    dialogRef: MatDialogRef<StudentPathsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IStudentPathsModalComponentData,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { mode, schoolId, studentIds, isProfileMode, studentPaths } = (this.data = cloneDeep(this.data));
    this.schoolId = schoolId;
    this.studentPaths = studentPaths;
    this.itemCount = studentIds.length;
    this.itemType = 'student';
    this.isEditMode = mode === 'UPDATE';
    this.isProfileMode = isProfileMode;

    this.pathCategoryOptions =
      Object.entries(this.PATH_CATEGORY_CONFIG)
        .map(
          ([key, { human }]) => ({ key, human }),
        );

    // update modal UI
    if (mode === 'ADD') {
      // isProfileMode indicates whether or not we're in batch action view, which is
      // restricted to college paths.
      const path = this.isProfileMode ? 'pathway' : 'college';
      this.action = `Add ${path}`;
    } else if (mode === 'UPDATE') {
      this.action = 'Update status';
    } else if (mode === 'REMOVE') {
      // 'Remove' mode is only available via batch action, which is restricted to college paths.
      this.action = 'Remove college';
    }

    // if navigating to the student paths modal from batch action view, the state of
    // selectedPathCategory must be such that the college form presents in the UI.
    if (!this.isProfileMode) {
      this.selectedPathCategory = PATH_CATEGORY_KEYS.COLLEGE;
    }
  }

  public close (): void {
    super.close();
  }

  public onPathCategorySelect (pathCategory: PATH_CATEGORY_KEYS): void {
    this.selectedPathCategory = pathCategory;
  }

  public submit ({ studentPath, status }: IEmitFormPayload): void {
    const path = {
      pathCategory: this.selectedPathCategory,
      ...studentPath,
    };

    const patch = {
      status,
      path,
    };

    super.close(patch);
  }
}
