import { reduce } from 'lodash';
import { IActivity } from '../../shared/typings/interfaces/activity.interface';
import * as activitiesActions from './../actions/activities-actions';

export interface IActivitiesState {
  loading: boolean;
  loaded: boolean;
  activitiesEntities: {
    [id: string]: IActivity;
  };
}

export const initialState: IActivitiesState = {
  loading: false,
  loaded: false,
  activitiesEntities: {},
};

export function reducer(
  state: IActivitiesState = initialState,
  action: activitiesActions.ActivitiesAction,
): IActivitiesState {
  let activity;
  let activitiesEntities;
  switch (action.type) {
    case activitiesActions.LOAD_ACTIVITIES:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case activitiesActions.LOAD_ACTIVITIES_SUCCESS:
      const activities = action.payload.data.Activities;
      const loadedActivities: IActivitiesState['activitiesEntities'] = reduce(
        activities,
        (acc, activity: IActivity) => {
          acc[activity._id] = activity;

          return acc;
        },
        {},
      );
      const mergedActivities = { ...state.activitiesEntities, ...loadedActivities };
      return {
        ...state,
        loading: false,
        loaded: true,
        activitiesEntities: mergedActivities,
      };
    case activitiesActions.LOAD_ACTIVITIES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    case activitiesActions.UPDATE_ACTIVITY_SUCCESS:
      const { activityId, patch } = action.payload;
      activitiesEntities = Object.assign({}, state.activitiesEntities);
      activity = activitiesEntities[activityId] ? activitiesEntities[activityId] : {};
      const updatedActivity = Object.assign(activity, patch);
      activitiesEntities[activityId] = updatedActivity;

      return {
        ...state,
        activitiesEntities,
      };
    case activitiesActions.CREATE_ACTIVITY_SUCCESS:
      activity = action.payload.data.createActivity;
      activitiesEntities = Object.assign({}, state.activitiesEntities);
      activitiesEntities[activity._id] = activity;

      return {
        ...state,
        activitiesEntities,
      };
    default:
      return state;
  }
}
