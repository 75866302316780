import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Observer, throwError } from 'rxjs';
import { catchError, delayWhen, map, switchMap, take } from 'rxjs/operators';
import { TGridShelter } from 'Src/ng2/shared/typings/interfaces/shelter.interface';
import { getShelterGridDataLoaded, getShelterGridDataSlice } from '../../../../store';
import { LoadShelterGridData } from '../../../../store/actions/shelter-actions/shelter-grid-actions';
import { ApiService } from './../../../../shared/services/api-service/api-service';

@Injectable({
  providedIn: 'root',
})
export class AttendanceGridService {
  constructor (private apiService: ApiService, private store: Store<any>) {}

  public getShelterGridConfig$ ({ shelterId, gridType }: { shelterId: string; gridType: TGridShelter }): Observable<any> {
    const query = `{
      ShelterGridConfig(shelterId: "${shelterId}", gridType: "${gridType}") {
        category
        categoryOrder
        cellRenderer
        cellRendererParams {
          params
        }
        checkboxSelection
        field
        filter
        filterParams {
          valueFormatter
          values
        }
        graphQlKey
        headerCheckboxSelection
        headerName
        headerTooltip
        hide
        lockPinned
        pinned
        resizable
        sortable
        tags
        tooltipComponentParams {
          tooltipTemplate
          tooltipHeader
        }
        tooltipField
        valueFormatter
        valueGetter
        width
      }
    }`;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(catchError(err => throwError(err)));
  }

  public getShelterGridData$ ({ shelterId, columns }): Observable<any> {
    const columnKeys = this.getColumnKeys(columns);
    const query = `{
      ShelterGridData(
        shelterId: "${shelterId}",
        columns: [${columnKeys}]
      ) {
        ${columns}
      }
    }`;
    const payload = { query, fetchPolicy: 'no-cache' };
    return this.apiService.getStudentsGraphQL(payload).pipe(catchError(err => throwError(err)));
  }

  private getColumnKeys (projection: string[]): string[] {
    return projection.map(key => {
      return `"${key}"`;
    });
  }

  public getShelterGridDataPayload (columnDefs: any[], shelterId: string): { shelterId: string; columns: string[] } {
    const columns = this.getColumnKeysFromColumnDefs(columnDefs);

    const payload = {
      shelterId,
      columns,
    };
    return payload;
  }

  public getAllShelterData$ ({ shelterId, gridType }: { shelterId: string, gridType: TGridShelter }): Observable<any> {
    return this.store.select(getShelterGridDataLoaded).pipe(
      delayWhen(loaded => this.loadShelterData$({ loaded, shelterId, gridType })),
      switchMap(() => this.getDataFromStore$()),
      take(1),
    );
  }

  private getDataFromStore$ (): Observable<any> {
    return this.store.select(getShelterGridDataSlice).pipe(
      take(1),
      map(({ columnDefs, rowData }) => {
        return { columnDefs, rowData };
      }),
    );
  }

  private loadShelterData$ ({ loaded, shelterId, gridType }: { loaded: boolean, shelterId: string, gridType: TGridShelter }): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      if (!loaded) {
        this.store.dispatch(
          new LoadShelterGridData({
            shelterId,
            gridType,
          }),
        );
      } else {
        observer.next('shelter data loaded');
        observer.complete();
      }
    });
  }

  public getColumnKeysFromColumnDefs (columnDefs: any[]): string[] {
    const columns = columnDefs.reduce((acc, columnDef) => {
      const { field, tooltipField } = columnDef;
      acc.push(field);
      // Add calcs needed for tooltips
      if (tooltipField) acc.push(tooltipField);

      return acc;
    }, []);

    return columns;
  }
}
