import { Injectable } from '@angular/core';
import {
  IBulkUpdateStudentActivitiesParams,
  ICreateStudentActivityParams,
} from '../../../../shared/typings/interfaces/student-activity.interface';

@Injectable()
export class GraphQLStudentActivitiesHelperService {
  constructor() {
    //
  }

  getStudentActivitiesQuery(schoolId, where = '{}'): string {
    return `{
      StudentActivities(schoolId: "${schoolId}", where: "${where}"){
        _id
        studentId
        startDate
        endDate
        activity {
          activityId
          schoolYear
          startDate
          endDate
          sector
          type
          hours
          partnerOrg {
            name
            _id
          }
        }
        completedHours
        status
      }
    }`;
  }

  updateStudentActivityQuery(
    studentActivityId: string,
    patch: {
      startDate?: string;
      endDate?: string;
      completedHours?: number;
      status?: string;
    },
  ): string {
    const { startDate, endDate, completedHours, status } = patch;
    let patchString = '';
    if (startDate) patchString += `startDate: "${startDate}", `;
    if (endDate) patchString += `endDate: "${endDate}", `;
    if (completedHours === null || completedHours >= 0) patchString += `completedHours: ${completedHours}, `;
    if (status) status === 'CLEARED' ? (patchString += `status: null, `) : (patchString += `status: "${status}", `);
    patchString = patchString.substring(0, patchString.length - 2);
    return `mutation{
      updateStudentActivity(studentActivityId: "${studentActivityId}", patch: {${patchString}}){
        _id
        startDate
        endDate
        studentId
        activity {
          activityId
          schoolYear
          startDate
          endDate
          sector
          type
          hours
          partnerOrg {
            name
            _id
          }
        }
        completedHours
        status
      }
    }`;
  }

  createStudentActivityQuery(studentActivityParams: ICreateStudentActivityParams): string {
    const { studentId, schoolId, activityId, startDate, endDate, status, completedHours } = studentActivityParams;
    let optionsString = `studentId: "${studentId}", schoolId: "${schoolId}", startDate: "${startDate}", endDate: "${endDate}"`;
    if (completedHours || completedHours === 0) optionsString += `, completedHours: ${completedHours}`;
    if (status) optionsString += `, status: "${status}"`;
    return `mutation{
      createStudentActivity(options: {${optionsString}}, activityId: "${activityId}"){
        _id
        studentId
        startDate
        endDate
        activity {
          activityId
          schoolYear
          startDate
          endDate
          sector
          type
          hours
          partnerOrg {
            name
            _id
          }
        }
        completedHours
        status
      }
    }`;
  }

  bulkUpdateStudentActivitiesQuery(params: IBulkUpdateStudentActivitiesParams): string {
    const {
      studentIds,
      schoolId,
      activityId,
      patch: { startDate, endDate, completedHours, status },
    } = params;
    let ids = '';
    for (let studentId of studentIds) {
      ids += `"${studentId}", `;
    }
    ids = ids.substring(0, ids.length - 2);
    let optionsString = `activityId: "${activityId}", studentIds: [${ids}], schoolId: "${schoolId}"`;
    if (startDate) optionsString += `, startDate: "${startDate}"`;
    if (endDate) optionsString += `, endDate: "${endDate}"`;
    if (completedHours || completedHours === 0) optionsString += `, completedHours: ${completedHours}`;
    if (status) status === 'CLEARED' ? (optionsString += `, status: null`) : (optionsString += `, status: "${status}"`);
    return `mutation{
      bulkUpdateStudentActivity(options: {${optionsString}}){
        backgroundJobId
      }
    }`;
  }
}
