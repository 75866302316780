import { NvTextboxVirtualScrollModule } from './nv-textbox-virtual-scroll/nv-textbox-virtual-scroll.module';
import { NgModule } from '@angular/core';
import { NvTimeRangePickerModule } from './nv-time-range-picker/nv-time-range-picker.module';
import { NvTimePickerModule } from './nv-time-picker/nv-time-picker.module';
import { NvDatePickerModule } from './nv-date-picker/nv-date-picker.module';
import { NvDateRangePickerModule } from './nv-date-range-picker/nv-date-range-picker.module';
import { NvDropdownModule } from './nv-dropdown/nv-dropdown.module';
import { NvFormFieldModule } from './nv-form-field/nv-form-field.module';
import { NvMultipickerModule } from './nv-multi-picker/nv-multi-picker.module';
import { NvNumboxModule } from './nv-numbox/nv-numbox.module';
import { NvTextboxModule } from './nv-textbox/nv-textbox.module';

/**
 *
 * NvInputModules bundles all input components into a single module.
 *
 * Useful when building forms, so you don't have to individually import all inputs individually
 */
const inputModules = [
  NvDatePickerModule,
  NvDateRangePickerModule,
  NvDropdownModule,
  NvFormFieldModule,
  NvMultipickerModule,
  NvNumboxModule,
  NvTextboxModule,
  NvTimePickerModule,
  NvTimeRangePickerModule,
  NvTextboxVirtualScrollModule,
];

@NgModule({
  imports: [...inputModules],
  exports: [...inputModules],
})
export class NvInputsModule {}
