import { Injectable } from '@angular/core';
import { IApi } from '../../api-service/api-service.interface';

@Injectable()
export class GraphQLPartnerHelperService {
  getPartnerQuery({ params, projections }: IApi['GetPartnerPayload']): string {
    const validType = params && params.type ? `"${params.type}"` : 'null';
    const validId = params && params._id ? `"${params._id}"` : 'null';
    const validProjections = (projections && projections.join(' ')).trim() || '_id';
    return `{
      Partner(
        type: ${validType}, 
        _id: ${validId}
      ) {
          ${validProjections}
        }
    }`;
  }

  getPartnersQuery({ params, projections }: IApi['GetPartnersPayload']): string {
    const validType = params && params.type ? `"${params.type}"` : 'null';
    const hasIds = params && params.ids && this.getValidArrArg(params.ids);
    const validIds = hasIds ? `[${hasIds}]` : 'null';
    const validClusterId = params && params.clusterId ? `"${params.clusterId}"` : 'null';
    const validProjections = (projections && projections.join(' ')).trim() || '_id';
    return `{
      Partners(
        type: ${validType}, 
        ids: ${validIds},
        clusterId: ${validClusterId}
      ) {
          ${validProjections}
        }
    }`;
  }

  private getValidArrArg(array: string[]): string[] | '' {
    return array.length
      ? array.map(el => {
          return `"${el}"`;
        })
      : '';
  }
}
