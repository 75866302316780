import { IModalsConfig } from './../modals.config.interface';

export const STUDENT_ACTIVITIES_MODAL_CONFIG: IModalsConfig = {
  input: {
    placeholder: 'Find an activity',
  },
  buttons: {
    confirm: {
      text: 'Assign',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
  dropdown: {
    placeholder: 'Find an activity',
  },
};

export const STUDENT_ACTIVITY_STATUS_MODAL_CONFIG: IModalsConfig = {
  buttons: {
    confirm: {
      text: 'Complete',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};

export const REMOVE_STUDENT_ACTIVITY_MODAL_CONFIG: IModalsConfig = {
  buttons: {
    confirm: {
      text: 'Remove',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
