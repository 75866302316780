import { forEach } from 'lodash';
import * as milestoneActions from '../actions/milestone-actions';

export interface IMilestonesState {
  loaded: boolean;
  loading: boolean;
  allLoaded: boolean;
  loadedEntities: object;
  milestoneEntities: {
    [id: string]: any;
  };
}

export const initialState: IMilestonesState = {
  loading: false,
  allLoaded: false,
  loaded: false,
  loadedEntities: {},
  milestoneEntities: {},
};

export function reducer(
  state: IMilestonesState = initialState,
  action: milestoneActions.MilestonesAction,
): IMilestonesState {
  switch (action.type) {
    case milestoneActions.LOAD_ALL_MILESTONES: {
      return {
        ...state,
        loaded: false,
        allLoaded: false,
        loading: true,
      };
    }

    case milestoneActions.LOAD_ALL_MILESTONES_SUCCESS:
      let payload = action.payload.reduce((acc, val) => {
        acc[val._id] = val;
        return acc;
      }, {});
      const milestoneEntities = { ...state.milestoneEntities, ...payload };
      return {
        ...state,
        loaded: true,
        allLoaded: true,
        loading: false,
        milestoneEntities,
      };

    case milestoneActions.LOAD_STUDENT_MILESTONES_SUCCESS: {
      let payload = action.payload.milestones;
      let studentId = action.payload.studentId;
      const loadedEntities = state.loadedEntities;
      // set to true even if payload is empty, otherwise ssv breaks (JE)
      loadedEntities[studentId] = true;

      payload = action.payload.milestones.reduce((acc, val) => {
        acc[val._id] = val;
        return acc;
      }, {});
      const milestoneEntities = Object.assign(state.milestoneEntities, payload);

      return {
        loaded: true,
        allLoaded: state.allLoaded,
        loading: false,
        loadedEntities: { ...loadedEntities },
        milestoneEntities,
      };
    }

    case milestoneActions.UPDATE_MILESTONE_SUCCESS: {
      const updatedMilestone = action.payload;
      const updatedMilestoneId = updatedMilestone._id;
      const milestoneEntities = Object.assign({}, state.milestoneEntities);
      milestoneEntities[updatedMilestoneId] = updatedMilestone;

      return {
        ...state,
        milestoneEntities,
      };
    }

    case milestoneActions.BULK_UPDATE_MILESTONES_SUCCESS: {
      const payload = action.payload;
      const milestoneEntities = Object.assign({}, state.milestoneEntities);
      forEach(payload, (milestonePatch: { itemId: string; patch: { status: string } }) => {
        const {
          itemId,
          patch: { status },
        } = milestonePatch;
        const updatedEntity = Object.assign({}, milestoneEntities[itemId]);
        updatedEntity.status = status;
        milestoneEntities[itemId] = updatedEntity;
      });
      return { ...state, milestoneEntities };
    }

    case milestoneActions.LOAD_ALL_MILESTONES_FAIL: {
      return {
        ...state,
        loaded: false,
        allLoaded: false,
        loading: false,
      };
    }

    default:
      return state;
  }
}
