import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { reduce, remove } from 'lodash';
import { ObjectCache } from '../../../shared/services/object-cache/object-cache.service';
import { IListColumnData, IListViewData } from '../../../shared/typings/interfaces/list-view.interface';
import { ListDisplayService } from './list-display.service';

@Component({
  template: '',
  styleUrls: ['./list-display.scss'],
  encapsulation: ViewEncapsulation.None,
})
export abstract class ListDisplay implements OnChanges {
  /* istanbul ignore next */
  @Input()
  set data (data: string) {
    this.parsedData = JSON.parse(data);
  }

  @Input() searchQuery: string;
  @Output() sortData: EventEmitter<object> = new EventEmitter<object>();

  navDataIndex = 0;
  navDataKey = 'data';
  dataFormatted: boolean = false;
  parsedData: IListViewData;
  formattedList: IListViewData;
  colHeaders: Array<object>;
  listStyle: string = null;
  sortedColumn = { columnKey: 'studentName', orderBy: 'desc' };
  expandedLists = {};
  store;

  constructor (
    protected listDisplayService: ListDisplayService,
    protected objectCache: ObjectCache,
    private ngStore: Store<any>,
  ) {
    this.store = ngStore;
  }

  abstract rowClick(
    rowData: Array<object>,
    sectionIndex?: number, // index of section in listData
    rowIndex?: number, // index of row in section
  ): void;

  ngOnChanges (changes: SimpleChanges): void {
    if (this.parsedData !== null) {
      this.navDataIndex = this.parsedData.navData ? this.parsedData.navData.index : 0;
      this.navDataKey = this.parsedData.navData ? this.parsedData.navData.key : 'data';
      this.formatData(this.parsedData);
    }
  }

  setListLevelStyle (parsedData: IListViewData): void {
    if (this.parsedData !== null) {
      this.listStyle = this.listDisplayService.getStyleForList(parsedData.listType);
    }
  }

  formatData (parsedData: IListViewData): void {
    this.colHeaders = this.getColumnHeaders(parsedData);
    remove(parsedData.sections, { count: 0 });
    this.formattedList = this.formatList(parsedData);
    this.setListLevelStyle(this.parsedData);
  }

  expandRows (section): void {
    const sectionLength = section.data.length;
    if (!this.expandedLists[section.name]) {
      const newVal = sectionLength < 110 ? sectionLength : 110;
      this.expandedLists[section.name] = newVal;
    } else if (this.expandedLists[section.name] > sectionLength - 100) this.expandedLists[section.name] = sectionLength;
    else this.expandedLists[section.name] += 100;
    this.formatSection(section, this.expandedLists[section.name]);
  }

  getColumnHeaders (parsedData: IListViewData): Array<object> {
    const columns = parsedData.columns;
    const colHeaders = reduce(
      columns,
      (columnHeaders, colData: IListViewData['columns'][any], key) => {
        const columnData = {
          key,
          human: colData.name,
          dataType: colData.dataType,
          orderBy: colData.orderBy,
          tooltip: colData.tooltip || colData.tooltipMsg,
        };
        columnHeaders.push(columnData);
        return columnHeaders;
      },
      [],
    );
    return colHeaders;
  }

  formatList (listData: IListViewData): IListViewData {
    this.dataFormatted = false;

    remove(listData.sections, element => {
      return element.count <= 0;
    });

    listData.sections.forEach(section => {
      // check if default number of rows to display is larger than available data rows
      let rowsToDisplay = section.defaultDisplayCount;
      const availableRows = section.data.length;
      if (rowsToDisplay > availableRows) rowsToDisplay = availableRows;
      this.formatSection(section, rowsToDisplay);
    });

    this.dataFormatted = true;
    return listData;
  }

  formatSection (section: any, rowsToFormat: number): void {
    section.formattedRows = [];
    const columnConfig = this.parsedData.columns;
    const excessRows = section.count - rowsToFormat;
    section.excessRows = excessRows;

    if (excessRows > 0) {
      const { footerMsg, footerIconClass } = this.formatFooter(excessRows);
      section.footerMsg = footerMsg;
      section.footerIconClass = footerIconClass;
    }

    for (let i = 0; i < rowsToFormat; i++) {
      const row = section.data[i];
      const rowData = [];

      Object.keys(row).forEach(key => {
        const columnData = row[key];
        let processedColData: IListColumnData;

        // if the key is present in columns (which has config options), process it
        // to modify the data value
        if (columnConfig[key] !== undefined) {
          const dataObj = {
            listType: this.parsedData.listType,
            columnType: columnConfig[key].cellType,
            columnData,
            cellConfig: columnConfig[key].cellConfig,
          };
          processedColData = this.listDisplayService.processColumnData(dataObj);
        } else {
          processedColData = {
            data: this.highlighter(columnData.data, this.searchQuery),
            style: '',
            dependencies: columnData.dependencies,
            tooltipMsg: columnData.tooltipMsg,
            tooltipClass: columnData.tooltipClass,
          };
        }
        rowData.push(processedColData);
      });

      section.formattedRows.push(rowData);
    }
  }

  /* Logic:
      If a pattern is found in the input, it will be prefixed with ~ char
      and suffixed with a @ char.
      Once all patterns are surrounded with corresponding ~ and @ chars,
      all ~ will be replaced with <em> tag and all @ with </em> tag. (dvn)
  */
  highlighter (str: string, searchQuery: string): string {
    if (!searchQuery) return str;

    const searchTerms = searchQuery.split(' ');
    let regExp;
    searchTerms.forEach(term => {
      // For safety, escape characters that might interfer with regex and
      // exclude ~ and @ chars
      regExp = new RegExp(term.replace(/\\?.(?!^)/g, '$&[~@-]*'), 'gi');
      str = str.replace(regExp, '~$&@'); // "$&" refers to the matched needle. Surround it with ~ and @
    });
    const highlightedStr = str.replace(/[~]/g, '<em>').replace(/[@]/g, '</em>');
    return highlightedStr;
  }

  formatFooter (excessRowCount: number): IListFooter {
    const footerMsg = excessRowCount + ' more...';
    const footerIconClass = 'students';
    return { footerMsg, footerIconClass };
  }

  updateSort (key, dataType, defaultOrder, currentOrder) {
    let sortToEmit;
    if (this.formattedList.sortedColumn === key) {
      sortToEmit = currentOrder === 'asc' ? 'desc' : 'asc';
    } else sortToEmit = defaultOrder;
    this.sortData.emit({ key, dataType, orderBy: sortToEmit });
  }

  showPanelShadow (scrollYPosition: number): boolean {
    return scrollYPosition > 10;
  }
}

export interface IListFooter {
  footerMsg: string;
  footerIconClass: string;
}
