import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';

@Component({
  selector: 'master-program-settings-mad-lib',
  templateUrl: './master-program-settings-mad-lib.component.html',
  styleUrls: ['./master-program-settings-mad-lib.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MasterProgramSettingsMadLibComponent {
  @Input() option$: Observable<IDropdownOption[]>;
  @Input() optionSelected: string;
  @Output() madLibChange = new EventEmitter();

  public emitMadLibChange (e: string): void {
    if (this.optionSelected !== e) {
      this.madLibChange.emit(e);
    }
  }
}
