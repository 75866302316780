import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BatchEditGroupModalComponent } from './batch-edit-group-modal.component';
import { BaseModalModule } from '../base-modal.module';
import { BatchEditService } from '../../services/batch-edit-service/batch-edit-service';
import { NvIconModule } from '../../../../nvps-libraries/design/nv-icon/nv-icon.module';
import { NvButtonModule } from '../../../../nvps-libraries/design/nv-button/nv-button.module';
import { NvIconButtonModule } from '../../../../nvps-libraries/design/nv-icon-button/nv-icon-button.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  declarations: [BatchEditGroupModalComponent],
  imports: [
    CommonModule,
    BaseModalModule,
    NvButtonModule,
    NvIconModule,
    NvIconButtonModule,
    NvNoteModule,
  ],
  exports: [BatchEditGroupModalComponent],
  providers: [BatchEditService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BatchEditGroupModule {}
