import { ComponentType } from '@angular/cdk/portal';
import { Component, Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { NvToastComponent } from '../../../../nvps-libraries/design/nv-toast/nv-toast.component';
import { SNACKBAR_CONFIG } from './snackbar.config';

export interface ISnackBarData {
  message: string;
  action?: string;
  duration?: number;
}
@Injectable()
export class SnackBarService {
  readonly SNACKBAR_CONFIG = SNACKBAR_CONFIG;
  constructor (public snackBar: MatSnackBar) {}

  public showSnackBar ({ message, action, duration }: ISnackBarData): void {
    const config = duration ? { ...this.SNACKBAR_CONFIG, ...{ duration } } : this.SNACKBAR_CONFIG;
    this.snackBar.open(message, action || null, config);
  }

  public openSnackBarFromComponent (component: ComponentType<any>, data): MatSnackBarRef<Component> {
    return this.snackBar.openFromComponent(component, data);
  }

  public showToast ({ toastText, duration = 3000 }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: false,
        actionText: null,
      },
      duration,
    });
  }

  public showToastWithCloseButton ({ toastText }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: true,
        actionText: null,
      },
    });
  }

  public showBatchActionNoteSuccessToast (backgroundJob): any {
    const message = (backgroundJob === 'BulkStudentNoteCreate')
      ? 'Note was added for selected students.'
      : 'Batch action applied to the selected students.';
    this.showToast({ toastText: message });
  }

  public showToastLowerLeftCorner ({ toastText, duration = 3000 }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: false,
        actionText: null,
      },
      duration,
      ...this.SNACKBAR_CONFIG,
    });
  }

  public showDangerToastWithCloseButton ({ toastText, isDanger = true }): MatSnackBarRef<NvToastComponent> {
    return this.snackBar.openFromComponent(NvToastComponent, {
      data: {
        toastText,
        hasClearButton: true,
        actionText: null,
        isDanger,
      },
      panelClass: 'nv-mat-snackbar',
      horizontalPosition: 'left',
      verticalPosition: 'bottom',
    });
  }

  public showBatchActionToast ({
    success,
    duration,
  }: {
    success: boolean;
    duration?: number;
  }): MatSnackBarRef<NvToastComponent> {
    const batchSuccessMessage = 'Batch action applied to the selected students.';
    const batchFailedMessage =
      'The batch action could not be applied to all of the selected students. Please try again.';
    const toastText = success ? batchSuccessMessage : batchFailedMessage;
    return this.showToast({ toastText, duration });
  }
}
