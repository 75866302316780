import { UserRolePermissionsForModelService } from 'Src/ng2/shared/services/user-role-permissions-for-model/user-role-permissions-for-model.service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ISchoolSupport, ISupportMini } from 'Src/ng2/shared/typings/interfaces/support.interface';
import { RegentsSubject } from 'Src/ng2/shared/constants/regents.constant';
import { DateHelpers } from 'Src/ng2/shared/services/date-helpers/date-helpers.service';

import * as _ from 'lodash';
import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { SupportCategories } from 'Src/ng2/shared/constants/support-categories.constant';
import { SupportStatuses, TSupportStatusesFrontend } from 'Src/ng2/shared/constants/support-statuses.constant';

interface IMetaParams {
  category?: any;
  examSubject?: any;
  serviceName?: any;
}

const MODEL_NAME = 'SUPPORT';
const relationToUserPath = 'createdBy.userId';

@Injectable()
export class ImSupport {
  private DEFAULT_SUPPORT: string;

  constructor (
    private utilitiesService: UtilitiesService,
    private dateHelpers: DateHelpers,
    private permissions: UserRolePermissionsForModelService,
  ) {
    this.DEFAULT_SUPPORT = SupportCategories.REGENTS_PREP.key;
  }

  private _getExamSubjectHumanName (examSubject): string {
    return _.find(RegentsSubject, { key: examSubject }).humanName;
  }

  public getMetaData (category): any {
    switch (category) {
      case 'REGENTS_PREP':
        return {
          examSubject: null,
        };
      case 'ACADEMIC':
        return {
          serviceName: 'Other',
          subject: null,
        };
      case 'ATTENDANCE':
        return {
          serviceName: null,
        };
      case 'PHYS_HEALTH_SERVICES':
        return {
          serviceName: null,
        };
      default:
        return null;
    }
  }

  public getStartAndEndDateString (support: ISchoolSupport): string {
    const {
      schedule: { startsOn, endsOn },
    } = support;
    const startHuman = this.utilitiesService.convertISODateToHumanDate(startsOn);
    const endHuman = this.utilitiesService.convertISODateToHumanDate(endsOn);
    let str = startHuman;
    if (endHuman) str += ` - ${endHuman}`;
    return str;
  }

  public getStartAndEndTimeString (support: ISchoolSupport): string {
    const {
      schedule: { startTime, endTime },
    } = support;
    return `${startTime} - ${endTime}`;
  }

  public getMeetsOnScheduleString (support: ISchoolSupport): string {
    const {
      schedule: { repeatsEvery, repeats, repeatsOn },
    } = support;
    let result = '';

    if (repeatsEvery === 1) {
      if (repeats === 'WEEKLY') {
        result += 'Weekly ';
      } else if (repeats === 'DAILY') {
        result += 'Daily ';
      } else if (repeats === 'MONTHLY') {
        result += 'Monthly ';
      }
    }

    if (repeatsEvery > 1) {
      result += `Every ${repeatsEvery} `;
      if (repeats === 'WEEKLY') {
        result += 'weeks ';
      } else if (repeats === 'DAILY') {
        result += 'days ';
      } else if (repeats === 'MONTHLY') {
        result += 'months ';
      }
    }

    if (repeats === 'WEEKLY') {
      const repeatsOnString = this.utilitiesService.getWeeklyScheduleString(repeatsOn);
      result += `on ${repeatsOnString}`;
    }
    return result;
  }

  public getLeadHumanName (support: ISchoolSupport): string {
    const { activityLead } = support;
    return `${activityLead.firstName} ${activityLead.lastName}`;
  }

  public getCreatorHumanName (support: ISchoolSupport): string {
    const { createdBy } = support;
    return `${createdBy.firstName} ${createdBy.lastName}`;
  }

  public categoryIsRegentsPrep (support: ISchoolSupport | ISupportMini): boolean {
    const { category, categories = [{ category }] } = support;
    const catIsRegentsPrep = !!categories.find(({ category }) => category === SupportCategories.REGENTS_PREP.key);
    return catIsRegentsPrep;
  }

  public getCategoryHumanName (support: ISchoolSupport | ISupportMini): string {
    const { category, metaData, categories = [{ category, metaData }] } = support;

    const categoryDetails = categories.map(({ category }) => SupportCategories[category]).filter(_.identity);

    if (categoryDetails.length !== categories.length) return '';

    let human = categoryDetails.map(({ humanName }) => humanName).join(',');

    if (this.categoryIsRegentsPrep(support)) {
      const regentsCatObj = support.categories.find(({ category }) => category === SupportCategories.REGENTS_PREP.key);
      const humanSubj = this._getExamSubjectHumanName(regentsCatObj.metaData.examSubject);
      human += ` ${humanSubj}`;
    }
    return human;
  }

  public getFrontendStatus (support: ISchoolSupport): TSupportStatusesFrontend {
    return this.getSupportStatusBasedOnSchedule(support);
  }

  public getSortOrderBasedOnFrontendStatus (support: ISchoolSupport): number {
    return this.getSupportOrderBasedOnCurrentState(support);
  }

  public isDeleted (support: ISchoolSupport): boolean {
    return support.status === SupportStatuses.backend.DELETED;
  }

  public isInProgress (support: ISchoolSupport): boolean {
    const frontEndStatus = this.getFrontendStatus(support);
    return frontEndStatus === SupportStatuses.frontend.IN_PROGRESS;
  }

  public isNotStarted (support: ISchoolSupport): boolean {
    const frontEndStatus = this.getFrontendStatus(support);
    return frontEndStatus === SupportStatuses.frontend.NOT_STARTED;
  }

  public isCompleted (support: ISchoolSupport): boolean {
    const frontEndStatus = this.getFrontendStatus(support);
    return frontEndStatus === SupportStatuses.frontend.COMPLETED;
  }

  public canBeEditedBasedOnStatus (support: ISchoolSupport): boolean {
    const canBeEdited = this.isInProgress(support) || this.isNotStarted(support);
    return canBeEdited;
  }

  public setPrimaryOutcomeMetrics (support: ISchoolSupport): any {
    const { category } = support;
    const supportConst = SupportCategories[category];
    const metrics = supportConst.primaryOutcomeMetrics;

    // For Regents Prep supports, filter the possible metrics for the given Regents subject
    // E.g. if the support is for alg, return 'regentsDetails.byCategory9.alg.maxScore', etc.
    // For all other types, just return the full array of metrics
    if (category === SupportCategories.REGENTS_PREP.key) {
      const { examSubject } = support.metaData;
      support.primaryOutcomeMetrics = _.filter(metrics, (metricPath: any) => {
        const metricSubject = metricPath.split('.')[2];
        return metricSubject === examSubject;
      });
    } else {
      support.primaryOutcomeMetrics = metrics;
    }
  }

  // This is attached to Support model, but also
  // used to set inital template value
  // destructures arg and sets default values if arg option is not provided
  public setMetaData (support: ISchoolSupport, { category, examSubject = null, serviceName = null }: IMetaParams): void {
    support.metaData = this.getMetaData(category);
    if (examSubject) support.metaData.examSubject = examSubject;
    if (serviceName) support.metaData.serviceName = serviceName;
  }

  // Returns formatted school year
  // e.g. `support.schoolYear = 'SY2017-18'` => '17-18'
  public getFormattedSchoolYear ({ schedule }: ISchoolSupport) {
    const startsOn = this.dateHelpers.moment(schedule.startsOn);
    const year = startsOn.year() % 2000;
    const month = startsOn.month(); // 0 indexed
    // if month is 8,9,10,11 (sept, oct, nov, dec) starting year is current year
    // if month is 0,1,2,3,4,5,6,7 starting year is last year
    const schoolYear = month >= 8 ? `${year}-${year + 1}` : `${year - 1}-${year}`;

    return schoolYear;
  }

  getInProgressAndNotStartedSupports (supports) {
    return _.reduce(
      supports,
      (result, support: any) => {
        const isInProgress = this.isInProgress(support);
        const isNotStarted = this.isNotStarted(support);
        if (isInProgress || isNotStarted) result.push(support);
        return result;
      },
      [],
    );
  };

  public getSupportsGroupedByFrontEndStatus (schoolSupports: ISchoolSupport[]) {
    const acc = {
      [SupportStatuses.frontend.IN_PROGRESS]: [],
      [SupportStatuses.frontend.NOT_STARTED]: [],
      [SupportStatuses.frontend.COMPLETED]: [],
    };

    _.reduce(
      schoolSupports,
      (acc, schoolSupport) => {
        const frontendStatus = this.getFrontendStatus(schoolSupport);

        if (acc[frontendStatus]) acc[frontendStatus].push(schoolSupport);

        return acc;
      },
      acc,
    );

    _.each(acc, (value, key) => {
      if (!value.length) delete acc[key];
    });

    return acc;
  }

  private dateIsBefore (support, date): boolean {
    const exactStart = moment(support.schedule.startsOn + ' ' + support.schedule.startTime);
    return date.isBefore(exactStart);
  }

  private dateIsBetween (support, date): boolean {
    return date.isBetween(support.schedule.startsOn, support.schedule.endsOn, null, '[]');
  }

  private dateIsAfter (support, date): boolean {
    const exactEnd = moment(support.schedule.endsOn + ' ' + support.schedule.endTime);
    return date.isAfter(exactEnd);
  }

  private getSupportStatusBasedOnSchedule (support) {
    // backend status is deleted, so return removed
    const backendStatus = support.status;
    if (backendStatus === SupportStatuses.backend.DELETED) {
      return SupportStatuses.frontend.REMOVED;
    }

    // backend status is active, so return 'not started', 'completed', or 'current' based on date
    const now = moment();
    if (this.dateIsBefore(support, now)) {
      return SupportStatuses.frontend.NOT_STARTED;
    } else if (this.dateIsBetween(support, now)) {
      return SupportStatuses.frontend.IN_PROGRESS;
    } else if (this.dateIsAfter(support, now)) {
      return SupportStatuses.frontend.COMPLETED;
    }
  }

  /**
   *
   * @param support
   * @returns {number}
   */
  private getSupportOrderBasedOnCurrentState (support) {
    const supportStatus = this.getSupportStatusBasedOnSchedule(support);
    if (supportStatus === SupportStatuses.frontend.IN_PROGRESS) {
      return 0;
    } else if (supportStatus === SupportStatuses.frontend.NOT_STARTED) {
      return 1;
    } else if (supportStatus === SupportStatuses.frontend.COMPLETED) {
      return 2;
    } else if (supportStatus === SupportStatuses.frontend.REMOVED) {
      return 3;
    }
  }

  canView (user, courseDiff) {
    return this.permissions.canViewPartial(MODEL_NAME, relationToUserPath)(courseDiff, user);
  }
}
