import { IRegentsCategory, RegentsScoreStringValue, RegentsStatusFormattedValue, TRegentsStatusDisplayValue } from './../../../constants/regents.constant';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { GraduationDate, TValidGradDates } from 'Src/ng2/shared/constants/graduation-date.constant';
import { CreditRequirements } from 'Src/ng2/shared/constants/credit-requirements.constant';
import { ICurrProgramCourse, INextScheduledRegentsExam, IRegentsExamDetails, IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';
import { IPlannedDiplomaType, PlannedDiplomaType } from 'Src/ng2/shared/constants/planned-diploma-type.constant';
import { RegentsPrepPriorityGroupingsTransferService } from './../../../../school/sdc/services/regents-prep-priority-groupings/regents-prep-priority-groupings-transfer.service';
import { RegentsPlanningPriorityGroupingsService } from 'Src/ng2/school/sdc/services/regents-planning-priority-groupings/regents-planning-priority-groupings.service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { DateHelpers } from './../../date-helpers/date-helpers.service';
import * as _ from 'lodash';

import { Injectable } from '@angular/core';
import { ImStudent } from '../im-student.service';
import { RegentsPlanningPriorityGroupingsTransferService } from 'Src/ng2/school/sdc/services/regents-planning-priority-groupings/regents-planning-priority-groupings-transfer.service';
import { RegentsPrepPriorityGroupingsService } from 'Src/ng2/school/sdc/services/regents-prep-priority-groupings/regents-prep-priority-groupings.service';
import { IRegentsExam, RegentsCategory, RegentsExam, RegentsSubject } from 'Src/ng2/shared/constants/regents.constant';
import { OnTrackStatus } from 'Src/ng2/shared/constants/on-track-status.constant';
import { RegentsCategoryByCategory9 } from 'Src/ng2/shared/constants/regents-category-by-category-9.constant';
import { RegentsCategoryByCategory5 } from 'Src/ng2/shared/constants/regents-category-by-category-5.constant';
import { ImModelsHelpers } from 'Src/ng2/shared/helpers/im-models/im-models.helper';
import { StudentStatic } from 'Src/ng2/shared/services/static-models/student-static.service';
import { RegentsCategoryGroupingForByCat5 } from 'Src/ng2/shared/constants/regents-category-grouping-for-by-cat-5.constant';
import { DiscontinuedRegentsExamKeys } from 'Src/ng2/shared/constants/discontinued-regents-exam-keys.constant';
import { ValidRegentsPlans } from 'Src/ng2/shared/constants/regents-plans.constant';
import { NextRegentsAdminDate } from 'Src/ng2/shared/constants/next-regents-admin-date.constant';
import { GraduationPlan } from 'Src/ng2/shared/constants/graduation-plan.constant';
import { GraduationPlanTransfer } from 'Src/ng2/shared/constants/graduation-plan-transfer.constant';
import { RegentsExamPrepStatuses } from 'Src/ng2/shared/constants/regents-exam-prep-statuses.constant';
import { ImStudentCurrentProgramHelpers } from '../im-student-credit-gaps-helpers/im-student-current-program-helpers';

interface ITranscriptForRegentsExam {
  exam: string;
  examKey: string;
  month: string;
  num: number;
  string: string;
  year: string;
}

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an this method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends) {
  return function dependencyDecorator (target, key, descriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentRegents {
  constructor (
    private dateHelpers: DateHelpers,
    private ImSchool: ImSchool,
    private imStudent: ImStudent,
    private UtilitiesService: UtilitiesService,
    private RegentsPlanningPriorityGroupingsService: RegentsPlanningPriorityGroupingsService,
    private RegentsPlanningPriorityGroupingsTransferService: RegentsPlanningPriorityGroupingsTransferService,
    private RegentsPrepPriorityGroupingsService: RegentsPrepPriorityGroupingsService,
    private RegentsPrepPriorityGroupingsTransferService: RegentsPrepPriorityGroupingsTransferService,
  ) {}

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    _.each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.UtilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      _.each(methods, m => {
        if (!_.includes(resolvedMethods, m)) {
          if (_.includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      _.pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the paths that `method` depends on
   *
   * @param method a this method
   * @return {Array} and array of paths
   */
  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!_.isArray(paths)) {
        console.warn(
          `this.pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
            `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a this method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  /**
   * Returns an object that contains details about the student's current Regents status
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType] - a value
   * in PlannedDiplomaType[CONSTANT].humanName
   * @returns {Object with regents 'status' information for a given plannedDiplomaType}
   */
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType', 'regentsDetails.numberPassed'],
    methods: ['getRegentsKeyForNumberPassed', 'getRegentsCatsBasedOnDiplomaType', 'isRegentsCatNeededForOnTrack'],
  })
  getRegentsOnTrackStatus (student, plannedDiplomaType) {
    const status = {
      totalRequiredCategories: 0,
      totalRequiredCategoriesFulfilled: 0,
      fulfilledAllRequiredCategories: false,
      neededOnTrackCategories: [],
      status: '', // 'ON', 'OFF', 'ALMOST', 'COLLEGE'
      onTrack: false,
    };

    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;
    const plannedDiplomaTypeDetails = _.find(PlannedDiplomaType, { humanName: plannedDiplomaType });

    if (plannedDiplomaTypeDetails) {
      const keyForNumberPassed = this.getRegentsKeyForNumberPassed(student, plannedDiplomaTypeDetails);
      const plannedDiplomaTypeHumanName = plannedDiplomaTypeDetails.humanName;
      status.totalRequiredCategoriesFulfilled = student.regentsDetails.numberPassed[keyForNumberPassed];

      // returns student.regentsDetails.byCat5 or 9 - all the categories needed for diploma type
      const regentsCategories = this.getRegentsCatsBasedOnDiplomaType(student, plannedDiplomaTypeHumanName);

      // gets cats needed for on track from regentsDetails.needed.onTrack
      // OR, use regentsDetails.needed.all if student is planned to grad this year
      const neededOnTrackCats = _.reduce(
        regentsCategories,
        (acc, value, key) => {
          const catIsNeeded = this.isRegentsCatNeededForOnTrack(student, key);
          if (catIsNeeded) {
            acc.push(_.find(RegentsCategory, { key }));
          }
          return acc;
        },
        [],
      );
      status.neededOnTrackCategories = neededOnTrackCats;

      status.totalRequiredCategories = parseInt(plannedDiplomaTypeDetails.byCategory);

      status.fulfilledAllRequiredCategories =
        status.totalRequiredCategories === status.totalRequiredCategoriesFulfilled;
      status.status = status.neededOnTrackCategories.length ? OnTrackStatus.OFF : OnTrackStatus.ON;
      status.onTrack = !status.neededOnTrackCategories.length;
    }

    return status;
  }

  @depends({
    paths: [],
    methods: ['getRegentsOnTrackStatus'],
  })
  getRegentsOnTrackStatusForDiffDiplomaTypes (student) {
    const { ADVANCED_REGENTS, REGENTS, LOCAL } = PlannedDiplomaType;
    const advanced = ADVANCED_REGENTS.humanName;
    const regents = REGENTS.humanName;
    const local = LOCAL.humanName;
    const onTrackStatusForDiffDiplomaTypes = {
      advanced: this.getRegentsOnTrackStatus(student, advanced),
      regents: this.getRegentsOnTrackStatus(student, regents),
      local: this.getRegentsOnTrackStatus(student, local),
    };

    return onTrackStatusForDiffDiplomaTypes;
  }

  /**
   * Based on plannedDiplomaType, it either returns student.regentsDetails.byCategory5
   * or student.regentsDetails.byCategory9
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType] - a value
   * in PlannedDiplomaType[CONSTANT].humanName
   * @returns {Object} student.regentsDetails.byCategory5 or student.regentsDetails.byCategory9
   */
  @depends({
    methods: [],
    paths: [
      ...ImStudent.prototype.pathsFor('isPlannedDiplomaTypeByCat5OrByCat9'),
      'gradPlanningDetails.plannedDiplomaType',
      'regentsDetails.byCategory5',
      'regentsDetails.byCategory9',
    ],
  })
  getRegentsCatsBasedOnDiplomaType (student, plannedDiplomaType, ignoreSecondAndThirdCats?) {
    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;
    const byCat = this.imStudent.isPlannedDiplomaTypeByCat5OrByCat9(student, plannedDiplomaType);
    let cats;

    if (byCat === '5') {
      const { byCategory5 } = student.regentsDetails;

      if (ignoreSecondAndThirdCats) {
        cats = _.reduce(
          byCategory5,
          (acc, cat, catKey: string) => {
            const regentCategoryConst = _.find(RegentsCategory, { key: catKey });
            const { secondOrThirdCat } = regentCategoryConst;

            if (!secondOrThirdCat) {
              acc[catKey] = cat;
            }

            return acc;
          },
          {},
        );
      } else {
        cats = Object.assign({}, byCategory5);
      }
    }

    if (byCat === '9') {
      cats = Object.assign({}, student.regentsDetails.byCategory9);
    }

    return cats;
  }

  /**
   * Based on plannedDiplomaType, it returns ‘local’ or ‘regents’ or ‘advanced’
   * @param {Object} student
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType] - a value
   * in PlannedDiplomaType[CONSTANT].humanName
   * @returns {String} correct key use when referencing regentsDetails.needed['onTrack' or 'all'][keyForNeeded]
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('isSafetyNetEligible'), 'gradPlanningDetails.plannedDiplomaType'],
    methods: [],
  })
  getRegentsKeyForNeededBasedOnDiplomaType (student: IStudent, plannedDiplomaType?) {
    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;
    const plannedDiplomaTypeDetails = _.find(PlannedDiplomaType, { humanName: plannedDiplomaType });
    const type = plannedDiplomaTypeDetails.type;
    if (type !== 'grad') return type; // type corresponds to key for needed here
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);
    if (isSafetyNetEligible) {
      return 'local';
    } else {
      return 'regents';
    }
  }

  /**
   * @param {Object} student
   * @param {Object} PlannedDiplomaType const
   * @returns {String} correct key use when referencing
   * regentsDetails.keyForNumberPassed[getRegentsKeyForNumberPassed()]
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('isSafetyNetEligible')],
    methods: [],
  })
  getRegentsKeyForNumberPassed (student: IStudent, plannedDiplomaTypeDetails: IPlannedDiplomaType) {
    const { type, keyForNumberPassed } = plannedDiplomaTypeDetails;

    if (type !== 'grad') {
      return keyForNumberPassed;
    } else {
      const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);

      if (isSafetyNetEligible) {
        return PlannedDiplomaType.LOCAL.keyForNumberPassed;
      } else {
        return keyForNumberPassed;
      }
    }
  }

  /**
   * Based on plannedDiplomaType, it returns 'of5Local' or 'of5Regents' or 'of5Advanced'
   * @param {Object} student
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType] - a value
   * in PlannedDiplomaType[CONSTANT].humanName
   * @returns {String} correct key use when referencing regentsDetails.numberPassed[keyForNumberPassed]
   */
  @depends({
    paths: ImStudent.prototype.pathsFor('isSafetyNetEligible'),
    methods: [],
  })
  getRegentsKeyForNumberPassedBasedOnDiplomaType (student, plannedDiplomaType) {
    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;
    const plannedDiplomaTypeDetails = _.find(PlannedDiplomaType, { humanName: plannedDiplomaType });
    const type = plannedDiplomaTypeDetails.type;
    if (type !== 'grad') return plannedDiplomaTypeDetails.keyForNumberPassed;
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);
    if (isSafetyNetEligible) {
      return 'of5Local';
    } else {
      return 'of5Regents';
    }
  }

  /**
   * Based on a student's safety net eligibility, it returns 'local' or 'regents'
   * @param {Object} student
   * @returns {String} correct key use when referencing regentsDetails.needed['onTrack' or 'all'][keyForNeeded]
   */
  @depends({
    paths: ImStudent.prototype.pathsFor('isSafetyNetEligible'),
    methods: [],
  })
  getRegentsKeyForNeededGrad (student) {
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);
    if (isSafetyNetEligible) {
      return 'local';
    } else {
      return 'regents';
    }
  }

  /**
   * Based on a student's safety net eligibility, it returns 'of5Local' or 'of5Regents'
   * @param {Object} student
   * @returns {String} correct key use when referencing regentsDetails.numberPassed[keyForNumberPassed]
   */
  @depends({
    paths: ImStudent.prototype.pathsFor('isSafetyNetEligible'),
    methods: [],
  })
  getRegentsKeyForNumberPassedGrad (student) {
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);
    if (isSafetyNetEligible) {
      return 'of5Local';
    } else {
      return 'of5Regents';
    }
  }

  /**
   * @param {string} student
   * @returns {Array} of regents cats that have been fulfilled
   */
  @depends({
    paths: ImStudent.prototype.pathsFor('isPlannedDiplomaTypeByCat5OrByCat9'),
    methods: ['getRegentsCatsBasedOnDiplomaType', 'getRegentsFulfilledKeyBasedOnDiplomaType'],
  })
  getFulfilledRegentsCatsForDiplomaType (student) {
    const byCat = this.imStudent.isPlannedDiplomaTypeByCat5OrByCat9(student);
    const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
    const regentsCatsForDiploma = this.getRegentsCatsBasedOnDiplomaType(student, plannedDiplomaType);
    const keyForFulfilled = this.getRegentsFulfilledKeyBasedOnDiplomaType(student);
    const requiredRegentsCatsForDiploma =
      byCat === '9' ? RegentsCategoryByCategory9.required : RegentsCategoryByCategory5.required;
    const fulfilledRegentsCatsForDiplomaType = _.filter(requiredRegentsCatsForDiploma, requiredCat => {
      const requiredCatKey = requiredCat.key;
      const catIsFulfilled = regentsCatsForDiploma[requiredCatKey][keyForFulfilled];
      return catIsFulfilled;
    });
    return fulfilledRegentsCatsForDiplomaType;
  }

  // TODO add tests
  /**
   * @param {string} student
   * @returns {Array} of regents cats that have been fulfilled
   */
  @depends({
    paths: [
      ...ImModelsHelpers.expandPath('regentsDetails.byCategory5.<byCat5Key>'),
      'regentsDetails.numberPassed.of5Regents',
      'regentsDetails.numberPassed.of5Local',
      '_id',
    ],
    methods: ['getRegentsKeyForNumberPassedGrad', 'getRegentsFulfilledKeyForGrad'],
  })
  getFulfilledRegentsCatsForGrad (student) {
    const keyForFulfilled = this.getRegentsFulfilledKeyForGrad(student);
    const regentsDetailsByCat5 = student.regentsDetails.byCategory5;
    const fulfilledRegentsCatsForGrad = _.filter(RegentsCategoryByCategory5.required, requiredCat => {
      const requiredCatKey = requiredCat.key;
      const catIsFulfilledForGrad = regentsDetailsByCat5[requiredCatKey][keyForFulfilled];
      return catIsFulfilledForGrad;
    });
    return fulfilledRegentsCatsForGrad;
  }

  /**
   * Given a regentsCategoryKey, it returns whether it is required based on a diploma type
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType] - a value
   * in PlannedDiplomaType[CONSTANT].humanName
   * @returns {Boolean}
   */
  @depends({
    methods: [],
    paths: ImStudent.prototype.pathsFor('isPlannedDiplomaTypeByCat5OrByCat9'),
  })
  isRegentsCatRequiredForDiplomaType (student, regentsCategoryKey, plannedDiplomaType) {
    const byCat = this.imStudent.isPlannedDiplomaTypeByCat5OrByCat9(student, plannedDiplomaType);
    const regentsCategory = _.find(RegentsCategory, { key: regentsCategoryKey });

    if (byCat === '5') {
      return _.includes(RegentsCategoryByCategory5.required, regentsCategory);
    }

    if (byCat === '9') {
      return _.includes(RegentsCategoryByCategory9.required, regentsCategory);
    }
  }

  /**
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @returns {Array} of regentsExams that are scheduled for a RegentsCategory
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isPlannedDiplomaTypeByCat5OrByCat9'),
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.recommendation'),
      'nextScheduledRegents',
      'gradPlanningDetails.plannedDiplomaType',
    ],
    // including _getScheduledRegentsExamsForPlust1RegentsByCat5 here will cause circular dependency
    methods: [
      '_getScheduledRegentsExamsForRegentsByCat5',
      '_getScheduledRegentsExamsForRegentsByCat9',
      'getRegentsFulfilledKeyBasedOnDiplomaType',
    ],
  })
  getRegentsExamsScheduledForRegentsCat (student, regentsCategoryKey, adminDate?, ignoreSecondAndThirdCats?) {
    const byCat = this.imStudent.isPlannedDiplomaTypeByCat5OrByCat9(student);
    let scheduledRegentsExamsForCat;

    if (byCat === '5') {
      const keyForFulfilled = this.getRegentsFulfilledKeyBasedOnDiplomaType(student);

      if (regentsCategoryKey === RegentsCategory.PLUS_1.key) {
        scheduledRegentsExamsForCat = this._getScheduledRegentsExamsForPlust1RegentsByCat5(
          student,
          regentsCategoryKey,
          keyForFulfilled,
          adminDate,
        );
      } else if (ignoreSecondAndThirdCats) {
        // used by cat 9 logic and ignore 2nd and 3rd cats
        scheduledRegentsExamsForCat = this._getScheduledRegentsExamsForRegentsByCat9(
          student,
          regentsCategoryKey,
          adminDate,
        );
      } else {
        scheduledRegentsExamsForCat = this._getScheduledRegentsExamsForRegentsByCat5(
          student,
          regentsCategoryKey,
          keyForFulfilled,
          adminDate,
        );
      }
    }

    if (byCat === '9') {
      scheduledRegentsExamsForCat = this._getScheduledRegentsExamsForRegentsByCat9(
        student,
        regentsCategoryKey,
        adminDate,
      );
    }

    return scheduledRegentsExamsForCat;
  }

  /**
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @returns {Array} of regentsExams that are scheduled for a byCategory5 regentsCategory
   */
  @depends({
    methods: ['_getAllowableRegentsExamsToBeScheduledForRegentsCatBy5', 'isRegentsExamScheduled'],
    paths: ['nextScheduledRegents'],
  })
  _getScheduledRegentsExamsForRegentsByCat5 (student, regentsCategoryKey, keyForFulfilled, adminDate) {
    if (regentsCategoryKey === RegentsCategory.PLUS_1.key) {
      const msg =
        'Cannot call this function with regentsCategoryKey ' +
        'of PLUS_1, call this._getScheduledRegentsExamsForPlust1RegentsByCat5 instead.';
      throw new Error(msg);
    }

    const scheduledExams = [];
    let allowableRegentsExamsToBeScheduledForRegentsCat;

    allowableRegentsExamsToBeScheduledForRegentsCat = this._getAllowableRegentsExamsToBeScheduledForRegentsCatBy5(
      student,
      regentsCategoryKey,
      keyForFulfilled,
    );

    _.forEach(allowableRegentsExamsToBeScheduledForRegentsCat, (regentsExam: any) => {
      const { key } = regentsExam;
      const scheduled = this.isRegentsExamScheduled(student, key, adminDate);

      if (scheduled) scheduledExams.push(regentsExam);
    });

    return scheduledExams;
  }

  /**
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @returns {Array} of regentsExams that can be scheduled for a byCategory5 regentsCategory
   */
  @depends({
    paths: ['regentsDetails.byCategory5'],
    // TODO if you add both this dependencies here, tests will fail due to circular dependency
    methods: ['_getAllowableRegentsExamsToBeScheduledForFulfilledRegentsCatBy5'],
  })
  _getAllowableRegentsExamsToBeScheduledForRegentsCatBy5 (student, regentsCategoryKey, keyForFulfilled) {
    const regCat = student.regentsDetails.byCategory5[regentsCategoryKey][keyForFulfilled];

    // FOR ALL fulfilled OR fulfilledCr REGENTS CATEGORIES
    if (regCat) {
      return this._getAllowableRegentsExamsToBeScheduledForFulfilledRegentsCatBy5(
        student,
        regentsCategoryKey,
        keyForFulfilled,
      );
    } else {
      // FOR UNFULFILLED REGENTS CATEGORIES
      return this._getAllowableRegentsExamsToBeScheduledForNotFulfilledRegentsByCat5(
        student,
        regentsCategoryKey,
        keyForFulfilled,
      );
    }
  }

  /**
   * Can only be called on a 'regentsCategory' that has been fulfilled or fulfilledCr
   * in student.regentsDetails.byCategory5
   * @throws if regentsCategory has not been fulfilled or fulfilledCr
   * @throws if regentsCategoryKey is RegentsCategory.PLUS_1.key
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @returns {Array} of regentsExams of the regentsSubject that has fulfilled a regentsCategory
   */
  @depends({
    paths: ['regentsDetails.byCategory5'],
  })
  _getAllowableRegentsExamsToBeScheduledForFulfilledRegentsCatBy5 (student, regentsCategoryKey, keyForFulfilled) {
    const regentsCategoryDetails = student.regentsDetails.byCategory5[regentsCategoryKey];

    if (!regentsCategoryDetails[keyForFulfilled]) {
      throw new Error('This method can only be called on a fulfilled regentsCategory (based on diplomaType)');
    }

    if (regentsCategoryKey === RegentsCategory.PLUS_1.key) {
      throw new Error('This method cannot be called for \'plus1\' category');
    }

    const exam = _.find(RegentsExam, { shortName: regentsCategoryDetails.regentsName });
    if (!exam) {
      const firstWord = regentsCategoryDetails.regentsName.split(' ')[0];
      const isPBAT = firstWord === 'PBAT';
      if (isPBAT) return []; // TODO: fix when we add PBAT (https://newvisions.atlassian.net/browse/PFD-5039)
    }

    const regentsExamKey = exam.key;
    const regentsSubject = StudentStatic.getRegentsSubjectForRegentsExam(regentsExamKey);

    return _.filter(regentsSubject.exams, { isOffered: true });
  }

  /**
   * Can only be called on a 'regentsCategory' that has NOT been fulfilled
   * or fulfilledCr in student.regentsDetails.byCategory5
   * @throws if regentsCategory has not been fulfilled or fulfilledCr
   * @throws if regentsCategoryKey is RegentsCategory.PLUS_1.key
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @return {Array} of regentsExams that can be scheduled for a not fulfilled
   * regentsCategory that can fulfill a regentsCategory
   */
  @depends({
    paths: ['regentsDetails.byCategory5'],
    methods: ['_getAllowableRegentsExamsToBeScheduledForRegentsCatBy5', '_getRegentsSubjectsScheduledForRegentsCat'],
  })
  _getAllowableRegentsExamsToBeScheduledForNotFulfilledRegentsByCat5 (
    student,
    regentsCategoryKey: string,
    keyForFulfilled,
  ) {
    const regentsCategoryDetails = student.regentsDetails.byCategory5[regentsCategoryKey];

    if (regentsCategoryDetails[keyForFulfilled]) {
      throw new Error(
        'This method can only be called on a regentsCategory that has not been fulfilled (based on diplomaType)',
      );
    }

    if (regentsCategoryKey === RegentsCategory.PLUS_1.key) {
      throw new Error('This method cannot be called for \'plus1\' category');
    }

    // FOR ALL NON PLUS_1 CATEGORIES
    const regentsExamsForRegentsCategory = StudentStatic.getRegentsExamsForRegentsCategory(regentsCategoryKey);
    const firstCatInGrouping_A = _.find(RegentsCategoryGroupingForByCat5, subj => {
      return subj.first.key === regentsCategoryKey;
    });
    const secondCatInGrouping = _.find(RegentsCategoryGroupingForByCat5, subj => {
      return subj.second.key === regentsCategoryKey;
    });
    const thirdCatInGrouping = _.find(RegentsCategoryGroupingForByCat5, subj => {
      return subj.third && subj.third.key === regentsCategoryKey;
    });
    const regentsCategoryGroupingForByCat5 = firstCatInGrouping_A || secondCatInGrouping || thirdCatInGrouping;

    // !regentsCategoryGroupingForByCat5 APPLIES TO ELA AND LOTE
    // firstCatInGrouping APPLIES TO MATH, SCI, and SS
    if (!regentsCategoryGroupingForByCat5 || firstCatInGrouping_A) {
      return regentsExamsForRegentsCategory;
    }

    // FOR SECOND_MATH, THIRD_MATH, SECOND_SCI, SECOND_SS
    const firstCatInGrouping = regentsCategoryGroupingForByCat5.first;

    const firstCatInGroupingFulFilled = student.regentsDetails.byCategory5[firstCatInGrouping.key][keyForFulfilled];
    let secondCatInGroupingFulfilled;

    if (secondCatInGrouping) {
      if (firstCatInGroupingFulFilled) {
        const allowable = this._getAllowableRegentsExamsToBeScheduledForRegentsCatBy5(
          student,
          firstCatInGrouping.key,
          keyForFulfilled,
        );
        return _.difference(regentsExamsForRegentsCategory, allowable);
      }
      const sched = this._getRegentsSubjectsScheduledForRegentsCat(student, firstCatInGrouping.key);
      return sched.length > 1 ? regentsExamsForRegentsCategory : [];
    }

    if (thirdCatInGrouping) {
      const secondCatInGrouping = regentsCategoryGroupingForByCat5.second;
      secondCatInGroupingFulfilled = student.regentsDetails.byCategory5[secondCatInGrouping.key][keyForFulfilled];

      if (secondCatInGroupingFulfilled) {
        const firstAllowable = this._getAllowableRegentsExamsToBeScheduledForRegentsCatBy5(
          student,
          firstCatInGrouping.key,
          keyForFulfilled,
        );
        const secondAllowable = this._getAllowableRegentsExamsToBeScheduledForRegentsCatBy5(
          student,
          secondCatInGrouping.key,
          keyForFulfilled,
        );
        return _.difference(regentsExamsForRegentsCategory, firstAllowable, secondAllowable);
      }

      if (firstCatInGroupingFulFilled) {
        const sched = this._getRegentsSubjectsScheduledForRegentsCat(student, secondCatInGrouping.key);
        let allowable;
        if (sched.length > 1) {
          const by5 = this._getAllowableRegentsExamsToBeScheduledForRegentsCatBy5(
            student,
            firstCatInGrouping.key,
            keyForFulfilled,
          );
          allowable = _.difference(regentsExamsForRegentsCategory, by5);
        } else {
          allowable = [];
        }
        return allowable;
      }
      const sched = this._getRegentsSubjectsScheduledForRegentsCat(student, secondCatInGrouping.key);
      return sched.length > 2 ? regentsExamsForRegentsCategory : [];
    }
  }

  /**
   * supports both byCategory5 and byCategory9
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @return {Array} of regentsSubjects that are scheduled for a regentsCategory
   */
  @depends({ methods: ['getRegentsExamsScheduledForRegentsCat'] })
  _getRegentsSubjectsScheduledForRegentsCat (student, regentsCategoryKey) {
    const regentsSchedForCat = this.getRegentsExamsScheduledForRegentsCat(student, regentsCategoryKey);
    const regentsSubjectsForRegentsExam = _.reduce(
      regentsSchedForCat,
      (result, scheduledRegentsExam: any) => {
        const regentsSubjectForRegentsExam = StudentStatic.getRegentsSubjectForRegentsExam(scheduledRegentsExam.key);
        result.push(regentsSubjectForRegentsExam);
        return result;
      },
      [],
    );
    return _.uniq(regentsSubjectsForRegentsExam);
  }

  /**
   * @returns {Array} of regentsExams that are scheduled for RegentsCategory.PLUS_1
   */
  @depends({
    paths: ['regentsDetails.byCategory5'],
    methods: ['getRegentsExamsScheduledForRegentsCat'],
  })
  _getScheduledRegentsExamsForPlust1RegentsByCat5 (student, regentsCategoryKey, keyForFulfilled, adminDate) {
    const plus1RegentsExams = [];

    if (regentsCategoryKey !== RegentsCategory.PLUS_1.key) {
      throw new Error(
        `_getScheduledRegentsExamsForPlust1RegentsByCat5 called with
        invalid regentsCategoryKey: ${regentsCategoryKey}`,
      );
    }

    if (student.regentsDetails.byCategory5[RegentsCategory.PLUS_1.key][keyForFulfilled]) {
      return [];
    }

    _.forEach(RegentsCategoryGroupingForByCat5, regentsCategoryGroup => {
      // we can assume that no second or third categories in a regentsCategoryGroup have been fulfilled
      // thus get the scheduled exams for all first categories in a regentsCategoryGroup
      // only if more than one exam has been scheduled for a first category, then add those to scheduled plus1 exams
      const regentsCategoryKey = regentsCategoryGroup.second.key;
      const scheduledExamsForCategory = this.getRegentsExamsScheduledForRegentsCat(
        student,
        regentsCategoryKey,
        adminDate,
        false,
      );

      plus1RegentsExams.push(scheduledExamsForCategory);
    });

    return _.flatten(plus1RegentsExams);
  }

  /**
   * @param {string} regentsCategory.key - a value in RegentsCategory[CONSTANT].key
   * @returns {Array} of regentsExams that are scheduled for a byCategory9 regentsCategory
   */
  @depends({
    paths: ['nextScheduledRegents'],
    methods: ['isRegentsExamScheduled'],
  })
  _getScheduledRegentsExamsForRegentsByCat9 (student, regentsCategoryKey, adminDate?) {
    const regentsExams = StudentStatic.getRegentsExamsForRegentsCategory(regentsCategoryKey);

    return _.filter(regentsExams, (regentsExam: any) => {
      const { key } = regentsExam;

      return this.isRegentsExamScheduled(student, key, adminDate);
    });
  }

  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isPlannedToGraduateThisSchoolYear'),
      ...ImStudent.prototype.pathsFor('getStudentYear'),
      'gradPlanningDetails.plannedDiplomaType',
      'gradPlanningDetails.plannedGraduationDate',
      'regentsDetails.needed.all.advanced',
      'regentsDetails.needed.all.regents',
      'regentsDetails.needed.all.local',
      'regentsDetails.needed.onTrack.advanced',
      'regentsDetails.needed.onTrack.regents',
      'regentsDetails.needed.onTrack.local',
    ],
    methods: ['getRegentsKeyForNeededBasedOnDiplomaType'],
  })
  isRegentsCatNeededForOnTrack (student, regentsCategory) {
    // NOTE: D75 students (and any others who are studentYear === 0) should NOT be measured
    // against school metrics until studentYear === 1;
    const studentYear = this.imStudent.getStudentYear(student);
    if (studentYear === 0) return false;

    const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
    const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;

    let matchingCategory;
    let neededOnTrack;

    // needed to add this logic because backend is NOT calculating regentsDetails.needed.onTrack.grad
    // if the student has no grad plan or is non-graduate then plannedDiplomaTypeDetails.type will equal grad
    // if that is the case, check if the student is spedDetails
    // if sped, use regentsDetails.needed.onTrack.local
    // if gened, use regentsDetails.needed.onTrack.regents
    const keyForNeeded = this.getRegentsKeyForNeededBasedOnDiplomaType(student, plannedDiplomaType);

    // if the student is planned to graduate this year
    // or, the student has no planned grad date but is in their 4th year
    // then all unfulfilled cats are needed
    const plannedToGradThisYear = this.imStudent.isPlannedToGraduateThisSchoolYear(student);
    const inFourthYear = !plannedGraduationDate && this.imStudent.getStudentYear(student) >= 4;

    if (plannedToGradThisYear || inFourthYear) {
      neededOnTrack = student.regentsDetails.needed.all[keyForNeeded];
    } else {
      neededOnTrack = student.regentsDetails.needed.onTrack[keyForNeeded];
    }

    matchingCategory = _.find(RegentsCategory, category => {
      return _.includes(neededOnTrack, category.neededOnTrackValue) && category.key === regentsCategory;
    });
    return !!matchingCategory;
  }

  @depends({
    paths: ['regentsDetails', 'nextScheduledRegents'],
    methods: [],
  })
  isStudentAdminDateValid (student, regentsExamKey, adminDate?): boolean {
    let studentAdminDateIsValid;
    const {
      nextScheduledRegents,
      regentsDetails: { byExam: regentsDetailsByExam },
    } = student;

    const examIsDiscontinued = _.includes(DiscontinuedRegentsExamKeys, regentsExamKey);
    if (examIsDiscontinued) return false;

    const allValidAdminDates = _.values(ValidRegentsPlans);
    const studentAdminDate = adminDate || nextScheduledRegents[regentsExamKey].adminDate;
    const recommendation = regentsDetailsByExam[regentsExamKey].recommendation;
    const studentAdminDateIsAValidAdminDate = _.includes(allValidAdminDates, studentAdminDate);
    const recommmendationIsValid = recommendation !== 'Not Eligible';
    studentAdminDateIsValid = recommmendationIsValid && studentAdminDateIsAValidAdminDate;

    return studentAdminDateIsValid;
  }

  /**
   * Given a regentsExamKey, it returns a boolean if the student is scheduled to sit for an exam.
   * An optional adminDate param can be passed to see if a student is scheduled to sit for
   * an exam on a particular admin date.
   * @param {string} `regentsExam.key` - a value in RegentsExam[CONSTANT].key
   * @param {string} `adminDate` - optional param
   * @returns {Boolean}
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('getEffectivePlannedGradDate'),
      'nextScheduledRegents',
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.recommendation'),
    ],
  })
  isRegentsExamScheduled (student, regentsExamKey, adminDate?) {
    // student can't be scheduled for a discontinued exam (compEla, oldTrig, oldAlg, oldGeom)
    // well, technically they can be from the grid, but we don't count it
    // Danielle TODO - add a test for this
    const examIsDiscontinued = _.includes(DiscontinuedRegentsExamKeys, regentsExamKey);
    if (examIsDiscontinued) return false;

    const studentAdminDate = student.nextScheduledRegents[regentsExamKey].adminDate;
    const studentAdminDateIsAValidAdminDate = this.isStudentAdminDateValid(student, regentsExamKey, studentAdminDate);

    if (adminDate) {
      const studentAdminDateMatchesAdminDatePassedIn = studentAdminDate === adminDate;
      return studentAdminDateIsAValidAdminDate && studentAdminDateMatchesAdminDatePassedIn;
    }

    return studentAdminDateIsAValidAdminDate;
  }

  /**
   * Gets the count of exams planned beyond the students planned grad date
   * @returns {number}
   */
  @depends({
    methods: ['getRegentsExamsPlannedBeyondStudentsGradDate'],
  })
  getCountOfRegentsExamsPlannedBeyondStudentsGradDate (student: IStudent): number {
    const regentsExamsPlannedBeyondStudentsGradDate = this.getRegentsExamsPlannedBeyondStudentsGradDate(student);
    return regentsExamsPlannedBeyondStudentsGradDate.length;
  }

  /**
   * Gets the exams planned beyond the students planned grad date
   * Uses the students cohort if the planned grad date is not defined
   * @returns {IRegentsExam[]}
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('getEffectivePlannedGradDate'), 'nextScheduledRegents'],
    methods: ['isRegentsExamScheduled'],
  })
  getRegentsExamsPlannedBeyondStudentsGradDate (student: IStudent): IRegentsExam[] {
    // Get the students effective planned grad date
    // Either their planned grad date or grad cohort if planned grad date is null
    const effPlannedGradDate = this.imStudent.getEffectivePlannedGradDate(student);
    const effPlannedGradDateMomentObj = this.dateHelpers.getMomentObjForMonthYearDate(effPlannedGradDate);
    const result: IRegentsExam[] = [];

    // Figure out which regents plans are after the students planned grad date
    const regentsExamsPlannedBeyondStudentsGradDate = _.reduce(
      student.nextScheduledRegents,
      (result, value: INextScheduledRegentsExam, key) => {
        // Check that the planned exam is valid - meaning its not an old exam or planned in the past
        const examIsSched = this.isRegentsExamScheduled(student, key);
        if (examIsSched) {
          // (DS) TODO: refactor this NOT to use moment
          // The beauty of ISO dates is that the strings themself are sortable.So 2018 - 03 - 11 >= 2018 - 03 - 10
          const examAdminDateMomentObj = this.dateHelpers.getMomentObjForMonthYearDate(value.adminDate);
          const examAdminDateIsBeyondStudGradDate = !effPlannedGradDateMomentObj.isSameOrAfter(examAdminDateMomentObj);
          if (examAdminDateIsBeyondStudGradDate) {
            const regentsExam = _.find(RegentsExam, { key });
            result.push(regentsExam);
          }
        }
        return result;
      },
      result,
    );

    return regentsExamsPlannedBeyondStudentsGradDate;
  }

  /**
   * @param {Object} student
   * @param {String} gradReq
   * @param {String} adminDate (optional)
   * @return {Array} of Objects (RegentsExam) scheduled for a gradReq
   */
  @depends({ methods: ['isRegentsExamScheduled'] })
  getScheduledRegentsExamsForGradReq (student, gradReq, adminDate) {
    const creditRequirement = _.find(CreditRequirements, { camelCase: gradReq });
    const regentsExams = creditRequirement.regentsExams;

    return _.reduce(
      regentsExams,
      (result, value) => {
        if (this.isRegentsExamScheduled(student, value, adminDate)) {
          const regentsExam = _.find(RegentsExam, { key: value });
          result.push(regentsExam);
        }
        return result;
      },
      [],
    );
  }

  /**
   * @param {Object} student
   * @param {String} subjectArea
   * @param {String} adminDate (optional)
   * @return {Array} of Objects (RegentsExam) scheduled for a subjectArea
   */
  @depends({ methods: ['isRegentsExamScheduled'] })
  getScheduledRegentsExamsForSubjectArea (student, subjectArea, adminDate) {
    const creditRequirement = _.find(CreditRequirements, creditRequirement => {
      const creditReqSubjectAreas = creditRequirement.join || creditRequirement.camelCase;
      return _.includes(creditReqSubjectAreas, subjectArea);
    });
    const regentsExams = creditRequirement ? creditRequirement.regentsExams : [];

    return _.reduce(
      regentsExams,
      (result, value) => {
        if (this.isRegentsExamScheduled(student, value, adminDate)) {
          const regentsExam = _.find(RegentsExam, { key: value });
          result.push(regentsExam);
        }
        return result;
      },
      [],
    );
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} next scheduled regents exams that are not discontinued and valid admin dates
   */
  @depends({
    paths: ['nextScheduledRegents'],
    methods: ['isRegentsExamScheduled'],
  })
  getAllNextScheduledRegentsExams (student) {
    const allNextScheduledRegents = _.reduce(
      student.nextScheduledRegents,
      (result, value, key) => {
        const examIsSched = this.isRegentsExamScheduled(student, key);
        if (examIsSched) {
          const regentsExam = _.find(RegentsExam, { key });
          result.push(regentsExam);
        }
        return result;
      },
      [],
    );
    return allNextScheduledRegents;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} next scheduled regents exams that are valid and sched for next admin
   */
  @depends({
    paths: ['nextScheduledRegents'],
    methods: ['isRegentsExamScheduled'],
  })
  getRegentsSchedForNextAdmin (student) {
    const regentsSchedNextAdmin = _.reduce(
      student.nextScheduledRegents,
      (result, value, key) => {
        const examIsSched = this.isRegentsExamScheduled(student, key, NextRegentsAdminDate);
        if (examIsSched) {
          const regentsExam = _.find(RegentsExam, { key });
          result.push(regentsExam);
        }
        return result;
      },
      [],
    );
    return regentsSchedNextAdmin;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of next scheduled regents exams that are valid and sched for next admin
   */
  @depends({
    paths: [],
    methods: ['getRegentsSchedForNextAdmin'],
  })
  getCountOfRegentsSchedForNextAdmin (student) {
    const regentsSchedNextAdmin = this.getRegentsSchedForNextAdmin(student);
    return regentsSchedNextAdmin.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} next scheduled regents exams that are valid and sched for future admin
   */
  @depends({
    paths: ['nextScheduledRegents'],
    methods: ['getAllNextScheduledRegentsExams'],
  })
  getRegentsSchedForFutureAdmin (student) {
    const allNextScheduledRegentsExams = this.getAllNextScheduledRegentsExams(student);
    const regentsSchedForFutureAdmin = _.reduce(
      allNextScheduledRegentsExams,
      (result, regentsExam) => {
        const adminDate = student.nextScheduledRegents[regentsExam.key].adminDate;
        if (adminDate !== NextRegentsAdminDate) {
          result.push(regentsExam);
        }
        return result;
      },
      [],
    );
    return regentsSchedForFutureAdmin;
  }

  // DANIELLE TODO WRITE TESTS
  // Danielle ask Carlos how to dry this up - same code in Regens panel controller
  /**
   * @param {Object} student
   * @return {Number} count planned regents exams that are offered at same time
   */
  @depends({
    paths: [],
    methods: ['getRegentsSchedForNextAdmin'],
  })
  getRegentsExamsWithConflicts (student) {
    const regentsSchedNextAdmin = this.getRegentsSchedForNextAdmin(student);
    const regentsSchedWithConflicts = [];
    // loop through exams sched next admin
    _.forEach(regentsSchedNextAdmin, regentsSched => {
      const regentsSchedExamKey = regentsSched.key;
      const regentsSchedDateTime = regentsSched.nextAdminDates;
      // for each exam loop through exams again to see if
      // any of the other exams have the same nextAdmin date as this one
      const regentsSchedConflicts = _.filter(regentsSchedNextAdmin, thisRegentsSched => {
        // if the schedule is unknown there are no conflicts
        if (regentsSchedDateTime[0] === 'Schedule Unknown') return false;
        // otherwise, check for conflicts
        const sameDateTime = _.intersection(regentsSchedDateTime, thisRegentsSched.nextAdminDates).length > 0;
        const differentExam = regentsSchedExamKey !== thisRegentsSched.key;
        if (sameDateTime && differentExam) return true;
        return false;
      });
      if (regentsSchedConflicts.length > 0) regentsSchedWithConflicts.push(regentsSched);
    });
    return regentsSchedWithConflicts;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count planned regents exams that are offered at same time
   */
  @depends({
    paths: [],
    methods: ['getRegentsExamsWithConflicts'],
  })
  getCountOfRegentsExamsWithConflicts (student) {
    const regentsExamsWithConflicts = this.getRegentsExamsWithConflicts(student);
    return regentsExamsWithConflicts.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count regents cats that have been fulfilled
   */
  @depends({
    paths: [],
    methods: ['getFulfilledRegentsCatsForDiplomaType'],
  })
  getCountOfFulfilledRegentsCatsForDiplomaType (student) {
    const fulfilledRegentsCatsForDiplomaType = this.getFulfilledRegentsCatsForDiplomaType(student);
    return fulfilledRegentsCatsForDiplomaType.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered on
   * trackfor his or her diploma type AND that he or she is NOT currently scheduled
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiploma', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackDiplomaNotSched (student) {
    const regentsCatsNeededForOnTrackDiploma = this.getRegentsCatsNeededForOnTrackDiploma(student);
    const regentsCatsNeededForOnTrackDiplomaNotSched = _.filter(regentsCatsNeededForOnTrackDiploma, regentsCategory => {
      const regentsCategoryKey = regentsCategory.key;
      const regentsExamSchedForRegentsCat = this.getRegentsExamsScheduledForRegentsCat(student, regentsCategoryKey);
      const numExamsSched = regentsExamSchedForRegentsCat.length;
      return numExamsSched === 0;
    });
    return regentsCatsNeededForOnTrackDiplomaNotSched;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered on
   * track for graduation AND that he or she is NOT currently scheduled
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackGrad', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackGradNotSched (student) {
    const regentsCatsNeededForOnTrackGrad = this.getRegentsCatsNeededForOnTrackGrad(student);
    const regentsCatsNeededForOnTrackGradNotSched = _.filter(regentsCatsNeededForOnTrackGrad, regentsCategory => {
      const regentsCategoryKey = regentsCategory.key;
      const regentsExamSchedForRegentsCat = this.getRegentsExamsScheduledForRegentsCat(student, regentsCategoryKey);
      const numExamsSched = regentsExamSchedForRegentsCat.length;
      return numExamsSched === 0;
    });
    return regentsCatsNeededForOnTrackGradNotSched;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {String} priority group
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('getStudentYear'),
      ...ImStudent.prototype.pathsFor('isActive'),
      ...ImStudent.prototype.pathsFor('isPlannedToGraduateThisSchoolYear'),
      'isHS',
    ],
    methods: ['getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched'],
  })
  getRegentsPlanningPriorityGrouping (student) {
    const studentYear = this.imStudent.getStudentYear(student);
    const studentIsActive = this.imStudent.isActive(student);
    const isHS = student.isHS;
    if (!isHS) return 'n/a'; // middle school students don't get a priority
    if (!studentIsActive) return 'n/a'; // in active students don't get a priority

    // get unaddressed regents needs
    const countOfRegentsCatsNeededForOnTrackDiplomaNotSched = this.getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched(
      student,
    );
    const hasUnaddressedRegentsNeeds = countOfRegentsCatsNeededForOnTrackDiplomaNotSched > 0;

    // we only care about students with unaddressed gaps!
    if (!hasUnaddressedRegentsNeeds) return 'No Unaddressed Regents Needs';

    // seniors and older
    if (studentYear >= 4) {
      // get student's grad plan
      const gradPlan = this.imStudent.getCurrentGradPlan(student);
      const gradPlanIsIncomplete = gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
      const gradPlanIsInPast = gradPlan === GraduationPlan.PLAN_IN_PAST.humanName;
      const nonGrad = gradPlan === GraduationPlan.NON_GRADUATE.humanName;

      if (nonGrad) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_NON_GRAD'); }

      if (gradPlanIsIncomplete || gradPlanIsInPast) {
        return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLAN_INCOMPLETE');
      }

      // get student's planned graduation date
      const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;
      const plannedGraduationDateDetails = _.find(GraduationDate, { humanName: plannedGraduationDate });
      const plannedGraduationMonthOrder = plannedGraduationDateDetails.monthOrder;

      // see if the student is planned to grad this year
      const plannedToGradThisYear = this.imStudent.isPlannedToGraduateThisSchoolYear(student);

      if (plannedToGradThisYear) {
        if (plannedGraduationMonthOrder === 1 || plannedGraduationMonthOrder === 2) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_JAN_MARCH'); }
        if (plannedGraduationMonthOrder === 3) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_JUNE'); }
        if (plannedGraduationMonthOrder === 4) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_AUG'); }
      } else {
        return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_BEYOND_AUG');
      }
    }

    // younger cohorts
    if (studentYear === 3) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('JUNIORS'); }
    if (studentYear === 2) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('SOPHOMORES'); }
    if (studentYear === 1) { return this.RegentsPlanningPriorityGroupingsService.getStudentValueForPriorityGroupByKey('FRESHMAN'); }
  }

  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isActive'),
      ...ImStudent.prototype.pathsFor('getCurrentGradPlanTransfer'),
      'isHS',
    ],
    methods: ['getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched'],
  })
  getRegentsPlanningPriorityGroupingTransfer (student, school) {
    const studentIsActive = this.imStudent.isActive(student);
    const isHS = student.isHS;
    if (!isHS) return 'n/a'; // middle school students don't get a priority
    if (!studentIsActive) return 'n/a'; // in active students don't get a priority

    // get unaddressed regents needs
    const countOfRegentsCatsNeededForOnTrackDiplomaNotSched = this.getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched(
      student,
    );
    const hasUnaddressedRegentsNeeds = countOfRegentsCatsNeededForOnTrackDiplomaNotSched > 0;

    if (!hasUnaddressedRegentsNeeds) return 'No Unaddressed Regents Needs';

    const gradPlan = this.imStudent.getCurrentGradPlanTransfer(student);
    const gradPlanIsIncomplete: boolean = gradPlan === GraduationPlanTransfer.PLAN_INCOMPLETE.humanName;
    const gradPlanIsInPast: boolean = gradPlan === GraduationPlanTransfer.PLAN_IN_PAST.humanName;
    const nonGrad: boolean = gradPlan === GraduationPlanTransfer.NON_GRADUATE.humanName;

    if (nonGrad) {
      return this.RegentsPlanningPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey(
        'PLANNED_NON_GRAD',
      );
    }

    if (gradPlanIsIncomplete || gradPlanIsInPast) {
      return this.RegentsPlanningPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey(
        'PLAN_INCOMPLETE',
      );
    }

    const gradDate = _.find(GraduationPlanTransfer, { humanName: gradPlan }).date;
    const next4GradDates = this.ImSchool.getNextFourGradDatesForTransfer();
    const next4GradDatesArray = [
      next4GradDates[0].humanName,
      next4GradDates[1].humanName,
      next4GradDates[2].humanName,
      next4GradDates[3].humanName,
    ];
    const index = next4GradDatesArray.indexOf(gradDate as TValidGradDates) + 1;

    if (index === 1) { return this.RegentsPlanningPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('ONE_TERM_AWAY'); }
    if (index === 2) {
      return this.RegentsPlanningPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey(
        'TWO_TERMS_AWAY',
      );
    }
    if (index === 3) {
      return this.RegentsPlanningPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey(
        'THREE_TERMS_AWAY',
      );
    }
    if (index === 4) {
      return this.RegentsPlanningPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey(
        'FOUR_TERMS_AWAY',
      );
    }

    return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLANNED_BEYOND_4');
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories  that the student needs
   * to be considered on track for his or her diploma type
   */
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
    methods: ['getRegentsOnTrackStatus'],
  })
  getRegentsCatsNeededForOnTrackDiploma (student) {
    const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
    const regentsOnTrackStatus = this.getRegentsOnTrackStatus(student, plannedDiplomaType);
    const regentsCatsNeededForOnTrackDiploma = regentsOnTrackStatus.neededOnTrackCategories;
    return regentsCatsNeededForOnTrackDiploma;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs
   * to be considered on track for graduation
   */
  @depends({
    paths: ['regentsDetails.needed.onTrack.local', 'regentsDetails.needed.onTrack.regents'],
    methods: ['getRegentsKeyForNeededGrad'],
  })
  getRegentsCatsNeededForOnTrackGrad (student) {
    const keyForNeededGrad = this.getRegentsKeyForNeededGrad(student);
    const neededForOnTrackValues = student.regentsDetails.needed.onTrack[keyForNeededGrad];
    const neededForOnTrackCats = _.map(neededForOnTrackValues, (neededOnTrackValue: string) => {
      return _.find(RegentsCategory, { neededOnTrackValue });
    });
    return neededForOnTrackCats;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of regents categories that the student
   * needs to be considered on track for his or her diploma type
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiploma'],
  })
  getCountOfRegentsCatsNeededForOnTrackDiploma (student) {
    const regentsCatsNeededForOnTrackDiploma = this.getRegentsCatsNeededForOnTrackDiploma(student);
    return regentsCatsNeededForOnTrackDiploma.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student
   * needs to be considered on track for his or her diploma type AND that he or she is currently scheduled for
   */
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
    methods: ['getRegentsCatsNeededForOnTrackDiploma', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackDiplomaSched (student) {
    const regentsCatsNeededForOnTrackDiploma = this.getRegentsCatsNeededForOnTrackDiploma(student);
    const regentsCatsNeededForOnTrackDiplomaSched = _.filter(regentsCatsNeededForOnTrackDiploma, regentsCategory => {
      const regentsCategoryKey = regentsCategory.key;
      const regentsExamSchedForRegentsCat = this.getRegentsExamsScheduledForRegentsCat(student, regentsCategoryKey);
      const numExamsSched = regentsExamSchedForRegentsCat.length;
      return numExamsSched > 0;
    });
    return regentsCatsNeededForOnTrackDiplomaSched;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of regents categories that the student needs to be considered on
   * track for his or her diploma type AND that he or she is currently scheduled for
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiplomaSched'],
  })
  getCountOfRegentsCatsNeededForOnTrackDiplomaSched (student) {
    const regentsCatsNeededForOnTrackDiplomaSched = this.getRegentsCatsNeededForOnTrackDiplomaSched(student);
    return regentsCatsNeededForOnTrackDiplomaSched.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered on
   * track for his or her diploma type AND that he or she is currently scheduled for IN THE NEXT ADMIN
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiploma', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin (student) {
    const regentsCatsNeededForOnTrackDiploma = this.getRegentsCatsNeededForOnTrackDiploma(student);
    const regentsCatsNeededForOnTrackDiplomaSchedNextAdmin = _.filter(
      regentsCatsNeededForOnTrackDiploma,
      regentsCategory => {
        const regentsCategoryKey = regentsCategory.key;
        const regentsExamSchedForRegentsCatInNextAdmin = this.getRegentsExamsScheduledForRegentsCat(
          student,
          regentsCategoryKey,
          NextRegentsAdminDate,
        );
        const numExamsSchedNextAdmin = regentsExamSchedForRegentsCatInNextAdmin.length;
        return numExamsSchedNextAdmin > 0;
      },
    );
    return regentsCatsNeededForOnTrackDiplomaSchedNextAdmin;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered on
   * track for graduation AND that he or she is currently scheduled for IN THE NEXT ADMIN
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackGrad', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackGradSchedNextAdmin (student) {
    const regentsCatsNeededForOnTrackGrad = this.getRegentsCatsNeededForOnTrackGrad(student);
    const regentsCatsNeededForOnTrackGradSchedNextAdmin = _.filter(regentsCatsNeededForOnTrackGrad, regentsCategory => {
      const regentsCategoryKey = regentsCategory.key;
      const regentsExamSchedForRegentsCatInNextAdmin = this.getRegentsExamsScheduledForRegentsCat(
        student,
        regentsCategoryKey,
        NextRegentsAdminDate,
      );
      const numExamsSchedNextAdmin = regentsExamSchedForRegentsCatInNextAdmin.length;
      return numExamsSchedNextAdmin > 0;
    });
    return regentsCatsNeededForOnTrackGradSchedNextAdmin;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered
   * on track for his or her diploma type AND that he or she is currently scheduled for IN THE NEXT ADMIN
   */
  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
    methods: ['getRegentsOnTrackStatus', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsExamsSchedNextAdminForNeededOnTrackDiplomaCats (student) {
    const plannedDiplomaType = student.gradPlanningDetails.plannedDiplomaType;
    const regentsOnTrackStatus = this.getRegentsOnTrackStatus(student, plannedDiplomaType);
    const regentsCatsNeededForOnTrackDiploma = regentsOnTrackStatus.neededOnTrackCategories;
    const regentsExamsSchedNextAdminForNeededOnTrackDiplomaCats = _.reduce(
      regentsCatsNeededForOnTrackDiploma,
      (result, regentsCategory) => {
        const regentsCategoryKey = regentsCategory.key;
        const regentsExamSchedForRegentsCatInNextAdmin = this.getRegentsExamsScheduledForRegentsCat(
          student,
          regentsCategoryKey,
          NextRegentsAdminDate,
        );
        return result.concat(regentsExamSchedForRegentsCatInNextAdmin);
      },
      [],
    );
    return regentsExamsSchedNextAdminForNeededOnTrackDiplomaCats;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of regents categories that the student needs to be considered on track
   * for his or her diploma type AND that he or she is currently scheduled for IN THE NEXT ADMIN
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin'],
  })
  getCountOfRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin (student) {
    const regentsCatsNeededForOnTrackDiplomaSchedNextAdmin = this.getRegentsCatsNeededForOnTrackDiplomaSchedNextAdmin(
      student,
    );
    return regentsCatsNeededForOnTrackDiplomaSchedNextAdmin.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered on
   * track for his or her diploma type AND that he or she is currently scheduled for IN FUTURE ADMIN
   */
  @depends({
    paths: [],
    methods: ['getRegentsOnTrackStatus', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin (student) {
    const regentsCatsNeededForOnTrackDiploma = this.getRegentsCatsNeededForOnTrackDiploma(student);
    const regentsCatsNeededForOnTrackDiplomaSchedFutureAdmin = _.filter(
      regentsCatsNeededForOnTrackDiploma,
      regentsCategory => {
        const regentsCategoryKey = regentsCategory.key;
        const regentsExamSchedForRegentsCat = this.getRegentsExamsScheduledForRegentsCat(student, regentsCategoryKey);
        const regentsExamSchedForRegentsCatFutureAdmin = _.filter(regentsExamSchedForRegentsCat, (regentsExam: any) => {
          const nextSchedAdminDate = student.nextScheduledRegents[regentsExam.key].adminDate;
          const nextSchedAdminDateIsValid = _.includes(ValidRegentsPlans, nextSchedAdminDate);
          const nextSchedAdminDateIsNotNextAdmin = nextSchedAdminDate !== NextRegentsAdminDate;
          return nextSchedAdminDateIsValid && nextSchedAdminDateIsNotNextAdmin;
        });
        return regentsExamSchedForRegentsCatFutureAdmin.length > 0;
      },
    );
    return regentsCatsNeededForOnTrackDiplomaSchedFutureAdmin;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Array} array of regents categories that the student needs to be considered on
   * track for graduation AND that he or she is currently scheduled for IN FUTURE ADMIN
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackGrad', 'getRegentsExamsScheduledForRegentsCat'],
  })
  getRegentsCatsNeededForOnTrackGradSchedFutureAdmin (student) {
    const regentsCatsNeededForOnTrackGrad = this.getRegentsCatsNeededForOnTrackGrad(student);
    const regentsCatsNeededForOnTrackGradSchedFutureAdmin = _.filter(
      regentsCatsNeededForOnTrackGrad,
      regentsCategory => {
        const regentsCategoryKey = regentsCategory.key;
        const regentsExamSchedForRegentsCat = this.getRegentsExamsScheduledForRegentsCat(student, regentsCategoryKey);
        const regentsExamSchedForRegentsCatFutureAdmin = _.filter(regentsExamSchedForRegentsCat, (regentsExam: any) => {
          const nextSchedAdminDate = student.nextScheduledRegents[regentsExam.key].adminDate;
          const nextSchedAdminDateIsValid = _.includes(ValidRegentsPlans, nextSchedAdminDate);
          const nextSchedAdminDateIsNotNextAdmin = nextSchedAdminDate !== NextRegentsAdminDate;
          return nextSchedAdminDateIsValid && nextSchedAdminDateIsNotNextAdmin;
        });
        return regentsExamSchedForRegentsCatFutureAdmin.length > 0;
      },
    );
    return regentsCatsNeededForOnTrackGradSchedFutureAdmin;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of regents categories that the student needs to be considered on
   * track for his or her diploma type AND that he or she is currently scheduled for IN FUTURE ADMIN
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin'],
  })
  getCountOfRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin (student) {
    const regentsCatsNeededForOnTrackDiplomaSchedFutureAdmin = this.getRegentsCatsNeededForOnTrackDiplomaSchedFutureAdmin(
      student,
    );
    return regentsCatsNeededForOnTrackDiplomaSchedFutureAdmin.length;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of regents categories that the student needs to be considered on
   * track for his or her diploma type AND that he or she is NOT currently scheduled
   */
  @depends({
    paths: [],
    methods: ['getRegentsCatsNeededForOnTrackDiplomaNotSched'],
  })
  getCountOfRegentsCatsNeededForOnTrackDiplomaNotSched (student) {
    const regentsCatsNeededForOnTrackDiplomaNotSched = this.getRegentsCatsNeededForOnTrackDiplomaNotSched(student);
    return regentsCatsNeededForOnTrackDiplomaNotSched.length;
  }

  /**
   * @param {Object} student
   * @param {String} exam
   * @return {Boolean}
   */
  @depends({
    paths: [
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.hasRegentsRecRationaleCollegeReadiness'),
      'nextScheduledRegents',
    ],
  })
  isRegentsRecRatCollegeReadinessMismatched (student, exam): boolean {
    const examDetails = _.pick(student.regentsDetails.byExam, exam);
    const studentIsRecommendedForExamForCr = _.get(examDetails[exam], 'hasRegentsRecRationaleCollegeReadiness');
    const studentIsNotPlannedForExamInNextAdmin = student.nextScheduledRegents[exam].adminDate !== NextRegentsAdminDate;
    const regentsRecRatCollegeReadinessMismatched =
      !!(studentIsRecommendedForExamForCr && studentIsNotPlannedForExamInNextAdmin);
    return regentsRecRatCollegeReadinessMismatched;
  }

  /**
   * @param {Object} student
   * @param {String} regentsExamKey
   * @param {String} plannedDiplomaType (optional)
   * @return {String} of statusRegents or statusLocal value for a regentsCat based
   * on regentsExam and diplomaType
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('isSafetyNetEligible'), 'gradPlanningDetails.plannedDiplomaType'],
    methods: ['getRegentsCatsBasedOnDiplomaType'],
  })
  getStatusForRegentsCat (student, regentsExamKey, plannedDiplomaType): TRegentsStatusDisplayValue {
    // NOTE: For a given exam, a D75 student (or other studentYear 0)
    // might fall into the regents exam status of 'Not Passed'
    // which in the regents exams panel is displayed as a front end value of NEEDED or NOT MET.
    // While technically true, this is misleading for D75 and other year 0 students
    // as they are not being measured against metrics until studentYear === 1;
    // Return a different display value that reflects their status (JYR)
    const studentYear = this.imStudent.getStudentYear(student);
    if (studentYear === 0) return RegentsStatusFormattedValue.NOT_NEEDED;

    const _plannedDiplomaType = _.find(PlannedDiplomaType, {
      humanName: plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType,
    });
    const diplomaType = _plannedDiplomaType.type;
    const regentsCat = this.getRegentsCatsBasedOnDiplomaType(student, plannedDiplomaType)[regentsExamKey];

    if (_.includes(['advanced', 'regents'], diplomaType)) {
      return regentsCat ? regentsCat.statusRegents : null;
    } else if (diplomaType === 'local') {
      return regentsCat ? regentsCat.statusLocal : null;
    } else if (diplomaType === 'grad') {
      if (this.imStudent.isSafetyNetEligible(student)) {
        return regentsCat ? regentsCat.statusLocal : null;
      }
      return regentsCat ? regentsCat.statusRegents : null;
    }
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {Number} count of next scheduled regents exams that are valid and sched for future admin
   */
  @depends({
    paths: [],
    methods: ['getRegentsSchedForFutureAdmin'],
  })
  getCountOfRegentsSchedForFutureAdmin (student) {
    const regentsSchedForFutureAdmin = this.getRegentsSchedForFutureAdmin(student);
    return regentsSchedForFutureAdmin.length;
  }

  /**
   * @param {Object} student
   * @param {String} target
   * @return {Array} exams where we recommend the student,
   * but the student is not planned for the exam in the next admin
   */
  @depends({
    paths: [
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.hasRegentsRecRationale<recRationaleTarget>'),
      'nextScheduledRegents',
    ],
  })
  getExamsRecommendedButNotPlannedByRecRationale (student, recRationaleSuffix) {
    const {
      nextScheduledRegents,
      regentsDetails: { byExam: regentsData },
    } = student;
    const path = `hasRegentsRecRationale${recRationaleSuffix}`;
    return _.reduce(
      nextScheduledRegents,
      (result, data, examName) => {
        const examDetails = _.pick(regentsData, examName);
        const hasRegentsRecRationaleCourse = examDetails[examName][path];
        const thisAdminDate = nextScheduledRegents[examName].adminDate;
        if (hasRegentsRecRationaleCourse && thisAdminDate !== NextRegentsAdminDate) {
          result.push(examName);
        }
        return result;
      },
      [],
    );
  }

  /**
   * given a regents exam, a score, and a plannedDiplomaType (optional),
   * it returns whether the score is college ready, passing, or not passing.
   * @param {string} regentsExam.key - a value in RegentsExam[CONSTANT].key
   * @param {string} score - a value in student.regentsDetails.byExam[regentsExam.key].transcript[index].mark.string
   * @param {string} [plannedDiplomaType=student.gradPlanningDetails.plannedDiplomaType] - a value
   * in PlannedDiplomaType[CONSTANT].humanName
   * @return {Object} { pass: {Boolean}, collegeReady: {Boolean}, plannedDiplomaType: {string}, score: {num} }
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('isSafetyNetEligible'), 'gradPlanningDetails.plannedDiplomaType'],
    methods: ['getRegentsKeyForNeededBasedOnDiplomaType'],
  })
  regentsExamPassedStatusBasedOnScoreAndDiplomaType (student, { regentsExamKey, score, plannedDiplomaType }) {
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);
    plannedDiplomaType = plannedDiplomaType || student.gradPlanningDetails.plannedDiplomaType;

    const status = {
      passed: null,
      collegeReady: null,
      isSafetyNetEligible,
      plannedDiplomaType,
      score,
    };

    const regentsExam = _.find(RegentsExam, { key: regentsExamKey });
    if (!regentsExam) {
      return status;
    }

    const keyForNeeded = this.getRegentsKeyForNeededBasedOnDiplomaType(student, plannedDiplomaType);
    const isAdvOrReg = _.includes(['advanced', 'regents'], keyForNeeded);
    const isLocal = _.includes(['local'], keyForNeeded);

    let scoresToPass;

    if (isAdvOrReg) {
      scoresToPass = regentsExam.scoresToPass.advAndRegents;
    } else if (isLocal) {
      scoresToPass = regentsExam.scoresToPass.local;
    } else {
      // for `Non-Graduate` or `No plan` diplomaTypes
      if (isSafetyNetEligible) {
        status.isSafetyNetEligible = true;
        scoresToPass = regentsExam.scoresToPass.local;
      } else {
        scoresToPass = regentsExam.scoresToPass.advAndRegents;
      }
    }

    if (scoresToPass) {
      status.collegeReady = false;
      status.passed = false;
      if (this.isExamAppealedBasedOnScore(score.string)) {
        status.passed = true;
      } else if (scoresToPass.collegeReady && score.num >= scoresToPass.collegeReady) {
        status.collegeReady = true;
        status.passed = true;
      } else if (score.num >= scoresToPass.pass) {
        status.passed = true;
      }
    }

    return status;
  }

  /**
   * @param {Object} student
   * @return {Number} count of examsSchedNextAdminNotInStars
   */
  @depends({
    paths: [],
    methods: ['getExamsSchedNextAdminNotInStars'],
  })
  getCountOfExamsSchedNextAdminNotInStars (student) {
    const examsSchedNextAdminNotInStars = this.getExamsSchedNextAdminNotInStars(student);
    return examsSchedNextAdminNotInStars.length;
  }

  // TODO: Needs test
  /**
   *
   * @param {Object} student
   * @return {Number} count of regentsDetails.byExam[exam].schedInStars === true;
   */
  @depends({
    paths: ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.schedInStars'),
    methods: [],
  })
  getRegentsExamsSchedInStarsNextAdmin (student) {
    const byExam = student.regentsDetails.byExam;
    const regentsExamsSchedInStarsNextAdmin = _.reduce(
      byExam,
      (result, exam: any, examKey) => {
        if (exam.schedInStars) {
          const examDetails = _.find(RegentsExam, { key: examKey });
          const examShortName = examDetails.shortName;
          result.push(examShortName);
        }
        return result;
      },
      [],
    );
    return regentsExamsSchedInStarsNextAdmin;
  }

  /**
   * @param {Object} student
   * @return {Array} examsSchedNextAdminNotInStars
   */
  @depends({
    paths: [...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.schedInStars'), 'nextScheduledRegents'],
  })
  getExamsSchedNextAdminNotInStars (student) {
    const {
      nextScheduledRegents,
      regentsDetails: { byExam: regentsData },
    } = student;
    return _.reduce(
      nextScheduledRegents,
      (result, v: any, examKey) => {
        const isNextAdmin = v.adminDate === NextRegentsAdminDate;
        if (isNextAdmin && !regentsData[examKey].schedInStars) {
          result.push(examKey);
        }
        return result;
      },
      [],
    );
  }

  /**
   * @param {Object} student
   * @return {Array} examsSchedNextAdminNotInStars
   */
  @depends({
    paths: [...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.schedInStars'), 'nextScheduledRegents'],
  })
  getExamsSchedNextAdminAndInStars (student) {
    const {
      nextScheduledRegents,
      regentsDetails: { byExam: regentsData },
    } = student;
    return _.reduce(
      nextScheduledRegents,
      (result, v: any, examKey) => {
        const isNextAdmin = v.adminDate === NextRegentsAdminDate;
        if (isNextAdmin && regentsData[examKey].schedInStars) {
          const { shortName } = _.find(RegentsExam, { key: examKey });
          result.push(shortName);
        }
        return result;
      },
      [],
    );
  }

  /**
   * @param {Object} student
   * @return {Array} array of Regents Exams where nextScheduledRegents.adminDate === 'DROP'
   *
   */
  @depends({
    paths: [...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.schedInStars'), 'nextScheduledRegents'],
  })
  getExamsToBeDroppedFromStars (student): IRegentsExam[] {
    const {
      nextScheduledRegents,
      regentsDetails: { byExam: regentsData },
    } = student;

    const examsToBeDroppedFromStars = _.reduce(
      nextScheduledRegents,
      (result, value: any, key: string) => {
        const examDetails = _.pick(regentsData, key);
        const adminDate = value.adminDate;
        if (examDetails[key].schedInStars && adminDate === 'DROP') {
          const regentsExam = _.find(RegentsExam, { key });
          result.push(regentsExam);
        }
        return result;
      },
      [],
    );

    return examsToBeDroppedFromStars;
  }

  /**
   * @param {Object} student
   * @return {Array} mismatchedExams
   */
  @depends({
    paths: [...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.schedInStars'), 'nextScheduledRegents'],
  })
  getRegentsExamsSchedInStarsNotPlannedInPortal (student) {
    const {
      nextScheduledRegents,
      regentsDetails: { byExam: regentsData },
    } = student;

    return _.reduce(
      nextScheduledRegents,
      (mismatchedExams, v: any, examKey) => {
        const examDetails = _.pick(regentsData, examKey);
        if (examDetails[examKey].schedInStars && v.adminDate !== NextRegentsAdminDate) {
          mismatchedExams.push(examKey);
        }
        return mismatchedExams;
      },
      [],
    );
  }

  /**
   * given a student and a planned diploma type it returns whether or
   * not to use regentsDetails.byCat[5or9] fulfilledRegents or fulfilledLocal
   * @param {Object} student
   * @param {Object} plannedDiplomaTypeDetails - an Object in regentsConstants.plannedDiplomaType
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('isSafetyNetEligible'), 'gradPlanningDetails.plannedDiplomaType'],
    methods: [],
  })
  getRegentsFulfilledKeyBasedOnDiplomaType (student, plannedDiplomaTypeDetails?) {
    const _plannedDiplomaTypeDetails =
      plannedDiplomaTypeDetails ||
      _.find(PlannedDiplomaType, {
        humanName: student.gradPlanningDetails.plannedDiplomaType,
      });
    const diplomaType = _plannedDiplomaTypeDetails.type;
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);

    if (diplomaType === 'local') return 'fulfilledLocal';
    if (diplomaType === 'regents' || diplomaType === 'advanced') return 'fulfilledRegents';
    if (isSafetyNetEligible) {
      return 'fulfilledLocal';
    } else {
      return 'fulfilledRegents';
    }
  }

  /**
   * given a student it returns whether or
   * not to use regentsDetails.byCat[5] fulfilledRegents or fulfilledLocal
   * @param {Object} student
   */
  @depends({
    paths: ImStudent.prototype.pathsFor('isSafetyNetEligible'),
    methods: [],
  })
  getRegentsFulfilledKeyForGrad (student) {
    const isSafetyNetEligible = this.imStudent.isSafetyNetEligible(student);
    if (isSafetyNetEligible) return 'fulfilledLocal';
    return 'fulfilledRegents';
  }

  // REGENTS PREP
  // REGENTS PREP
  // REGENTS PREP
  // REGENTS PREP
  // REGENTS PREP

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @param {Object} school
   * @param {Array} studentSupports
   * @return {Object} regents scheduled next admin with specific prep status
   */
  @depends({
    paths: [],
    methods: ['getRegentsPrepStatusesForAllExams', 'getRegentsSchedForNextAdmin'],
  })
  getRegentsSchedWithSpecificPrepStatus (student, school, studentSupports, prepStatus) {
    const regentsPrepStatusesForExams = this.getRegentsPrepStatusesForAllExams(student, school, studentSupports);
    const examKeysWithSpecificStatus = _.reduce(
      regentsPrepStatusesForExams,
      (result, examPrepStatus, examKey) => {
        if (examPrepStatus === prepStatus) result.push(examKey);
        return result;
      },
      [],
    );
    const examsWithSpecificStatus = _.filter(RegentsExam, exam => {
      return _.includes(examKeysWithSpecificStatus, exam.key);
    });
    return examsWithSpecificStatus;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @param {Object} school
   * @param {Array} studentSupports
   * @return {Number} count of regents scheduled next admin with specific prep status
   */
  @depends({
    paths: [],
    methods: ['getRegentsSchedWithSpecificPrepStatus'],
  })
  getCountOfRegentsSchedWithSpecificPrepStatus (student, school, studentSupports, prepStatus) {
    const regentExamsSchedWithPrepStatus = this.getRegentsSchedWithSpecificPrepStatus(
      student,
      school,
      studentSupports,
      prepStatus,
    );
    return regentExamsSchedWithPrepStatus.length;
  }

  /**
   * @param {Object}
   * @param {Object}
   * @return {Array}
   */
  @depends({
    paths: [],
    methods: ['getAlignedCoursesForAllRegentsExams'],
  })
  getAlignedCoursesForRegentsExam (student, school, examKey) {
    const allAlignedCourses = this.getAlignedCoursesForAllRegentsExams(student, school);
    const alignedCoursesForExam = _.filter(allAlignedCourses, { examKey });
    return alignedCoursesForExam || [];
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object}
   * @param {Object}
   * @return {Array}
   */
  @depends({
    paths: ImStudent.prototype.pathsFor('getCoursesForCurrentTermYear'),
    methods: [],
  })
  getAlignedCoursesForAllRegentsExams (student, school) {
    const allAlignedCourses = this.ImSchool.getAlignedCoursesForAllRegentsExamsGroupByCourseIdTerm(school);
    const studentProgram = this.imStudent.getCoursesForCurrentTermYear(student);

    const thisStudentsCurrentAlignedCourses = _.reduce(
      studentProgram,
      (result, course: any) => {
        const courseId = course.courseId;
        const alignedCourses = allAlignedCourses[courseId] || [];
        if (alignedCourses) {
          _.each(alignedCourses, (alignedCourse: any) => {
            const newCourse = this.UtilitiesService.copyPOJO(course);
            newCourse.examKey = alignedCourse.examKey;
            result.push(newCourse);
          });
        }
        return result;
      },
      [],
    );

    return thisStudentsCurrentAlignedCourses || [];
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @param {String} examKey
   * @return {String} prep status, one of 6 options
   */
  @depends({
    paths: [],
    methods: ['getRegentsPrepStatusesForAllExams'],
  })
  getRegentsPrepStatusForExam (student, school, studentSupports, examKey) {
    const regentsPrepStatuses = this.getRegentsPrepStatusesForAllExams(student, school, studentSupports);
    const regentsPrepStatusForExam = regentsPrepStatuses && regentsPrepStatuses[examKey];
    return regentsPrepStatusForExam;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @param {Object} school
   * @param {Array} studentSupports
   * @return {Object} prep status object { ccAlg: 'No Support', global: 'Additional Prep Only'  }
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('getCurrentlyActiveSupports'), ...ImStudent.prototype.pathsFor('isActive')],
    methods: ['getAlignedCoursesForAllRegentsExams', 'isRegentsExamScheduled'],
  })
  // @memoized({ exceptWhen: STUDENT_IS_MUTABLE }) // TODO (DS): Could's get this to work
  getRegentsPrepStatusesForAllExams (student, school, studentSupports) {
    const studentIsActive = this.imStudent.isActive(student);
    if (!studentIsActive) return;

    // get the students active supports that are regents preps
    const activeStudentSupports = this.imStudent.getCurrentlyActiveSupports(student, studentSupports, 'REGENTS_PREP');
    const activeStudentSupportsByExamSubject = _.reduce(
      activeStudentSupports,
      (result, studentSupport: any) => {
        const support = studentSupport.support;
        if (!support.metaData || !support.metaData.examSubject) {
          return result;
        }
        const examSubject = support.metaData.examSubject;
        result[examSubject] = result[examSubject] || [];
        result[examSubject].push(studentSupport);
        return result;
      },
      {},
    );

    // get the students supporting courses
    const alignedCourses = this.getAlignedCoursesForAllRegentsExams(student, school);
    const alignedCoursesByExam = _.reduce(
      alignedCourses,
      (result, course) => {
        const examKey = course.examKey;
        if (!examKey) {
          return result;
        }
        result[examKey] = result[examKey] || [];
        result[examKey].push(course);
        return result;
      },
      {},
    );

    // get the exams that are scheduled in the next admin - returns array of Regents Exam constants
    const regentsSchedNextAdmin = this.getRegentsSchedForNextAdmin(student);

    // loop through each scheduled regents exam and assess prep status
    const regentsPrepStatusesForExams = _.reduce(
      regentsSchedNextAdmin,
      (result, exam) => {
        const examKey = exam.key;

        const examSubject = exam.subject.key;
        const activeStudentSupportsForExam = activeStudentSupportsByExamSubject[examSubject] || [];
        const currentlyAlignedCoursesForExam = alignedCoursesByExam[examKey] || [];
        const hasSupport = activeStudentSupportsForExam.length > 0;
        const hasCourse = currentlyAlignedCoursesForExam.length > 0;

        const bothSupportAndCourse = hasSupport && hasCourse;
        if (bothSupportAndCourse) {
          result[examKey] = RegentsExamPrepStatuses.COURSE_ADD_PREP;
          return result;
        }

        const noSupportOrCourse = !hasSupport && !hasCourse;
        if (noSupportOrCourse) {
          result[examKey] = RegentsExamPrepStatuses.NO_PREP;
          return result;
        }

        const onlySupport = hasSupport && !hasCourse;
        if (onlySupport) {
          result[examKey] = RegentsExamPrepStatuses.ADD_PREP;
          return result;
        }

        // loop through aligned courses -- there could be more than one aligned course
        // see if any of the alinged courses has a status of failing or borderline
        // if so, include the student in the 'Only Course - Failing/Borderline' group
        const courseStatuses = _.map(currentlyAlignedCoursesForExam, (course: ICurrProgramCourse) => {
          const currentCourseMark = ImStudentCurrentProgramHelpers.getCurrentCourseMark(course.marks);
          const gradeMarkStatus = ImStudentCurrentProgramHelpers.getGradeStatus(currentCourseMark);
          return gradeMarkStatus;
        });
        const includesFailing = _.includes(courseStatuses, 'Failing');
        const includesBorderline = _.includes(courseStatuses, 'Borderline');
        const overallStatusFailingBorderline = includesFailing || includesBorderline;

        const onlyCourseFailingBorderline = !hasSupport && hasCourse && overallStatusFailingBorderline;
        if (onlyCourseFailingBorderline) {
          result[examKey] = RegentsExamPrepStatuses.COURSE_FAILING;
          return result;
        }

        const onlyCoursePassing = !hasSupport && hasCourse && !overallStatusFailingBorderline;
        if (onlyCoursePassing) {
          result[examKey] = RegentsExamPrepStatuses.COURSE_PASSING;
          return result;
        }
      },
      {},
    );

    return regentsPrepStatusesForExams;
  }

  // DANIELLE TODO WRITE TESTS
  /**
   * @param {Object} student
   * @return {String} priority group
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isActive'),
      ...ImStudent.prototype.pathsFor('getStudentYear'),
      ...ImStudent.prototype.pathsFor('getCurrentGradPlan'),
      ...ImStudent.prototype.pathsFor('isPlannedToGraduateThisSchoolYear'),
      'isHS',
    ],
    methods: ['getCountOfRegentsSchedWithSpecificPrepStatus'],
  })
  getRegentsPrepPriorityGrouping (student, school, studentSupports) {
    const studentYear = this.imStudent.getStudentYear(student);
    const studentIsActive = this.imStudent.isActive(student);
    const isHS = student.isHS;
    if (!isHS) return 'n/a'; // middle school students don't get a priority
    if (!studentIsActive) return 'n/a'; // in active students don't get a priority

    // get unaddressed regents needs
    const countOfRegentsCatsNeededForOnTrackDiplomaNotSched = this.getCountOfRegentsSchedWithSpecificPrepStatus(
      student,
      school,
      studentSupports,
      RegentsExamPrepStatuses.NO_PREP,
    );
    const hasExamsPlannedWithNoPrep = countOfRegentsCatsNeededForOnTrackDiplomaNotSched > 0;

    // we only care about students with planned exams with no prep!
    if (!hasExamsPlannedWithNoPrep) {
      return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('NONE');
    }

    // seniors and older
    if (studentYear >= 4) {
      // get student's grad plan
      const gradPlan = this.imStudent.getCurrentGradPlan(student);

      const nonGrad = gradPlan === GraduationPlan.NON_GRADUATE.humanName;
      if (nonGrad) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_NON_GRAD'); }

      const gradPlanIsIncomplete = gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
      const gradPlanIsInPast = gradPlan === GraduationPlan.PLAN_IN_PAST.humanName;
      if (gradPlanIsIncomplete || gradPlanIsInPast) {
        return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLAN_INCOMPLETE');
      }

      // get student's planned graduation date
      const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;
      const plannedGraduationDateDetails = _.find(GraduationDate, { humanName: plannedGraduationDate });
      const plannedGraduationMonthOrder = plannedGraduationDateDetails.monthOrder;

      // see if the student is planned to grad this year
      const plannedToGradThisYear = this.imStudent.isPlannedToGraduateThisSchoolYear(student);

      if (plannedToGradThisYear) {
        if (plannedGraduationMonthOrder === 1 || plannedGraduationMonthOrder === 2) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_JAN_MARCH'); }
        if (plannedGraduationMonthOrder === 3) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_JUNE'); }
        if (plannedGraduationMonthOrder === 4) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_AUG'); }
      } else {
        return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_BEYOND_AUG');
      }
    }

    // younger cohorts
    if (studentYear === 3) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('JUNIORS'); }
    if (studentYear === 2) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('SOPHOMORES'); }
    if (studentYear === 1) { return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('FRESHMAN'); }
  }

  /**
   * @param {Object} student
   * @return {String} priority group
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('isActive'),
      ...ImStudent.prototype.pathsFor('getCurrentGradPlanTransfer'),
      'isHS',
    ],
    methods: ['getCountOfRegentsSchedWithSpecificPrepStatus'],
  })
  getRegentsPrepPriorityGroupingTransfer (student, school, studentSupports) {
    const studentIsActive = this.imStudent.isActive(student);
    const isHS = student.isHS;
    if (!isHS) return 'n/a'; // middle school students don't get a priority
    if (!studentIsActive) return 'n/a'; // in active students don't get a priority

    const countOfRegentsCatsNeededForOnTrackDiplomaNotSched = this.getCountOfRegentsSchedWithSpecificPrepStatus(
      student,
      school,
      studentSupports,
      RegentsExamPrepStatuses.NO_PREP,
    );

    const hasExamsPlannedWithNoPrep = countOfRegentsCatsNeededForOnTrackDiplomaNotSched > 0;

    // we only care about students with planned exams with no prep!
    if (!hasExamsPlannedWithNoPrep) {
      return this.RegentsPrepPriorityGroupingsService.getStudentValueForPriorityGroupByKey('NONE');
    }

    const gradPlan = this.imStudent.getCurrentGradPlanTransfer(student);
    const nonGrad: boolean = gradPlan === GraduationPlanTransfer.NON_GRADUATE.humanName;

    if (nonGrad) { return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLANNED_NON_GRAD'); }

    const gradPlanIsIncomplete: boolean = gradPlan === GraduationPlanTransfer.PLAN_INCOMPLETE.humanName;
    const gradPlanIsInPast: boolean = gradPlan === GraduationPlanTransfer.PLAN_IN_PAST.humanName;
    if (gradPlanIsIncomplete || gradPlanIsInPast) {
      return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLAN_INCOMPLETE');
    }

    const gradDate = _.find(GraduationPlanTransfer, { humanName: gradPlan }).date;
    const next4GradDates = this.ImSchool.getNextFourGradDatesForTransfer();
    const next4GradDatesArray = [
      next4GradDates[0].humanName,
      next4GradDates[1].humanName,
      next4GradDates[2].humanName,
      next4GradDates[3].humanName,
    ];
    const index = next4GradDatesArray.indexOf(gradDate as TValidGradDates) + 1;

    if (index === 1) { return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('ONE_TERM_AWAY'); }
    if (index === 2) { return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('TWO_TERMS_AWAY'); }
    if (index === 3) { return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('THREE_TERMS_AWAY'); }
    if (index === 4) { return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('FOUR_TERMS_AWAY'); }

    return this.RegentsPrepPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLANNED_BEYOND_4');
  }

  /**
   * @param {Object} IStudent
   * @return {Array} regents categories
   */
  @depends({
    paths: [...ImStudent.prototype.pathsFor('isPlannedDiplomaTypeByCat5OrByCat9')],
    methods: ['getFulfilledRegentsCatsForDiplomaType', 'getRegentsCatsNeededForOnTrackDiploma'],
  })
  getRegentsCatsNotYetNeededForDiploma (student: IStudent) {
    const studentDiplomaCat = this.imStudent.isPlannedDiplomaTypeByCat5OrByCat9(student);
    const allRegentsCatsForStudent =
      studentDiplomaCat === '5' ? RegentsCategoryByCategory5.required : RegentsCategoryByCategory9.required;
    const fulfilledCats = this.getFulfilledRegentsCatsForDiplomaType(student);
    const neededCats = this.getRegentsCatsNeededForOnTrackDiploma(student);
    const neededAndFulfilled = [...fulfilledCats, ...neededCats];
    return _.difference(allRegentsCatsForStudent, neededAndFulfilled);
  }

  /**
   * @param {Object} IStudent
   * @return {Array} regents categories
   */
  @depends({
    paths: [],
    methods: ['getFulfilledRegentsCatsForGrad', 'getRegentsCatsNeededForOnTrackGrad'],
  })
  getRegentsCatsNotYetNeededForGrad (student: IStudent) {
    const fulfilledCats = this.getFulfilledRegentsCatsForGrad(student);
    const neededCats = this.getRegentsCatsNeededForOnTrackGrad(student);
    const neededAndFulfilled = [...fulfilledCats, ...neededCats];
    return _.difference(RegentsCategoryByCategory5.required, neededAndFulfilled);
  }

  /**
   * @param {Object} IStudent
   * @param {String} examKey
   * @return {Boolean}
   */
  @depends({
    paths: [
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.fulfilledRegents'),
      ...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.fulfilledLocal'),
    ],
    methods: ['getRegentsFulfilledKeyBasedOnDiplomaType'],
  })
  isExamFulfilledForDiplomaType (student: IStudent, examKey: string): boolean {
    const {
      regentsDetails: { byExam: detailsByExam },
    } = student;
    const keyForFulfilled = this.getRegentsFulfilledKeyBasedOnDiplomaType(student);
    const fullfilledStatus = detailsByExam[examKey][keyForFulfilled];
    const examIsFulfilled = fullfilledStatus !== false;
    return examIsFulfilled;
  }

  /**
   * @param {Object} IStudent
   * @return {Array} IRegentsExam[]
   */
  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor('getEffectiveStudentYear'),
      ...ImStudent.prototype.pathsFor('getEffectiveCohort'),
    ],
    methods: ['isExamFulfilledForDiplomaType'],
  })
  getPastDueExamsForDiplomaType (student: IStudent, school: ISchool): IRegentsExam[] {
    const effectiveStudentYear = this.imStudent.getEffectiveStudentYear(student);
    const lastSyStudentYear = effectiveStudentYear - 1;

    // Empty array for freshman OR students without classOf value
    if (lastSyStudentYear < 1) return [];
    const effectiveCohort = this.imStudent.getEffectiveCohort(student, lastSyStudentYear);
    const regentsMetricsForStudent = this.ImSchool.getRegentsMetricForEffectiveCohortAndStudent(
      school,
      effectiveCohort,
      lastSyStudentYear,
    );
    const pastDueExams = _.reduce(
      RegentsExam,
      (result, exam: IRegentsExam) => {
        const examKey = exam.key;
        const examIsOffered = exam.isOffered;
        const examSubject = exam.subject.key;
        const examSubjectShouldHaveBeenPassedLastYearBasedOnMetric = regentsMetricsForStudent[examSubject];
        const examIsFulfilled = this.isExamFulfilledForDiplomaType(student, examKey);
        const examIsPastDue = !examIsFulfilled && examIsOffered && examSubjectShouldHaveBeenPassedLastYearBasedOnMetric;
        if (examIsPastDue) result.push(exam);
        return result;
      },
      [],
    );
    return pastDueExams;
  }

  /**
   * @param {Object} IStudent
   * @return {Array} IRegentsCategory[]
   */
  @depends({
    paths: ['regentsDetails.needed.onTrackPastDue'],
    methods: ['getRegentsKeyForNeededBasedOnDiplomaType'],
  })
  getPastDueRegentsCatsForDiplomaType (student): IRegentsCategory[] {
    const {
      regentsDetails: {
        needed: { onTrackPastDue },
      },
    } = student;
    const keyForNeeded = this.getRegentsKeyForNeededBasedOnDiplomaType(student);
    const neededOnTrackPastDueValues = onTrackPastDue[keyForNeeded];
    const neededOnTrackPastDueRegentsCats = _.map(neededOnTrackPastDueValues, (neededOnTrackValue: string) => {
      return _.find(RegentsCategory, { neededOnTrackValue });
    });
    return neededOnTrackPastDueRegentsCats;
  }

  /**
   * @param {Object} IStudent
   * @return {Array} IRegentsExam[]
   */
  @depends({
    paths: [],
    methods: ['getPastDueRegentsCatsForDiplomaType', 'getPastDueExamsForDiplomaType'],
  })
  // If too slow, refactor with new constant to limit all the looping
  getPastDueExamsInPastDueRegentsCatsForDiplomaType (student: IStudent, school: ISchool): IRegentsExam[] {
    let uniquePastDueExams;
    const pastDueRegentsCategories = this.getPastDueRegentsCatsForDiplomaType(student);
    const pastDueExams = this.getPastDueExamsForDiplomaType(student, school);
    const pastExamsForPastCats: IRegentsExam[] = _.reduce(
      pastDueRegentsCategories,
      (result, category: IRegentsCategory) => {
        const categoryExams = category.exams;
        const intersectionArr = _.intersection(categoryExams, pastDueExams);
        result.push(...intersectionArr);
        return result;
      },
      [],
    );
    uniquePastDueExams = Array.from(new Set(pastExamsForPastCats));
    return uniquePastDueExams;
  }

  /**
   * @param {Object} IStudent
   * @return {Array} IRegentsExam[]
   */
  @depends({
    paths: [],
    methods: ['getPastDueExamsInPastDueRegentsCatsForDiplomaType', 'isRegentsExamScheduled'],
  })
  // If too slow, refactor with new constant to limit all the looping
  getPastDueExamsInPastDueRegentsCatsForDiplomaTypeNotPlanned (student: IStudent, school: ISchool): IRegentsExam[] {
    const pastDueExamsInPastDueRegentsCatsForDiplomaType = this.getPastDueExamsInPastDueRegentsCatsForDiplomaType(
      student,
      school,
    );
    return _.reduce(
      pastDueExamsInPastDueRegentsCatsForDiplomaType,
      (result, exam: IRegentsExam) => {
        const examKey = exam.key;
        const isPlanned = !this.isRegentsExamScheduled(student, examKey);
        if (isPlanned) result.push(exam);
        return result;
      },
      [],
    );
  }

  getExamTranscriptForRegentsSubject (student: IStudent, regentsSubjectKey) {
    const { exams } = _.find(RegentsSubject, { key: regentsSubjectKey });
    const { byExam } = student.regentsDetails;

    const transcriptForSubject: ITranscriptForRegentsExam[] = _.reduce(
      exams,
      (acc, e) => {
        const { key } = e;
        const examDetails: IRegentsExamDetails = byExam[key];
        const { exam, transcript } = examDetails;

        _.each(transcript, t => {
          const { month, year } = t;
          const { string, num } = t.mark;
          const transcriptForExam: ITranscriptForRegentsExam = { exam, examKey: key, month, year, string, num };

          acc.push(transcriptForExam);
        });

        return acc;
      },
      [],
    );

    return transcriptForSubject;
  }

  /**
   * @param {Object} IStudent
   * @return {Array} IRegentsExam[]
   */
  @depends({
    paths: [...ImModelsHelpers.expandPath('regentsDetails.byExam.<examKey>.attempts')],
    methods: ['getRegentsSchedForNextAdmin', 'getRegentsFulfilledKeyBasedOnDiplomaType'],
  })
  getExamsPlannedInNextAdminAttemptedThreeOrMoreTimes (student: IStudent): IRegentsExam[] {
    const fulfilledKey = this.getRegentsFulfilledKeyBasedOnDiplomaType(student);
    const examsPlannedInNextAdmin = this.getRegentsSchedForNextAdmin(student);
    const examsPlannedInNextAdminAttemptedAndFailedThreeOrMoreTimes = _.filter(
      examsPlannedInNextAdmin,
      (exam: IRegentsExam) => {
        const examKey = exam.key;
        const examData = student.regentsDetails.byExam[examKey];
        const attempts = examData.attempts;
        const fulfilled = examData[fulfilledKey];
        return attempts >= 3 && !fulfilled;
      },
    );
    return examsPlannedInNextAdminAttemptedAndFailedThreeOrMoreTimes;
  }

  /**
   * @param {Object} IStudent
   * @return {Number} number
   */
  @depends({
    paths: [],
    methods: ['getExamsPlannedInNextAdminAttemptedThreeOrMoreTimes'],
  })
  getCountOfExamsPlannedInNextAdminAttemptedThreeOrMoreTimes (student: IStudent): number {
    const examsPlannedInNextAdminAttemptedThreeOrMoreTimes = this.getExamsPlannedInNextAdminAttemptedThreeOrMoreTimes(
      student,
    );
    return examsPlannedInNextAdminAttemptedThreeOrMoreTimes.length;
  }

  isExamAppealedBasedOnScore(score: string | number){
    return score === RegentsScoreStringValue.SA || score === RegentsScoreStringValue.WG;
  }
}
