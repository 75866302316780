import { Component, HostBinding, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { IToastInputs } from './nv-toast.component.interface';

/**
 *
 * Use a Toast to inform the user of an action that has been performed 'behind-the-scenes'.
 *
 */
@Component({
  selector: 'nv-toast',
  templateUrl: './nv-toast.component.html',
  styleUrls: ['./nv-toast.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvToastComponent {
  @HostBinding('class') classString = '';
  // default maxWidth is 344px. We might need change the maxWidth in some instances to properly fit more text in the toast
  @HostBinding('style.maxWidth') maxWidth = '';

  public iconName = 'exclamation-red';

  constructor (
    public snackBarRef: MatSnackBarRef<NvToastComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: IToastInputs,
  ) {}

  ngOnInit () {
    if (this.data.isDanger) {
      this.classString = 'danger';
    }
    if(this.data.maxWidth) {
      this.maxWidth = this.data.maxWidth;
    }
  }
}
