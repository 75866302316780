import { IUser } from '../../shared/typings/interfaces/user.interface';
import { IListSummaryEvent, IListSummaryGrouping } from './../../shared/components/list-summary/list-summary.component';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, SubscriptionLike as ISubscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { CsvExporterService } from 'Src/ng2/shared/services/csv-exporter/csv-exporter.service';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { getCsvDataState, getSchool } from 'Src/ng2/store';
import { ICsvDataState } from '../../store/reducers/csv-data-reducer';
import { unsubscribeComponent } from '../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ModalsService } from '../../shared/modals/modals.service';
import { ImUser } from '../../shared/services/im-models/im-user';
import { ToggleBatchActions } from '../../store/actions/batch-actions-actions';
import { getBatchActionsMode, getBatchActionsSelectedStudentIds } from '../../store/selectors/batch-actions-selectors';
import { IInfoPanel } from './../../shared/components/info-panel/info-panel.component';
import { getCurrentUser } from './../../store/selectors/current-user-selectors';
import { PartnerTypes, TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';
import { TBatchActionsOrigin } from 'Src/ng2/shared/components/batch-actions-menu/batch-actions-menu.component';

export interface IListData {
  listType: string;
  groupings: any;
  madlibSelections: { focus: any; filter: any; grouping: any };
  columns: any[];
  rowType?: string;
  schoolName?: string;
  fileName?: string;
}

export interface IAdditionalSideBarTool {
  label: string;
  subLabel?: string;
  icon: string;
  onClick: Function;
  isActive?: boolean;
}

@Component({
  selector: 'content-tools',
  templateUrl: './content-tools.component.html',
  styleUrls: ['./content-tools.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ContentToolsComponent implements OnInit {
  @Input() isRefactoredList: boolean;
  @Input() isGridCsv: boolean = false;
  @Input() listData: IListData;
  @Input() listSummaryGroupData: IListSummaryGrouping[] = [];
  @Input() infoPanel?: IInfoPanel;
  @Input() additionalTools?: IAdditionalSideBarTool[] = [];
  @Input() batchActionsMode$;
  @Input() hideTools?: boolean;
  @Input() hasBatchActions: boolean = true;
  @Input() hasListActions: boolean = true;
  @Input() batchActionsToShow: string[];
  @Input() contextPartnerType: TValidPartnerTypes = PartnerTypes.SCHOOL;
  @Input() shelterId: string;
  @Input() origin: TBatchActionsOrigin;
  @Input() hasListSummary: boolean = true;

  @Output() selectOption: EventEmitter<IListSummaryEvent> = new EventEmitter<IListSummaryEvent>();

  public students = [];
  public countStudents: number;
  public userCanEdit: boolean;
  public school: ISchool;
  public isListActionsState: boolean;
  public isBatchActionsState: boolean;
  public batchActionsMode: boolean;
  public currentUserSubscription: ISubscription;
  public batchActionsModeSubscription: ISubscription;
  public batchActionSelectedStudentIdsSubscription: ISubscription;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public csvStoreDataSubscription: ISubscription;
  public csvStoreData: ICsvDataState;
  public currentUser: IUser;

  constructor (
    private modalsService: ModalsService,
    private store: Store<any>,
    private imUser: ImUser,
    private csvExporterService: CsvExporterService,
  ) {
    //
  }

  ngOnInit (): void {
    const schoolSlice = this.store.select(getSchool);
    schoolSlice.pipe(take(1)).subscribe((school: ISchool) => {
      this.school = school;
    });

    const currentUserSlice$ = this.store.select(getCurrentUser);
    this.currentUserSubscription = currentUserSlice$.pipe(take(1)).subscribe((user: IUser) => {
      const isEditingUser = this.imUser.isEditingUser(user, { partnerType: this.contextPartnerType });
      this.userCanEdit = isEditingUser;
      this.currentUser = user;
    });

    const batchActionsModeSlice$ = this.batchActionsMode$ || this.store.select(getBatchActionsMode);
    this.batchActionsModeSubscription = batchActionsModeSlice$
      .pipe(takeUntil(this.destroy$))
      .subscribe((mode: boolean) => {
        this.batchActionsMode = mode;
      });

    const selectedStudentIdsSlice$ = this.store.select(getBatchActionsSelectedStudentIds);
    this.batchActionSelectedStudentIdsSubscription = selectedStudentIdsSlice$
      .pipe(takeUntil(this.destroy$))
      .subscribe((studentIds: string[]) => {
        this.countStudents = studentIds.length;
      });

    const csvStoreData$ = this.store.select(getCsvDataState);
    this.csvStoreDataSubscription = csvStoreData$
      .pipe(takeUntil(this.destroy$))
      .subscribe((csvStoreData: ICsvDataState) => {
        this.csvStoreData = csvStoreData;
      });
  }

  enableBatchActionsMode (): void {
    this.batchActionsMode = true;
    this.store.dispatch(new ToggleBatchActions(this.batchActionsMode));
  }

  exportMadLibCsv () {
    this.csvExporterService.exportListToCsv(this.csvStoreData);
  }

  // called when content-tools is passed a refactored list flag
  exportList (): void {
    this.csvExporterService.exportRefactoredListToCsv(this.listData);
  }

  exportGridCsv (): void {
    const { columns, groupings: { rowData }, fileName } = this.listData;
    this.csvExporterService.exportGridToCsv(columns, rowData, fileName);
  }

  exportParterCampusListCsv (): void {
    this.csvExporterService.exportCollegeNowPartnerCampusListToCsv(this.listData);
  }

  export (): void {
    this.modalsService.openAlertModal({
      title: 'Coming soon!',
      message: '',
    });
  }

  onFilterChange ({ filterKey, title }: IListSummaryEvent): void {
    const index = this.listSummaryGroupData.findIndex(({ title: t }) => t === title);
    this.selectOption.emit({ filterKey, index });
  }

  onExportCsvClick (): void {
    if (this.isRefactoredList) {
      this.exportList();
    } else if (this.isGridCsv) {
      this.exportGridCsv();
    } else if (this.listData.listType === 'PartnerCampus') {
      this.exportParterCampusListCsv();
    } else {
      this.exportMadLibCsv();
    }
  }
}
