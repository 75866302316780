import { IMilestone } from '../typings/interfaces/milestones.interface';
import { IEditableRecord } from './../services/batch-edit-service/batch-edit-service';
import { SorterColumnDataType } from './sorter-column-data-type.constant';

export interface IMilestoneEditable extends IEditableRecord {
  tooltip: string;
  collegeOrder: number;
  careerOrder: number;
  dueBy: string;
  flagType: string;
  isCalculated: boolean;
}

const milestoneStatusOptions = ['Complete', 'Incomplete'];

// if key has a non-null value for collegeOrder/careerOrder
// the milestone exists in that track (JE)
export const MilestonesKeyMap = {
  PROFESSIONAL_EMAIL: {
    humanName: 'Professional email',
    tooltip: 'Student has created an email account that follows a standard format (ie, firstname@domain.org).',
    collegeOrder: 2,
    careerOrder: 4,
    dueBy: 'End of Soph',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  COLLEGE_FAIR: {
    humanName: 'College fair',
    tooltip: 'Student has attended a college fair.',
    collegeOrder: 3,
    careerOrder: null,
    dueBy: 'End of Junior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  FINALIZED_PERSONAL_STATEMENT: {
    humanName: 'Finalized personal statement',
    tooltip: 'Student has a finalized personal statement.',
    collegeOrder: 4,
    careerOrder: null,
    dueBy: 'Oct 1 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  FINALIZED_LETTER_OF_REC: {
    humanName: 'Letters of recommendation',
    tooltip: 'Student has two finalized letters of recommedations.',
    collegeOrder: 5,
    careerOrder: null,
    dueBy: 'Oct 1 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  CREATED_POSTSECONDARY_LIST: {
    humanName: 'Created postsecondary list',
    tooltip: 'Student has documented a list of a minimum of 2 colleges (and career pathways if applicable) where they plan to apply.',
    collegeOrder: 6,
    careerOrder: null,
    dueBy: 'Oct 18 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  OPP_PROGRAM_ELL_CONFIRM: {
    humanName: 'Opportunity program financial eligibility determination',
    tooltip: 'Parent income information has been reviewed and EOP eligibility has been confirmed in the Portal.',
    collegeOrder: 7,
    careerOrder: null,
    dueBy: 'Oct 18 of Senior',
    flagType: 'hard',
    isCalculated: true,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
  } as IMilestoneEditable,
  CUNY_APP_SUBMISSION: {
    humanName: 'CUNY application submission',
    tooltip: 'Student has submitted the CUNY application.',
    collegeOrder: 8,
    careerOrder: null,
    dueBy: 'Nov 21 of Senior',
    flagType: 'hard',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  COUNSELING_FINAL_DECISION: {
    humanName: 'Final decision conversation',
    tooltip: 'Student and counselor have met to make a final postsecondary decision.',
    collegeOrder: 11,
    careerOrder: 12,
    dueBy: 'May 1 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  FINAL_DECISION: {
    humanName: 'Final decision',
    tooltip: 'Counselor has entered the student\'s final postsecondary decision in the Portal.',
    collegeOrder: 12,
    careerOrder: 13,
    dueBy: 'May 1 of Senior',
    flagType: 'hard',
    isCalculated: true,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
  } as IMilestoneEditable,
  COUNSELING_TRANSITION: {
    humanName: 'Transition plan',
    tooltip:
      'Counselor has met with student (and caregiver) to discuss the student’s next steps ' +
      'for a successful postsecondary transition. A transition plan has been documented.',
    collegeOrder: 13,
    careerOrder: 14,
    dueBy: 'June 10 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  PICTURE_ID: {
    humanName: 'Valid picture ID',
    tooltip: 'Student has a valid picture ID.',
    collegeOrder: null,
    careerOrder: 3,
    dueBy: 'End of Soph',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  WORKING_PAPERS: {
    humanName: 'Working papers',
    tooltip: 'Any student under 18 (minors) must have an employment certificate before they may begin work.',
    collegeOrder: null,
    careerOrder: 5,
    dueBy: 'End of Soph',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  FINALIZED_RESUME: {
    humanName: 'Resume',
    tooltip: 'Student has an updated and accurate resume.',
    collegeOrder: null,
    careerOrder: 9,
    dueBy: 'Oct 1 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  ANNUAL_CAREER_INTEREST_SURVEY: {
    humanName: 'Career interest survey',
    tooltip:
      'Student has explored and taken inventory of their interests and related careers through completing a Career Interest Survey.',
    collegeOrder: null,
    careerOrder: 2,
    dueBy: 'End of May',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  ANNUAL_POSTSECONDARY_PLAN: {
    humanName: 'Annual postsecondary plan',
    tooltip:
      'Student has documented a postsecondary plan that includes their accomplishments, interests, and goals. This could also be a CDOS Career Plan which is completed annually.',
    collegeOrder: 1,
    careerOrder: 1,
    dueBy: 'End of May',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  APPLIED_FOR_SUMMER_JOB: {
    humanName: 'Applied for job',
    tooltip: 'Student has submitted an application for SYEP, a job, or other career development or Work Based Learning experience.',
    collegeOrder: null,
    careerOrder: 6,
    dueBy: 'End of Junior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  COMPLETED_SUMMER_JOB: {
    humanName: 'Professional work experience',
    tooltip:
      'Student has participated in SYEP or other work-based learning program that provides work experience, or has a part-time job.',
    collegeOrder: null,
    careerOrder: 7,
    dueBy: 'Oct 1 of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  COVER_LETTER: {
    humanName: 'Cover letter',
    tooltip: 'Student has drafted a well written letter introducing themselves to prospective employers.',
    collegeOrder: null,
    careerOrder: 8,
    dueBy: 'End of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  LINKEDIN_PROFILE: {
    humanName: 'LinkedIn profile',
    tooltip: 'Student has created a LinkedIn profile that contains the student\'s professional contact information and details on their work experience.',
    collegeOrder: null,
    careerOrder: 10,
    dueBy: 'End of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
  EMPLOYABILITY_SKILLS_PROFILE: {
    humanName: 'Employability skills profile',
    tooltip: 'Staff have worked with student\'s CDOS aligned WBL supervisor to complete required employability skills profile.',
    collegeOrder: null,
    careerOrder: 11,
    dueBy: 'End of Senior',
    flagType: 'soft',
    isCalculated: false,
    editable: true,
    dataType: SorterColumnDataType.RADIO,
    dataTypeOptions: {
      subType: 'MILESTONE',
      values: milestoneStatusOptions,
    },
    category: 'MILESTONES, POSTSECONDARY',
  } as IMilestoneEditable,
};

export const MilestonesHumanMap = {
  'Professional email': 1,
  'College fair': 1,
  'Finalized personal statement': 1,
  'Letters of recommendation': 1,
  'Created postsecondary list': 1,
  'Opportunity program financial eligibility determination': 1,
  'FAFSA application processed': 1,
  'TAP application processed': 1,
  'Final decision conversation': 1,
  'Final decision': 1,
  'Valid picture ID': 1,
  'Working papers': 1,
  Resume: 1,
  'Career interest survey': 1,
  'Annual postsecondary plan': 1,
  'Applied for job': 1,
  'Professional work experience': 1,
  'Cover letter': 1,
  'LinkedIn profile': 1,
  'Employability skills profile': 1,
};

export const careerMilestones = {
  ANNUAL_CAREER_INTEREST_SURVEY: 'annualCareerInterestSurvey',
  ANNUAL_POSTSECONDARY_PLAN: 'annualPostsecondaryPlan',
  PICTURE_ID: 'pictureId',
  PROFESSIONAL_EMAIL: 'professionalEmail',
  WORKING_PAPERS: 'workingPapers',
  APPLIED_FOR_SUMMER_JOB: 'appliedForSummerJob',
  COMPLETED_SUMMER_JOB: 'completedSummerJob',
  COVER_LETTER: 'coverLetter',
  FINALIZED_RESUME: 'finalizedResume',
  LINKEDIN_PROFILE: 'linkedInProfile',
  EMPLOYABILITY_SKILLS_PROFILE: 'employabilitySkillsProfile',
  COUNSELING_FINAL_DECISION: 'counselingFinalDecision',
  FINAL_DECISION: 'finalDecision',
  COUNSELING_TRANSITION: 'counselingTransition',
};

export const collegeMilestones = {
  ANNUAL_POSTSECONDARY_PLAN: 'annualPostsecondaryPlan',
  CREATED_POSTSECONDARY_LIST: 'createdPostsecondaryList',
  PROFESSIONAL_EMAIL: 'professionalEmail',
  COLLEGE_FAIR: 'collegeFair',
  FINALIZED_PERSONAL_STATEMENT: 'finalizedPersonalStatement',
  FINALIZED_LETTER_OF_REC: 'finalizedLetterOfRec',
  OPP_PROGRAM_ELL_CONFIRM: 'oppProgramEllConfirm',
  CUNY_APP_SUBMISSION: 'cunyAppSubmission',
  FAFSA_APP_SUBMISSION: 'fafsaAppSubmission',
  TAP_APP_SUBMISSION: 'tapAppSubmission',
  COUNSELING_FINAL_DECISION: 'counselingFinalDecision',
  FINAL_DECISION: 'finalDecision',
  COUNSELING_TRANSITION: 'counselingTransition',
};

export interface IMilestoneDataObj {
  milestone: {
    data: string;
    dependencies: {
      checked: boolean;
      isCalculated: boolean;
      docId: string;
      disabled: boolean;
    };
    collegeOrder: number;
    careerOrder: number;
    tooltipMsg: string;
    tooltipClass: string;
  };
  status: {
    data: IMilestone;
    dependencies: {
      dueAt: IMilestone['dueAt'];
    };
  };
  dueBy: {
    data: string; // '-' or dueBy value
    dependencies: {
      style: string; // css classes separated by space
    };
  };
}

export const PostsecondaryExplorationMilestones = {
  headerName: 'Postsecondary exploration',
  keys: [
    'ANNUAL_CAREER_INTEREST_SURVEY',
    'COLLEGE_FAIR',
    'FINALIZED_RESUME',
    'PICTURE_ID',
    'ANNUAL_POSTSECONDARY_PLAN',
    'WORKING_PAPERS',
    'PROFESSIONAL_EMAIL',
    'APPLIED_FOR_SUMMER_JOB',
  ],
};

export const PostsecondaryPreparationMilestones = {
  headerName: 'Postsecondary preparation',
  keys: [
    'ANNUAL_CAREER_INTEREST_SURVEY',
    'OPP_PROGRAM_ELL_CONFIRM',
    'FINALIZED_RESUME',
    'CREATED_POSTSECONDARY_LIST',
    'ANNUAL_POSTSECONDARY_PLAN',
    'COVER_LETTER',
    'FINALIZED_PERSONAL_STATEMENT',
    'FINALIZED_LETTER_OF_REC',
    'LINKEDIN_PROFILE',
    'APPLIED_FOR_SUMMER_JOB',
  ],
};

export const PostsecondaryApplicationMilestones = {
  headerName: 'Postsecondary application',
  keys: [
    'ANNUAL_CAREER_INTEREST_SURVEY',
    'FINALIZED_RESUME',
    'CREATED_POSTSECONDARY_LIST',
    'CUNY_APP_SUBMISSION',
    'APPLIED_FOR_SUMMER_JOB',
  ],
};

export const PostsecondaryTransitionMilestones = {
  headerName: 'Postsecondary transition',
  keys: [
    'FINALIZED_RESUME',
    'EMPLOYABILITY_SKILLS_PROFILE',
    'COUNSELING_FINAL_DECISION',
    'FINAL_DECISION',
    'COUNSELING_TRANSITION',
    'COMPLETED_SUMMER_JOB',
    'LINKEDIN_PROFILE',
  ],
};

export const PostsecondaryMilestonesCategories = {
  POSTSECONDARY_EXPLORATION: PostsecondaryExplorationMilestones,
  POSTSECONDARY_PREPARATION: PostsecondaryPreparationMilestones,
  POSTSECONDARY_APPLICATION: PostsecondaryApplicationMilestones,
  POSTSECONDARY_TRANSITION: PostsecondaryTransitionMilestones,
};
