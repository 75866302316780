import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BaseModalModule } from '../base-modal.module';
import { RemoveStudentActivityModalComponent } from './remove-student-activity/remove-student-activity-modal.component';
import { StudentActivitiesModalComponent } from './student-activities-modal.component';
import { StudentActivityModalHelpersService } from './student-activity-modal-helpers.service';
import { StudentActivityStatusModalComponent } from './student-activity-status/student-activity-status-modal.component';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';
import { NvInputsModule } from 'Src/nvps-libraries/design/nv-inputs.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';
import { NvIconModule } from 'Src/nvps-libraries/design/nv-icon/nv-icon.module';

@NgModule({
  declarations: [
    StudentActivitiesModalComponent,
    StudentActivityStatusModalComponent,
    RemoveStudentActivityModalComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    BaseModalModule,
    MatMenuModule,
    NvButtonModule,
    NvNoteModule,
    NvInputsModule,
    NvIconModule,
  ],
  exports: [StudentActivitiesModalComponent, StudentActivityStatusModalComponent, RemoveStudentActivityModalComponent],
  providers: [StudentActivityModalHelpersService],
})
export class StudentActivitiesModalModule {}
