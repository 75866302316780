import { IGroupData, IListConfig } from 'Src/ng2/shared/models/list-models';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { DateHelpers } from './../../../shared/services/date-helpers/date-helpers.service';
import { IRowData } from '../../models/list-models';
import { TSortDirections } from '../../services/list-services/sort-and-filter.service';

import { CUNY_APPS_COLUMNS, HESC_FIN_AID_COLUMNS } from './postsec-data-sources-modal.config';
import { ICunyCollegeReport } from 'Src/ng2/student/common-panels/student-postsec/student-postsec.component';

export interface IPostsecDataSourcesModalComponentData extends IBaseModalData {
  cunyReportData: ICunyCollegeReport[];
  fafsaApplicationStatus: string;
  fafsaSubmissionDate: string;
  tapApplicationStatus: string;
  tapSubmissionDate: string;
}

@Component({
  templateUrl: './postsec-data-sources-modal.component.html',
  styleUrls: ['./postsec-data-sources-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PostsecDataSourcesModalComponent extends BaseModalComponent implements OnInit {
  // Modal Configurations
  public readonly title: string = 'Postsec data resources';
  public readonly confirmationButtonLabel: string = 'Ok';
  public readonly isProfileMode: boolean = true;

  // segment toggle
  public controlOptions: string[] = ['CUNY Apps', 'HESC Fin Aid'];
  public controlIndex: number = 0;

  // note configs
  public infoIconName: string = 'info';
  public infoText: string;
  private readonly cunyInfoText: string =
    'The data below reflects student data as it appears in the latest CUNY applications report. The Portal by New Visions partners with CUNY to receive these reports monthly.';
  private readonly hescInfoText: string =
    'The data below reflects student data as it appears in the latest Higher Education Services Corporation (HESC) report. The Portal by New Visions partners with NYC DOE to receive weekly FAFSA and TAP application status updates.';

  // list configs
  public columns;
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$ = new BehaviorSubject(null);
  public filterFormControl: FormControl = new FormControl();
  public sortKey$: BehaviorSubject<string> = new BehaviorSubject(null);
  public sortDirection$: BehaviorSubject<TSortDirections> = new BehaviorSubject(null);
  public batchActionsMode$: Observable<boolean> = of(false);
  public listConfig: IListConfig;

  public cunyGroupingData: IGroupData[];
  public hescGroupingData: IGroupData[];
  private cunyColumnIndexMap: { [columnKey: string]: number };
  private hescColumnIndexMap: { [columnKey: string]: number };

  constructor (
    dialogRef: MatDialogRef<PostsecDataSourcesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IPostsecDataSourcesModalComponentData,
    private dateHelpers: DateHelpers,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    const {
      cunyReportData,
      fafsaApplicationStatus,
      fafsaSubmissionDate,
      tapApplicationStatus,
      tapSubmissionDate,
    } = this.data;

    this.listConfig = {
      emptyTableMessage: 'No available data',
      listType: 'POSTSEC_DATA_SOURCES',
      noDataMessage: 'No available data',
      sortableColumns: false,
      displayGroupFooter: false,
    };

    this.cunyColumnIndexMap = this.createColumnIndexMap(CUNY_APPS_COLUMNS);
    this.hescColumnIndexMap = this.createColumnIndexMap(HESC_FIN_AID_COLUMNS);

    this.cunyGroupingData = this.formatCunyGroupData(cunyReportData);
    this.hescGroupingData = this.formatHescGroupData(
      fafsaApplicationStatus,
      fafsaSubmissionDate,
      tapApplicationStatus,
      tapSubmissionDate,
    );

    // default to CUNY Apps
    this.onSegmentToggle(0);
  }

  private createColumnIndexMap (columns) {
    return columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});
  }

  private formatCunyGroupData (cunyData: ICunyCollegeReport[]): IGroupData[] {
    const formattedRowData: IRowData[][] = cunyData.map(cunyReport => {
      const { college, status } = cunyReport;
      return [
        {
          data: college,
          meta: {},
          columnKey: 'PATH_NAME',
        },
        {
          data: status,
          meta: {},
          columnKey: 'PATH_STATUS',
        },
      ];
    });

    const groupedData: IGroupData = {
      key: 'College',
      human: 'College',
      rowData: formattedRowData || [],
      showAll: true,
    };

    return [groupedData];
  }

  private formatHescGroupData(
    fafsaApplicationStatus: string,
    fafsaSubmissionDate: string,
    tapApplicationStatus: string,
    tapSubmissionDate: string,
  ): IGroupData[] {
    const formattedRowData: IRowData[][] = [
      [
        {
          data: 'FAFSA',
          columnKey: 'DATA_SOURCE',
        },
        {
          data: fafsaApplicationStatus,
          columnKey: 'HESC_REPORT_STATUS',
        },
        {
          data: fafsaSubmissionDate,
          columnKey: 'HESC_SUBMISSTION_DATE',
        },
      ],
      [
        {
          data: 'TAP',
          columnKey: 'DATA_SOURCE',
        },
        {
          data: tapApplicationStatus,
          columnKey: 'HESC_REPORT_STATUS',
        },
        {
          data: tapSubmissionDate,
          columnKey: 'HESC_SUBMISSTION_DATE',
        },
      ],
    ];

    const groupedData: IGroupData = {
      key: 'Data',
      human: 'Data',
      rowData: formattedRowData || [],
      showAll: true,
    };

    return [groupedData];
  }

  onConfirm (): void {
    super.close();
  }

  onSegmentToggle (segmentId: number): void {
    this.controlIndex = segmentId;
    if (this.controlIndex === 0) {
      this.infoText = this.cunyInfoText;
      this.columns = CUNY_APPS_COLUMNS;
      this.columnIndexMap = this.cunyColumnIndexMap;
      this.groupingData$.next(this.cunyGroupingData);
    } else if (this.controlIndex === 1) {
      this.infoText = this.hescInfoText;
      this.columns = HESC_FIN_AID_COLUMNS;
      this.columnIndexMap = this.hescColumnIndexMap;
      this.groupingData$.next(this.hescGroupingData);
    }
  }
}
