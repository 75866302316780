import { switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { StoreDataService } from '../../../shared/services/store-data-service/store-data.service';
import { LoadCurrentUser, getCurrentUser, getCurrentUserLoadedStatus } from 'Src/ng2/store';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { Auth } from 'Src/ng2/shared/auth/auth.service';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';

@Injectable()
export class SyncSheetGuard implements CanActivate {
  constructor (
    private router: Router,
    private storeDataService: StoreDataService,
    private imUser: ImUser,
    private auth: Auth,
  ) {}

  async canActivate (): Promise<boolean | UrlTree> {
    const _id = await this.auth.getCurrentUserId();
    return this.storeDataService.loadDataToStore$({ _id }, getCurrentUserLoadedStatus, LoadCurrentUser, getCurrentUser).pipe(
      switchMap((user: IUser) => {
        const showSyncedSheets = this.imUser.showSyncedSheets(user);
        if (!showSyncedSheets) return of(this.router.createUrlTree(['home'])) as Observable<UrlTree>;
        else return of(true) as Observable<boolean>;
      }),
    ).toPromise();
  }
}
