import { STAR_CHARTER_FLATTENED_STUDENT_MAP } from './flattened-student-map-star-charter/star-charter-flattened-student-map';
import * as _ from 'lodash';
import { CurrentSchoolYear, getPastSchoolYearWithPrefix } from '../../constants/current-school-year.constant';
import { FLATTENED_STUDENT_MAP_FOR_MAP_GROWTH } from './flattened-student-map-screeners/flattened-student-map-for-map-growth.constant';
import { FLATTENED_STUDENT_MAP_FOR_DESSA } from './flattened-student-map-screeners/flattened-student-map-for-dessa.constant';
import { ACADIENCE_FLATTENED_STUDENT_MAP } from './flattened-student-map-screeners/acadience-flattened-student-map.constant';
import { IREADY_FLATTENED_STUDENT_MAP } from './flattened-student-map-screeners/iReady-flattened-student-map.constant';
import { F_AND_P_FLATTENED_STUDENT_MAP, addStudentAssessmentColumns } from './flattened-student-map-screeners/f-and-p-flattened-student-map.constant';
// TODO: do we need to define 'calculation' for these constants? If not, remove it

export interface IFlattenedStudentMap {
  [key: string]: {
    tag: string;
    tags: string[];
    humanName: string;
    path?: string;
    calculation?: any;
    isDeprecated?: boolean;
  };
}

/* istanbul ignore next */
export const FlattenedStudentMap: IFlattenedStudentMap = {
  avgGpa8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Avg GPA: 8th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.eighthGrade',
    // calculation handled on backend
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  avgElaMathPr8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Avg ELA & Math PR: 8th Grade',
    path: 'middleSchoolDetails.examScoreSummary.pr8thGrade.avg',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfFailedCoursesInCurrSy: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Count of Failed Courses in CurrSY',
    path: 'creditDetails.currSy.failed',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfFailedCoursesInPriorSy: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Count of Failed Courses in PriorSY',
    path: 'creditDetails.priorSy.failed',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaCourseMark7thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Course Mark: 7th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.seventhGrade.elaCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaCourseMark8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Course Mark: 8th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.elaCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaPr7thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 7th Grade',
    path: 'middleSchoolDetails.examScoreSummary.pr7thGrade.rd',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaPr8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 8th Grade',
    path: 'middleSchoolDetails.examScoreSummary.pr8thGrade.rd',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathCourseMark7thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Course Mark: 7th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.seventhGrade.mathCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathCourseMark8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Course Mark: 8th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.mathCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathPr7thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 7th Grade',
    path: 'middleSchoolDetails.examScoreSummary.pr7thGrade.ma',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathPr8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 8th Grade',
    path: 'middleSchoolDetails.examScoreSummary.pr8thGrade.ma',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathScaledScoreMostRecent: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Scaled Score (Most Recent)',
    path: 'charterDetails.mathScaledScoreMostRecent',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathScaledScoreTestDate: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Scaled Score Test Date',
    path: 'charterDetails.mathScaledScoreTestDate',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  latestMpCountOfCoursesNotPassing: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Latest MP Count of Courses Not Passing',
    path: 'creditDetails.currSy.mostRecentMps.totalFailing',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  latestMpCountOfCoursesNotPassingNeededForGrad: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Latest MP Count of Courses Not Passing Needed for Grad',
    path: 'creditDetails.currSy.mostRecentMps.totalFailingHighPriority',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  readingLexileMostRecent: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Reading Lexile (Most Recent)',
    path: 'charterDetails.readingLexileMostRecent',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  readingLexileTestDate: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Reading Lexile Test Date',
    path: 'charterDetails.readingLexileTestDate',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  readingRtITier: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Reading RtI Tier',
    path: 'charterDetails.readingRtiTier',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  scienceCourseMark7thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Science Course Mark: 7th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.seventhGrade.sciCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  scienceCourseMark8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Science Course Mark: 8th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.sciCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  socialStudiesCourseMark7thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Social Studies Course Mark: 7th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.seventhGrade.ssCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  socialStudiesCourseMark8thGrade: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Social Studies Course Mark: 8th Grade',
    path: 'middleSchoolDetails.courseMarkHistory.eighthGrade.ssCoreMs.mark.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transcriptGradeAverage: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Transcript Grade Average',
    path: 'creditDetails.transcriptGradeAverage.gpa',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdDoeRiskGroup: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd DOE Risk Group',
    path: 'att.riskGroup',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdLast20Days: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Last 20 Days',
    path: 'att.last20.pctPres',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdLast20DaysVsYtD: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Last 20 Days vs. YtD',
    path: 'att.last20MinusYtdPct',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdToday: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Today',
    path: 'att.today',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdMon: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Mon',
    path: 'att.absMon',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdTues: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Tues',
    path: 'att.absTues',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdWed: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Wed',
    path: 'att.absWed',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdThurs: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Thurs',
    path: 'att.absThurs',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdFri: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Fri',
    path: 'att.absFri',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdYtd: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd: YTD',
    path: 'att.ytd',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attdYtdFilter: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd: YTD Filter',
    path: 'att.ytd',
    calculation (student) {
      const { att } = student;
      let results;
      if (att.ytd === null) results = null;
      if (att.ytd < 0.51) results = '<51%';
      if (att.ytd >= 0.51 && att.ytd <= 0.8) results = '51-80%';
      if (att.ytd > 0.8 && att.ytd <= 0.9) results = '81-90%';
      if (att.ytd > 0.9 && att.ytd <= 0.95) results = '91-95%';
      if (att.ytd > 0.95) results = '>95%';
      return results;
    },
  },
  attdPriorSy: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd: PriorSY',
    path: 'att.history',
    calculation (student) {
      const {
        att: { history },
      } = student;
      const oneYearprior = history.find(({ year }) => year === 'SY2021-22');
      const overallPctPres = (oneYearprior && oneYearprior.overallPctPres) || null;

      return overallPctPres;
    },
  },
  attdTwoPriorSy: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd: Two PriorSY',
    path: 'att.history',
    calculation (student) {
      const {
        att: { history },
      } = student;
      const twoYearsPrior = history.find(({ year }) => year === 'SY2020-21');
      const overallPctPres = (twoYearsPrior && twoYearsPrior.overallPctPres) || null;

      return overallPctPres;
    },
  },
  attdThreePriorSy: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd: Three PriorSY',
    path: 'att.history',
    calculation (student) {
      const {
        att: { history },
      } = student;
      const threeYearsPrior = history.find(({ year }) => year === 'SY2019-20');
      const overallPctPres = (threeYearsPrior && threeYearsPrior.overallPctPres) || null;

      return overallPctPres;
    },
  },
  attd8thGrade: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd: 8th Grade',
    path: 'att.eighthGradeFinalPercentPresent',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attendedSummerSchoolInPriorSy: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attended Summer School in Prior SY',
    path: 'creditDetails.summerSchool.attendedInPriorSy',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  changeFromLastYear: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Change from Last Year',
    path: 'att.ytdLastSy',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  consecutiveAbsences: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Consecutive Absences',
    path: 'att.absStreak',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfDaysAbsentLast10Days: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Days Absent: Last 10 Days',
    path: 'att.last10.abs',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfDaysAbsentLast5Days: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Days Absent: Last 5 Days',
    path: 'att.last5.abs',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfDaysAbsentYtd: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Days Absent: YTD',
    path: 'att.currSy.abs',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  daysAbsent: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Days Absent',
    path: 'att.currSy.daysAbs',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  daysLate: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Days Late',
    path: 'att.currSy.daysLate',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  latenessesInLast5Days: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Latenesses in Last 5 Days',
    path: 'att.last5.daysLate',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  latenessesYtd: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Latenesses YTD',
    path: 'att.currSy.late',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  percentDaysLatePriorSy: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Percent Days Late: PriorSY',
    path: 'att.priorSyFinalPercentLate',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  percentDaysLateYtd: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Percent Days Late: YTD',
    path: 'att.currSyYtdPercentLate',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transitDistanceMiles: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Transit Distance (miles)',
    path: 'studentDetails.transitDistanceMiles',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transitTimeMinutes: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Transit Time (minutes)',
    path: 'studentDetails.transitTimeMinutes',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  admitDate: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Admit Date',
    path: 'studentDetails.admitDate',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  citywideLowestThird: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Citywide Lowest Third',
    path: 'middleSchoolDetails.lowestThird.citywide',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  class: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Class',
    path: 'studentDetails.classOf',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  dischargeCode: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Discharge Code',
    path: 'studentDetails.dischargeCode',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  dob: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'DOB',
    path: 'studentDetails.dob',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mll: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'MLL',
    path: 'ellDetails.isEll',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  ethnicity: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Ethnicity',
    path: 'studentDetails.ethnicity',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  gender: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Gender',
    path: 'studentDetails.gender',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  grade: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Grade',
    path: 'studentDetails.currGradeLevel',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  housingStatus: {
    tag: 'Basic info, Attendance',
    tags: ['Basic info', ' Attendance'],
    humanName: 'Housing Status',
    path: 'studentDetails.housingStatus',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  swd: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'SWD',
    path: 'spedDetails.isSped',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  lepStatus: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'LEP Status',
    path: 'ellDetails.lepStatus',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  nvLowestThird: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'NV Lowest Third',
    path: 'middleSchoolDetails.lowestThird.nv',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  officialClass: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Official Class',
    path: 'studentDetails.officialClass',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  parentEmail: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Parent Email',
    path: 'studentContactDetails.parentEmail',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  schoolLowestThird: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'School Lowest Third',
    path: 'middleSchoolDetails.lowestThird.school',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  sendingSchool: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Sending School',
    path: 'studentDetails.sendingSchool',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  sife: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'SIFE',
    path: 'ellDetails.sife',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  swdProgram: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'SWD Program',
    path: 'spedDetails.program',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  status: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Status',
    path: 'schoolStatus',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  studentEmail: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Student Email',
    path: 'studentContactDetails.email',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  studentId: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Student ID',
    path: 'studentId',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  studentName: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Student Name',
    path: 'studentDetails.name.lastFirst',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  homeLanguage: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'Home Language',
    path: 'studentDetails.homeLanguage',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  tempHousingFlag: {
    tag: 'Basic info, Attendance',
    tags: ['Basic info', ' Attendance'],
    humanName: 'Temp Housing Flag',
    path: 'studentDetails.tempResFlag',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  sohoPrincipalSuspensionsYtd: {
    tag: 'Behavioral',
    tags: ['Behavioral'],
    humanName: 'SOHO: Principal Suspensions YTD',
    path: 'behaviorDetails.suspensions.currSy.principal',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  sohoSuperintendentSuspensionsYtd: {
    tag: 'Behavioral',
    tags: ['Behavioral'],
    humanName: 'SOHO: Superintendent Suspensions YTD',
    path: 'behaviorDetails.suspensions.currSy.superintendent',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  artEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Art: Earned',
    path: 'creditDetails.byArea.art.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  artEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Art: Earned & Sched',
    path: 'creditDetails.byArea.art.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  coreCreditsEarnedSchedInCurrSy: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Core Credits Earned & Sched in CurrSY',
    path: 'creditDetails.currSy.earnedPlusScheduledCore',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  coreCreditsEarnedToDateInCurrSy: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Core Credits Earned to Date in CurrSY',
    path: 'creditDetails.currSy.earnedCore',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfCoreCreditsEarnedViaCreditRecovery: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Count of Core Credits Earned via Credit Recovery',
    path: 'creditDetails.creditRecovery.coreCreditsEarned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  coursesPotentiallyEligibleForCreditRecovery: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Courses Potentially Eligible for Credit Recovery',
    path: 'creditDetails.creditRecovery.eligibleCoursesString',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  cteEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'CTE: Earned',
    path: 'creditDetails.byArea.cte.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  cteEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'CTE: Earned & Sched',
    path: 'creditDetails.byArea.cte.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  currentCreditsScheduled: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Current Credits Scheduled',
    path: 'creditDetails.byArea.total.scheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  earned10CreditsInPriorSy: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Earned 10+ Credits in PriorSY',
    path: 'creditDetails.priorSy.earned10Plus',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  earnedCreditsIn3Of4CoreSubjectsInCurrSy: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Earned Credits in 3 of 4 Core Subjects in CurrSY',
    path: 'creditDetails.currSy.earned3Of4Core',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  econEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Econ: Earned',
    path: 'creditDetails.byArea.ssEcon.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  econEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Econ: Earned & Sched',
    path: 'creditDetails.byArea.ssEcon.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaOtherEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'ELA Other: Earned',
    path: 'creditDetails.byArea.elaOther.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaOtherEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'ELA Other: Earned & Sched',
    path: 'creditDetails.byArea.elaOther.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'ELA: Earned',
    path: 'creditDetails.byArea.elaCore.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'ELA: Earned & Sched',
    path: 'creditDetails.byArea.elaCore.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  currentGpa: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Current GPA',
    path: 'creditDetails.GPA',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  gpaBand: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'GPA Band',
    calculation (student) {
      return student;
    },
  },
  projectedGpa: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Projected GPA',
    path: 'creditDetails.gpaProjected',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  projectedChangeInGpa: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Projected Change in GPA',
    path: 'creditDetails.gpaChanged',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Global: Earned',
    path: 'creditDetails.byArea.ssGlobal.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Global: Earned & Sched',
    path: 'creditDetails.byArea.ssGlobal.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  govtEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Govt: Earned',
    path: 'creditDetails.byArea.ssGovt.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  govtEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Govt: Earned & Sched',
    path: 'creditDetails.byArea.ssGovt.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  healthEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Health: Earned',
    path: 'creditDetails.byArea.health.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  healthEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Health: Earned & Sched',
    path: 'creditDetails.byArea.health.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  lifeSciEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Life Sci: Earned',
    path: 'creditDetails.byArea.sciLife.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  lifeSciEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Life Sci: Earned & Sched',
    path: 'creditDetails.byArea.sciLife.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  loteEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'LOTE: Earned',
    path: 'creditDetails.byArea.lote.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  loteEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'LOTE: Earned & Sched',
    path: 'creditDetails.byArea.lote.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Math: Earned',
    path: 'creditDetails.byArea.math.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Math: Earned & Sched',
    path: 'creditDetails.byArea.math.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  peEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'PE: Earned',
    path: 'creditDetails.byArea.pe.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  peEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'PE: Earned & Sched',
    path: 'creditDetails.byArea.pe.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  physSciEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Phys Sci: Earned',
    path: 'creditDetails.byArea.sciPhysical.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  physSciEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Phys Sci: Earned & Sched',
    path: 'creditDetails.byArea.sciPhysical.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ssElectiveEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'SS Elective: Earned',
    path: 'creditDetails.byArea.ssElective.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ssElectiveEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'SS Elective: Earned & Sched',
    path: 'creditDetails.byArea.ssElective.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  subjectsWithCreditGaps: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Subjects with Credit Gaps',
    path: 'creditDetails.creditGaps.subjectsWithGaps',

    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  total44ReqEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total (44 Req): Earned',
    path: 'creditDetails.byArea.total.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  totalCreditsAttempted: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total Credits Attempted',
    path: 'creditDetails.byArea.total.attempted',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  totalCreditsEarnedSchedInCurrSy: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total Credits Earned & Sched in CurrSY',
    path: 'creditDetails.currSy.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  totalCreditsEarnedToDateInCurrSy: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total Credits Earned to Date in CurrSY',
    path: 'creditDetails.currSy.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  totalCreditsEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total Credits: Earned & Sched',
    path: 'creditDetails.byArea.total.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  totalSciEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total Sci: Earned',
    path: 'creditDetails.byArea.sciTotal.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  totalSciEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'Total Sci: Earned & Sched',
    path: 'creditDetails.byArea.sciTotal.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usEarned: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'US: Earned',
    path: 'creditDetails.byArea.ssUs.earned',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usEarnedSched: {
    tag: 'Credits',
    tags: ['Credits'],
    humanName: 'US: Earned & Sched',
    path: 'creditDetails.byArea.ssUs.earnedPlusScheduled',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mostRecentNyseslatPl: {
    tag: 'ELL',
    tags: ['ELL'],
    humanName: 'Most recent NYSESLAT PL',
    path: 'ellDetails.nyseslat.examScoreHistory',
    calculation (student) {
      return student;
    },
  },
  nyseslatPlPriorSy: {
    tag: 'ELL',
    tags: ['ELL'],
    humanName: 'NYSESLAT PL Prior SY',
    path: 'ellDetails.nyseslat.examScoreHistory',
    calculation (student) {
      const prevSchoolYearWithPrefix = getPastSchoolYearWithPrefix(CurrentSchoolYear.WITH_SY_PREFIX, 1);
      const examScore = student.ellDetails.nyseslat.examScoreHistory.find(
        ({ year }) => year === prevSchoolYearWithPrefix,
      );
      return examScore ? examScore.nyseslat : null;
    },
  },
  nyseslatPlTwoSyPrior: {
    tag: 'ELL',
    tags: ['ELL'],
    humanName: 'NYSESLAT PL Two SY Prior',
    path: 'ellDetails.nyseslat.examScoreHistory',
    calculation (student) {
      const twoSchoolYearsPriorWithPrefix = getPastSchoolYearWithPrefix(CurrentSchoolYear.WITH_SY_PREFIX, 2);
      const examScore = student.ellDetails.nyseslat.examScoreHistory.find(
        ({ year }) => year === twoSchoolYearsPriorWithPrefix,
      );
      return examScore ? examScore.nyseslat : null;
    },
  },
  nyseslatPlThreeSyPrior: {
    tag: 'ELL',
    tags: ['ELL'],
    humanName: 'NYSESLAT PL Three SY Prior',
    path: 'ellDetails.nyseslat.examScoreHistory',
    calculation (student) {
      const threeSchoolYearsPriorWithPrefix = getPastSchoolYearWithPrefix(CurrentSchoolYear.WITH_SY_PREFIX, 3);
      const examScore = student.ellDetails.nyseslat.examScoreHistory.find(
        ({ year }) => year === threeSchoolYearsPriorWithPrefix,
      );
      return examScore ? examScore.nyseslat : null;
    },
  },
  testOutYear: {
    tag: 'ELL',
    tags: ['ELL'],
    humanName: 'Test Out Year',
    path: 'ellDetails.testOutYr',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  attendanceThreat: {
    tag: 'Graduation, Attendance',
    tags: ['Graduation', ' Attendance'],
    humanName: 'Attendance Threat',
    path: 'gradDetails.threatsToGrad.attendanceThreat',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  countOfGradThreats: {
    tag: 'Graduation',
    tags: ['Graduation'],
    humanName: 'Count of Grad Threats',
    path: 'gradDetails.threatsToGrad.totalThreats',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  courseFailureThreat: {
    tag: 'Graduation, Academic',
    tags: ['Graduation', ' Academic'],
    humanName: 'Course Failure Threat',
    path: 'gradDetails.threatsToGrad.courseFailureThreat',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  creditGapsThreat: {
    tag: 'Graduation, Credits',
    tags: ['Graduation', ' Credits'],
    humanName: 'Credit Gaps Threat',
    path: 'gradDetails.threatsToGrad.creditGapThreat',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  plannedCredentials: {
    tag: 'Graduation',
    tags: ['Graduation'],
    humanName: 'Planned Credentials',
    path: 'gradPlanningDetails.plannedCreds',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  plannedDiplomaType: {
    tag: 'Graduation',
    tags: ['Graduation'],
    humanName: 'Planned Diploma Type',
    path: 'gradPlanningDetails.plannedDiplomaType',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  plannedEndorsements: {
    tag: 'Graduation',
    tags: ['Graduation'],
    humanName: 'Planned Endorsements',
    path: 'gradPlanningDetails.plannedEndorsements',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  plannedGraduationDate: {
    tag: 'Graduation',
    tags: ['Graduation'],
    humanName: 'Planned Graduation Date',
    path: 'gradPlanningDetails.plannedGraduationDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  potentiallyEligibleForSafetyNet: {
    tag: 'Graduation',
    tags: ['Graduation'],
    humanName: 'Potentially Eligible for Safety Net',
    path: 'spedDetails.potentialSafetyNet',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  regentsThreat: {
    tag: 'Graduation, Regents',
    tags: ['Graduation', ' Regents'],
    humanName: 'Regents Threat',
    path: 'gradDetails.threatsToGrad.regentsThreat',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  collegeReadyOnMathRegentsOrSatMath: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'College Ready On Math Regents Or SAT Math',
    path: 'postsecondary.isMathCR',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfApExams: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Count of AP Exams',
    path: 'postsecondary.ap.countOfExams',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfApExamsWith3: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Count of AP Exams with 3+',
    path: 'postsecondary.ap.countOfExamsWith3',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathRegentsCollegeReady: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Math Regents College Ready',
    path: 'postsecondary.isMathCR',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  progressToCunyBenchmarks: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Progress to CUNY Benchmarks',
    path: 'gradDetails.progress.cuny.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  progressToGraduationCollegeReadiness: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Progress to Graduation/College Readiness',
    path: 'gradDetails.progress.grad.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  psatMaxScoreMath: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'PSAT Max Score: Math',
    path: 'postsecondary.PSAT.mathMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  psatMaxScoreReading: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'PSAT Max Score: Reading',
    path: 'postsecondary.PSAT.readingMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  psatMaxScoreTotalReadingMathWriting: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'PSAT Max Score: Total (Reading + Math + Writing)',
    path: 'postsecondary.PSAT.totalMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  psatMaxScoreWriting: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'PSAT Max Score: Writing',
    path: 'postsecondary.PSAT.writingMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  satMaxScoreMath: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'SAT Max Score: Math',
    path: 'postsecondary.SAT.mathMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  satMaxScoreReading: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'SAT Max Score: Reading',
    path: 'postsecondary.SAT.readingMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  satMaxScoreTotalReadingMath: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'SAT Max Score: Total (Reading + Math)',
    path: 'postsecondary.SAT.total1600Max',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  satMaxScoreWriting: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'SAT Max Score: Writing',
    path: 'postsecondary.SAT.writingMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccAlgAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Alg: Attempts',
    path: 'regentsDetails.byExam.ccAlg.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccAlgMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Alg: Max Score',
    path: 'regentsDetails.byExam.ccAlg.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccAlgNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Alg: Next Scheduled',
    path: 'nextScheduledRegents.ccAlg.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccAlgNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Alg: NV Rec',
    path: 'regentsDetails.byExam.ccAlg.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccAlgSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Alg: Sched In Stars',
    path: 'regentsDetails.byExam.ccAlg.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccElaAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC ELA: Attempts',
    path: 'regentsDetails.byExam.ccEla.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccElaMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC ELA: Max Score',
    path: 'regentsDetails.byExam.ccEla.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccElaNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC ELA: Next Scheduled',
    path: 'nextScheduledRegents.ccEla.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccElaNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC ELA: NV Rec',
    path: 'regentsDetails.byExam.ccEla.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccElaSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC ELA: Sched In Stars',
    path: 'regentsDetails.byExam.ccEla.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccGeomAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Geom: Attempts',
    path: 'regentsDetails.byExam.ccGeom.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccGeomMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Geom: Max Score',
    path: 'regentsDetails.byExam.ccGeom.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccGeomNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Geom: Next Scheduled',
    path: 'nextScheduledRegents.ccGeom.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccGeomNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Geom: NV Rec',
    path: 'regentsDetails.byExam.ccGeom.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccGeomSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Geom: Sched In Stars',
    path: 'regentsDetails.byExam.ccGeom.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccTrigAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Trig: Attempts',
    path: 'regentsDetails.byExam.ccTrig.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccTrigMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Trig: Max Score',
    path: 'regentsDetails.byExam.ccTrig.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ccTrigNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Trig: Next Scheduled',
    path: 'nextScheduledRegents.ccTrig.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccTrigNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Trig: NV Rec',
    path: 'regentsDetails.byExam.ccTrig.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  ccTrigSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'CC Trig: Sched In Stars',
    path: 'regentsDetails.byExam.ccTrig.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  chemAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Chem: Attempts',
    path: 'regentsDetails.byExam.chem.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  chemMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Chem: Max Score',
    path: 'regentsDetails.byExam.chem.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  chemNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Chem: Next Scheduled',
    path: 'nextScheduledRegents.chem.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  chemNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Chem: NV Rec',
    path: 'regentsDetails.byExam.chem.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  chemSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Chem: Sched In Stars',
    path: 'regentsDetails.byExam.chem.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  compElaAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Comp ELA: Attempts',
    path: 'regentsDetails.byExam.compEla.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  compElaMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Comp ELA: Max Score',
    path: 'regentsDetails.byExam.compEla.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfRegentsPassedAt65Of5: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Count of Regents Passed at 65+ of 5',
    path: 'regentsDetails.numberPassed.of5Regents',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfRegentsPassedAt65Of9: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Count of Regents Passed at 65+ of 9',
    path: 'regentsDetails.numberPassed.of9Advanced',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countOfRegentsPassedOf5PriorToCurrSy: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Count of Regents Passed of 5 Prior to CurrSY',
    path: 'regentsDetails.numberPassed.of5GradPriorToCurrSy',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  countRegentsExamsSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Count Regents Exams Sched in STARS',
    path: 'regentsDetails.countOfNextSchedStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  earthAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Earth: Attempts',
    path: 'regentsDetails.byExam.earth.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  earthMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Earth: Max Score',
    path: 'regentsDetails.byExam.earth.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  earthNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Earth: Next Scheduled',
    path: 'nextScheduledRegents.earth.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  earthNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Earth: NV Rec',
    path: 'regentsDetails.byExam.earth.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  earthSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Earth: Sched In Stars',
    path: 'regentsDetails.byExam.earth.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaExamProgressAndStarsSchedulingStatus: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'ELA: Exam Progress and STARS Scheduling Status',
    path: 'regentsDetails.byCategory5.ela.schedStatusStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  englishRegentsCollegeReady: {
    tag: 'Regents, Postsecondary',
    tags: ['Regents', ' Postsecondary'],
    humanName: 'English Regents College Ready',
    path: 'postsecondary.isELACR',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  geomAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Geom: Attempts',
    path: 'regentsDetails.byExam.oldGeom.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  geomMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Geom: Max Score',
    path: 'regentsDetails.byExam.oldGeom.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global: Attempts',
    path: 'regentsDetails.byExam.global.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global: Max Score',
    path: 'regentsDetails.byExam.global.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global: Next Scheduled',
    path: 'nextScheduledRegents.global.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  globalNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global: NV Rec',
    path: 'regentsDetails.byExam.global.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  globalSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global: Sched In Stars',
    path: 'regentsDetails.byExam.global.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalTwoAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global Two: Attempts',
    path: 'regentsDetails.byExam.globalTwo.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalTwoMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global Two: Max Score',
    path: 'regentsDetails.byExam.globalTwo.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  globalTwoNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global Two: Next Scheduled',
    path: 'nextScheduledRegents.globalTwo.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  globalTwoNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global Two: NV Rec',
    path: 'regentsDetails.byExam.globalTwo.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  globalTwoSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Global Two: Sched In Stars',
    path: 'regentsDetails.byExam.globalTwo.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  intAlgAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Int Alg: Attempts',
    path: 'regentsDetails.byExam.intAlg.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  intAlgMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Int Alg: Max Score',
    path: 'regentsDetails.byExam.intAlg.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  livEnvAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Liv Env: Attempts',
    path: 'regentsDetails.byExam.livingEnv.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  livEnvMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Liv Env: Max Score',
    path: 'regentsDetails.byExam.livingEnv.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  livEnvNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Liv Env: Next Scheduled',
    path: 'nextScheduledRegents.livingEnv.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  livEnvNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Liv Env: NV Rec',
    path: 'regentsDetails.byExam.livingEnv.recommendation',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  livEnvSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Liv Env: Sched In Stars',
    path: 'regentsDetails.byExam.livingEnv.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  loteAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'LOTE: Attempts',
    path: 'regentsDetails.byExam.lote.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  loteMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'LOTE: Max Score',
    path: 'regentsDetails.byExam.lote.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  loteNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'LOTE: Next Scheduled',
    path: 'nextScheduledRegents.lote.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  loteNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'LOTE: NV Rec',
    path: 'regentsDetails.byExam.lote.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  loteSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'LOTE: Sched In Stars',
    path: 'regentsDetails.byExam.lote.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  mathExamProgressAndStarsSchedulingStatus: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Math: Exam Progress and STARS Scheduling Status',
    path: 'regentsDetails.byCategory5.math.schedStatusStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScoreActEnglish: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: ACT English',
    path: 'postsecondary.ACT.readingMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScoreActMath: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: ACT Math',
    path: 'postsecondary.ACT.mathMax',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScoreEnglishExams: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: English Exams',
    path: 'regentsDetails.byCategory5.ela.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScoreMath: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: Math',
    path: 'regentsDetails.byCategory5.math.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScorePhysicalScience: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: Physical Science',
    path: 'regentsDetails.byCategory9.physSci.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScorePlusOne: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: Plus One',
    path: 'regentsDetails.byCategory5.plus1.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScoreScience: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: Science',
    path: 'regentsDetails.byCategory5.sci.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  maxScoreSs: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Max Score: SS',
    path: 'regentsDetails.byCategory5.ss.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  physicsAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Physics: Attempts',
    path: 'regentsDetails.byExam.physics.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  physicsMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Physics: Max Score',
    path: 'regentsDetails.byExam.physics.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  physicsNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Physics: Next Scheduled',
    path: 'nextScheduledRegents.physics.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  physicsNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Physics: NV Rec',
    path: 'regentsDetails.byExam.physics.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  physicsSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Physics: Sched In Stars',
    path: 'regentsDetails.byExam.physics.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  plannedLoteExamName: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Planned LOTE Exam Name',
    path: 'nextScheduledRegents.lote.examLanguage',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  regents1OptionExamProgressAndStarsSchedulingStatus: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Regents +1 Option: Exam Progress and STARS Scheduling Status',
    path: 'regentsDetails.byCategory5.plus1.schedStatusStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  regentsNeededToBeOnTrackForCollegeReadiness: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Regents Needed To Be On-Track For College Readiness',
    path: 'regentsDetails.needed.onTrack.collegeReady',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  remainingRegentsForAdvancedRegentsDiploma: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Remaining Regents For Advanced Regents Diploma',
    path: 'regentsDetails.needed.all.advanced',
    calculation (student) {
      const res = _.get(student, this.path);
      return res && res.length ? res.join(',') : '';
    },
  },
  scienceExamProgressAndStarsSchedulingStatus: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Science: Exam Progress and STARS Scheduling Status',
    path: 'regentsDetails.byCategory5.sci.schedStatusStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  ssExamProgressAndStarsSchedulingStatus: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'SS: Exam Progress and STARS Scheduling Status',
    path: 'regentsDetails.byCategory5.ss.schedStatusStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  trigAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Trig: Attempts',
    path: 'regentsDetails.byExam.oldTrig.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  trigMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'Trig: Max Score',
    path: 'regentsDetails.byExam.oldTrig.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US: Attempts',
    path: 'regentsDetails.byExam.us.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US: Max Score',
    path: 'regentsDetails.byExam.us.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US: Next Scheduled',
    path: 'nextScheduledRegents.us.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  usNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US: NV Rec',
    path: 'regentsDetails.byExam.us.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  usSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US: Sched In Stars',
    path: 'regentsDetails.byExam.us.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usFrameworkAttempts: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US Framework: Attempts',
    path: 'regentsDetails.byExam.usFramework.attempts',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usFrameworkMaxScore: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US Framework: Max Score',
    path: 'regentsDetails.byExam.usFramework.maxScore.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  usFrameworkNextScheduled: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US Framework: Next Scheduled',
    path: 'nextScheduledRegents.usFramework.adminDate',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  usFrameworkNvRec: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US Framework: NV Rec',
    path: 'regentsDetails.byExam.usFramework.recommendation',
    calculation (student) {
      const date = _.get(student, this.path);
      return date ? date.toString() : '';
    },
  },
  usFrameworkSchedInStars: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'US Framework: Sched In Stars',
    path: 'regentsDetails.byExam.usFramework.schedInStars',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transferSchoolsOnlyAgeOn1231OfCurrSy: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Age on 12/31 of CurrSY',
    path: 'transferDetails.ageOn1231ThisSy',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transferSchoolsOnlyCountOfAdmitsToCurrentSchool: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Count of Admits to Current School',
    path: 'transferDetails.countOfAdmitsToCurrSchool',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transferSchoolsOnlyCountOfRegentsPassedAt65PriorTo1stEntry: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Count of Regents Passed at 65+ Prior to 1st Entry',
    path: 'transferDetails.regentsPassedAt65PlusPriorToFirstEntry',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transferSchoolsOnlyCreditsEarnedPriorTo1stEntry: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Credits Earned Prior to 1st Entry',
    path: 'transferDetails.creditsEarnedPriorToFirstEntry',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transferSchoolsOnlyDistanceFromGraduation: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Distance from Graduation',
    path: 'gradDetails.progress.termsToGrad.string',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  transferSchoolsOnlyMostAtRiskAtTimeOfEntry: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Most at Risk at Time of Entry',
    path: 'transferDetails.mostAtRisk',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  transferSchoolsOnlyOverageUnderCreditedAtTimeOfEntry: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Overage Under Credited at Time of Entry',
    path: 'transferDetails.overAgeUnderCredited',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  transferSchoolsOnlySeniorFlag: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Senior Flag',
    path: 'transferDetails.isSenior',
    calculation (student) {
      const val = _.get(student, this.path);
      return val ? 'Yes' : '';
    },
  },
  transferSchoolsOnlyYearOfEntry: {
    tag: 'Transfer',
    tags: ['Transfer'],
    humanName: '*Transfer Schools Only: Year of Entry',
    path: 'transferDetails.yearOfEntry',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  elaGrowthScore2013: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2013',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2012-13' }),
        'growth',
      );
    },
  },
  elaGrowthScore2014: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2014',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2013-14' }),
        'growth',
      );
    },
  },
  elaGrowthScore2015: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2015',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2014-15' }),
        'growth',
      );
    },
  },
  elaGrowthScore2016: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2016',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2015-16' }),
        'growth',
      );
    },
  },
  elaGrowthScore2017: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2017',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2016-17' }),
        'growth',
      );
    },
  },
  elaGrowthScore2018: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2018',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2017-18' }),
        'growth',
      );
    },
  },
  elaGrowthScore2019: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Growth Score 2019',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2018-19' }),
        'growth',
      );
    },
  },
  elaMostRecentMpCourseMark: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA Most Recent MP Course Mark',
    path: 'currProgram.grades',
    calculation (student) {
      return _.result(_.find(student.currProgram.grades, { mostRecent: true, subj: 'ELA_CORE_MS' }), 'str');
    },
  },
  elaPr2013: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2013',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2012-13' }),
        'pr',
      );
    },
  },
  elaPr2014: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2014',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2013-14' }),
        'pr',
      );
    },
  },
  elaPr2015: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2015',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2014-15' }),
        'pr',
      );
    },
  },
  elaPr2016: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2016',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2015-16' }),
        'pr',
      );
    },
  },
  elaPr2017: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2017',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2016-17' }),
        'pr',
      );
    },
  },
  elaPr2018: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2018',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2017-18' }),
        'pr',
      );
    },
  },
  elaPr2019: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2019',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2018-19' }),
        'pr',
      );
    },
  },
  elaPr2022: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'ELA PR: 2022',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'ELA', year: 'SY2021-22' }),
        'pr',
      );
    },
  },
  mathGrowthScore2013: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2013',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2012-13' }),
        'growth',
      );
    },
  },
  mathGrowthScore2014: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2014',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2013-14' }),
        'growth',
      );
    },
  },
  mathGrowthScore2015: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2015',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2014-15' }),
        'growth',
      );
    },
  },
  mathGrowthScore2016: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2016',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2015-16' }),
        'growth',
      );
    },
  },
  mathGrowthScore2017: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2017',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2016-17' }),
        'growth',
      );
    },
  },
  mathGrowthScore2018: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2018',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2017-18' }),
        'growth',
      );
    },
  },
  mathGrowthScore2019: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Growth Score 2019',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2018-19' }),
        'growth',
      );
    },
  },
  mathMostRecentMpCourseMark: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math Most Recent MP Course Mark',
    path: 'currProgram.grades',
    calculation (student) {
      return _.result(_.find(student.currProgram.grades, { mostRecent: true, subj: 'MATH_CORE_MS' }), 'str');
    },
  },
  mathPr2013: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2013',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2012-13' }),
        'pr',
      );
    },
  },
  mathPr2014: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2014',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2013-14' }),
        'pr',
      );
    },
  },
  mathPr2015: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2015',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2014-15' }),
        'pr',
      );
    },
  },
  mathPr2016: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2016',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2015-16' }),
        'pr',
      );
    },
  },
  mathPr2017: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2017',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2016-17' }),
        'pr',
      );
    },
  },
  mathPr2018: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2018',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2017-18' }),
        'pr',
      );
    },
  },
  mathPr2019: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2019',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2018-19' }),
        'pr',
      );
    },
  },
  mathPr2022: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Math PR: 2022',
    path: 'assmnts.history',
    calculation (student) {
      return _.result(
        _.find(student.assmnts.history, { name: 'State Assessment', subj: 'Math', year: 'SY2021-22' }),
        'pr',
      );
    },
  },
  scienceMostRecentMpCourseMark: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Science Most Recent MP Course Mark',
    path: 'currProgram.grades',
    calculation (student) {
      return _.result(_.find(student.currProgram.grades, { mostRecent: true, subj: 'SCI_CORE_MS' }), 'str');
    },
  },
  socialStudiesMostRecentMpCourseMark: {
    tag: 'Academic',
    tags: ['Academic'],
    humanName: 'Social Studies Most Recent MP Course Mark',
    path: 'currProgram.grades',
    calculation (student) {
      return _.result(_.find(student.currProgram.grades, { mostRecent: true, subj: 'SS_CORE_MS' }), 'str');
    },
  },
  attdSept: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Sept',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'sep');
    },
  },
  attdOct: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Oct',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'oct');
    },
  },
  attdNov: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Nov',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'nov');
    },
  },
  attdDec: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Dec',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'dec');
    },
  },
  attdJan: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Jan',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'jan');
    },
  },
  attdFeb: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Feb',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'feb');
    },
  },
  attdMar: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Mar',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'mar');
    },
  },
  attdApr: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd Apr',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'apr');
    },
  },
  attdMay: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd May',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'may');
    },
  },
  attdJune: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attd June',
    path: 'att.history',
    calculation (student) {
      return _.result(_.find(student.att.history, { year: 'SY2019-20' }), 'jun');
    },
  },
  daysPresent: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Days Present',
    path: 'att.currSy.daysPresent',
    calculation (student) {
      const days = student.att.currSy.daysPresent;
      return days && days.length ? days.join(',') : '';
    },
  },
  advisorEmail: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Advisor Email',
    path: 'pointPeople',
    calculation (student) {
      // This logic must match ImUser#getEffectiveEmail function
      const gafeEmail = _.result(_.find(student.pointPeople, { type: 'ADVISOR' }), 'user.gafeEmail');
      const doeEmail = _.result(_.find(student.pointPeople, { type: 'ADVISOR' }), 'user.doeEmail');
      return gafeEmail || doeEmail;
    },
  },
  advisor: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Advisor',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'ADVISOR' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'ADVISOR' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  guidanceCounselor: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Guidance Counselor',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'GUIDANCE_COUNSELOR' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'GUIDANCE_COUNSELOR' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  guidanceCounselorEmail: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Guidance Counselor Email',
    path: 'pointPeople',
    calculation (student) {
      // This logic must match ImUser#getEffectiveEmail function
      const gafeEmail = _.result(_.find(student.pointPeople, { type: 'GUIDANCE_COUNSELOR' }), 'user.gafeEmail');
      const doeEmail = _.result(_.find(student.pointPeople, { type: 'GUIDANCE_COUNSELOR' }), 'user.doeEmail');
      return gafeEmail || doeEmail;
    },
  },
  attendancePointPerson: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Attendance Point Person',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'ATTENDANCE' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'ATTENDANCE' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  academicPointPerson: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Academic Point Person',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'ACADEMIC' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'ACADEMIC' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  creditsPointPerson: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Credits Point Person',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'CREDITS' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'CREDITS' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  regentsPointPerson: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Regents Point Person',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'REGENTS' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'REGENTS' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  postsecondaryPointPerson: {
    tag: 'Point people',
    tags: ['Point people'],
    humanName: 'Postsecondary Point Person',
    path: 'pointPeople',
    calculation (student) {
      const firstName = _.result(_.find(student.pointPeople, { type: 'POSTSECONDARY' }), 'user.firstName') || '';
      const lastName = _.result(_.find(student.pointPeople, { type: 'POSTSECONDARY' }), 'user.lastName') || '';
      return `${firstName} ${lastName}`;
    },
  },
  pbatEnglish: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'PBAT: English',
    path: 'regentsDetails.transcript',
    calculation (student) {
      return _.result(_.find(student.regentsDetails.transcript, { examName: 'PBAT ELA' }), 'mark.string');
    },
  },
  pbatLote: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'PBAT: LOTE',
    path: 'regentsDetails.transcript',
    calculation (student) {
      return _.result(_.find(student.regentsDetails.transcript, { examName: 'PBAT LOTE' }), 'mark.string');
    },
  },
  pbatMath: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'PBAT: Math',
    path: 'regentsDetails.transcript',
    calculation (student) {
      return _.result(_.find(student.regentsDetails.transcript, { examName: 'PBAT Math' }), 'mark.string');
    },
  },
  pbatScience: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'PBAT: Science',
    path: 'regentsDetails.transcript',
    calculation (student) {
      return _.result(_.find(student.regentsDetails.transcript, { examName: 'PBAT Science' }), 'mark.string');
    },
  },
  pbatSocialStudies: {
    tag: 'Regents',
    tags: ['Regents'],
    humanName: 'PBAT: Social Studies',
    path: 'regentsDetails.transcript',
    calculation (student) {
      return _.result(_.find(student.regentsDetails.transcript, { examName: 'PBAT SS' }), 'mark.string');
    },
  },
  studentSupports: {
    tag: 'Student Supports',
    tags: ['Student Supports'],
    humanName: 'Student Supports',
    path: 'join_student_supports',
    calculation (student) {
      return student;
    },
  },
  pastStudentSupports: {
    tag: 'Past Student Supports',
    tags: ['Past Student Supports'],
    humanName: 'Past Student Supports',
    path: 'join_student_supports',
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  annualPostsecondaryPlan: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Annual Postsecondary Plan',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  professionalEmail: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Professional Email',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  finalDecisionConversation: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Final Decision Conversation',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  finalDecision: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Final Decision',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  completedTransitionPlan: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Completed Transition Plan',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  finalizedPersonalStatement: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Finalized Personal Statement',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  finalizedLettersOfRecommendation: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Finalized Letters of Recommendation',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  collegeFair: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'College Fair',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  createdPostsecondaryList: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Created Postsecondary List',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  eopFinancialEligibilityConfirmation: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'EOP Financial Eligibility Confirmation',
    path: 'eopFinancialEligibility',
    isDeprecated: true,
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  cunyApplicationFeeSubmission: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'CUNY Application Fee Submission',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  completedSummerJob: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Completed Summer Job',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  finalizedResume: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Finalized Resume',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  coverLetter: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Cover Letter',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  linkedInProfile: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'LinkedIn Profile',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  employabilitySkillsProfile: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Employability Skills Profile',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  annualCareerInterestSurvey: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Annual Career Interest Survey',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  appliedForSummerJob: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Applied For Summer Job',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  validPictureId: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Valid Picture ID',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  workingPapers: {
    tag: 'Postsecondary',
    tags: ['Postsecondary, Milestone'],
    humanName: 'Working Papers',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  satSuperscore: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'SAT Superscore',
    path: 'postsecondary.SAT.superscore',
    calculation (student) {
      return student;
    },
  },
  finalDecisionCommitment: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Final Decision Commitment',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  committedToSpecialProgram: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Committed to Special Program',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  'fafsaApplicationStatus': {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'FAFSA Application Status',
    path: 'hesc.officialReport.fafsaStatus',
    calculation(student) {
      return student;
    }
  },
  'fafsaSubmissionDate': {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'FAFSA Submission Date',
    path: 'hesc.officialReport.fafsaDateSubmitted',
    calculation(student) {
      return student;
    }
  },
  'tapApplicationStatus': {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'TAP Application Status',
    path: 'hesc.officialReport.tapStatus',
    calculation(student) {
      return student;
    }
  },
  'tapSubmissionDate': {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'TAP Submission Date',
    path: 'hesc.officialReport.tapDateSubmitted',
    calculation(student) {
      return student;
    }
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  activityListWithStatus: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Activity List with Status',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  activitiesUpcomingOrInProgress: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Activities Upcoming or In Progress',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  activitiesComplete: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Activities Complete',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  activitiesIncomplete: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Activities Incomplete',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  activitiesNeedingConfirmation: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Activities Needing Confirmation',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  postsecListPlansToApply: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Postsec List: Plans to Apply',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  postsecListApplied: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Postsec List: Applied',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  postsecListResponses: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Postsec List: Responses',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  postsecListAcceptances: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Postsec List: Acceptances',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  postsecListCollegeList: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Postsec List: College List',
    path: 'join_student_paths',
    calculation (student) {
      return student;
    },
  },
  postsecListCareerList: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Postsec List: Career List',
    path: 'join_student_paths',
    calculation (student) {
      return student;
    },
  },
  appliedToCunySchools: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Applied to CUNY Schools',
    path: 'join_paths',
    calculation (student) {
      return student;
    },
  },
  appliedToSunySchools: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Applied to SUNY Schools',
    path: 'join_paths',
    calculation (student) {
      return student;
    },
  },
  appliedToPrivateNonProfitSchools: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Applied to Private Non-Profit Schools',
    path: 'join_paths',
    calculation (student) {
      return student;
    },
  },
  appliedToForProfitSchools: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Applied to For Profit Schools',
    path: 'join_paths',
    calculation (student) {
      return student;
    },
  },
  appliedToPublicOutOfStateSchools: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Applied to Public (Out of State) Schools',
    path: 'join_paths',
    calculation (student) {
      return student;
    },
  },
  committedInstitutionType: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Committed Institution Type',
    path: 'join_paths',
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  milestonesCompleted: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Milestones Completed',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  milestonesIncomplete: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Milestones Incomplete',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // This column is deprecated and can be safely dleted from the FSM
  // after this card is in production: https://newvisions.atlassian.net/browse/POS-917
  // JIRA card to track removal: https://newvisions.atlassian.net/browse/POS-1213
  activityHoursCompleted: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Activity Hours Completed',
    path: 'join_calculated_fields',
    isDeprecated: true,
    calculation (student) {
      return student;
    },
  },
  // Hidden as a part of PFD-11066, left as a comment in the case we need to unhide
  // learningPreference: {
  //   tag: 'Basic Info',
  //   tags: ['Basic Info', 'Learning Preference'],
  //   humanName: 'Learning Preference',
  //   path: 'studentDetails.learningPreference',
  //   calculation (student) {
  //     return student;
  //   },
  // },
  inPersonYtdAttendance: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'In-person YTD Attendance',
    path: 'att.ytdInPersonRate',
    calculation (student) {
      return student;
    },
  },
  remoteYtdAttendance: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Remote YTD Attendance',
    path: 'att.ytdOnlineRate',
    calculation (student) {
      return student;
    },
  },
  inPersonAttendanceLast20Days: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'In-person Attendance Last 20 Days',
    path: 'att.last20.pctPresInPerson.pct',
    calculation (student) {
      return student;
    },
  },
  remoteAttendanceLast20Days: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Remote Attendance Last 20 Days',
    path: 'att.last20.pctPresRemote.pct',
    calculation (student) {
      return student;
    },
  },
  hasInternetAccess: {
    tag: 'Device Access',
    tags: ['Device Access'],
    humanName: 'Has Internet Access',
    path: 'deviceAccess.hasInternetAccess',
    calculation (student) {
      return student;
    },
  },
  totalGoogleClassroomAssignmentsTurnedInReclaimedOrGraded: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Total Google Classroom Assignments Turned In, Reclaimed, or Graded',
    path: 'googleClassroom.totalEngagedAssignments',
    calculation (student) {
      return student;
    },
  },
  attendancePreviousSchoolDay: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Attendance Previous School Day',
    path: 'att.prevSchoolDay',
    calculation (student) {
      return student;
    },
  },
  hasSmartphoneAccess: {
    tag: 'Device Access',
    tags: ['Device Access'],
    humanName: 'Has Smartphone Access',
    path: 'deviceAccess.hasSmartPhoneAccess',
    calculation (student) {
      return student;
    },
  },
  countOfInPersonDaysAbsentYtd: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of In-person Days Absent: YTD',
    path: 'att.currSy.absInPerson',
    calculation (student) {
      return student;
    },
  },
  hasComputerAccess: {
    tag: 'Device Access',
    tags: ['Device Access'],
    humanName: 'Has Computer Access',
    path: 'deviceAccess.hasComputerAccess',
    calculation (student) {
      return student;
    },
  },
  deviceRequestStatus: {
    tag: 'Device Access',
    tags: ['Device Access'],
    humanName: 'Device Request Status',
    path: 'deviceAccess.requestStatus',
    calculation (student) {
      return student;
    },
  },
  hasDoeDevice: {
    tag: 'Device Access',
    tags: ['Device Access'],
    humanName: 'Has DOE Device',
    path: 'deviceAccess.requestStatus',
    calculation (student) {
      return student;
    },
  },
  countOfRemoteDaysAbsentYtd: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Remote Days Absent: YTD',
    path: 'att.currSy.absRemote',
    calculation (student) {
      return student;
    },
  },
  countOfInPersonDaysAbsentLast5: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of In-person Days Absent: Last 5',
    path: 'att.last5.absInPerson',
    calculation (student) {
      return student;
    },
  },
  countOfRemoteDaysAbsentLast5: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Remote Days Absent: Last 5',
    path: 'att.last5.absRemote',
    calculation (student) {
      return student;
    },
  },
  countOfInPersonDaysAbsentLast10: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of In-person Days Absent: Last 10',
    path: 'att.last10.absInPerson',
    calculation (student) {
      return student;
    },
  },
  countOfRemoteDaysAbsentLast10: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Remote Days Absent: Last 10',
    path: 'att.last10.absRemote',
    calculation (student) {
      return student;
    },
  },
  countOfInPersonDaysAbsentLast20: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of In-person Days Absent: Last 20',
    path: 'att.last20.absInPerson',
    calculation (student) {
      return student;
    },
  },
  countOfRemoteDaysAbsentLast20: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Remote Days Absent: Last 20',
    path: 'att.last20.absRemote',
    calculation (student) {
      return student;
    },
  },
  countOfDaysAbsentThisTerm: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Days Absent: This Term',
    path: 'att.currTerm.abs',
    calculation (student) {
      return student;
    },
  },
  countOfInPersonDaysAbsentThisTerm: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of In-person Days Absent: This Term',
    path: 'att.currTerm.absInPerson',
    calculation (student) {
      return student;
    },
  },
  countOfRemoteDaysAbsentThisTerm: {
    tag: 'Attendance',
    tags: ['Attendance'],
    humanName: 'Count of Remote Days Absent: This Term',
    path: 'att.currTerm.absRemote',
    calculation (student) {
      return student;
    },
  },
  cdosHoursEarned: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'CDOS Hours Earned',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  wblHoursEarned: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'WBL Hours Earned',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  nonWblHoursEarned: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Non-WBL Hours Earned',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  plannedCdosHours: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Planned CDOS Hours',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  plannedWblHours: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Planned WBL Hours',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  plannedNonWblHours: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'Planned Non-WBL Hours',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  cdosCoursesHoursEarned: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'CDOS Courses Hours Earned',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  cdosActivityHoursEarned: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'CDOS Activity Hours Earned',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  cdosActivitiesCompleted: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'CDOS Activities Completed',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  cdosCoursesCompleted: {
    tag: 'Postsecondary',
    tags: ['Postsecondary'],
    humanName: 'CDOS Courses Completed',
    path: 'join_calculated_fields',
    calculation (student) {
      return student;
    },
  },
  daysEngagedWithGoogleMeetOverLast5Days: {
    tag: 'Google Meet',
    tags: ['Google Meet', 'Google Hangout'],
    humanName: 'Days Engaged with Google Meet over Last 5 Days',
    path: 'googleHangout',
    calculation (student) {
      return student;
    },
  },
  percentOfDaysEngagedWithGoogleMeet: {
    tag: 'Google Meet',
    tags: ['Google Meet', 'Google Hangout'],
    humanName: 'Percent of Days Engaged with Google Meet',
    path: 'googleHangout',
    calculation (student) {
      return student;
    },
  },
  meetDevicesUsed: {
    tag: 'Google Meet',
    tags: ['Google Meet', 'Google Hangout'],
    humanName: 'Meet Devices Used',
    path: 'googleHangout.devices',
    calculation (student) {
      return student;
    },
  },
  daysEngagedWithGoogleClassroomOverLast5Days: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Days Engaged with Google Classroom over Last 5 Days',
    path: 'googleClassroom.remoteEngagementLast5',
    calculation (student) {
      return student;
    },
  },
  percentOfDaysEngagedWithGoogleClassroom: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Percent of Days Engaged with Google Classroom',
    path: 'googleClassroom.remoteEngagement',
    calculation (student) {
      return student;
    },
  },
  totalGoogleClassroomAssignments: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Total Google Classroom Assignments',
    path: 'googleClassroom.totalAssignments',
    calculation (student) {
      return student;
    },
  },
  totalEnrolledGoogleClassroomCourses: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Total Enrolled Google Classroom Courses',
    path: 'googleClassroom',
    calculation (student) {
      return student;
    },
  },
  googleClassroomStudentEmail: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Google Classroom Student Email',
    path: 'googleClassroom.studentEmail',
    calculation (student) {
      return student;
    },
  },
  percentOfDaysEngagedWithGoogleMeetOverLast5Days: {
    tag: 'Google Meet',
    tags: ['Google Meet', 'Google Hangout'],
    humanName: 'Percent of Days Engaged with Google Meet Over Last 5 Days',
    calculation (doc) {
      return doc;
    },
  },
  percentOfDaysEngagedWithGoogleClassroomOverLast5Days: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Percent of Days Engaged with Google Classroom Over Last 5 Days',
    calculation (doc) {
      return doc;
    },
  },
  googleClassroomAssignmentsEngagedOverLast5Days: {
    tag: 'Google Classroom',
    tags: ['Google Classroom'],
    humanName: 'Google Classroom Assignments Engaged Over Last 5 Days',
    calculation (doc) {
      return doc;
    },
  },
  msGpa: {
    tag: 'MS GPA',
    tags: ['MS GPA', 'GPA'],
    humanName: 'MS GPA',
    calculation (doc) {
      return doc;
    },
  },
  ellStatus: {
    tag: 'Basic info',
    tags: ['Basic info'],
    humanName: 'ELL status',
    path: 'ellDetails.ellStatus',
    calculation (student) {
      return _.get(student, this.path);
    },
  },
  homeAddress: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Home Address',
    path: 'atsContactDetails.home',
    calculation(student) {
     return _.get(student, this.path);
    }
  },
  adult1: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Adult 1',
    path: 'atsContactDetails.adult1',
    calculation(student) {
      return _.get(student, this.path);
    }
  },
  adultPhone1: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Adult Phone 1',
    path: 'atsContactDetails.adult1.phone',
    calculation(student) {
        return _.get(student, this.path);
    }
  },
  adult2: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Adult 2',
    path: 'atsContactDetails.adult2',
    calculation(student) {
      return _.get(student, this.path);
    }
  },
  adultPhone2: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Adult Phone 2',
    path: 'atsContactDetails.adult2.phone',
    calculation(student) {
        return _.get(student, this.path);
    }
  },
  adult3: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Adult 3',
    path: 'atsContactDetails.adult3',
    calculation(student) {
      return _.get(student, this.path);
    }
  },
  adultPhone3: {
    tag: 'Contact info',
    tags: ['Basic info'],
    humanName: 'Adult Phone 3',
    path: 'atsContactDetails.adult3.phone',
    calculation(student) {
        return _.get(student, this.path);
    }
  },
  ...FLATTENED_STUDENT_MAP_FOR_MAP_GROWTH,
  ...STAR_CHARTER_FLATTENED_STUDENT_MAP,
  ...FLATTENED_STUDENT_MAP_FOR_DESSA,
  ...ACADIENCE_FLATTENED_STUDENT_MAP,
  ...IREADY_FLATTENED_STUDENT_MAP,
};

export const addDynamicColumns = (schoolAssessments?, toggleNycTMvp?): IFlattenedStudentMap => {
  if (toggleNycTMvp) {
    addStudentAssessmentColumns(schoolAssessments);
    return { ...FlattenedStudentMap, ...F_AND_P_FLATTENED_STUDENT_MAP };
  }
  return FlattenedStudentMap;
};
