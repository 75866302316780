import { createSelector, createFeatureSelector } from '@ngrx/store';

const getPortalState = createFeatureSelector('PORTAL_STORE');

export const getUsersSlice = createSelector(
  getPortalState,
  (slice: any) => {
    return slice.users;
  },
);

export const getUsersLoadedStatus = createSelector(
  getUsersSlice,
  (state: any) => {
    return state.loaded;
  },
);

export const getUsersLoadingStatus = createSelector(
  getUsersSlice,
  (state: any) => {
    return state.loading;
  },
);

export const getUsersEntities = createSelector(
  getUsersSlice,
  (state: any) => {
    return state.userEntities;
  },
);

export const getUsersList = createSelector(
  getUsersSlice,
  (state: any) => Object.values(state.userEntities),
);
