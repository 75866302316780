import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth } from './../../auth/auth.service';

@Injectable()
/* istanbul ignore next */
export class TokenInterceptor implements HttpInterceptor {
  constructor(private auth: Auth) {}

  // we do not want to send Auth token to aws
  // otherwise, aws will return a 403 forbidden (CM).
  readonly AWS_URL_FRAGMENT = 's3.amazonaws';

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = request && request.url;
    if (url && !url.includes(this.AWS_URL_FRAGMENT)) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.getIdToken()}`,
        },
      });
    }
    return next.handle(request);
  }
}
