import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

import { SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { ISchool, ISchoolEditable } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { getSchool, getSchoolUpdatedResult } from 'Src/ng2/store/selectors/school-selectors';
import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { IGroupData, IRowData } from 'Src/ng2/shared/models/list-models';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { UpdateSelectedStudentIds } from 'Src/ng2/store';
import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IPageHeaderMeta } from '../../header/page-header/page-header-meta.interface';
import { MasterProgramSettingsDataService } from './master-program-settings-data.service';

@Component({
  selector: 'master-program-settings',
  templateUrl: './master-program-settings.component.html',
  styleUrls: ['./master-program-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class MasterProgramSettingsComponent implements OnInit, OnDestroy {
  public currentSchool: ISchool;
  public schoolSlice: Subscription;
  public schoolStatusAfterUpdate: Subscription;
  public groupData$: BehaviorSubject<IGroupData> = new BehaviorSubject(null);
  public option$: BehaviorSubject<IDropdownOption[]> = new BehaviorSubject(null);
  public batchActionsMode$= new BehaviorSubject<boolean>(false);
  public bulkUpdateCourses: string[] = [];
  public batchActionsHash: any;
  public numberOfCoursesSelected: Number = this.bulkUpdateCourses.length;
  public madLibSelection: string;
  public columns: string[] = ['COURSE_CODE', 'SUBJECT', 'COURSE_NAME', 'TEACHER', 'CAPACITY', 'REGENTS_PREP', 'REGENTS_CULM'];
  public pageHeaderMeta: IPageHeaderMeta;
  public termMetaData: string;

  constructor (
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private masterProgramSettingsDataService: MasterProgramSettingsDataService,
    private batchActionsService: BatchActionsService,
    private store: Store<any>,
    private modalsService: ModalsService,
    private snackBarService: SnackBarService,
  ) {
    document.title = 'Settings - Master program | Portal';
  }

  ngOnInit (): void {
    this.madLibSelection = this.activatedRoute.snapshot.queryParams.grouping
      ? this.activatedRoute.snapshot.queryParams.grouping
      : 'all_courses';

    this.schoolSlice = this.getCurrentSchool()
      .pipe(
        tap((school) => {
          this.currentSchool = school;
          this.nextGroupData(this.currentSchool);
          this.nextOptions();
        })).subscribe();

    this.termMetaData = this.getTermMetaData();
    this.pageHeaderMeta = {
      title: 'Master Program',
      subTitle: null,
      infoIcon: {
        tooltipData: `Displaying courses for ${this.termMetaData}`,
      },
      textboxPlaceholder: 'Search by course code',
    };

    this.batchActionsHash = this.batchActionsService.initializeCoursesBatchActionsHash();
    this.resetBatchActions();
  }

  ngOnDestroy ():void {
    document.title = 'Portal by New Visions';
    this.schoolSlice.unsubscribe();
    if (this.schoolStatusAfterUpdate) this.schoolStatusAfterUpdate.unsubscribe();
  }

  private getCurrentSchool (): Observable<ISchool> {
    return this.store.select(getSchool);
  }

  private nextGroupData (school): void {
    let grouping;
    if (this.madLibSelection === 'All courses') {
      grouping = 'all_courses';
    } else {
      grouping = this.madLibSelection;
    }
    const groupData: IGroupData = this.masterProgramSettingsDataService.getMasterProgramGroupData(school, grouping);

    this.groupData$.next(groupData);
  }

  private nextOptions (): void {
    const options: IDropdownOption[] = this.masterProgramSettingsDataService.getDropdownOptions();
    this.option$.next(options);
  }

  public onMadLibChange (e: string): void {
    this.madLibSelection = e;
    this.updateUrl();

    this.nextGroupData(this.currentSchool);
  }

  private updateUrl (): void {
    let grouping;

    if (this.madLibSelection === 'All courses') {
      grouping = null;
    } else {
      grouping = this.madLibSelection;
    }

    const extras = {
      relativeTo: this.activatedRoute,
      queryParams: { grouping },
      replaceUrl: true,
      queryParamsHandling: 'merge' as QueryParamsHandling,
    };

    this.router.navigate([], extras);
  }

  private getTermMetaData () {
    return this.masterProgramSettingsDataService.getTermMetaData();
  }

  public onClickedBatchActions (): void {
    this.batchActionsMode$.next(!this.batchActionsMode$.getValue());
    this.resetBatchActions();
  }

  public sendToRemovePrep (): void {
    const coursesId: string[] = this.bulkUpdateCourses;
    const allCurrentUserPrepCourses: ISchoolEditable['userEnteredPrepCourses'] = this.currentSchool.userEnteredPrepCourses;
    const prepsData = {
      coursesId,
      allCurrentUserPrepCourses,
      schoolId: this.currentSchool._id,
    };
    this.modalsService
      .openRemovePrepInMasterProgramModal(prepsData)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onPatchSchoolResult();
        }
      });
  }

  public sendToAssignPrep (): void {
    const coursesId: string[] = this.bulkUpdateCourses;
    const allCurrentUserPrepCourses: ISchoolEditable['userEnteredPrepCourses'] = this.currentSchool.userEnteredPrepCourses;
    const prepsData = {
      coursesId,
      allCurrentUserPrepCourses,
      schoolId: this.currentSchool._id,
    };
    this.modalsService
      .openAssignPrepInMasterProgramModal(prepsData)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onPatchSchoolResult();
        }
      });
  }

  public onBatchActionCourseSelected ($event: { updateAll: boolean; data: IRowData[]; level: 'SECTION' | 'ROW' }) {
    const batchData = this.batchActionsService.formatBatchActionEvt($event);
    this.batchActionsHash.updateCoursesIds(batchData);
    const coursesIds = this.batchActionsHash.getCoursesIds();

    this.bulkUpdateCourses = coursesIds.map((id) => {
      return id.studentId; // studentId because we are reusing exiting batch-actions service
    });

    this.numberOfCoursesSelected = this.bulkUpdateCourses.length;
    this.store.dispatch(new UpdateSelectedStudentIds(coursesIds));
  }

  private resetBatchActions (): void {
    this.batchActionsHash.reset();
    this.bulkUpdateCourses = [];
    this.numberOfCoursesSelected = this.bulkUpdateCourses.length;
    this.store.dispatch(new UpdateSelectedStudentIds([]));
  }

  private onPatchSchoolResult ():void {
    this.schoolStatusAfterUpdate = this.store.select(getSchoolUpdatedResult)
      .pipe(
        tap((updated: boolean) => {
          this.showToast(updated);
        })).subscribe();
  }

  private showToast (updated: boolean): void {
    // Explicit check for true / false (values could be null, undefined)
    if (updated === false) {
      this.snackBarService.showToastWithCloseButton({ toastText: 'Unable to update the list of courses. Please try again.' });
    }
    if (updated === true) {
      const currentBatchActionsValue = this.batchActionsMode$.getValue();
      if (currentBatchActionsValue) {
        this.batchActionsMode$.next(false);
      }
      this.resetBatchActions();
      this.updateUrl();
      this.nextGroupData(this.currentSchool);
      this.nextOptions();
      this.snackBarService.showToastLowerLeftCorner({ toastText: 'Batch action applied to the selected courses.' });
    }
  }
}
