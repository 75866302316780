import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { IStudent } from '../../../typings/interfaces/student.interface';
import { BaseModalComponent } from '../../base-modal.component';
import { EOP_MILESTONE_MODAL_CONFIG } from './eop-milestone-modal.config';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
export interface IEOPMilestoneModalcomponentData {
  title: string;
  message: string;
}

@Component({
  templateUrl: './eop-milestone-modal.component.html',
  styleUrls: ['./eop-milestone-modal.component.scss'],
  selector: 'eop-milestone-modal',
  encapsulation: ViewEncapsulation.None,
})
export class EOPMilestoneModalComponent extends BaseModalComponent implements OnInit {
  // Modal Configurations
  title: string = 'Confirm EOP eligibility';
  statusPlaceholder = 'Select a status';
  isProfileMode: boolean = true;
  readonly buttons = EOP_MILESTONE_MODAL_CONFIG.buttons;
  readonly eopOptions: IDropdownOption[] = [
    { key: '—', human: '—' },
    { key: 'Eligible', human: 'Eligible' },
    { key: 'Not eligible', human: 'Not eligible' },
  ];

  student: IStudent;
  eopEligibility: string;
  currentSelection: string;
  milestoneId: string;

  constructor (
    dialogRef: MatDialogRef<EOPMilestoneModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store<any>,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.student = this.data.student;
    this.eopEligibility = this.student.eopFinancialEligibility.status;
    this.currentSelection = this.eopEligibility;
  }

  onConfirm (): void {
    super.close(this.currentSelection);
  }

  onCancel (): void {
    super.close();
  }

  updateSelection (selection: string): void {
    this.currentSelection = selection;
  }

  isOptionActive (option: string): boolean {
    return this.currentSelection === option;
  }

  validateChoice (): boolean {
    return this.eopEligibility === this.currentSelection;
  }
}
