import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvButtonModule } from '../nv-button/nv-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvCardComponent } from './nv-card.component';

@NgModule({
  imports: [CommonModule, NvButtonModule, NvIconModule],
  declarations: [NvCardComponent],
  exports: [NvCardComponent],
})
export class NvCardModule {}
