import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { BaseModalModule } from '../base-modal.module';
import { AssignSupportModalComponent } from './assign-support-modal.component';
import { AssignSupportModalService } from './assign-support-modal.service';
import { NvTextboxModule } from 'Src/nvps-libraries/design/nv-textbox/nv-textbox.module';
import { NvFormFieldModule } from 'Src/nvps-libraries/design/nv-form-field/nv-form-field.module';
import { NvDateRangePickerModule } from 'Src/nvps-libraries/design/nv-date-range-picker/nv-date-range-picker.module';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';

@NgModule({
  declarations: [AssignSupportModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    BaseModalModule,
    MatDialogModule,
    NvTextboxModule,
    NvFormFieldModule,
    NvDateRangePickerModule,
    NvButtonModule,
  ],
  exports: [AssignSupportModalComponent],
  providers: [AssignSupportModalService],
})
export class AssignSupportModalModule {}
