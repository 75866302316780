import { GraphQLStudentDessaHelperService } from './graphql-helpers/student-dessa/student-dessa-queries.service';
import { GraphQLStudentIReadyHelperService } from './graphql-helpers/student-iready/student-iready-queries.service';
import { StudentSet } from 'Src/ng2/shared/services/student-set/student-set.service';
import { NgModule } from '@angular/core';
import { Auth } from './../auth/auth.service';
import { UtilitiesService } from './../services/utilities/utilities.service';
import { ApiHelpers } from './api-helpers';
import { ApiService } from './api-service/api-service';
import { CsvExporterService } from './csv-exporter/csv-exporter.service';
import { CsvImporterService } from './csv-importer/csv-importer-service';
import { DashboardCircleService } from './dashboard-circle-service/dashboard-circle-service';
import { DateHelpers } from './date-helpers/date-helpers.service';
import { ErrorToaster } from './error-toaster/error-toaster.service';
import { EventBus } from './event-bus/event-bus';
import { FormValidatorsService } from './form-validators/form-validators.service';
import { GraphQLActivitiesHelperService } from './graphql-helpers/activities/activities-queries.service';
import { GraphQLDataLoadsHelperService } from './graphql-helpers/data-loads/data-loads-queries.service';
import { GraphQLSchoolAssessmentsHelperService } from './graphql-helpers/school-assessments/school-assessments-queries.service';
import { GraphQLPartnerHelperService } from './graphql-helpers/partners/partner-queries.service';
import { GraphQLClusterHelperService } from './graphql-helpers/clusters/cluster-queries.service';
import { GraphQlShelterClusterUserHelperService } from './graphql-helpers/user-management/cluster-user/shelter-cluster-user-queries.service';
import { GraphQLStudentActivitiesHelperService } from './graphql-helpers/student-activities/student-activities-queries.service';
import { GraphQLStudentAssessmentsHelperService } from './graphql-helpers/student-assessments/student-assessments-queries.service';
import { GraphQLSchoolClusterUserHelperService } from './graphql-helpers/user-management/cluster-user/school-cluster-user-queries.service';
import { ImModelsModule } from './im-models/im-models.module';
import { ListSummaryGroupings } from './list-summary-groupings/list-summary-groupings.service';
import { AssessmentsGrouping } from './mad-lib-services/grouping-functions/assessments/assessments-groupings';
import { CourseCodeGrouping } from './mad-lib-services/grouping-functions/course-code/course-code';
import { CoursePriorityGrouping } from './mad-lib-services/grouping-functions/course-priority/course-priority';
import { CourseRollupGrouping } from './mad-lib-services/grouping-functions/course-rollup/course-rollup';
import { HasOrNeedsSupportForCourseGrouping } from './mad-lib-services/grouping-functions/has-or-needs-support-for-course/has-or-needs-support-for-course';
import { HasOrNeedsSupportForSubjGrouping } from './mad-lib-services/grouping-functions/has-or-needs-support-for-subj/has-or-needs-support-for-subj';
import { ActivitiesGrouping } from './mad-lib-services/grouping-functions/postsec-groupings/activities-groupings';
import { SubjectRollupGrouping } from './mad-lib-services/grouping-functions/subject-rollup/subject-rollup';
import { TeacherRollupGrouping } from './mad-lib-services/grouping-functions/teacher-rollup/teacher-rollup';
import { MadLibGroupings } from './mad-lib-services/mad-lib-groupings';
import { MadLibHelpers } from './mad-lib-services/mad-lib-helpers';
import { MadLibSorting } from './mad-lib-services/mad-lib-sorting';
import { NotificationMessageParser } from './notification-message-parser/notification-message-parser.service';
import { ObjectCache } from './object-cache/object-cache.service';
import { ImCachedObject } from './im-models/im-cached-object.service';
import { PointPeopleService } from './point-people/point-people.service';
import { PortalConfig } from './portal-config';
import { PrevStateService } from './prev-state/prev-state.service';
import { PusherService } from './pusher/pusher-service';
import { PusherListeners } from './pusher/pusher-listeners';
import { RollupGroupingFooterService } from './rollup-grouping-footer-service/rollup-grouping-footer-service';
import { PathParserService } from './side-nav/path-parser.service';
import { SideNavService } from './side-nav/side-nav.service';
import { StudentCollectionsMergeService } from './student-collections-merge/student-collections-merge.service';
import { StudentFetchServicesModule } from './student-fetch/student-fetch.services.module';
import { StudentPanelPersistance } from './student-panel-persistance/student-panel-persistance.service';
import { ToggleService } from './toggle/toggle.service';
import { UserAgentService } from './user-agent.service.ts/user-agent.service';
import { UserRolePermissionsForModelService } from './user-role-permissions-for-model/user-role-permissions-for-model.service';
import { WebStorageServicesModule } from './web-storage/web-storage.services.module';
import { WindowRef } from './windowRef';
import { DeprecatedRouteService } from './route-services/deprecated-route.service';
import { RollupGroupingStackedBarService } from './rollup-grouping-stacked-bar-service/rollup-grouping-stacked-bar-service';
import { GraphQLSchoolUserHelperService } from './graphql-helpers/user-management/school-user/school-user-queries.service';
import { GraphQLStudentMapGrowthHelperService } from './graphql-helpers/student-map-growth/student-map-growth-queries.service';
import { GraphQLStudentAcadienceHelperService } from './graphql-helpers/student-acadience/student-acadience-queries.service';
import { GraphQLProgramChangesHelperService } from './graphql-helpers/course-diffs-and-gap-plans/program-changes-queries.service';
import { GraphQLGapPlansHelperService } from './graphql-helpers/gap-plans/gap-plans-queries.service';
import {GraphQLSELSupportHelperService} from './graphql-helpers/sel-supports/sel-supports-queries.service'
import { GraphQlStudentPathsHelperService } from './graphql-helpers/student-paths/student-paths-queries.service';
import { GraphQLStudentDocLogsHelperService } from './graphql-helpers/student-docLogs/student-docLogs-queries.service';
import { GraphQLCsvDownloadsService } from './graphql-helpers/csv-downloads/csv-downloads.queries.service';
import { GraphQlShelterSuccessMentorHelperService } from './graphql-helpers/shelter/success-mentor/success-mentor-queries.service';
import { GraphQLNvConfigsHelperService } from './graphql-helpers/nv-configs/nv-configs.queries.service';
import { GraphQLGridViewHelperService } from './graphql-helpers/gridviews/gridviews-queries.service';
import { UrlPathService } from './url-path-service/url-path.service';
import { GraphQLExperiencesHelperService } from './graphql-helpers/experiences/experiences-queries.service';

@NgModule({
  imports: [WebStorageServicesModule, StudentFetchServicesModule, ImModelsModule],
  providers: [
    Auth,
    DateHelpers,
    ApiService,
    ApiHelpers,
    EventBus,
    WindowRef,
    MadLibGroupings,
    // individual groupings
    ActivitiesGrouping,
    CourseRollupGrouping,
    TeacherRollupGrouping,
    SubjectRollupGrouping,
    HasOrNeedsSupportForSubjGrouping,
    HasOrNeedsSupportForCourseGrouping,
    CoursePriorityGrouping,
    CourseCodeGrouping,
    ListSummaryGroupings,
    AssessmentsGrouping,

    NotificationMessageParser,
    ErrorToaster,
    UserRolePermissionsForModelService,

    MadLibHelpers,
    MadLibSorting,
    PusherService,
    PusherListeners,
    PortalConfig,
    ToggleService,
    SideNavService,
    StudentPanelPersistance,
    UserAgentService,
    PrevStateService,
    UtilitiesService,
    UrlPathService,
    PathParserService,
    FormValidatorsService,
    PointPeopleService,
    StudentCollectionsMergeService,
    GraphQLDataLoadsHelperService,
    GraphQLStudentAssessmentsHelperService,
    GraphQLSchoolAssessmentsHelperService,
    GraphQLStudentActivitiesHelperService,
    GraphQLActivitiesHelperService,
    GraphQLSchoolClusterUserHelperService,
    GraphQLPartnerHelperService,
    GraphQLClusterHelperService,
    GraphQlShelterClusterUserHelperService,
    GraphQlShelterSuccessMentorHelperService,
    GraphQLPartnerHelperService,
    GraphQLClusterHelperService,
    GraphQLStudentMapGrowthHelperService,
    GraphQLStudentAcadienceHelperService,
    DeprecatedRouteService,
    GraphQLSchoolUserHelperService,
    GraphQLGapPlansHelperService,
    GraphQLStudentDessaHelperService,
    GraphQLStudentIReadyHelperService,
    GraphQLSELSupportHelperService,
    GraphQlStudentPathsHelperService,
    GraphQLExperiencesHelperService,
    // BackgroundJob,
    CsvExporterService,
    CsvImporterService,
    ImCachedObject,
    ObjectCache,
    RollupGroupingFooterService,
    RollupGroupingStackedBarService,
    DashboardCircleService,
    GraphQLProgramChangesHelperService,
    GraphQLStudentDocLogsHelperService,
    GraphQLCsvDownloadsService,
    GraphQLNvConfigsHelperService,
    GraphQLGridViewHelperService,
  ],
})
export class SharedServicesModule {}
