import { reduceToStudentPathName } from './../../../../school/lists/postsecondary-panel-lists/postsecondary-panel-list-data/student-path-data-helpers';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { find, map, sortBy } from 'lodash';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { PATH_CATEGORY_KEYS, StudentPathStatuses } from '../../../constants/student-paths.constant';
import { IStudent } from '../../../typings/interfaces/student.interface';
import { IStudentPath } from '../../../typings/interfaces/studentPaths.interface';
import { BaseModalComponent } from '../../base-modal.component';
import { FINAL_DECISION_MILESTONE_MODAL_CONFIG } from './final-decision-milestone-modal.config';

const STATUS_DROPDOWN_OPTIONS = [
  {
    key: StudentPathStatuses.COMMITTED,
    human: StudentPathStatuses.COMMITTED,
  },
  {
    key: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
    human: StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP,
  },
];

@Component({
  templateUrl: './final-decision-milestone-modal.component.html',
  styleUrls: ['./final-decision-milestone-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  selector: 'final-decision-milestone-modal',
})
export class FinalDecisionMilestoneModalComponent extends BaseModalComponent implements OnInit {
  // Modal Configurations
  title: string = 'Confirm final decision';
  isProfileMode: boolean = true;
  readonly buttons = FINAL_DECISION_MILESTONE_MODAL_CONFIG.buttons;

  student: IStudent;
  milestoneId: string;
  currentFinalDecision: string;
  currentStatus: string;
  studentPaths: IStudentPath[];
  studentPathOptions: IDropdownOption[];
  statusOptions: IDropdownOption[];
  committedPath: IStudentPath;
  pathPlaceholder = 'Select a college or career';
  statusPlaceholder = 'Select a status';

  constructor (
    dialogRef: MatDialogRef<FinalDecisionMilestoneModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private store: Store<any>,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    this.student = this.data.student;
    this.studentPaths = this.setStudentPaths(this.data.studentPaths);
    this.setOptions();
  }

  onConfirm (): void {
    // check required because div buttons in template can't be disabled (JE)
    // this will be refactored when all modals are updated to the new modal pattern
    if (this.isChoiceValid()) {
      const studentPath = find(this.studentPaths, (studentPath: IStudentPath) => {
        return studentPath._id === this.currentFinalDecision;
      });
      const parsedFinalPlanStatus =
        this.currentStatus === StudentPathStatuses.COMMITTED
          ? StudentPathStatuses.COMMITTED
          : StudentPathStatuses.COMMITTED_WITH_SPECIAL_OPP;
      const selection = { studentPathId: studentPath._id, status: parsedFinalPlanStatus };
      super.close(selection);
    }
  }

  onCancel (): void {
    super.close();
  }

  isChoiceValid (): boolean {
    return !!this.currentFinalDecision && !!this.currentStatus;
  }

  updateFinalDecision (option: string): void {
    this.currentFinalDecision = option;
  }

  updateStatus (option: string): void {
    this.currentStatus = option;
  }

  setStudentPaths (data): IStudentPath[] {
    return Object.keys(data).map(k => {
      const studentPath = this.data.studentPaths[k];
      const { COMMITTED, COMMITTED_WITH_SPECIAL_OPP } = StudentPathStatuses;
      if (studentPath.status === COMMITTED || studentPath.status === COMMITTED_WITH_SPECIAL_OPP) {
        this.committedPath = studentPath;
      }
      return studentPath;
    });
  }

  createStudentPathOptions (studentPaths: IStudentPath[]): IDropdownOption[] {
    const studentPathOptions = map(studentPaths, studentPath => {
      return {
        key: studentPath._id,
        human: reduceToStudentPathName(studentPath.path),
      };
    });
    return sortBy(studentPathOptions, option => option.human);
  }

  setOptions (): void {
    this.studentPathOptions = this.createStudentPathOptions(this.studentPaths);
    const chosenFinalDecision = find(this.studentPaths, studentPath => {
      const { COMMITTED, COMMITTED_WITH_SPECIAL_OPP } = StudentPathStatuses;
      return studentPath.status === COMMITTED || studentPath.status === COMMITTED_WITH_SPECIAL_OPP;
    });

    this.statusOptions = STATUS_DROPDOWN_OPTIONS;

    if (chosenFinalDecision) {
      this.currentFinalDecision = reduceToStudentPathName(chosenFinalDecision.path);
      this.currentStatus = chosenFinalDecision.status;

      if (chosenFinalDecision.path.pathCategory !== PATH_CATEGORY_KEYS.COLLEGE) this.statusOptions = [STATUS_DROPDOWN_OPTIONS[0]];
    }
  }
}
