/* tslint:disable */
import { Action } from '@ngrx/store';
import { IListSort, IListDimensions } from '../students-actions';

/** POSTSECONDARY_STUDENTS_ACTIONS */
export const LOAD_POSTSECONDARY_STUDENTS = '[Portal] Load Postsecondary Students';
export const LOAD_POSTSECONDARY_STUDENTS_FAIL = '[Portal] Load Postsecondary Students Fail';
export const UPDATE_POSTSECONDARY_STUDENTS = '[Portal] Update Postsecondary Students';
export const UPDATE_POSTSECONDARY_STUDENTS_SORT = '[Portal] Update Postsecondary Students Sort';
export const UPDATE_POSTSECONDARY_STUDENTS_DIMENSIONS = '[Portal] Update Postsecondary Students Dimensions';
// success actions
export const LOAD_POSTSECONDARY_STUDENTS_SUCCESS = '[Portal] Load Postsecondary Students Success';

export const LOAD_POSTSECONDARY_STUDENTS_ = '[Portal] Load Postsecondary Students Success';
export const UPDATE_POSTSECONDARY_STUDENTS_SUCCESS = '[Portal] Update Postsecondary Students Success';
export const UPDATE_POSTSECONDARY_STUDENTS_PROJECTION_SUCCESS =
  '[Portal] Update Postsecondary Students Projection Success';
export const UPDATE_POSTSECONDARY_STUDENTS_FAIL = '[Portal] Update Postsecondary Students Fail';
export const UPDATE_POSTSECONDARY_STUDENTS_PROJECTION_FAIL = '[Portal] Update Postsecondary Projection Fail';
export const BULK_UPDATE_POSTSECONDARY_STUDENTS_SUCCESS = '[Portal] Bulk Update Postsecondary Students Success';
export const BULK_UPDATE_POSTSECONDARY_STUDENTS = '[Portal] Bulk Update Postsecondary Students';
export const BULK_UPDATE_POSTSECONDARY_STUDENTS_FAIL = '[Portal] Bulk Update Postsecondary Students Fail';

// LOAD
export class LoadPostsecondaryStudents implements Action {
  readonly type = LOAD_POSTSECONDARY_STUDENTS;
  constructor(public payload: any) {}
}

export class LoadPostsecondaryStudentsSuccess implements Action {
  readonly type = LOAD_POSTSECONDARY_STUDENTS_SUCCESS;
  constructor(public payload: any) {}
}
export class LoadPostsecondaryStudentsFail implements Action {
  readonly type = LOAD_POSTSECONDARY_STUDENTS_FAIL;
  constructor(public payload: any) {}
}

// UPDATE
export class UpdatePostsecondaryStudents implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS;
  constructor(public payload: any) {}
}

export class UpdatePostsecondaryStudentsSort implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS_SORT;
  constructor(public payload: IListSort) {}
}

export class UpdatePostsecondaryStudentsDimensions implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS_DIMENSIONS;
  constructor(public payload: IListDimensions) {}
}

export class UpdatePostsecondaryStudentsSuccess implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePostsecondaryStudentsProjectionSuccess implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS_PROJECTION_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdatePostsecondaryStudentsFail implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS_FAIL;
  constructor(public payload: any) {}
}

export class UpdatePostsecondaryStudentsProjectionFail implements Action {
  readonly type = UPDATE_POSTSECONDARY_STUDENTS_PROJECTION_FAIL;
  constructor(public payload: any) {}
}

// BULK UPDATE
export class BulkUpdatePostsecondaryStudents implements Action {
  readonly type = BULK_UPDATE_POSTSECONDARY_STUDENTS;
  constructor(public payload: any) {}
}

export class BulkUpdatePostsecondaryStudentsSuccess implements Action {
  readonly type = BULK_UPDATE_POSTSECONDARY_STUDENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdatePostsecondaryStudentsFail implements Action {
  readonly type = BULK_UPDATE_POSTSECONDARY_STUDENTS_FAIL;
  constructor(public payload: any) {}
}

export type PostsecondaryActions =
  | LoadPostsecondaryStudents
  | LoadPostsecondaryStudentsSuccess
  | LoadPostsecondaryStudentsFail
  | UpdatePostsecondaryStudents
  | BulkUpdatePostsecondaryStudents
  | UpdatePostsecondaryStudentsSort
  | UpdatePostsecondaryStudentsDimensions
  | UpdatePostsecondaryStudentsSuccess
  | UpdatePostsecondaryStudentsProjectionSuccess
  | UpdatePostsecondaryStudentsFail
  | UpdatePostsecondaryStudentsProjectionFail
  | BulkUpdatePostsecondaryStudents
  | BulkUpdatePostsecondaryStudentsSuccess
  | BulkUpdatePostsecondaryStudentsFail;
