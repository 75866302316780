import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NvPillButtonModule } from '../nv-pill-button/nv-pill-button.module';
import { NvTextboxModule } from '../nv-textbox/nv-textbox.module';
import { NvMultiPickerMenuContentComponent } from './nv-multi-picker-menu-content/nv-multi-picker-menu-content.component';
import { NvMultiPickerComponent } from './nv-multi-picker.component';
import { NvMultipickerMenuService } from './nv-multi-picker.service';

@NgModule({
  imports: [CommonModule, NvPillButtonModule, NvTextboxModule, MatMenuModule],
  // providers: [NvMultipickerMenuService],
  declarations: [NvMultiPickerComponent, NvMultiPickerMenuContentComponent],
  exports: [NvMultiPickerComponent],
})
export class NvMultipickerModule {}
