// TODO UPDATE AT TERM 1 FLIP: move '+' to next grad cohort, remove old grad cohort and add next one, increase order
export const CLASS_OF_MAPPING = {
  '2024+': {
    key: '2024+',
    human: '2024+',
    filterValue: '2024+',
    filterKey: 'classOf',
    order: 0,
  },
  '2025': {
    key: '2025',
    human: '2025',
    filterValue: '2025',
    filterKey: 'classOf',
    order: 1,
  },
  '2026': {
    key: '2026',
    human: '2026',
    filterValue: '2026',
    filterKey: 'classOf',
    order: 2,
  },
  '2027': {
    key: '2027',
    human: '2027',
    filterValue: '2027',
    filterKey: 'classOf',
    order: 3,
  },
  '2028': {
    key: '2028',
    human: '2028',
    filterValue: '2028',
    filterKey: 'classOf',
    order: 4,
  },
  '2029': {
    key: '2029',
    human: '2029',
    filterValue: '2029',
    filterKey: 'classOf',
    order: 5,
  },
  '2030': {
    key: '2030',
    human: '2030',
    filterValue: '2030',
    filterKey: 'classOf',
    order: 6,
  },
  '2031': {
    key: '2031',
    human: '2031',
    filterValue: '2031',
    filterKey: 'classOf',
    order: 7,
  },
  2032: {
    key: '2031',
    human: '2031',
    filterValue: '2031',
    filterKey: 'classOf',
    order: 8,
  },
  None: {
    key: 'None',
    human: 'None',
    filterValue: 'None',
    filterKey: 'none',
    order: 9,
  },
};
