import { IRowData } from '../models/list-models';

export interface ITooltipContent {
  template?: string;
  headers?: string[] | string[][];
  rowData: string[][] | IRowData[][];
  typeOfTable?: string; // optional, in case we need to know the type of table
  options?: {
    preserveTwoColumnLayout?: boolean
  }
}
export interface ITooltipData {
  type?: 'sync' | 'async';
  calc?: string;
  content?: ITooltipContent | any;
}

export type TTooltipElType = 'PANEL_FIELD' | 'FIXED_OR_INFINITE_LIST_CELL';

interface ICoreNvTooltipData {
  connectedElType?: TTooltipElType; // if not provided, defaults to 'FIXED_OR_INFINITE_LIST_CELL' down stream
}

interface IStaticNvTooltipData extends ICoreNvTooltipData {
  simpleContent?: string;
  tableContent?: ITooltipContent;
}

interface IAsyncNvTooltipData extends ICoreNvTooltipData {
  schoolId?: string;
  shelterId?: string;
  docId?: string;
  calc?: string; // graphqlKey for backend calc
  wildcard?: object;
  caresId?: string;
  meta?: string;
}

interface INvTooltipData extends IStaticNvTooltipData, IAsyncNvTooltipData {}

export type TNvTooltipData = string | INvTooltipData;

export function isTooltipContent (ttTest) {
  if (typeof ttTest !== 'object') return false;
  const hasRowData = Object.keys(ttTest).includes('rowData') && Array.isArray(ttTest.rowData);
  const rowsAreArrays = ttTest.rowData.every(row => Array.isArray(row));
  return hasRowData && rowsAreArrays;
}
