import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IErrorModalComponentData {
  title: string;
  message: string;
  confirmText?: string;
}

@Component({
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ErrorModalComponent implements OnInit {
  title: string;
  message: string;
  confirmText: string;

  constructor (
    public dialogRef: MatDialogRef<ErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IErrorModalComponentData,
  ) {}

  ngOnInit (): void {
    this.title = this.data.title;
    this.message =
      this.data.message || 'The batch action could not be applied to all of the selected students. Please try again.';
    this.confirmText = this.data.confirmText || 'OK';
  }

  onConfirm (): void {
    this.dialogRef.close();
  }
}
