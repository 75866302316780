import { ArrowCellRenderer } from '../arrow-cell-renderer/arrow-cell-renderer.component';
import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { IRowData } from '../../../models/list-models';

@Component({
  selector: 'app-arrow-trend',
  templateUrl: '../arrow-cell-renderer/arrow-cell-renderer.component.html',
  styleUrls: ['../arrow-cell-renderer/arrow-cell-renderer.component.scss'],
})

export class ArrowTrendCellRenderer extends ArrowCellRenderer implements ICellRendererAngularComp, OnInit {
  @Input() input: { 
    row: IRowData[]; column: IRowData; dynamicComponentInputData?: any 
  };

  //agInit is used by the grid cell renderer pattern
  public agInit(params: ICellRendererParams): void {
    super.agInit(params);
    //The promise here makes this function call async causing it to be picked up on the next change detection check. see https://angular.io/errors/NG0100
    Promise.resolve().then(() => this.addPercentSignToData());
  }

  //ngOnInit is used by the list dynamic component pattern
  public ngOnInit(): void {
    super.ngOnInit();
    //The promise here makes this function call async causing it to be picked up on the next change detection check. see https://angular.io/errors/NG0100
    Promise.resolve().then(() => this.addPercentSignToData());
  }

  public override valueFormatter(value){
    if(value){
      // Here we are treating a 0 as a null value because of the way trend data can be interpreted
      if(value === '—' || value === 0){
        this.displayValue = '—';
        this.cellClass = 'em-dash';
      } else {
        this.displayValue = `${Math.abs(parseFloat(value))}%`;
        this.setIcon(value);
      }
    } else {
      this.displayValue = '—';
      this.cellClass = 'em-dash';
    }
  }

  private addPercentSignToData(): void {
    if (this?.input?.column?.data) {
      if (!this.includesPercentSign(this.input.column.data) && this.input.column.data !== '—')  this.input.column.data += "%";
    }
    if (this?.params?.value) {
      if (!this.includesPercentSign(this.params.value) && this.params.value !== '—') this.params.value += "%";
    }
  }
}
