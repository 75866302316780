import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvMatTooltipModule } from 'Src/ng2/shared/components/nv-mat-tooltip/nv-mat-tooltip.module';
import { NvTooltipModule } from 'Src/ng2/shared/nv-tooltip/nv-tooltip.module';
import { SharedServicesModule } from '../../services/shared.services.module';
import { InfoPanelComponent } from './info-panel.component';

@NgModule({
  declarations: [InfoPanelComponent],
  imports: [CommonModule, SharedServicesModule, NvMatTooltipModule, NvTooltipModule],
  exports: [InfoPanelComponent],
})
export class InfoPanelModule {}
