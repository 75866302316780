import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';

export enum TrendDirection {
  INCREASE = 'increase',
  NEUTRAL = 'dot',
  DECREASE = 'decrease',
}

export enum TrendColor {
  POSITIVE = 'green',
  NEGATIVE = 'red',
  NEUTRAL = 'blue',
}

/**
 *
 * Displays percentages and changes based on previous and current trending data. The bottom text color and change indicator is based on the `TrendDirection` value. 
 * Positive values displayed green text, neutral values display blue text and negative values display red text.
 * Can be used as a button to filter data depending on the corresponding data type.
 *
 */
@Component({
  selector: 'nv-navigation-tab-tile',
  templateUrl: './nv-navigation-tab-tile.component.html',
  styleUrls: ['./nv-navigation-tab-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvNavigationTabTileComponent {
  /**
   * Map used to determine which icon and color to use for bottom text.
   */
  public iconMap: { [icon: string]: { [color: string]: string } } = {
    [TrendDirection.INCREASE]: {
      [TrendColor.POSITIVE]: 'increase',
      [TrendColor.NEUTRAL]: 'increase-blue',
      [TrendColor.NEGATIVE]: 'increase-red',
    },
    [TrendDirection.DECREASE]: {
      [TrendColor.POSITIVE]: 'decrease-green',
      [TrendColor.NEUTRAL]: 'decrease-blue',
      [TrendColor.NEGATIVE]: 'decrease',
    },
    [TrendDirection.NEUTRAL]: {
      [TrendColor.NEUTRAL]: 'dot',
    },
  };

  /**
   * String representing the trend direction. Determines the icon displayed in the bottom row text
   *
   * Green up arrow for `increase`
   *
   * Blue up arrow for `neutral-up`
   *
   * Blue down arrow for `neutral-down`
   *
   * Red down arrow for `decrease`
   */
  @Input() trendDirection?: TrendDirection = TrendDirection.NEUTRAL;

  /**
   * String representing the trend text color.
   *
   * Green indicates positive trend
   *
   * Blue indicates a neutral trend
   *
   * Red indicates a negative trend
   */
  @Input() trendColor?: TrendColor = TrendColor.NEUTRAL;
  /**
   * Title text displayed on the top of the tile.
   */
  @Input() name: string = '';

  /**
   * Tooltip text of tile
   */
  @Input() tooltip: string = '';

  /**
   * Value displayed on the middle of the tile.
   */
  @Input() value: string | number = null;

  /**
   * Description of the denominator
   */
  @Input() denominator: string | number = '';

  /**
   * Used for displaying positive/neutral/negative % changes displayed on the bottom of the tile.
   */
  @Input() difference: string | number = null;

  /**
   * The type of data being displayed. Can be used to determine which tile is selected as well as what data is shown by sibling components.
   */
  @Input() key: string = '';

  /**
   *
   * Time frame for change indicator text that appears at the bottom of the tile.
   *
   */
  @Input() timeFrame: string = '';

  /**
  *
  * A boolean representing whether the tile is selected or not. Effects styling of tile.
  */
  @Input() isSelected: boolean = false;

  /**
   *
   * Emits the `key` of the selected tile when clicked. Can also serve as an ID to be used to determine whether tile is selected or not based on the parent component.
   *
   */
  @Output() tileEmitter = new EventEmitter<string>();

  emitTileClick (dataType: string): void {
    this.tileEmitter.emit(dataType);
  }
}
