import {
  CLEAR_SHELTER_GRID_DATA,
  LOAD_SHELTER_GRID_DATA,
  LOAD_SHELTER_GRID_DATA_SUCCESS,
  SAVE_SHELTER_GRID_DATA,
  ShelterGridDataActions,
} from '../../actions/shelter-actions/shelter-grid-actions';

export const initialState = {
  loading: false,
  loaded: false,
  shelterId: null,
  columnDefs: [],
  rowData: [],
  filterHash: null,
  sortHash: null,
  stateHash: null,
  scrollLeft: null,
  scrollTop: null,
};

export function reducer(state = initialState, action: ShelterGridDataActions) {
  let payload: any;
  switch (action.type) {
    case SAVE_SHELTER_GRID_DATA:
      payload = action.payload;
      return {
        ...state,
        ...payload,
      };

    case LOAD_SHELTER_GRID_DATA:
      payload = action.payload;
      return {
        ...state,
        loaded: false,
        loading: true,
        shelterId: payload.shelterId,
      };

    case LOAD_SHELTER_GRID_DATA_SUCCESS:
      payload = action.payload;
      return {
        ...state,
        loaded: true,
        loading: false,
        ...payload,
      };

    case CLEAR_SHELTER_GRID_DATA:
      return {
        loaded: false,
        loading: false,
        shelterId: null,
        columnDefs: [],
        rowData: [],
        filterHash: null,
        sortHash: null,
        stateHash: null,
        scrollLeft: null,
        scrollTop: null,
      };

    default:
      return state;
  }
}
