import { NvPillModule } from './../nv-pill/nv-pill.module';
import { NvIconModule } from './../nv-icon/nv-icon.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvPerformanceCardComponent } from './nv-performance-card.component';

@NgModule({
  imports: [CommonModule, NvIconModule, NvPillModule],
  declarations: [NvPerformanceCardComponent],
  exports: [NvPerformanceCardComponent],
})
export class NvPerformanceCardModule {};
