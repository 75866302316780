import { IModalsConfig } from './../modals.config.interface';

export const ACTIVITIES_MODAL_CONFIG: IModalsConfig = {
  buttons: {
    confirm: {
      text: 'Create',
      disableButtonRipple: true,
    },
    cancel: {
      text: 'Cancel',
      disableButtonRipple: true,
    },
  },
};
