import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NvDatePickerModule } from '../nv-date-picker/nv-date-picker.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvDateRangePickerComponent } from './nv-date-range-picker.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, NvDatePickerModule, NvIconModule],
  declarations: [NvDateRangePickerComponent],
  exports: [NvDateRangePickerComponent],
})
export class NvDateRangePickerModule {}
