import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { getSchool } from 'Src/ng2/store';

@Component({
  selector: 'other-tools-resources',
  templateUrl: './other-tools-resources.component.html',
  styleUrls: ['./other-tools-resources.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OtherToolsResourcesComponent implements OnInit {
  public school;
  public configs;

  public resourcesConfig = [
    {
      district: ['NYC'],
      human: 'Strategic Data Check-ins',
      link: 'https://sdc.newvisions.org',
      style: 'link'
    },
    {
      district: ['NYC',],
      human: 'New Visions CloudLab',
      link: 'https://cloudlab.newvisions.org/',
      style: 'link'
    },
    {
      district: ['NYC', 'Lansing'],
      human: 'Free Open-Source Curriculum Materials',
      link: 'https://curriculum.newvisions.org/',
      style: 'bottom-link link'
    }
  ];

  constructor(
    private windowRef: WindowRef,
    private store: Store,
  ) { }

  ngOnInit() {
    this.store.select(getSchool).pipe(
      tap(storeSchool => (this.school = storeSchool)),
      take(1)
    ).subscribe();
    
    this.filterConfigsByDistrict();
  }

  filterConfigsByDistrict() {
    this.configs = this.resourcesConfig.filter((config) => {
      return config.district.includes(this.school.district);
    })
    Object.freeze(this.configs);
  }


  goExternal($url: string): void {
    const window = this.windowRef.nativeWindow;
    window.open($url, '_blank');
  }
}
