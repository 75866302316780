import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { filter } from 'lodash';
import { take, tap } from 'rxjs/operators';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from 'Src/ng2/routing/guards/user-role-permissions-for-route-guards.constant';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
import { Toggles, TToggles } from '../../shared/constants/toggles.constant';
import { HelpDeskService } from '../../shared/services/help-desk/help-desk.service';
import { PortalConfig } from '../../shared/services/portal-config';
import { ToggleService } from '../../shared/services/toggle/toggle.service';
import { ISchool } from '../../shared/typings/interfaces/school.interface';
import { getSchool } from '../../store';
import { TSelectedView } from './../../shared/components/portal-switcher/portal-switcher.component';
import { ImSchool } from './../../shared/services/im-models/im-school';
import { ImUser } from './../../shared/services/im-models/im-user';
import { getCurrentUser } from './../../store/selectors/current-user-selectors';
import { INavItem, getSmallSideNavConfig, ContentAreaNavItems, MoreToolsNavItems, shapeSchoolNavItems } from './side-nav.config';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

export interface ISidenav {
  [key: string]: ISidebarItem[]
}

/* istanbul ignore next */
@Component({
  selector: 'nvps-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SideNavNgComponent implements OnInit {
  // portal switcher props
  public selectedView: TSelectedView;
  public currentUser: IUser;
  public nickname: string;

  // config + component props
  public schoolId: string;
  public sidenav: ISidenav;
  public activeUrl: string;
  public selectedSidebarItem: string;

  // toggle related props
  private isSummerSchool: boolean;
  private isUftDoeAdvisingUser: boolean;

  // getSettingsFirstDefaultSubRoute
  private isHs: boolean;
  private isTransferSchool: boolean;
  private isSchoolWideEditor: boolean;
  private isCaseloadUser: boolean;

  private publicConfig: PortalConfig['publicConfig'];

  constructor (
    private helpDeskService: HelpDeskService,
    private imSchool: ImSchool,
    private imUser: ImUser,
    private objectCache: ObjectCache,
    private portalConfig: PortalConfig,
    private router: Router,
    private store: Store<any>,
    private toggleService: ToggleService,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) {
    this.publicConfig = this.portalConfig.publicConfig;
  }

  ngOnInit () {
    this.store
      .pipe(
        select(getCurrentUser),
        tap(currentUser => (this.currentUser = currentUser)),
        take(1),
      )
      .subscribe();

    this.selectedView = 'School';
    this.activeUrl = this.getActiveUrl(this.router.routerState.snapshot.url);
    this.isSchoolWideEditor = this.imUser.isSchoolWideEditor(this.currentUser);
    this.isCaseloadUser = this.imUser.isCaseloadUser(this.currentUser);
    this.isUftDoeAdvisingUser = this.imUser.isUftDoeAdvisingUser(this.currentUser);

    this.store
      .select(getSchool)
      .pipe(
        tap(school => {
          this.schoolId = school._id;
          this.nickname = school.nickName;
          this.isHs = this.imSchool.isHighSchool(school);
          this.isTransferSchool = this.imSchool.isTransferSchool(school);
          this.isSummerSchool = this.imSchool.isSummerSchool(school, this.currentUser);
          this.sidenav = this.getSideNav(school);
          this.updateSelectedItem([...this.sidenav.contentArea, ...this.sidenav.tools], { activeUrl: this.activeUrl });
        }),
        take(1),
      )
      .subscribe();
  }

  getSideNav (school: ISchool): ISidenav | any {
    const ContentAreaConfig = this.getMenuItems(ContentAreaNavItems, school);
    const MoreToolsConfig = this.getMenuItems(MoreToolsNavItems, school);

    const settingsRoute = this.getSettingsFirstDefaultSubRoute(school);
    const SmallSideNavConfig = getSmallSideNavConfig(settingsRoute);
    const smallNav = this.getMenuItems(SmallSideNavConfig, school);
    return { contentArea: ContentAreaConfig, tools: MoreToolsConfig, smallNav };
  }

  getMenuItems (configs: INavItem[], school: ISchool): ISidebarItem[] {
    const preFilteredConfigs = configs.reduce(this.filterItems.bind(this), []);
    const finalizedv3Options = this._cacheDefaultQueryParams(preFilteredConfigs);
    const allowedOptions = this.validateMenuOptions(finalizedv3Options, school);
    const sortedMenuOptions = allowedOptions.sort((a, b) => a.order - b.order);
    const formattedNavItems = shapeSchoolNavItems(sortedMenuOptions, this.schoolId, this.urlPathService.computeDistrictUrlPath.bind(this.urlPathService));
    return formattedNavItems || [];
  }

  public goExternal (name: string): void {
    const window = this.windowRef.nativeWindow;
    switch (name) {
      case 'Get Help': {
        this.helpDeskService.showHelp();
        break;
      }
      case 'Privacy & Terms': {
        window.open(this.publicConfig.PRIVACY_POLICY, '_blank');
        break;
      }
      default:
        return null;
    }
  }

  public updateSelectedItem (menuConfigs: ISidebarItem[], { selectedItemKey, activeUrl }: { selectedItemKey?: string, activeUrl?: string }): ISidebarItem {
    if (selectedItemKey) { // find item by key
      const navItem = menuConfigs.find(navItem => {
        if (navItem.key === selectedItemKey) return true;
        if (navItem.children) return this.updateSelectedItem(navItem.children, { selectedItemKey });
        return false;
      });
      if (navItem && !navItem?.children) {
        this.selectedSidebarItem = selectedItemKey;
        return navItem;
      }
    } else { // find item by url
      const urlMatches = menuConfigs.filter(navItem => {
        if (`/${activeUrl}/`.includes(`/${this.getActiveUrl(navItem.url)}`)) {
          return true;
        }
        if (navItem.children) return this.updateSelectedItem(navItem.children, { activeUrl });
        return false;
      });
      if (urlMatches.length === 1) this.selectedSidebarItem = urlMatches[0].key;
      else if (urlMatches.length > 1) {
        const activeUrlArr = activeUrl.split('/');
        const selectedItem = this.compareUrl(activeUrlArr, urlMatches, 0);
        this.selectedSidebarItem = selectedItem.key;
        return selectedItem;
      }
    }
  }

  private compareUrl (activeUrl: string[], navItemMatches: ISidebarItem[], index: number): ISidebarItem {
    if (!activeUrl[index]) return null;
    if (navItemMatches.length === 1) return navItemMatches[0];

    const matches = navItemMatches.filter((item: ISidebarItem) => {
      const urlArr = this.getActiveUrl(item.url).split('/');
      return urlArr[index] === activeUrl[index];
    });

    if (matches.length === 0 && navItemMatches.length === 1) return navItemMatches[0];

    return this.compareUrl(activeUrl, matches, index++);
  }

  /**
   * A recursive catch all reduce function that filters the available nav items based on
   * logic not included in the configs.
  */
  private filterItems (acc: INavItem[], item: INavItem): INavItem[] {
    // Add filter logic here
    switch (item.key) {
      case 'SUPPLEMENTAL_ADVISING':
        if (!this.isUftDoeAdvisingUser) return acc;
        break;
      case 'SUMMER_SCHOOL_LIST':
        /*
          special case toggle check for summer school list as there are
          scenearios where summerSchoolToggleOn and this.isSummerSchool
          may not align.
        */
        // eslint-disable-next-line no-case-declarations
        const summerSchoolToggleOn = this.toggleService.getToggleState(Toggles.TOGGLE_SUMMER_SCHOOL);
        if (!summerSchoolToggleOn || !this.isSummerSchool) return acc;
        break;
      default:
        break;
    }
    // end filter logic

    const updatedItem = { ...item };
    if (item?.children?.length > 0) {
      updatedItem.children = item.children.reduce(this.filterItems.bind(this), []);
    }
    acc.push(updatedItem);
    return acc;
  }

  _cacheDefaultQueryParams (navOptions: INavItem[]): INavItem[] {
    return navOptions.map((navItem) => {
      const defaults = navItem.queryParams;
      let cachedDefaults;
      if (defaults) {
        cachedDefaults = Object.assign({}, defaults);
        for (const param in defaults) {
          const obj = defaults[param];
          if (obj) {
            cachedDefaults[param] = this.objectCache.cacheObject(obj);
          }
        }
        return { ...navItem, queryParams: cachedDefaults };
      }
      return { ...navItem };
    });
  };

  private isBehindActiveToggle (toggleKeys?: TToggles[]): boolean {
    if (!toggleKeys || toggleKeys.length === 0) return true;
    const activeToggles = toggleKeys.some(toggle => this.toggleService.getToggleState(toggle));
    return activeToggles;
  }

  private validSchoolOptions (options: INavItem[], school: ISchool): INavItem[] {
    const validOptions = options.filter((option: INavItem) => {
      const { schoolTypes, districts, toggleKeys, children } = option;
      if (children) {
        const validChildren = this.validSchoolOptions(children, school);
        option.children = validChildren;
      }

      return this.imSchool.hasCorrectSchoolType(school, schoolTypes) &&
        this.isCorrectDistrict(school.district, districts) &&
        this.isBehindActiveToggle(toggleKeys);
    });
    return validOptions;
  }

  // Check user role permissions and school type using the same service methods as in RouteGuard and SchoolTypeGuard.
  // Applicable to all menu items
  private validateMenuOptions (options: INavItem[], school: ISchool): INavItem[] {
    const accessibleUserOptions = this.isRestricted(options);
    const validSchoolTypeOptions = this.validSchoolOptions(accessibleUserOptions, school);
    return validSchoolTypeOptions;
  }

  private isCorrectDistrict (district: string, districtTypes: Array<string>) {
    return districtTypes.includes(district);
  }

  getActiveUrl (url: string): string {
    if (!url) return;
    const [urlWithoutParams] = url.split('?');
    const remainingPath = urlWithoutParams.split('/').slice(3);
    const activeUrl = remainingPath.join('/');
    return activeUrl;
  }

  /*
    Restrict this schools links based on current user
    only accounts for urls in the side nav that have school routes
    only restricts based on user role permissions
    This implementation should be reviewed as part of the following refactor:
    https://newvisions.atlassian.net/browse/PI-2672 (AB)
  */
  isRestricted (options: any): any[] {
    const allowedOptions = filter(options, (option: any) => {
      if (option.key === 'OTHER_TOOLS') return true;
      // get restrictions for all items
      if (option.isActive) {
        if (option.url) {
          const rolesThatCanAccessRoute = USER_ROLE_PERMISSIONS_FOR_GUARDS[`school/:schoolId/${option.url}`];
          const authorized = this.imUser.isRoleAuthorized({
            user: this.currentUser,
            rolesThatCanAccessRoute,
            partnerId: this.schoolId,
            partnerType: 'school',
          });
          option.isRestricted = !authorized;
        } else if (option.children) {
          const authorizedChilren = this.isRestricted(option.children);
          option.children = authorizedChilren;
          option.isRestricted = !authorizedChilren.length;
        } else {
          option.isRestricted = false;
        }
      }
      return option.isRestricted === false;
    });
    return allowedOptions;
  }

  // Returns the first default sub route when on settings route,
  // depends on the school type and user role type
  // TODO - jchu : move to a route guard (e.g.SettingsRouteGuard) and put into SettingsRoutingModule
  private getSettingsFirstDefaultSubRoute (school: ISchool): string {
    const { district } = school;
    if (district === 'Lansing') return 'settings/supports';

    let route: string;
    if (this.isHs) {
      // high schoool is transfer school, small hs, large hs, hybrid school
      if (this.isSchoolWideEditor || this.isCaseloadUser) {
        route = 'settings/supports';
      } else {
        // --> at this point, it is school admin, delegated school admin
        if (this.isTransferSchool) {
          route = 'settings/supports';
        } else {
          route = 'settings/credits';
        }
      }
    } else {
      // for middle or elementary school
      route = 'settings/supports';
    }
    return route;
  }

  public navigateToHomepage (): void {
    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/lists/tiles`);
    this.router.navigate([url]);
  }
}
