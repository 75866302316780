import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { COLUMN_DATA_TYPE } from '../../../../../shared/constants/list-view/cell-type.constant';
import { IListColumnData } from '../../../../../shared/typings/interfaces/list-view.interface';
import { ListCellFormattingService } from '../../list-cell-formatting.service';

/* istanbul ignore next */
@Injectable()
export class CollegePathService {
  listCellFormattingService: ListCellFormattingService;

  constructor() {
    this.listCellFormattingService = new ListCellFormattingService();
  }

  formatColumnData(columnType, columnDataObj, colConfig) {
    const { data } = columnDataObj;

    switch (columnType) {
      case COLUMN_DATA_TYPE.FLAG:
        return this.listCellFormattingService.flagFormatter(data);

      case COLUMN_DATA_TYPE.GENERIC:
        return this.pathFormatter(columnDataObj, colConfig);

      default:
        let formattedData: IListColumnData = {
          data,
          style: '',
        };
        return formattedData;
    }
  }

  pathFormatter(colDataObj, colConfig) {
    const { data, dependencies } = colDataObj;
    if (_.isEmpty(colConfig)) {
      return this.listCellFormattingService.genericFormatter(data);
    }

    return {
      data,
      style: 'center',
      dependencies,
    };
  }
}
