import { ChangeDetectorRef, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { cloneDeep, pick } from 'lodash';

import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { IUser } from '../../../shared/typings/interfaces/user.interface';
import { ImNote } from 'Src/ng2/shared/services/im-models/im-note.service';
import { ImSchool } from './../../services/im-models/im-school';
import { ISchool } from '../../typings/interfaces/school.interface';
import { ISchoolNote, IShelterNote, IValidNoteModes, VALID_NOTE_MODES } from '../../typings/interfaces/note.interface';
import { IStudent } from '../../typings/interfaces/student.interface';
import { IPickerOption } from 'Src/nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { NOTES_CATEGORIES_OPTIONS, NOTE_TEMPLATE, NOTE_SHELTER_TEMPLATE } from './notes-modal.config';
import { PartnerTypes, TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { BackgroundJobNotificationService } from '../../services/background-job-notification-service/background-job-notification-service';

export interface INotesData extends IBaseModalData {
  partnerType: TValidPartnerTypes;
  school?: ISchool;
  shelterId?: string;
  student?: IStudent;
  studentName?: string;
  currentUser: IUser;
  note?: any;
  mode: IValidNoteModes;
  studentId?: string; // School Portal
  caresId?: string; // Shelter Portal
  studentIds?: string[]; // School Portal
  caresIds?: string[]; // Shelter Portal
  docId?: any;
}

@Component({
  selector: 'notes-shell-modal',
  templateUrl: './notes-modal-shell.component.html',
  styleUrls: ['./notes-modal-shell.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NotesModalShellComponent extends BaseModalComponent implements OnInit {
  noteData: ISchoolNote | IShelterNote;
  partnerType: TValidPartnerTypes;
  schoolId: string;
  studentId: string;
  currentUser: IUser;
  mode: string;
  student: IStudent;
  caresIds: string[]; // Bulk create shelter notes
  editIsRestricted: boolean;
  isHighSchool: boolean;
  public title: 'Add note' | 'Edit note';
  public primaryBtnName: 'Add' | 'Edit';
  public noteForm: FormGroup;
  public notesCategoriesOptions: IPickerOption[];
  noteToEdit: any;
  public itemCount: number;
  public itemType: string = 'student';
  public isProfileMode: boolean;
  public students: string[];
  public studentName: any;
  public caresId: any;
  public docId: any;
  public isFormDirty: boolean = false;

  // SHELTER (Available only on shelter portal)
  public shelterId: string;
  public shelterStudent: any;

  constructor (
    dialogRef: MatDialogRef<NotesModalShellComponent>,
    @Inject(MAT_DIALOG_DATA) public data: INotesData,
    private apiService: ApiService,
    private imNoteService: ImNote,
    private imSchoolService: ImSchool,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private backgroundJobNotificationService: BackgroundJobNotificationService,
  ) {
    super(dialogRef);
  }

  ngOnInit (): void {
    // clone data to avoid mutating the passed in data
    const { partnerType, school, student, currentUser, mode, shelterId, caresIds, studentIds, studentName, studentId, caresId, docId } = cloneDeep(this.data);
    this.partnerType = partnerType;
    this.currentUser = currentUser;
    this.mode = mode;
    this.docId = docId;

    if (this.partnerType === PartnerTypes.SCHOOL) {
      this.schoolId = school?._id;
      this.isHighSchool = this.imSchoolService.isHighSchool(school);
      // ES and MS have the same categories
      this.notesCategoriesOptions = this.isHighSchool ? NOTES_CATEGORIES_OPTIONS.HS : NOTES_CATEGORIES_OPTIONS.ES;
      if (this.mode === VALID_NOTE_MODES.EDIT_MODAL || this.mode === VALID_NOTE_MODES.EDIT_PROFILE) {
        this.student = student;
        this.studentId = studentId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
      if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SCHOOL) {
        this.students = studentIds;
        this.itemCount = this.students.length;
        this.isProfileMode = false;
      }
      else {
        this.student = student;
        this.studentId = studentId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
    }

    // SHELTER
    if (this.partnerType === PartnerTypes.SHELTER) {
      this.shelterId = shelterId;
      this.notesCategoriesOptions = NOTES_CATEGORIES_OPTIONS.SHELTER;
      if (this.mode === VALID_NOTE_MODES.EDIT_MODAL || this.mode === VALID_NOTE_MODES.EDIT_PROFILE) {
        this.shelterStudent = student;
        this.caresId = caresId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
      else if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SHELTER) {
        this.caresIds = caresIds;
        this.itemCount = this.caresIds.length;
        this.isProfileMode = false;
      } else {
        this.shelterStudent = student;
        this.caresId = caresId;
        this.studentName = studentName;
        this.isProfileMode = true;
      }
    }

    switch (mode) {
      case VALID_NOTE_MODES.CREATE:
      case VALID_NOTE_MODES.CREATE_BULK_SCHOOL:
        this.title = 'Add note';
        this.primaryBtnName = 'Add';
        this.noteData = this._createInitialSchoolNoteData();
        this.editIsRestricted = !this.imNoteService.canCreate(this.currentUser, this.noteData);
        this.initFormControlsToCreateNote();
        break;
      case VALID_NOTE_MODES.EDIT_PROFILE:
        this.title = 'Edit note';
        this.primaryBtnName = 'Edit';
        this.editIsRestricted = !this.imNoteService.canEdit(this.currentUser, this.data.note);
        this.initFormControlsToEditNote(this.docId);
        break;
      case VALID_NOTE_MODES.EDIT_MODAL:
        this.title = 'Edit note';
        this.primaryBtnName = 'Edit';
        this.editIsRestricted = false;
        this.initFormControlsToEditNote(this.docId);
        break;
      case VALID_NOTE_MODES.CREATE_SHELTER:
      case VALID_NOTE_MODES.CREATE_BULK_SHELTER:
        this.title = 'Add note';
        this.primaryBtnName = 'Add';
        this.noteData = this._createInitialShelterNoteData();
        this.editIsRestricted = false; // All shelter user can create notes
        this.initFormControlsToCreateNote();
        break;
      default:
        break;
    }
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  public initFormControlsToCreateNote (): void {
    const initialForm = {
      note: '',
      selectedCategories: [],
    };

    this.noteForm = this.formBuilder.group({
      note: [initialForm.note, Validators.required],
      selectedCategories: [initialForm.selectedCategories, Validators.required],
    });
  }

  private _createInitialSchoolNoteData (): ISchoolNote {
    let studentName = null; // undefined for bulk action
    let studentId = null; // undefined for bulk action
    if (this.student) {
      studentName = this.student.studentDetails.name.lastFirst;
      studentId = this.student._id;
    };
    if (!this.student && this.studentName) {
      studentName = this.studentName;
    };
    if (!this.student && this.studentId) {
      studentId = this.studentId;
    };
    const note = {
      ...NOTE_TEMPLATE,
      attachments: {
        student: { studentId, lastFirst: studentName },
      },
      schoolId: this.schoolId,
      contextPartnerType: this.partnerType,
    };

    return note;
  }

  private _createInitialShelterNoteData (): IShelterNote {
    let studentName = null; // undefined for bulk action
    let caresId = null; // undefined for bulk action
    if (this.shelterStudent) {
      studentName = this.shelterStudent?.SHELTER_STUDENT_NAME_FIRST_LAST;
      caresId = this.shelterStudent?.CARES_ID;
    }
    if (!this.shelterStudent && this.studentName) {
      studentName = this.studentName;
    }
    if (!this.shelterStudent && this.caresId) {
      caresId = this.caresId;
    }
    const note = {
      ...NOTE_SHELTER_TEMPLATE,
      attachments: {
        shelterStudent: {
          caresId,
          firstLast: studentName,
        },
      },
      shelterId: this.shelterId,
      contextPartnerType: this.partnerType,
    };

    return note;
  }

  public initFormControlsToEditNote (docId?): void {
    let noteId;
    if (this.mode === VALID_NOTE_MODES.EDIT_MODAL) {
      noteId = docId;
    } else {
      noteId = this.data.note.docId;
    }
    this.apiService.getNote(noteId, this.partnerType).subscribe((data:any) => {
      this.noteData = data;
      const initialForm = {
        note: this.noteData.body,
        selectedCategories: this.noteData.categories,
      };

      this.noteForm = this.formBuilder.group({
        note: [initialForm.note, Validators.required],
        selectedCategories: [initialForm.selectedCategories, Validators.required],
      });
    });
  }

  public handleSelectedCategories (selectedCats): void {
    this.noteForm.controls.selectedCategories.setValue(selectedCats);
    this.noteForm.controls.selectedCategories.markAsDirty();
  }

  public onClickPrimaryBtn (): void {
    const payload = this._prepPayloadForApi();

    switch (this.primaryBtnName) {
      case 'Add':
        if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SCHOOL) {
          const ids = this.students;
          this.apiService.bulkCreateNote(ids, payload, this.partnerType).pipe(
            tap((createdNote) => {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkStudentNoteCreate' });
              this.dialogRef.close(createdNote);
            }),
            catchError((err: any) => {
              return throwError(err);
            }),
          ).subscribe();
        }
        if (this.mode === VALID_NOTE_MODES.CREATE_BULK_SHELTER) {
          this.apiService.bulkCreateNote(this.caresIds, payload, this.partnerType).pipe(
            tap((createdNote) => {
              this.backgroundJobNotificationService.sendMessage({ backgroundJob: 'BulkShelterStudentNoteCreate' });
              this.dialogRef.close(createdNote);
            }),
            catchError((err: any) => {
              return throwError(err);
            }),
          ).subscribe();
        }
        if (this.mode === VALID_NOTE_MODES.CREATE_SHELTER || this.mode === VALID_NOTE_MODES.CREATE) {
          this.apiService.createNote(payload).pipe(
            tap((createdNote) => {
              this.dialogRef.close(createdNote);
            }),
            catchError((err: any) => {
              return throwError(err);
            }),
          ).subscribe();
        }
        break;
      case 'Edit':
          this.apiService.patchNote(payload._id, pick(payload, this.imNoteService.getEditableFields()), this.partnerType).pipe(
          tap((updatedNote) => {
            this.dialogRef.close(updatedNote);
          }),
          catchError((err: any) => {
            return throwError(err);
          }),
          ).subscribe();
        break;
      default:
        break;
    }
  }

  private _prepPayloadForApi (): ISchoolNote | IShelterNote {
    const noteBody = this.noteForm.controls.note.value;
    const categories = this.noteForm.controls.selectedCategories.value;
    const payload = {
      ...this.noteData,
      body: noteBody,
      categories,
    };

    return payload;
  }

  public onCancel (): void {
    this.dialogRef.close();
  }
}
