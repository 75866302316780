export const NYCT_DASH_TILES = {
  'F&P level decline or no growth': 'F&P level decline or no growth',
  'F&P data inconsistencies': 'F&P data inconsistencies',
  'F&P on level or above': 'F&P on level or above',
};

export const NYCT_DASH_MIDLEVEL = {
  FANDP_DATA_INCONSISTENCIES: 'FANDP_DATA_INCONSISTENCIES',
  FANDP_LEVEL_DECLINE_OR_NO_GROWTH: 'FANDP_LEVEL_DECLINE_OR_NO_GROWTH',
  FANDP_ON_LEVEL_OR_ABOVE: 'FANDP_ON_LEVEL_OR_ABOVE',
};
