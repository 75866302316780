import { ISupportGroupingsPayload } from './../../actions/support-actions/support-groupings.actions';

// construct a gql query string
export const generateQuery = ({ payload }: { payload: ISupportGroupingsPayload }) => {
  const { schoolId, id = '', baseCollectionName = 'supports', filterKeys, groupingKey, columns } = payload;

  const columnKeys: string[] = columns.map(({ graphQlKey }) => graphQlKey);
  const filterKeysArray: string[] = filterKeys.reduce((accum: string[], key: string) => {
    if (key.includes('="')) {
      // Escape the wildcard value passed from filter dropdown, in addition to the one from rollup table
      const filterVal = key.split('=');
      const filterString = filterVal[1].replace(/\"/g, '\\"');
      accum.push(`"${filterVal[0]}=${filterString}"`);
    } else {
      accum.push(`"${key}"`);
    }
    return accum;
  }, []);

  // allowEmptyTable field is currently returned when the requested groupingKey is 'STUDENT_SUPPORT_PROFILE`
  // It is added as meta to the response object, and is used by the front end to determine whether to render a single
  // 'no supports` message as a table row, so as to allow for column headers to be included in the graphql response.
  return `{
    SupportGroupings(
      schoolId: "${schoolId}", 
      id: "${id}",
      baseCollectionName: "${baseCollectionName}",
      filterKeys: [${filterKeysArray}], 
      groupingKey: "${groupingKey}", 
      columns: ${JSON.stringify(columnKeys)}
    ){
      key
      human
      tooltip
      rowData {
        data
        meta
        columnKey
      }
      allowEmptyTable
    }
  }
  `;
};

export const SUPPORT_GROUPINGS_JOINS = [];
