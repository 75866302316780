import { UserRolePermissionsForModelService } from './../user-role-permissions-for-model/user-role-permissions-for-model.service';
import { UserRolePermissionsForModel } from './../../constants/user-role-permissions-for-model.constant';
import { Injectable } from '@angular/core';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { ISchoolNote } from '../../typings/interfaces/note.interface';

const MODEL_NAME = 'NOTE';
const relationToUserPath = 'createdBy.userId';

@Injectable()
export class ImNote {
  constructor (
    private imUser: ImUser,
    private permissions: UserRolePermissionsForModelService,
  ) {}

  createdByEmail (note: ISchoolNote) {
    return this.imUser.getEffectiveEmail(note.createdBy);
  }

  supportName (note: ISchoolNote) {
    const { support, studentSupport } = note.attachments;
    if (support) {
      return support.name;
    } else if (studentSupport) {
      return studentSupport.support.name;
    }
  }

  canEdit (user, note) {
    return this.permissions.canEditPartial(MODEL_NAME, relationToUserPath)(note, user);
  }

  canCreate (user, note) {
    return this.permissions.canCreatePartial(MODEL_NAME, relationToUserPath)(note, user);
  }

  canView (user, note) {
    return this.permissions.canViewPartial(MODEL_NAME, relationToUserPath)(note, user);
  }

  canDelete (user, note) {
    return this.permissions.canDeletePartial(MODEL_NAME, relationToUserPath)(note, user);
  }

  getEditableFields () {
    return ['body', 'status', 'categories', 'attachments'];
  }
}
