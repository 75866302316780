import { RollbarService } from './../rollbar/rollbar.service';
import { Inject, Injectable } from '@angular/core';
import { each, pull, reduce } from 'lodash';
import { IProjection } from '../../../../ng2/shared/services/student-fetch/student-fetch.service';
import { UtilitiesService } from './../utilities/utilities.service';
import * as Rollbar from 'rollbar';

/* istanbul ignore next */
@Injectable()
export class StudentFetchMssgsService {
  constructor (@Inject(RollbarService) private rollbar: Rollbar, private utilitiesService: UtilitiesService) {
    //
  }

  public logBundlesOffInPublicConfig (): void {
    const message =
      'Static bundles are turned off in PublicConfig. ' +
      'Fetching editable and static data from student list search endpoint.';
    console.warn(message);
  }

  public logWarningForBundleMismatch (opts: {
    schoolId: string;
    bundleName;
    bundleDataMismatches: string[];
    endpointDataMismatches: string[];
  }): void {
    const { schoolId, bundleName, bundleDataMismatches, endpointDataMismatches } = opts;
    const endOfMismatch = 'Due to the mismatch, these students are currently ommitted from the view.';
    let message: string;
    message = `Unmatched students for school ${schoolId} between search endpoint and static bundle for ${bundleName}.`;

    if (bundleDataMismatches)
    { 
message += ` Student "_ids" of ${bundleDataMismatches} exist
    in bundle but not in student collection. ${endOfMismatch}`; 
}

    if (endpointDataMismatches)
    { 
message += ` Student "_ids" of ${endpointDataMismatches} exist
    in student collection but not in bundle. ${endOfMismatch}`; 
}
    this.rollbar.warning(`Unmatched students for school ${schoolId}: `, message);
  }

  public logMismatchedBundleProjection (opts: {
    bundleName;
    pathsInBundle: string[];
    bundleProjection: IProjection;
  }): void {
    const { bundleName, pathsInBundle, bundleProjection } = opts;
    const mismatches = [];
    const incrementalBundlePathsProjection = reduce(
      pathsInBundle,
      (acc, p: string) => {
        if (p.endsWith('>')) {
          const pathWithoutBracketSyntax = p.replace(/<.*>/g, '');
          acc[pathWithoutBracketSyntax] = 1;
        } else {
          const _path = p.split('.');
          let incrementalPath;
          each(_path, _p => {
            incrementalPath = incrementalPath ? `${incrementalPath}.${_p}` : _p;

            if (!acc[incrementalPath]) {
              acc[incrementalPath] = 1;
            }
          });
        }

        return acc;
      },
      {},
    );
    each(bundleProjection, (v, p) => {
      if (!incrementalBundlePathsProjection[p]) mismatches.push(p);
    });

    if (mismatches.length) {
      const warningMssg =
        `Mismatched projection for SDC activity "${bundleName}". ` +
        `Bundle projection includes fields "${mismatches}", which are not included in the ` +
        'current bundle returned by AWS. Regenerate bundles so that bundlePaths.json and the bundles on AWS match.';
      // If staticPaths are returned, update bundlePaths.json on the backend with all of the static paths
      // that are returned for the SDC activity.
      console.warn(warningMssg);
      this.rollbar.warning(warningMssg);
    }
  }

  logMismatchedStaticPaths (opts: { bundleName; staticPaths: string[] }): void {
    const { bundleName, staticPaths } = opts;

    if (staticPaths) {
      const _staticPaths = [...staticPaths].sort();
      // TODO: temporary solution - 'studentDetails.advisorTemp' field is not logged as a misaligned path
      pull(_staticPaths, 'studentDetails.advisorTemp');

      if (_staticPaths.length) {
        const warningMssg =
          `Misaligned static paths for SDC activity "${bundleName}. ` +
          `Regenerate bundles after updating "bundlePaths.json" on the backend for "${bundleName}" ` +
          `with the following static paths: "${JSON.stringify(staticPaths)}" .`;
        // If staticPaths are returned, update bundlePaths.json
        // on the backend with all of the static paths
        // that are returned for the SDC activity.
        console.warn(warningMssg);
        this.rollbar.warning(warningMssg);
      }
    }
  }

  // This logs paths that are included in bundlePaths.json for an sdc activity
  // but are not part of the projection for the sdc activity on the frontend.
  // This can happen if a path is removed from the sdc activity on the frontend (CM).
  public logMismatchedBundlePaths ({ bundleName, bundleProjection, fullProjection }) {
    const diffProjection: any = this.utilitiesService.diffProjection(fullProjection, bundleProjection);
    // _id will often not be included in fullProjection, so we do not want to log that
    // as a mismatch (CM)
    delete diffProjection._id;
    const fieldsInBundleNotInSdc = Object.keys(diffProjection).sort();

    if (fieldsInBundleNotInSdc.length) {
      const warningMssg =
        `Misaligned paths for "${bundleName}" in "bundlePaths.json". It includes fields that ` +
        'are not included in the projection for the SDC activity. Regenerate bundles after removing the following ' +
        `paths on the backend from "${bundleName}" in bundlePaths.json: ${JSON.stringify(fieldsInBundleNotInSdc)}.`;
      console.warn(warningMssg);
      this.rollbar.warning(warningMssg);
    }
  }
}
