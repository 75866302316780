import { Component, Inject, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface IConfirmModalComponentData {
  title: string;
  message: string;
  subMessage?: string;
  // Note: if warningContent is provided through this.data, it could a string of plain text or html
  warningContent?: string | undefined;
  hasWarningIcon?: boolean;
  confirmText?: string;
  cancelText?: string;
  subtitle?: string;
  noteText?: string; // Use if <nv-note> component is needed to highlight a piece of text under the title. Ex: Graduation, Credit and Regents Metrics.
}

@Component({
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmModalComponent implements OnInit, AfterViewInit {
  // Property usage explanation:
  // 1. title and message shall be provided for the modal to display meaningful content
  // 2. (optional) subMessage is located under message in the modal and is displayed as plain text
  // 3. (optional) warningContent is located under message/subMessage in the modal, and could be displayed as rich content(html string) or just plain text(text string)
  // 4. (optional) hasWarningIcon, if true, it will replace the X button with the warning icon
  title: string;
  message: string;
  subMessage: string;
  warningContent: string;
  hasWarningIcon: boolean;
  confirmText: string;
  cancelText: string;
  subtitle: string;
  noteText: string;
  @ViewChild('warningContent', { static: false }) warningContentEl: ElementRef;

  constructor (
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    private renderer: Renderer2,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmModalComponentData,
  ) {}

  ngOnInit (): void {
    this.title = this.data.title;
    this.message = this.data.message;
    this.subMessage = this.data.subMessage;
    this.warningContent = this.data.warningContent;
    this.hasWarningIcon = this.data.hasWarningIcon || false;
    this.confirmText = this.data.confirmText || 'OK';
    this.cancelText = this.data.cancelText || 'Cancel';
    this.subtitle = this.data.subtitle;
    this.noteText = this.data.noteText;
  }

  ngAfterViewInit (): void {
    if (this.warningContentEl) {
      this.renderer.setProperty(this.warningContentEl.nativeElement, 'innerHTML', this.warningContent);
    }
  }

  onConfirm (): void {
    this.dialogRef.close(true);
  }

  onCancel (): void {
    this.dialogRef.close(false);
  }
}
