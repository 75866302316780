import { IGroupData } from './../../models/list-models';
import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { unsubscribeComponent } from '../../helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IListSummaryModalEventObj } from '../../modals/list-summary/list-summary-modal.component';
import { ModalsService } from '../../modals/modals.service';
import { ListSummaryGroupings } from '../../services/list-summary-groupings/list-summary-groupings.service';
import { IDropdownOption } from '../../typings/interfaces/design-library.interface';

export interface IInfoRow {
  name: string;
  data: string;
  tooltipData?: any;
}

export interface IListSummaryGrouping {
  title: string;
  data: IGroupData[];
  groupHumanName: string;
  countLabel?: string;
  filterOptions: IDropdownOption[];
}

export interface IListSummaryEvent {
  filterKey: string;
  index?: number;
  title?: string;
}

@Component({
  selector: 'list-summary',
  templateUrl: './list-summary.component.html',
  styleUrls: ['./list-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ListSummaryComponent implements OnInit {
  @Input() summaryTable: IListSummaryGrouping;
  @Output() selectOption: EventEmitter<IListSummaryEvent> = new EventEmitter<IListSummaryEvent>();

  public selectedSummaryFilterOption: string;
  public showMore: boolean;

  constructor (
    public modalsService: ModalsService,
    private listSummaryGroupings: ListSummaryGroupings,
  ) {}

  ngOnInit (): void {
    const filterOptions = this.summaryTable.filterOptions || [];
    this.selectedSummaryFilterOption = filterOptions[0]?.key;
    this.showMore = this.summaryTable.data.length > 5;
  }

  openModal (): void {
    this.modalsService
      .openListSummaryModal(this.summaryTable)
      .afterClosed()
      .subscribe(($event: IListSummaryModalEventObj | undefined) => {
        if ($event) {
          this.listSummaryGroupings.groupingNavEvent$.emit($event);
        }
      });
  }

  onRowClick ($sectionIndex: number): void {
    const $event: IListSummaryModalEventObj = { sectionIndex: $sectionIndex };
    this.listSummaryGroupings.groupingNavEvent$.emit($event);
  }

  onFilterChange (filterKey: string): void {
    this.selectedSummaryFilterOption = filterKey;
    this.selectOption.emit({ filterKey, title: this.summaryTable.title });
  }
}
