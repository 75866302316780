import { Injectable } from '@angular/core';
import { ApiService } from './../services/api-service/api-service';

@Injectable()
export class NvTooltipDataService {
  constructor (private apiService: ApiService) {}

  cleanTooltipFilter (tooltipFilter: any): string {
    const tooltipFilterStr = JSON.stringify(tooltipFilter);
    const cleanedStr = tooltipFilterStr.replace(/"/g, '\\"');
    return cleanedStr;
  }

  canChangeDocId (calc) {
    let isTooltip;
    switch (calc) {
      case 'REGENTS_PREP_CELL_TOOLTIP':
      case 'COURSE_PERIOD_CELL_TOOLTIP':
        isTooltip = true;
        break;
      default:
        isTooltip = false;
        break;
    }
    return isTooltip;
  }

  getAsyncTooltip (payload) {
    const { schoolId, shelterId, docId, calc, caresId, tooltipFilter = {}, meta } = payload;
    const metaObject = meta ? JSON.parse(meta) : null;
    const includeMeta = !!(meta && Object.keys(metaObject).length);

    const validatedSchoolId = schoolId ? `schoolId: "${schoolId}", ` : '';
    const validatedShelterId = shelterId ? `shelterId: "${shelterId}", ` : '';
    const validatedCaresId = caresId ? `caresId: "${caresId}", ` : '';
    const tooltipFilterStr = this.cleanTooltipFilter(tooltipFilter);

    const formattedCalcKey = meta && includeMeta ? `${calc}=${metaObject.currentLevel},${metaObject.levelId},${metaObject._id}` : calc;
    let validatedDocId;

    if (this.canChangeDocId(calc)) {
      validatedDocId = schoolId; // we need the schoolId as the docId for these tooltips
    } else {
      validatedDocId = docId && docId.docId ? docId.docId : docId;
    }

    const query = `{
      Tooltip(
        ${validatedSchoolId}
        ${validatedShelterId}
        id: "${validatedDocId}",
        calc: "${formattedCalcKey}",
        tooltipFilter: "${tooltipFilterStr}"
        ${validatedCaresId}
      ) {
          content {
            ...on SimpleTooltip {
              simpleContent
            }
            ... on TableTooltip{
              tableContent{
                template
                rowData
                headers
                typeOfTable
                options {
                  preserveTwoColumnLayout
                }
              }
            }
          }
        }
      }`;

    const queryPayload = {
      query,
      fetchPolicy: 'no-cache',
    };

    return this.apiService.getStudentsGraphQL(queryPayload);
  }
}
