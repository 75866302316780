import { Injectable } from '@angular/core';
import { IActivity, IActivityMini } from 'Src/ng2/shared/typings/interfaces/activity.interface';
import { DateHelpers } from '../date-helpers/date-helpers.service';
// import { UserRolePermissionsForModelService } from '../../services/user-role-permissions-for-model/user-role-permissions-for-model.service';

// const PERMISSIONING_MODEL_NAME = 'ACTIVITY';

@Injectable()
export class ImActivity {
  constructor(public dateHelpers: DateHelpers) {
    // TODO: make use of permissioning service
    // this.permissioningFns = {
    //   canEdit: this.UserRolePermissionsForModelService.canEditPartial(PERMISSIONING_MODEL_NAME),
    //   canView: this.UserRolePermissionsForModelService.canViewPartial(PERMISSIONING_MODEL_NAME),
    //   canCreate: this.UserRolePermissionsForModelService.canCreatePartial(PERMISSIONING_MODEL_NAME),
    //   canDelete: this.UserRolePermissionsForModelService.canDeletePartial(PERMISSIONING_MODEL_NAME),
    // };
  }

  // TODO: make use of permissioning service
  // canEdit(activity: IActivity, viewingUser: any): boolean {
  //   return this.permissioningFns.canEdit(activity, viewingUser);
  // }

  // canCreate(activity: IActivity, viewingUser: any): boolean {
  //   return this.permissioningFns.canCreate(activity, viewingUser);
  // }

  // canView(activity: IActivity, viewingUser: any): boolean {
  //   return this.permissioningFns.canView(activity, viewingUser);
  // }

  getActivityTitle(activity: IActivity | IActivityMini): string {
    const {
      type,
      sector,
      startDate,
      endDate,
      partnerOrg: { name },
    } = activity;
    const start = this.dateHelpers.getFormattedMoment(startDate, 'MMM D');
    const startDates = start.split(' ');
    const end = this.dateHelpers.getFormattedMoment(endDate, 'MMM D');
    const endDates = end.split(' ');
    let computedDate;
    if (start === end) computedDate = `${startDates[0]} ${startDates[1]}`;
    else if (startDates[0] === endDates[0]) computedDate = `${startDates[0]} ${startDates[1]} - ${endDates[1]}`;
    else computedDate = `${startDates[0]} ${startDates[1]} - ${endDates[0]} ${endDates[1]}`;
    if (sector) return `${type} at ${name}, ${sector}, ${computedDate}`;
    else return `${type} at ${name}, ${computedDate}`;
  }

  getCroppedActivityTitle(activity: IActivity | IActivityMini): string {
    const {
      type,
      partnerOrg: { name },
    } = activity;
    return `${type} at ${name}`;
  }
}
