import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep, reduce, set } from 'lodash';
import { FormValidatorsService } from '../../services/form-validators/form-validators.service';
import { BaseModalComponent } from '../base-modal.component';
import { IBaseModalData } from '../modals.service';
import { EDIT_CONTACT_INFO_MODAL_CONFIG } from './edit-contact-info-modal.config';
import { UpdateSingleStudent } from 'Src/ng2/store';
import { RegularExpressionsUtility } from 'Src/ng2/shared/utilities/regular-expressions/regular-expressions.utility';

export interface IContactInfo {
  mobile?: string | null;
  email?: string | null;
  parentEmail?: string | null;
}

export interface IEditContactInfoModalComponentData extends IBaseModalData, IContactInfo {
  schoolId: string;
  studentIds: string[];
  mobile: string | null;
  email: string | null;
  parentEmail: string | null;
}

@Component({
  selector: 'edit-contact-info-modal',
  templateUrl: './edit-contact-info-modal.component.html',
  styleUrls: ['./edit-contact-info-modal.scss'],
})
export class EditContactInfoModalComponent extends BaseModalComponent implements OnInit {
  public contactInfoForm: FormGroup;
  public placeholder: string;
  public iconName: string = 'close';

  // Modal Configurations
  readonly title = EDIT_CONTACT_INFO_MODAL_CONFIG.title;
  readonly buttons = EDIT_CONTACT_INFO_MODAL_CONFIG.buttons;

  private studentIds;

  constructor (
    @Inject(MAT_DIALOG_DATA) public data: IEditContactInfoModalComponentData,
    dialogRef: MatDialogRef<EditContactInfoModalComponent>,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private formValidatorService: FormValidatorsService,
    private store: Store<any>,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const {
      mobile,
      email,
      parentEmail,
      studentIds,
    } = cloneDeep(this.data);
    this.studentIds = studentIds;

    const emailValidatorPattern = RegularExpressionsUtility.emailIdWithDomainRegex();
    // build form
    this.contactInfoForm = this.formBuilder.group({
      mobile: [mobile || ''], // nv-mask is already validating for phone number
      email: [email || '', { validators: [Validators.email, Validators.pattern(emailValidatorPattern)] }],
      parentEmail: [parentEmail || '', { validators: [Validators.email, Validators.pattern(emailValidatorPattern)] }],
    });
  }

  ngAfterViewChecked (): void {
    this.cdr.detectChanges();
  }

  public onCancel (): void {
    this.dialogRef.close();
  }

  public onSave (): void {
    const path = 'studentContactDetails';
    const mappedInput = this.mapFormInput(this.contactInfoForm.value);
    const patch = {
      [path]: {
        ...mappedInput,
      },
    };

    this.store.dispatch(new UpdateSingleStudent({ patch, id: this.studentIds[0] }));
    this.dialogRef.close();
  }

  public onClearInput (controlName: string): void {
    this.contactInfoForm.controls[controlName].setValue('');
    this.contactInfoForm.controls[controlName].markAsDirty();
  }

  private mapFormInput (input: IContactInfo): IContactInfo {
    return {
      mobile: input.mobile || null,
      email: input.email || null,
      parentEmail: input.parentEmail || null,
    };
  }
}
