
const schenectadyDistrict = 'schenectadyny';
const nycDistrict = 'doenycny';
const lansingDistrict = 'lansingmi';
const uniondaleDistrict = 'uniondaleny';

export const districtsConfig = {
  SCHENECTADY_DISTRICT: schenectadyDistrict,
  NYC_DISTRICT: nycDistrict,
  LANSING_DISTRICT: lansingDistrict,
  UNIONDALE_DISTRICT: uniondaleDistrict,
  DISTRICTS_WITH_VIEW_NETWORK_DISABLED: [
    schenectadyDistrict,
    uniondaleDistrict,
  ],
};
