import { EventEmitter, Component, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { ICourse } from './../../../student/common-panels/remote-learning/models';
import { tap, map } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import * as _ from 'lodash';
import { ImCourseDiff } from 'Src/ng2/shared/services/im-models/im-course-diff';
import { ImGapPlan } from 'Src/ng2/shared/services/im-models/im-gap-plan';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ICourseDiff } from '../../typings/interfaces/course-diff.interface';
import { IGapPlan } from '../../typings/interfaces/gap-plan.interface';
import {
  ICoursePartial,
  ISchool,
  TCreditSubjAreas,
} from '../../typings/interfaces/school.interface';
import { IStudent } from '../../typings/interfaces/student.interface';
import { ISubjectArea, SubjectAreas, TValidSubjHuman } from '../../constants/subject-areas.constant';
import { ImStudentCreditGaps } from '../../services/im-models/im-student-credit-gaps/im-student-credit-gaps';
import { ImStudentCurrentProgramHelpers } from 'Src/ng2/shared/services/im-models/im-student-credit-gaps-helpers/im-student-current-program-helpers';

import { Observable } from 'rxjs';

type TRowType = 'ON_PROGRAM' | 'ON_CODEDECK';

export interface IUserFilter {
  subjectArea: string;
  period: string;
  courseCodeWithSection: string
}

export interface ICourseForTable extends ICoursePartial {
  subject: string;
  pendingEnrollment: number;
  courseCodeWithSection: string;
  type: TRowType;
  canBeAdded: boolean;
}

export interface ITermYear {
  termYear: number;
  human: string;
}

@Component({
  selector: 'find-a-course-table',
  templateUrl: './find-a-course-table.component.html',
  styleUrls: ['./find-a-course-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FindACourseTable implements OnInit {
  // Bindings
  @Input() termYear: number;
  @Input() termNumber: number;
  @Input() termType: 'FUTURE' | 'CURRENT' | 'NEXT';
  @Input() courses: ICoursePartial[];
  @Input() studentCourseDiffs: ICourseDiff[] = [];
  @Input() studentGapPlans: IGapPlan[] = [];
  @Input() schoolCourseDiffs: ICourseDiff[] = [];
  @Input() schoolGapPlans: IGapPlan[] = [];
  @Input() student: IStudent;
  @Input() school: ISchool;
  @Input() initialFilter: IUserFilter;
  @Output() newCourseDiff: EventEmitter<any> = new EventEmitter();
  @Output() newGapPlan: EventEmitter<any> = new EventEmitter();

  // For view
  public userFilter: IUserFilter = {
    subjectArea: 'elaCore',
    period: '',
    courseCodeWithSection: '',
  };

  public courseForm: FormControl;
  public courseFilterOptions$: Observable<Array<Partial<ICourse>>>;

  public filteredCourses: ICourseForTable[];
  public subjectsMenu: ISubjectArea[];
  public periodsMenu: Array<string | number>;
  public courseCodesMenu = [];
  public allTermYears: ITermYear[];
  public hoveredCourseCodeWithSection: string;

  private mappedCourses: ICourseForTable[];
  private district;

  constructor (
    private ImStudentCreditGaps: ImStudentCreditGaps,
    private utils: UtilitiesService,
    private modalsService: ModalsService,
    private ImSchool: ImSchool,
    private imGapPlan: ImGapPlan,
    private imCourseDiff: ImCourseDiff,
    private ApiService: ApiService,
  ) {}

  ngOnInit () {
    this.district = this.school.district;
    this.courseForm = new FormControl(this.initialFilter?.courseCodeWithSection || '');
    // Set termYear and allTermYears are only needed if termType is FUTURE
    if (this.termType === 'FUTURE') {
      this.allTermYears = this.buildTermYears(this.school, 12);
      // The termYear at position 1 is the one after next
      this.termYear = this.allTermYears[1].termYear;
    }

    // Set default filters
    this.userFilter = this.assignFilter(this.initialFilter);

    // Set courses;
    this.setCourses(this.termYear);

    // `hoveredCourseCodeWithSection` is used in the html to only show the `courseCodeWithSection` tooltip
    // when hovering over the element. Otherwise, the table becomes too slow for larger data sets due to
    // drawing a tooltip on the DOM, even if the tooltip is hidden, for each row. (CM)
    this.hoveredCourseCodeWithSection = null;

    this.courseFilterOptions$ = this.courseForm.valueChanges.pipe(
      tap((courseCodeWithSection) => this.setFilteredCourses({ ...this.userFilter, courseCodeWithSection })),
      map((text) => this.filterCourseMenu(this.filteredCourses, text)),
    );
  }

  setCourses (termYear) {
    // Get termNumber of selected termYear
    const termYearAsString = termYear.toString();
    const termNumber = parseInt(termYearAsString.substring(termYearAsString.length - 1, termYearAsString.length));

    // Get courses for termNumber
    this.courses = this.ImSchool.getCombinedMasterProgramCodeDeck(this.school, termNumber);
    // Format courses for termNumber
    this.mappedCourses = this.getCoursesMappedToTable(this.courses);
    // Filter courses for termNumber
    this.filteredCourses = this.filterTableCourses(this.mappedCourses, this.userFilter, this.termType);
    // Build menus
    this.subjectsMenu = this.buildSubjectsMenu(this.courses);
    this.periodsMenu = this.buildPeriodsMenu(this.courses);
  }

  assignFilter (initialFilter) {
    if (!initialFilter) return this.userFilter;
    return _.defaults(initialFilter, this.userFilter);
  }

  // Build an array of term years, starting with the nextTermYear
  buildTermYears (school, numTermYears: number): ITermYear[] {
    let counter = 0;
    const nextTermYear = this.ImSchool.getNextTermYear(school);
    let followingTermYear = nextTermYear;

    const human = this.utils.getHumanReadableTerm(followingTermYear);
    const termYear = followingTermYear;

    const allTermYears = [
      {
        human,
        termYear,
      },
    ];

    // -1 because we already loaded the first item above
    const quitCount = numTermYears - 1;
    while (counter < quitCount) {
      followingTermYear = this.ImSchool.getNextTermYear(school, followingTermYear);
      const human = this.utils.getHumanReadableTerm(followingTermYear);
      allTermYears.push({
        human,
        termYear: followingTermYear,
      });
      counter++;
    }

    return allTermYears;
  }

  buildSubjectsMenu (courses: ICoursePartial[]): ISubjectArea[] {
    const district = this.school.district;
    const allSubjects = courses.map(course => course.subjectArea);
    const uniqed = _.uniq(allSubjects);
    const cleaned = _.reduce(
      uniqed,
      (result, uniqueSubject: TCreditSubjAreas) => {
        const subjectArea = _.find(SubjectAreas, { camelCase: uniqueSubject });
        if (subjectArea) result.push(subjectArea);
        return result;
      },
      [],
    );
    const sorted = _.sortBy(cleaned);
    return sorted;
  }

  buildPeriodsMenu (courses: ICoursePartial[]): Array<string | number> {
    const allPeriods = courses.map(course => course.period);
    const uniqed = _.uniq(allPeriods);
    const cleaned = _.filter(uniqed, period => {
      return period !== null && period !== undefined;
    });
    // fix sort for double-periods or periods > 9 (JE)
    const sorted = _.sortBy(cleaned, (period: string) => {
      return parseInt(period);
    });
    return sorted;
  }

  // Convert initial ICourse objects to ICourseForTable objs
  getCoursesMappedToTable (courses: ICoursePartial[]): ICourseForTable[] {
    const mappedCourses = courses.map(course => {
      const type = this.getRowType(course);
      const isOnProgram = type === 'ON_PROGRAM';
      const subject = this.getSubjectNameForCourse(course);

      const pendingEnrollment = isOnProgram
        ? this.calculatePendingEnrollmentForCourse(course.courseId, course.enrollment, this.schoolCourseDiffs)
        : undefined;

      const courseCodeWithSection = this.getCourseCodeWithSection(course);
      const canBeAdded = this.getCanBeAddedStatus(
        course.courseCode,
        course.section,
        this.student,
        this.school,
        this.studentCourseDiffs,
        this.studentGapPlans,
      );

      const extender = {
        subject,
        pendingEnrollment,
        courseCodeWithSection,
        type,
        canBeAdded,
      };

      const courseForTable = Object.assign(course, extender);

      return courseForTable;
    });

    return mappedCourses;
  }

  getCanBeAddedStatus (
    courseCode: string,
    section: string,
    student: IStudent,
    school: ISchool,
    studentCourseDiffs: ICourseDiff[],
    studentGapPlans: IGapPlan[],
  ): boolean {
    const courseCanBeAdded = this.ImStudentCreditGaps.canCourseBeAddedToProgram(
      student,
      school,
      studentCourseDiffs,
      courseCode,
      section,
    );

    const gapPlanFound = studentGapPlans.find(gapPlan => {
      const found = gapPlan && _.includes(gapPlan.plan, courseCode);
      return found && gapPlan.status === 'ACTIVE';
    });

    return courseCanBeAdded && !gapPlanFound;
  }

  // Using whether or not there is a course section to determine if its
  // a course on the master program vs one on the official code deck.
  // This may change in the future, so putting into its own func
  getRowType (course: ICoursePartial): TRowType {
    const hasSection = !!course.section;
    return hasSection ? 'ON_PROGRAM' : 'ON_CODEDECK';
  }

  getCourseCodeWithSection (course): string {
    const { courseCode, section } = course;
    return section ? `${courseCode}-${section}` : courseCode;
  }

  getSubjectNameForCourse (course: ICoursePartial): TValidSubjHuman | null {
    return ImStudentCurrentProgramHelpers.getHumanReadableSubjectAreaForCourse(course, this.school.district);
  }

  // Set a default filter on subjectArea. We never show "All" because
  // it takes too long to render (JC)
  filterTableCourses (mappedCourses: ICourseForTable[], userFilter: IUserFilter, termType: string): ICourseForTable[] {
    const { subjectArea, period, courseCodeWithSection } = userFilter;

    // There will always be a subjectArea, other values may be empty ''
    const filter: any = {
      subjectArea,
    };

    // NEXT and FUTURE term types should only include official code deck
    if (termType === 'NEXT' || termType === 'FUTURE') filter.type = 'ON_CODEDECK';

    // Old comment by JC said this needs to be an integer, but the course objects have period as a string (JE)
    if (period) filter.period = period;

    let filteredCourses = _.filter(mappedCourses, filter);

    // Further filter for partial course code via course code auto-complete
    if (courseCodeWithSection) {
      filteredCourses = this.getCoursePickerFilter(filteredCourses, courseCodeWithSection);
    }

    return filteredCourses;
  }

  getCoursePickerFilter (filteredCourses: ICourseForTable[], courseCodeWithSection: string): ICourseForTable[] {
    return _.filter(filteredCourses, course => {
      return _.includes(course.courseCodeWithSection.toLowerCase(), courseCodeWithSection.toLowerCase());
    });
  }

  setFilteredCourses (filters) {
    this.filteredCourses = this.filterTableCourses(this.mappedCourses, filters, this.termType);
  }

  // Used by the md-autocomplete component to further filter
  // course options prior to course selection
  filterCourseMenu (filteredCourses: ICourseForTable[], courseCodeWithSection): ICourseForTable[] {
    const filteredList = this.getCoursePickerFilter(filteredCourses, courseCodeWithSection);
    return filteredList;
  }

  calculatePendingEnrollmentForCourse (courseId, currentEnrollment: number, courseDiffs: ICourseDiff[] = []): number {
    // Count up the matching courseDiffs
    const courseDiffCount = courseDiffs.reduce((count, courseDiff) => {
      if (courseDiff.courseId === courseId) {
        if (courseDiff.action === 'ADD') count++;
        if (courseDiff.action === 'DROP') count--;
      }
      return count;
    }, 0);

    return currentEnrollment + courseDiffCount;
  }

  handleUserClick (course: ICourseForTable) {
    // This will disable the "ADD" button immediately
    course.canBeAdded = false;

    if (course.type === 'ON_PROGRAM') {
      this.addCourseDiff(course);
    } else {
      this.addGapPlan(course);
    }

    // Need to also update the unfiltered list, or else the "ADD" button will come back
    const unfilteredCourse = _.find(this.mappedCourses, { courseCodeWithSection: course.courseCodeWithSection });
    unfilteredCourse.canBeAdded = false;
  }

  addGapPlan (course: ICourseForTable) {
    const gapPlan: Partial<IGapPlan> = this.imGapPlan.createNew(null, this.school._id, this.student);
    gapPlan.termYear = this.termYear;
    gapPlan.plan = course.courseCode;
    gapPlan.gradReq = course.gradReq;
    gapPlan.creditValue = course.creditValue;

    // Kick off http request, but keep going
    // Assume success, but error modal will pop up if not
    this.createGapPlan(gapPlan);
  }

  addCourseDiff (course: ICourseForTable) {
    const template = this.imCourseDiff.getTemplate();
    const diff: Partial<ICourseDiff> = template;
    diff._id = this.utils.createV4Uuid();
    diff.termYear = this.termYear;
    diff.student.studentId = this.student._id;
    diff.student.lastFirst = this.student.studentDetails.name.lastFirst;
    diff.schoolId = this.school._id;
    diff.courseId = course.courseId;

    this.courseDiffHasConflicts(diff, course).then(hasConflict => {
      // Kick off http request, but keep going
      // Assume success, but error modal will pop up if not
      if (!hasConflict) {
        this.createCourseDiff(diff);
      }
    });
  }

  courseDiffHasConflicts (diff: Partial<ICourseDiff>, course: ICourseForTable): Promise<boolean> {
    const { courseId } = diff;
    const { courseCodeWithSection } = course;

    // Array of courses on student program conflicting with the diff to add
    const conflictsOnProgam = this.ImStudentCreditGaps.getCoursesConfictingWithCourseBeingAdded(
      this.student,
      this.school,
      this.studentCourseDiffs,
      courseId,
    );

    // Array of existing pending diff ADDs that conflict
    const conflictingCourseDiffs = _.reduce(
      this.studentCourseDiffs,
      (conflicts, courseDiff: any) => {
        const conflictingCourseId = this.imCourseDiff.scheduleOfCourseToBeAddedConflicts(
          courseDiff,
          this.school,
          courseId,
        );
        if (conflictingCourseId) {
          conflicts.push(conflictingCourseId);
        }
        return conflicts;
      },
      [],
    );

    const hasConflict = !!_.size(conflictingCourseDiffs) || !!_.size(conflictsOnProgam);
    // If no conflict, return false. Otherwise, alert user via modal
    if (!hasConflict) return Promise.resolve(false);

    return this.modalsService.openConfirmModal({
      title: 'Confirm Add',
      confirmText: 'OK',
      message: this.getCreditGapsConfirmationText(courseCodeWithSection, conflictsOnProgam, conflictingCourseDiffs),
    })
      .afterClosed()
      .pipe(
        map((confirmed) => !confirmed),
      ).toPromise();
  }

  // We should emit back to component here
  createGapPlan (gapPlan) {
    this.studentGapPlans.push(gapPlan);
    this.newGapPlan.emit(gapPlan);
  }

  // We should emit back to component here
  createCourseDiff (diff): void {
    this.studentCourseDiffs.push(diff);
    this.schoolCourseDiffs.push(diff);
    this.newCourseDiff.emit(diff);
  }

  getCreditGapsConfirmationText (courseCodeWithSectionOfAddition, conflictingStudentCourses, conflictingStudentDiffCourses) {
    const formatCoursesText = courses => {
      let text = '';
      const coursesLength = courses.length;

      if (coursesLength === 1) {
        text = `${courses[0].courseCode}-${courses[0].section}`;
      } else if (coursesLength === 2) {
        text = `${courses[0].courseCode}-${courses[0].section} and ${courses[1].courseCode}-${courses[1].section}`;
      } else if (coursesLength > 2) {
        _.each(courses, (course: any, index: number) => {
          if (index !== coursesLength - 1) {
            text += `${course.courseCode}-${course.section}, `;
          } else {
            text += `and ${course.courseCode}-${course.section}`;
          }
        });
      }

      return text;
    };

    const courseOrCoursesText = conflictingStudentCourses.length > 1 ? 'courses' : 'course';
    const additionOrAdditionsText = conflictingStudentDiffCourses.length > 1 ? 'additions' : 'addition';

    if (conflictingStudentCourses.length && conflictingStudentDiffCourses.length) {
      // tslint:disable-next-line:max-line-length
      return `Adding ${courseCodeWithSectionOfAddition} may conflict with ${
        conflictingStudentCourses.length
      } ${courseOrCoursesText}, ${formatCoursesText(
        conflictingStudentCourses,
      )}, already on the student's current program. It may also conflict with ${
        conflictingStudentDiffCourses.length
      } scheduled pending ${additionOrAdditionsText}, ${formatCoursesText(
        conflictingStudentDiffCourses,
      )}. Are you sure you want to add it?`;
    } else if (conflictingStudentCourses.length) {
      // tslint:disable-next-line:max-line-length
      return `Adding ${courseCodeWithSectionOfAddition} may conflict with ${
        conflictingStudentCourses.length
      } ${courseOrCoursesText}, ${formatCoursesText(
        conflictingStudentCourses,
      )}, already on the student's current program. Are you sure you want to add it?`;
    } else if (conflictingStudentDiffCourses.length) {
      // tslint:disable-next-line:max-line-length
      return `Adding ${courseCodeWithSectionOfAddition} may conflict with ${
        conflictingStudentDiffCourses.length
      } scheduled pending ${additionOrAdditionsText}, ${formatCoursesText(
        conflictingStudentDiffCourses,
      )}. Are you sure you want to add it?`;
    }
  }
}
