import { NvTextboxVirtualScrollModule } from './nv-textbox-virtual-scroll/nv-textbox-virtual-scroll.module';
import { NvDropdownMenuModule } from './nv-dropdown-menu/nv-dropdown-menu.module';
import { NvTimeRangePickerModule } from './nv-time-range-picker/nv-time-range-picker.module';
import { NvTimePickerModule } from './nv-time-picker/nv-time-picker.module';
import { NgModule } from '@angular/core';
// NV Modules
import { NvButtonModule } from './nv-button/nv-button.module';
import { NvDatePickerModule } from './nv-date-picker/nv-date-picker.module';
import { NvDateRangePickerModule } from './nv-date-range-picker/nv-date-range-picker.module';
import { NvDropdownItemModule } from './nv-dropdown-item/nv-dropdown-item.module';
import { NvDropdownModule } from './nv-dropdown/nv-dropdown.module';
import { NvFormFieldModule } from './nv-form-field/nv-form-field.module';
import { NvIconButtonModule } from './nv-icon-button/nv-icon-button.module';
import { NvIconModule } from './nv-icon/nv-icon.module';
import { NvLogoModule } from './nv-logo/nv-logo.module';
import { NvMultipickerModule } from './nv-multi-picker/nv-multi-picker.module';
import { NvNoteModule } from './nv-note/nv-note.module';
import { NvNumboxModule } from './nv-numbox/nv-numbox.module';
import { NvPillButtonModule } from './nv-pill-button/nv-pill-button.module';
import { NvPillModule } from './nv-pill/nv-pill.module';
import { NvSegmentedControlModule } from './nv-segmented-control/nv-segmented-control.module';
import { NvSidebarItemModule } from './nv-sidebar-item/nv-sidebar-item.module';
import { NvSidebarListModule } from './nv-sidebar-list/nv-sidebar-list.module';
import { NvTextboxModule } from './nv-textbox/nv-textbox.module';
import { NvToastModule } from './nv-toast/nv-toast.module';
import { NvDashboardTileModule } from './nv-dashboard-tile/nv-dashboard-tile.module';
import { NvPerformanceCardModule } from './nv-performance-card/nv-performance-card.module';
import { NvNavigationTabTileModule } from './nv-navigation-tab-tile/nv-navigation-tab-tile.module';
import { NvCardModule } from './nv-card/nv-card.module';

const modulesArray = [
  NvButtonModule,
  NvDatePickerModule,
  NvDateRangePickerModule,
  NvDropdownMenuModule,
  NvDropdownModule,
  NvFormFieldModule,
  NvDropdownItemModule,
  NvIconButtonModule,
  NvIconModule,
  NvLogoModule,
  NvMultipickerModule,
  NvNoteModule,
  NvNumboxModule,
  NvPillButtonModule,
  NvPillModule,
  NvSegmentedControlModule,
  NvSidebarItemModule,
  NvSidebarListModule,
  NvTextboxModule,
  NvToastModule,
  NvTimePickerModule,
  NvTimeRangePickerModule,
  NvTextboxVirtualScrollModule,
  NvDashboardTileModule,
  NvPerformanceCardModule,
  NvNavigationTabTileModule,
  NvCardModule,
];

@NgModule({
  imports: [...modulesArray],
  exports: [...modulesArray],
})
export class NvDesignLibraryModule {}
