import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { BatchActionsMenuModule } from '../../shared/components/batch-actions-menu/batch-actions-menu.module';
import { ListSummaryModule } from '../../shared/components/list-summary/list-summary.module';
import { NvMatTooltipModule } from '../../shared/components/nv-mat-tooltip/nv-mat-tooltip.module';
import { ModalsModule } from '../../shared/modals/modals.module';
import { SharedServicesModule } from '../../shared/services/shared.services.module';
import { InfoPanelModule } from './../../shared/components/info-panel/info-panel.module';
import { ContentToolsComponent } from './content-tools.component';

@NgModule({
  declarations: [ContentToolsComponent],
  imports: [
    CommonModule,
    SharedServicesModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    BatchActionsMenuModule,
    ModalsModule,
    NvMatTooltipModule,
    ListSummaryModule,
    InfoPanelModule,
    NvDesignLibraryModule,
  ],
  exports: [ContentToolsComponent],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ContentToolsModule {}
