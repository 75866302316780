export const SCHOOL_BATCH_ACTIONS_LABEL = {
  VIEW_PROFILES: 'VIEW_PROFILES',
  ASSIGN_SUPPORT: 'ASSIGN_SUPPORT',
  COMPLETE_SUPPORT: 'COMPLETE_SUPPORT',
  DELETE_SUPPORT_RECORD: 'DELETE_SUPPORT_RECORD',
  ADD_COLLEGE: 'ADD_COLLEGE',
  UPDATE_STATUS: 'UPDATE_STATUS',
  REMOVE_COLLEGE: 'REMOVE_COLLEGE',
  ADD_NOTES_SCHOOL: 'ADD_NOTES_SCHOOL',
  ADD_EXPERIENCE: 'ADD_EXPERIENCE',
};

export const SHELTER_BATCH_ACTION_LABEL = {
  ASSIGN_SUCCESS_MENTOR: 'ASSIGN_SUCCESS_MENTOR',
  MARK_COMPLETE_SUCCESS_MENTOR: 'MARK_COMPLETE_SUCCESS_MENTOR',
  REMOVE_SUCCESS_MENTOR: 'REMOVE_SUCCESS_MENTOR',
  VIEW_SHELTER_STUDENT_PROFILES: 'VIEW_SHELTER_STUDENT_PROFILES',
  ADD_NOTES: 'ADD_NOTES',
};

// In the Att List, batch actions options are under Success Mentoring option
const tooltipDefault: string = 'Select one or more students from the list';
const toolTipDefaultStyle: string = 'batch-action-tooltip';

export const SHELTER_BATCH_ACTIONS = [
  {
    human: 'View profiles',
    userCanAccess: true,
    districts: ['NYC'],
    tooltip: {
      default: tooltipDefault,
      unique: "Open selected students' Profiles and click through them one by one",
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'VIEW_SHELTER_STUDENT_PROFILES',
    iconStyle: 'pages',
    type: 'MODAL',
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 0,
      },
      dropdown: {
        isVisible: false,
      },
    },
  },
  {
    human: 'Success Mentor',
    districts: ['NYC'],
    tooltip: {
      default: 'Select one or more students from the list',
      unique: 'Modify the Success Mentor of the selected students',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'SHELTER_SUCCESS_MENTOR',
    iconStyle: 'person',
    type: 'MENU',
    options: [
      {
        key: SHELTER_BATCH_ACTION_LABEL.ASSIGN_SUCCESS_MENTOR,
        human: 'Assign success mentor',
        tooltip: {
          default: 'Select one or more students from the list',
          unique: 'Assign success mentor for the selected students',
        },
      },
      {
        key: SHELTER_BATCH_ACTION_LABEL.MARK_COMPLETE_SUCCESS_MENTOR,
        human: 'Mark complete',
        tooltip: {
          default: 'Select one or more students from the list',
          unique: 'Mark success mentoring as complete for the selected students',
        },
      },
      {
        key: SHELTER_BATCH_ACTION_LABEL.REMOVE_SUCCESS_MENTOR,
        human: 'Delete mentor record',
        customClass: 'label-warning-text',
        tooltip: {
          default: 'Select one or more students from the list',
          unique: 'Remove success mentor for the selected students',
        },
      },
    ],
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 1,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 1,
      },
    },
  },
  {
    human: 'Notes',
    districts: ['NYC'],
    tooltip: {
      default: 'Select one or more students from the list',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'NOTES',
    iconStyle: 'note',
    type: 'MENU',
    options: [
      {
        key: SHELTER_BATCH_ACTION_LABEL.ADD_NOTES,
        human: 'Add note',
        tooltip: {
          default: 'Select one or more students from the list',
          unique: 'Add a note for the selected students',
        },
      },
    ],
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 2,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 2,
      },
    },
  },
];

// In the Grid, there is no nested options
export const SHELTER_BATCH_ACTIONS_DATA_GRID = [
  {
    human: 'Assign success mentor',
    districts: ['NYC'],
    tooltip: {
      default: 'Select one or more students from the list',
      unique: 'Assign success mentor for the selected students',
    },
    tooltipStyle: 'batch-action-tooltip',
    key: SHELTER_BATCH_ACTION_LABEL.ASSIGN_SUCCESS_MENTOR,
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: false,
        sortOrder: 1,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 1,
      },
    },
  },
  {
    human: 'Mark complete',
    districts: ['NYC'],
    tooltip: {
      default: 'Select one or more students from the list',
      unique: 'Mark success mentoring as complete for the selected students',
    },
    tooltipStyle: 'batch-action-tooltip',
    key: SHELTER_BATCH_ACTION_LABEL.MARK_COMPLETE_SUCCESS_MENTOR,
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: false,
        sortOrder: 2,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 2,
      },
    },
  },
  {
    human: 'Delete mentor record',
    districts: ['NYC'],
    customClass: 'label-warning-text',
    tooltip: {
      default: 'Select one or more students from the list',
      unique: 'Remove success mentor for the selected students',
    },
    tooltipStyle: 'batch-action-tooltip',
    key: SHELTER_BATCH_ACTION_LABEL.REMOVE_SUCCESS_MENTOR,
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: false,
        sortOrder: 3,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 3,
      },
    },
  },
];

export const SCHOOL_BATCH_ACTIONS = [
  {
    human: 'View profiles',
    userCanAccess: true,
    districts: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    tooltip: {
      default: tooltipDefault,
      unique: "Open selected students' Profiles and click through them one by one",
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'VIEW_PROFILES',
    iconStyle: 'pages',
    type: 'MODAL',
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 0,
      },
      dropdown: {
        isVisible: false,
      },
    },
  },
  {
    human: 'Supports',
    districts: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    tooltip: {
      default: tooltipDefault,
      unique: 'Modify the support groups of the selected students',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'SUPPORTS',
    iconStyle: 'people',
    type: 'MENU',
    options: [
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.ASSIGN_SUPPORT,
        human: 'Assign support',
      },
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.COMPLETE_SUPPORT,
        human: 'Mark support complete',
      },
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.DELETE_SUPPORT_RECORD,
        human: 'Delete support record',
      },
    ],
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 1,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 1,
      },
    },
  },
  {
    human: 'Experiences',
    districts: ['NYC'],
    tooltip: {
      default: tooltipDefault,
      unique: null,
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'EXPERIENCES',
    iconStyle: 'briefcase',
    type: 'MENU',
    options: [
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.ADD_EXPERIENCE,
        human: 'Add Experience',
      },
    ],
    hsOnly: true,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 2,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 2,
      },
    },
  },
  {
    human: 'College list',
    districts: ['NYC', 'Schenectady', 'Uniondale'],
    tooltip: {
      default: tooltipDefault,
      unique: 'Modify the college list for the selected students',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'COLLEGE_LIST',
    iconStyle: 'college',
    type: 'MENU',
    options: [
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.ADD_COLLEGE,
        human: 'Add college',
      },
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.UPDATE_STATUS,
        human: 'Update status',
      },
      {
        key: SCHOOL_BATCH_ACTIONS_LABEL.REMOVE_COLLEGE,
        human: 'Remove college',
      },
    ],
    hsOnly: true,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 4,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 4,
      },
    },
  },
  {
    human: 'Add note',
    districts: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    tooltip: {
      default: 'Select one or more students from the list',
      unique: 'Add a note for the selected students',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: SCHOOL_BATCH_ACTIONS_LABEL.ADD_NOTES_SCHOOL,
    iconStyle: 'note-add',
    type: 'MODAL',
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 5,
      },
      dropdown: {
        isVisible: false,
      },
    },
  },
  {
    human: 'Edit fields',
    districts: ['NYC', 'Schenectady', 'Uniondale'],
    tooltip: {
      default: tooltipDefault,
      unique: 'Edit data fields for the selected students',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'FIELDS',
    iconStyle: 'edit',
    type: 'MODAL',
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 6,
      },
      dropdown: {
        isVisible: true,
        sortOrder: 0,
      },
    },
  },
  {
    human: 'Generate report',
    districts: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    tooltip: {
      default: tooltipDefault,
      unique: 'Generate PDF report for the selected students',
    },
    tooltipStyle: toolTipDefaultStyle,
    key: 'REPORTS',
    userCanAccess: true,
    iconStyle: 'report',
    type: 'MODAL',
    hsOnly: false,
    menuTypes: {
      'side-nav': {
        isVisible: true,
        sortOrder: 7,
      },
      dropdown: {
        isVisible: false,
      },
    },
  },
];
