import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AttendanceGridService } from '../../../shelter/grid/attendance/services/attendance-grid.service';
import {
  LoadShelterGridDataSuccess,
  LOAD_SHELTER_GRID_DATA,
  SaveShelterGridData,
} from '../../actions/shelter-actions/shelter-grid-actions';

@Injectable()
export class ShelterGridDataEffects {
  constructor (
    private actions$: Actions,
    private attendanceGridService: AttendanceGridService,
    private store: Store<any>,
  ) {}

  loadShelterGridData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<any>(LOAD_SHELTER_GRID_DATA),
      switchMap(data => {
        const {
          payload: { shelterId, gridType },
        } = data;
        return this.attendanceGridService.getShelterGridConfig$({ shelterId, gridType });
      }),
      withLatestFrom(this.store),
      switchMap(([config, storeState]) => {
        const {
          data: { ShelterGridConfig: columnDefs },
        } = config;
        const {
          PORTAL_STORE: {
            shelterGridData: { shelterId },
          },
        } = storeState;
        this.store.dispatch(new SaveShelterGridData({ columnDefs }));
        const payload = this.attendanceGridService.getShelterGridDataPayload(columnDefs, shelterId);
        return of(payload);
      }),
      switchMap(payload => {
        return this.attendanceGridService.getShelterGridData$(payload);
      }),
      map(data => {
        const {
          data: { ShelterGridData: rowData },
        } = data;
        return new LoadShelterGridDataSuccess({ rowData });
      }),
    );
  });
}
