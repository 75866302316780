import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { NvDropdownItemModule } from '../nv-dropdown-item/nv-dropdown-item.module';
import { NvSidebarItemModule } from '../nv-sidebar-item/nv-sidebar-item.module';
import { NvSidebarListHeaderComponent } from './nv-sidebar-list-header/nv-sidebar-list-header.component';
import { NvSidebarListComponent } from './nv-sidebar-list.component';

@NgModule({
  imports: [CommonModule, NvSidebarItemModule, MatTreeModule, MatMenuModule, NvDropdownItemModule],
  declarations: [NvSidebarListComponent, NvSidebarListHeaderComponent],
  exports: [NvSidebarListComponent],
})
export class NvSidebarListModule {}
