import { Inject, Injectable } from '@angular/core';
import { each, keys, map, set, startsWith } from 'lodash';
import { IData, IProjection } from './student-fetch.service';

interface IBuildArrayToObjectFunction<T> {
  (): T;
}

/* istanbul ignore next */
@Injectable()
export class StudentFetchUnzipService {
  constructor () {
    //
  }

  public unzipData<T> (data: IData, projection?: IProjection) {
    const paths = data.paths;
    const values = data.values;
    const pathsToGet = projection && keys(projection);
    const arrayToObjectFunction = this.buildArrayToObjectFunction<T>(paths, pathsToGet);
    // <T,U>(func:'that takes T and returns U')
    const unzipedData = map<any[], T>(values, arrayToObjectFunction);

    return unzipedData;
  }

  /**
   * This method is used to parse the student list.search endpoint response
   * @param paths {Array} of dot notation paths
   * @param specific paths {Array} to get (reduces paths)
   * @returns {Function} that takes an array of values and returns an object
   */
  private buildArrayToObjectFunction<T> (paths: string[], pathsToGet?: string[]): IBuildArrayToObjectFunction<T> {
    const ret = {};
    const pathAndIndex: {
      paths: string[];
      idxs: number[];
    } = { paths: [], idxs: [] };
    let func: string;

    if (pathsToGet) {
      each(pathsToGet, pToReturn => {
        each(paths, (p, idx) => {
          if (startsWith(p, pToReturn)) {
            pathAndIndex.paths.push(p);
            pathAndIndex.idxs.push(idx);
          }
        });
      });
    } else {
      pathAndIndex.paths = paths;
    }

    each(pathAndIndex.paths, p => {
      set(ret, p, undefined);
    });
    func = `var ret = ${JSON.stringify(ret)};`;

    if (pathAndIndex.idxs.length) {
      each(pathAndIndex.paths, (p: any, idx: number) => {
        func = func + `ret.${p}=values[${pathAndIndex.idxs[idx]}];`;
      });
    } else {
      each(paths, (p, idx) => {
        func = func + `ret.${p}=values[${idx}];`;
      });
    }

    func = func + 'return ret;';
    const arrayToObjectFunction: any = new Function('values', func);

    return arrayToObjectFunction;
  }
}
