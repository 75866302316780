import { WizardHelpers } from './../../../services/wizard-helpers.service';
import { StarsMismatchStep } from './../shared-steps/stars-mismatch-step/stars-mismatch-step.component';
import { PriorityGroupStep } from './priority-group-step/priority-group-step.component';
import { PlanPastDueExamsStep } from '../shared-steps/plan-past-due-exams-step/plan-past-due-exams-step.component';
import { OnTrackRegentsStep } from './../shared-steps/on-track-regents-step/on-track-regents-step.component';
import { GradPlansStep } from './../shared-steps/grad-plans-step/grad-plans-step.component';
import { ExamLoadStep } from './../shared-steps/exam-load-step/exam-load-step.component';
import { RegentsPlanningConfigService } from './../../../../../../app/sdc/sdc-state/sdc-configs/regents-planning-config.service';
import { MatStepper } from '@angular/material/stepper';
import { SpinnerService } from '../../../../../shared/components/spinner/spinner-modal.service';
import { FormControl } from '@angular/forms';
import { ImSchool } from '../../../../../shared/services/im-models/im-school';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { tap, map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

import { Component, ViewChild, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { CulminatingCourseStep } from '../shared-steps/culminating-course-step/culminating-course-step.component';
import { ReadinessElaStep } from '../shared-steps/readiness-ela-step/readiness-ela-step.component';
import { ReadinessMathStep } from '../shared-steps/readiness-math-step/readiness-math-step.component';
import { RegentsExportStep } from '../shared-steps/regents-export-step/regents-export.component';
import { InstructionStep } from '../../../shared/components/wizard-steps/instruction-step/instruction-step.component';

@Component({
  selector: 'regents-planning-transfer-wizard',
  templateUrl: './regents-planning-transfer-wizard.component.html',
  styleUrls: ['./regents-planning-transfer-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegentsPlanningTransferWizard {
  public school$: Observable<ISchool>
  public flattenedStudents$: Observable<any>;
  public schoolSupports$: Observable<any>;
  public studentSupports$: Observable<any>;
  public classOfDropdownOptions: Array<{key: string, human: string}>
  public currentUser$: Observable<any>;
  public dropdownSelection;
  public spinner;
  private schoolId: string;
  public isLinear: boolean = false;
  public selectedStepIndex: number;
  public instructionDescription: string = `The Regents Planning wizard helps you decide which exams students will attempt in the upcoming Regents
  administration. The wizard presents recommendations based on common reasons why schools plan to sit students for
  exams and allows you to take bulk planning actions based on those reasons. The wizard supports efficient initial
  planning followed by an individual review of students who may need additional attention.`;

  constructor (
    private cd: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private imSchool: ImSchool,
    private spinnerService: SpinnerService,
    private wizardHelpers: WizardHelpers,
    private regentsPlanningConfigService: RegentsPlanningConfigService,
  ) {}

  @ViewChild(CulminatingCourseStep) culminatingCourseStep: CulminatingCourseStep;
  @ViewChild(InstructionStep) instructionStep: InstructionStep;
  @ViewChild(ExamLoadStep) examLoadStep: ExamLoadStep;
  @ViewChild(GradPlansStep) gradPlansStep: GradPlansStep;
  @ViewChild(PriorityGroupStep) priorityGroupStep: PriorityGroupStep;
  @ViewChild(OnTrackRegentsStep) onTrackRegentsStep: OnTrackRegentsStep;
  @ViewChild(PlanPastDueExamsStep) planPastDueExamsStep: PlanPastDueExamsStep;
  @ViewChild(ReadinessElaStep) readinessElaStep: ReadinessElaStep;
  @ViewChild(ReadinessMathStep) readinessMathStep:ReadinessMathStep;
  @ViewChild(RegentsExportStep) regentsExportStep:RegentsExportStep;
  @ViewChild(StarsMismatchStep) starsMismatchStep:StarsMismatchStep;

  @ViewChild(MatStepper) stepper: MatStepper;

  ngOnInit () {
    this.selectedStepIndex = this.activatedRoute.snapshot.queryParams.activeWizardStep || 0;
    this.spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.classOfDropdownOptions = this.wizardHelpers.generateTransferDropdown(this.imSchool.getNextFourGradDatesForTransfer());
    this.dropdownSelection = new FormControl(this.activatedRoute.snapshot.data?.filter?.dropdownSelection || 'All');
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.school$ = this.wizardHelpers.getSchool$();
    this.schoolSupports$ = this.wizardHelpers.getSchoolSupports$(this.schoolId);
    this.studentSupports$ = this.wizardHelpers.getStudentSupports$(this.schoolId);
    this.currentUser$ = this.wizardHelpers.getUser$();

    this.flattenedStudents$ = combineLatest([
      this.wizardHelpers.getSdcStudents(this.regentsPlanningConfigService),
      this.school$,
      this.dropdownSelection.valueChanges.pipe(startWith(this.dropdownSelection.value)),
    ]).pipe(
      tap(([_, __, dropdownSelection]) => this.wizardHelpers.updateUrl(dropdownSelection, this.selectedStepIndex)),
      map(([students, _, dropdownSelection]) =>
        this.wizardHelpers.getFilteredStudents({
          students,
          filters: this.wizardHelpers.getFilters(this.wizardHelpers.getGradPlanFilter(dropdownSelection, this.classOfDropdownOptions), { status: { values: ['A'] } }),
        })),
      tap(() => this.spinner.close()),
    );
  }

  ngAfterViewInit () {
    this.stepper._getIndicatorType = () => 'number';
  }

  ngAfterContentChecked () {
    this.cd.detectChanges();
  }

  public onStepChange ({ selectedIndex }) {
    this.selectedStepIndex = selectedIndex;
    this.wizardHelpers.updateUrl(this.dropdownSelection.value, this.selectedStepIndex);
  }

  public handleDropdownChange ($event) {
    this.dropdownSelection.patchValue($event);
  }
}
