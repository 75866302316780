import { StudentOtherSchoolType, TSingleStudentViewOtherSchoolTypes } from './../../shared/typings/interfaces/student.interface';
const ALL_SCHOOL_TYPES = ['ES', 'MS', 'HS'];

export type TValidStudentTypes = 'ES' | 'MS' | 'HS';

export interface IStudentPanel {
  id: string;
  human: string;
  studentTypes: TValidStudentTypes[];
  otherSchoolTypes?: TSingleStudentViewOtherSchoolTypes[];
  toggles?: string[];
}
export interface IStudentPanels {
  [propName: string]: IStudentPanel;
}

export const ProfileHeaderConfig = [
  {
    key: 'ID',
    human: 'ID',
    path: 'OSIS_NUMBER',
    isHS: [true, false],
    district: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data
  },
   {
    key: 'GRADE',
    human: 'GRADE',
    path: 'GRADE',
    isHS: [false],
    district: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data
  },
  {
    key: 'CLASS',
    human: 'CLASS',
    path: 'CLASS',
    isHS: [true],
    district: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data
  },
  {
    key: 'OFC',
    human: 'OFC',
    path: 'OFFICIAL_CLASS',
    isHS: [true, false],
    district: ['NYC', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data
  },
  {
    key: 'SWD',
    human: 'SWD',
    path: 'IS_SPED',
    isHS: [true, false],
    district: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data ? 'YES': 'NO'
  },
  {
    key: 'ML',
    human: 'ML',
    path: 'IS_ELL',
    isHS: [true, false],
    district: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data ? 'YES': 'NO'
  },
  {
    key: 'GRAD PLAN',
    path: ['IS_TRANSFER', 'NON_TRANSFER_GRADUATION_PLAN', 'TRANSFER_GRADUATION_PLAN'],
    human: 'GRAD PLAN',
    isHS: [true, false],
    district: ['NYC', 'Lansing', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [false],
    isUftDoeAdvisingStudent: [false],
    customFormat: ({ IS_TRANSFER, TRANSFER_GRADUATION_PLAN, NON_TRANSFER_GRADUATION_PLAN }) => {
      return IS_TRANSFER ? TRANSFER_GRADUATION_PLAN : NON_TRANSFER_GRADUATION_PLAN;
    },
  },
  {
    key: 'SAFETY NET',
    human: 'SAFETY NET',
    path: 'SAFETY_NET_ELIGIBLE',
    isHS: [true, false],
    district: ['NYC', 'Schenectady', 'Uniondale'],
    isSummerSchoolStudent: [false],
    isUftDoeAdvisingStudent: [false],
    customFormat: (data) => {
      switch(data){ 
        case 'Yes':
          return 'ELIGIBLE';
        case 'No':
          return 'NOT ELIGIBLE';
        default: 
          return '—';
      }
    }
  },
  {
    key: 'HOUSING STATUS',
    human: 'HOUSING STATUS',
    path: 'HOUSING_STATUS',
    isHS: [true, false],
    district: ['NYC'],
    isSummerSchoolStudent: [true, false],
    isUftDoeAdvisingStudent: [true, false],
    customFormat: (data) => data
  },
  // Learning Preference removed per POD-273
  // {
  //   key: 'LEARNING PREFERENCE',
  //   human: 'LEARNING PREFERENCE',
  //   path: 'learningPreference',
  //   isHS: [true, false],
  //   district: ['NYC'],
  //   isSummerSchoolStudent: [true, false],
  //   isUftDoeAdvisingStudent: [true, false],
  // }
];

/**
 * NOTE: Each panel has a config file in its directory that contains the panel title and paths.
 * The title there MUST match the human name here. TODO: Tighten up. - JYR.
 */
export const StudentPanelsConfig = {
  BASIC_INFO: {
    id: 'student-basic-info-panel',
    human: 'Basic Info',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  CONTACT_INFO: {
    id: 'student-contact-info-panel',
    human: 'Contact Info',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  ATTENDANCE: {
    id: 'student-attendance-panel',
    human: 'Attendance',
    studentTypes: ALL_SCHOOL_TYPES,
    hideForSummerSchoolStudent: false,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: ['TOGGLE_START_OF_SCHOOL_YEAR'],
  },
  ATTENDANCE_SUMMER: {
    id: 'student-attendance-summer-panel',
    human: 'Attendance',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: ['TOGGLE_SUMMER_SCHOOL'],
  },
  SCREENERS: {
    id: 'student-screeners-panel',
    human: 'Screeners',
    studentTypes: ['ES', 'MS', 'HS'],
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  FORM_ASSMNTS: {
    id: 'student-fa-panel',
    human: 'Formative Assessments',
    studentTypes: ['ES', 'MS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  ASSIGNED_SUPPORTS: {
    id: 'student-supports-panel',
    human: 'Supports',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  ACADEMIC: {
    id: 'student-academic-panel',
    human: 'Academic',
    studentTypes: ['ES', 'MS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  GRAD_PLANNING: {
    id: 'student-grad-panel-v3',
    human: 'Graduation',
    studentTypes: ['HS'],
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  REMOTE_LEARNING: {
    id: 'remote-learning-panel',
    human: 'Remote Learning',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  CURRENT_PROGRAM: {
    id: 'student-current-program-panel',
    human: 'Current Program',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  CREDITS: {
    id: 'student-credits-panel',
    human: 'Credits',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  CREDIT_GAPS: {
    id: 'student-credit-gaps-panel',
    human: 'Credit Gaps',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  REGENTS: {
    id: 'student-regents-panel',
    human: 'Regents',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  POINT_PEOPLE: {
    id: 'student-point-people-panel',
    human: 'Point People',
    studentTypes: ALL_SCHOOL_TYPES,
    otherSchoolTypes: [],
    districts: [
      'NYC',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
  POSTSECONDARY: {
    id: 'student-postsec-panel',
    human: 'Postsecondary',
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.OPSR],
    districts: ['NYC'],
    toggles: [],
  },
  TRANSCRIPT_AND_EXAM_HISTORY: {
    id: 'student-transcript-and-exam-history-panel',
    human: 'Transcript and Regents History',
    alternativePanelName: {
      Lansing: 'Transcript'
    },
    studentTypes: ['HS'],
    otherSchoolTypes: [StudentOtherSchoolType.SUMMER, StudentOtherSchoolType.OPSR],
    districts: [
      'NYC',
      'Lansing',
      'Schenectady',
      'Uniondale',
    ],
    toggles: [],
  },
};

export default StudentPanelsConfig;
