import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseModalComponent } from './../../base-modal.component';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExperienceModalService } from './experience-modal-service/experience-modal-service';
import { IExperience } from 'Src/ng2/shared/typings/interfaces/experiences.interface';

export interface IExperienceModalData {
  batchAction?: {
    studentIds: string[];
    schoolId: string;
  }
  editExperience?: IExperience;
  isProfileMode: boolean;
}

@Component({
  templateUrl: 'experiences-modal.component.html',
  styleUrls: ['./experiences-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ExperiencesModalComponent extends BaseModalComponent implements OnInit {
  readonly title = 'Add Experience';
  readonly ddPlaceHolder = 'Select a milestone';

  public options$: Observable<IDropdownOption[]>;
  public selectedOption$: BehaviorSubject<string> = new BehaviorSubject(null);
  public modalFocus = 'experiencesModal';

  public isProfileMode: boolean;
  public itemCount: number;

  constructor (
    dialogRef: MatDialogRef<ExperiencesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IExperienceModalData,
    private experienceModalService: ExperienceModalService,
  ) {
    super(dialogRef);
  }

  ngOnInit () {
    this.isProfileMode = this.data.isProfileMode;
    this.options$ = this.experienceModalService.getExperienceModalConfig$(this.modalFocus);

    if (this.data.batchAction) {
      // editing from batch actions
      this.itemCount = this.data.batchAction?.studentIds?.length || 1;
    } else if (this.data.editExperience?.milestone) {
      // editing from student profile
      this.onOptionChange(this.data.editExperience?.milestone);
    }
  }

  onOptionChange ($event) {
    this.selectedOption$.next($event);
  }

  onCancel (): void {
    super.close();
  }

  onSave ($event) {
    super.close({ ...$event, milestone: this.selectedOption$.value });
  }
}
