import { PlannedDiplomaType } from './../../../constants/planned-diploma-type.constant';
import { CREDIT_REQS } from './../../../constants/credit-reqs.constant';
import { ICourse } from './../../../../student/common-panels/remote-learning/models';
import { GraduationDate } from './../../../constants/graduation-date.constant';
import { SubjectAreas } from './../../../constants/subject-areas.constant';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { CreditRequirements } from './../../../constants/credit-requirements.constant';
import { ICurrProgramCourse, IStudent } from 'Src/ng2/shared/typings/interfaces/student.interface';
import { ICourseDiff } from 'Src/ng2/shared/typings/interfaces/course-diff.interface';
import { CreditGapsPriorityGroupingsTransferService } from './../../../../school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings-transfer.service';
import { UtilitiesService } from 'Src/ng2/shared/services/utilities/utilities.service';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { ImStudent } from '../im-student.service';
import { CreditGapsPriorityGroupingsService } from 'Src/ng2/school/sdc/services/credit-gaps-priority-groupings/credit-gaps-priority-groupings.service';
import { OnTrackStatus } from 'Src/ng2/shared/constants/on-track-status.constant';
import { IGapPlan } from 'Src/ng2/shared/typings/interfaces/gap-plan.interface';
import { GraduationPlan } from 'Src/ng2/shared/constants/graduation-plan.constant';

/**
 * `@depends` decorator
 *
 * Use this decorator to state that an ImStudentCreditGaps method depends on certain paths, joins or other methods:
 *    @depends({ paths: ['studentDetails.name'], joins: ['courseDiffs'], methods: ['_getSomething']})
 *    fullName(student) {  }
 *
 * @param depends Object an object with three optional keys: `paths`, `joins` and `methods`
 * @return {dependencyDecorator}
 */
function depends (depends) {
  return function dependencyDecorator (target, key, descriptor) {
    descriptor.value.depends = depends;
    return descriptor;
  };
}

@Injectable()
export class ImStudentCreditGaps {
  constructor (
    private ImSchool: ImSchool,
    private ImStudent: ImStudent,
    private UtilitiesService: UtilitiesService,
    private CreditGapsPriorityGroupingsService: CreditGapsPriorityGroupingsService,
    private CreditGapsPriorityGroupingsTransferService: CreditGapsPriorityGroupingsTransferService,
  ) {}

  /**
   * Removes fields from `student` that are not included in the dependent paths or joins
   *
   * @param method
   * @returns {Student} masked student
   */
  maskedStudentForMethod (student, method) {
    const paths = this.pathsFor(method);
    const joins = this.joinsFor(method);
    _.each(joins, join => {
      paths.push(`join_${join}`);
    });
    return this.UtilitiesService.generatePatch(student, paths);
  }

  _dependentMethods (method) {
    const resolvedMethods = [];
    const unresolvedMethods = [];

    // See here for the dependency resolution algorithm used:
    // https://www.electricmonk.nl/log/2008/08/07/dependency-resolving-algorithm/
    const resolveMethods = (method, resolvedMethods, unresolvedMethods) => {
      unresolvedMethods.push(method);
      const methods = (this[method].depends || {}).methods || [];
      _.each(methods, m => {
        if (!_.includes(resolvedMethods, m)) {
          if (_.includes(unresolvedMethods, m)) {
            throw new Error(`Circular method dependency detected: ${method} -> ${m}`);
          }
          resolveMethods(m, resolvedMethods, unresolvedMethods);
        }
      });
      resolvedMethods.push(method);
      _.pull(unresolvedMethods, method);
    };

    resolveMethods(method, resolvedMethods, unresolvedMethods);
    return resolvedMethods;
  }

  /**
   * Traverses the method dependency graph and returns all of the paths that `method` depends on
   *
   * @param method a ImStudentCreditGaps method
   * @return {Array} and array of paths
   */
  pathsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) {
      const paths = (this[method].depends || {}).paths;
      if (!_.isArray(paths)) {
        console.warn(
          `ImStudent#pathsFor('${method}') was called, but ${method} didn't declare any dependent paths. ` +
            `If ${method} doesn't depend on any paths, annotate it with \`@depends({ paths: [] })\``,
        );
      }
      return paths;
    }
    const dependentMethods = this._dependentMethods(method);
    const dependentPaths = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.paths || [];
        }),
      ),
    );
    return dependentPaths;
  }

  /**
   * Traverses the method dependency graph and returns all of the joins that `method` depends on
   *
   * @param method a ImStudentCreditGaps method
   * @return {Array} and array of joins
   */
  joinsFor (method) {
    if (!this[method]) throw new Error(`method '${method}' does not exist`);

    if (!(this[method].depends || {}).methods) return (this[method].depends || {}).joins;
    const dependentMethods = this._dependentMethods(method);
    const dependentJoins = _.uniq(
      _.flatten(
        _.map(dependentMethods, m => {
          const depends = this[m].depends;
          if (!depends) {
            console.warn(`\`${m}\` was detected as a dependent method, but it didn't register any dependencies itself`);
            return [];
          }
          return depends.joins || [];
        }),
      ),
    );
    return dependentJoins;
  }

  @depends({ paths: ['gradDetails.threatsToGrad.creditGapThreat'] })
  isCreditsOnTrack (student) {
    const path = 'gradDetails.threatsToGrad.creditGapThreat';
    const status = { status: '' };

    if (this.UtilitiesService.getFieldByPath(student, path)) {
      status.status = OnTrackStatus.OFF;
    } else {
      status.status = OnTrackStatus.ON;
    }

    return status;
  }

  @depends({ methods: ['_sumCourseDiffGaps'] })
  _calculatePendingGapsForGradReqs (student, courseDiffs, school, _gradReqs) {
    const componentGradReqs = _gradReqs || [];
    const change = _.reduce(
      componentGradReqs,
      (sum, req) => {
        // For each component requirement, filter all pending courseDiffs and sum their credit values
        const creditChange = this._sumCourseDiffGaps(courseDiffs, req, school);
        return sum + creditChange;
      },
      0,
    );
    return change;
  }

  @depends({ methods: ['_calculatePendingGapsForGradReqs', 'getStartGapForGradReq'] })
  _getCreditGapPendingChangesForSsGovtEcon (student, courseDiffs, school) {
    const ssEconPending = this._calculatePendingGapsForGradReqs(student, courseDiffs, school, ['ssEcon']);
    const ssEconStartGap = this.getStartGapForGradReq(student, 'ssEcon');
    const ssEconNet = ssEconStartGap + ssEconPending;

    const ssGovtPending = this._calculatePendingGapsForGradReqs(student, courseDiffs, school, ['ssGovt']);
    const ssGovtStartGap = this.getStartGapForGradReq(student, 'ssGovt');
    const ssGovtNet = ssGovtStartGap + ssGovtPending;

    // Both are flex, sum and return
    if (ssEconNet <= 0 && ssGovtNet <= 0) {
      const ssEconHasPositiveStartGap = ssEconStartGap >= 0;
      const ssGovtHasPositiveStartGap = ssGovtStartGap >= 0;
      const bothHavePositiveStartGap = ssEconHasPositiveStartGap && ssGovtHasPositiveStartGap;
      const bothHaveNegativeStartGap = !ssEconHasPositiveStartGap && !ssGovtHasPositiveStartGap;

      if (bothHavePositiveStartGap || bothHaveNegativeStartGap) return ssEconPending + ssGovtPending;
      if (ssGovtHasPositiveStartGap) return ssEconPending;
      if (ssEconHasPositiveStartGap) return ssGovtPending;
    }

    // Both are gaps, sum and return
    if (ssEconNet >= 0 && ssGovtNet >= 0) {
      return ssEconPending + ssGovtPending;
    }

    // Return the total number of gaps closed, exlusive of flex
    const ssEconNumReduced = Math.min(Math.abs(ssEconStartGap), ssEconPending);
    const ssGovtNumReduced = Math.min(Math.abs(ssGovtStartGap), ssGovtPending);
    return ssEconNumReduced + ssGovtNumReduced;
  }

  @depends({ methods: ['getStartGapForGradReq'] })
  _getCreditGapPlansForSsGovtEcon (student, gapPlans) {
    function countByGradReq (gradReq) {
      return _.reduce(
        gapPlans,
        (sum, gapPlan: IGapPlan) => {
          const thisReq = gapPlan.gradReq;

          // Only ACTIVE gapPlans should be counted
          if (gapPlan.status !== 'ACTIVE') return sum;

          if (gradReq === thisReq) {
            sum += gapPlan.creditValue;
          }
          return sum;
        },
        0,
      );
    }

    const ssEconPlans = countByGradReq('ssEcon');
    const ssEconStartGap = this.getStartGapForGradReq(student, 'ssEcon');

    const ssGovtPlans = countByGradReq('ssGovt');
    const ssGovtStartGap = this.getStartGapForGradReq(student, 'ssGovt');

    const ssGovtEconPlans = countByGradReq('ssGovtEcon');
    const ssGovtEconStartGap = this.getStartGapForGradReq(student, 'ssGovtEcon');

    // Return the total number of gaps closed, exlusive of flex
    const ssEconNumReduced = Math.min(Math.abs(ssEconStartGap), ssEconPlans);
    const ssGovtNumReduced = Math.min(Math.abs(ssGovtStartGap), ssGovtPlans);
    const ssGovtEconNumReduced = Math.min(Math.abs(ssGovtEconStartGap), ssGovtEconPlans);
    return ssEconNumReduced + ssGovtNumReduced + ssGovtEconNumReduced;
  }

  @depends({ paths: [] })
  _sumCourseDiffGaps (courseDiffs: ICourseDiff[], req, school) {
    return _.chain(courseDiffs)
      .filter(diff => {
        if (req === 'total') return true;
        const courseReq = this.ImSchool.getGradReqForCourse(school, diff.courseId);
        return courseReq === req && diff.status === 'PENDING';
      })
      .reduce((count, diff) => {
        const coeff = this._calculateCoefficientForCourse(diff);
        // Invert the value
        const val = coeff * this.ImSchool.getCreditValueForCourse(school, diff.courseId);
        return count + val;
      }, 0)
      .value();
  }

  /**
   * @param {String} gradReq - a value in CreditSubject[CONSTANT].machineName
   * @returns {Numeric} sum of pending course ADD (+) and DROP (-)
   */
  @depends({
    methods: ['_getCreditGapPendingChangesForSsGovtEcon', '_calculatePendingGapsForGradReqs'],
  })
  getCreditGapPendingChangesForGradReq (student, _courseDiffs, school, gradReq): number {
    let gap;
    let componentGradReqs = [];
    const courseDiffs = _courseDiffs || [];

    if (gradReq === 'total') {
      componentGradReqs.push('total');
    } else if (gradReq === 'ssGovtEcon') {
      gap = this._getCreditGapPendingChangesForSsGovtEcon(student, courseDiffs, school);
      return gap;
    } else if (gradReq === 'sciTotal') {
      componentGradReqs = componentGradReqs.concat(['sciLife', 'sciPhysical', 'sciOther']);
    } else {
      componentGradReqs.push(gradReq);
    }
    gap = this._calculatePendingGapsForGradReqs(student, courseDiffs, school, componentGradReqs);
    return gap;
  }

  _calculateCoefficientForCourse (diff) {
    const coeff = diff.action === 'ADD' ? 1 : diff.action === 'DROP' ? -1 : 0;
    return coeff;
  }

  @depends({ methods: ['_getCreditGapPlansForSsGovtEcon'] })
  getCreditGapPlansForGradReq (student, gapPlans, gradReq): number {
    let componentReqs = [];

    if (gradReq === 'sciTotal') {
      componentReqs = componentReqs.concat(['sciPhysical', 'sciLife', 'sciOther', 'sciTotal']);
    } else if (gradReq === 'ssGovtEcon') {
      return this._getCreditGapPlansForSsGovtEcon(student, gapPlans);
    } else {
      componentReqs.push(gradReq);
    }

    return _.reduce(
      gapPlans,
      (sum, gapPlan: IGapPlan) => {
        const req = gapPlan.gradReq;

        // Only ACTIVE gapPlans should be counted
        if (gapPlan.status !== 'ACTIVE') return sum;

        if (_.includes(componentReqs, req) || gradReq === 'total') {
          sum += gapPlan.creditValue;
        }
        return sum;
      },
      0,
    );
  }

  /**
   *
   * @param school
   * @param student
   * @param req
   */
  @depends({ paths: ['studentProgramDetails.currSy'] })
  getCreditsScheduledForGradReq (student: IStudent, req, school) {
    if (student.studentProgramDetails.currSy !== null) {
      /**
       *
       * Biz requirement for CreditGapsPanel is to loop through student's current program:
       *
       * `student.studentProgramDetails.currSy`
       *
       * in order to get the gradReq and creditValue for each course in the program, using the methods below.
       *
       * However, each course in current program already has those properties defined. I
       * confirmed with Carlos that the courses listed in `student.studentProgramDetails.currSy'
       * pull their gradReq and creditValue info from `school.masterSchedule`, which is where
       * the two IMSchool methods listed below get their data to begin with.
       *
       * So this is probably redundant. But leaving for now until can check with DC.
       *
       */

      // return a credits scheduled total based on credit requirement
      const currentTermYear: number = this.ImSchool.getCurrentTermYear(school);
      const { currSy } = student.studentProgramDetails;
      const program: ICurrProgramCourse[] = _.filter(currSy, { termYear: currentTermYear });

      switch (req) {
        case CreditRequirements.TOTAL.camelCase:
          return _.reduce(
            program,
            (total, course) => {
              return total + course.creditValue;
            },
            0,
          );

        case CreditRequirements.SCI_TOTAL.camelCase:
          return _.sum(
            _.map(program, course => {
              if (
                _.includes(
                  [
                    CreditRequirements.SCI_PHYSICAL.camelCase,
                    CreditRequirements.SCI_LIFE.camelCase,
                    CreditRequirements.SCI_OTHER.camelCase,
                  ],
                  course.gradReq,
                )
              ) {
                return course.creditValue;
              }
            }),
          );

        case CreditRequirements.SS_GOVT_ECON.camelCase:
          return _.sum(
            _.map(program, course => {
              const valid = [
                CreditRequirements.SS_GOVT.camelCase,
                CreditRequirements.SS_ECON.camelCase,
                CreditRequirements.SS_GOVT_ECON.camelCase,
              ];
              if (_.includes(valid, course.gradReq)) {
                return course.creditValue;
              }
            }),
          );

        default: {
          // filter the student program to return courses that match the individual requirement
          const coursesInGradReq = _.filter(program, { gradReq: req });
          return _.sum(
            _.map(coursesInGradReq, (course: ICurrProgramCourse) => {
              return course.creditValue;
            }),
          );
        }
      }
    }
  }

  @depends({
    methods: ['getStartGapForGradReq', 'getCreditGapPendingChangesForGradReq', 'getCreditGapPlansForGradReq'],
  })
  getNetGapsForGradReq (student, courseDiffs, gapPlans, school, gradReq): number {
    // student has courseDiffs, gapPlans
    const startGap = this.getStartGapForGradReq(student, gradReq);
    const pendingChange = this.getCreditGapPendingChangesForGradReq(student, courseDiffs, school, gradReq);
    const planChange = this.getCreditGapPlansForGradReq(student, gapPlans, gradReq);

    // gapPlans cannot increase the size of flex, can only decrease gaps
    const pendingGaps = startGap + pendingChange;
    const futureGaps = pendingGaps + planChange;

    if (pendingGaps > 0) {
      // If pending gaps result in flex, no need to subtract futureGaps
      return Number(pendingGaps.toFixed(2));
    } else if (futureGaps <= 0) {
      // else if planChange does not result in increased flex size, return it
      return Number(futureGaps.toFixed(2));
    } else if (futureGaps > 0) {
      // else if future gaps would increase total gaps beyond zero, return zero
      return 0;
    } else {
      return Number(pendingGaps.toFixed(2));
    }
  }

  // TODO: This should be memoized, no? (JC)
  @depends({
    paths: _.union(ImStudent.prototype.pathsFor(['getCoursesForCurrentTermYear'])),
    methods: [],
  })
  getCoursesForCurrentTermYearWithoutDropCourseDiffs (
    student: IStudent,
    courseDiffs: ICourseDiff[],
  ): ICurrProgramCourse[] {
    const studentCourses: ICurrProgramCourse[] = this.ImStudent.getCoursesForCurrentTermYear(student);

    _.each(courseDiffs, studentDiff => {
      _.remove(studentCourses, course => {
        return course.courseId === studentDiff.courseId && studentDiff.action === 'DROP';
      });
    });
    return studentCourses;
  }

  // TODO: Should this be memoized ?? (JC)
  @depends({
    methods: ['getCoursesForCurrentTermYearWithoutDropCourseDiffs'],
    paths: ['creditDetails.transcript'],
  })
  canCourseBeAddedToProgram (
    student: IStudent,
    school: ISchool,
    courseDiffs: ICourseDiff[],
    courseCode: string,
    section: string,
  ): boolean {
    const coursesWithoutDropCourseDiffs = this.getCoursesForCurrentTermYearWithoutDropCourseDiffs(student, courseDiffs);

    // Needed for PE comparisons --> Only course-section combo is invalid for PE
    const courseCodeSection = courseCode + '-' + section;

    // This will be courseCodes for non PE courses, and courseCodeSections for PE
    const netCourseCodes = [];

    const isGym = courseCode => {
      if(school.district === 'NYC') return courseCode.slice(0, 2) === 'PP';
      else return false;
    };

    // Add course codes for current courses - "drop" diffs
    _.each(coursesWithoutDropCourseDiffs, course => {
      const courseCode = course.courseCode;
      const courseIsGym = isGym(courseCode);
      if (courseIsGym) {
        const courseCodeSection = courseCode + '-' + course.section;
        netCourseCodes.push(courseCodeSection);
      } else netCourseCodes.push(courseCode);
    });

    // Add course codes for "add" diffs
    _.each(courseDiffs, courseDiff => {
      if (courseDiff.action === 'ADD') {
        // TODO: implement instance mehtod getCourseCode()
        const courseCode = courseDiff.courseId && courseDiff.courseId.split('-')[2];
        const courseIsGym = isGym(courseCode);

        if (courseIsGym) netCourseCodes.push(courseDiff.courseId);
        else netCourseCodes.push(courseCode);
      }
    });

    const isPlannedForCurrTerm = () => {
      const courseIsGym = isGym(courseCode);
      const nonGymIsFound = !courseIsGym && _.includes(netCourseCodes, courseCode);
      const gymIsFound = courseIsGym && _.includes(netCourseCodes, courseCodeSection);
      return nonGymIsFound || gymIsFound;
    };

    const wasAlreadyEarned = () => {
      // Gym credit can be earned for course codes already on transcript
      if (isGym(courseCode)) {
        return false;
      }

      const transcript = student.creditDetails.transcript;
      const subjectAreasThatCannotBeTakenIfPassedObj = _.filter(SubjectAreas, { canBeTakenIfPassed: false });
      const subjectAreasThatCannotBeTakenIfPassed = _.map(subjectAreasThatCannotBeTakenIfPassedObj, 'camelCase');

      const result = _.every(transcript, course => {
        if (course.creditValue > 0 && course.passFailEquivalent === 'P') {
          if (course.courseCode === courseCode) {
            return !_.includes(subjectAreasThatCannotBeTakenIfPassed, course.subjectArea);
          }
        }
        return true;
      });

      return !result;
    };

    const isPlanned = isPlannedForCurrTerm();
    const wasEarned = wasAlreadyEarned();
    const canBeAdded = !isPlanned && !wasEarned;
    return canBeAdded;
  }

  // CREDIT GAP TODO -- ADD TESTS
  @depends({
    methods: ['getGapsWithNoPlanForGradReq', '_getSumOfSubjectSpecificGapsWithNoPlan', '_getMaxSciGapWithNoPlan'],
  })
  getMaxGapsWithNoPlan (student, courseDiffs, gapPlans, school) {
    const fortyFourReqGapsWithNoPlan = this.getGapsWithNoPlanForGradReq(
      student,
      courseDiffs,
      gapPlans,
      school,
      'total',
    );
    const sumSubjectSpecificGapsWithNoPlan = this._getSumOfSubjectSpecificGapsWithNoPlan(
      student,
      courseDiffs,
      gapPlans,
      school,
    );
    const maxSciGapWithNoPlan = this._getMaxSciGapWithNoPlan(student, courseDiffs, gapPlans, school);
    const subjectSpecificAndMaxSci = sumSubjectSpecificGapsWithNoPlan + maxSciGapWithNoPlan;
    const maxGap = Math.max(subjectSpecificAndMaxSci, fortyFourReqGapsWithNoPlan);
    const maxGapAsNegative = maxGap * -1;
    return maxGapAsNegative;
  }

  // Difficult to test, skipping
  @depends({ methods: ['getGapsWithNoPlanForGradReq'] })
  _getSumOfSubjectSpecificGapsWithNoPlan (student, courseDiffs, gapPlans, school) {
    let sum = 0;
    _.forEach(CreditRequirements, creditRequirement => {
      const gradReq = creditRequirement.camelCase;
      const isIncludedInCreditGapsPanel = creditRequirement.orders.creditGapPanel > 0;
      const subjectAreasToExclude = ['total', 'cte', 'sciTotal', 'sciPhysical', 'sciLife'];
      const isSubjectSpecificGap = !_.includes(subjectAreasToExclude, gradReq);
      if (isSubjectSpecificGap && isIncludedInCreditGapsPanel) {
        const subjectSpecificGap = this.getGapsWithNoPlanForGradReq(student, courseDiffs, gapPlans, school, gradReq);
        sum = sum + subjectSpecificGap;
      }
    });
    return sum;
  }

  // CREDIT GAP TODO -- ADD TESTS
  @depends({ methods: ['getGapsWithNoPlanForGradReq'] })
  _getMaxSciGapWithNoPlan (student, courseDiffs, gapPlans, school) {
    const sciTotalGapWitNoPlan = this.getGapsWithNoPlanForGradReq(student, courseDiffs, gapPlans, school, 'sciTotal');
    const sciPhysicalWithNoPlan = this.getGapsWithNoPlanForGradReq(
      student,
      courseDiffs,
      gapPlans,
      school,
      'sciPhysical',
    );
    const sciLifeGapWithNoPlan = this.getGapsWithNoPlanForGradReq(student, courseDiffs, gapPlans, school, 'sciLife');
    return Math.max(sciPhysicalWithNoPlan + sciLifeGapWithNoPlan, sciTotalGapWitNoPlan);
  }

  // CREDIT GAP TODO -- ADD TESTS
  @depends({ methods: ['getNetGapsForGradReq'] })
  getGapsWithNoPlanForGradReq (student, courseDiffs, gapPlans, school, gradReq) {
    const netGap = this.getNetGapsForGradReq(student, courseDiffs, gapPlans, school, gradReq);
    const netGapWithoutFlex = Math.min(0, netGap);
    const netGapWithoutFlexAsPositive = Math.abs(netGapWithoutFlex);
    return netGapWithoutFlexAsPositive;
  }

  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor(['getStudentYear']),
      ...ImStudent.prototype.pathsFor(['isActive']),
      ...ImStudent.prototype.pathsFor(['isPlannedToGraduateThisSchoolYear']),
      ...ImStudent.prototype.pathsFor(['getCurrentGradPlan']),
      'studentDetails.classOf',
    ],
    methods: ['getMaxGapsWithNoPlan'],
  })
  getCreditGapPriorityGrouping (student, courseDiffs, gapPlans, school) {
    const studentYear = this.ImStudent.getStudentYear(student);
    const studentIsActive = this.ImStudent.isActive(student);
    const studentIsHs = student.isHS;
    if (!studentIsHs) return 'n/a'; // middle school students don't get a priority
    if (!studentIsActive) return 'n/a'; // in active students don't get a priority

    // get max gap with no plan
    const creditMaxGapNoPlan = this.getMaxGapsWithNoPlan(student, courseDiffs, gapPlans, school);
    const hasGapsWithNoPlan = creditMaxGapNoPlan < 0;

    // we only care about students with unaddressed gaps!
    if (!hasGapsWithNoPlan) return 'No Unaddressed Gaps';

    // seniors and older
    if (studentYear >= 4) {
      // get student's grad plan
      const gradPlan = this.ImStudent.getCurrentGradPlan(student);
      const gradPlanIsIncomplete = gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
      const gradPlanIsInPast = gradPlan === GraduationPlan.PLAN_IN_PAST.humanName;
      const nonGrad = gradPlan === GraduationPlan.NON_GRADUATE.humanName;

      if (nonGrad) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_NON_GRAD'); }

      if (gradPlanIsIncomplete || gradPlanIsInPast) {
        return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLAN_INCOMPLETE');
      }

      // get student's planned graduation date
      const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;
      const plannedGraduationDateDetails = _.find(GraduationDate, { humanName: plannedGraduationDate });
      const plannedGraduationMonthOrder = plannedGraduationDateDetails.monthOrder;

      // see if the student is planned to grad this year
      const plannedToGradThisYear = this.ImStudent.isPlannedToGraduateThisSchoolYear(student);

      if (plannedToGradThisYear) {
        if (plannedGraduationMonthOrder === 1 || plannedGraduationMonthOrder === 2) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_JAN_MARCH'); }
        if (plannedGraduationMonthOrder === 3) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_JUNE'); }
        if (plannedGraduationMonthOrder === 4) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_AUG'); }
      } else {
        return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('PLANNED_BEYOND_AUG');
      }
    }

    // younger cohorts
    if (studentYear === 3) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('JUNIORS'); }
    if (studentYear === 2) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('SOPHOMORES'); }
    if (studentYear === 1) { return this.CreditGapsPriorityGroupingsService.getStudentValueForPriorityGroupByKey('FRESHMAN'); }
  }

  @depends({
    paths: [
      ...ImStudent.prototype.pathsFor(['isActive']),
      ...ImStudent.prototype.pathsFor(['isPlannedToGraduateThisSchoolYear']),
      ...ImStudent.prototype.pathsFor(['getCurrentGradPlan']),
      'studentDetails.classOf',
      'isHS',
    ],
    methods: ['getMaxGapsWithNoPlan'],
  })
  getCreditGapPriorityGroupingTransfer (student, courseDiffs, gapPlans, school) {
    const studentIsActive = this.ImStudent.isActive(student);
    const studentIsHs = student.isHS;
    if (!studentIsHs) return 'n/a'; // middle school students don't get a priority
    if (!studentIsActive) return 'n/a'; // in active students don't get a priority

    // get max gap with no plan
    const creditMaxGapNoPlan = this.getMaxGapsWithNoPlan(student, courseDiffs, gapPlans, school);
    const hasGapsWithNoPlan = creditMaxGapNoPlan < 0;

    // we only care about students with unaddressed gaps!
    if (!hasGapsWithNoPlan) { return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('NONE'); }

    // get student's grad plan
    const gradPlan = this.ImStudent.getCurrentGradPlan(student);
    const gradPlanIsIncomplete = gradPlan === GraduationPlan.PLAN_INCOMPLETE.humanName;
    const gradPlanIsInPast = gradPlan === GraduationPlan.PLAN_IN_PAST.humanName;
    const nonGrad = gradPlan === GraduationPlan.NON_GRADUATE.humanName;

    if (nonGrad) { return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLANNED_NON_GRAD'); }
    if (gradPlanIsIncomplete || gradPlanIsInPast) {
      return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLAN_INCOMPLETE');
    }

    // get student's planned graduation date
    const plannedGraduationDate = student.gradPlanningDetails.plannedGraduationDate;

    // see if the student is planned to grad this year
    const next4GradDates = this.ImSchool.getNextFourGradDatesForTransfer();
    const indexOfPlannedGradDateInNext4 = _.findIndex(next4GradDates, { humanName: plannedGraduationDate });
    const isPlannedToGradInNext4Terms = indexOfPlannedGradDateInNext4 >= 0;

    if (isPlannedToGradInNext4Terms) {
      const plannedTermsAwayFromGrad = indexOfPlannedGradDateInNext4 + 1;
      if (plannedTermsAwayFromGrad === 1) { return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('ONE_TERM_AWAY'); }
      if (plannedTermsAwayFromGrad === 2) { return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('TWO_TERMS_AWAY'); }
      if (plannedTermsAwayFromGrad === 3) { return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('THREE_TERMS_AWAY'); }
      if (plannedTermsAwayFromGrad === 4) { return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('FOUR_TERMS_AWAY'); }
    } else {
      return this.CreditGapsPriorityGroupingsTransferService.getStudentValueForPriorityGroupByKey('PLANNED_BEYOND_4');
    }
  }

  @depends({
    methods: ['getStartGapForGradReq', 'getCreditGapPendingChangesForGradReq'],
    joins: ['pendingCourseDiffs'],
  })
  getGapsResolvedByPendingCourseDiffs (student, school, gradReq) {
    const startGap = this.getStartGapForGradReq(student, gradReq);
    // grad reqs with flex will have 0 gaps resolved through course diffs
    if (startGap > 0) return 0;
    const termYear = this.ImSchool.getCurrentTermYear(school);
    const pendingCourseDiffsForCurrentTermYear = _.filter(student.join_pendingCourseDiffs, { termYear });
    const pendingChangesForGradReq = this.getCreditGapPendingChangesForGradReq(
      student,
      pendingCourseDiffsForCurrentTermYear,
      school,
      gradReq,
    );
    const startGapAsPositive = Math.abs(startGap);
    return Math.min(startGapAsPositive, pendingChangesForGradReq);
  }

  @depends({
    methods: ['getStartGapForGradReq', 'getGapsResolvedByPendingCourseDiffs', 'getCreditGapPlansForGradReq'],
    joins: ['activeGapPlans'],
  })
  getGapsResolvedByPendingGapPlans (student, school, gradReq) {
    const startGap = this.getStartGapForGradReq(student, gradReq);
    // grad reqs with flex will have 0 gaps resolved through gap plans
    if (startGap > 0) return 0;
    const gapsResolvedByPendingChanges = this.getGapsResolvedByPendingCourseDiffs(student, school, gradReq);
    const gapPlansForGradReq = this.getCreditGapPlansForGradReq(student, student.join_activeGapPlans, gradReq);
    const startGapAsPositive = Math.abs(startGap);
    const remainingGapsToBeClosed = startGapAsPositive - gapsResolvedByPendingChanges;
    return Math.min(remainingGapsToBeClosed, gapPlansForGradReq);
  }

  /**
   * Checks to see if a course in school.masterSchedule conflicts
   * with a course on student.studentProgramDetails.currSy
   * A conflict occurs if the period of the two courses are
   * the same and there is at least one overlap in cycle days
   * @param {Object} student
   * @param {Object} school
   * @param {Array} of courseDiffs
   * @param {String} courseId of a course on school.masterSchedule
   * @return {Array} of course on student.studentProgramDetails.currSy that c
   * onflict or empty array if no conflict exists
   */
  @depends({
    methods: ['getCoursesForCurrentTermYearWithoutDropCourseDiffs', 'appendCycleDayToProgram'],
  })
  getCoursesConfictingWithCourseBeingAdded (
    student: IStudent,
    school: ISchool,
    courseDiffs: ICourseDiff[],
    courseIdOfCourseToBeAdded: string,
  ): ICourse[] {
    const courseToBeAdded = this.ImSchool.getCourseByIdFromMasterSchedule(school, courseIdOfCourseToBeAdded);
    const courseToBeAddedCyclePeriodArr = courseToBeAdded.cyclePeriod && courseToBeAdded.cyclePeriod.split('-');
    const studentCourses = this.getCoursesForCurrentTermYearWithoutDropCourseDiffs(student, courseDiffs);

    const conflictingCourses: ICourse[] = _.reduce(
      studentCourses,
      (acc, studentCourse) => {
        const studentCourseCyclePeriodArr = studentCourse.cyclePeriod && studentCourse.cyclePeriod.split('-');
        const conflictExists = this.isThereACyclePeriodConflict(
          studentCourseCyclePeriodArr,
          courseToBeAddedCyclePeriodArr,
        );

        if (conflictExists) {
          acc.push(studentCourse);
        }

        return acc;
      },
      [],
    );

    return conflictingCourses;
  }

  /**
   * Checks to see if two courses have a conflicting cyclePeriod
   * @param {Array} of strings resulting from course.cyclePeriod.split('-')
   * @param {Array} of strings resulting from course.cyclePeriod.split('-')
   * @return {Boolean}
   */
  @depends({ paths: [] })
  private isThereACyclePeriodConflict (course1CyclePeriodArr: string[], course2CyclePeriodArr: string[]): boolean {
    const conflictExists = _.some(course1CyclePeriodArr, (course1PeriodsForDay, indx) => {
      const course2PeriodsForDay = course2CyclePeriodArr[indx];

      return this.isThereAConflictForPeriodsForDay(course1PeriodsForDay, course2PeriodsForDay);
    });

    return conflictExists;
  }

  /**
   * Checks if course periods for a day between two courses conflict
   * @param {String} a course periods for a day found in course.cyclePeriod (e.g. 'X', '8', or '3,5')
   * @param {String} a course periods for a day found in course.cyclePeriod (e.g. 'X', '8', or '3,5')
   * @return {Boolean}
   */
  private isThereAConflictForPeriodsForDay (
    course1PeriodsForDay: string = 'X',
    course2PeriodsForDay: string = 'X',
  ): boolean {
    const course1IsNotScheduledForDay = course1PeriodsForDay === 'X';
    const course2IsNotScheduledForDay = course2PeriodsForDay === 'X';
    let periodsForDayConflict: boolean;

    if (course1IsNotScheduledForDay || course2IsNotScheduledForDay) {
      periodsForDayConflict = false; // cyclePeriod is not scheduled and is open
    } else {
      // standardization takes into account potential of block scheduling
      const course1PeriodsForDayStandardized = course1PeriodsForDay.split(',');
      const course2PeriodsForDayStandardized = course2PeriodsForDay.split(',');

      periodsForDayConflict = _.some(course1PeriodsForDayStandardized, period => {
        return _.includes(course2PeriodsForDayStandardized, period);
      });
    }

    return periodsForDayConflict;
  }

  @depends({
    paths: [],
    methods: ['appendCycleDayToCourse'],
  })
  appendCycleDayToProgram (
    student: IStudent,
    school: ISchool,
    studentCourses: ICurrProgramCourse[],
  ): ICurrProgramCourse[] {
    const studentCoursesWithAppendedCycleDay = _.map(studentCourses, studentCourse => {
      const studentCourseWithAppendedCycleDay = this.appendCycleDayToCourse(student, school, studentCourse);
      return studentCourseWithAppendedCycleDay;
    });
    return studentCoursesWithAppendedCycleDay;
  }

  @depends({ paths: [] })
  appendCycleDayToCourse (student: IStudent, school: ISchool, studentCourse: ICurrProgramCourse): ICurrProgramCourse {
    const studentCourseCopy = _.cloneDeep(studentCourse);
    const courseFromMasterSchedule = _.find(school.masterSchedule, { courseId: studentCourseCopy.courseId });
    if (courseFromMasterSchedule) {
      studentCourseCopy.cycleDay = courseFromMasterSchedule.cycleDay;
    }
    return studentCourseCopy;
  }

  /*
   * @param {string} gradReq is machine name
   */
  @depends({
    methods: [],
    paths: [...ImStudent.prototype.pathsFor(['getStudentYear']), 'creditDetails.byArea'],
  })
  getStartGapForGradReq (student, gradReq, district: string = 'NYC'): number {
    // Applies to D75 and isMS.
    const studentYear = this.ImStudent.getStudentYear(student, district);
    if (studentYear === 0) return 0;
    const gap = student.creditDetails.byArea[gradReq].schoolCreditGap;
    const fixedGap = _.isNumber(gap) && Number(gap.toFixed(2)) || gap;
    return fixedGap;
  }

  /**
   * @returns {Array} of gradReqs with calculated net gaps that align with a gradReq
   */
  @depends({ methods: ['getNetGapsForGradReq'] })
  getNetGapsForAlignedGradReqs (student, courseDiffs, gapPlans, school, gradReq, district) {
    const alignedCreditRequirements = _.find(CreditRequirements, { camelCase: gradReq }).alignedCreditRequirements;

    return _.map(alignedCreditRequirements, gradReq => {
      return {
        camelCase: gradReq.camelCase,
        human: gradReq.human[district],
        netGaps: this.getNetGapsForGradReq(student, courseDiffs, gapPlans, school, gradReq.camelCase),
      };
    });
  }

  // RELIES ON IMSTUDENT MODEL:
  @depends({
    paths: _.union(
      ImStudent.prototype.pathsFor(['getEffectiveCohort']),
      ImStudent.prototype.pathsFor(['getStudentCurrentTerm']),
    ),
    methods: [],
  })
  getStudentOnTrackMetrics (student, school) {
    const studentCohort = this.ImStudent.getEffectiveCohort(student);
    const schoolCreditMetrics = this.ImSchool.getSchoolCreditMetrics(school, studentCohort);
    const studentCurrentTerm = this.ImStudent.getStudentCurrentTerm(student, school);
    let studentOnTrackMetrics: any = _.find(schoolCreditMetrics, { term: studentCurrentTerm });
    // studentOnTrackMetrics is defined, return it
    // student is middle-schooler ie: !studentCohort -- return null studentOnTrackMetrics
    // student is super senior -- ie: studentCohort / !studentOnTrackMetrics --
    //    return last term in schoolCreditMetrics
    studentOnTrackMetrics =
      studentOnTrackMetrics || !studentCohort ? studentOnTrackMetrics : _.last(schoolCreditMetrics);

    if (studentOnTrackMetrics) {
      studentOnTrackMetrics.ssGovtEcon = studentOnTrackMetrics.ssGovt + studentOnTrackMetrics.ssEcon;
    }

    return studentOnTrackMetrics || null;
  }

  // RELIES ON IMSTUDENT MODEL:
  @depends({
    paths: _.union(
      ImStudent.prototype.pathsFor(['getEffectiveCohort']),
      ImStudent.prototype.pathsFor(['getStudentCurrentTerm']),
    ),
    methods: [],
  })
  getStudentOnTrackMetricsForPriorTerm (student, school) {
    const studentCohort = this.ImStudent.getEffectiveCohort(student);
    const schoolCreditMetrics = this.ImSchool.getSchoolCreditMetrics(school, studentCohort);
    const studentCurrentTerm = this.ImStudent.getStudentCurrentTerm(student, school);
    const studentPriorTerm = studentCurrentTerm - 1;

    // If student is in the first term, return null;
    if (studentPriorTerm <= 0) return null;

    let studentOnTrackMetrics: any = _.find(schoolCreditMetrics, { term: studentPriorTerm });
    // studentOnTrackMetrics is defined, return it
    // student is middle-schooler ie: !studentCohort -- return null studentOnTrackMetrics
    // student is super senior -- ie: studentCohort / !studentOnTrackMetrics --
    //    return last term in schoolCreditMetrics
    studentOnTrackMetrics =
      studentOnTrackMetrics || !studentCohort ? studentOnTrackMetrics : _.last(schoolCreditMetrics);

    if (studentOnTrackMetrics) {
      studentOnTrackMetrics.ssGovtEcon = studentOnTrackMetrics.ssGovt + studentOnTrackMetrics.ssEcon;
    }

    return studentOnTrackMetrics || null;
  }

  @depends({
    paths: ['gradPlanningDetails.plannedDiplomaType'],
  })
  getGradReqsForStudent (student, studentOnTrackMetrics, district) {
    const creditReqs = _.cloneDeep(CREDIT_REQS);
    const diplomaType = student.gradPlanningDetails.plannedDiplomaType;

    if (studentOnTrackMetrics) {
      if (diplomaType === PlannedDiplomaType.ADVANCED_REGENTS.humanName) {
        creditReqs.lote.gradReq[district] = 6;
      }
      if (studentOnTrackMetrics.cte > 0) {
        creditReqs.cte.gradReq[district] = 7;
      }
      return creditReqs;
    }
    return null;
  }
}
