import { IconWithTextComponent } from './../components/icon-with-text/icon-with-text.component';
import { IRowData } from '../models/list-models';
import { DocMouseMoveService } from './../services/document-mousemove-service/document-mousemove.service';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef,
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, InjectionToken, Injector, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { debounceTime, filter, switchMap, take, tap } from 'rxjs/operators';
import { NvTooltipComponent } from './nv-tooltip.component';
import { ITooltipData, TNvTooltipData, TTooltipElType, ITooltipContent } from './nv-tooltip.interface';
import { NvTooltipDataService } from './nv-tooltip.service';

export const TOOLTIP_DATA = new InjectionToken<{}>('TOOLTIP_DATA');

@Directive({ selector: '[nvTooltip]' })
export class NvTooltipDirective implements OnDestroy {
  @Input() tooltipData: TNvTooltipData;

  private overlayRef: OverlayRef;
  private isTooltipActive$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private onMouseout$: Observable<any>;
  private onMouseover$: Observable<any>;

  constructor(
    private el: ElementRef,
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private injector: Injector,
    private nvTooltipDataService: NvTooltipDataService,
    private documentMousemove: DocMouseMoveService,
  ) { }

  @HostListener('mouseenter')
  onMouseEnter() {
    const isMouseInElement$ = this.documentMousemove.event$.pipe(
      switchMap(evt => this.isEventInElement(evt, this.el.nativeElement)),
    );

    this.onMouseout$ = this.createMouseOutStream(isMouseInElement$);
    this.onMouseover$ = this.createMouseOverStream(isMouseInElement$);
    combineLatest([this.onMouseout$, this.onMouseover$])
      .pipe(take(1))
      .subscribe();
  }

  ngOnInit() {
    this.overlayRef = this.createOverlay(this.overlay);
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }

  private createMouseOutStream(isMouseInElement$: Observable<boolean>): Observable<any> {
    return isMouseInElement$.pipe(
      filter(isHovered => !isHovered && this.isTooltipActive$.value),
      tap(() => {
        this.overlayRef.detach();
        this.isTooltipActive$.next(false);
      }),
    );
  }

  private createMouseOverStream(isMouseInElement$: Observable<boolean>): Observable<any> {
    return isMouseInElement$.pipe(
      debounceTime(50),
      filter(isHovered => isHovered && !this.isTooltipActive$.value),
      switchMap(() => this.getTooltipContent(this.tooltipData)),
      tap((tooltipData: any) => {
        const content = tooltipData.data.Tooltip ? tooltipData.data.Tooltip.content : {};

        // is simple or table
        const { tableContent, simpleContent } = content || {};

        // Format rowdata for table content
        if (tableContent) {
          tableContent.rowData = this.getFormattedRowData(tableContent.rowData);
        }

        const inputs: ITooltipData = { content: tableContent || simpleContent };
        this.isTooltipActive$.next(true);
        if (!this.overlayRef.hasAttached() && this.shouldDisplayTooltip(tableContent, simpleContent)) { this.overlayRef.attach(new ComponentPortal(NvTooltipComponent, null, this.createInjector(inputs))); }
      }),
    );
  }

  // validate table content to have row data and simple content to some length before displaying(Jack)
  private shouldDisplayTooltip(tableContent, simpleContent): boolean {
    const isValidSimpleContent = this.isValidSimpleContent(simpleContent);
    const isValidTableContent = this.isValidTableContent(tableContent);
    return isValidSimpleContent || isValidTableContent;
  }

  private isValidTableContent(tableContent): boolean {
    return !!(tableContent && tableContent.rowData && tableContent.rowData.length);
  }

  private isValidSimpleContent(simpleContent: String): boolean {
    return !!(simpleContent && simpleContent.length);
  }

  private createInjector(tooltipData: ITooltipData): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(TOOLTIP_DATA, tooltipData);
    return new PortalInjector(this.injector, injectorTokens);
  }

  private getTooltipContent(tooltipData: TNvTooltipData): Observable<any> {
    let caresId;
    // guard against mistaken misuse of this directive
    if (!tooltipData) return EMPTY;
    // add a check to support old lists.
    if (typeof tooltipData === 'string') return of({ data: { Tooltip: { content: { simpleContent: tooltipData } } } });

    const { simpleContent, tableContent, schoolId, shelterId, docId, calc, wildcard, connectedElType, meta } = tooltipData;
    if (simpleContent) {
      return of({ data: { Tooltip: { content: { simpleContent } } } });
    }

    if (tableContent) {
      return of({ data: { Tooltip: { content: { tableContent } } } });
    }

    // check against docId before parsing it - jchu
    if (!docId) return EMPTY;

    const { tooltipFilter } = JSON.parse(docId);
    const tooltipBaseDocId = this.getTooltipDocId(docId, connectedElType);
    
    let payload = { shelterId, schoolId, docId: tooltipBaseDocId, tooltipFilter, calc, meta };

    if (shelterId) {
      caresId = tooltipData.caresId; // This is the case for shelter profile success mentoring panel
      if (!caresId) caresId = this.getCaresId(docId); // This is the case for shelter list and grid
      payload = Object.assign(payload, { caresId });
    }
    if (wildcard) payload = Object.assign(payload, { wildcard });

    return this.nvTooltipDataService.getAsyncTooltip(payload);
  }

  private createOverlay(overlay: Overlay) {
    const positionStrategy: FlexibleConnectedPositionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.el)
      .withPositions([
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
        },
      ]);
    const config: OverlayConfig = new OverlayConfig({ positionStrategy });
    return overlay.create(config);
  }

  private isEventInElement(event, element): Observable<boolean> {
    const rect = element.getBoundingClientRect();
    const x = event.clientX;
    if (x < rect.left || x >= rect.right) return of(false);
    const y = event.clientY;
    if (y < rect.top || y >= rect.bottom) return of(false);
    return of(true);
  }

  // re-calced tooltipBaseDocId (will be used to filter mongo doc through _id in the backend)
  // 1. for any student list(fixed table list, infinite table list), docId passed as a stringified obj wrapped inside .meta
  // 2. for student profile panel (e.g. basic info panel), docId is directly passed
  private getTooltipDocId(docId, connectedElType: TTooltipElType = 'FIXED_OR_INFINITE_LIST_CELL') {
    let tooltipBaseDocId;
    switch (connectedElType) {
      case 'PANEL_FIELD':
        tooltipBaseDocId = docId;
        break;
      case 'FIXED_OR_INFINITE_LIST_CELL':
      default:
        tooltipBaseDocId = JSON.parse(docId).data;
        break;
    }
    return tooltipBaseDocId;
  }

  private getCaresId(docId: string): string {
    return JSON.parse(docId).caresId;
  }

  private getFormattedRowData(rowData: ITooltipContent['rowData']): ITooltipContent['rowData'] {
    return rowData.map((row: any) => {
      return row.map((col: IRowData) => {
        // col could be a string or a valid IRowData object
        if (col?.columnKey) {
          col.dynamic = this.getDynamicComponent(col.columnKey);
        }
        return col;
      });
    });
  }

  private getDynamicComponent(columnKey: string): IRowData['dynamic'] {
    let component: IRowData['dynamic'];
    switch (columnKey) {
      case 'LATEST_MP_MARK':
        component = IconWithTextComponent;
        break;
      default:
        break;
    }
    return component;
  }
}
