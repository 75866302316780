import { ISubjectArea, SubjectAreas, TValidSubjHuman } from './../../../constants/subject-areas.constant';
import * as _ from 'lodash';
import { ICurrProgramCourseMark, ICurrProgramCourseTeacher, TValidCoursePriorities } from 'Src/ng2/shared/typings/interfaces/student.interface';

export type TValidGradeStatuses = 'Failing' | 'Passing' | 'Borderline' | '';
export type TValidHumanReadablePriorities = 'High' | 'Med' | 'Low' | '';

export class ImStudentCurrentProgramHelpers {
  // TODO move to shared service (DS)?
  static getSubjectAreaForCourse (course): ISubjectArea {
    return _.find(SubjectAreas, { camelCase: course.subjectArea });
  }

  // TODO move to shared service (DS)?
  static getHumanReadableSubjectAreaForCourse (
    course,
    district = 'NYC',
    subjectArea?,
  ): TValidSubjHuman {
    let subjectAreaHumanName;
    subjectArea = subjectArea || this.getSubjectAreaForCourse(course);
    if (!subjectArea) {
      console.warn('Subject area human name not found for: ' + course.subjectArea);
      subjectAreaHumanName = '';
    } else {
      subjectAreaHumanName = subjectArea.humanByDistrict && subjectArea.humanByDistrict[district] ? subjectArea.humanByDistrict[district] : subjectArea.human;
    }
    return subjectAreaHumanName;
  }

  static sortMps (marks: ICurrProgramCourseMark[]) {
    const copy = _.cloneDeep(marks);
    const sorted = _.sortBy(copy, ['mp']);
    return sorted;
  }

  static getPriorityString (priority: TValidCoursePriorities): TValidHumanReadablePriorities {
    switch (priority) {
      case 'high':
        return 'High';
      case 'medium':
        return 'Med';
      case 'low':
        return 'Low';
      default:
        return '';
    }
  }

  static getGradeStatus (mark: ICurrProgramCourseMark): TValidGradeStatuses {
    if (!mark) return '';
    let status;
    const isFailing = mark.isFailing;
    const gradeNum = mark.num;

    if (isFailing) {
      status = 'Failing';
    } else if (gradeNum >= 75) {
      status = 'Passing';
    } else {
      status = 'Borderline';
    }

    return status;
  }

  static getJoinedTeacherNames (teachers: ICurrProgramCourseTeacher[]): string {
    const nameArray = [];
    _.forEach(teachers, (teacher: any) => {
      nameArray.push(teacher.nickname);
    });
    return nameArray.join(', ');
  }

  static getCurrentCourseMark (marks: ICurrProgramCourseMark[]): ICurrProgramCourseMark | null {
    // No marks, return null
    if (!marks || marks.length === 0) return null;

    // Find mark via isMostRecent property
    const currMark = _.find(marks, mark => mark.isMostRecent === true);

    return currMark;
  }

  // TODO: This is a placeholder until data team ensures that all marks have string value
  static getHumanReadableCourseMark (mark: ICurrProgramCourseMark) {
    if (!mark) return '';
    const string = mark.string;
    const num = mark.num;
    if (string) return string;
    if (num) return _.toString(Math.round(num));
  }
}
