import { Store } from '@ngrx/store';
import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { find } from 'lodash';
import * as moment from 'moment';

import { IGapPlan } from 'Src/ng2/shared/typings/interfaces/gap-plan.interface';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { CourseDiffsAndGapPlansDataService } from './course-diffs-and-gap-plans-data/course-diffs-and-gap-plans-data.service';
import { unsubscribeComponent } from '../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ICourseDiff } from 'Src/ng2/shared/typings/interfaces/course-diff.interface';
import { FixedToInfiniteViewComponent } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { IGroupData, IListConfig, IRowData } from 'Src/ng2/shared/models/list-models';
import { TSortDirections } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { ListHeaderService } from '../../lists/services/list-headers/list-headers.service';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { CsvExporterService, CsvType } from 'Src/ng2/shared/services/csv-exporter/csv-exporter.service';
import { IRow } from '../../mid-level-dashboard/mid-level-dash.component';
import { PROGRAM_CHANGES_GROUPING_OPTIONS } from './course-diffs-and-gap-plans-data/course-diffs-and-gap-plans-columns.constants';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'course-diffs-and-gap-plans-container',
  templateUrl: './course-diffs-and-gap-plans-container.component.html',
  styleUrls: ['./course-diffs-and-gap-plans-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
// @ts-ignore
export class CourseDiffsAndGapPlansContainer extends FixedToInfiniteViewComponent implements OnInit {
  public groupData;
  public schoolId: string;
  public gapPlans: IGapPlan[];
  public currentSchool: ISchool;
  public courseDiffs: ICourseDiff[];
  public fociOptions: IDropdownOption[];
  private defaultTermYear;
  public groupingOptions: IDropdownOption[];
  public fociOptionSelected: string;
  public groupingOptionSelected: string;
  public filteredTable: IRow[];
  public pageHeaderMeta = {
    title: 'Program Changes',
    subTitle: null,
    icon: {
      tooltip: null,
    },
  };

  // list container props
  public listConfig: IListConfig;
  public batchActionsMode$: Observable<boolean>;
  public columnIndexMap: { [columnKey: string]: number };
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public dynamicComponentInputData: any;
  public columns;
  public filterFormControl: FormControl;
  public groupingData$: BehaviorSubject<IGroupData[]> = new BehaviorSubject(null);
  public noDataMessage: any;
  public activeList: 'INFINITE' | 'FIXED';
  public onRowClick: Function;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public sortKey$: BehaviorSubject<string>;
  public updateSort: Function;

  // SuperProps
  public onFixed: Function;
  public onInfinite: Function;
  public onBatchAction: Function;
  public onDynamicComponentClick: Function = () => null;
  public onUiRowDataUpdate: Function;
  public uiRowData;

  // Refs
  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: true }) listContent: ElementRef;

  constructor (
    private route: ActivatedRoute,
    private courseDiffsAndGapPlansDataService: CourseDiffsAndGapPlansDataService,
    public resolver: ComponentFactoryResolver,
    private listHeaderService: ListHeaderService,
    private objectCache: ObjectCache,
    private windowRef: WindowRef,
    private csvExporter: CsvExporterService,
    private batchActionsService: BatchActionsService,
    private store: Store<any>,
    private urlPathService: UrlPathService,
  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnInit (): void {
    this.groupingOptions = PROGRAM_CHANGES_GROUPING_OPTIONS;
    this.sortDirection$ = new BehaviorSubject(null);
    this.sortKey$ = new BehaviorSubject(null);
    this.batchActionsMode$ = of(false);
    this.groupingOptionSelected = 'gradReq';
    this.columns = this.courseDiffsAndGapPlansDataService.getProgramChangesColumns(this.groupingOptionSelected);
    this.columnIndexMap = this.columns.reduce((mapping, col: any, i: number) => {
      mapping[col.graphQlKey] = i;
      return mapping;
    }, {});
    this.filterFormControl = new FormControl();
    this.onFixed = this.updateCssForListType('FIXED');
    this.onInfinite = this.updateCssForListType('INFINITE');
    this.listConfig = {
      emptyTableMessage: 'No planned courses found. Please select another term.',
      listType: 'PROGRAM_CHANGES',
      noDataMessage: 'No planned courses found. Please select another term.',
      sortableColumns: false,
      maximumVisibleRowsPerGroup: 5,
    };
    const { schoolId } = this.route.snapshot.params;
    this.schoolId = schoolId;
    this.onRowClick = this.goToStudentProfile;
    this.setDefaultTermYear();
    this.updateGroupingData();
  }

  private setDefaultTermYear (): void {
    this.courseDiffsAndGapPlansDataService.getSchool$()
      .pipe(
        tap((currentSchool) => {
          this.defaultTermYear = currentSchool.currentTermYear;
        }),
        take(1),
      ).subscribe();
  }

  private updateGroupingData (): void {
    const fociOption = this.fociOptionSelected ?? this.defaultTermYear;
    const groupingOption = this.groupingOptionSelected;
    this.columns = this.courseDiffsAndGapPlansDataService.getProgramChangesColumns(groupingOption);
    const columnsNames = this.columns.map(columnObj => columnObj.graphQlKey);

    this.courseDiffsAndGapPlansDataService.getProgramChanges$(
      this.schoolId,
      fociOption,
      groupingOption,
      columnsNames,
    ).pipe(
      tap(res => {
        const {
          data: {
            ProgramChangesGrouping: { groupData, fociOptions },
          },
        } = res;

        this.filteredTable = groupData;
        this.groupingData$.next(groupData);
        this.fociOptions = fociOptions;
        this.setDefaultFociOption();
      }),
    ).subscribe();
  }

  private setDefaultFociOption () {
    if (!this.fociOptionSelected) {
      const option = this.fociOptions.find(option => option.key === this.defaultTermYear.toString());
      this.fociOptionSelected = option ? option.key : this.fociOptions[0].key;
    }
  }

  public onFociChange (e: string): void {
    this.fociOptionSelected = e;
    this.updateGroupingData();
  }

  public onGroupingChange (e: string): void {
    this.groupingOptionSelected = e;
    this.updateGroupingData();
  }

  private goToStudentProfile (rowData: IRowData[]): void {
    const row = find(rowData, 'meta');
    const studentMetaData = JSON.parse(row.meta);
    const _id = `${studentMetaData.studentId}${this.schoolId}`;
    const cachedStudentId = this.objectCache.cacheObject({ studentId: [_id] });

    const url = this.urlPathService.computeDistrictUrlPath(`/school/${this.schoolId}/student?filter=${cachedStudentId}`);
    this.windowRef.nativeWindow.open(url, '_blank');
  }

  public generateCsv (): void {
    const docType = 'text/csv;charset=utf-8';
    const currentDateAndTime = moment().format('MM-DD-YYYY hmma');
    const fileName = `Program Change & Gap Plans ${currentDateAndTime}.csv`;
    const csvString = this.csvExporter.createProgramChangesCsv(this.filteredTable, this.groupingOptionSelected);
    this.csvExporter.exportCsv(csvString, fileName, docType, CsvType.Student);
  }

  public onFocusedGroup ($event: {
    groupData: IGroupData;
    sortKey: string;
    sortDirection: TSortDirections;
    groupIndx: number;
  }) {
    const { groupIndx } = $event;
    const newGroupings: IGroupData[] = [];
    this.groupingData$.value.forEach((grouping: IGroupData, i: number) => {
      const newGrouping = { ...grouping };
      if (i === groupIndx) {
        newGrouping.showAll = true;
      }
      newGroupings.push(newGrouping);
    });
    this.groupingData$.next(newGroupings);
  }

  public updateCssForListType (listMode: 'INFINITE' | 'FIXED'): Function {
    return (instance?: ElementRef): void => {
      this.activeList = listMode;
      const addClassForListMode = listMode === 'INFINITE' ? 'infinite' : 'fixed';
      this.listContent.nativeElement.classList.add(addClassForListMode);
      const removeClassForListMode = listMode === 'INFINITE' ? 'fixed' : 'infinite';
      this.listContent.nativeElement.classList.remove(removeClassForListMode);
      this.pageHeaderMeta =
          listMode === 'INFINITE'
            ? { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getInfiniteHeaderIcon(instance) } }
            : { ...this.pageHeaderMeta, ...{ icon: this.listHeaderService.getFixedHeaderIcon() } };
    };
  }
}
