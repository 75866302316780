import { IPickerOption } from './../../../../../nvps-libraries/design/nv-multi-picker/nv-multi-picker.interface';
import { Component, Input, Output, EventEmitter, OnInit, ViewEncapsulation } from '@angular/core';

export interface IEditGridColumnFilters {
  categories: string[];
  tags: string[];
}

@Component({
  selector: 'edit-grid-columns-madlib',
  templateUrl: './edit-grid-columns-madlib.component.html',
  styleUrls: ['./edit-grid-columns-madlib.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditGridColumnsMadlibComponent implements OnInit {
  @Input() categories: string[];
  @Input() tags: string[];

  @Output() selectedFilters = new EventEmitter<IEditGridColumnFilters>();
  @Output() uncheckAll = new EventEmitter<boolean>();

  formattedCategories: IPickerOption[];
  formattedTags: IPickerOption[];

  selectedCategories: string[];
  selectedTags: string[];

  private readonly defaultFilterText: string = 'All fields';
  public filterText: string;

  ngOnInit (): void {
    this.setFormattedFilters(this.categories, this.tags);
    this.resetSelectedFilters();
    this.filterText = this.defaultFilterText;
  }

  public onUncheckAll (): void {
    this.uncheckAll.emit();
  }

  public onFilter (): void {
    this.filterText = this.getUpdatedText(this.selectedCategories, this.selectedTags);
    this.emitFilters(this.selectedCategories, this.selectedTags);
  }

  public resetSelectedFilters (): void {
    this.selectedCategories = [];
    this.selectedTags = [];
  }

  private setFormattedFilters (categories: string[] = [], tags: string[] = []): void {
    this.formattedCategories = this.convertToPickerArr(categories);
    this.formattedTags = this.convertToPickerArr(tags);
  }

  private convertToPickerArr (arr: string[] = []): IPickerOption[] {
    return arr.map(ele => ({ key: ele, human: ele }));
  }

  private emitFilters (categories: string[] = [], tags: string[] = []): void {
    this.selectedFilters.emit({ categories, tags });
  }

  private getUpdatedText (categories: string[] = [], tags: string[] = []): string {
    let text: string = this.defaultFilterText;

    const numCategories = categories.length;
    const numTags = tags.length;

    const categoryText = numCategories > 1 ? `${numCategories} categories` : `${numCategories} category`;
    const tagText = numTags > 1 ? `${numTags} tags` : `${numTags} tag`;

    if (numCategories) {
      text = `${categoryText}`;
    } else if (numTags) {
      text = `${tagText}`;
    }
    return text;
  }
}
