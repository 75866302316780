import { WindowRef } from './../../../../../../shared/services/windowRef';
import { ImSupport } from 'Src/ng2/shared/services/im-models/im-support/im-support.service';
import { tap, startWith } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { FormControl } from '@angular/forms';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { RegentsExam } from '../../../../../../shared/constants/regents.constant';
import { IStudentSupport } from 'Src/ng2/shared/typings/interfaces/student-support.interface';
import { cloneDeep, map, filter, find, uniqBy, every, orderBy, each } from 'lodash';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { ISchoolSupport, ISchoolSupportExtended } from 'Src/ng2/shared/typings/interfaces/support.interface';
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UrlPathService } from 'Src/ng2/shared/services/url-path-service/url-path.service';

@Component({
  selector: 'regents-exam-supports-table',
  templateUrl: './regents-exam-supports-table.component.html',
  styleUrls: ['./regents-exam-supports-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegentsExamSupportsTable {
  @Input() private studentSupports;
  @Input() public schoolSupports;

  data: any[];
  schoolId: string;
  supportFilter: any;
  sortField: string;
  sortDirection: 'asc' | 'desc';
  examSubjectsList: any;
  supportLeadsList: any;
  browserTab: string;

  private initialSortFields: string[];

  // public storedStudentSupports;
  public currentSchool: ISchool;
  public supportExamSubjFilter = new FormControl('All');
  public supportLeadFilter = new FormControl('All');

  constructor (
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private imSupport: ImSupport,
    private imUser: ImUser,
    private windowRef: WindowRef,
    private urlPathService: UrlPathService,
  ) { }

  ngOnInit () {
    this.schoolId = this.activatedRoute.snapshot.params.schoolId;
    this.initialSortFields = ['_statusObj.order', 'metaData.examSubject'];
    this.sortField = '_statusObj.order';
    this.sortDirection = 'asc';
    let tableData;
    if (this.schoolSupports.length) tableData = cloneDeep(this.schoolSupports);
    this.data = orderBy(this.renderTable(tableData), this.initialSortFields, this.sortDirection);
    this.supportFilter = { examSubject: undefined, activityLeadUserId: undefined };
    this.examSubjectsList = uniqBy(
      map(this.data, (support: ISchoolSupport) => {
        return {
          k: support.metaData.examSubject,
          human: this.getHumanName(support.metaData.examSubject),
        };
      }),
      'k',
    );

    this.examSubjectsList.unshift({ k: 'All', human: 'All' });
    this.browserTab = 'new';

    const mappedList = map(this.data, support => {
      const email = this.imUser.getEffectiveEmail(support.activityLead);
      const item = {
        userId: support.activityLead.userId,
        email,
      };
      return item;
    });

    this.supportLeadsList = uniqBy(mappedList, 'userId');
    this.supportLeadsList.unshift({ email: 'All', userId: 'All' });

    combineLatest([this.supportExamSubjFilter.valueChanges.pipe(startWith(null)), this.supportLeadFilter.valueChanges.pipe(startWith(null))])
      .pipe(
        tap(() => {
          this.supportFilterChanged();
        }),
      ).subscribe();
  }

  // THIS WONT WORK
  openMenu ($mdOpenMenu, ev) {
    $mdOpenMenu(ev);
  }

  supportFilterChanged () {
    const filters = [];
    if (this.supportExamSubjFilter.value && this.supportExamSubjFilter.value !== 'All') {
      filters.push(support => support.metaData.examSubject === this.supportExamSubjFilter.value);
    }
    if (this.supportLeadFilter.value && this.supportLeadFilter.value !== 'All') {
      filters.push(support => support.activityLead.gafeEmail === this.supportLeadFilter.value);
    }
    each(this.data, support => {
      support._matchesFilter = every(filters, filter => filter(support));
    });
  }

  getHumanName (key: string) {
    const foundKey = find(RegentsExam, (exam: any) => exam.subject.key === key);
    return foundKey ? foundKey.subject.humanName : key;
  }

  activeSort (field: string) {
    return field === this.sortField;
  }

  renderTable (supports: ISchoolSupport[]) {
    const table = filter(supports, (support: ISchoolSupportExtended) => {
      const supportIsDeleted = this.imSupport.isDeleted(support);
      const supportCategoryIsRegentsPrep = this.imSupport.categoryIsRegentsPrep(support);

      // exclude supports that are completed; only shpw regents prep supports
      if (!supportIsDeleted && supportCategoryIsRegentsPrep) {
        support._statusObj = {
          currentState: '',
          order: null,
        };
        support._matchesFilter = true;
        support._enrollments = filter(this.studentSupports, (studentSupport: IStudentSupport) => {
          const isActive = studentSupport.status === 'ACTIVE';
          const supportMatches = support._id === studentSupport.support.supportId;
          if (isActive && supportMatches) return true;
        }).length;
        support._startDate = support.schedule.startsOn;
        support._startAndEndDateString = this.imSupport.getStartAndEndDateString(support);
        support._leadHumanName = this.imSupport.getLeadHumanName(support);
        support._meetsOnScheduleString = this.imSupport.getMeetsOnScheduleString(support);
        support._startAndEndTimeString = this.imSupport.getStartAndEndTimeString(support);
        support._statusObj.currentState = this.imSupport.getFrontendStatus(support);
        support._canBeEdited = this.imSupport.canBeEditedBasedOnStatus(support);
        support._statusObj.order = this.imSupport.getSortOrderBasedOnFrontendStatus(support);
        support._examSubjectPrepsFor = this.getHumanName(support.metaData.examSubject);

        return true;
      } else {
        return false;
      }
    });

    return table;
  }

  // post init
  sortBy (field: string) {
    const activeSort = this.activeSort(field);
    const reverse = {
      asc: 'desc' as 'desc',
      desc: 'asc' as 'asc',
    };
    this.sortDirection = activeSort ? reverse[this.sortDirection] : 'asc';
    this.initialSortFields = [];
    this.sortField = field;
    this.data = orderBy(this.data, [this.sortField], this.sortDirection);
  }

  goToSupportSettings (): void {
    const districtUrl = this.urlPathService.computeDistrictUrlPath(`school/${this.schoolId}/settings/supports`);
    const urlTree = this.router.createUrlTree([districtUrl]);
    const url = this.router.serializeUrl(urlTree);
    this.windowRef.nativeWindow.open(url, '_blank');
  }
}
