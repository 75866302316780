import { IPerformanceCardRowData, IPerformanceCardTableData } from './nv-performance-card.interface';
import { Component, Input, ViewEncapsulation } from '@angular/core';

/**
 * Performance card component. Displays table of content with a maximum of 5.
 * Can be configured to show different lists with header pill buttons
 */
@Component({
  selector: 'nv-performance-card',
  templateUrl: 'nv-performance-card.component.html',
  styleUrls: ['nv-performance-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvPerformanceCardComponent {
  /**
   * Primary color. Determines color of the selected pill button
   */

  @Input() primaryColor: string;
  /**
   * Secondary color. Determines color of the background pill button header.
   */
  @Input() secondaryColor: string;

  /**
   * Table data. The keys will be used to populate the header pill options.
   * The values used to populate the table rows will correspond with the selected pill index values.
   * For arrays with more than 1 element, there will be on click pill functionality
   * `icon` property will be used to determine the nv-icon displayed next to change indicator text
   */
  @Input() tableData: IPerformanceCardTableData[];

  /**
   * Used to determine which set of data to populate row table data. Performance table is limited to row at most 5 rows of data.
   */
  truncatedRowData: {
    [idx: number]: IPerformanceCardRowData[]
  };

  /**
   * Used to determine which rows to show.
   */
  selectedIndex: number = 0;

  ngOnInit () {
    this.truncatedRowData = this.tableData.reduce((acc, { rowData }, idx) => {
      const maxRowCount = 5;
      const hasData = rowData?.length;
      acc[idx] = hasData ? rowData.slice(0, maxRowCount) : this.addEmptyRows();
      return acc;
    }, {});
  }

  private addEmptyRows ():IPerformanceCardRowData[] {
    const minRowCount = 2;
    const colCount = 3;
    return new Array<IPerformanceCardRowData>(minRowCount).fill({ data: new Array(colCount).fill(null) });
  }

  public onPillClick (index: number) {
    this.selectedIndex = index;
  }

  public isSelected (index: number) {
    return this.selectedIndex === index;
  }
}
