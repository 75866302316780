import { RouterExitService } from './../../../routing/services/router-exit.service';
import { IDLENESS_TIME_TO_SHOW_WARNING, IDLENESS_WARNING } from './../../constants/auth/auth.constant';
import { PortalConfig } from './../portal-config';
import { Title } from '@angular/platform-browser';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Auth } from '../../auth/auth.service';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Injectable()
export class UserInactivity {
  private readonly INACTIVITY_ERR_MESSAGE = "You've been logged out due to inactivity.";
  private readonly defaultTitle: string;

  constructor (
    private router: Router,
    private auth: Auth,
    private idle: Idle,
    private title: Title,
    private portalConfig: PortalConfig,
    private routerExitService: RouterExitService,

  ) {
    this.defaultTitle = `${this.portalConfig.publicConfig.DISPLAY_NV_ENV} - Portal by New Visions`;
  }

  public start (): void {
    this.idle.setIdle(IDLENESS_TIME_TO_SHOW_WARNING);
    this.idle.setTimeout(IDLENESS_WARNING);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleEnd.subscribe(() => {
      this.title.setTitle(this.defaultTitle);
      this.reset();
    });

    this.idle.onTimeoutWarning.subscribe((countdown: number) => {
      const idleState = `You will time out in ${countdown} seconds`;
      this.title.setTitle(idleState);
    });

    this.idle.onTimeout.pipe(
      tap(() => {
        this.title.setTitle(this.defaultTitle);
        this.onTimeout();
      }),
    ).subscribe();

    this.idle.watch();
  }

  private reset (): void {
    this.idle.watch();
  }

  private onTimeout (): void {
    const lastKnownUrl = this.router.url;
    const previousUrlValue = this.routerExitService?.previousUrl$?.value
    let prevUrl;

    if (previousUrlValue) {
      const { url, queryParams } = previousUrlValue;
      const queryParamsStr = new URLSearchParams(queryParams).toString();
      prevUrl = queryParamsStr ? `${url}?${queryParamsStr}` : url;
    }
  
    const logoutOpts = { errorMessage: this.INACTIVITY_ERR_MESSAGE, lastKnownUrl, prevUrl };
    this.auth.logout(logoutOpts);
  }
}
