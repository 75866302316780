import { TValidPartnerTypes } from '../../typings/interfaces/partner.interface';
import { ImUser } from '../../services/im-models/im-user';
import { IUser } from '../../typings/interfaces/user.interface';
import { Router } from '@angular/router';
import { IDropdownOption } from '../../typings/interfaces/design-library.interface';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { UrlPathService } from '../../services/url-path-service/url-path.service';
import { PortalConfig } from '../../services/portal-config';
import { SessionStorageService } from '../../services/web-storage/session-storage/session-storage.service';
import { districtsConfig } from 'Src/ng2/shared/constants/districts-config.constant';
const portalSwitcherConfig = require('./portal-switcher-config.json');

export type TSelectedView = 'School' | 'Network' | 'Shelter';

interface IPortalSwitcherDropdownOption extends IDropdownOption {
  permissions: {
    isSuperAdmin: {
      needsSuperAdminAccess: boolean;
    } | null;
    isClusterUser: {
      needsHybridAccess: boolean;
      needsMultiSchoolOrShelterAccess: boolean;
      needsAtLeastOneSchoolOrShelterAccess: boolean;
    } | null;
    isMultiSchoolUser: {
      needsAtLeastTwoSchoolsAccess: boolean;
    },
    isMultiDistrictUser: boolean;
  };
}

interface IPortalSwitcherUserPermission {
  isClusterUser: boolean;
  hasHybridAccess: boolean;
  hasAccessToMultiSchoolsOrShelters: boolean;
  hasAccessToAtLeastOneSchoolOrShelter: boolean;
  isSuperAdmin: boolean;
  isMultiSchoolUser: boolean;
  hasAccessToAtLeastTwoSchools: boolean;
  isMultiDistrictUser: boolean;
}

@Component({
  selector: 'portal-switcher',
  templateUrl: './portal-switcher.component.html',
  styleUrls: ['./portal-switcher.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PortalSwitcherComponent implements OnInit {
  @Input() currentUser: IUser;
  @Input() selectedView: TSelectedView;
  @Input() label: 'string';

  public options: IPortalSwitcherDropdownOption[];

  constructor (
    private imUser: ImUser,
    private router: Router,
    private urlPathService: UrlPathService,
    private portalConfig: PortalConfig,
    private sessionStorageService: SessionStorageService,
  ) {}

  ngOnInit (): void {
    this.options = this._setDropdownOptions(this.selectedView, this.currentUser);
  }

  selectOption (event: string): void {
    let url: string;
    switch (event) {
      case 'Switch School':
      case 'Switch to a School':
        url = this.urlPathService.computeDistrictUrlPath('school-picker');
        break;
      case 'Explore Schools':
        const hasAccessToOneSchoolOnly /* eslint-disable-line */ =
          this.imUser.isClusterUser(this.currentUser, 'school') &&
          this.imUser.isSingleClusterUser(this.currentUser, 'school');
        const isNetworkOnly = this.imUser.isNetworkOnly(this.currentUser); /* eslint-disable-line */
        const hasNoSchoolButIsNetSchool = this.imUser.hasNoSchoolButIsNetSchool(this.currentUser,); /* eslint-disable-line */
        if (isNetworkOnly || hasNoSchoolButIsNetSchool) {
          url = this.urlPathService.computeDistrictUrlPath(`/network/school/${this.currentUser.nvRole.clusterId}`);
        } else if (this.currentUser._role_clusterSchoolIds && hasAccessToOneSchoolOnly) {
          const schoolId = this.currentUser._role_clusterSchoolIds[0];
          url = this.urlPathService.computeDistrictUrlPath(`/school/${schoolId}/lists/tiles`);
        } else {
          url = this.urlPathService.computeDistrictUrlPath('school-picker');
        }
        break;
      case 'View Network':
        url = this.urlPathService.computeDistrictUrlPath(`/network/school/${this.currentUser.nvRole.clusterId}`);
        break;
      case 'Switch District':
        url = 'district-picker';
        break;
      case 'Switch Shelter':
        url = 'shelter-picker';
        break;
      case 'Explore Shelters':
        const hasAccessToOneShelterOnly = /* eslint-disable-line */
          this.imUser.isClusterUser(this.currentUser, 'shelter') &&
          this.imUser.isSingleClusterUser(this.currentUser, 'shelter');
        if (hasAccessToOneShelterOnly && this.currentUser._role_shelterClusterShelterIds.length) {
          url = `shelter/${this.currentUser._role_shelterClusterShelterIds[0]}`;
        } else {
          url = 'shelter-picker';
        }
        break;
    }
    if (url) this.router.navigate([url]);
  }

  private _getDropdownOptions (selectedView: TSelectedView): IPortalSwitcherDropdownOption[] {
    return portalSwitcherConfig[selectedView];
  }

  private _getUserPermissions (user: IUser): IPortalSwitcherUserPermission {
    const userPermissions = {
      isClusterUser: false,
      hasHybridAccess: false,
      hasAccessToMultiSchoolsOrShelters: false,
      hasAccessToAtLeastOneSchoolOrShelter: false,
      isSuperAdmin: false,
      isMultiSchoolUser: false,
      hasAccessToAtLeastTwoSchools: false,
      isMultiDistrictUser: false,
    };
    const partnerType: TValidPartnerTypes = this.selectedView === 'Shelter' ? 'shelter' : 'school';
    const userDistricts = this.sessionStorageService.getItem('districts') || [];

    const isSuperAdmin = this.imUser.isSuperAdmin(user, partnerType);
    const isClusterUser = this.imUser.isClusterUser(user, partnerType);
    const isNetworkOnly = this.imUser.isNetworkOnly(user);
    const isSingleClusterUser = this.imUser.isSingleClusterUser(user, partnerType);
    const hasNoSchoolButIsNetSchool = this.imUser.hasNoSchoolButIsNetSchool(user);
    const isActiveHybridClusterUser = this.imUser.isHybridClusterUser(user) && this.imUser.isActive(user, 'hybrid');
    const isClusterAdmin = this.imUser.isClusterAdmin(user, partnerType);
    const isMultiSchoolUser = this.imUser.isMultiSchoolUser(user);
    const isSingleMultiScholUser = isMultiSchoolUser && this.imUser.isSingleMultiSchoolUser(user);
    const isMultiDistrictUser = this.imUser.isMultiDistrictUser(userDistricts);

    if (isSuperAdmin) userPermissions.isSuperAdmin = true;
    if (isClusterUser) userPermissions.isClusterUser = true;
    if (isMultiSchoolUser) userPermissions.isMultiSchoolUser = true;
    if ((!isSingleClusterUser && !isNetworkOnly) || isClusterAdmin) {
      userPermissions.hasAccessToAtLeastOneSchoolOrShelter = true;
      userPermissions.hasAccessToMultiSchoolsOrShelters = true;
    }
    if (isSingleClusterUser && !hasNoSchoolButIsNetSchool && !isNetworkOnly && !isClusterAdmin) {
      userPermissions.hasAccessToAtLeastOneSchoolOrShelter = true;
    }
    if (isMultiSchoolUser && !isSingleMultiScholUser) {
      userPermissions.hasAccessToAtLeastTwoSchools = true;
    }
    if (isActiveHybridClusterUser) userPermissions.hasHybridAccess = true;
    if (isMultiDistrictUser) userPermissions.isMultiDistrictUser = true;

    return userPermissions;
  }

  private _setDropdownOptions (selectedView: TSelectedView, user: IUser): IPortalSwitcherDropdownOption[] {
    const defaultDropdownOptions = this._getDropdownOptions(selectedView);
    const userPermissions = this._getUserPermissions(user);
    return defaultDropdownOptions.filter(defaultOption => {
      return (
        this._isOptionAccessible_superAdmin(userPermissions, defaultOption) ||
        this._isOptionAccessible_clusterUser(userPermissions, defaultOption) ||
        this._isOptionAccessible_multiSchoolUser(userPermissions, defaultOption) ||
        this._isOptionAccessible_multiDistrictUser(userPermissions, defaultOption)
      );
    });
  }

  private _isOptionAccessible_superAdmin (
    { isSuperAdmin }: IPortalSwitcherUserPermission,
    { permissions: { isSuperAdmin: superAdminConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    return isSuperAdmin && superAdminConfig && superAdminConfig.needsSuperAdminAccess;
  }

  private _isOptionAccessible_clusterUser (
    {
      isClusterUser,
      hasHybridAccess,
      hasAccessToMultiSchoolsOrShelters,
      hasAccessToAtLeastOneSchoolOrShelter,
    }: IPortalSwitcherUserPermission,
    { key, permissions: { isClusterUser: clusterUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    const isViewNetworkAccessible = key === 'View Network' ? this.isViewNetworkEnabledForDistrict() : true;
    return (
      isViewNetworkAccessible &&
      isClusterUser &&
      clusterUserConfig &&
      (!clusterUserConfig.needsHybridAccess || hasHybridAccess === clusterUserConfig.needsHybridAccess) &&
      (!clusterUserConfig.needsMultiSchoolOrShelterAccess ||
        hasAccessToMultiSchoolsOrShelters === clusterUserConfig.needsMultiSchoolOrShelterAccess) &&
      (!clusterUserConfig.needsAtLeastOneSchoolOrShelterAccess ||
        hasAccessToAtLeastOneSchoolOrShelter === clusterUserConfig.needsAtLeastOneSchoolOrShelterAccess)
    );
  }

  private _isOptionAccessible_multiSchoolUser (
    {
      isMultiSchoolUser,
      hasAccessToAtLeastTwoSchools,
    }: IPortalSwitcherUserPermission,
    { permissions: { isMultiSchoolUser: multiSchoolUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    return isMultiSchoolUser && multiSchoolUserConfig?.needsAtLeastTwoSchoolsAccess && (multiSchoolUserConfig?.needsAtLeastTwoSchoolsAccess === hasAccessToAtLeastTwoSchools);
  }

  private _isOptionAccessible_multiDistrictUser (
    { isMultiDistrictUser }: IPortalSwitcherUserPermission,
    { permissions: { isMultiDistrictUser: multiDistrictUserConfig } }: IPortalSwitcherDropdownOption,
  ): boolean {
    if (this.portalConfig.publicConfig.IS_NYC_DISTRICT) return false;
    return isMultiDistrictUser && multiDistrictUserConfig;
  }

  private isViewNetworkEnabledForDistrict () {
    const currentDistrict = this.sessionStorageService.getItem('currentDistrict');
    return !districtsConfig.DISTRICTS_WITH_VIEW_NETWORK_DISABLED.includes(currentDistrict);
  };
}
