import { Injectable } from '@angular/core';
import { ICreateActivityParams, ILinkedCourse } from '../../../typings/interfaces/activity.interface';

@Injectable()
export class GraphQLActivitiesHelperService {
  constructor() {
    //
  }

  getActivitiesQuery(schoolId, where = '{}'): string {
    return `{
      Activities(schoolId: "${schoolId}", where: "${where}"){
        _id
        startDate
        endDate
        sector
        type
        partnerOrg {
          name
          _id
        }
        hours
        status
        createdBy {
          firstName
          lastName
        }
        createdAt
        schoolId
        linkedCourses {
          code
          name
          schoolYear
          creditValue
          termYears
        }
        terms {
          startTerm {
            yearTerm
            schoolYear
            termStartDate
            termEndDate
          }
          endTerm {
            yearTerm
            schoolYear
            termStartDate
            termEndDate
          }
          termRange
        }
        hoursCountedInCourse
      }
    }`;
  }

  createActivityQuery(activityParams: ICreateActivityParams): string {
    const {
      schoolId,
      type,
      sector,
      terms,
      startDate,
      endDate,
      hours,
      partnerOrg,
      linkedCourses,
      hoursCountedInCourse,
    } = activityParams;
    let optionsString = `schoolId: "${schoolId}", type: "${type}", startDate: "${startDate}", endDate: "${endDate}", status: "IN_PROGRESS"`;
    if (sector) optionsString += `, sector: "${sector}"`;
    if (hours || hours === 0) optionsString += `, hours: ${hours}`;
    if (terms) {
      const obj = this._getTermStringValues(terms);
      optionsString += `, terms: {startTerm: ${obj.parsedStartTerm}, endTerm: ${obj.parsedEndTerm}, termRange: [${
        obj.parsedTermRange
      }]}`;
    }
    if (linkedCourses) {
      const courses = this._getLinkedCourses(linkedCourses);
      optionsString += `, linkedCourses: [${courses}]`;
    }
    const countHours = hoursCountedInCourse ? 'true' : 'false';
    if (hoursCountedInCourse) optionsString += `, hoursCountedInCourse: ${countHours}`;
    return `mutation{
      createActivity(options: {${optionsString}}, partnerOrgId: "${partnerOrg._id}"){
        _id
        startDate
        endDate
        sector
        type
        partnerOrg {
          name
          _id
        }
        hours
        status
        createdBy {
          firstName
          lastName
        }
        createdAt
        linkedCourses {
          code
          name
          schoolYear
          creditValue
          termYears
        }
        terms {
          startTerm {
            yearTerm
            schoolYear
            termStartDate
            termEndDate
          }
          endTerm {
            yearTerm
            schoolYear
            termStartDate
            termEndDate
          }
          termRange
        }
        hoursCountedInCourse
      }
    }`;
  }

  updateActivityQuery(activityId, patch): string {
    const {
      type,
      sector,
      terms,
      startDate,
      endDate,
      hours,
      partnerOrg,
      status,
      linkedCourses,
      hoursCountedInCourse,
    } = patch;
    let patchString = '';
    if (type) patchString += `type: "${type}", `;
    if (sector) patchString += `sector: "${sector}", `;
    if (startDate) patchString += `startDate: "${startDate}", `;
    if (endDate) patchString += `endDate: "${endDate}", `;
    if (hours || hours === 0) {
      const newHours = hours === 0 ? null : hours;
      patchString += `hours: ${newHours}, `;
    }
    if (linkedCourses) {
      const courses = this._getLinkedCourses(linkedCourses);
      patchString += `linkedCourses: [${courses}], `;
    }
    if (terms) {
      const obj = this._getTermStringValues(terms);
      patchString += `terms: {startTerm: ${obj.parsedStartTerm}, endTerm: ${obj.parsedEndTerm}, termRange: [${
        obj.parsedTermRange
      }]}, `;
    }
    if (status) patchString += `status: "${status}", `;
    if (partnerOrg) patchString += `partnerOrg: { _id: "${partnerOrg._id}", name: "${partnerOrg.name}" }, `;
    const countHoursDefined = typeof hoursCountedInCourse !== 'undefined';
    const countHours = countHoursDefined && hoursCountedInCourse ? 'true' : 'false'; // string for GraphQL payload
    if (countHoursDefined) patchString += `hoursCountedInCourse: ${countHours}, `;
    patchString = patchString.substring(0, patchString.length - 2);
    return `mutation{
      updateActivity(activityId: "${activityId}", patch: {${patchString} }){
        _id
        startDate
        endDate
        sector
        type
        partnerOrg {
          name
          _id
        }
        createdBy {
          firstName
          lastName
        }
        createdAt
        hours
        status
        linkedCourses {
          code
          name
          schoolYear
          creditValue
          termYears
        }
        terms {
          startTerm {
            yearTerm
            schoolYear
            termStartDate
            termEndDate
          }
          endTerm {
            yearTerm
            schoolYear
            termStartDate
            termEndDate
          }
          termRange
        }
        hoursCountedInCourse
      }
    }`;
  }

  _getLinkedCourses(linkedCourses: ILinkedCourse[]): string {
    let courses = '';
    for (let course of linkedCourses) {
      const { code, name, schoolYear, creditValue, termYear } = course;
      courses += `{code: "${code}", name: "${name}", schoolYear: "${schoolYear}", termYears: [${termYear}], creditValue: ${creditValue}}, `;
    }
    return courses.substring(0, courses.length - 2);
  }

  _getTermInfo(termObj): string {
    const { yearTerm, schoolYear, termStartDate, termEndDate } = termObj;
    const term = `{yearTerm: "${yearTerm}", schoolYear: "${schoolYear}", termStartDate: "${termStartDate}", termEndDate: "${termEndDate}"}`;
    return term;
  }

  _getTermRange(termRange: string[]): string {
    let termRangeString = '';
    termRange.forEach(term => (termRangeString += `"${term}", `));
    return termRangeString.substring(0, termRangeString.length - 2);
  }

  _getTermStringValues({ startTerm, endTerm, termRange }) {
    const parsedStartTerm = this._getTermInfo(startTerm);
    const parsedEndTerm = this._getTermInfo(endTerm);
    const parsedTermRange = this._getTermRange(termRange);
    const termStringObj = {
      parsedStartTerm,
      parsedEndTerm,
      parsedTermRange,
    };
    return termStringObj;
  }

  getPartnerOrgsQuery(schoolId, where = '{}'): string {
    return `{
      PartnerOrgs(schoolId: "${schoolId}", where: "${where}"){
        _id
        name
      }
    }`;
  }

  updatePartnerOrgQuery(partnerOrgId, patch): string {
    return `mutation{
      updatePartnerOrg(activityId: "${partnerOrgId}", patch: ${patch}){
        _id
        name
      }
    }`;
  }

  createPartnerOrgQuery(schoolId: string, partnerOrgParams: { name: string }): string {
    const { name } = partnerOrgParams;
    return `mutation{
      createPartnerOrg(options: {schoolId: "${schoolId}", name: "${name}"}){
        _id
        name
      }
    }`;
  }
}
