import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export const TEXTAREA_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => NvTextareaComponent),
  multi: true,
};

/**
 *
 * A Textarea allows the user to enter multiple lines of text.
 *
 * Use a `FormControl` to bind a value to this component.
 *
 */
@Component({
  providers: [TEXTAREA_VALUE_ACCESSOR],
  selector: 'nv-textarea',
  templateUrl: './nv-textarea.component.html',
  styleUrls: ['./nv-textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvTextareaComponent implements ControlValueAccessor {
  /**
   *
   * Defines a character limit.
   */
  @Input() maxCharacters?: number;

  /**
   *
   * Text that displays before any text has been entered.
   */
  @Input() placeholder: string = 'Enter a description...';

  public textContent: string = '';
  public charsRemaining: number;
  private _onChange;
  private _onTouched;

  constructor (private cd: ChangeDetectorRef) {}

  ngAfterViewInit () {
    this.charsRemaining = this._calcCharsRemaining(this.textContent);
    this.cd.detectChanges();
  }

  _calcCharsRemaining (value: string) {
    const relevantChars = value.replace(/([\r\t])+/g, '');
    return this.maxCharacters - relevantChars.length;
  }

  handleChange (event): void {
    const value = event.target.value;
    this.charsRemaining = this._calcCharsRemaining(value);

    if (this._onChange) this._onChange(value); // effective emit
    if (this._onTouched) this._onTouched(value); // effective emit
  }

  // Custom FormControl methods - must be present in order to be able to attach an Angular FormControl
  writeValue (val: any): void {
    if (val) {
      this.textContent = val;
    }
  }

  registerOnChange (fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched (fn: any): void {
    this._onTouched = fn;
  }
}
