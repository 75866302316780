import { StudentSet } from 'Src/ng2/shared/services/student-set/student-set.service';
import { NgModule } from '@angular/core';
import { StudentFetchMssgsService } from './student-fetch-mssgs.service';
import { StudentFetchUnzipService } from './student-fetch-unzip.service';
import { StudentFetchService } from './student-fetch.service';

@NgModule({
  providers: [StudentFetchMssgsService, StudentFetchUnzipService, StudentFetchService, StudentSet ],
})
export class StudentFetchServicesModule {
  //
}
