import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvToastComponent } from './nv-toast.component';

@NgModule({
  imports: [CommonModule, MatSnackBarModule, NvIconButtonModule, NvIconModule],
  declarations: [NvToastComponent],
  exports: [NvToastComponent],
})
export class NvToastModule {}
