import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { IRowData } from '../../../shared/models/list-models';
import { IDropdownOption } from '../../../shared/typings/interfaces/design-library.interface';
import { IShelterClusterUserIdentifier } from 'Src/ng2/shared/modals/user-management/cluster-user/shelter-cluster-user/shelter-cluster-user-modals.config';

// Mimic the structure of what backend returns per column constant calc
export interface IShelterClusterUserListMoreColmnData {
  isSelf: boolean;
  isAdmin: boolean; // in this context, it is only referring to shelter cluster admin
  canBeRemoved: boolean;
  schoolClusterAdmins: Array<{
    gafeEmail: string | null;
    doeEmail: string | null;
    dhsEmail: string | null;
    authenticationEmail: string;
  }>;
}

type TShelterClusterUserListMoreButtonActionMode = 'DETAILS' | 'PORTFOLIO' | 'REMOVE';

export interface IShelterClusterUserListMoreButtonEmitData {
  action: TShelterClusterUserListMoreButtonActionMode;
  user: IShelterClusterUserIdentifier;
  hybridClusterUserContext: {
    // only needs the other side's admins info, so it is schoolClusterAdmins
    [adminsType: string]: IShelterClusterUserListMoreColmnData['schoolClusterAdmins'];
  };
}

@Component({
  selector: 'shelter-network-settings-more-button',
  templateUrl: './shelter-network-settings-more-button.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ShelterNetworkSettingsMoreButtonComponent implements OnInit {
  @Input() input: { row: IRowData[]; column: IRowData };
  @Output() output = new EventEmitter();
  options: IDropdownOption[];
  moreButtonDisabled: boolean = false;
  user: IShelterClusterUserIdentifier;
  hybridClusterUserContext: {
    [adminsType: string]: IShelterClusterUserListMoreColmnData['schoolClusterAdmins'];
  };

  public ngOnInit () {
    const { row } = this.input;
    const accessLevel = this._getColumnFromKey({ row, columnKey: 'SHELTER_ACCESS_LEVEL' }).data;
    const userColumn = this._getColumnFromKey({ row, columnKey: 'STUB' });
    const moreColumn = this._getColumnFromKey({ row, columnKey: 'SHELTER_MORE' });
    const moreColumnData: IShelterClusterUserListMoreColmnData = JSON.parse(moreColumn.meta);
    const id = JSON.parse(userColumn.meta).data;
    const name = userColumn.data;
    this.user = { id, name };
    this.hybridClusterUserContext = {
      schoolClusterAdmins: moreColumnData.schoolClusterAdmins,
    };
    this.options = this._initDropdownOptions({ accessLevel, moreColumnData });
    this.moreButtonDisabled = this._disableMoreBtn({ accessLevel, moreColumnData });
  }

  // if the user's access level is not 'Yes' in the has access column, disable 'Edit portfolio' option
  // if the user can not be removed, disable 'Remove user' option
  private _initDropdownOptions ({ accessLevel, moreColumnData }): IDropdownOption[] {
    const { canBeRemoved } = moreColumnData;
    const editDetailOption = { key: 'Edit details', human: 'Edit details' };
    const editPortfolioOption =
      accessLevel === 'Yes'
        ? { key: 'Edit portfolio', human: 'Edit portfolio' }
        : { key: 'Edit portfolio', human: 'Edit portfolio', disabled: true };
    const removeUserOption = { key: 'Remove user', human: 'Remove user' };
    const options = canBeRemoved
      ? [editDetailOption, editPortfolioOption, removeUserOption]
      : [editDetailOption, editPortfolioOption];
    return options;
  }

  // if the user to be accessed is 'Shelter Cluster Admin', or self
  // disable three dot menu (i.e. more button)
  private _disableMoreBtn ({ accessLevel, moreColumnData }): boolean {
    const { isSelf, isAdmin } = moreColumnData;
    return isAdmin || isSelf;
  }

  public onSelect (e): void {
    let action: TShelterClusterUserListMoreButtonActionMode;
    switch (e) {
      case 'Edit details': {
        action = 'DETAILS';
        break;
      }
      case 'Edit portfolio': {
        action = 'PORTFOLIO';
        break;
      }
      case 'Remove user': {
        action = 'REMOVE';
        break;
      }
    }
    const emitData: IShelterClusterUserListMoreButtonEmitData = {
      action,
      user: this.user,
      hybridClusterUserContext: this.hybridClusterUserContext,
    };
    this.output.emit(emitData);
  }

  private _getColumnFromKey ({ row, columnKey }: { row: IRowData[]; columnKey: string }): IRowData {
    return row.find(v => v.columnKey === columnKey);
  }
}
