import { districtRoute } from './routes/district/district.route';
import { districtPickerRoute } from './routes/district-picker/district-picker.route';
import { nycSchoolPickerRoute } from './routes/nyc-school-picker/nyc-school-picker.route';
import { nycExploreRoute } from './routes/nyc-explore/nyc-explore.route';
import { nycSchoolRoute } from './routes/nyc-school/nyc-school.route';
import { nycNetworkRoute } from './routes/nyc-network/nyc-network.route';
import { homeRoute } from './routes/home/home.route';
import { callbackRoute } from './routes/callback/callback.route';
import { loginRoute } from './routes/login/login.route';
import { rootRoute } from './routes/root/root.route';
import { Route } from '@angular/router';
import { TValidUserRoles } from '../shared/typings/interfaces/user.interface';
import { notFoundRoute } from './routes/not-found/not-found.route';
import { shelterRoute } from './routes/shelter/shelter.route';
import { shelterPickerRoute } from './routes/shelter-picker/shelter-picker.route';
import { environment } from 'Src/environments/environment';

export interface IRouteConfigsOpts extends Route {
  url?: string;
  data?: { [key: string]: any };
  rolePermissions: TValidUserRoles[];
  authenticationRequired: boolean;
  // won't pass a partner at root, login, home etc
  partnerType?: 'school' | 'shelter' | 'hybrid';
}

export interface IRouteConfigs {
  [routeName: string]: IRouteConfigsOpts;
}

export const ROUTE_CONFIGS = new Map();

/**
 * Add routes here { file : fileCameled }
 * routes/shelter/shelter.route.ts --> ROUTE_CONFIGS.set('shelter.route', shelterRoute);
 */
const { PUBLIC_CONFIG } = environment;
const addNycRoutes = PUBLIC_CONFIG.IS_NYC_DISTRICT;
ROUTE_CONFIGS.set('root.route', rootRoute);
ROUTE_CONFIGS.set('login.route', loginRoute);
if (addNycRoutes) {
  ROUTE_CONFIGS.set('nyc-school.route', nycSchoolRoute);
  ROUTE_CONFIGS.set('nyc-explore.route', nycExploreRoute);
  ROUTE_CONFIGS.set('nyc-school-picker.route', nycSchoolPickerRoute);
  ROUTE_CONFIGS.set('nyc-network.route', nycNetworkRoute);
}
ROUTE_CONFIGS.set('shelter-picker.route', shelterPickerRoute);
ROUTE_CONFIGS.set('shelter.route', shelterRoute);
ROUTE_CONFIGS.set('callback.route', callbackRoute);
ROUTE_CONFIGS.set('home.route', homeRoute);
ROUTE_CONFIGS.set('district-picker.route', districtPickerRoute);
ROUTE_CONFIGS.set('not-found.route', notFoundRoute);
if (!addNycRoutes) ROUTE_CONFIGS.set('district.route', districtRoute);
/**
 * IMPORTANT! THIS WILL BREAK THE APP. THIS SHOULD ALWAYS BE THE LAST ROUTE!
 * THIS IS CATCHING EVERY ROUTE THAT FAILS TO MATCH
 */
ROUTE_CONFIGS.set('wildcard.route', { path: '**', redirectTo: '/not-found' });
