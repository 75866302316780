import { IFlag } from '../../../shared/typings/interfaces/flags.interface';
import { IPointPerson } from '../../../shared/typings/interfaces/student.interface';

export const MY_STUDENTS_PROJECTION = {
  _id: true,
  studentId: true,
  'studentDetails.name': true,
  'studentDetails.grade': true,
  'studentDetails.classOf': true,
  'studentDetails.officialClass': true,
  'gradPlanningDetails.plannedGraduationDate': true,
  'gradPlanningDetails.plannedDiplomaType': true,
  'spedDetails.isSped': true,
  'ellDetails.isEll': true,
  isMS: true,
  isHS: true,
  isES: true,
  pointPeople: true,
  otherStaff: true,
};

export interface IMyStudentsListStudent {
  _id: string;
  studentId: string;
  studentDetails: {
    classOf: string;
    grade: string;
  };
  gradPlanningDetails: {
    plannedDiplomaType: string;
    plannedGraduationDate: string;
  };
  spedDetails: {
    isSped: boolean;
  };
  ellDetails: {
    isEll: boolean;
  };
  isMS: boolean;
  isHS: boolean;
  isES: boolean;
  pointPeople: IPointPerson[];
  otherStaff: IPointPerson[];
  attendanceFlags?: IFlag[];
  academicFlags?: IFlag[];
}

export const MY_STUDENTS_JOINS = [];
