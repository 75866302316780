import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ISidebarItemQueryParams } from '../nv-sidebar-list/nv-sidebar.interface';

/**
 *
 * Used internally by the Sidebar List.
 *
 * Like the Dropdown Item, it's not likely that you'll use this component on its own.
 *
 */
@Component({
  selector: 'nv-sidebar-item',
  templateUrl: './nv-sidebar-item.component.html',
  styleUrls: ['./nv-sidebar-item.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NvSidebarItemComponent {
  /**
   *
   *
   */
  @Input() label: string;

  /**
   *
   *
   */
  @Input() icon: string;

  @Input() url?: string;

  @Input() queryParams?: ISidebarItemQueryParams;

  /**
   *
   *
   */
  @Input() isSelected: boolean = false;

  /**
   *
   *
   */
  @Input() isChild: boolean = false;

  /**
   *
   *
   */
  @Input() isDisabled: boolean = false;

  /**
   *
   * The icon that displays on the right-hand side.
   * Typically `dropdown` or `caret-down`
   */
  @Input() actionIcon: string;

  /**
   * TEMPORARY ============== TODO: REMOVE once we have beta icon
   * Whether or not to display the beta flag next to the sidebar item
   */
  @Input() hasBetaFlag: boolean = false;

  /**
   *
   *
   */
  @Output() sidebarItemSelect: EventEmitter<void> = new EventEmitter<void>();
  handleClick (event) {
    this.sidebarItemSelect.emit();
  }
}
