// tslint:disable:max-classes-per-file
import { Action } from '@ngrx/store';
export const CLEAR_SHELTER_GRID_DATA = '[Portal] Clear Shelter Grid Data';
export const LOAD_SHELTER_GRID_DATA = '[Portal] Load Shelter Grid Data';
export const LOAD_SHELTER_GRID_DATA_SUCCESS = '[Portal] Load Shelter Grid Data Success';
export const SAVE_SHELTER_GRID_DATA = '[Portal] Save Shelter Grid Data';

export class SaveShelterGridData implements Action {
  readonly type = SAVE_SHELTER_GRID_DATA;
  constructor(public payload: any) {}
}

export class LoadShelterGridData implements Action {
  readonly type = LOAD_SHELTER_GRID_DATA;
  constructor(public payload: any) {}
}

export class LoadShelterGridDataSuccess implements Action {
  readonly type = LOAD_SHELTER_GRID_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class ClearShelterGridData implements Action {
  readonly type = CLEAR_SHELTER_GRID_DATA;
}

// action types
export type ShelterGridDataActions =
  | ClearShelterGridData
  | LoadShelterGridData
  | LoadShelterGridDataSuccess
  | SaveShelterGridData;
