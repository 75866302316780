import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { LoadActivities } from './../../../../store/actions/activities-actions';
import { getActivitiesLoadedStatus, getActivitiesEntitiesList } from './../../../../store/selectors/activities-selectors';
import { Injectable, Inject } from '@angular/core';
import { getCurrentUser, getSchoolAssessmentsLoadedStatus, LoadSchoolAssessments, getSchoolAssessmentsEntitiesList } from 'Src/ng2/store';
import { StoreDataService } from 'Src/ng2/shared/services/store-data-service/store-data.service';
import { Observable, from } from 'rxjs';
import { ISchoolAssessment } from 'Src/ng2/shared/typings/interfaces/school-assessment.interface';

@Injectable()
export class SettingsPageData {
  constructor (
    private storeDataService: StoreDataService,
    private apiService: ApiService,
  ) { }

  getCurrentUser$ () {
    return this.storeDataService.getDataFromStore$(getCurrentUser);
  }

  getActivites$ (payload: {schoolId: string}) {
    const statusSelection = getActivitiesLoadedStatus;
    const loadAction = LoadActivities;
    const itemSelection = getActivitiesEntitiesList;
    return this.storeDataService.loadDataAndGetStream$(payload, statusSelection, loadAction, itemSelection);
  }

  getSchoolAssessments$ (payload: {schoolId: string, schoolYear: string}): Observable<Array<ISchoolAssessment>> {
    const statusSelection = getSchoolAssessmentsLoadedStatus;
    const loadAction = LoadSchoolAssessments;
    const itemSelection = getSchoolAssessmentsEntitiesList;
    return this.storeDataService.loadDataToStore$(payload, statusSelection, loadAction, itemSelection);
  }

  getSchoolUsers$ (payload: {schoolId: string }) {
    const { schoolId } = payload;
    return this.apiService.getUsers(schoolId, { where: { authorizationStatus: { $eq: 'FULL_ACCESS' } } });
  }
}
