import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvIconButtonComponent } from './nv-icon-button.component';

@NgModule({
  imports: [CommonModule, NvIconModule, MatRippleModule],
  declarations: [NvIconButtonComponent],
  exports: [NvIconButtonComponent],
})
export class NvIconButtonModule {}
