import { IActivity } from './../../shared/typings/interfaces/activity.interface';
/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_ACTIVITIES = '[Portal] Load Activities';
export const LOAD_ACTIVITIES_SUCCESS = '[Portal] Load Activities Success';
export const LOAD_ACTIVITIES_FAIL = '[Portal] Load Activities Failure';
export const CREATE_ACTIVITY = '[Portal] Create Activity';
export const CREATE_ACTIVITY_SUCCESS = '[Portal] Create Activity Success';
export const CREATE_ACTIVITY_FAIL = '[Portal] Create Activity Fail';
export const UPDATE_ACTIVITY = '[Portal] Update Activity';
export const UPDATE_ACTIVITY_SUCCESS = '[Portal] Update Activity Success';
export const UPDATE_ACTIVITY_FAIL = '[Portal] Update Activity Fail';

// register store actions
export class LoadActivities implements Action {
  readonly type = LOAD_ACTIVITIES;
  constructor(public payload: { schoolId: string }) {}
}

export class LoadActivitiesSuccess implements Action {
  readonly type = LOAD_ACTIVITIES_SUCCESS;
  constructor(public payload: { data: { Activities: IActivity[] } }) {}
}

export class LoadActivitiesFail implements Action {
  readonly type = LOAD_ACTIVITIES_FAIL;
  constructor(public payload: any) {}
}

export class CreateActivity implements Action {
  readonly type = CREATE_ACTIVITY;
  constructor(public payload: any) {}
}

export class CreateActivitySuccess implements Action {
  readonly type = CREATE_ACTIVITY_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateActivityFail implements Action {
  readonly type = CREATE_ACTIVITY_FAIL;
  constructor(public payload: any) {}
}

export class UpdateActivity implements Action {
  readonly type = UPDATE_ACTIVITY;
  constructor(public payload: { activityId: string; patch: any }) {}
}

export class UpdateActivitySuccess implements Action {
  readonly type = UPDATE_ACTIVITY_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateActivityFail implements Action {
  readonly type = UPDATE_ACTIVITY_FAIL;
  constructor(public payload: any) {}
}

// action types
export type ActivitiesAction =
  | LoadActivities
  | LoadActivitiesSuccess
  | LoadActivitiesFail
  | CreateActivity
  | CreateActivitySuccess
  | CreateActivityFail
  | UpdateActivity
  | UpdateActivitySuccess
  | UpdateActivityFail;
