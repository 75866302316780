import { IModalsConfig } from '../../modals.config.interface';

export const EOP_MILESTONE_MODAL_CONFIG: IModalsConfig = {
  dropdown: {
    options: [
      { key: '—', human: '—' },
      { key: 'Eligible', human: 'Eligible' },
      { key: 'Not eligible', human: 'Not eligible' },
    ],
  },
  buttons: {
    confirm: { text: 'Confirm', disableButtonRipple: true },
    cancel: { text: 'Cancel', disableButtonRipple: true },
  },
};
