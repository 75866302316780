import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { getCurrentUser, getSchool } from 'Src/ng2/store';

@Component({
  selector: 'credit-gaps-wizard-container',
  templateUrl: './credit-gaps-wizard-container.component.html',
  styleUrls: ['./credit-gaps-wizard-container.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreditGapsWizardContainer implements OnInit {
  initialWizardState;
  currentSchool;
  currentUser;
  isTransfer;
  constructor (
    private route: ActivatedRoute,
    private store: Store<any>,
    private imSchool: ImSchool,
  ) {}

  ngOnInit (): void {
    this.initialWizardState = this.route.snapshot.data.initialWizardState;
    this.store
      .pipe(
        select(getCurrentUser),
        take(1),
      )
      .subscribe(val => {
        this.currentUser = val;
      });
    this.store
      .pipe(
        select(getSchool),
        take(1),
      )
      .subscribe((val: ISchool) => {
        this.currentSchool = val;
        this.isTransfer = this.imSchool.isTransferSchool(val);
      });

    // NOTE-jchu: the original logic for quering currentCourseDiffs:
    // https://github.com/NewVisionsForPublicSchools/nv-data-tools-portal/pull/6054/files#diff-1fd3a3f68e942ab80159ee4454d9441182010ca2576f8e4e469d4d0b53a5ad62L441
  }
}
