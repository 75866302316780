import { Component, Input, OnInit, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';

export interface IUpdateSelectorOutput {
  autoUpdateSchedule: null | {
    weekDay: '0' | '1' | '2' | '3' | '4' | '5' | '6' | '*' ;
  };
};

export type IUpdateSelectorInput = Partial<IUpdateSelectorOutput>;

const scheduleToFrequencyAndWeekDaySelections =
  (autoUpdateSchedule: IUpdateSelectorInput['autoUpdateSchedule']) => {
    if (!autoUpdateSchedule) {
      return { frequency: 'No automatic updates', weekDay: '0' };
    }
    if (autoUpdateSchedule.weekDay === '*') {
      return { frequency: 'Daily', weekDay: '0' };
    }
    return { frequency: 'Weekly', weekDay: autoUpdateSchedule.weekDay };
  }
;

const WEEKDAY_AVAILABLE_OPTIONS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const frequencyAndWeekDaySelectionsToSchedule =
  (frequency: string, weekDay: string): IUpdateSelectorOutput => {
    switch (frequency) {
      case 'Daily':
        return {
          autoUpdateSchedule: {
            weekDay: '*',
          },
        };
      case 'Weekly':
        return { autoUpdateSchedule: { weekDay } } as IUpdateSelectorOutput;
      case 'No automatic updates':
      default:
        return { autoUpdateSchedule: null };
    }
  }
;

@Component({
  selector: 'update-selector',
  templateUrl: './update-selector.component.html',
  styleUrls: ['./update-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateSelectorComponent implements OnInit {
  @Input() isEditMode: boolean;
  @Input() initialFormData: IUpdateSelectorInput;
  @Output() completed: EventEmitter<IUpdateSelectorOutput> = new EventEmitter<IUpdateSelectorOutput>();
  @Output() abandoned: EventEmitter<void> = new EventEmitter<void>();

  form: FormGroup;

  weekDayAvailableOptions: IDropdownOption[] = (
    WEEKDAY_AVAILABLE_OPTIONS.map((str, idx) => ({ key: idx.toString(), human: str }))
  );

  nextButtonText: string;

  constructor (
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit () {
    const {
      frequency,
      weekDay,
    } = scheduleToFrequencyAndWeekDaySelections(this.initialFormData.autoUpdateSchedule);

    this.form = this.formBuilder.group(
      {
        frequency: [frequency, Validators.required],
        weekDay: [weekDay],
      },
      {
        validators: (control) => {
          const frequency = control.get('frequency');
          const weekDay = control.get('weekDay');
          const weekDayRequired = frequency && frequency.value === 'Daily';
          if (weekDayRequired && weekDay && !weekDay.value) {
            return { weekDayOptionsMissing: true };
          }
          return null;
        },
      },
    );

    this.nextButtonText = this.isEditMode ? 'Save' : 'Create';
  }

  onWeekDaySelected ($event: string) {
    this.form.controls.weekDay.setValue($event);
  }

  onFrequencySelected ({ value }: { value: string }) {
    this.form.controls.frequency.setValue(value);
  }

  onNext () {
    const output = frequencyAndWeekDaySelectionsToSchedule(
      this.form.controls.frequency.value,
      this.form.controls.weekDay.value,
    );
    this.completed.emit(output);
  }

  onBack () {
    this.abandoned.emit();
  }
}
