import { Injectable } from '@angular/core';
import { map, reduce, sortBy } from 'lodash';
import { Observable } from 'rxjs';
import { map as rxMap } from 'rxjs/operators';
import { IActivity } from 'Src/ng2/shared/typings/interfaces/activity.interface';
import { IListViewData } from 'Src/ng2/shared/typings/interfaces/list-view.interface';
import { IStudentActivity } from 'Src/ng2/shared/typings/interfaces/student-activity.interface';
import { DateHelpers } from '../../../date-helpers/date-helpers.service';
import { ImActivity } from '../../../im-models/im-activity.service';
import { ImStudentActivity } from '../../../im-models/im-student-activity.service';
import { FORMATTED_GROUPING_LISTS } from '../../mad-lib-groupings.constant';
import { Moment } from 'moment';

export interface IActivityRow {
  activityName: {
    data: string;
    dependencies: {
      activityId: string;
    };
  };
  status: {
    data: string;
    dependencies: object;
  };
  createdBy: {
    data: string;
    dependencies: object;
    tooltipMsg: string;
    tooltipClass: string;
  };
  projectedHours: {
    data: string | number;
    dependencies: object;
  };
  linkedCourses: {
    data: string | number;
    tooltipMsg: string;
    tooltipClass: string;
    dependencies: object;
  };
}

export interface IParsedActivity {
  schoolYear: string;
  activityName: string;
  activityId: string;
  status: string;
  statusOrder: number;
  createdBy: string;
  createdAt: string;
  createdByTooltip: string;
  projectedHours: string | number;
  linkedCourses: string | number;
  linkedCoursesTooltip: string;
}

export interface IParsedStudentActivity {
  studentActivityId: string;
  schoolYear: string;
  activityName: string;
  activityId: string;
  status: string;
  statusOrder: number;
  startDate: string;
  parsedHours: string | number;
  style: string;
}

@Injectable()
export class ActivitiesGrouping {
  constructor (
    private imStudentActivity: ImStudentActivity,
    private dateHelpers: DateHelpers,
    private imActivity: ImActivity,
  ) {
    //
  }

  _parseStudentActivity (studentActivity: IStudentActivity): IParsedStudentActivity {
    const {
      _id,
      activity: { hours, activityId, schoolYear },
      completedHours,
      startDate,
    } = studentActivity;
    let parsedHours = '';
    if (!completedHours && completedHours !== 0) {
      parsedHours = hours > 0 ? `-/${hours}` : '–';
    } else {
      parsedHours = hours ? `${completedHours}/${hours}` : `${completedHours}`;
    }
    const activityName = this.imStudentActivity.getStudentActivityTitle(studentActivity);
    const PARSED_STATUS_ORDER = {
      Upcoming: 0,
      'In progress': 1,
      'Needs confirmation': 2,
      Complete: 3,
    };
    const parsedStatus = this.getStudentActivityStatus(studentActivity);
    const statusOrder = PARSED_STATUS_ORDER[parsedStatus];
    const style = parsedStatus === 'Needs confirmation' ? 'warning' : null;
    return {
      studentActivityId: _id,
      schoolYear,
      activityName,
      activityId,
      status: parsedStatus,
      statusOrder,
      startDate,
      parsedHours,
      style,
    };
  }

  getStudentActivityStatus (studentActivity: IStudentActivity) {
    const { status, startDate, endDate } = studentActivity;
    let parsedStatus;
    if (status === 'COMPLETED') parsedStatus = 'Complete';
    // user marked complete (JE)
    else if (status === 'INCOMPLETE') {
      // user marked incomplete
      parsedStatus = 'Incomplete';
    } else {
      // no user-applied status
      const today = this.dateHelpers.getMomentObj();
      if (today.isBefore(startDate)) parsedStatus = 'Upcoming';
      // start date in future
      else if (today.isBetween(startDate, endDate, 'day', '[]')) parsedStatus = 'In progress';
      // today is in date range
      else parsedStatus = 'Needs confirmation'; // today is after end date
    }
    return parsedStatus;
  }

  _parseActivity (activity: IActivity, today: Moment): IParsedActivity {
    const {
      _id,
      terms: {
        startTerm: { schoolYear },
      },
      status,
      startDate,
      endDate,
      createdBy: { firstName, lastName },
      createdAt,
      hours,
      linkedCourses,
    } = activity;
    if (status !== 'DELETED') {
      const parsedHours = hours === 0 || !hours ? '-' : hours;
      const activityName = this.imActivity.getActivityTitle(activity);
      const parsedCreatedAt = this.dateHelpers.getFormattedFromISO(createdAt, 'M/DD/YYYY');
      const activityStartMoment = this.dateHelpers.getMomentObj(startDate);
      const activityEndMoment = this.dateHelpers.getMomentObj(endDate);
      const isUpcoming = today <= activityStartMoment;
      const isInProgress = today >= activityStartMoment && today <= activityEndMoment;
      let parsedStatus;
      let statusOrder;
      if (isUpcoming) {
        parsedStatus = 'Upcoming';
        statusOrder = 0;
      } else if (isInProgress) {
        parsedStatus = 'In progress';
        statusOrder = 1;
      } else {
        parsedStatus = 'Past';
        statusOrder = 2;
      }
      const linkedCoursesCount = linkedCourses && linkedCourses.length ? linkedCourses.length : '-';
      const sortedCourses = sortBy(linkedCourses, course => {
        return course ? course.name : null;
      });
      const linkedCoursesTooltip = reduce(
        sortedCourses,
        (tooltip, course) => {
          tooltip += course ? `${course.name} (${course.code})\n` : '';
          return tooltip;
        },
        '',
      );
      return {
        schoolYear,
        activityName,
        activityId: _id,
        status: parsedStatus,
        statusOrder,
        createdAt: parsedCreatedAt,
        createdBy: `${firstName} ${lastName}`,
        createdByTooltip: `Created on ${parsedCreatedAt}`,
        projectedHours: parsedHours,
        linkedCourses: linkedCoursesCount,
        linkedCoursesTooltip,
      };
    }
  }

  public _shapeActivityForTable (activity: IParsedActivity): IActivityRow {
    const {
      activityName,
      activityId,
      status,
      createdBy,
      createdByTooltip,
      projectedHours,
      linkedCourses,
      linkedCoursesTooltip,
    } = activity;

    return {
      activityName: {
        data: activityName,
        dependencies: {
          activityId,
        },
      },
      status: {
        data: status,
        dependencies: {},
      },
      createdBy: {
        data: createdBy,
        dependencies: {},
        tooltipMsg: createdByTooltip,
        tooltipClass: 'activity-tooltip',
      },
      projectedHours: {
        data: projectedHours,
        dependencies: {},
      },
      linkedCourses: {
        data: linkedCourses,
        tooltipMsg: linkedCoursesTooltip,
        tooltipClass: 'tooltip-linked-courses',
        dependencies: {},
      },
    };
  }

  public getActivitiesGrouping (activities$: Observable<IActivity[]>, columns, listType): Observable<any> {
    const today = this.dateHelpers.moment();
    return activities$.pipe(
      rxMap(activities => {
        const parsedActivities = reduce(
          activities,
          (res, activity) => {
            const parsed = this._parseActivity(activity, today);
            if (parsed) res.push(parsed);
            return res;
          },
          [],
        );
        const sortedActivities = sortBy(parsedActivities, ['statusOrder', 'createdAt']);
        const activitiesBySY = reduce(
          sortedActivities,
          (res, activity: IParsedActivity) => {
            const { schoolYear } = activity;
            const parsedSchoolYear = 'SY' + schoolYear.slice(4);
            const shapedActivity = this._shapeActivityForTable(activity);
            let currentActivities;
            if (res[parsedSchoolYear]) {
              currentActivities = res[parsedSchoolYear];
            } else {
              currentActivities = [];
            }
            // const currentActivities = res[parsedSchoolYear] ? res[parsedSchoolYear] : [];
            currentActivities.push(shapedActivity);
            res[parsedSchoolYear] = currentActivities;
            return res;
          },
          {},
        );

        const schoolYears = Object.keys(activitiesBySY).sort((a, b) => {
          return a < b ? 1 : -1;
        });

        const formattedList: IListViewData = FORMATTED_GROUPING_LISTS.activities.getFormattedList({
          columns,
          listType,
        });
        formattedList.sections = map(schoolYears, year => {
          const activityData = activitiesBySY[year];
          return {
            name: year,
            count: activityData.length,
            data: activityData,
            defaultDisplayCount: 100,
            dataType: 'String',
            key: year,
          };
        });
        return formattedList;
      }),
    );
  }
}
