import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { LoadCurrentUser } from './../../../store/actions/current-user-actions';
import { Auth } from './../../../shared/auth/auth.service';
import { getCurrentUser, getCurrentUserLoadedStatus } from './../../../store/selectors/current-user-selectors';
import { Store, select } from '@ngrx/store';
import { identity } from 'lodash';
import { LoadSchool } from './../../../store/actions/school-actions';
import { take, tap, switchMap, filter } from 'rxjs/operators';
import { getSchoolLoadedStatus, getSchool } from './../../../store/selectors/school-selectors';
import { combineLatest, Observable, of } from 'rxjs';
import { ImSchool } from './../../../shared/services/im-models/im-school';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ISummerSchool } from 'Src/ng2/shared/typings/interfaces/school.interface';

@Injectable()
export class SummerSchoolGuard implements CanActivate {
  constructor (
    public imSchool:ImSchool,
    public router: Router,
    public store: Store<any>,
    private auth: Auth,
  ) {}

  getSchoolFromStoreOrAPI (schoolId: string): Observable<ISummerSchool> {
    return this.store.pipe(
      select(getSchoolLoadedStatus),
      tap(loaded => {
        if (!loaded) this.store.dispatch(new LoadSchool(schoolId));
      }),
      filter(identity),
      switchMap(() => this.store.pipe(select(getSchool))),
      take(1),
    );
  }

  getCurrentUserFromStoreOrApi (): Observable<IUser> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      tap(async loaded => {
        if (!loaded) {
          const _id = await this.auth.getCurrentUserId();
          this.store.dispatch(new LoadCurrentUser({ _id }));
        }
      }),
      filter(loaded => loaded),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
      take(1),
    );
  }

  canActivate (snapshot: ActivatedRouteSnapshot): Observable<UrlTree | boolean> {
    const { schoolId } = snapshot.params;

    return combineLatest([
      this.getSchoolFromStoreOrAPI(schoolId),
      this.getCurrentUserFromStoreOrApi()
    ]).pipe(
      switchMap(([school, currentUser]) => {
        if (!this.imSchool.isSummerSchool(school, currentUser)) return of(this.router.createUrlTree(['home'])) as Observable<UrlTree>;
        else return of(true) as Observable<boolean>;
      }),
    );
  }
}
