import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Unsubscribable } from 'rxjs';
import { Observable } from 'rxjs/Observable';

import { ACTIVITIES_LIST_COLUMNS } from '../../../../school/lists/list-display/list-display-activity/list-display-activity.component';
import { LIST_TYPE } from '../../../../shared/constants/list-view/list-view.constant';
import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { IActivitiesModalComponentData } from '../../../../shared/modals/activities/activities-modal.component';
import { ModalsService } from '../../../../shared/modals/modals.service';
import { ActivitiesGrouping } from '../../../../shared/services/mad-lib-services/grouping-functions/postsec-groupings/activities-groupings';
import { IActivity } from '../../../../shared/typings/interfaces/activity.interface';
import { ISchool } from '../../../../shared/typings/interfaces/school.interface';
import { SettingsPageData } from './../settings-data-service/settings-data.service';

@Component({
  selector: 'activities-settings',
  templateUrl: './activities-settings.component.html',
  styleUrls: ['./activities-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class ActivitiesSettingsComponent implements OnInit, OnDestroy {
  public activities$: Observable<IActivity[]>;
  public currentSchool: ISchool;
  public activities: IActivity[];
  public formattedActivities$;
  activitiesSub: Unsubscribable;

  public pageHeaderMeta = {
    title: 'Activities',
    subTitle: null,
    icon: {
      tooltip: null,
    },
    action: {
      label: 'Create new activity',
      trigger: null,
    },
    infoIcon: {
      tooltipData: 'This section provides a single place to create or modify activities for your school. Activities represent student experiences which can include, but are not limited to, college and career development, work based learning and academic enrichment.',
    },
  };

  constructor (
    private activitiesGroupings: ActivitiesGrouping,
    private modalsService: ModalsService,
    private dataService: SettingsPageData,
    private activatedRoute: ActivatedRoute,
  ) {
    document.title = 'Settings - Activities | Portal';
  }

  ngOnInit () {
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;
    this.activities$ = this.dataService.getActivites$({ schoolId: this.currentSchool._id });
    this.formatListData(this.activities$);

    // subscribe to activities so they can be passed to list-display-activites (JE)
    this.activitiesSub = this.activities$.subscribe(activities => {
      this.activities = activities;
    });

    const opts: IActivitiesModalComponentData = {
      mode: 'CREATE',
      schoolId: this.currentSchool._id,
      isProfileMode: true,
    };

    this.pageHeaderMeta.action.trigger = () => {
      this.modalsService.openActivitiesModal(opts);
    };
  }

  ngOnDestroy ():void {
    document.title = 'Portal by New Visions';
  }

  formatListData (activities: Observable<IActivity[]>): void {
    const formatedActivitiesGrouping = this.activitiesGroupings.getActivitiesGrouping(activities, ACTIVITIES_LIST_COLUMNS, LIST_TYPE.ACTIVITIES);
    this.formattedActivities$ = formatedActivitiesGrouping;
  }
}
