import { IUser, IUserMini, IUserMiniWithRole } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { TValidPartnerTypes } from 'Src/ng2/shared/typings/interfaces/partner.interface';

const defaultHybridHandler = (user: any) => user;

export function userCate(user: IUser | IUserMini | IUserMiniWithRole, partnerType: TValidPartnerTypes = 'school') {
  return ({ school: schoolHandler, shelter: shelterHandler, hybrid: hybridHandler = defaultHybridHandler }) => {
    let res = null;
    switch (partnerType) {
      case 'hybrid':
        res = hybridHandler(user);
        break;
      case 'school':
        res = schoolHandler(user);
        break;
      case 'shelter':
        res = shelterHandler(user);
        break;
      default:
        break;
    }
    return res;
  };
}
