import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';
import { NvDropdownModule } from 'Src/nvps-libraries/design/nv-dropdown/nv-dropdown.module';

import { BaseModalModule } from '../../base-modal.module';
import { EOPMilestoneModalComponent } from './eop-milestone-modal.component';
@NgModule({
  declarations: [EOPMilestoneModalComponent],
  imports: [
    CommonModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDialogModule,
    BaseModalModule,
    NvDropdownModule,
    NvButtonModule,
  ],
  exports: [EOPMilestoneModalComponent],
})
export class EOPMilestoneModalModule {}
