import { WindowRef } from 'Src/ng2/shared/services/windowRef';
import { IConfirmModalComponentData } from 'Src/ng2/shared/modals/confirm/confirm-modal.component';
import { GraduationDate } from 'Src/ng2/shared/constants/graduation-date.constant';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { Observable } from 'rxjs/Observable';
import { LoadSdcStudents, UpdateSdcStudents } from './../../../store/actions/sdc-actions/sdc-actions';
import { getSdcFlattenedStudentList, getSdcLoadingStatus, getSdcColumnKeys } from './../../../store/selectors/sdc-selectors/sdc-selectors';
import { ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { getSupportsEntitiesList } from './../../../store/selectors/supports-selectors';
import { LoadSupports } from './../../../store/actions/supports-actions';
import { tap, filter, switchMap, shareReplay, withLatestFrom, map, take } from 'rxjs/operators';
import { getSupportsLoadedStatus, getStudentSupportsEntitiesList, LoadStudentSupports, getStudentSupportsLoadedStatus, getCurrentUser, getSchool } from 'Src/ng2/store';
import { Store } from '@ngrx/store';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';
import { Router } from '@angular/router';
import { NextRegentsAdminDate } from './../../../shared/constants/next-regents-admin-date.constant';
import { RegentsExam, IRegentsExam } from './../../../shared/constants/regents.constant';
import { Injectable } from '@angular/core';
import { reduce, find, identity, toArray, last, findIndex, slice, each, map as _map, orderBy, valuesIn } from 'lodash';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { diploma } from 'Src/ng2/shared/constants/graduation-plan.constant';
import { StudentStatic } from 'Src/ng2/shared/services/static-models/student-static.service';
import { Cohort } from 'Src/ng2/shared/constants/cohort.constant';

type TIRegentsFields = keyof IRegentsExam;

export interface IRegentsBulkPatch {
  _ids: string[];
  path: string;
  newValue: { adminDate: string, isWaived: boolean };
}

export interface IStudentToExamData {
  [key: string]: {
    _ids: string[];
    studentIds: string[];
  };
}

export interface IPatchEvent {
  title?: string;
  message: string;
  patches: any[]
}

@Injectable()
export class WizardHelpers {
  constructor (
    private imSchool: ImSchool,
    private store: Store<any>,
    private objectCache: ObjectCache,
    private router: Router,
    private modalService: ModalsService,
    private windowRef: WindowRef,
  ) { }

  getRegentsWizardLanguageHelper (preferredKey) {
    return reduce(
      RegentsExam,
      (helperObject, examData) => {
        const key = examData[preferredKey];
        // can add but not remove keys
        const { humanName, subject } = examData;
        helperObject[key] = { humanName, subject };
        return helperObject;
      },
      {},
    );
  }

  getRegentsExamBulkPatch (
    studentToExamData: IStudentToExamData,
    IRegentExamField: TIRegentsFields,
  ): IRegentsBulkPatch[] {
    return reduce(
      studentToExamData,
      (patches: IRegentsBulkPatch[], { _ids }, prop: string) => {
        if (!_ids.length) return patches;
        const propToKey = find(RegentsExam, { [IRegentExamField]: prop }).key;
        patches.push({
          _ids,
          path: `nextScheduledRegents.${propToKey}`,
          newValue: {
            adminDate: NextRegentsAdminDate,
            isWaived: true,
          },
        });
        return patches;
      },
      [],
    );
  }

  getGradPlanningBulkPatch (_ids: string[], gradDate: string, diplomaType: diploma) {
    return [
      {
        _ids,
        path: 'gradPlanningDetails.plannedDiplomaType',
        newValue: `${diplomaType}`,
      },
      {
        _ids,
        path: 'gradPlanningDetails.plannedGraduationDate',
        newValue: `${gradDate}`,
      },
    ];
  }

  getCountOfStudentsToBePatched (studentExamData: IStudentToExamData): number {
    return reduce(
      studentExamData,
      (total: number, { studentIds }) => {
        return (total += studentIds.length);
      },
      0,
    );
  }

  goToStateInNewTab (url: string): void {
    this.windowRef.nativeWindow.open(url);
  }

  getFilteredStudents ({ filters, students }) {
    return students.filter((student) => {
      for (const key in filters) {
        if (filters[key].values && !filters[key].values.includes(student[key])) return false;
      }
      return true;
    });
  }

  updateUrl (dropdownSelection, activeWizardStep) {
    const filter = this.objectCache.cacheObject({ dropdownSelection });
    this.router.navigate(
      [],
      {
        queryParams: { filter, activeWizardStep },
        replaceUrl: true,
        queryParamsHandling: 'merge',
      });
  }

  navigateToView ({ url, cacheData, sort = null }: { url: string; cacheData: object; sort?: any }): void {
    const filter = this.objectCache.cacheObject(cacheData);
    this.router.navigate([url], { queryParams: { filter, sort } });
  }

  getSdcStudents (config) {
    return this.getSchool$()
      .pipe(
        map(({ _id }) => _id),
        withLatestFrom(this.store.select(getSdcColumnKeys)),
        tap(([schoolId, loadedColumnKeys]) => {
          if (!config.columns.every((column) => loadedColumnKeys.includes(column))) {
            this.store.dispatch(new LoadSdcStudents({
              schoolId,
              columnKeys: config.columns,
              joins: config.joins,
              bundleName: config.bundleName,
            } as any));
          }
        }),
        switchMap(() => this.store.select(getSdcLoadingStatus)),
        filter((loading) => !loading),
        switchMap(() => this.store.select(getSdcFlattenedStudentList)),
      );
  }

  getSchool$ (): Observable<ISchool> {
    return this.store.select(getSchool);
  }

  getUser$ () {
    return this.store.select(getCurrentUser);
  }

  getStudentSupports$ (schoolId: string) {
    return this.store.select(getStudentSupportsLoadedStatus)
      .pipe(
        tap((loaded: boolean) => {
          if (!loaded) this.store.dispatch(new LoadStudentSupports({ schoolId }));
        }),
        filter(identity),
        switchMap(() => this.store.select(getStudentSupportsEntitiesList)),
      );
  }

  getSchoolSupports$ (schoolId: string) {
    return this.store.select(getSupportsLoadedStatus)
      .pipe(
        tap((loaded: boolean) => {
          if (!loaded) this.store.dispatch(new LoadSupports({ schoolId }));
        }),
        filter(identity),
        switchMap(() => this.store.select(getSupportsEntitiesList)),
      );
  }

  generateNonTransferDropdown () {
    const dropdownOptions = [
      { key: 'All', human: 'All' },
      ...this.imSchool.getCurrentCohorts().map((cohort) => {
        const isSeniorYear = cohort === CurrentSchoolYear.ENDFULL;
        if (isSeniorYear) return { key: cohort, human: `${cohort} and older` };
        else return { key: cohort, human: cohort };
      })];
    return dropdownOptions;
  }

  generateTransferDropdown (nextFourGradDates) {
    const gradDatesArray = toArray(GraduationDate);
    const lastDate:any = last(nextFourGradDates);
    const indexOfLastDate = findIndex(gradDatesArray, lastDate);
    const diplomaTypes = ['Local', 'Regents', 'Advanced Regents'];

    const beyondLastTermGradPlans = reduce(
      slice(gradDatesArray, indexOfLastDate + 1),
      (result, { humanName }) => {
        each(diplomaTypes, (diploma) => result.push(`${diploma} ${humanName}`));
        return result;
      },
      [],
    );

    const beyondDatesDropdownOption = [
      {
        key: `Planned Beyond ${lastDate.humanName} Grad`,
        human: `Planned Beyond ${lastDate.humanName} Grad`,
        values: beyondLastTermGradPlans,
      },
    ];

    const nextFourTermsDropdownOptions = _map(nextFourGradDates, ({ humanName }) => {
      const gradPlans = [];
      each(diplomaTypes, diploma => gradPlans.push(`${diploma} ${humanName}`));
      return { key: `Planned ${humanName} Grad`, human: `Planned ${humanName} Grad`, values: gradPlans };
    });
    return [{ key: 'All', human: 'All', values: beyondLastTermGradPlans }, ...nextFourTermsDropdownOptions, ...beyondDatesDropdownOption];
  }

  generateGradPlanDropdown (): { key: string; human: string }[] {
    const years = valuesIn(Cohort);
    const arrayOfOptionObjects =  reduce(
      years,
      (acc, { studentYear, humanName }: any) => {
        if (studentYear === 4) acc.push({ key: humanName, human: `${humanName} and older` });
        if (studentYear <= 3) acc.push({ key: humanName, human: humanName });
        return acc;
      },
      [{ key: 'All', human: 'All' }],
    );

    return arrayOfOptionObjects;
  }

  generateTransferGradPlanDropdown (students: any): { key: string; human: string }[] {
    const uniqueOptions = new Set();
    const arrayOfOptionObjects = students.reduce((acc, { cohort }) => {
      if (cohort && !uniqueOptions.has(cohort)) {
        acc.push({ key: cohort, human: cohort });
        uniqueOptions.add(cohort);
      }
      return acc;
    }, []);
    const orderedOptions = orderBy(arrayOfOptionObjects, ['key']);
    return [{ key: 'All', human: 'All' }, ...orderedOptions];
  }

  getFilters (configFilter, dropdownFilter) {
    return Object.assign({}, configFilter, dropdownFilter || {});
  }

  getClassFilter (school, dropdownSelection) {
    const isSeniorYear = dropdownSelection === CurrentSchoolYear.ENDFULL;
    const classFilter = { cohort: { values: isSeniorYear ? this.imSchool.getSeniorAndSuperCohorts(school) : dropdownSelection === 'All' ? null : [dropdownSelection] } };
    return classFilter;
  }

  getGradPlanFilter (dropdownSelection, dropdownOptions) {
    const gradFilter = { gradPlan: { values: dropdownSelection === 'All' ? null : dropdownOptions.find(({ key }) => key === dropdownSelection)?.values } };
    return gradFilter;
  }

  confirmThenPatchStudents ({ patches, title = 'Confirm Bulk Update', message }: IPatchEvent): void {
    const data: IConfirmModalComponentData = {
      title,
      message,
      confirmText: 'OK',
    };

    this.modalService
      .openConfirmModal(data)
      .afterClosed()
      .pipe(
        filter(identity),
        tap(() => this.store.dispatch(new UpdateSdcStudents({ patches }))),
      )
      .subscribe();
  }

  getIdsFromDistrictIds (districtIds: string[], schoolId: string): string[] {
    return StudentStatic.createIdsFromDistrictIds(schoolId, districtIds);
  }
}
