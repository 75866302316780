import {
  IUser,
  IUserMiniWithRole,
} from '../../../typings/interfaces/user.interface';
import { IM_USER_CONSTANTS } from './../im-user';
import { ImUserCore } from './im-user-core';
export class ImUserShelter extends ImUserCore {
  isShelterUser (user: IUser): boolean {
    const role = this.getRoleType(user, 'shelter');
    return IM_USER_CONSTANTS.SHELTER_ROLES.includes(role as any);
  }

  // Decide if cluster user can acccess network settings (i.e. shelter cluster user permissions)
  canViewShelterNetworkSettings (user: IUser | IUserMiniWithRole): boolean {
    if (this.isSuperAdmin(user, 'shelter')) {
      return true;
    }
    const {
      nvRoleShelter: { type },
      canManageShelterUsers,
    } = user;
    const roleMet = type === 'shelter_cluster_admin';
    return roleMet && canManageShelterUsers;
  }

  // Decide if cluster user has at least one shelter to view (MVP)
  static checkShelterPortfolioAccess (user: IUser): boolean {
    if (this.isSuperAdmin(user, 'shelter')) {
      return true;
    }
    const imUser = new this();
    const role = imUser.getRoleType(user, 'shelter');
    if (role === 'shelter_cluster_admin') {
      return true;
    }
    if (role === 'shelter_only') {
      return this.isClusterWideOrHasOnePlus(user.shelterPortfolio);
    }
    // if shelter_only user does not have any shelters that are viewable
    // returns false
    return false;
  }

  // Decide if cluster user can access student lvl from mid lvl in shelter net dash view
  // TODO
  canSeeShelterNetworkStuLvl (user: IUser): boolean {
    if (this.isSuperAdmin(user, 'shelter')) {
      return true;
    }
    // ..
    return false;
  }

  // Decide if cluster user can manage users
  static checkShelterClusterUserManageStatus (user: IUser): boolean {
    if (this.isSuperAdmin(user, 'shelter')) {
      return true;
    }
    return user.canManageShelterUsers;
  }
}
