import { BACKGROUND_JOB_STATUS_TYPES } from './../../../services/background-job/background-job.service';
import { HttpResponse } from '@angular/common/http';
import { BatchActionsEffectsUtilities } from './../../../../store/utilities/batch-actions-effects-utilities';
import { SpinnerService } from 'Src/ng2/shared/components/spinner/spinner-modal.service';
import { Component, OnInit, OnDestroy, Injector, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, forkJoin, throwError } from 'rxjs';
import { take, tap, switchMap, catchError, filter } from 'rxjs/operators';

import { IGroupData, IRowData } from 'Src/ng2/shared/models/list-models';
import { ApiService } from 'Src/ng2/shared/services/api-service/api-service';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { unsubscribeComponent } from '../../../../shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { getCurrentUser } from './../../../../store/selectors/current-user-selectors';
import { ModalsService } from 'Src/ng2/shared/modals/modals.service';
import { IConfirmModalComponentData } from 'Src/ng2/shared/modals/confirm/confirm-modal.component';
import { ISchoolUserIdentifier, ISchoolUserModalShellData, TSchoolUserModalViewMode } from 'Src/ng2/shared/modals/user-management/school-user/school-user-modals.config';
import { BatchActionsService } from 'Src/ng2/school/lists/services/batch-actions/batch-actions.service';
import { UpdateSelectedStudentIds } from 'Src/ng2/store';
import { SnackBarService } from 'Src/ng2/shared/services/snackbar/snackbar.service';
import { BackgroundJob } from 'Src/ng2/shared/services/background-job/background-job.service';
import { IBatchData } from 'Src/ng2/school/student-level-dashboard/student-level-dash.component';

const accessLevelMap = {
  no_access: {
    human: 'No access',
    description: 'not be able to login.',
  },
  view_caseload: {
    human: 'Caseload viewer',
    description: 'be able to view their caseloads of students and add notes.',
  },
  edit_caseload: {
    human: 'Caseload editor',
    description: 'be able to edit their caseload of students.',
  },
  view_all: {
    human: 'School wide viewer',
    description: 'be able to view all students and add notes.',
  },
  edit_all: {
    human: 'School wide editor',
    description: 'be able to edit all students and create supports.',
  },
  delegated_school_admin: {
    human: 'Delegated admin',
    description: 'be able to edit all students, create supports, and manage users.',
  },

};

@Component({
  selector: 'user-management-settings',
  templateUrl: './user-management-settings.component.html',
  styleUrls: ['./user-management-settings.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

@unsubscribeComponent
export class UserManagementSettingsComponent implements OnInit, OnDestroy {
  public groupData$: BehaviorSubject<IGroupData> = new BehaviorSubject(null);
  public option$: BehaviorSubject<IDropdownOption> = new BehaviorSubject(null);
  public madLibSelection: string;
  public currentSchool: any;
  public currentUser: IUser;
  public schoolUsers$: Observable<IUser[]>;
  public columns: string[] = ['USER_NAME', 'SCHOOL_ACCESS_LEVEL', 'JOB_ROLE', 'ADDED_ON', 'STUDENT_CASELOAD', 'SCHOOL_MORE'];
  public batchActionsMode$= new BehaviorSubject<boolean>(false);
  public bulkUpdateUsers = [];
  public batchActionsHash: any;
  public numberOfUsersSelected: Number = this.bulkUpdateUsers.length;

  constructor (
    private spinnerService: SpinnerService,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private store: Store<any>,
    private modalsService: ModalsService,
    private router: Router,
    private route: ActivatedRoute,
    private batchActionsService: BatchActionsService,
    private snackBarService: SnackBarService,
    private backgroundJob: BackgroundJob,

  ) {
    document.title = 'Settings - User management | Portal';
  }

  ngOnInit (): void {
    const spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.madLibSelection = this.route.snapshot.queryParams.grouping
      ? this.route.snapshot.queryParams.grouping
      : 'All users';
    this.currentSchool = this.activatedRoute.snapshot.data.schoolResource;

    forkJoin([this.getMadLib(), this.getUsers(), this.getCurrentUser$()])
      .pipe(
        tap(res => {
          const [madLibs, users, currentUser] = res;
          this.currentUser = currentUser;
          this.nextOptions(madLibs);
          this.nextGroupData(users);
        }),
      )
      .subscribe({
        complete: () => spinner.close(),
      });

    this.batchActionsHash = this.batchActionsService.initializeUserBatchActionsHash();
    this.resetBatchActions();
  }

  ngOnDestroy ():void {
    document.title = 'Portal by New Visions';
  }

  private getCurrentUser$ () {
    return this.store.pipe(
      select(getCurrentUser),
      take(1),
    );
  }

  private getUsers (): Observable<any> {
    let grouping;
    if (this.madLibSelection === 'All users') {
      grouping = null;
    } else {
      grouping = this.madLibSelection;
    }
    return this.apiService.getSchoolUsers({
      schoolId: this.currentSchool._id,
      grouping,
      projection: this.columns,
    });
  }

  private getMadLib (): Observable<any> {
    return this.apiService.getSchoolUserMadLib({
      schoolId: this.currentSchool._id,
      projection: this.columns,
    });
  }

  private nextOptions (madLibs): void {
    const {
      data: {
        SchoolUserGrouping: { groupingOptions: options },
      },
    } = madLibs;
    this.option$.next(options);
  }

  private nextGroupData (users): void {
    const {
      data: {
        SchoolUserGrouping: { groupData },
      },
    } = users;
    this.groupData$.next(groupData);
  }

  public onClickedMoreButton ({ action, user } : { action: 'DETAILS' | 'REMOVE'; user: ISchoolUserIdentifier }): void {
    switch (action) {
      case 'DETAILS':
        this.sendToEditUser({ user });
        break;
      case 'REMOVE':
        this.sendToRemoveUser({ user });
        break;
      default: break;
    }
  }

  public onClickedAddUser (): void {
    this.sendToCreateUser();
  }

  public onClickedAccessLevel (accessLevel): void {
    this.sendToBulkUpdateUsers(this.bulkUpdateUsers, accessLevel);
  }

  public onClickedBatchActions (): void {
    this.batchActionsMode$.next(!this.batchActionsMode$.getValue());
    this.resetBatchActions();
  }

  private sendToBulkUpdateUsers (users, accessLevel) {
    const data: IConfirmModalComponentData = {
      title: 'Edit access level',
      subtitle: `${users.length} users selected`,
      message: `Are you sure you want to set the access level of 
                <b>${users.length}</b> users to <b>${accessLevelMap[accessLevel].human}</b>? 
                These users will ${accessLevelMap[accessLevel].description}`,
      confirmText: 'Confirm',
    };
    this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .pipe(
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            const userPayload = { users };
            const columns = this.columns;
            const schoolId = this.currentSchool._id;

            return this.apiService.bulkUpdateUserPermissions({ userPayload, columns }, accessLevel, schoolId);
          }
        }),
        switchMap((response) => BatchActionsEffectsUtilities.getJobSubjectFromGraphql(this.backgroundJob, { response, queryName: 'bulkUpdateSchoolUserPermissions' } as any)),
        // Wait until background job completes before refetching users
        filter(({ type }) => type === BACKGROUND_JOB_STATUS_TYPES.RESOLVE),
        switchMap(() => this.getUsers()),
        tap(res => {
          const toastMessage = 'The list of users was updated.';
          this.onMutateUserReqReturned(res.data.SchoolUserGrouping, toastMessage);
        }),
        catchError((error) => {
          this.showToast(false, 'Unable to update the list of users. Please try again.');
          return throwError(error);
        }),
      ).subscribe();
  }

  public onMadLibChange (e: string): void {
    const spinner = this.spinnerService.openSpinner({ message: 'Loading...' });
    this.madLibSelection = e;
    this.updateUrl();

    forkJoin([this.getUsers()])
      .pipe(
        tap(res => {
          const [users] = res;
          this.nextGroupData(users);
        }),
      )
      .subscribe({
        complete: () => spinner.close(),
      });
  }

  private sendToCreateUser (): void {
    const mode = 'CREATE';
    const data: ISchoolUserModalShellData = {
      projectionColumns: this.columns,
      currentUser: this.currentUser,
      mode,
      schoolId: this.currentSchool._id,
      schoolType: this.currentSchool.schoolType,
    };
    this.modalsService
      .openSchoolUserShellModal(data, mode)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onMutateUserReqReturned(res, 'The list of users was updated.');
        }
      });
  }

  private sendToEditUser ({ user }): void {
    const mode = 'EDIT';
    const data: ISchoolUserModalShellData = {
      projectionColumns: this.columns,
      currentUser: this.currentUser,
      user,
      mode,
      schoolId: this.currentSchool._id,
      schoolType: this.currentSchool.schoolType,
    };
    this.modalsService
      .openSchoolUserShellModal(data, mode)
      .afterClosed()
      .subscribe(res => {
        if (res) {
          this.onMutateUserReqReturned(res, 'The list of users was updated.');
        }
      });
  }

  private sendToRemoveUser ({ user }: { user: ISchoolUserIdentifier }): void {
    const data: IConfirmModalComponentData = {
      title: 'Remove User',
      message: `Are you sure you want to remove <b>${user.name}</b> from this school?`,
      confirmText: 'Remove',
    };
    this.modalsService
      .openConfirmModal(data)
      .afterClosed()
      .pipe(
        switchMap((confirmed: boolean) => {
          if (confirmed) {
            const userPayload = { userId: user.id };
            const columns = this.columns;
            const mode: TSchoolUserModalViewMode = 'REMOVE';
            const schoolId = this.currentSchool._id;

            return this.apiService.mutateSchoolUser({ userPayload, columns }, mode, schoolId);
          }
        },
        ),
        tap((res: { data: { [mutationName: string]: IRowData[][] }; errors: any[] }) => {
          if (res.errors) {
            this.showToast(false, 'Unable to update the list of users. Please try again.');
          } else {
            const { data } = res;
            if (data && Object.keys(data).length) {
              this.onMutateUserReqReturned(data.deleteSchoolUser, 'The list of users was updated.');
            }
          }
        }),
        catchError((error) => {
          this.showToast(false, 'Unable to update the list of users. Please try again.');
          return throwError(error);
        }),
      ).subscribe();
  }

  private onMutateUserReqReturned (res, toastMessage): void {
    const { groupData, groupingOptions } = res;
    this.groupData$.next(groupData);
    this.option$.next(groupingOptions); // Update dropdownoptions
    this.madLibSelection = 'All users';

    const currentBatchActionsValue = this.batchActionsMode$.getValue();
    if (currentBatchActionsValue) {
      this.batchActionsMode$.next(false);
    }
    this.resetBatchActions();
    this.updateUrl();
    this.showToast(true, toastMessage);
  }

  private updateUrl (): void {
    let grouping;

    if (this.madLibSelection === 'All users') {
      grouping = null;
    } else {
      grouping = this.madLibSelection;
    }

    const extras = {
      relativeTo: this.route,
      queryParams: { grouping },
      replaceUrl: true,
      queryParamsHandling: 'merge' as QueryParamsHandling,
    };

    this.router.navigate([], extras);
  }

  public onBatchActionUserSelected ($event: IBatchData) {
    const batchData = this.batchActionsService.formatBatchActionEvt($event);
    const forbiddenIds = this.getForbiddenIds($event);
    this.batchActionsHash.updateUserIds(batchData, { forbiddenIds });
    const usersIds = this.batchActionsHash.getUserIds();

    this.bulkUpdateUsers = usersIds.map((id, index) => {
      return id.studentId;
    });
    this.numberOfUsersSelected = this.bulkUpdateUsers.length;
    this.store.dispatch(new UpdateSelectedStudentIds(usersIds));
  }

  getForbiddenIds ($event: IBatchData): string[] {
    // exclude logged in user and school admin
    const loggedInUserId = this.currentUser._id;
    let nonEditableIds : string[] = [];
    const { data, level } = $event;
    if (level === 'SECTION') {
      nonEditableIds = (data as IRowData[][]).reduce(
        (acc, row) => {
          const userColumn = this.getColumnFromKey({ row, columnKey: 'STUB' });
          const userColumnData = userColumn && JSON.parse(userColumn.meta);
          if (userColumnData && userColumnData.nonEditable) {
            acc.push(userColumnData.data);
          }
          return acc;
        },
        [] as string[],
      );
    } else {
      const userColumn = this.getColumnFromKey({ row: data as IRowData[], columnKey: 'STUB' });
      const userColumnData = userColumn && JSON.parse(userColumn.meta);
      if (userColumnData && userColumnData.nonEditable) {
        nonEditableIds.push(userColumnData.data);
      }
    }
    return [loggedInUserId, ...nonEditableIds];
  }

  resetBatchActions (): void {
    this.batchActionsHash.reset();
    this.bulkUpdateUsers = [];
    this.numberOfUsersSelected = this.bulkUpdateUsers.length;
    this.store.dispatch(new UpdateSelectedStudentIds([]));
  }

  private showToast (updated: boolean, toastMessage: string): void {
    if (updated) {
      this.snackBarService.showToastLowerLeftCorner({ toastText: toastMessage });
    } else {
      this.snackBarService.showDangerToastWithCloseButton({ toastText: toastMessage, isDanger: true });
    }
  }

  private getColumnFromKey ({ row, columnKey }: { row: IRowData[]; columnKey: string }): IRowData {
    return row.find(v => v.columnKey === columnKey);
  }
}
