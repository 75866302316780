import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NvMatTooltipModule } from '../../components/nv-mat-tooltip/nv-mat-tooltip.module';
import { BaseModalModule } from '../base-modal.module';
import { ActivitiesModalComponent } from './activities-modal.component';
import { NvInputsModule } from 'Src/nvps-libraries/design/nv-inputs.module';
import { NvIconModule } from 'Src/nvps-libraries/design/nv-icon/nv-icon.module';
import { NvButtonModule } from 'Src/nvps-libraries/design/nv-button/nv-button.module';
import { NvNoteModule } from 'Src/nvps-libraries/design/nv-note/nv-note.module';

@NgModule({
  declarations: [ActivitiesModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    BaseModalModule,
    NvInputsModule,
    NvButtonModule,
    NvIconModule,
    NvNoteModule,
    NvMatTooltipModule,
    MatCheckboxModule,
  ],
  exports: [ActivitiesModalComponent],
  providers: [],
})
export class ActivitiesModalModule {}
