import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
@Component({
  selector: 'user-management-right-nav',
  templateUrl: './user-management-right-nav.component.html',
  styleUrls: ['./user-management-right-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class UserManagementRightNavComponent implements OnInit {
  @Output() clickedBatchActions = new EventEmitter();
  @Output() clickedAddUser = new EventEmitter();
  @Output() clickedAccessLevel = new EventEmitter();
  @Input() batchActionsMode$: Observable<boolean>;
  @Input() numberOfUsersSelected: Number;
  isDisabled: boolean = true;

  toolsTitle = 'Tools';
  toolsSidebarItems: ISidebarItem[] = [
    {
      human: 'Batch actions',
      key: 'batchActions',
      icon: 'select',
    },
    {
      human: 'Add user',
      key: 'addUser',
      icon: 'person-add',
    },
  ];

  batchActionsTitle = 'Batch Actions';
  batchActionsSubTitle: String;
  batchActionsSidebarItems: ISidebarItem[];

  ngOnInit () {
    this.batchActionsSubTitle = `${this.numberOfUsersSelected} users selected`;
    this.batchActionsSidebarItems = [
      {
        human: 'Access level',
        key: 'access_level',
        disabled: this.isDisabled,
        expandAs: 'dropdown',
        children: [
          {
            human: 'Delegated admin',
            key: 'delegated_school_admin',
          },
          {
            human: 'School wide editor',
            key: 'edit_all',
          },
          {
            human: 'School wide viewer',
            key: 'view_all',
          },
          {
            human: 'Caseload editor',
            key: 'edit_caseload',
          },
          {
            human: 'Caseload viewer',
            key: 'view_caseload',
          },
          {
            human: 'No access',
            key: 'no_access',
          },
        ],
      },
    ];
  }

  ngOnChanges (changes: SimpleChanges) {
    this.batchActionsSubTitle = `${changes.numberOfUsersSelected.currentValue} users selected`;
    this.isDisabled = !(changes.numberOfUsersSelected.currentValue > 0);
    this.batchActionsSidebarItems = [
      {
        human: 'Access level',
        key: 'access_level',
        disabled: this.isDisabled,
        expandAs: 'dropdown',
        children: [
          {
            human: 'Delegated admin',
            key: 'delegated_school_admin',
          },
          {
            human: 'School wide editor',
            key: 'edit_all',
          },
          {
            human: 'School wide viewer',
            key: 'view_all',
          },
          {
            human: 'Caseload editor',
            key: 'edit_caseload',
          },
          {
            human: 'Caseload viewer',
            key: 'view_caseload',
          },
          {
            human: 'No access',
            key: 'no_access',
          },
        ],
      },
    ];
  }

  onSelectItem ($selectedKey: string): void {
    switch ($selectedKey) {
      case 'batchActions':
        this.clickedBatchActions.emit();
        break;
      case 'addUser':
        this.clickedAddUser.emit();
        break;
      case 'delegated_school_admin':
        this.clickedAccessLevel.emit($selectedKey);
        break;
      case 'edit_all':
        this.clickedAccessLevel.emit($selectedKey);
        break;
      case 'view_all':
        this.clickedAccessLevel.emit($selectedKey);
        break;
      case 'edit_caseload':
        this.clickedAccessLevel.emit($selectedKey);
        break;
      case 'view_caseload':
        this.clickedAccessLevel.emit($selectedKey);
        break;
      case 'no_access':
        this.clickedAccessLevel.emit($selectedKey);
        break;
      default:
        break;
    }
  }

  onCancel (): void {
    this.clickedBatchActions.emit();
  }
}
