import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NvIconButtonModule } from '../nv-icon-button/nv-icon-button.module';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvNumboxComponent } from './nv-numbox.component';

@NgModule({
  imports: [CommonModule, NvIconModule, NvIconButtonModule, MatInputModule, FormsModule, ReactiveFormsModule],
  declarations: [NvNumboxComponent],
  exports: [NvNumboxComponent],
})
export class NvNumboxModule {}
