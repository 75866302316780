import { Injectable } from '@angular/core';
import { reduce, find, groupBy, uniq, sortBy } from 'lodash';

import { IGroupData, IRowData } from 'Src/ng2/shared/models/list-models';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { ImSchool } from 'Src/ng2/shared/services/im-models/im-school';
import { SubjectAreas } from 'Src/ng2/shared/constants/subject-areas.constant';
import { ICourse, ISchool } from 'Src/ng2/shared/typings/interfaces/school.interface';
import { CurrentSchoolYear } from 'Src/ng2/shared/constants/current-school-year.constant';

const REGENTS_SUBJECT_MAP: {[key: string]: string } = {
  ela: 'ELA',
  alg: 'Alg',
  geom: 'Geom',
  trig: 'Trig',
  livingEnv: 'Liv Env',
  earth: 'Earth',
  chem: 'Chem',
  physics: 'Physics',
  global: 'Global',
  us: 'US',
  lote: 'LOTE',
};

@Injectable()
export class MasterProgramSettingsDataService {
  private masterProgram: any[];
  private currentTermYear: number;
  private allPreps = {};
  private rowData: IRowData[][];
  private currentSchool: ISchool;
  private currentTermNumber: number;

  constructor (private ImSchool: ImSchool) {}

  public getMasterProgramGroupData (school, grouping) {
    this.currentSchool = school;
    this.currentTermNumber = this.ImSchool.getCurrentTermNumber(this.currentSchool);
    this.currentTermYear = this.ImSchool.getCurrentTermYear(school);
    const masterProgramForCurrentTermYear = this.ImSchool.getMasterScheduleForTermYear(
      school,
      this.currentTermYear,
    );

    this.masterProgram = [...masterProgramForCurrentTermYear];

    const subjectAreas = this._getSubjectAreas(this.masterProgram);
    const userPrepCourses = this._getUserEnteredPrepCoursesFromSchool(school);
    const nvPrepCourses = this._getCoursePrepFromMasterSchedule(this.masterProgram);

    // Merge nvPreps and userCreatedPreps
    for (const prep in nvPrepCourses) {
      this.allPreps[prep] = { ...nvPrepCourses[prep], ...userPrepCourses[prep] };
    }

    // Set rowData
    this.rowData = masterProgramForCurrentTermYear.map((course:ICourse) => {
      const { courseCode, section, subjectArea, courseName, teachers, enrollment, capacity, isRegentsCulminating, courseId } = course;
      const schoolId = this.currentSchool._id;
      const courseNameFormatted = this.toTitleCase(courseName);
      const teacherName = teachers[0]?.nickname ? this.toTitleCase(teachers[0]?.nickname) : null;

      return [
        {
          columnKey: 'COURSE_CODE',
          data: `${courseCode}-${section}`,
          meta: JSON.stringify({
            data: courseId,
            schoolId: this.currentSchool._id,
            tooltipFilter: {
              schools: {
                masterSchedule: { $elemMatch: { courseId: courseId } },
              },
            },
          }),
        },
        { columnKey: 'SUBJECT', data: subjectAreas[subjectArea], meta: null },
        { columnKey: 'COURSE_NAME', data: courseNameFormatted, meta: null },
        { columnKey: 'TEACHER', data: teacherName, meta: null },
        { columnKey: 'CAPACITY', data: `${enrollment} of ${capacity}`, meta: null },
        {
          columnKey: 'REGENTS_PREP',
          data: this._getRegentsHumanName(this.allPreps[courseId]),
          meta: JSON.stringify({
            data: schoolId,
            tooltipFilter: {
              schools: {
                masterSchedule: { $elemMatch: { courseId: courseId } },
              },
            },
          }),
        },
        { columnKey: 'REGENTS_CULM', data: isRegentsCulminating ? 'Yes' : 'No', meta: null },
      ];
    });

    // if grouping is all_courses or 'all_teachers' or 'all_preps_subjects , don't filter
    let groupData: IGroupData;
    const noFilter = (grouping === 'all_courses');

    if (noFilter) {
      groupData = {
        human: 'Courses',
        rowData: this.rowData,
      };
      return groupData;
    }

    // if grouping is: preps
    const filterByPreps = (grouping?.split('_')[0] === 'prep');
    if (filterByPreps) {
      const dataFilteredByPreps = this._filterRowDataByPreps(grouping);
      groupData = {
        human: 'Courses',
        rowData: dataFilteredByPreps,
      };
      return groupData;
    }
    // if grouping is based in: subject, teacher, or regents culmin
    const dataFiltered = this._filterRowData(grouping);
    groupData = {
      human: 'Courses',
      rowData: dataFiltered,
    };

    return groupData;
  }

  private _getUserEnteredPrepCoursesFromSchool (school):{[key: string]: {}} {
    const userEnteredPrepCoursesFinalObj = {};
    const userEnteredPrepCourses = school.userEnteredPrepCourses; // array of objects
    const organizedByIdUserEnteredPrepCourses = groupBy(userEnteredPrepCourses, 'courseId'); // group by same course

    // Create object with courseId as the key and value is object with all its corresponding preps
    // ex: 13W320-207-EEF41QNX-1: {prep0: "ela", prep1: "lote"}
    for (const course in organizedByIdUserEnteredPrepCourses) {
      const courseData = organizedByIdUserEnteredPrepCourses[course];

      const mapped = courseData.map((c, index) => ({ [`prep${index}`]: c.coursePrepsFor }));
      userEnteredPrepCoursesFinalObj[course] = Object.assign({}, ...mapped);
    }

    return userEnteredPrepCoursesFinalObj;
  }

  private _getCoursePrepFromMasterSchedule (masterSchedule): {[key: string]: {}} {
    const nvPreps = masterSchedule.reduce((acc, course) => {
      const { courseId } = course;
      const courseIdExistsInAcc = acc[courseId];
      if (!courseIdExistsInAcc) acc[courseId] = { nvPreps: course.coursePrepsFor };
      return acc;
    }, {});

    return nvPreps;
  }

  private _getSubjectAreas (masterProgram): {[key: string]: string} {
    const subjectAreas = reduce(
      masterProgram,
      (result, course: any) => {
        const subjectArea = course.subjectArea;
        if (!result[subjectArea]) {
          const subjectAreaObj: any = find(SubjectAreas, { camelCase: subjectArea });
          result[subjectArea] = subjectAreaObj ? subjectAreaObj.human : subjectArea;
        }
        return result;
      },
      {},
    );
    return subjectAreas;
  }

  private _getRegentsHumanName (allPrepsForCourse): string[] {
    const arrOfPreps = Object.values(allPrepsForCourse);
    const formattedArrOfPreps = arrOfPreps.map((prep:string) => {
      return REGENTS_SUBJECT_MAP[prep];
    });
    // Remove undefined values
    const finalPreps = formattedArrOfPreps.filter(course => course != null);
    return (finalPreps.length > 0) ? finalPreps : null;
  };

  // Generate dropdown options
  public getDropdownOptions (): IDropdownOption[] {
    const subjectAreas = this._getSubjectAreas(this.masterProgram);
    const availableSubjects = [];
    for (const subject in subjectAreas) {
      availableSubjects.push({ human: subjectAreas[subject], key: subjectAreas[subject] });
    }
    const sortedSubjects = sortBy(availableSubjects, ['human', 'key']);

    const allTeachers = this._getAvailableTeachers();
    const availableTeachers = [];
    for (const teacher of allTeachers) {
      availableTeachers.push({ human: teacher, key: teacher });
    }

    const allPreps = this._getPrepsForDropdown();
    const availablePreps = [];
    for (const prep of allPreps) {
      availablePreps.push({ human: REGENTS_SUBJECT_MAP[prep], key: `prep_${REGENTS_SUBJECT_MAP[prep]}` });
    }

    return [
      {
        human: 'All courses',
        key: 'all_courses',
      },
      {
        human: 'Subjects',
        key: 'subjects',
        options: sortedSubjects,
      },
      {
        human: 'Teachers',
        key: 'teachers',
        options: availableTeachers,
      },
      {
        human: 'Regents culmin',
        key: 'regents_culmin',
        options: [
          { human: 'Regents culmin', key: 'Yes' },
          { human: 'Not culmin', key: 'No' },
        ],
      },
      {
        human: 'Preps for',
        key: 'preps_for',
        options: availablePreps,
      },
    ];
  }

  private _getAvailableTeachers (): string[] {
    const result = this.masterProgram.map(course => {
      const teachersArr = course.teachers;
      const result = this._getTeachers(teachersArr);
      return result;
    });

    result.sort();

    const noTeacherOption = result.find(e => e === 'No teachers');

    if (noTeacherOption) {
      result.splice(0, 0, noTeacherOption); // Insert 'No teachers' option, it will become second option in dropdown
    }

    return uniq(result);
  }

  private _getTeachers (teachersArray: any[]): string {
    // If teachers for a course is empty
    if (!teachersArray.length) {
      return 'No teachers';
    }

    const nameArray = teachersArray.map((teacherObj) => {
      if (!teacherObj) {
        return 'No teachers'; // If teacher data is empty
      } else {
        return this.toTitleCase(teacherObj.nickname);
      }
    });
    return nameArray.join(', ');
  }

  // convert a string to Title Case. ex: TEST => Test
  public toTitleCase (str) {
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      },
    );
  }

  private _getPrepsForDropdown (): string[] {
    const allPrepsInCourses = this.allPreps;
    const result = [];
    for (const course in allPrepsInCourses) {
      const prepsNamesOnly = Object.values(allPrepsInCourses[course]); // array of prep names, ex: ['ela', 'trig', 'earth']
      result.push(...prepsNamesOnly);
    }
    return uniq(result).filter(el => el != null); // return unique values and remove null values
  }

  // Helper methods to filter rowData
  private _filterRowData (grouping):IRowData[][] {
    const filteredData = this.rowData.filter(row => {
      if (grouping === 'No teachers') { // Group rows with no data
        return row.some(e => {
          // Group rows with courses that have no teachers assign
          const noTeachersAssignCondition = (e.columnKey === 'TEACHER') && ((e.data === undefined) || (e.data === null));
          return noTeachersAssignCondition;
        });
      } else return row.some(e => e.data === grouping);
    });

    return filteredData;
  }

  private _filterRowDataByPreps (grouping):IRowData[][] {
    const prepFilter = grouping.split('_')[1]; // ex: prep_ela => ela

    const filteredData = this.rowData.filter(row => {
      return row.some(column => {
        let arrayWithVals: string[];
        let isValid: boolean;
        if (Array.isArray(column.data)) {
          arrayWithVals = column.data;
          const valid = arrayWithVals.some(prep => prep === prepFilter);
          isValid = valid;
        } else {
          isValid = false;
        }
        return isValid;
      });
    });
    return filteredData;
  }

  // Get Term Meta Data
  public getTermMetaData (): string {
    const termNumberToDisplay = this._getTermNumberToDisplay();
    const currentSchoolYear = `${CurrentSchoolYear.START}-${CurrentSchoolYear.END}`;
    const humanTermYear = `Term ${termNumberToDisplay} SY${currentSchoolYear}`;
    return humanTermYear;
  }

  private _getTermNumberToDisplay (): number {
    const condition1 = this.currentSchool.termStructure === 'ANNUALIZED';
    const condition2 = this.currentSchool.currentTermYear === 182;
    return condition1 && condition2 ? 1 : this.currentTermNumber;
  }

  // Columns for Infinite Table
  public getColumns () {
    return [
      {
        columnName: 'COURSE_CODE',
        columnOrder: 0,
        graphQlKey: 'COURSE_CODE',
        cellTooltip: 'COURSE_PERIOD_CELL_TOOLTIP',
      },
      {
        columnName: 'subject',
        columnOrder: 1,
        graphQlKey: 'SUBJECT',
      },
      {
        columnName: 'course name',
        columnOrder: 2,
        graphQlKey: 'COURSE_NAME',
      },
      {
        columnName: 'teacher',
        columnOrder: 3,
        graphQlKey: 'TEACHER',
      },
      {
        columnName: 'capacity',
        columnOrder: 4,
        graphQlKey: 'CAPACITY',
      },
      {
        columnName: 'regents prep',
        columnOrder: 5,
        graphQlKey: 'REGENTS_PREP',
        columnDataFormat: 'REGENTS_PREP',
        headerTooltip: 'List of exams that this course preps for. Preps can be assigned by New Visions and/or be assigned in the Portal.',
        cellTooltip: 'REGENTS_PREP_CELL_TOOLTIP',
      },
      {
        columnName: 'regents culm.',
        columnOrder: 6,
        graphQlKey: 'REGENTS_CULM',
      },
    ];
  }
}
