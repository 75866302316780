/* tslint:disable */

import { Action } from '@ngrx/store';

export const LOAD_ALL_MILESTONES = '[Portal] Load All Milestones';
export const LOAD_ALL_MILESTONES_FAIL = '[Portal] Load All Milestones Fail';
export const LOAD_ALL_MILESTONES_SUCCESS = '[Portal] Load All Milestones Success';
export const LOAD_STUDENT_MILESTONES = '[Portal] Load Student Milestones';
export const LOAD_STUDENT_MILESTONES_FAIL = '[Portal] Load Student Milestones Fail';
export const LOAD_STUDENT_MILESTONES_SUCCESS = '[Portal] Load Student Milestones Success';
export const UPDATE_MILESTONE = '[Portal] Update Milestone';
export const UPDATE_MILESTONE_SUCCESS = '[Portal] Update Milestone Success';
export const UPDATE_MILESTONE_FAIL = '[Portal] Update Milestone Fail';
export const BULK_UPDATE_MILESTONES = '[Portal] Bulk Update Milestones';
export const BULK_UPDATE_MILESTONES_SUCCESS = '[Portal] Bulk Update Milestones Success';
export const BULK_UPDATE_MILESTONES_FAIL = '[Portal] Bulk Update Milestones Fail';

export class LoadAllMilestones implements Action {
  readonly type = LOAD_ALL_MILESTONES;
  constructor(public payload: any) {}
}

export class LoadAllMilestonesFail implements Action {
  readonly type = LOAD_ALL_MILESTONES_FAIL;
  constructor(public payload: any) {}
}

export class LoadAllMilestonesSuccess implements Action {
  readonly type = LOAD_ALL_MILESTONES_SUCCESS;
  constructor(public payload: any) {}
}

export class LoadStudentMilestones implements Action {
  readonly type = LOAD_STUDENT_MILESTONES;
  constructor(public payload: object) {}
}

export class LoadStudentMilestonesFail implements Action {
  readonly type = LOAD_STUDENT_MILESTONES_FAIL;
  constructor(public payload: any) {}
}

export class LoadStudentMilestonesSuccess implements Action {
  readonly type = LOAD_STUDENT_MILESTONES_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateMilestone implements Action {
  readonly type = UPDATE_MILESTONE;
  constructor(public payload) {}
}

export class UpdateMilestoneSuccess implements Action {
  readonly type = UPDATE_MILESTONE_SUCCESS;
  constructor(public payload) {}
}

export class UpdateMilestoneFail implements Action {
  readonly type = UPDATE_MILESTONE_FAIL;
  constructor(public payload) {}
}

export class BulkUpdateMilestones implements Action {
  readonly type = BULK_UPDATE_MILESTONES;
  constructor(public payload) {}
}

export class BulkUpdateMilestonesSuccess implements Action {
  readonly type = BULK_UPDATE_MILESTONES_SUCCESS;
  constructor(public payload) {}
}

export class BulkUpdateMilestonesFail implements Action {
  readonly type = BULK_UPDATE_MILESTONES_FAIL;
  constructor(public payload) {}
}

// action types
export type MilestonesAction =
  | LoadAllMilestones
  | LoadAllMilestonesFail
  | LoadAllMilestonesSuccess
  | LoadStudentMilestones
  | LoadStudentMilestonesSuccess
  | LoadStudentMilestonesFail
  | UpdateMilestoneSuccess
  | UpdateMilestoneFail
  | BulkUpdateMilestones
  | BulkUpdateMilestonesSuccess
  | BulkUpdateMilestonesFail;
