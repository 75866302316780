import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SettingsModule } from '../settings.module';
import { DistanceToGraduationSettingsComponent } from './distance-to-graduation-settings.component';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { SchoolTypeGuard } from 'Src/ng2/routing/guards/school-type-guard/school-type-guard';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from 'Src/ng2/routing/guards/user-role-permissions-for-route-guards.constant';
import { SettingsRoute } from 'Src/ng2/shared/constants/settings-routes.constant';
import { IRouteConfigsOpts } from 'Src/ng2/routing/route.config';

export const routes: IRouteConfigsOpts[] = [
  {
    path: '',
    component: DistanceToGraduationSettingsComponent,
    canActivate: [RouteGuard, SchoolTypeGuard],
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/distance-to-grad'],
    partnerType: 'school',
    authenticationRequired: true,
    data: {
      schoolTypes: ['transfer'],
      activeRoute: SettingsRoute.DistanceToGraduation,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), SettingsModule],
  exports: [RouterModule],
})
export class DistanceToGraduationSettingsRoutingModule {}
