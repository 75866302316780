import { ActivatedRoute } from '@angular/router';
import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'network-standard-layout',
  templateUrl: './network-standard-layout.component.html',
  styleUrls: ['./network-standard-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NetworkStandardLayoutComponent {
  public isInMaintenanceMode: boolean;

  constructor (private route: ActivatedRoute) { }

  ngOnInit () {
    this.isInMaintenanceMode = this.route.snapshot.data.maintenanceMode;
  }
}
