import { Injectable } from '@angular/core';

@Injectable()
export class GraphQLNvConfigsHelperService {
  getNvConfigsQuery ({ schoolId, domainKey, tabKey }): string {
    const fields = this.constructSetupTabQueryFields(domainKey, tabKey);
    return `{
      NvConfigs(
        schoolId: "${schoolId}",
        domainKey: "${domainKey}",
        tabKey: "${tabKey}"
      ) {
        ${fields}
        }
    }`;
  }

  constructSetupTabQueryFields (domainKey: string, tabKey: string): string {
    return `
      setup {
        goals {
          isActive
          allowUserEdits
          metaData {
            title
            content {
              contentText
              iconBackground
              iconName
            }
          }
        }
        systemOverview {
          isActive
          allowUserEdits
          metaData {
            title
            content {
              tabLabel
              data {
                label
                text
              }
            }
          }
        }
        schoolConfiguration {
          isActive
          allowUserEdits
          metaData {
            title
          }
        }
      }
    `;
  }
};
