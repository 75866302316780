import { ActivatedRoute } from '@angular/router';
import { TSortDirections, Direction } from 'Src/ng2/shared/services/list-services/sort-and-filter.service';
import { IListData } from './../../../shell/content-tools/content-tools.component';
import {
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Unsubscribable, of } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { FixedToInfiniteViewComponent } from 'Src/ng2/shared/components/list/fixed-to-infinite-view-container/fixed-to-infinite-view.component';
import { IRowData, IGroupData } from 'Src/ng2/shared/models/list-models';
import { ObjectCache } from 'Src/ng2/shared/services/object-cache/object-cache.service';
import { IListConfig } from '../../../shared/models/list-models';
import {
  BackgroundJobNotificationService,
  TValidBackgroundJob,
} from '../../../shared/services/background-job-notification-service/background-job-notification-service';
import { UpdateSelectedStudentIds } from '../../../store/actions/batch-actions-actions';
import { IFocus } from '../academic-list-v2/academic-list-data/academic-data.service';
import { BatchActionsService } from '../services/batch-actions/batch-actions.service';
import { MadlibService } from '../services/madlib/madlib.service';
import { ATTENDANCE_LOGS_KEBAB_OPTIONS } from './support-attendance-logs-kebab/support-attendance-logs-kebab.component';
import { SupportAttendanceLogsDataService } from './support-attendance-logs-list-v2-data/support-attendance-logs-list-v2-data.service';

@Component({
  selector: 'support-attendance-logs-list-v2',
  templateUrl: './support-attendance-logs-list-v2.component.html',
  styleUrls: ['./support-attendance-logs-list-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
// @ts-ignore
export class SupportAttendanceLogsListV2Component extends FixedToInfiniteViewComponent implements OnInit, OnDestroy {
  public fociData$: Observable<any>;
  public batchActionsHash: any;
  public madlibModel: any;
  public dataSource$: Observable<any>;
  public foci: IFocus;
  public fociByCategory: Array<{ categoryLabel: string; foci: IFocus[] }>;
  public activeList: 'INFINITE' | 'FIXED';

  // The background jobs does this view need to refetch on?
  public backgroundJobDependencies: TValidBackgroundJob[] = [
    'BulkAttendanceRecordsCreate',
    'BulkAttendanceRecordsUpdate',
    'BulkStudentSupportUpdate',
    'SupportMiniSchemaUpdate',
  ];

  public listData: IListData;
  public pageHeaderMeta: any = {
    title: '',
    subTitle: null,
    icon: {},
  };

  // SuperProps
  public onFixed: Function;
  public onInfinite: Function = (instance: string) => instance; // need to define default function
  public onRowClick: Function;
  public onBatchAction: Function;
  public onDynamicComponentClick: Function;
  public columns;
  public dynamicColumns = new BehaviorSubject(null);
  public dynamicColumnIndexMap = new BehaviorSubject(null);
  public columnIndexMap: { [columnKey: string]: number };
  public groupingData$ = new BehaviorSubject(null);

  public filterFormControl: FormControl;
  public sortKey$: BehaviorSubject<string>;
  public sortDirection$: BehaviorSubject<TSortDirections>;
  public updateSort: Function = () => null;

  public listConfig: IListConfig = {
    listType: 'STUDENT',
    noDataMessage: 'No logs to display',
    sortableColumns: false,
    allowEmptyTable: false,
  };

  public dynamicComponentInputData: any;
  public uiRowData: any;
  public noDataMessage: any;
  public onUiRowDataUpdate: Function;

  // SubSinks
  public notificationServiceSub: Unsubscribable;
  public getFociDataSub: Unsubscribable;

  // NOTE: This component is required to implement every abstract member defined on super.
  // but for support settings list, we don't do anything with batchActions related code.
  // until a more ideal solution is determined, need to keep these two defined

  // Bindings
  @Input() schoolId: string;
  @Input() batchActionsMode$: Observable<any>;
  @Input() supportId: string;

  // Refs
  @ViewChild('entry', { read: ViewContainerRef, static: true }) entry: ViewContainerRef;
  @ViewChild('listContent', { static: true }) listContent: ElementRef;

  @Output() openTakeAttendanceList = new EventEmitter();

  // DI
  constructor (
    public resolver: ComponentFactoryResolver,
    public madlibService: MadlibService,
    public objectCache: ObjectCache,
    public notificationService: BackgroundJobNotificationService,
    public supportAttendanceLogsDataService: SupportAttendanceLogsDataService,
    private store: Store<any>,
    private batchActionsService: BatchActionsService,
    private route: ActivatedRoute,
  ) {
    // We are extending the fixed-to-infinite functionality
    super(resolver, batchActionsService, store);
  }

  ngOnInit (): void {
    // Clear the cache on initialization. User *may* have changed schools.
    this.supportAttendanceLogsDataService.clearFociCache();
    // Set the header of the list
    this.pageHeaderMeta.title = 'All logs';
    // Set initial state
    this.filterFormControl = new FormControl();
    this.sortKey$ = new BehaviorSubject('STUB');
    this.sortDirection$ = new BehaviorSubject<TSortDirections>(Direction.desc);
    // Overwrite super methods -- how we respond to user events [batchActions & rowClick]
    this.onBatchAction = this.formatAndFireBatchActionEvt;
    this.onRowClick = this.takeAttendance;
    this.onDynamicComponentClick = this.onActionSelect;
    this.onUiRowDataUpdate = ($event: IGroupData) => {
      const groupings = Object.values($event);
      this.uiRowData = groupings;
    };
    // Listen for completed background jobs and refetch data as needed
    this.notificationServiceSub = this.notificationService
      .getMessage()
      .pipe(
        filter(({ backgroundJob }) => this.backgroundJobDependencies.includes(backgroundJob)),
        tap(() => this.initializeAttendanceLogsList(this.schoolId, this.route.snapshot.queryParams, false)),
      )
      .subscribe();

    // Kick off data pipeline `getFoci$---generateMadlibModel---getGrouping$---createListContainer--|`
    this.initializeAttendanceLogsList(this.schoolId, this.route.snapshot.queryParams, true);
  }

  ngOnDestroy () {
    if (this.notificationServiceSub) this.notificationServiceSub.unsubscribe();
    if (this.getFociDataSub) this.getFociDataSub.unsubscribe();
  }

  // getFocusData---createMadlibModel---getGroupingData---createListContainer--|
  initializeAttendanceLogsList (schoolId: string, params, loadContainer: boolean): void {
    this.getFociDataSub = this.getFociData$(schoolId)
      .pipe(
        // Format and set the partials to fit the nv-dropdown
        tap(fociPartials => (this.fociByCategory = this.madlibService.getFormattedFoci(fociPartials))),
        // Once we know the default focus, fetch complete data to populate corresponding filter and grouping options
        switchMap(() => this.getFociData$(schoolId)),
        switchMap(([completeFocusData]) =>
          of(
            this.madlibService.setMadlibModel(
              this.madlibService.formatFocus(completeFocusData),
              completeFocusData.filters,
            ),
          ),
        ),
        // Set madlib model with focus containing all data
        tap(madlibModel => (this.madlibModel = madlibModel)),
        switchMap(madlibModel => this.getSupportGroupingData$(madlibModel)),
        // Set side-bar summary/csv-export etc.
        tap(groupingData => {
          this.updateGroupingDependentProps(groupingData);
          const $event = {
            groupData: this.groupingData$.value[0],
            sortKey: null,
            sortDirection: null,
          };
          if (loadContainer) this.createInfiniteStudentTableComponent($event);
        }),
      )
      .subscribe();
  }

  getSupportGroupingData$ (madlibModel) {
    const groupingPayload: any = this.madlibService.getGroupingDataPayload(this.schoolId, this.madlibModel.value);
    groupingPayload.baseCollectionName = 'supports';
    groupingPayload.id = `${this.supportId}`;

    return this.supportAttendanceLogsDataService.getSupportGroupingData$(groupingPayload).pipe(
      switchMap(({ data: { SupportGroupings } }) => {
        this.updateGroupingDependentProps(SupportGroupings);
        return of(SupportGroupings);
      }),
    );
  }

  getFociData$ (schoolId: string): Observable<any> {
    return this.supportAttendanceLogsDataService.getSupportFocusData$(schoolId, 'SUPPORT_ATTENDANCE_LOGS').pipe(
      switchMap(({ data: { SupportFocus } }) => {
        return of(SupportFocus);
      }),
      take(1),
    );
  }

  formatAndFireBatchActionEvt ($event: { updateAll: boolean; data: IRowData[]; level: 'SECTION' | 'ROW' }): void {
    const batchData = this.batchActionsService.formatBatchActionEvt($event);
    this.batchActionsHash.updateStudentIds(batchData);
    const studentIds = this.batchActionsHash.getStudentIds();
    this.store.dispatch(new UpdateSelectedStudentIds(studentIds));
  }

  updateGroupingDependentProps (groupings): void {
    // Update columns and mapping
    const { columns, columnIndexMap } = this.madlibService.getColumnDataBasedOnMadlibModel(this.madlibModel);
    this.dynamicColumns.next(columns);
    this.dynamicColumnIndexMap.next(columnIndexMap);
    // Update all fields dependent on the new grouping data
    this.groupingData$.next(groupings);
  }

  takeAttendance (sessionRowData) {
    const { expectedStudents } = JSON.parse(sessionRowData[0].meta);
    const [sessionCol] = sessionRowData;
    const meta = JSON.parse(sessionCol.meta);
    const data = {
      ...meta,
      schoolId: this.schoolId,
      supportId: this.supportId,
    };
    const countStudents = expectedStudents ? expectedStudents.length : 0;
    if (countStudents > 0) this.openTakeAttendanceList.emit(data);
  }

  onActionSelect ({ action, meta }): void {
    const { MARK_ALL_PRESENT, SESSION_NOT_HELD, TAKE_ATTENANCE, REVERT_TO_HELD } = ATTENDANCE_LOGS_KEBAB_OPTIONS;
    const data = {
      ...meta,
      schoolId: this.schoolId,
      supportId: this.supportId,
    };
    switch (action) {
      case MARK_ALL_PRESENT.action: {
        data.status = 'PRESENT';
        this.supportAttendanceLogsDataService.markAttendanceRecordStatus(data);
        break;
      }
      case SESSION_NOT_HELD.action: {
        data.status = 'EXCUSED';
        this.supportAttendanceLogsDataService.markAttendanceRecordStatus(data);
        break;
      }
      case TAKE_ATTENANCE.action: {
        this.openTakeAttendanceList.emit(data);
        break;
      }
      case REVERT_TO_HELD.action: {
        data.status = 'DELETED';
        this.supportAttendanceLogsDataService.markAttendanceRecordStatus(data);
        break;
      }
    }
  }
}
