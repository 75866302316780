import { MatCheckboxModule } from '@angular/material/checkbox';
import { CommonModule } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';

import { NvButtonModule } from './../../../../nvps-libraries/design/nv-button/nv-button.module';
import { NvDesignLibraryModule } from 'Src/nvps-libraries/design/nv-design-library.module';
import { SupportSettingsListModule } from './../../../school/lists/supports/support-settings-list/support-settings-list.module';
import { ExitViewModule } from '../exit-view/exit-view.component.module';
import { StoreDataService } from 'Src/ng2/shared/services/store-data-service/store-data.service';
import { SettingsPageData } from './settings-data-service/settings-data.service';
import { AssessmentsSettingsComponent } from './assessments-settings/assessments-settings.component';
import { MasterProgramSettingsComponent } from './master-program-settings/master-program-settings.component';
import { UserManagementSettingsComponent } from './user-management-settings/user-management-settings.component';
import { SupportsSettingsComponent } from './supports-settings/supports-settings.component';
import { ActivitiesSettingsComponent } from './activities-settings/activities-settings.component';
import { NvMatTooltipModule } from '../nv-mat-tooltip/nv-mat-tooltip.module';
import { NvTooltipModule } from './../../nv-tooltip/nv-tooltip.module';
import { ListDisplayActivityModule } from 'Src/ng2/school/lists/list-display/list-display-activity/list-display-activity.module';
import { PageHeaderModule } from './../header/page-header/page-header.module';
import { InfiniteTableModule } from './../list/infinite-table/infinite-table.module';
import { UserManagementContentComponent } from './user-management-settings/user-management-content/user-management-content.component';
import { UserManagementRightNavComponent } from './user-management-settings/user-management-right-nav/user-management-right-nav.component';
import { UserManagementMoreButtonComponent } from './user-management-settings/user-management-more-button/user-management-more-button.component';
import { UserManagementMadLibComponent } from './user-management-settings/user-management-mad-lib/user-management-mad-lib.component';
import { MasterProgramSettingsContentComponent } from './master-program-settings/master-program-settings-content/master-program-settings-content.component';
import { MasterProgramSettingsMadLibComponent } from './master-program-settings/master-program-settings-mad-lib/master-program-settings-mad-lib.component';
import { MasterProgramSettingsRightNavComponent } from './master-program-settings/master-program-settings-right-nav/master-program-settings-right-nav.component';
import { MasterProgramSettingsPrepsPill } from './master-program-settings/master-program-settings-preps-pill/master-program-settings-preps-pill.component';
@NgModule({
  imports: [
    CommonModule,
    ExitViewModule,
    NvDesignLibraryModule,
    MatTabsModule,
    SupportSettingsListModule,
    PageHeaderModule,
    // assesements imports
    MatMenuModule,
    NvMatTooltipModule,
    NvTooltipModule,
    // activity modules
    ListDisplayActivityModule,
    InfiniteTableModule,
    NvButtonModule,
    MatCheckboxModule,
  ],
  declarations: [
    AssessmentsSettingsComponent,
    // CreditsSettingsComponent,
    ActivitiesSettingsComponent,
    MasterProgramSettingsComponent,
    SupportsSettingsComponent,
    UserManagementSettingsComponent,
    UserManagementContentComponent,
    UserManagementRightNavComponent,
    UserManagementMoreButtonComponent,
    UserManagementMadLibComponent,
    MasterProgramSettingsContentComponent,
    MasterProgramSettingsMadLibComponent,
    MasterProgramSettingsRightNavComponent,
    MasterProgramSettingsPrepsPill,
  ],
  providers: [
    SettingsPageData,
    StoreDataService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModule {}
