import { Injectable } from '@angular/core';
import { IListColumnData } from '../../../shared/typings/interfaces/list-view.interface';
import { ListCellFormattingService } from '../list-display/list-cell-formatting.service';
import { COLUMN_DATA_TYPE } from './../../../shared/constants/list-view/cell-type.constant';

@Injectable()
export class MyStudentService {
  listCellFormattingService: ListCellFormattingService;

  constructor() {
    this.listCellFormattingService = new ListCellFormattingService();
  }

  formatColumnData(columnType, columnData) {
    const { data } = columnData;

    switch (columnType) {
      case COLUMN_DATA_TYPE.FLAG:
        return this.listCellFormattingService.flagFormatter(data);
      case COLUMN_DATA_TYPE.GENERIC:
        return this.listCellFormattingService.genericFormatter(data);
      case COLUMN_DATA_TYPE.POINT_PERSON:
        return this.listCellFormattingService.pointPersonFormatter(data);
      case COLUMN_DATA_TYPE.OTHER_STAFF:
        return this.listCellFormattingService.otherStaffFormatter(data);
      default:
        let formattedData: IListColumnData = {
          data,
          style: '',
        };
        return formattedData;
    }
  }
}
