import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvSidebarItemComponent } from './nv-sidebar-item.component';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [CommonModule, NvIconModule, MatRippleModule, RouterModule],
  declarations: [NvSidebarItemComponent],
  exports: [NvSidebarItemComponent],
})
export class NvSidebarItemModule {}
