import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { UpdateStudentPath, IStudentPathUpdatePayload } from 'Src/ng2/store/actions/student-paths-actions';
import { Store } from '@ngrx/store';
import { COLLEGE_PATH_STATUSES, PATH_CATEGORY_KEYS, SPEC_OPP_HUMAN_MAP, SPEC_OPP_KEY_MAP } from 'Src/ng2/shared/constants/student-paths.constant';
import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';

const DEFAULT_STATUSES = [
  COLLEGE_PATH_STATUSES.PLANS_TO_APPLY,
  COLLEGE_PATH_STATUSES.APPLIED,
  COLLEGE_PATH_STATUSES.ACCEPTED,
  COLLEGE_PATH_STATUSES.DENIED,
];

const COLLEGE_STATUSES = [
  COLLEGE_PATH_STATUSES.APPLIED_WITH_SPECIAL_OPP,
  COLLEGE_PATH_STATUSES.ACCEPTED_WITH_SPECIAL_OPP,
  COLLEGE_PATH_STATUSES.WAITLISTED,
];
@Component({
  selector: 'student-postsec-panel-list-status-dropdown',
  templateUrl: './student-postsec-panel-list-status-dropdown.component.html',
  styleUrls: ['./student-postsec-panel-list-status-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentPostsecPanelListStatusDropdownComponent implements OnInit {
  @Input() input: any;
  @Output() output = new EventEmitter();

  options: IDropdownOption[];
  selectedOption: String;
  isViewOnlyUser: boolean;

  constructor (private store: Store<any>) {}

  public ngOnInit () {
    const { status, studentPathId, pathCategory } = this.input.column.meta;
    const { finalDecisionPathId } = this.input.dynamicComponentInputData;
    this.isViewOnlyUser = this.input.column.meta.isViewOnlyUser;

    const statuses = [...DEFAULT_STATUSES];
    const finalDecisionStatuses = [
      COLLEGE_PATH_STATUSES.COMMITTED,
    ];

    if (pathCategory === PATH_CATEGORY_KEYS.COLLEGE) {
      const committedSpecialOppStatus = SPEC_OPP_KEY_MAP[COLLEGE_PATH_STATUSES.COMMITTED_WITH_SPECIAL_OPP];
      statuses.push(...COLLEGE_STATUSES);
      finalDecisionStatuses.push(committedSpecialOppStatus);
    }

    if (!this.isViewOnlyUser) {
      this.options = statuses.map((status) => {
        const parsedStatus = SPEC_OPP_KEY_MAP[status] ? SPEC_OPP_KEY_MAP[status] : status;
        return {
          human: parsedStatus,
          key: parsedStatus,
        };
      });

      if (!finalDecisionPathId || (finalDecisionPathId && finalDecisionPathId === studentPathId)) {
        finalDecisionStatuses.forEach((status) => this.options.push({ key: status, human: status }));
      }
    }

    this.selectedOption = status;
  }

  onStatusChange (optionKey: IDropdownOption['key']): void {
    const { studentPathId, schoolId, studentId } = this.input.column.meta;
    // parse status from mapping (AB)
    const parsedStatus = SPEC_OPP_HUMAN_MAP[optionKey] ? SPEC_OPP_HUMAN_MAP[optionKey] : optionKey;
    const payload: IStudentPathUpdatePayload = {
      studentPathId,
      status: parsedStatus,
      schoolId,
      studentId,
    };
    this.store.dispatch(new UpdateStudentPath(payload));
  }
}
