/* tslint:disable */
import { find } from 'lodash';
import { ISubjectArea, SubjectAreas } from '../../../ng2/shared/constants/subject-areas.constant';
import { IStudentSupport } from '../typings/interfaces/student-support.interface';
import { Observable } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

export type TValidGraphNames =
  | 'POST_SECONDARY_FLAGS'
  | 'POST_SECONDARY_PLANS'
  | 'DAILY_ATT'
  | 'ABSENT_TODAY'
  | 'NEEDS_ATT_SUPPORT'
  | 'NEEDS_MATH_SUPPORT'
  | 'NEEDS_ELA_SUPPORT';

const todayString = _getFormattedDate();

/* istanbul ignore next */
export const DASHBOARD_GRAPHS = {
  Postsecondary: [],
  Attendance: [],
  Academic: [
    {
      category: 'Academic',
      isDeprecated: true,
      key: 'FAILURES_WITH_NO_SUPPORT',
      tileName: 'High priority failures with no support',
      flag: null,
      type: 'SUPPORTED_SUBJECTS',
      hasMadlibs: true,
      isAdminOnly: true,
      // not persisted on the store -- calculated on the fly
      isCalculatedState: true,
      calculateGraphState: (students$?) => {
        let graphState$: { loaded: boolean; loading: boolean; data: any };
        students$
          .pipe(
            map((students: any[]) => {
              return students.reduce((total, student) => {
                const subjectsWithSupportMap = _getSubjectsWithSupportMap(student);
                const countFailuresWithNoSupport = _getFailuresWithNoSupportCount(student, subjectsWithSupportMap);
                total += countFailuresWithNoSupport;
                return total;
              }, 0);
            }),
            tap(data => (graphState$ = { loaded: true, loading: false, data })),
            take(1),
          )
          .subscribe();
        return graphState$;
      },
      getTodayData: () => null,
    },
  ],
};

// HS_DASHBOARD_GRAPHS
export const HS_DASHBOARD_GRAPHS = {
  Postsecondary: DASHBOARD_GRAPHS.Postsecondary,
  Attendance: DASHBOARD_GRAPHS.Attendance,
};

//ES_DASHBOARD_GRAPHS
export const ES_DASHBOARD_GRAPHS = {
  Attendance: DASHBOARD_GRAPHS.Attendance,
  Academic: DASHBOARD_GRAPHS.Academic.slice(0, 2), // High priority failures not relevant to ES schools
};

// MS_AND_ES_DASHBOARD_GRAPHS
export const ES_AND_MS_DASHBOARD_GRAPHS = {
  Attendance: DASHBOARD_GRAPHS.Attendance,
  Academic: DASHBOARD_GRAPHS.Academic,
};

// Temporary graph-options to facilitate qa of manager-view-lite graph
export const HS_DASHBOARD_GRAPHS_WITH_LIMITED_ACAD = {
  Postsecondary: DASHBOARD_GRAPHS.Postsecondary,
  Attendance: DASHBOARD_GRAPHS.Attendance,
  Academic: [DASHBOARD_GRAPHS.Academic[2]],
};
export const GRAPHS_BY_SCHOOL_TYPE = {
  'K to 5': ES_DASHBOARD_GRAPHS,
  'K to 8': ES_DASHBOARD_GRAPHS,
  '6 to 8': ES_DASHBOARD_GRAPHS,
  '6 to 12': ES_AND_MS_DASHBOARD_GRAPHS,
  'Large HS': HS_DASHBOARD_GRAPHS_WITH_LIMITED_ACAD,
  'Small HS': HS_DASHBOARD_GRAPHS_WITH_LIMITED_ACAD,
  Charter: HS_DASHBOARD_GRAPHS_WITH_LIMITED_ACAD,
  Transfer: HS_DASHBOARD_GRAPHS_WITH_LIMITED_ACAD,
};

// HELPER FUNCTIONS
function _getFlagCount(
  students$: Observable<Array<{ flags: { type: string; isIgnored: boolean; status: string } }>>,
  flagType: string,
): { x: string; y: number } {
  let state: { x: string; y: number };
  const todayString = _getFormattedDate();
  students$
    .pipe(
      map((students: Array<{ flags: any }>) =>
        students.reduce((sumData, { flags }) => {
          flags.forEach(({ type, isIgnored, status }) => {
            if (type === flagType && !isIgnored && status === 'ACTIVE') sumData++;
          });
          return sumData;
        }, 0),
      ),
      tap(flagsToday => (state = { x: todayString, y: flagsToday })),
      take(1),
    )
    .subscribe();
  return state;
}

function _getSubjectsWithSupportMap(student: { activeStudentSupports: IStudentSupport[] }): { [key: string]: boolean } {
  const { activeStudentSupports } = student;
  return activeStudentSupports.reduce((subjectsWithSupport, studentSupport) => {
    const {
      support: { category, metaData },
    } = studentSupport;

    const isAcademicSupport = category === 'ACADEMIC';
    const hasMetaData = metaData;

    if (!isAcademicSupport || !hasMetaData) return subjectsWithSupport;
    // get subjectArea constant from support.metaData.subject --
    // these are misaligned to currProgram.grade, so mapping is necessary
    const subjectAreaData: ISubjectArea = find(SubjectAreas, { humanShort: metaData.subject });
    if (!subjectAreaData) return subjectsWithSupport;

    const { key: subjectKey } = subjectAreaData;
    subjectsWithSupport[subjectKey] = true;

    return subjectsWithSupport;
  }, {});
}

function _getFailuresWithNoSupportCount(
  student: {
    currProgram: {
      grades: Array<{ subj: string; pf: string; mostRecent: boolean; priority: string }>;
    };
  },
  subjectsWithSupportMap: { [key: string]: boolean },
): number {
  const {
    currProgram: { grades },
  } = student;
  return grades.reduce((failedWithoutSupportCount, { subj, pf, mostRecent, priority }) => {
    const isFailing = pf === 'F';
    const hasNoSupport = !subjectsWithSupportMap[subj];
    const isHighPriority = priority === 'high';
    const isMostRecentGrade = mostRecent;
    if (isFailing && hasNoSupport && isHighPriority && isMostRecentGrade) failedWithoutSupportCount++;
    return failedWithoutSupportCount;
  }, 0);
}

function _getFormattedDate(date?) {
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1; // months from 1-12
  const day = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  const todayString = `${year}-${month}-${day}`;
  return todayString;
}

export function getHelperFunctionsForTests() {
  return {
    _getFormattedDate,
    _getFlagCount,
    _getFailuresWithNoSupportCount,
    _getSubjectsWithSupportMap,
  };
}

export const DEPRECATED_GRAPHS = {
  CREATED_POSTSECONDARY_LIST: true,
  APPLIED_TO_ALL_COLLEGES: true,
  ACCEPTED_TO_ONE_OR_MORE_COLLEGES: true,
  HAVE_FINAL_DECISION: true,
  DAILY_ATT: true,
  ABSENT_TODAY: true,
  NEEDS_ATT_SUPPORT: true,
  NEEDS_MATH_SUPPORT: true,
  NEEDS_ELA_SUPPORT: true,
};
