import { Injectable } from '@angular/core';
import { ITranscriptCourse } from 'Src/ng2/shared/typings/interfaces/student.interface';

@Injectable()
export class TranscriptAndExamHistoryService {
  public didPassExam (gradPlanningDetails, mark: number): boolean {
    const diplomaType = gradPlanningDetails.plannedDiplomaType;
    const safetyNetEligible = gradPlanningDetails.schoolVerifiedSafetyNetEligibility;

    switch (diplomaType) {
      case 'Regents':
      case 'Advanced Regents':
      case 'Non-Graduate':
        return mark >= 65;
      case 'Local':
        return mark >= 55;
      case 'No plan':
        if ((mark >= 55) && (safetyNetEligible)) {
          return true;
        }
        if ((mark >= 65) && (!safetyNetEligible)) {
          return true;
        }
        return false;
      default:
        return false;
    }
  }

  // SY2019-20 ==> SY19-20
  public formatSchoolYear (year: string): string {
    return year.slice(0, 2) + year.slice(4);
  }

  public filterDataByYear (data: ITranscriptCourse[], filter: string): ITranscriptCourse[] {
    const result = data.filter(item => {
      const formattedSchoolYear = this.formatSchoolYear(item.schoolYear);
      return formattedSchoolYear === filter;
    },
    );
    return result;
  }
}
