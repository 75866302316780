/* eslint-disable camelcase */
import { THybridRoleTypes } from './../../../typings/interfaces/user.interface';
import {
  IUser,
  IUserMiniWithRole,
  TEffectiveRole,
} from '../../../typings/interfaces/user.interface';
import { IM_USER_CONSTANTS } from './../im-user';
import { userCate } from './im-user.helper';
import { PartnerTypes, TValidPartnerTypes } from '../../../typings/interfaces/partner.interface';

export interface IRoleTypesMapper {
  [partnerType: string]: TEffectiveRole;
}

export class ImUserCore {
  getRoleTypes (user: IUser | IUserMiniWithRole): IRoleTypesMapper[] {
    const roles = IM_USER_CONSTANTS.PARTNER_TYPES.reduce((_roles, partnerType: TValidPartnerTypes) => {
      const role = this.getRoleType(user, partnerType);
      if (role) {
        _roles.push({ [partnerType]: role });
      }
      return _roles;
    }, []);
    return roles;
  }

  getRoleType (
    user: IUser | IUserMiniWithRole,
    partnerType: TValidPartnerTypes = 'school',
  ): TEffectiveRole | THybridRoleTypes {
    const cated = userCate(user, partnerType);
    return cated({
      hybrid: this.getRoleTypeHybrid.bind(this),
      school: this.getRoleTypeSchool,
      shelter: this.getRoleTypeShelter,
    });
  }

  private getRoleTypeSchool (user: IUser | IUserMiniWithRole): TEffectiveRole {
    return user.nvRole ? user.delegatedRole || user.nvRole.type : null;
  }

  private getRoleTypeShelter (user: IUser | IUserMiniWithRole): TEffectiveRole {
    return user.nvRoleShelter ? user.delegatedRoleShelter || user.nvRoleShelter.type : null;
  }

  private getRoleTypeHybrid (user: IUser | IUserMiniWithRole): THybridRoleTypes {
    const schoolRoleType = this.getRoleTypeSchool(user);
    const shelterRoleType = this.getRoleTypeShelter(user);
    return { schoolRoleType, shelterRoleType };
  }

  hasPartnerId (user: IUser, partnerId: string, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    const cated = userCate(user, partnerType);
    return cated({
      school: this.hasSchoolId.bind(this, user, partnerId),
      shelter: this.hasShelterId.bind(this, user, partnerId),
    });
  }

  private hasSchoolId (user: IUser, schoolId: string): boolean {
    const {
      nvRole: { schoolId: _schoolId },
      _role_clusterSchoolIds,
    } = user;
    if (_schoolId) {
      return schoolId === _schoolId;
    } else if (_role_clusterSchoolIds) {
      return _role_clusterSchoolIds.includes(schoolId);
    }
    return false;
  }

  private hasShelterId (user: IUser, shelterId: string): boolean {
    const {
      nvRoleShelter: { shelterId: _shelterId },
      _role_shelterClusterShelterIds,
    } = user;
    if (_shelterId) {
      return shelterId === _shelterId;
    } else if (_role_shelterClusterShelterIds) {
      return _role_shelterClusterShelterIds.includes(shelterId);
    }
    return false;
  }

  getRoleTypeOnAllLevels (user: IUser | IUserMiniWithRole, opts?: { partnerType?, partnerId? }): TEffectiveRole | THybridRoleTypes {
    const partnerType = opts?.partnerType || PartnerTypes.SCHOOL;
    const partnerId = opts?.partnerId;
    if (partnerId) {
      return this.recalcRoleTypeOnPartnerLevel({ user, partnerType, partnerId });
    } else {
      return this.getRoleTypeOnPartnerLevel(user, partnerType) || this.getRoleType(user, partnerType);
    }
  }

  getRoleTypeOnPartnerLevel (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): TEffectiveRole {
    const cated = userCate(user, partnerType);
    return cated({
      school: user => user.effectiveSchoolRoleType,
      shelter: user => user.effectiveShelterRoleType,
    });
  }

  setRoleTypeOnPartnerLevel (
    roleType: TEffectiveRole,
    user: IUser,
    partnerType: TValidPartnerTypes = 'school',
  ): void {
    const cated = userCate(user, partnerType);
    cated({
      school: user => {
        user.effectiveSchoolRoleType = roleType;
      },
      shelter: user => {
        user.effectiveShelterRoleType = roleType;
      },
    });
  }

  recalcRoleTypeOnPartnerLevel ({ user, partnerType, partnerId }): TEffectiveRole | THybridRoleTypes {
    const userRole = this.getRoleType(user, partnerType);
    const cated = userCate(user, partnerType);

    const recalcedRole = cated({
      hybrid: () => userRole,
      school: user => {
        if (partnerId) {
          const userRoleOnSchoolLvl = this.recalcRoleTypeOnSchoolLevel(user, partnerId);
          this.setRoleTypeOnPartnerLevel(userRoleOnSchoolLvl, user, partnerType);
          return userRoleOnSchoolLvl || userRole;
        } else {
          return userRole;
        }
      },
      shelter: user => {
        if (partnerId) {
          const userRoleOnShelterLvl = this.recalcRoleTypeOnShelterLevel(user, partnerId);
          this.setRoleTypeOnPartnerLevel(userRoleOnShelterLvl, user, partnerType);
          return userRoleOnShelterLvl || userRole;
        } else {
          return userRole;
        }
      },
    });
    return recalcedRole;
  }

  // this method is to recalculate school roles against context school Id at run time
  // can be used on all types of school roles
  // returns effectiveSchoolRoleType given a school id
  // this method gets called in route guard through parent ImUser.recalcRoleTypeOnPartnerLevel
  recalcRoleTypeOnSchoolLevel (user: IUser, schoolId: string): TEffectiveRole | undefined {
    let effectiveSchoolRoleType;
    const role = this.getRoleType(user, PartnerTypes.SCHOOL);
    if (role === 'network_and_school' && schoolId) {
      const { edit_all, view_all, cluster_edit_all, cluster_view_all } = user.portfolio.permissions;
      if (cluster_edit_all === true || edit_all?.includes(schoolId)) {
        effectiveSchoolRoleType = 'school_admin';
      } else if (cluster_view_all === true || view_all?.includes(schoolId)) {
        effectiveSchoolRoleType = 'view_all';
      } else {
        effectiveSchoolRoleType = 'school';
      }
    }
    if (role === 'multi_school' && schoolId) {
      const {
        delegated_school_admin,
        edit_all,
        view_all,
        edit_caseload,
        view_caseload,
      } = user.portfolio.permissions;
      if (delegated_school_admin.includes(schoolId)) {
        effectiveSchoolRoleType = 'delegated_school_admin';
      } else if (edit_all.includes(schoolId)) {
        effectiveSchoolRoleType = 'edit_all';
      } else if (view_all.includes(schoolId)) {
        effectiveSchoolRoleType = 'view_all';
      } else if (edit_caseload.includes(schoolId)) {
        effectiveSchoolRoleType = 'edit_caseload';
      } else if (view_caseload.includes(schoolId)) {
        effectiveSchoolRoleType = 'view_caseload';
      } else {
        effectiveSchoolRoleType = 'school';
      }
    }

    return effectiveSchoolRoleType;
  }

  // this method is to recalculate shelter roles against context shelter Id at run time
  // can be used on all types of shelter roles
  // returns effectiveShelterRoleType given a shelter id
  // this method gets called in route guard through parent ImUser.recalcRoleTypeOnPartnerLevel
  private recalcRoleTypeOnShelterLevel (user: IUser, shelterId: string): TEffectiveRole | undefined {
    let effectiveShelterRoleType;
    const role = this.getRoleType(user, PartnerTypes.SHELTER);
    const hasShelterId = this.hasShelterId(user, shelterId);
    if (role === 'shelter_only' && shelterId && hasShelterId) {
      const { edit_all, view_all, cluster_edit_all, cluster_view_all } = user.shelterPortfolio.permissions;
      if (cluster_edit_all === true || edit_all?.includes(shelterId)) {
        effectiveShelterRoleType = 'shelter_edit_all';
      } else if (cluster_view_all === true || view_all?.includes(shelterId)) {
        effectiveShelterRoleType = 'shelter_view_all';
      } else {
        effectiveShelterRoleType = 'shelter';
      }
    } else if (!hasShelterId) {
      effectiveShelterRoleType = 'shelter';
    }
    return effectiveShelterRoleType;
  }

  isSuperAdmin (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    const role: TEffectiveRole | THybridRoleTypes = this.getRoleType(user, partnerType);
    if (partnerType === 'hybrid') {
      const { schoolRoleType, shelterRoleType } = role as THybridRoleTypes;
      return (
        IM_USER_CONSTANTS.FRONT_END_SUPER_ADMIN_ROLES.includes(schoolRoleType) &&
        IM_USER_CONSTANTS.FRONT_END_SUPER_ADMIN_ROLES.includes(shelterRoleType)
      );
    } else {
      return IM_USER_CONSTANTS.FRONT_END_SUPER_ADMIN_ROLES.includes(role as TEffectiveRole);
    }
  }

  static isSuperAdmin (user: IUser | IUserMiniWithRole, partnerType: TValidPartnerTypes = PartnerTypes.SCHOOL): boolean {
    const imUser = new this();
    return imUser.isSuperAdmin(user, partnerType);
  }

  static isClusterWideOrHasOnePlus (portfolio: IUser['portfolio'] | IUser['shelterPortfolio']): boolean {
    const { cluster_edit_all, cluster_view_all, edit_all, view_all } = portfolio.permissions;
    const clusterWideAccess = cluster_edit_all || cluster_view_all;
    const hasAccessToOnePlus = !!edit_all?.length || !!view_all?.length;
    return clusterWideAccess || hasAccessToOnePlus;
  }
}
