import { Component, Output, EventEmitter, Input, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { ISidebarItem } from 'Src/nvps-libraries/design/nv-sidebar-list/nv-sidebar.interface';
@Component({
  selector: 'master-program-settings-right-nav',
  templateUrl: './master-program-settings-right-nav.component.html',
  styleUrls: ['./master-program-settings-right-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class MasterProgramSettingsRightNavComponent implements OnInit {
  @Output() clickedBatchActions = new EventEmitter();
  @Output() clickedAssignPrep = new EventEmitter();
  @Output() clickedRemovePrep = new EventEmitter();
  @Input() batchActionsMode$: Observable<boolean>;
  @Input() numberOfCoursesSelected: Number;
  isDisabled: boolean = true;

  toolsTitle = 'Tools';
  toolsSidebarItems: ISidebarItem[] = [
    {
      human: 'Batch actions',
      key: 'batchActions',
      icon: 'select',
    },
  ];

  batchActionsTitle = 'Batch Actions';
  batchActionsSubTitle: String;
  batchActionsSidebarItems: ISidebarItem[];

  ngOnInit () {
    this.batchActionsSubTitle = `${this.numberOfCoursesSelected} courses selected`;
    this.batchActionsSidebarItems = [
      {
        human: 'Regents prep',
        key: 'regents_prep',
        disabled: this.isDisabled,
        expandAs: 'dropdown',
        children: [
          {
            human: 'Assign prep',
            key: 'assignPrep',
          },
          {
            human: 'Remove prep',
            key: 'removePrep',
          },
        ],
      },
    ];
  }

  ngOnChanges (changes: SimpleChanges) {
    this.batchActionsSubTitle = `${changes.numberOfCoursesSelected.currentValue} courses selected`;
    this.isDisabled = !(changes.numberOfCoursesSelected.currentValue > 0);
    this.batchActionsSidebarItems = [
      {
        human: 'Regents prep',
        key: 'regents_prep',
        disabled: this.isDisabled,
        expandAs: 'dropdown',
        children: [
          {
            human: 'Assign prep',
            key: 'assignPrep',
          },
          {
            human: 'Remove prep',
            key: 'removePrep',
          },
        ],
      },
    ];
  }

  public onSelectItem ($selectedKey: string): void {
    switch ($selectedKey) {
      case 'batchActions':
        this.clickedBatchActions.emit();
        break;
      case 'assignPrep':
        this.clickedAssignPrep.emit();
        break;
      case 'removePrep':
        this.clickedRemovePrep.emit();
        break;
      case 'cancel':
        this.clickedBatchActions.emit();
        break;
      default:
        break;
    }
  }

  public onCancel (): void {
    this.clickedBatchActions.emit();
  }
}
