import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

// background jobs should align to backend backgroundJob.className
export type TValidBackgroundJob =
  | 'BulkStudentUpdate'
  | 'BulkStudentSupportUpdate'
  | 'BulkStudentSupportCreate'
  | 'BulkAttendanceRecordsUpdate'
  | 'BulkAttendanceRecordsCreate'
  | 'SupportMiniSchemaUpdate'
  | 'BulkStudentPathCreate'
  | 'BulkStudentPathUpdate'
  | 'BulkStudentActivityCreate'
  | 'BulkStudentActivityUpdate'
  | 'BulkMilestoneUpdate'
  | 'BulkShelterSuccessMentorCreate'
  | 'BulkShelterSuccessMentorUpdate'
  | 'BulkShelterStudentNoteCreate'
  | 'BulkStudentNoteCreate';

export interface IBackgroundJobStatusMsg {
  state: 'STARTED' | 'RESOLVED' | 'REJECTED' | 'PARTIAL_SETTLED';
  type: 'NOTIFY' | 'RESOLVE' | 'REJECT' | 'PARTIAL';
  status: any;
}
export interface IMessage {
  backgroundJob: TValidBackgroundJob;
  backgroundJobStatusMsg?: IBackgroundJobStatusMsg | IBackgroundJobStatusMsg['status'];
}

@Injectable({
  providedIn: 'root',
})
export class BackgroundJobNotificationService {
  private subject = new Subject<any>();

  sendMessage (message: IMessage) {
    this.subject.next(message);
  }

  getMessage (): Observable<any> {
    return this.subject.asObservable();
  }
}
