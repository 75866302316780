import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { identity } from 'lodash';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { ImUser } from 'Src/ng2/shared/services/im-models/im-user';
import { IUser } from 'Src/ng2/shared/typings/interfaces/user.interface';
import { unsubscribeComponent } from './shared/helpers/unsubscribe-decorator/unsubscribe-decorators.helper';
import { ApiService } from './shared/services/api-service/api-service';
import { HelpDeskService } from './shared/services/help-desk/help-desk.service';
import { ImNotification } from './shared/services/im-models/im-notification.service';
import { PortalConfig } from './shared/services/portal-config';
import { WindowRef } from './shared/services/windowRef';
import { INotification } from './shared/typings/interfaces/notification.interface';
import { getCurrentUser, getCurrentUserLoadedStatus } from './store/selectors/current-user-selectors';

@Component({
  selector: 'data-portal',
  template: `
    <notifications
      *ngIf="(notificationsForUser$ | async) as notifications"
      [notifications]="notifications"
      [currentUser]="this.getCurrentUser$() | async"
    ></notifications>
    <div class="environment-indicator" [ngClass]="env"></div>

    <router-outlet (activate)="onActivate()" (deactivate)="onDeactive()"></router-outlet>

    <div [ngStyle]="{ display: (showUiView | async) ? 'initial' : 'none' }">
      <!-- <div class="ui-view v3-bg"></div> must be clear of any other attributes to allow ui-route to work (jchu)-->
      <!-- the parent must be a native html element (jchu)-->
      <div class="ui-view v3-bg"></div>
    </div>
  `,
  styleUrls: ['./styles/main.scss'],
  encapsulation: ViewEncapsulation.None,
})
@unsubscribeComponent
export class AppComponent implements OnInit {
  public env: string = '';
  public envConfig: { [key: string]: boolean } = {};
  public currentEnvClass: string;
  public notificationsForUser$: Observable<Array<INotification>>;
  public showUiView: BehaviorSubject<boolean> = new BehaviorSubject(true);
  private currentUser$: Observable<IUser>;

  constructor (
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private api: ApiService,
    private helpDeskService: HelpDeskService,
    private imNotification: ImNotification,
    private imUser: ImUser,
    private portalConfig: PortalConfig,
    private store: Store<any>,
    private router: Router,
    private windowRef: WindowRef,
  ) {
    // initiate google analytics
    if (this.portalConfig.publicConfig.GOOG_ANALTICS_ID) {
      this.angulartics2GoogleAnalytics.startTracking();
    }
    if (this.portalConfig.publicConfig.GOOG_ANALTICS4_ID) {
      this.handleRouteEvents();
    }

    if (
      this.portalConfig.publicConfig.DISPLAY_NV_ENV !== '' &&
      this.portalConfig.publicConfig.DISPLAY_ENV_PATH !== 'prod'
    ) {
      this.env = this.portalConfig.publicConfig.DISPLAY_NV_ENV.toLowerCase(); // sets the env indicator class
      /* eslint no-undef: 0 */
      const favicon: HTMLLinkElement = document.querySelector('link[rel="icon"]');
      favicon.href = `/assets/img/favicon-${this.env}.ico`;
      const title = document.querySelector('title');
      title.innerText = `${this.portalConfig.publicConfig.DISPLAY_NV_ENV} - Portal by New Visions`;
    }
  }

  ngOnInit (): void {
    this.helpDeskService.startHelpDeskService();
    this.notificationsForUser$ = this.getNotificationsForUser$();
  }

  public onActivate (): void {
    this.showUiView.next(false);
  }

  public onDeactive (): void {
    this.showUiView.next(true);
  }

  private getNotificationsForUser$ (): Observable<Array<INotification>> {
    return combineLatest([this.getCurrentUser$(), this.getNotifications$()]).pipe(
      filter(([currentUser, notifications]) => !!currentUser && !!notifications),
      // Once both of these emit we know a user in authenticated and set the user session
      tap(([currentUser]) => this.imUser.setCurrentUserSession(currentUser)),
      switchMap(([user, notifications]) => of(this.imNotification.filterForInProgress(notifications, user._id))),
    );
  }

  public getCurrentUser$ (): Observable<IUser> {
    this.currentUser$ = this.store.pipe(
      select(getCurrentUserLoadedStatus),
      filter(identity),
      switchMap(() => this.store.pipe(select(getCurrentUser))),
    );
    return this.currentUser$;
  }

  private getNotifications$ (): Observable<Array<INotification>> {
    return this.store.pipe(
      select(getCurrentUserLoadedStatus),
      filter(identity),
      switchMap(() => this.api.getNotifications({ params: { status: 'ACTIVE' } })),
      map(({ data }: { data: Array<INotification> }) => data),
    );
  }

  private handleRouteEvents () {
    // needed for sending every route change to google analytics
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      tap((event: NavigationEnd) => {
        const previousPagePath = this.router.getCurrentNavigation().previousNavigation?.finalUrl?.toString() || '(entrance)';
        this.windowRef.nativeWindow.gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
          previous_page_path: previousPagePath,
          page_location: event.urlAfterRedirects,
          page_referrer: previousPagePath,
        });
      }),
    ).subscribe();
  }
}
