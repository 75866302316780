import { ISchool } from './school.interface';
import { IShelter } from './shelter.interface';

export enum PartnerTypes {
  SCHOOL = 'school',
  SHELTER = 'shelter',
  HYBRID = 'hybrid',
}
export type TValidPartnerTypes = 'shelter' | 'school' | 'hybrid';

// IPartner could be extended to include other partner interface
export interface IPartner extends Partial<IShelter>, Partial<ISchool> {}
