import { NumberFilterPassCases } from './../../../../app/shared/services/sorter/ag-grid/numberFilter/number-filter-pass-cases.service';
import { AG_GRID_TEXT_FILTER_TYPES } from './../../../../app/shared/services/sorter/ag-grid/ag-grid-filter.constant';
import { SorterColumn } from 'Src/ng2/shared/services/sorter-column/sorter-column.service';
import * as _ from 'lodash';

import { SorterColumnDataType } from '../../../../ng2/shared/constants/sorter-column-data-type.constant';
import { IUserMini } from '../../../../ng2/shared/typings/interfaces/user.interface';
import { Injectable } from '@angular/core';

const DataTypeFactoryMethod = {};
DataTypeFactoryMethod[SorterColumnDataType.BOOLEAN_YES_NO] = 'makeBooleanYesNoFilter';
DataTypeFactoryMethod[SorterColumnDataType.STRING] = 'makeSetFilter';
DataTypeFactoryMethod[SorterColumnDataType.NUMERIC] = 'makeNumericFilter';
DataTypeFactoryMethod[SorterColumnDataType.ARRAY] = 'makeSetFilter';
DataTypeFactoryMethod[SorterColumnDataType.ENUM] = 'makeSetFilter';
DataTypeFactoryMethod[SorterColumnDataType.REGENTS_ADMIN] = 'makeSetFilter';
DataTypeFactoryMethod[SorterColumnDataType.USER_MINI] = 'makeUserMiniFilter';

@Injectable()
export class AgGridFilterService {
  public DataTypeFactoryMethod = DataTypeFactoryMethod;

  constructor (private SorterColumn: SorterColumn) {}

  makeFilterFunctionsFromFilterSet (filterSet) {
    const filterFunctions = _.map(filterSet, (filter, column) => {
      return this.makeFilterForColumn(column, filter);
    });

    return filterFunctions;
  }

  /**
   *
   * @param sorterColumn {String} The column name you want to filter on.
   * @param filter {Object} A filter object. This must match the `dataType` of the column.
   * @returns {Function} A function that takes a `flattenedStudent` and returns true if that student matches
   *    `filter` for `sorterColumn`
   */
  makeFilterForColumn (sorterColumn, filter) {
    const dataType = this.SorterColumn.getByColumnKey(sorterColumn).dataType;

    const methodName = DataTypeFactoryMethod[dataType];

    if (!methodName) throw new Error(`filter method cannot be found for SorterColumn === "${sorterColumn}"`);
    const method = this[methodName];

    return method(sorterColumn, _.cloneDeep(filter));
  }

  /**
   * Filter function for BOOLEAN_YES_NO
   *
   * BOOLEAN_YES_NO filters should have this format:
   * {
   *    in: [true|false] // an array of allowed values
   * }
   *
   * @param sorterColumn
   * @param filter
   * @returns {Function} has the signature (flattenedStudent) and returns true if it passes the filter
   */
  makeBooleanYesNoFilter (sorterColumn, filter) {
    return flattenedStudent => {
      return _.includes(filter.in, flattenedStudent[sorterColumn]);
    };
  }

  /**
   * Filter function for STRING
   *
   * ag-grid 'text' filter example
   * studentName: {
   *  filter: "Joe",
   *  type: 1 // contains filter
   * }
   *
   * @param sorterColumn
   * @param filter
   * @returns {Function} has the signature (flattenedStudent) and returns true if it passes the filter
   */

  makeTextFilter (sorterColumn, filter) {
    return function (flattenedStudent) {
      const filterVal = filter.filter.toLowerCase();
      const columnVal = flattenedStudent[sorterColumn].toLowerCase();

      switch (filter.type) {
        case AG_GRID_TEXT_FILTER_TYPES.CONTAINS:
          return _.includes(columnVal, filterVal);
        case AG_GRID_TEXT_FILTER_TYPES.EQUALS:
          return _.isEqual(columnVal, filterVal);
        case AG_GRID_TEXT_FILTER_TYPES.NOT_EQUALS:
          return !_.isEqual(columnVal, filterVal);
        case AG_GRID_TEXT_FILTER_TYPES.STARTS_WITH:
          return _.startsWith(columnVal, filterVal);
        case AG_GRID_TEXT_FILTER_TYPES.ENDS_WITH:
          return _.endsWith(columnVal, filterVal);
        default:
          return false;
      }
    };
  }

  /**
   * Filter function for NUMERIC
   *
   * ag-grid 'text' filter example
   * totalCreditsEarned: {
   *  filter: 40,
   *  type: 1 // equals filter
   * }
   *
   * @param sorterColumn
   * @param filter
   * @returns {Function} has the signature (flattenedStudent) and returns true if it passes the filter
   */
  makeNumericFilter (sorterColumn, filter) {
    return function (flattenedStudent) {
      const columnVal: number = parseFloat(flattenedStudent[sorterColumn]);
      const filterVal = filter.filter;
      // returns boolean
      return NumberFilterPassCases.evaluate(filter.type, columnVal, filterVal);
    };
  }

  /**
   * Filter function for ARRAY or ENUM
   *
   * ag-grid 'set' filter example
   * plannedDiplomaType: ['Advanced Regents', 'Regents']
   * visible fields are included in array
   *
   * @param sorterColumn
   * @param filter
   * @returns {Function} has the signature (flattenedStudent) and returns true if it passes the filter
   */
  makeSetFilter (sorterColumn, filter) {
    let emptyArrayValInFilter = false;

    if (_.indexOf(filter, 'null') !== -1) {
      // ag-grid uses "null" filter value for cells with values of '' or null
      // add "" and null to filter, since "" or null are the actual value of empty cells in the sorter
      filter = filter.concat(['', null]);
    }

    // for filtering empty array values
    if (_.includes(filter, '[]')) {
      emptyArrayValInFilter = true;
    }

    return function (flattenedStudent) {
      let columnVal = flattenedStudent[sorterColumn];
      const isArray = _.isArray(columnVal);
      // if it's a string (for ENUM types), cast array
      columnVal = !isArray ? [columnVal] : columnVal;

      if (_.intersection(filter, columnVal).length) {
        return true;
      }

      // for empty arrays
      if (isArray && !columnVal.length && emptyArrayValInFilter) {
        return true;
      }

      return false;
    };
  }

  makeUserMiniFilter (sorterColumn, filter: IUserMini[]) {
    const noAdvisor: IUserMini = {
      userId: null,
      firstName: null,
      lastName: null,
      gafeEmail: null,
      doeEmail: null,
    };

    return function (flattenedStudent) {
      const columnVal: IUserMini = flattenedStudent[sorterColumn] || noAdvisor;
      return columnVal && _.find(filter, { lastName: columnVal.lastName });
    };
  }
}
