import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NvIconModule } from '../nv-icon/nv-icon.module';
import { NvNavigationTabTileComponent } from './nv-navigation-tab-tile.component';

@NgModule({
  imports: [CommonModule, NvIconModule],
  declarations: [NvNavigationTabTileComponent],
  exports: [NvNavigationTabTileComponent],
})
export class NvNavigationTabTileModule {}
