import { RollbarService } from './../../rollbar/rollbar.service';
import { Inject, Injectable } from '@angular/core';
import { WebStorageUtility } from './../../../utilities/web-storage/web-storage.utility';
import { WindowRef } from './../../windowRef';
import * as Rollbar from 'rollbar';

@Injectable()
export class SessionStorageService extends WebStorageUtility<string> {
  constructor (private windowRef: WindowRef, @Inject(RollbarService) private Rollbar: Rollbar) {
    super(windowRef.nativeWindow.sessionStorage, Rollbar);
  }
}
