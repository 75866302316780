import { SchoolSingleColumnLayoutComponent } from 'Src/ng2/school/layouts/school-single-column-layout/school-single-column-layout.component';
import { GridStateResolver } from '../../../resolvers/grid-state/grid-state.resolver';
import { MaintenanceModeResolver } from '../../../resolvers/maintenance-mode/maintenance-mode.resolver';
import { RouteGuard } from '../../../guards/route-guard/route-guard';
import { NetworkStandardLayoutComponent } from '../../../../network/layouts/network-standard-layout/network-standard-layout.component';
import { deprecatedNetworkRoutesMatcher } from '../../../url-matchers/deprecated-network-routes-matcher/deprecated-network-routes-matcher';
import { ClusterIdResolver } from '../../../resolvers/cluster-id/cluster-id.resolver';
export const networkRoute = {
  path: 'network',
  url: '/network',
  canActivate: [RouteGuard],
  children: [
    // matches old route and redirects to /home
    {
      matcher: deprecatedNetworkRoutesMatcher,
      redirectTo: '/home',
    },
    // START OF NEW ROUTE URL:
    // new route for school net dash: added cluster type and cluster id
    {
      path: 'school/:clusterId',
      resolve: {
        cluster: ClusterIdResolver,
      },
      data: {
        clusterType: 'school',
      },
      children: [
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'dashboard',
        },
        {
          path: 'dashboard',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-top-level/network-top-level.module').then(m => m.NetworkTopLevelModule),
        },
        {
          path: 'attendance',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/attendance-trends/attendance-trends.module').then(m => m.AttendanceTrendsModule),
        },
        {
          path: 'midlevel',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-mid-level/network-mid-level.module').then(m => m.NetworkMidLevelModule),
        },
        {
          path: 'studentLevel',
          component: NetworkStandardLayoutComponent,
          resolve: { maintenanceMode: MaintenanceModeResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () =>
            import('../../../../network/network-student-level/network-student-level.module').then(
              m => m.NetworkStudentLevelModule,
            ),
        },
        {
          path: 'grid',
          component: SchoolSingleColumnLayoutComponent,
          resolve: { gridState: GridStateResolver, maintenanceMode: MaintenanceModeResolver },
          data: { maintenanceToggles: ['POSTGRES_MAINTENANCE_IN_PROGRESS'] },
          loadChildren: () => import('../../../../network/network-foci-grid/network-foci-grid.module').then(m => m.NetworkFociGridModule),
        },
        {
          path: 'settings',
          loadChildren: () =>
            import('../../../../network/network-settings/network-settings.module').then(m => m.NetworkSettingsModule),
        },
      ],
    },
    // new route for shelter net dash: added cluster type and cluster id
    {
      path: 'shelter/:clusterId',
      resolve: {
        cluster: ClusterIdResolver,
      },
      data: {
        clusterType: 'shelter',
      },
      children: [
        {
          path: 'settings',
          loadChildren: () =>
            import('../../../../shelter/shelter-network-settings/shelter-network-settings.module').then(
              m => m.ShelterNetworkSettingsModule,
            ),
        },
      ],
    },
  ],
};
