import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { RouteGuard } from 'Src/ng2/routing/guards/route-guard/route-guard';
import { USER_ROLE_PERMISSIONS_FOR_GUARDS } from 'Src/ng2/routing/guards/user-role-permissions-for-route-guards.constant';
import { SchoolTypeGuard } from 'Src/ng2/routing/guards/school-type-guard/school-type-guard';
import { SettingsModule } from '../settings.module';
import { GraduationSettingsComponent } from './graduation-settings.component';
import { SettingsRoute } from 'Src/ng2/shared/constants/settings-routes.constant';
import { IRouteConfigsOpts } from 'Src/ng2/routing/route.config';
import { DistrictGuard } from 'Src/ng2/routing/guards/district-guard/district-guard';

export const routes: IRouteConfigsOpts[] = [
  {
    path: '',
    canActivate: [RouteGuard, SchoolTypeGuard, DistrictGuard],
    rolePermissions: USER_ROLE_PERMISSIONS_FOR_GUARDS['school/:schoolId/other-tools/graduation'],
    authenticationRequired: true,
    data: {
      schoolTypes: ['hs'],
      activeRoute: SettingsRoute.GraduationMetrics,
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'fourth-year-students',
      },
      {
        path: 'fourth-year-students',
        component: GraduationSettingsComponent,
        data: {
          schoolTypes: ['hs'],
          activeRoute: SettingsRoute.GraduationMetrics,
          gradPlanRoute: 'fourth',
        },
      },
      {
        path: 'third-year-students',
        component: GraduationSettingsComponent,
        data: {
          schoolTypes: ['hs'],
          activeRoute: SettingsRoute.GraduationMetrics,
          gradPlanRoute: 'third',
        },
      },
      {
        path: 'second-year-students',
        component: GraduationSettingsComponent,
        data: {
          schoolTypes: ['hs'],
          activeRoute: SettingsRoute.GraduationMetrics,
          gradPlanRoute: 'second',
        },
      },
      {
        path: 'first-year-students',
        component: GraduationSettingsComponent,
        data: {
          schoolTypes: ['hs'],
          activeRoute: SettingsRoute.Metrics,
          gradPlanRoute: 'first',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes as Routes), SettingsModule],
  exports: [RouterModule],
})
export class GraduationSettingsRoutingModule {}
