import { IBulkUpdateStudentActivitiesParams, IStudentActivity } from './../../shared/typings/interfaces/student-activity.interface';
/* tslint:disable */

import { Action } from '@ngrx/store';
export const LOAD_STUDENT_ACTIVITIES = '[Portal] Load Student Activities';
export const LOAD_STUDENT_ACTIVITIES_SUCCESS = '[Portal] Load StudentActivities Success';
export const LOAD_STUDENT_ACTIVITIES_FAIL = '[Portal] Load Student Activities Failure';
export const CREATE_STUDENT_ACTIVITY = '[Portal] Create Student Activity';
export const CREATE_STUDENT_ACTIVITY_SUCCESS = '[Portal] Create Student Activity Success';
export const CREATE_STUDENT_ACTIVITY_FAIL = '[Portal] Create Student Activity Fail';
export const BULK_CREATE_STUDENT_ACTIVITIES = '[Portal] Bulk Create Student Activities';
export const BULK_CREATE_STUDENT_ACTIVITIES_SUCCESS = '[Portal] Bulk Create Student Activities Success';
export const BULK_CREATE_STUDENT_ACTIVITIES_FAIL = '[Portal] Bulk Create Student Activities Fail';
export const UPDATE_STUDENT_ACTIVITY = '[Portal] Update Student Activity';
export const UPDATE_STUDENT_ACTIVITY_SUCCESS = '[Portal] Update Student Activity Success';
export const UPDATE_STUDENT_ACTIVITY_FAIL = '[Portal] Update Student Activity Fail';
export const BULK_UPDATE_STUDENT_ACTIVITIES = '[Portal] Bulk Update Student Activities';
export const BULK_UPDATE_STUDENT_ACTIVITIES_SUCCESS = '[Portal] Bulk Update Student Activities Success';
export const BULK_UPDATE_STUDENT_ACTIVITIES_FAIL = '[Portal] Bulk Update Student Activities Fail';

// register store actions
export class LoadStudentActivities implements Action {
  readonly type = LOAD_STUDENT_ACTIVITIES;
  constructor(public payload: { schoolId: string }) {}
}

export class LoadStudentActivitiesSuccess implements Action {
  readonly type = LOAD_STUDENT_ACTIVITIES_SUCCESS;
  constructor(public payload: { data: { StudentActivities: IStudentActivity[] } }) {}
}

export class LoadStudentActivitiesFail implements Action {
  readonly type = LOAD_STUDENT_ACTIVITIES_FAIL;
  constructor(public payload: any) {}
}

export class CreateStudentActivity implements Action {
  readonly type = CREATE_STUDENT_ACTIVITY;
  constructor(public payload: any) {}
}

export class CreateStudentActivitySuccess implements Action {
  readonly type = CREATE_STUDENT_ACTIVITY_SUCCESS;
  constructor(public payload: any) {}
}

export class CreateStudentActivityFail implements Action {
  readonly type = CREATE_STUDENT_ACTIVITY_FAIL;
  constructor(public payload: any) {}
}

export class UpdateStudentActivity implements Action {
  readonly type = UPDATE_STUDENT_ACTIVITY;
  constructor(public payload: { studentActivityId: string; patch: any }) {}
}

export class UpdateStudentActivitySuccess implements Action {
  readonly type = UPDATE_STUDENT_ACTIVITY_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateStudentActivityFail implements Action {
  readonly type = UPDATE_STUDENT_ACTIVITY_FAIL;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentActivities implements Action {
  readonly type = BULK_UPDATE_STUDENT_ACTIVITIES;
  constructor(public payload: IBulkUpdateStudentActivitiesParams) {}
}

export class BulkUpdateStudentActivitiesSuccess implements Action {
  readonly type = BULK_UPDATE_STUDENT_ACTIVITIES_SUCCESS;
  constructor(public payload: any) {}
}

export class BulkUpdateStudentActivitiesFail implements Action {
  readonly type = BULK_UPDATE_STUDENT_ACTIVITIES_FAIL;
  constructor(public payload: any) {}
}

// action types
export type StudentActivitiesAction =
  | LoadStudentActivities
  | LoadStudentActivitiesSuccess
  | LoadStudentActivitiesFail
  | CreateStudentActivity
  | CreateStudentActivitySuccess
  | CreateStudentActivityFail
  | UpdateStudentActivity
  | UpdateStudentActivitySuccess
  | UpdateStudentActivityFail
  | BulkUpdateStudentActivities
  | BulkUpdateStudentActivitiesSuccess
  | BulkUpdateStudentActivitiesFail;
