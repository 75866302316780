import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { ImActivity } from '../../../../shared/services/im-models/im-activity.service';
import { IActivity, IUpdateActivityParams } from '../../../../shared/typings/interfaces/activity.interface';
import { UpdateActivity } from '../../../../store';
import { BaseModalComponent } from '../../base-modal.component';
import { IBaseModalData } from '../../modals.service';

export type TValidActivityStatus = 'DELETE' | 'MARK_COMPLETE' | 'MARK_INCOMPLETE';

export interface IActivityStatusModalComponentData extends IBaseModalData {
  activity: IActivity;
  mode: TValidActivityStatus;
}

@Component({
  selector: 'activities-modal',
  templateUrl: './activity-status-modal.component.html',
  styleUrls: ['./activity-status-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivityStatusModalComponent extends BaseModalComponent implements OnInit, OnDestroy {
  public activityForm: FormGroup;
  public destroy$: Subject<boolean> = new Subject<boolean>();

  // Modal Configurations
  public schoolId: string;
  public title: string;
  public confirmationButtonLabel: string;
  public cancelButtonLabel: string;
  public activityName: string;
  public activityId: string;
  public mode: string;

  constructor (
    dialogRef: MatDialogRef<ActivityStatusModalComponent>,
    private imActivity: ImActivity,
    @Inject(MAT_DIALOG_DATA) public data: IActivityStatusModalComponentData,
    private store: Store<any>,
  ) {
    super(dialogRef);
  }

  public ngOnInit (): void {
    const { mode, activity } = cloneDeep(this.data);
    this.isProfileMode = true;
    this.mode = mode;
    if (mode === 'DELETE') {
      this.title = 'Delete Activity';
      this.confirmationButtonLabel = 'Delete';
      this.cancelButtonLabel = 'Cancel';
    }
    this.activityName = this.imActivity.getActivityTitle(activity);
    this.activityId = activity._id;
  }

  public ngOnDestroy (): void {
    super.ngOnDestroy();
  }

  public close (): void {
    super.close();
  }

  public confirmAction (mode: string) {
    if (mode === 'DELETE') this.deleteActivity();
  }

  public deleteActivity (): void {
    const patch: IUpdateActivityParams = {
      status: 'DELETED',
    };
    this.store.dispatch(new UpdateActivity({ activityId: this.activityId, patch }));
    this.close();
  }
}
