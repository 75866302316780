// This constant is to represent what type of view the list view component
// is supposed to display.
export enum LIST_TYPE {
  MY_STUDENTS = 'MY_STUDENTS',
  ATTENDANCE = 'ATTENDANCE',
  ACADEMIC = 'ACADEMIC',
  POSTSECONDARY = 'POSTSECONDARY',
  POSTSECONDARY_ALT = 'POSTSECONDARY_ALT',
  GRAPH_DETAIL = 'GRAPH_DETAIL',
  GRAPH_LIST = 'GRAPH_LIST',
  GRAPH_LIST_POSTSEC = 'GRAPH_LIST_POSTSEC',
  MILESTONE = 'MILESTONE',
  COLLEGE_PATH = 'COLLEGE_PATH',
  CAREER_PATH = 'CAREER_PATH',
  NETWORK = 'NETWORK',
  STUDENT_ACTIVITIES = 'STUDENT_ACTIVITIES',
  ACTIVITIES = 'ACTIVITIES',
}
