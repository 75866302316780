import { IDropdownOption } from 'Src/ng2/shared/typings/interfaces/design-library.interface';
import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';

/**
 *
 * The a dropdown allows users to select a single option from a list.
 *
 * Options can be nested indefinitely to allow for grouping of options.
 * See the `IDropdownOptions` interface for more.
 *
 */
@Component({
  selector: 'nv-dropdown',
  templateUrl: './nv-dropdown.component.html',
  styleUrls: ['./nv-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NvDropdownComponent {
  /**
   *
   * The key of the selected option
   */
  @Input() selected: string;
  /**
   *
   * The optional styling class for list of options
   */
  @Input() customClass: string;

  /**
   *
   * An array of `IDropdownOptions`.
   *
   * Each option can have its own options attribute
   *
   * ```
   * interface IDropdownOption {
   *  key: string,
   *  human: string,
   *  tags?: Array<string>,
   *  disabled?: boolean;
   *  options?: Array<IDropdownOption>
   * }
   * ```
   *
   */
  @Input() options: IDropdownOption[];

  /**
   *
   * Temporary prop to return either a IACOption or string
   *
   * When refactoring, this should be always true
   */
  @Input() returnObject: boolean = false; // TODO -  REMOVE THIS ON REFACTOR - AT

  /**
   *
   * The text inside the pill when there is no `selected` option
   *
   * Required when no `selected` prop is given
   */
  @Input() placeholder: string;

  /**
   *
   * Sets the text to `color`, and the background to a light version of `color`
   */
  @Input() isInverted: boolean = false;

  /**
   *
   * The event emited when an option is selected.
   *
   * Returns the key of the selected `IDropdownOption`
   *
   */
  @Output() selectOption: EventEmitter<IDropdownOption | string> = new EventEmitter<IDropdownOption | string>();

  get selectedOption (): IDropdownOption {
    return this.getOptionFromKey(this.selected, this.options);
  }

  get pillLabel (): string {
    return this.selectedOption ? (this.selectedOption.pillPlaceholder || this.selectedOption.human) : this.placeholder;
  }

  handleSelectSuboption (option: IDropdownOption): void {
    this.selected = option.key;
    const emitVal = this.returnObject ? option : option.key;
    this.selectOption.emit(emitVal);
  }

  getOptionFromKey (searchKey: string, searchOptions: IDropdownOption[]): IDropdownOption {
    let foundOption: IDropdownOption = null;
    if (searchKey) {
      for (const opt of searchOptions) {
        if (foundOption) {
          break;
        } else if (opt.key === searchKey) {
          foundOption = opt;
        } else if (opt.options) {
          foundOption = this.getOptionFromKey(searchKey, opt.options);
        }
      }
    }
    return foundOption;
  }
}
